import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { invoiceStatusListLabel } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { InvoiceStatus } from 'types/entities/invoice';
import {
  BetweenAbsoluteOperands,
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import { MultiSelectFilterFieldsType } from '../../Common/CommonConditionFilter/MultiSelectFilterField';
import {
  WorkFlowBetweenRelativeOperands,
  WorkFlowGeneralRelativeOperands,
} from '../../EventCondition/operator';
import { WorkFlowFilterOperator } from '../../EventCondition/type';
import { DisplayProps } from '../TriggerTypeBasedDisplaySeparator';
import GetDisplayName from './GetDisplayName';
import DisplayGeneralData from './Helper/DisplayCurrentDate';
import { DisplayCustomFieldFilter } from './Helper/DisplayCustomFieldFilter';
import DisplayRelativeValue from './Helper/DisplayRelativeValue';
import InvoiceStatusINDisplay from './Helper/InvoiceStatusINDisplay';
import RelativeBTWNDate from './Helper/RelativeBTWNDate';

export const TriggerDisplayTexts = {
  daysBefore: 'days before',
  currentDate: 'current date',
  to: 'to',
  daysAfter: 'days after',
  daysOf: 'days of',
};

export interface DisplayOperatorValueProps extends DisplayProps {
  conditionType: MultiSelectFilterFieldsType;
}

export function displaySingleItem(text: string, color: string, strong?: boolean) {
  return (
    <GrowText color={color} strong={strong}>
      {text}
    </GrowText>
  );
}

export function displayRangeItem(texts: string[], color: string, strong?: boolean) {
  return (
    <Flex gap="var(--space-4)">
      {texts.map((text, index) => (
        <Fragment key={index}>{displaySingleItem(text, color, strong)}</Fragment>
      ))}
    </Flex>
  );
}

export function DisplayRangefield(props: {
  from: string | number;
  to: string | number;
  color: string;
  strong?: boolean;
}) {
  return (
    <Flex gap="var(--space-4)">
      {[props.from, 'and', props.to].map((text, index) => (
        <GrowText key={index} color={props.color} strong={props.strong}>
          {text}
        </GrowText>
      ))}
    </Flex>
  );
}

export function DisplayOperand(props: DisplayProps) {
  const { condition, color, strong, text } = props;
  const format = useSelector(dateFormatSelector);
  if (!condition || !condition?.operator) {
    return null;
  }

  const { type, operator } = condition;
  const workflowOperator = operator as WorkFlowFilterOperator<InvoiceStatus>;
  const singleDateOperand = workflowOperator?.operands as DefaultAbsoluteOperand<unknown>;
  const operatorType = operator.type;

  switch (type) {
    case 'INVOICE_STATUS':
      if (operatorType === 'IN') {
        const statusDisplayOperand = workflowOperator?.operands as InAbsoluteOperands<unknown>;
        return (
          <InvoiceStatusINDisplay
            value={statusDisplayOperand as InAbsoluteOperands<InvoiceStatus>}
            color={color}
            strong={strong}
            text={text}
          />
        );
      }
      const statusOperand = condition?.operator?.operands as BetweenAbsoluteOperands<InvoiceStatus>;
      const fromValue = statusOperand?.value_holder?.from?.value as InvoiceStatus;
      const toValue = statusOperand?.value_holder?.to?.value as InvoiceStatus;
      const from = invoiceStatusListLabel[fromValue];
      const to = invoiceStatusListLabel[toValue];
      return displayRangeItem([from, TriggerDisplayTexts.to, to], props.color, props.strong);

    case 'INVOICE_DUE_DATE':
    case 'ISSUE_DATE':
      if (operatorType === 'BTWN') {
        if (workflowOperator.operands.type === 'RELATIVE') {
          return (
            <RelativeBTWNDate
              condition={props.condition?.operator?.operands as WorkFlowBetweenRelativeOperands}
              color={props.color}
              strong={strong}
            />
          );
        }
        const dateRangeBtwnOperator = props.condition?.operator
          ?.operands as BetweenAbsoluteOperands<string>;
        const from = getCustomFormattedDate(
          dateRangeBtwnOperator?.value_holder?.from?.value,
          format
        );
        const to = getCustomFormattedDate(dateRangeBtwnOperator?.value_holder?.to?.value, format);
        return <DisplayRangefield from={from} to={to} color={color} strong={strong} />;
      }
      if (workflowOperator.operands.type === 'RELATIVE') {
        const relativenOperand = props.condition?.operator
          ?.operands as WorkFlowGeneralRelativeOperands;
        return (
          <DisplayRelativeValue
            offsetValue={relativenOperand?.value?.offset}
            offsetType={relativenOperand?.value?.offset_type}
            color={props.color}
            strong={strong}
          />
        );
      }
      const dateRangesingleOperator = props.condition?.operator
        ?.operands as DefaultAbsoluteOperand<string>;
      const displayValue = getCustomFormattedDate(
        dateRangesingleOperator?.value_holder?.value as string,
        format
      );
      return <DisplayGeneralData value={displayValue} color={color} strong={strong} text={text} />;
    case 'INVOICE_CUSTOM_FIELD':
    case 'CUSTOMER_CUSTOM_FIELD':
      return <DisplayCustomFieldFilter {...props} conditionType={type} />;
    default:
      if (operatorType === 'BTWN') {
        const operand = workflowOperator?.operands as BetweenAbsoluteOperands<string | number>;
        const from = operand?.value_holder?.from?.value ?? 0;
        const to = operand?.value_holder?.to?.value ?? 0;
        return <DisplayRangefield from={from} to={to} color={color} strong={strong} />;
      }
      if (operatorType === 'IN') {
        const statusDisplayOperand = workflowOperator?.operands as InAbsoluteOperands<unknown>;
        const displayValue = statusDisplayOperand?.value_holder?.map((value) => value.value);
        const displayData = (
          <GetDisplayName
            selectedEventType={type as WorkFlowEventTypes}
            displayValue={displayValue}
          />
        );
        return <DisplayGeneralData value={displayData} color={color} strong={strong} text={text} />;
      }
      break;
  }

  return (
    <DisplayGeneralData
      value={singleDateOperand?.value_holder?.value as string | number}
      color={color}
      strong={strong}
      text={text}
    />
  );
}
