import { Skeleton } from 'antd';
import { ReactNode } from 'react';
import HideWrapper from '../../../../../../Common/Util/HideWrapper';

interface ConditionFilterFieldAndDisplayProps {
  type: 'DISPLAY' | 'FILTER';
  filterCompoenent: ReactNode;
  displayComponent: ReactNode;
  loading: boolean;
}
export default function ConditionFilterFieldAndDisplay(props: ConditionFilterFieldAndDisplayProps) {
  return (
    <>
      <HideWrapper hide={props.type !== 'FILTER'}>
        <> {props.loading ? <Skeleton.Input /> : props.filterCompoenent}</>
      </HideWrapper>
      <HideWrapper hide={props.type !== 'DISPLAY'}>
        <>{props.displayComponent}</>
      </HideWrapper>
    </>
  );
}
