import { Input } from 'antd';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import produce from 'immer';
import { head } from 'lodash';
import {
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowFilterOperator } from '../../EventCondition/type';
interface CreditPeriodTextBoxProps {
  value?: WorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
}
const t = {
  inputPlaceHolder: 'Please provide a valid input ...',
  errorMsg: 'Please fill this field',
};
export default function SingleTextBoxFilter(props: CreditPeriodTextBoxProps) {
  const currentType = props?.value?.type;
  const creditPeriodValue = props.value?.operands as InAbsoluteOperands<string>;
  const inputFieldValue = head(creditPeriodValue?.value_holder)?.value;

  function handleFieldOnchange(value?: string) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        if (draftFilter) {
          const statusOperand = draftFilter.operands as
            | InAbsoluteOperands<string>
            | DefaultAbsoluteOperand<string>;
          statusOperand.value_holder = value ? [{ value: value }] : [];
        }
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <HideWrapper hide={currentType !== 'IN'}>
      <StyledFormItem
        shouldUpdate
        validateStatus={
          Boolean(creditPeriodValue?.value_holder.length) || Boolean(inputFieldValue)
            ? 'success'
            : 'error'
        }
        help={
          Boolean(creditPeriodValue?.value_holder.length) || Boolean(inputFieldValue) ? (
            <></>
          ) : (
            <>{t.errorMsg}</>
          )
        }
      >
        <Input
          onChange={(e) => handleFieldOnchange(e.target.value)}
          value={inputFieldValue}
          style={{ width: '100%' }}
          placeholder={t.inputPlaceHolder}
        />
      </StyledFormItem>
    </HideWrapper>
  );
}
