import { Texto } from 'components/BaseComponents/Typography/Texto';
import { Fragment } from 'react';
import { PrivilegesCategory } from 'store/privilges/type';
import styled from 'styled-components';
import { EPages } from '../../roles-config';
import PrivilegesOptions from './PrivillegesOptions';
interface PageSectionProps {
  pageConfig: [EPages, PrivilegesCategory];
}

const StyledCard = styled.div`
  background: var(--gray-1);
  border: 1px solid var(--gray-4);
  border-radius: var(--br-1);
  padding: var(--space-20) var(--space-24);
  scroll-behavior: smooth;
  scroll-padding: 400px;
`;

function PageSection(props: Readonly<PageSectionProps>) {
  const [page, privilegesList] = props.pageConfig;

  const CheckAllLabel = (
    <Texto size="16" weight="semibold" color="var(--gray-9)">
      {privilegesList.label}
    </Texto>
  );

  const CheckBoxOptions = (
    <Fragment>
      <PrivilegesOptions
        checkAllLabel={CheckAllLabel}
        privilegesList={privilegesList.value}
        page={page}
      />
    </Fragment>
  );
  return (
    <>{privilegesList.value.length ? <StyledCard id={page}>{CheckBoxOptions}</StyledCard> : null}</>
  );
}

export default PageSection;
