import { FormInstance } from 'antd/es/form';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadedFiles } from 'types/common/attachments';
import { UploadFileTypes } from '../../../services/attachments';

/**
 * `paramName` : field name in the form
 *
 * `uploadFileType` : UploadFileTypes enum
 */
interface Args {
  paramName: string;
  uploadFileType: UploadFileTypes;
  initialValue?: File[];
}

interface UseFileUploadArgs extends Args {
  form: FormInstance;
}

export function useFileUploadInReactHookForm({
  paramName,
  uploadFileType,
  initialValue = [],
}: Args): [UploadedFiles[], React.Dispatch<React.SetStateAction<UploadedFiles[]>>] {
  const { setValue } = useFormContext();
  const [fileList, setFileList] = useState<UploadedFiles[]>(initialValue);

  useEffect(() => {
    return () => {
      setFileList([]);
      setValue(paramName, {
        fileList: [],
        type: uploadFileType,
      });
    };
  }, [setValue, paramName, uploadFileType]);

  useEffect(() => {
    if (fileList && fileList.length) {
      setValue(paramName, {
        fileList,
        type: uploadFileType,
      });
    }
  }, [fileList, setValue, paramName, uploadFileType]);

  return [fileList, setFileList];
}

export function useFileUpload({
  form,
  paramName,
  uploadFileType,
  initialValue = [],
}: UseFileUploadArgs): [UploadedFiles[], React.Dispatch<React.SetStateAction<UploadedFiles[]>>] {
  const [fileList, setFileList] = useState<UploadedFiles[]>(initialValue);

  useEffect(() => {
    return () => {
      setFileList([]);
      form.setFieldValue(paramName, {
        fileList: [],
        type: uploadFileType,
      });
    };
  }, [form, paramName, uploadFileType]);

  useEffect(() => {
    form.setFieldValue(paramName, {
      fileList,
      type: uploadFileType,
    });
  }, [fileList, form, paramName, uploadFileType]);

  return [fileList, setFileList];
}
