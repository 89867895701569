import { useQueryClient } from '@tanstack/react-query';
import { Form } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { get, has, some } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkFlowEventTypes, WorkflowMetaDto } from 'types/entities/workflow';
import { WorkflowForms } from '../../type';
import {
  findMandatoryConditionsByEntityType,
  findOptionalConditions,
  generateOptionalConditions,
} from '../DisplayCard/Helper';
import { getDefaultFilterDataFromType } from '../EventCondition/Helper';
import { defaultCustomerAnyChgAmountData } from '../EventCondition/initial-state';
import { WorkFlowFilterRenderer } from '../Filters/WorkFlowFilterCallBack';
import { findEntityEventOptions } from '../Hooks';
import { StyledPeriodicWrapper } from '../style';
import { WorkFlowTexts } from '../text';
import TriggerConditionList from './TriggerFormFields/ConditionList/TriggerConditionList';
import { TriggerEntityFormField } from './TriggerFormFields/TriggerEntityFormField';
import { TriggerEventTypeField } from './TriggerFormFields/TriggerEventTypeField';
import { TriggerFollowUpOperator } from './TriggerFormFields/TriggerFollowUpOperator';
import { TriggerMandatoryEvent } from './TriggerFormFields/TriggerMandatoryEvent';
import { TriggerPeriodicField } from './TriggerFormFields/TriggerPeriodicField';
import {
  ConditionText,
  EntityLabel,
  EventLabel,
  FrequencyText,
  TypeMoreInfo,
} from './TriggerFormFields/util';
import { TriggerFormProps } from './type';

export default function TriggerForm(props: TriggerFormProps) {
  const { form, actionForm } = props;
  const queryClient = useQueryClient();
  const { workflow_id } = useParams();

  const {
    entity,
    trigger_type: entityType,
    condition_list: conditionEvent,
    selected_event: selectedEvent,
  } = props?.formFields;
  const entityOptions = findEntityEventOptions(entity);
  const optionalConditions = props?.value && findOptionalConditions(props?.value);
  const [modifiedOptionalConditions, setModifiedOptionalConditions] = useState(
    optionalConditions && generateOptionalConditions(optionalConditions)
  );
  const mandatory = findMandatoryConditionsByEntityType(props?.value?.trigger_type, props.value);
  const [mandatoryData, setMandatoryData] = useState(mandatory);
  const workflowMeta = queryClient.getQueryData<WorkflowMetaDto>(['workflow-meta', workflow_id]);
  const EntityForm = (
    <TriggerEventTypeField entityValue={props.value?.entity_type} onChange={handleEventOnChange} />
  );

  const mandatoryTypeFormItem = (
    <>
      {entityOptions && (
        <TriggerMandatoryEvent
          mandatory={mandatory}
          derivedOptions={entityOptions}
          handleselectEvent={handleselectEvent}
          triggerType={props.value?.trigger_type}
          mandatoryEvent={props?.value?.event_type}
          entity={entity}
          conditionEvent={conditionEvent}
          customField={entity === 'INVOICE' ? props.invoiceCustomField : props.customerCustomField}
        />
      )}
    </>
  );

  function handleEventOnChange(value: string) {
    form.setFieldValue('trigger_type', 'LIVE');
    form.setFieldValue('selected_event', undefined);
    form.setFieldValue('mandatory_operator', undefined);
    setModifiedOptionalConditions([]);
    const isNewEntity = entity && entity !== value;
    const actionObject = actionForm.getFieldValue([]);
    const isActionsCreated = workflow_id
      ? some(workflowMeta?.workflow_rules, (rule) => has(rule, 'actions'))
      : has(actionObject, 'actions') && get(actionObject, 'actions.length');
    form.setFieldValue('entityUpdated', isActionsCreated ? isNewEntity : false);
    setMandatoryData(undefined);
  }

  function handleselectEvent(value: WorkFlowEventTypes) {
    if (entity === 'INVOICE') {
      const mandatoryCondition = defaultCustomerAnyChgAmountData;
      form.setFieldValue('mandatory_operator', mandatoryCondition?.operator);
    }
    if (entity === 'CUSTOMER') {
      const mandatoryCondition = getDefaultFilterDataFromType(value, props.customFields);
      form.setFieldValue('mandatory_operator', mandatoryCondition?.operator);
    }
  }
  const EventFormItem = (
    <Flex direction="column" gap="var(--space-8)">
      {EventLabel}
      {EntityForm}
    </Flex>
  );
  const EntityItem = <TriggerEntityFormField entityValue={props.value?.trigger_type} />;
  const FollowupTrack = (
    <Flex direction="column" gap="var(--space-8)">
      {EntityLabel}
      {EntityItem}
    </Flex>
  );

  const MandatoryFilterFormItem = (
    <>
      {mandatoryTypeFormItem}

      <StyledFormItem
        name={`mandatory_operator`}
        initialValue={mandatory ? mandatory?.operator : 'ANY'}
        validateStatus="success"
        help={<></>}
        hidden={entity === 'INVOICE'}
      >
        {entity === 'INVOICE' ? null : (
          <WorkFlowFilterRenderer
            form={form}
            selectedEvent={
              selectedEvent
                ? (selectedEvent as WorkFlowEventTypes)
                : (mandatoryData?.id as unknown as WorkFlowEventTypes)
            }
            customFields={props.customFields}
          />
        )}
      </StyledFormItem>
    </>
  );
  const MandatoryConditionItem = (
    <Flex gap="var(--space-8)" direction="column">
      <Flex gap="var(--space-8)">{MandatoryFilterFormItem}</Flex>
    </Flex>
  );
  const LiveType = <HideWrapper hide={entityType !== 'LIVE'}>{MandatoryConditionItem}</HideWrapper>;
  const PeriodicType = (
    <HideWrapper hide={entityType !== 'PERIODIC'}>
      <StyledPeriodicWrapper direction="column" gap="var(--space-8)">
        {FrequencyText}
        <TriggerPeriodicField mandatory={mandatory} />
      </StyledPeriodicWrapper>
    </HideWrapper>
  );
  const ConditionListFormItem = (
    <Flex gap="var(--space-12)" direction="column">
      <TriggerConditionList
        form={form}
        selectedEvent={selectedEvent}
        initialValue={modifiedOptionalConditions}
        customFields={entity === 'INVOICE' ? props.invoiceCustomField : props.customerCustomField}
      />
    </Flex>
  );

  const FollowUpConditions = (
    <Flex align="center" gap="var(--space-4)">
      <TriggerFollowUpOperator operator={props.value?.condition?.operator ?? 'AND'} />
      <GrowText>{WorkFlowTexts.ofTheFollowing}</GrowText>
    </Flex>
  );
  const TypeFormItem = (
    <Flex direction="column" gap="var(--space-12)">
      {FollowupTrack}
      {TypeMoreInfo}
      {LiveType}
      {PeriodicType}
    </Flex>
  );
  const TriggerTypeFormItem = (
    <HideWrapper hide={!entity}>
      <>{TypeFormItem}</>
    </HideWrapper>
  );
  return (
    <Form
      name={WorkflowForms.TRIGGER_FORM}
      className="tw-overflow-auto !tw-h-[calc(100vh-290px)] tw-pb-[40px]"
      requiredMark={false}
      form={form}
      colon={false}
      layout="horizontal"
      preserve={false}
    >
      <Flex direction="column" justify="space-between">
        <Flex direction="column" gap="var(--space-24)" justify="space-between">
          {EventFormItem}
          {TriggerTypeFormItem}

          <HideWrapper hide={!selectedEvent}>
            <Flex direction="column" gap="var(--space-12)">
              {ConditionText}
              <Flex direction="column" gap="var(--space-16)">
                <HideWrapper hide={!conditionEvent || !conditionEvent?.length}>
                  {FollowUpConditions}
                </HideWrapper>
                {ConditionListFormItem}
              </Flex>
            </Flex>
          </HideWrapper>
        </Flex>
      </Flex>
    </Form>
  );
}
