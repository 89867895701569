import { Anchor, Col, Divider, Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { useUserPrivilegesQuery } from 'queries/Settings/user-administration';
import { useEffect, useRef, useState } from 'react';
import { Roles } from 'store/roles/types';
import styled from 'styled-components';
import { EPages } from '../roles-config';

import { GrowText } from 'components/BaseComponents/Typography';
import { PrivilegesCategory } from 'store/privilges/type';
import PageSection from './PageSection';
import SideBarTitle from './PageSection/SideBarTitle';
import { PrivilegesBody, SEditPrivileges } from './style';

interface EditPrivilegesProps {
  currentRole: Roles;
}

interface AnchorLinkProps {
  key: string;
  item: PrivilegesCategory;
}

type sideBarMenuItems = {
  [key in EPages]: string;
};

export const sideMenuLabel: sideBarMenuItems = {
  [EPages.DASHBOARD]: 'Dashboard',
  [EPages.INBOX]: 'Inbox',
  [EPages.ALL_CUSTOMERS]: 'All Customers',
  [EPages.AR_AGING]: 'AR Aging',
  [EPages.CUSTOMER_DETAIL]: 'Customers',
  [EPages.INVOICE_LIST]: 'Invoice List',
  [EPages.INVOICE_DETAIL]: 'Invoice Detail',
  [EPages.COLLECTIONS]: 'Collections',
  [EPages.COLLECTION_FOLLOWUP]: 'Collection Strategy',
  [EPages.REPORTS]: 'Reports',
  [EPages.SETTINGS]: 'Settings',
  [EPages.GENERAL]: 'General',
};
export const StyledAnchor = styled(Anchor)`
  padding: var(--space-20) 0;
  height: 100%;
  background: var(--gray-1);

  &.no-padding {
    padding: 0 !important;
    background: transparent;
    .icon {
      display: none;
    }
    .ant-anchor-link-active {
      .icon {
        display: block;
      }
    }
    .ant-anchor {
      padding-inline-start: 0 !important;
    }
    .ant-anchor-link {
      padding: var(--space-12) var(--space-8) !important ;
    }
    a {
      width: 100% !important;
    }

    .ant-anchor::before {
      content: none !important;
    }
  }

  .ant-anchor-link {
    display: flex;
    align-items: center;
    height: 30px;
  }

  .ant-anchor-ink-visible {
    display: none !important;
  }

  .ant-anchor-link-active > .ant-anchor-link-title {
    color: var(--primary-7);
  }

  .ant-anchor .ant-anchor-fixed {
    padding: 0;
  }
  &.ant-anchor-ink::before {
    display: none !important ;
  }

  .ant-anchor-link.ant-anchor-link-active {
    background: var(--primary-1);
  }
  .ant-anchor-link.ant-anchor-link-active {
    .anchor-text {
      font-weight: var(--fs-semibold) !important ;
    }
  }
`;

const StyledAnchorLink = styled(Anchor.Link)`
  padding: var(--space-8) var(--space-24);
`;

const StyledCol = styled(Col)`
  overflow: auto;
  height: 100%;
  min-height: 100%;
`;

function EditPrivileges(props: Readonly<EditPrivilegesProps>) {
  const { data, isLoading } = useUserPrivilegesQuery(props.currentRole.id);

  const ref = useRef<HTMLDivElement | null>(null);
  const [isRendered, setIsRendered] = useState(false);
  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);
  function handleIsRendered() {
    if ((data?.rolesAndPrivilegeTree || data?.otherRolesAndPrivileges) && ref.current) {
      setIsRendered(true);
    }
  }

  function handleTargetOffset() {
    setTargetOffset(window.innerHeight / 6);
  }

  useEffect(handleIsRendered, [data]);
  useEffect(handleTargetOffset, []);

  if (!(data?.rolesAndPrivilegeTree || data?.otherRolesAndPrivileges)) {
    return null;
  }

  const PageMainSection = [...data.rolesAndPrivilegeTree]?.map((pageConfig, index) => (
    <PageSection key={index} pageConfig={pageConfig} />
  ));

  const PageOthersSection = [...data.otherRolesAndPrivileges]?.map((pageConfig, index) => (
    <PageSection key={index} pageConfig={pageConfig} />
  ));

  function handleGetContainer() {
    return ref.current as HTMLElement;
  }

  function AnchorLink(props: Readonly<AnchorLinkProps>) {
    const { item } = props;
    const { key, value, label } = item;
    return (
      <>
        {value.length ? (
          <div style={{ height: '30px' }}>
            <StyledAnchorLink title={<SideBarTitle label={label} />} key={key} href={`#${key}`} />
          </div>
        ) : null}
      </>
    );
  }
  return (
    <SEditPrivileges className="privileges-container">
      <PrivilegesBody style={{ height: '100%', overflow: 'auto' }}>
        <Col span={5} style={{ paddingLeft: 0, paddingRight: 0, overflow: 'auto' }}>
          {isLoading || !isRendered ? (
            <Skeleton paragraph={{ rows: 10 }} />
          ) : (
            <StyledAnchor
              getContainer={handleGetContainer}
              affix={false}
              showInkInFixed={false}
              targetOffset={targetOffset}
            >
              {[...data.rolesAndPrivilegeTree.entries()].map(([key, value]) => {
                return <AnchorLink key={key} item={value} />;
              })}
              <div style={{ padding: '0 var(--space-24)' }}>
                <Divider style={{ margin: 'var(--space-8) 0' }} />
                <GrowText
                  color="var(--gray-7)"
                  size="14"
                  style={{ paddingBottom: 'var(--space-8)' }}
                >
                  Others
                </GrowText>
              </div>
              {[...data.otherRolesAndPrivileges.entries()].map(([key, value]) => {
                return <AnchorLink key={key} item={value} />;
              })}
            </StyledAnchor>
          )}
        </Col>
        <StyledCol span={19} id="wy-scroll-layout" ref={ref}>
          {isLoading ? (
            <Skeleton paragraph={{ rows: 20 }} />
          ) : (
            <Flex
              direction="column"
              gap="--space-24"
              style={{ padding: 'var(--space-20) var(--space-20) 580px var(--space-20)' }}
            >
              {PageMainSection}
              {PageOthersSection}
            </Flex>
          )}
        </StyledCol>
      </PrivilegesBody>
    </SEditPrivileges>
  );
}

export default EditPrivileges;
