import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CustomFieldIconWithText,
  GrowButton,
  GrowFlex,
  GrowTypography,
} from '@sinecycle/growcomponents';
import { Form, Select, Skeleton, Tag, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RadioGroup } from 'components/BaseComponents/Radio';
import { LabelBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { CustomTagProps } from 'components/Common/ActivitiesForm/types';
import { FormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { find, map } from 'lodash';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { useEmailGroupedOptions } from '../../../hooks/useRelativeOptions';
import { WorkflowForms } from '../../../type';
import AssigneeReadOnly from '../../ActionConfigurations/TitleComponents/AssigneeReadOnly';
import {
  ActionActivityTypes,
  AddApprovalProps,
  ApprovalContact,
  ApprovalContactOptions,
  ApprovalType,
  ApprovalUserOptions,
} from '../../types';
import { ActionType } from '../ActionType';
export const approvaltypeOptions = [
  {
    label: 'Any',
    value: 'ANY',
    style: {
      borderRadius: 'var(--br-1 ) 0px 0px var(--br-1 )',
    },
  },
  { label: 'First Response', value: 'FIRST_RESPONSE' },

  {
    label: 'All',
    value: 'ALL',
    style: {
      borderRadius: '0px var(--br-1 ) var(--br-1 ) 0px',
    },
  },
];
const label: Record<ApprovalType, string> = {
  ANY: 'Any one of the approver can approve',
  FIRST_RESPONSE: 'Based on the first approver’s response, workflow continues',
  ALL: 'Everyone  of the approver needs to approve',
};
export const approvalTypeLabel: Record<ApprovalType, string> = {
  ANY: 'Any',
  FIRST_RESPONSE: 'First response',
  ALL: 'All',
};

const transformContactOptions = (option: ApprovalContact) =>
  match(option)
    .with({ type: BaseRecepientsType.POC }, (props) => ({
      value: props.value,
      type: props.type,
      association_level: props.association_level,
    }))
    .with({ type: BaseRecepientsType.USER }, (props) => ({ id: props.id, type: props.type }))
    .with({ type: BaseRecepientsType.APPROVER_CF }, (props) => ({
      id: props.id,
      type: props.type,
    }))
    .with({ type: BaseRecepientsType.APPROVER_CONSTANT }, (props) => ({
      email: props.email,
      type: props.type,
    }))
    .otherwise(() => {
      throw new Error(`Unhandled recipient type: ${JSON.stringify(option)}`);
    });
const transformContactForSelect = (contact: ApprovalContact) =>
  match(contact)
    .with({ type: BaseRecepientsType.POC }, (props) => ({ ...props, value: props.value }))
    .with({ type: BaseRecepientsType.USER }, (props) => ({ ...props, value: props.id }))
    .with({ type: BaseRecepientsType.APPROVER_CF }, (props) => ({ ...props, value: props.id }))
    .with({ type: BaseRecepientsType.APPROVER_CONSTANT }, (props) => ({
      ...props,
      value: props.email,
    }))
    .otherwise(() => {
      throw new Error(`Unhandled recipient type: ${JSON.stringify(contact)}`);
    });

function getAppovers(value?: ApprovalContact, options?: ApprovalUserOptions) {
  return match(value)
    .with({ type: BaseRecepientsType.POC }, (props) => {
      const name = options?.find((option) => option.value === props.value);
      return name?.label;
    })
    .with({ type: BaseRecepientsType.USER }, (props) => {
      const name = options?.find((option) => option.value === props.id);
      return name?.label;
    })
    .with({ type: BaseRecepientsType.APPROVER_CF }, (props) => {
      const name = options?.find((option) => option.value === props.id);
      return name?.label;
    })
    .with({ type: BaseRecepientsType.APPROVER_CONSTANT }, (props) => {
      return props.email;
    })
    .otherwise(() => {
      throw new Error(`Unhandled recipient type: ${JSON.stringify(value)}`);
    });
}
function AddApproval(props: AddApprovalProps) {
  const Approvers = (
    <FormItem
      label="Approver(s)"
      name={'to'}
      initialValue={props.data?.to}
      rules={[{ required: true, message: 'this field is required' }]}
    >
      <ApprovalUsers options={props.options} />
    </FormItem>
  );
  const Response = (
    <FormItem
      label="Who should approve?"
      name="approval_type"
      initialValue={props.data?.approval_type ?? 'ANY'}
    >
      <ApprovalTypeChoose />
    </FormItem>
  );
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <AddApprovalReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={props.form}
          layout="vertical"
          preserve={false}
        >
          <div className="tw-p-16 tw-bg-gray-2">
            {Approvers}
            {Response}
          </div>
          <ActionType value={ActionActivityTypes.APPROVAL_REQUEST} />
        </Form>
      )}
    </>
  );
}

export { AddApproval };

function ApprovalUsers(props: {
  value?: ApprovalContact[];
  onChange?: (value: ApprovalContact[]) => void;
  options?: ApprovalUserOptions;
}) {
  const { onChange, value } = props;

  const { combinedOptions, loading } = useEmailGroupedOptions(value);

  const currentOptions = useMemo(() => combinedOptions, [combinedOptions]);

  const [hasSearch, setHasSearch] = useState<boolean>(true);
  const [form] = useForm<{ type: BaseRecepientsType.APPROVER_CONSTANT; email: string }>();

  function tagRender(props: CustomTagProps) {
    const { closable, onClose, label, value } = props;
    const isCustomField = find(combinedOptions, { value }) as ApprovalContact;

    return (
      <Tag
        onClose={onClose}
        closable={closable}
        style={{
          paddingBlock: 'var(--space-2)',
          backgroundColor: 'var(--gray-2)',
        }}
        className="tw-flex tw-items-center tw-mr-4"
      >
        {isCustomField?.type === BaseRecepientsType.APPROVER_CF ? (
          <CustomFieldIconWithText hasSymbol style={{ fontSize: 'var(--fs-12)' }}>
            {label}
          </CustomFieldIconWithText>
        ) : (
          <GrowTypography.Text ellipsis style={{ maxWidth: '180px' }} fs="12">
            {label}
          </GrowTypography.Text>
        )}
      </Tag>
    );
  }

  const modifiedValue = map(value, transformContactForSelect) as unknown as ApprovalContact[];

  const CustomEmail = (
    <Form
      form={form}
      className="tw-flex tw-p-4"
      initialValues={{ type: BaseRecepientsType.APPROVER_CONSTANT }}
    >
      <Form.Item hidden name="type" />
      <Form.Item
        name="email"
        style={{ margin: 0 }}
        initialValue={'Add Email'}
        rules={[{ message: 'Please enter valid email', type: 'email' }]}
      >
        <AddEmail
          onClick={() => {
            form.validateFields().then((submit) => {
              onChange?.([...(value ?? []), { ...submit }]);
            });
          }}
        />
      </Form.Item>
    </Form>
  );

  const ApprovalChoose = (
    <Select<ApprovalContact[], ApprovalContactOptions>
      placeholder="Add Approvers"
      value={modifiedValue}
      onChange={(value, options) => {
        const modifiedvalue = map(options, transformContactOptions) as unknown as ApprovalContact[];
        onChange?.(modifiedvalue);
      }}
      virtual
      options={currentOptions}
      mode="multiple"
      showSearch
      optionFilterProp="label"
      tagRender={tagRender}
      onSearch={(value) => {
        form.setFieldValue('email', value);
        form.validateFields();
        const seacrhValue = combinedOptions?.filter((f) => {
          return f.label.includes(value.toLowerCase());
        });
        setHasSearch(Boolean(seacrhValue?.length));
      }}
      optionRender={(value) => {
        if (value?.data?.type === BaseRecepientsType.APPROVER_CF) {
          return <CustomFieldIconWithText hasSymbol>{value.label}</CustomFieldIconWithText>;
        }

        return value.label;
      }}
      dropdownRender={(menu) => {
        return (
          <>
            {hasSearch ? menu : null}
            <>{hasSearch ? null : CustomEmail}</>
          </>
        );
      }}
      onDropdownVisibleChange={(open) => {
        if (open) {
          return;
        }
        setHasSearch(true);
        form.resetFields();
      }}
    />
  );
  return currentOptions?.length ? ApprovalChoose : <Skeleton.Input active={loading} />;
}

function ApprovalTypeChoose(props: { value?: string; onChange?: (value: string) => void }) {
  const Info = (
    <GrowFlex vertical gap={8}>
      <InfoContent
        label={<LabelBlock color="var(--gray-6)" label={'Any'} strong />}
        content={<LabelBlock color="var(--gray-1)" label={label.ANY} />}
      />
      <InfoContent
        label={<LabelBlock color="var(--gray-6)" label={'All'} strong />}
        content={<LabelBlock color="var(--gray-1)" label={label.ALL} />}
      />
      <InfoContent
        label={<LabelBlock color="var(--gray-6)" label={'First Response'} strong />}
        content={<LabelBlock color="var(--gray-1)" label={label.FIRST_RESPONSE} />}
      />
    </GrowFlex>
  );
  return (
    <div className="tw-flex tw-items-center tw-gap-24">
      <RadioGroup
        optionType="button"
        options={approvaltypeOptions}
        value={props.value}
        onChange={(value) => {
          props.onChange?.(value.target.value);
        }}
      />
      <Tooltip title={Info}>
        <div>
          <FontAwesomeIcon
            icon={['far', 'circle-info']}
            color="var(--blue-6)"
            className="hover:tw-bg-background-1 tw-p-4 tw-rounded-full"
          />
        </div>
      </Tooltip>
    </div>
  );
}

export function AddApprovalReadOnly(props: AddApprovalProps) {
  const { data } = props;
  const { combinedOptions } = useEmailGroupedOptions(props.data?.to);

  const modifiedvalue = props.data?.to
    ?.map((value) => {
      return getAppovers(value, combinedOptions);
    })
    .filter(Boolean);

  const aptype = data?.approval_type ? label[data.approval_type] : null;
  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent
        label="Approver(s)"
        content={<AssigneeReadOnly assignees={modifiedvalue as string[]} />}
      />
      <InfoContent label="Who should approve?" content={aptype} />
    </div>
  );
}

interface AddEmailProps {
  value?: string;
  onClick: () => void;
}
function AddEmail(props: AddEmailProps) {
  const { value, onClick } = props;
  return (
    <GrowButton
      onClick={onClick}
      type="text"
      icon={<FontAwesomeIcon icon={['far', 'plus-circle']} color="var(--primary-7)" />}
    >
      <LabelBlock color="var(--primary-7)" label={value} />
    </GrowButton>
  );
}
