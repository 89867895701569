import {
  ExecutionDVD,
  ExecutionsActionDetailRequestParam,
  ExecutionsActionDetailResponse,
  ExecutionsMapLV,
  ExecutionsReport,
} from 'components/Settings/WorkFlowAutomation/Executions/type';
import { omit } from 'lodash';
import { ApprovalRequestDV } from 'pages/Approvals/type';
import { PageList } from 'types/common/page-list';
import { ActivityActions } from './../components/Settings/WorkFlowAutomation/Executions/type';
import ky from './ky';

const baseUrl = 'v1/executions';

interface ExecutionsDetailedViewParams {
  event_id?: string;
  workflow_id?: string;
}
export interface ExecutionsApprovalRequestParams {
  workflow_id?: string;
  approval_id?: number;
}
export async function getExecutionsList(params?: ExecutionsReport) {
  const search = omit(params, 'filters');
  return ky
    .post(`${baseUrl}/generate-report`, {
      searchParams: search,
      json: { ...params?.filters },
    })
    .json<PageList<ExecutionsMapLV, unknown>>();
}
export async function getExecutionsDetailedView(params?: ExecutionsDetailedViewParams) {
  return ky
    .get(`${baseUrl}/${params?.event_id}/workflow/${params?.workflow_id}`)
    .json<ExecutionDVD>();
}

export async function getExecutionActionDetailsView<T extends ActivityActions>(
  params: Partial<ExecutionsActionDetailRequestParam>
) {
  const endpoint =
    params.status === 'SUCCESS' ||
    (params.action_type === 'APPROVAL_REQUEST' && params.status === 'IN_PROGRESS')
      ? 'success'
      : params.status === 'FAILURE'
      ? 'failure'
      : '';

  return ky
    .post(`${baseUrl}/workflow/${params.workflow_id}/action-detail-view/${endpoint}`, {
      json: params,
    })
    .json<ExecutionsActionDetailResponse<T>>();
}

export async function getExecutionsApprovalDetails(params: ExecutionsApprovalRequestParams) {
  return ky
    .get(`${baseUrl}/workflow/${params?.workflow_id}/approval_request/${params.approval_id}`)
    .json<ApprovalRequestDV>();
}
