import { Flex } from 'components/BaseComponents/Layout/Flex';
import { FilePreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import AssociatedFiles from 'components/Common/AssociatedFiles';
import AssociatedCustomerStatement from 'components/Common/AssociatedFiles/CustomerStatement';
import CustomerStatementPreview from 'components/Common/AssociatedFiles/CustomerStatement/Preview';
import PreviewAssociatedFiles from 'components/Common/AssociatedFiles/PreviewAssociatedFiles';
import { AFiles, AssociatedFile } from 'components/Common/AssociatedFiles/type';
import HideWrapper from 'components/Common/Util/HideWrapper';
import BulkDocuments from 'components/Documents/Email/BulkDocuments';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { FileIcon } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/new-email';
import { produce } from 'immer';
import { find, get } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AllOpenInvoicesAttachment,
  CurrentInvoiceAttachment,
  DocumentAttachment,
  GenerativeAttachment,
} from 'store/email/types';
import { AccountConfigKey } from 'types/entities/account';
import { FollowupType } from 'types/entities/collection-strategy';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import useCustomerStatement from 'util/hooks/useCustomerStatement';
function useStatement(
  formMethod?: UseFormReturn<EmailActionData, object>,
  followupType?: FollowupType
) {
  function initializeValue() {
    const value = find(formMethod?.getValues('attachments'), {
      type: 'GENERATIVE_ATTACHMENT',
    }) as GenerativeAttachment;
    if (!value) return;
    if (!value.list.length) return;
    return value.list[0];
  }
  const isCustomerStatementEnabled = useConfig(AccountConfigKey.CUSTOMER_STATEMENT);
  const initalvalue = useMemo(initializeValue, [formMethod]);
  const [associatedFiles, setAssociatedFiles] = useState<AssociatedFile[]>([]);
  const [documents, setDocuments] = useState<AssociatedFile[]>();

  const { statements, handleEnable, handleRemove, enable, handleFormChange, handleTemplateUpload } =
    useCustomerStatement('RELATIVE', initalvalue);

  function initializeDocuments() {
    const doc = find(formMethod?.getValues('attachments'), {
      type: 'DOCUMENT',
    }) as DocumentAttachment;
    if (!doc) return;

    return [
      ...(doc.customer_document_type_ids ?? []),
      ...(doc.invoice_document_type_ids ?? []),
    ].map((f) => String(f));
  }

  const docs = useMemo(initializeDocuments, [formMethod]);
  function handleDocuments(event: CustomEvent) {
    if (event.type === 'add-document') {
      setDocuments(event.detail);
    }
  }

  const documentEventCallback = useCallback(handleDocuments, []);

  function getSelectedDocuments() {
    if (!documents) return [];
    return documents.filter((f) => Boolean(f.checked));
  }

  const selectedDocuments = useMemo(getSelectedDocuments, [documents]);
  useEffect(() => {
    document.addEventListener('add-document', documentEventCallback as any);
    return () => {
      document.removeEventListener('add-document', documentEventCallback as any);
    };
  }, [documentEventCallback]);
  function handleAssociatedFileChange(id?: number | string, status?: boolean) {
    if (!id) return;
    const updatedFiles = produce(associatedFiles, (draft) => {
      const isExist = draft.findIndex((file) => file.id === id);

      draft[isExist].checked = status;
      return draft;
    });
    setAssociatedFiles(updatedFiles);
    const preValues = formMethod?.getValues('attachments') ?? [];
    if (followupType === FollowupType.INVOICE) {
      const updatedValues = produce(preValues, (draft) => {
        const isExits = draft.findIndex((f) => f.type === 'CURRENT_INVOICE');

        if (isExits === -1) {
          draft.push({
            type: status ? 'CURRENT_INVOICE' : undefined,
          });
        } else {
          draft[isExits] = {
            type: status ? 'CURRENT_INVOICE' : undefined,
          };
        }
      }).filter((f) => Boolean(f.type));
      formMethod?.setValue('attachments', updatedValues);
    } else {
      const updatedValues = produce(preValues, (draft) => {
        const isExits = draft.findIndex((f) => f.type === 'ALL_OPEN_INVOICES');

        if (isExits === -1) {
          draft.push({
            type: status ? 'ALL_OPEN_INVOICES' : undefined,
          });
        } else {
          draft[isExits] = {
            type: status ? 'ALL_OPEN_INVOICES' : undefined,
          };
        }
      }).filter((f) => Boolean(f.type));
      formMethod?.setValue('attachments', updatedValues);
    }
  }

  function initializeAssociateFiles() {
    const status =
      followupType === FollowupType.INVOICE
        ? (find(formMethod?.getValues('attachments'), {
            type: 'CURRENT_INVOICE',
          }) as CurrentInvoiceAttachment)
        : (find(formMethod?.getValues('attachments'), {
            type: 'ALL_OPEN_INVOICES',
          }) as AllOpenInvoicesAttachment);

    const fileName = followupType === FollowupType.INVOICE ? 'Invoice File' : ' All Open Invoices';
    const result = produce(AFiles, (draft) => {
      draft[0].name = fileName;
      draft[0].checked = status ? Boolean(status.type) : false;
      return draft;
    });
    setAssociatedFiles(result);
  }

  function handleTemplateAttachFiles(status?: boolean) {
    const predicate = (previousState: AssociatedFile[]) =>
      produce(previousState, (draft) => {
        draft[0].checked = status;
        return draft;
      });
    setAssociatedFiles(predicate);
  }

  function handleInsertDocuments(templateDocument: DocumentAttachment) {
    const customerIds = templateDocument.customer_document_type_ids
      ? templateDocument.customer_document_type_ids?.map((f) => String(f))
      : [];
    const invoiceIds = templateDocument.invoice_document_type_ids
      ? templateDocument.invoice_document_type_ids?.map((f) => String(f))
      : [];
    const ids = [...(customerIds ?? []), ...(invoiceIds ?? [])];
    const updatedValues = documents?.map((f) => {
      if (ids.includes(String(f.typeId))) {
        return { ...f, checked: true };
      }
      return { ...f, checked: false };
    });

    setDocuments(updatedValues);
    const event = new CustomEvent('update-document', { detail: updatedValues });
    document.dispatchEvent(event);
  }
  function resetDocuments() {
    const updatedValues = documents?.map((f) => {
      return { ...f, checked: false };
    });
    setDocuments(updatedValues);
    const event = new CustomEvent('update-document', { detail: updatedValues });
    document.dispatchEvent(event);
  }

  const PreviewAssociatedFileComp = (
    <>
      {associatedFiles
        .filter((file) => Boolean(file.checked))
        .map((file) => {
          return (
            <PreviewAssociatedFiles
              key={file.id}
              associatedFile={file}
              onRemove={handleAssociatedFileChange}
            />
          );
        })}
    </>
  );
  const CustomerStatement = (
    <HideWrapper hide={!isCustomerStatementEnabled}>
      <AssociatedCustomerStatement
        checked={enable}
        onChange={handleEnable}
        key={String(enable)}
        statement={statements}
        onFormChange={handleFormChange}
      />
    </HideWrapper>
  );

  const AssociatedFileComp = (
    <Flex direction="column" gap="--space-8">
      <AssociatedFiles
        associatedFiles={associatedFiles}
        onChange={handleAssociatedFileChange}
        extra={CustomerStatement}
      />
      <BulkDocuments selectedValues={docs} />
    </Flex>
  );

  const CustomerStatementPreviewComp = (
    <HideWrapper hide={!isCustomerStatementEnabled}>
      <CustomerStatementPreview
        fileType={statements.file_type}
        onRemove={handleRemove}
        hide={!enable}
        statementType={statements.attachment_type}
      />
    </HideWrapper>
  );

  const ExtraPreview = (
    <Flex gap="--space-8" wrap="wrap">
      {CustomerStatementPreviewComp}
      {PreviewAssociatedFileComp}
      {selectedDocuments.map((f) => {
        return (
          <FilePreview
            previewIcon={FileIcon}
            hidePreview={true}
            fileId={Number(f.id)}
            previewId={f.previewId}
            fileName={f.file_name as string}
            key={f.id}
            onRemove={(id) => {
              const updatedFiles = produce(documents, (draft) => {
                if (!draft) return [];
                const isExist = draft.findIndex((file) => file.id === String(id));

                draft[isExist].checked = false;
                return draft;
              });
              setDocuments(updatedFiles);

              const event = new CustomEvent('update-document', { detail: updatedFiles });
              document.dispatchEvent(event);
            }}
          />
        );
      })}
    </Flex>
  );

  const AttachInvoice = get(associatedFiles, '[0].checked', false);

  function updateStatements() {
    const preValues = formMethod?.getValues('attachments') ?? [];

    const updatedValues = produce(preValues, (draft) => {
      const isExits = draft.findIndex((f) => f.type === 'GENERATIVE_ATTACHMENT');

      if (isExits === -1) {
        draft.push({
          type: 'GENERATIVE_ATTACHMENT',
          list: enable ? [statements] : [],
        });
      } else {
        draft[isExits] = {
          type: 'GENERATIVE_ATTACHMENT',
          list: enable ? [statements] : [],
        };
      }
    });

    formMethod?.setValue('attachments', updatedValues);
  }

  function updateDocuments() {
    const preValues = formMethod?.getValues('attachments') ?? [];
    const customerDocuments = selectedDocuments.filter((f) => f.entity === 'CUSTOMER');
    const invoiceDocuments = selectedDocuments.filter((f) => f.entity === 'INVOICE');

    const updatedValues = produce(preValues, (draft) => {
      const isExits = draft.findIndex((f) => f.type === 'DOCUMENT');

      if (isExits === -1) {
        draft.push({
          type: selectedDocuments.length ? 'DOCUMENT' : undefined,
          invoice_document_type_ids: invoiceDocuments.length
            ? invoiceDocuments.map((f) => String(f.id))
            : undefined,
          customer_document_type_ids: customerDocuments.length
            ? customerDocuments.map((f) => String(f.id))
            : undefined,
        });
      } else {
        draft[isExits] = {
          type: selectedDocuments.length ? 'DOCUMENT' : undefined,
          invoice_document_type_ids: invoiceDocuments.length
            ? invoiceDocuments.map((f) => String(f.id))
            : undefined,
          customer_document_type_ids: customerDocuments.length
            ? customerDocuments.map((f) => String(f.id))
            : undefined,
        };
      }
    }).filter((f) => Boolean(f.type));

    formMethod?.setValue('attachments', updatedValues);
  }
  useEffect(updateStatements, [enable, formMethod, statements]);
  useEffect(updateDocuments, [followupType, formMethod, selectedDocuments]);
  useEffect(initializeAssociateFiles, [followupType, formMethod]);

  return {
    AssociatedFileComp,
    ExtraPreview,
    enable,
    statements,
    handleTemplateUpload,
    AttachInvoice,
    handleTemplateAttachFiles,
    handleInsertDocuments,
    resetDocuments,
  };
}

export default useStatement;
