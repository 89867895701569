import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, RadioChangeEvent } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { DownloadButton } from 'components/BaseComponents/Buttons';
import { DateRangeSelect } from 'components/BaseComponents/DateRangeSelect';
import { DateRangeString } from 'components/BaseComponents/DateRangeSelect/types';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { RadioGroup } from 'components/BaseComponents/Radio';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DatePickerComponent } from 'components/BaseComponents/Calendar';
import { useGetReportAvailableDates } from 'queries/Customers/outstanding-customer';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { getCurrentDate } from 'util/datetime-formatter';
import { RelativeDateRange, getFormattedDateForRelativeOptions } from 'util/relative-date-range';
import { ARAgingExportTabType, CurrentPage } from './QuickReportsComponents';

const t = {
  dateRange: 'Date Range',
  warningMessage: 'Historic reports will be based on only transaction data synced in Growfin.',
};

const downloadButtonStyles: React.CSSProperties = {
  background: 'var(--purple-8)',
  border: 0,
  color: 'var(--gray-1)',
};
const DATE_FORMAT = 'DD MMM YYYY';

export enum DateInputType {
  SINGLE,
  RANGE,
  NO_DATE,
}

export enum ExportOption {
  ALL = 'All',
  FILTERED_DATA = 'Filtered Data',
}

export enum ReportType {
  CURRENT = 'CURRENT',
  HISTORY = 'HISTORY',
}

const ExportOptions = [
  {
    label: ExportOption.ALL,
    value: ExportOption.ALL,
  },
  {
    label: ExportOption.FILTERED_DATA,
    value: ExportOption.FILTERED_DATA,
  },
];

type QuickReportsCardProps = {
  title: React.ReactNode;
  description: string;
  style?: React.CSSProperties;
  downloading?: boolean;
  handleClose: () => void;
  hideOptions?: boolean;
  extra?: React.ReactNode;
  currentPage?: CurrentPage;
  tabType?: ARAgingExportTabType;
  selectedDate?: string;
} & (
  | {
      dateInputType: DateInputType.SINGLE;
      onDownload: (option: ExportOption, reportType: ReportType, date?: string) => void;
    }
  | {
      dateInputType: DateInputType.RANGE;
      onDownload: (option: ExportOption, from?: string, to?: string) => void;
    }
  | {
      dateInputType: DateInputType.NO_DATE;
      onDownload: () => void;
    }
);

export function QuickReportsCard(props: QuickReportsCardProps) {
  // const isBalanceComputed = useConfig(AccountConfigKey.SHOULD_EVALUATE_BALANCES);
  const [selectedDate, setDateChange] = useState(props.selectedDate);
  const LAST_30_DAYS = 31;

  const [dateRange, setDateRange] = useState<{ from?: string; to?: string }>(() => {
    const [from, to] = getFormattedDateForRelativeOptions(RelativeDateRange.THIS_WEEK);
    return { from, to };
  });

  const date = getCurrentDate();
  const [selectedExportOption, setSelectedExportOption] = useState<ExportOption>(ExportOption.ALL);
  const { data } = useGetReportAvailableDates();

  useEffect(() => {
    setSelectedExportOption(ExportOption.ALL);
  }, [props.hideOptions]);

  function onDateRangeChange({ from, to, type }: DateRangeString) {
    // cannot change in utils/relative-date-range.ts as it's used in other places where it's required
    if (type === RelativeDateRange.MORE_THAN_30_DAYS) {
      setDateRange({ from: '', to });
    } else {
      setDateRange({ from, to });
    }
  }

  function onDownload() {
    if (props.dateInputType === DateInputType.SINGLE) {
      if (props.tabType === ARAgingExportTabType.HISTORY) {
        if (selectedDate) {
          props.onDownload(selectedExportOption, ReportType.HISTORY, selectedDate);
        }
      } else {
        if (selectedExportOption === ExportOption.ALL) {
          props.onDownload(selectedExportOption, ReportType.CURRENT, date);
        } else {
          props.onDownload(selectedExportOption, ReportType.CURRENT, getCurrentDate());
        }
      }
    }
    if (props.dateInputType === DateInputType.RANGE) {
      props.onDownload(selectedExportOption, dateRange.from, dateRange.to);
    }

    if (props.dateInputType === DateInputType.NO_DATE) {
      props.onDownload();
    }
  }

  function handleReportsOptionSelect(e: RadioChangeEvent) {
    setSelectedExportOption(e.target.value);
  }

  const DateRangeSelectJSX = (
    <>
      <label style={{ marginLeft: 'var(--space-2)' }}>{t.dateRange}</label>
      <Flex gap="--space-12">
        <DateRangeSelect
          showCustomRangeSelect
          default={{ type: RelativeDateRange.THIS_WEEK }}
          onChange={onDateRangeChange}
          format={DATE_FORMAT}
        />
        <DownloadButton
          onClick={onDownload}
          loading={props.downloading}
          style={downloadButtonStyles}
        />
      </Flex>
    </>
  );

  const DateSelectJSX = (
    <>
      <Flex gap="--space-12" justify="space-between">
        <OutstandingCustomersDateDescription />
        <DownloadButton
          onClick={onDownload}
          loading={props.downloading}
          style={downloadButtonStyles}
        />
      </Flex>
    </>
  );

  const DateSelectJSXWithoutDescription = (
    <>
      <Flex gap="--space-12" justify="space-between">
        <DownloadButton
          onClick={onDownload}
          loading={props.downloading}
          style={downloadButtonStyles}
        />
      </Flex>
    </>
  );

  function getDisabledDate(currentDate: Dayjs) {
    const todaysDate = dayjs();
    const startDate = todaysDate.subtract(LAST_30_DAYS, 'day');
    const last_30_days = currentDate.isBefore(startDate) || currentDate.isAfter(todaysDate);
    const validDates = data ? data : [];
    const isExcludedDate = !validDates.includes(currentDate.format('YYYY-MM-DD'));
    return last_30_days || isExcludedDate;
  }

  function getDateToDisplay() {
    const validDates = data ? data : [];
    if (validDates.length) {
      return validDates[validDates.length - 1];
    }
    return;
  }

  const dateToDisplay = getDateToDisplay();
  useEffect(() => {
    setDateChange(dateToDisplay);
  }, [dateToDisplay]);

  const DateSelectJSXForHistory = (
    <>
      <Flex gap="--space-12" direction="column">
        <DatePickerComponent
          currentDate={selectedDate}
          onChangeCallBack={(_, date) => setDateChange(date as string)}
          disabledDate={getDisabledDate}
          customFormat={DATE_FORMAT}
          customStyle={{ width: '100%' }}
        />
        <DownloadButton
          onClick={onDownload}
          loading={props.downloading}
          style={downloadButtonStyles}
        />
      </Flex>
    </>
  );

  const DownloadButtonComp = (
    <DownloadButton onClick={onDownload} loading={props.downloading} style={downloadButtonStyles} />
  );

  const StyledRadioGroup = styled(RadioGroup)`
    .ant-radio-wrapper-checked {
      font-weight: var(--fs-semibold);
    }
  `;

  const ReportsOptions = (
    <StyledRadioGroup
      options={ExportOptions}
      value={selectedExportOption}
      onChange={handleReportsOptionSelect}
    />
  );

  const WarningMessage = (
    <Texto style={{ background: 'var(--geekblue-1)', padding: 'var(--space-8) var(--space-16)' }}>
      {t.warningMessage}
    </Texto>
  );

  const CloseButton = (
    <Button
      icon={<FontAwesomeIcon icon={['fal', 'times']} color="var(--gray-8)" size="sm" />}
      style={{
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto',
      }}
      onClick={props.handleClose}
    />
  );

  return (
    <Flex gap="--space-12" style={{ maxWidth: '320px' }}>
      <Flex direction="column" gap="--space-12">
        <Texto
          size="20"
          color="--gray-9"
          weight="semibold"
          style={{ marginBottom: 'var(--space-20)' }}
        >
          <Flex gap="var(--space-12)">
            <FontAwesomeIcon icon={['fal', 'file-chart-line']} color="var(--volcano-4)" />
            <Texto size="16" weight="semibold">
              {props.title}
            </Texto>
            {CloseButton}
          </Flex>
        </Texto>
        {props.extra && <>{props.extra}</>}
        <Texto color="--gray-10" style={{ maxWidth: 400 }}>
          {props.description}
        </Texto>
        {!props.hideOptions && ReportsOptions}

        {props?.tabType === ARAgingExportTabType.HISTORY ? (
          <div>{DateSelectJSXForHistory}</div>
        ) : selectedExportOption === ExportOption.ALL ? (
          <div>
            {props.dateInputType === DateInputType.SINGLE
              ? props?.currentPage === CurrentPage.INVOICE
                ? DateSelectJSXWithoutDescription
                : DateSelectJSX
              : props.dateInputType === DateInputType.RANGE
              ? DateRangeSelectJSX
              : DownloadButtonComp}
          </div>
        ) : props?.currentPage === CurrentPage.INVOICE ? (
          <div>{DateSelectJSXWithoutDescription}</div>
        ) : (
          <div>{DateSelectJSX}</div>
        )}

        {props?.currentPage === CurrentPage.OUTSTANDING && WarningMessage}
      </Flex>
    </Flex>
  );
}

function OutstandingCustomersDateDescription() {
  const format = useSelector(dateFormatSelector);
  return (
    <Flex direction="column">
      <TypographyText>{`As of`}</TypographyText>
      <TypographyText strong>{`Today (${dayjs().format(format)})`}</TypographyText>
    </Flex>
  );
}
