import { BulkAction } from '@sinecycle/growcomponents';
import NoteFormModal from 'components/Common/ActivitiesForm/Note/NoteFormModal';
import { NoteFormText } from 'components/Common/ActivitiesForm/text';
import {
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { NoteFormActivityProps, NoteResponseSchema } from 'components/Common/hooks/type';
import { ActivitySchema } from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitySchema';
import useFormSubmitMutate from 'components/HigherOrderComponent/KeyActivitesContainer/hooks/useFormSubmitMutate';
import {
  ActivityMutationProps,
  MutationFunctionResponseType,
} from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
import useOpen from 'util/hooks/useOpen';

interface AddNoteProps extends BulkAction<IInvoices> {
  customerNames: string[];
  customTransformer: (
    data: Record<FormItemNames, any>,
    customProps?: CustomActivityProps
  ) => Record<string, any>;
}

export default function AddNote(props: AddNoteProps) {
  const { selectedKey, customerNames, customTransformer, onClose } = props;
  const onFormMutateFn = useFormSubmitMutate();
  const dateFormat = useSelector(dateFormatSelector);

  const { onClose: closeModal, open } = useOpen({
    open: Boolean(selectedKey === 'NOTE'),
  });

  function onMutateFn(data: MutationFunctionProps<ActivityMutationProps>) {
    return onFormMutateFn(data);
  }

  function onModalClose() {
    onClose?.();
    closeModal();
  }

  return (
    <NoteFormModal<NoteFormActivityProps>
      openModal={open}
      onClose={onModalClose}
      customerNames={customerNames}
      onMutateFn={onMutateFn as MutationFunctionResponseType<NoteResponseSchema>}
      bulkRecordsLength={customerNames?.length}
      customTransformer={customTransformer}
      text={NoteFormText}
      responseSchema={ActivitySchema.NOTE}
      dateFormat={dateFormat}
      taskRemainderResponseSchema={ActivitySchema.TASK_REMAINDER}
    />
  );
}
