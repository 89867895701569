import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { ActionTitleProps, RaiseDisputeActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function DisputeTitle(props: ActionTitleProps<RaiseDisputeActionType>) {
  const { item } = props;

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.DISPUTE.title}
      icon={actionIcons.DISPUTE.icon}
      backgroundColor={actionIcons.DISPUTE.style.backgroundColor}
      title={item?.title}
      strong={props.strong}
    />
  );
}
