import { DisputeStatus, DisputeViewsStatus } from 'components/CollectionActivites/Disputes/types';
import { CreditMemoStatus } from 'components/CustomerDetails/Body/Statements/CreditAdjustmentsStatements';
import { head, map } from 'lodash';
import { InvoiceFollowupStatus, invoiceFollowupStatusConfig } from 'store/invoice/type';
import { disputeStatusConfig } from 'types/activities/dispute';
import { EscalationType, EscalationTypeMenu } from 'types/activities/escalation';
import {
  InvoicePaymentStatus,
  invoicePaymentStatusConfig,
  PaymentStatus,
  paymentStatusConfig,
} from 'types/activities/payment';
import { PromiseToPayStatus, PTPStatusMenu } from 'types/activities/promise-to-pay';
import { TaskStatus, taskStatusConfig } from 'types/activities/task';
import { InvoiceSegmentOperandType } from 'types/entities/invoice-segments/operands';
import { DateWorkFlowOperatorTypes, WorkFlowOperatorTypes } from '../../type';
import { WorkFlowGreaterOperator } from '../EventCondition/operator';
import { WorkFlowDateOptionType } from '../type';
import { creditMemoStatusOptions } from './type';

interface defaultDateOptionProps {
  label: string;
  value: WorkFlowDateOptionType;
}

export const defaultDateOption: defaultDateOptionProps[] = [
  { label: 'Date Range', value: InvoiceSegmentOperandType.ABSOLUTE },
  { label: 'Relative Date', value: InvoiceSegmentOperandType.RELATIVE },
];

type WorkFlowOperationOptionsType = {
  label: string;
  value: WorkFlowOperatorTypes;
};
export const WorkFlowDateOperatorLabel: Record<DateWorkFlowOperatorTypes, string> = {
  BTWN: 'Between',
  GT: 'After',
  LT: 'Before',
  EQ: 'On',
};
export const WorkFlowOperationOptions: WorkFlowOperationOptionsType[] = [
  {
    label: WorkFlowDateOperatorLabel.BTWN,
    value: 'BTWN',
  },
  {
    label: WorkFlowDateOperatorLabel.LT,
    value: 'LT',
  },
  {
    label: WorkFlowDateOperatorLabel.GT,
    value: 'GT',
  },
  {
    label: WorkFlowDateOperatorLabel.EQ,
    value: 'EQ',
  },
];

const relativeGreaterthanOperator: WorkFlowGreaterOperator<number> = {
  type: 'GT',
  operands: {
    type: 'RELATIVE',
    value: { type: 'DATE', offset: 0, offset_type: 'AFTER', relative_day: 'CURRENT_DATE' },
  },
};
export const defaultRelativeDateData = {
  type: 'ISSUE_DATE',
  operator: relativeGreaterthanOperator,
  mandatory: false,
};
const PaymentStatusOption = map(PaymentStatus, (status) => ({
  value: status,
  label: paymentStatusConfig[status].label,
}));
const DisputeStatusOption = map(DisputeViewsStatus, (status) => ({
  value: status,
  label: disputeStatusConfig[status as DisputeStatus].label,
}));
const TaskStatusOption = map(TaskStatus, (status) => ({
  value: status,
  label: taskStatusConfig[status].label,
}));
const InvoiceFollowUpstatusOptions = map(
  Object.values(InvoiceFollowupStatus).filter(
    (status) =>
      status !== InvoiceFollowupStatus.CONTACT_MISSING &&
      status !== InvoiceFollowupStatus.INVALID_EMAIL_ID &&
      status !== InvoiceFollowupStatus.VIEWED_ATTACHMENT
  ),
  (status) => ({
    value: status,
    label: invoiceFollowupStatusConfig[status].label,
  })
);
const InvoicePaymentOptions = map(InvoicePaymentStatus, (status) => ({
  value: status,
  label: invoicePaymentStatusConfig[status].label,
}));

export const WorkFlowSingleStatusConfig = {
  PTP_STATUS: {
    type: PromiseToPayStatus.OPEN,
    options: PTPStatusMenu,
  },
  PAYMENT_STATUS: {
    type: PaymentStatus.FULLY_APPLIED,
    options: PaymentStatusOption,
  },
  CREDIT_MEMO_STATUS: {
    type: CreditMemoStatus.FULLY_APPLIED,
    options: creditMemoStatusOptions,
  },
  DISPUTE_STATUS: {
    type: head(DisputeViewsStatus),
    options: DisputeStatusOption,
  },
  TASK_STATUS: {
    type: TaskStatus.OPEN,
    options: TaskStatusOption,
  },
  ESCALATION_STATUS: {
    type: EscalationType.OPEN,
    options: EscalationTypeMenu,
  },
  INVOICE_FOLLOWUP_STATUS: {
    type: InvoiceFollowupStatus.EMAIL_OPENED,
    options: InvoiceFollowUpstatusOptions,
  },
  INVOICE_PAYMENT_STATUS: {
    type: InvoicePaymentStatus.PENDING,
    options: InvoicePaymentOptions,
  },
};
