import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SortOrder, StatusCellConfig } from '@sinecycle/growcomponents';
import { ApprovalType } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/types';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { RequestConfig } from 'types/common/common';
import {
  EventSnapshotAttachments,
  EventSnapshotAttachmentType,
  SnapShotEvent,
} from 'types/common/events';
import { AccountDetails } from 'types/entities/account';
import { User } from 'types/entities/user';

const approvalStatus = ['APPROVED', 'REJECTED', 'PENDING'] as const;
export type UserApprovalStatus = (typeof approvalStatus)[number];
export const userApprovalStatusConfig: Record<
  UserApprovalStatus,
  { icon: IconProp; statusConfig: StatusCellConfig }
> = {
  APPROVED: {
    icon: ['far', 'thumbs-up'],
    statusConfig: {
      label: 'Approved',
      style: {
        backgroundColor: 'var(--green-1)',
        textColor: 'var(--green-8)',
      },
    },
  },
  PENDING: {
    icon: ['far', 'exclamation-circle'],
    statusConfig: {
      label: 'Pending',
      style: {
        backgroundColor: 'var(--orange-1)',
        textColor: 'var(--orange-8)',
      },
    },
  },
  REJECTED: {
    icon: ['far', 'thumbs-down'],
    statusConfig: {
      label: 'Rejected',
      style: {
        backgroundColor: 'var(--red-1)',
        textColor: 'var(--red-7)',
      },
    },
  },
};

export function getUserApprovalStatusConfig(status?: UserApprovalStatus) {
  if (!status) return;
  return userApprovalStatusConfig[status];
}
export interface ApprovalRequestFilter {
  user_approval_status_list: Array<UserApprovalStatus>;
  assignee_list: Array<number>;
  created_by_workflow_id: Array<number>;
  sort_col: string;
  order: SortOrder;
}
export interface ApprovalRequestLV {
  id: number;
  account_id: number;
  type: ApprovalType;
  entity_id: number;
  created_at?: string;
  updated_at?: string;
  entity_type: EVENT_TRIGGER_CONDITION_KEYS;
  created_by_workflow?: WorkflowBasicDetail;
}

export interface ApprovalRequestReport {
  list: Array<UserApprovalRequestMapLV>;
  current_page: number;
  total_pages: number;
  total_records: number;
}

export interface UserApprovalRequestMapLV {
  id: number;
  account_id: number;
  user_id: number;
  status: UserApprovalStatus;
  approval_request: ApprovalRequestLV;
  created_at?: string;
  updated_at?: string;
  comment?: string;
  approved_by_user_id?: number;
}
export interface UserApprovalRequestMapLVAction extends UserApprovalRequestMapLV {
  user?: User;
  approved_by_user?: User;
}

export interface ApprovalRequestConfig extends RequestConfig<ApprovalRequestFilter> {}

export interface WorkflowBasicDetail {
  id: number;
  title: string;
  enabled: boolean;
  owner_id: number;
}

export interface UserApprovalRequestMapDV {
  id: number;
  account_id: number;
  comment?: string;
  user_id: number;
  status: UserApprovalStatus;
  created_at?: string;
  updated_at?: string;
  approval_request: ApprovalRequestDV;
  approved_by_user_id?: number;
  can_respond?: boolean;
}

export interface ApprovalRequestDV {
  id: number;
  account_id: number;
  type: ApprovalType;
  entity_type: EVENT_TRIGGER_CONDITION_KEYS;
  entity_id: number;
  created_at?: string;
  updated_at?: string;
  status: UserApprovalStatus;
  entity_snapshot?: SnapShotEvent;
  created_by_workflow?: WorkflowBasicDetail;
  attachments?: EventSnapshotAttachments<EventSnapshotAttachmentType>;
}

export interface UserApprovalRequestMapAnon {
  id: number;
  created_at?: string;
  updated_at?: string;
  approval_request: ApprovalRequestDV;
  status: UserApprovalStatus;
  account: AccountDetails;
  user: User;
  current_user: User;
}

export interface UserApprovalRequestUpdate {
  comment?: string;
  status: UserApprovalStatus;
}
