import { WorkFlowEventTypes } from 'types/entities/workflow';

export const WorkFlowDateFormat = 'DD MMM YYYY';

export interface WorkFlowStatusConfig {
  label: string;
  value: EVENT_TRIGGER_CONDITION_KEYS;
  style: React.CSSProperties;
  icon: React.ReactNode;
  eventTypes: WorkFlowEventTypes[];
}

export const EntityTypes = [
  'INVOICE',
  'CUSTOMER',
  // 'PAYMENT',
  // 'CREDIT_MEMO',
  // 'DISPUTE',
  // 'TASK',
  // 'PTP',
  // 'ESCALATION',
] as const;
export type EVENT_TRIGGER_CONDITION_KEYS = (typeof EntityTypes)[number];

export type EventEntityTypesConfigType = Record<EVENT_TRIGGER_CONDITION_KEYS, WorkFlowEventTypes[]>;

export const EventEntityTypesConfig: EventEntityTypesConfigType = {
  INVOICE: [
    'DUE_DAYS',
    'INVOICE_STATUS',
    'ISSUE_DATE',
    'INVOICE_DUE_DATE',
    'INVOICE_CREATE',
    'INVOICE_VALUE',
    'INVOICE_BALANCE',
    'INVOICE_CUSTOM_FIELD',
    'INVOICE_SUBSIDIARY',
    'INVOICE_UPDATE',
    'INVOICE_FOLLOWUP_STATUS',
    'OVERDUE_DAYS',
    'INVOICE_PAYMENT_STATUS',
    'INVOICE_BALANCE_DUE_TX',
    'INVOICE_DUE',
    'INVOICE_IS_OVERDUE',
    'INVOICE_OVERDUE',
    'INVOICE_TAX',
    'INVOICE_IS_DUE',
    'INVOICE_IS_OVERDUE',
  ],
  // PAYMENT: ['PAYEMENT_CREATE', 'PAYMENT_BALANCE', 'PAYMENT_STATUS'],
  // CREDIT_MEMO: ['CREDIT_MEMO_BALANCE', 'CREDIT_MEMO_CREATED', 'CREDIT_MEMO_STATUS'],
  // DISPUTE: ['DISPUTE_STATUS', 'DISPUTE_TYPE', 'DUE_DATE', 'DISPUTE_ASSIGNEE'],
  // TASK: ['TASK_STATUS', 'DUE_DATE', 'TASK_ASSIGNEE'],
  // PTP: ['PTP_DATE', 'PTP_STATUS', 'PTP_AMOUNT'],
  // ESCALATION: ['ESCALATION_STATUS', 'DUE_DATE', 'ESCALATION_ASSIGNEE'],
  CUSTOMER: [
    'PRIMARY_CONTACT',
    'CREDIT_TERM',
    'SECONDARY_CONTACT',
    'COLLECTION_OWNER',
    'OUTSTANDING_VALUE',
    'OPEN_INVOICE_COUNT',
    'CUSTOMER_CUSTOM_FIELD',
    'CUSTOMER_SUBSIDIARY',
  ],
  // PAYMENT: ['PAYEMENT_CREATE', 'PAYMENT_BALANCE', 'PAYMENT_STATUS'],
  // CREDIT_MEMO: ['CREDIT_MEMO_BALANCE', 'CREDIT_MEMO_CREATED', 'CREDIT_MEMO_STATUS'],
};

export const customWorkFlowStatus = [
  'INVOICE',
  // 'DISPUTE',
  // 'TASK',
  // 'PAYMENT',
  // 'CREDIT_MEMO',
  // 'ESCALATION',
  'CUSTOMER',
  // 'PTP',
] as const;
export type WorkFlowStatus = (typeof customWorkFlowStatus)[number];
