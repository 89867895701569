import MultiSelectFilterCondition from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Common/CommonConditionFilter/MultiSelectFilter/MultiSelectFilterCondition';
import { WorkFlowFilterOperator } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/EventCondition/type';
import { useGetCreditTerms } from 'queries/Customers/customers';
import ConditionFilterFieldAndDisplay from './CommonConditionFilter/ConditionFilterFieldAndDisplay';
import { findCustomAndContactOptionMatches } from './CommonConditionFilter/MultiSelectFilterField';

interface CollectionOwnerFieldProps {
  type: 'DISPLAY' | 'FILTER';
  value?: WorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
  selectedValue?: string[];
}
export default function CreditTermFilter(props: CollectionOwnerFieldProps) {
  const { data: creditTermsData, isLoading } = useGetCreditTerms();

  const matchedCustomValueOptions = findCustomAndContactOptionMatches(
    'CREDIT_TERM',
    creditTermsData,
    props.selectedValue
  );
  const FilterComponent = (
    <>
      {creditTermsData && (
        <MultiSelectFilterCondition
          conditionType="CREDIT_TERM"
          value={props.value}
          onChange={props.onChange}
          data={creditTermsData}
        />
      )}
    </>
  );

  const DisplayComponent = (
    <>
      {matchedCustomValueOptions?.map((value, index) => (
        <>
          {value} {index === matchedCustomValueOptions?.length - 1 ? '' : ', '}
        </>
      ))}
    </>
  );

  return (
    <ConditionFilterFieldAndDisplay
      type={props.type}
      filterCompoenent={FilterComponent}
      displayComponent={DisplayComponent}
      loading={isLoading}
    />
  );
}
