import { useQueryClient } from '@tanstack/react-query';
import { FormInstance } from 'antd';
import { chain } from 'lodash';
import { useParams } from 'react-router-dom';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { ActionActivityTypes } from '../types';

/**
 *
 * @param form
 * @param type
 * @returns new Map<number, boolean>
 *
 * This hooks extract the custom field ids currently use in the workflow action.
 */
export default function useExtractCustomFieldIds(
  form?: FormInstance,
  type?: ActionActivityTypes.CUSTOMER_CUSTOM_FIELD | ActionActivityTypes.INVOICE_CUSTOM_FIELD
) {
  const { workflow_id } = useParams();
  const queryClient = useQueryClient();

  const workflowMeta: WorkflowMetaDto = workflow_id
    ? queryClient.getQueryData<WorkflowMetaDto>(['workflow-meta', workflow_id])
    : form?.getFieldValue([]);

  return chain(workflowMeta?.workflow_rules)
    .flatMap('actions')
    .map('action')
    .filter({ type })
    .map('field_id')
    .compact()
    .reduce((map, fieldId) => map.set(fieldId, fieldId), new Map<number, boolean>())
    .value();
}
