import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Escalation } from 'types/activities/escalation';
import { EsclationDetailsLayout } from './EsclationDetailsLayout';

interface EscalationDetailsProps extends ActivityDetailsBaseProps {}
function EscalationDetails(props: EscalationDetailsProps) {
  const { id, type, isListFetched, activitiesMode } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Escalation>({
    id,
    type,
    isListFetched,
  });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <EsclationDetailsLayout
      isUnauthorized={isUnauthorized}
      isLoading={isLoading}
      data={data}
      {...props}
    />
  );
}

export { EscalationDetails };
