import { CalendarEventType } from 'components/Common/CalenderEvent/type';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { WorkFlowConditionsConfig } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { isNumber } from 'lodash';
import { CustomField, CustomFieldUIType } from 'types/entities/custom-field';
import {
  BetweenAbsoluteOperands,
  DefaultAbsoluteOperand,
  EqualToAbsoluteOperands,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import { WorkFlowConditions, WorkFlowRule } from '../../type';
import { getCustomFieldInitialValue } from '../Filters/CustomFieldFilter/customFieldHooks';
import { WorkFlowTriggerFormResponse } from '../type';
import {
  CustomFieldDefaultDateData,
  defaultCustomerAnyChgAmountData,
  defaultDateData,
  defaultDeliveryFlagData,
  defaultEqOperatorData,
  defaultInPeriodOperatorData,
  defaultInvoiceFollowupStatusData,
  defaultInvoicePaymentStatusData,
  defaultInvoicePdfData,
  defaultInvoiceStatusData,
  defaultStatusData,
  greaterThanDefaultData,
} from './initial-state';
import { BaseWorkFlowFilter, WorkFlowCallBackDataProps, WorkFlowFilterOperator } from './type';

function formatCondition(condition: BaseWorkFlowFilter, entityType: EVENT_TRIGGER_CONDITION_KEYS) {
  const { type, operator } = condition;

  if (isNumber(type)) {
    condition.type = entityType === 'INVOICE' ? 'INVOICE_CUSTOM_FIELD' : 'CUSTOMER_CUSTOM_FIELD';
    condition.id = type;
  } else if (type === 'INVOICE_DUE_DATE' || type === 'ISSUE_DATE') {
    if (operator.operands.type === 'ABSOLUTE') {
      if (operator.type === 'BTWN') {
        const dateOperand = operator.operands as BetweenAbsoluteOperands<Dayjs | string>;
        dateOperand.value_holder.from.value = workFlowFormatDate(
          dateOperand.value_holder.from.value as dayjs.Dayjs
        );
        dateOperand.value_holder.to.value = workFlowFormatDate(
          dateOperand.value_holder.to.value as dayjs.Dayjs
        );
      } else if (operator.type === 'EQ') {
        const dateOperand = operator.operands as DefaultAbsoluteOperand<Dayjs | string>;
        dateOperand.value_holder.value = workFlowFormatDate(
          dateOperand.value_holder.value as dayjs.Dayjs
        ) as string;
      } else {
        const dateOperand = operator.operands as DefaultAbsoluteOperand<Dayjs | string>;
        dateOperand.value_holder.value = workFlowFormatDate(
          dateOperand.value_holder.value as dayjs.Dayjs
        ) as string;
      }
    }
  }
}
export function workFlowFormatDate(value: dayjs.Dayjs) {
  return getCustomFormattedDate(value, 'YYYY-MM-DD');
}

export function periodicReceipient(type?: CalendarEventType, days?: number[]) {
  if (type === CalendarEventType.DAILY) {
    return produce(defaultEqOperatorData, (draft) => {
      const eqOperand = draft.operands as EqualToAbsoluteOperands<boolean>;
      eqOperand.value_holder.value = true;
    });
  }

  return produce(defaultInPeriodOperatorData, (draft) => {
    if (draft.operands && days) {
      const eqOperand = draft.operands as InAbsoluteOperands<number>;
      const daysValue = days.map((day) => {
        return { value: day };
      });
      eqOperand.value_holder = daysValue;
    }
  });
}

export type EventResponseReturnType = ReturnType<typeof handleEventResponse>;
export type PeriodicResponseReturnType = ReturnType<typeof handlePeriodicResponse>;
export type HandleValidatedFieldsReturnType = EventResponseReturnType | PeriodicResponseReturnType;

export function handleValidatedFields(
  response: WorkFlowTriggerFormResponse,
  conditionId?: number
): Omit<WorkFlowRule, 'actions' | 'id'> | undefined {
  if (response.trigger_type === 'LIVE') {
    return handleEventResponse(response, conditionId);
  } else if (response.trigger_type === 'PERIODIC') {
    return handlePeriodicResponse(response, conditionId);
  }
}

function handleEventResponse(
  eventResponse: WorkFlowTriggerFormResponse,
  conditionId?: number
): Omit<WorkFlowRule, 'actions' | 'id'> {
  let triggerConditionMeta: BaseWorkFlowFilter[] = [];

  if (
    eventResponse.selected_event !== 'INVOICE_CREATE' &&
    eventResponse.selected_event !== 'INVOICE_UPDATE' &&
    !isNumber(eventResponse.selected_event)
  ) {
    const conditionMeta: BaseWorkFlowFilter = {
      type: eventResponse.selected_event as WorkFlowEventTypes,
      operator: eventResponse.mandatory_operator,
      mandatory: true,
    };

    const formattedConditionList = produce(conditionMeta, (draft) => {
      return draft;
    });
    triggerConditionMeta.push(formattedConditionList);
  }
  if (isNumber(eventResponse.selected_event)) {
    const conditionMeta: BaseWorkFlowFilter = {
      type: eventResponse.entity === 'INVOICE' ? 'INVOICE_CUSTOM_FIELD' : 'CUSTOMER_CUSTOM_FIELD',
      operator: eventResponse.mandatory_operator,
      mandatory: true,
      id: eventResponse.selected_event,
    };
    const formattedConditionList = produce(conditionMeta, (draft) => {
      return draft;
    });
    triggerConditionMeta.push(formattedConditionList);
  }
  if (eventResponse.condition_list) {
    const formattedConditionList = produce(eventResponse.condition_list, (draft) => {
      draft.forEach((item) => formatCondition(item, eventResponse.entity));
    });
    triggerConditionMeta = [
      ...triggerConditionMeta,
      ...(formattedConditionList as BaseWorkFlowFilter[]),
    ];
  }

  const mandatoryConditions: WorkFlowConditions = {
    id: conditionId,
    operator: eventResponse.followup_operator ?? 'AND',
    conditions: triggerConditionMeta,
    type: WorkFlowConditionsConfig[eventResponse.entity],
  };

  return {
    trigger_type: eventResponse.trigger_type,
    entity_type: eventResponse.entity,
    event_type: isNumber(eventResponse.selected_event)
      ? eventResponse.entity === 'INVOICE'
        ? 'INVOICE_CUSTOM_FIELD'
        : 'CUSTOMER_CUSTOM_FIELD'
      : eventResponse.selected_event,
    condition: mandatoryConditions,
  };
}

function handlePeriodicResponse(
  periodicResponse: WorkFlowTriggerFormResponse,
  conditionId?: number
): Omit<WorkFlowRule, 'actions' | 'id'> {
  const generatedCondition: WorkFlowFilterOperator<unknown> = periodicReceipient(
    periodicResponse?.periodic_conditions?.type,
    periodicResponse?.periodic_conditions?.days
  );

  const conditions = {
    type: periodicResponse?.periodic_conditions?.type,
    operator: generatedCondition,
  };

  let mergedArray: BaseWorkFlowFilter[] = [];

  if (conditions.type) {
    mergedArray.push({
      type: conditions.type,
      operator: conditions.operator,
    });
  }

  if (periodicResponse.condition_list) {
    mergedArray = mergedArray.concat(periodicResponse.condition_list.map((user) => user));
  }

  const mandatoryConditions: WorkFlowConditions = {
    id: conditionId,
    operator: periodicResponse.followup_operator ?? 'AND',
    conditions: mergedArray,
  };

  return {
    trigger_type: periodicResponse.trigger_type,
    entity_type: periodicResponse.entity,
    condition: mandatoryConditions,
  };
}

export function getDefaultFilterDataFromType(
  type?: WorkFlowEventTypes | number,
  customFields?: CustomField[]
): WorkFlowCallBackDataProps | undefined {
  const customFieldId = customFields?.find((filed) => filed.id === type);
  const matchedCustomField = customFields?.find((field) => field?.id === customFieldId?.id);
  switch (matchedCustomField?.ui_type) {
    case CustomFieldUIType.DATEPICKER:
      return CustomFieldDefaultDateData;
    case CustomFieldUIType.DROPDOWN:
    case CustomFieldUIType.TEXT_AREA:
    case CustomFieldUIType.TEXT_BOX:
    case CustomFieldUIType.NONE:
      const value = getCustomFieldInitialValue();
      return value;
  }

  if (!type) return;
  switch (type) {
    case 'INVOICE_VALUE':
    case 'DUE_DAYS':
    case 'INVOICE_BALANCE':
    case 'PAYMENT_BALANCE':
    case 'CREDIT_MEMO_BALANCE':
    case 'PTP_AMOUNT':
    case 'OUTSTANDING_VALUE':
    case 'OPEN_INVOICE_COUNT':
    case 'OVERDUE_DAYS':
    case 'INVOICE_BALANCE_DUE_TX':
    case 'INVOICE_DUE':
    case 'INVOICE_OVERDUE':
    case 'INVOICE_TAX':
      return greaterThanDefaultData;
    case 'INVOICE_STATUS':
      return defaultInvoiceStatusData;
    case 'ISSUE_DATE':
    case 'INVOICE_DUE_DATE':
      return defaultDateData;
    case 'DELIVERY_FLAG':
      return defaultDeliveryFlagData;
    case 'INVOICE_PDF':
    case 'INVOICE_IS_DUE':
    case 'INVOICE_IS_OVERDUE':
      return defaultInvoicePdfData;
    case 'PAYMENT_STATUS':
    case 'CREDIT_MEMO_STATUS':
    case 'DISPUTE_STATUS':
    case 'DISPUTE_ASSIGNEE':
    case 'DISPUTE_TYPE':
    case 'CUSTOMER_SUBSIDIARY':
    case 'INVOICE_SUBSIDIARY':
      return defaultStatusData;
    case 'PRIMARY_CONTACT':
    case 'COLLECTION_OWNER':
    case 'SECONDARY_CONTACT':
    case 'CREDIT_TERM':
      return defaultCustomerAnyChgAmountData;
    case 'INVOICE_FOLLOWUP_STATUS':
      return defaultInvoiceFollowupStatusData;
    case 'INVOICE_PAYMENT_STATUS':
      return defaultInvoicePaymentStatusData;
  }
}
