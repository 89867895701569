import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { ActionTitleProps, NoteActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function CallLogTitle(props: ActionTitleProps<NoteActionType>) {
  const { item } = props;

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.CALL_LOG.title}
      icon={actionIcons.CALL_LOG.icon}
      backgroundColor={actionIcons.CALL_LOG.style.backgroundColor}
      title={item.notes}
      strong={props.strong}
    />
  );
}
