import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { isEmpty } from 'lodash';
import { PlaceholderProps } from 'types/entities/email-templates';
import { StyledPlaceholder } from './style';

interface OptionItemProps {
  key: number;
  placeholder: PlaceholderProps;
  onPlaceholderInsertCallback: (val: string) => void;
  viewOnly?: boolean;
}

const t = {
  insert: 'Insert',
};

export function OptionItem(props: OptionItemProps) {
  const { description, label, section } = props.placeholder;
  const tooltipTitle = props.viewOnly ? (!isEmpty(description) ? description : label) : t.insert;

  const InfoIcon = (
    <FontAwesomeIcon
      className="cursor-pointer"
      icon={['far', 'info-circle']}
      color="var(--gray-7)"
    />
  );

  const InsertIcon = (
    <FontAwesomeIcon
      className="placeholder-icon add-icon"
      icon={['far', 'plus']}
      color="var(--gray-6)"
    />
  );

  const IconWithTooltip = (
    <Tooltip
      align={{ offset: [-18, 7] }}
      className="placeholder-icon"
      placement="topLeft"
      title={tooltipTitle}
    >
      {props.viewOnly ? InfoIcon : InsertIcon}
    </Tooltip>
  );

  const Label = (
    <StyledPlaceholder
      justify="space-between"
      align="center"
      onClick={() =>
        !props.viewOnly && props.onPlaceholderInsertCallback(props.placeholder.placeholder)
      }
      className={props.viewOnly ? '' : 'hover-styles'}
    >
      <CustomFieldIconWithText hasSymbol={section === 'Custom Fields'}>
        <TypographyText className="label">{props.placeholder.label}</TypographyText>
      </CustomFieldIconWithText>
      {IconWithTooltip}
    </StyledPlaceholder>
  );

  return Label;
}
