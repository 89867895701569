import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowFlex, GrowTypography, StatusTag } from '@sinecycle/growcomponents';
import { InfoIconRegular } from 'components/Common/Icons/Icons';
import { getUserApprovalStatusConfig, UserApprovalStatus } from 'pages/Approvals/type';
import { User } from 'types/entities/user';
import { OnBehalf } from './OnBehalf';
import StatusPopover from './StatusPopover';

interface ApprovalStatusProps {
  status?: UserApprovalStatus;
  approvedUser?: User;
  comment?: string;
  actionTake?: boolean;
  assignedUser?: User;
  commentMode?: 'STATUS' | 'INFO';
}

export default function ApprovalStatus(props: ApprovalStatusProps) {
  const { status, comment, actionTake, approvedUser, assignedUser, commentMode = 'STATUS' } = props;
  const config = getUserApprovalStatusConfig(status);

  const tagStyles: React.ComponentProps<typeof StatusTag>['style'] = {
    borderColor: '',
    backgroundColor: config?.statusConfig.style.backgroundColor ?? '',
    textColor: config?.statusConfig.style.textColor ?? '',
  };
  const Text = (
    <>
      {config ? (
        <div className="tw-flex tw-items-center tw-gap-4">
          <FontAwesomeIcon icon={config?.icon} color={config?.statusConfig.style.textColor} />
          {config?.statusConfig.label}
        </div>
      ) : null}
    </>
  );
  const Title = (
    <GrowFlex align="center" space="8">
      <div>
        {config ? (
          <FontAwesomeIcon icon={config?.icon} color={config?.statusConfig.style.textColor} />
        ) : null}
      </div>
      <GrowTypography.Text fs="12">{`${approvedUser?.first_name ?? ''} ${
        approvedUser?.last_name ?? ''
      }`}</GrowTypography.Text>
    </GrowFlex>
  );
  const Content = <GrowTypography.Text fs="12">{comment}</GrowTypography.Text>;

  const Onbehalf = <OnBehalf assignedUser={assignedUser} />;

  const PopoverTitle = comment ? Title : undefined;
  const PopoverContent = comment ? (
    <GrowFlex vertical space="8" style={{ lineHeight: 'normal' }}>
      {Content}
      {actionTake ? Onbehalf : null}
    </GrowFlex>
  ) : undefined;
  const StatusMode = (
    <StatusPopover title={PopoverTitle} content={PopoverContent}>
      {config ? <StatusTag text={Text} style={tagStyles} /> : '-'}
    </StatusPopover>
  );

  const InfoMode = (
    <GrowFlex space="8" align="center">
      {config ? <StatusTag text={Text} style={tagStyles} /> : '-'}
      {comment ? (
        <StatusPopover title={PopoverTitle} content={PopoverContent}>
          <InfoIconRegular />
        </StatusPopover>
      ) : null}
    </GrowFlex>
  );
  return commentMode === 'STATUS' ? StatusMode : InfoMode;
}
