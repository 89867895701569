import { FormName } from 'components/Common/AssociatedFiles/CustomerStatement/Statements';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { FileType } from 'types/common/attachments';
import {
  CustomerStatement,
  StatementPeriodType,
  StatementPeriods,
  StatementTypes,
} from 'types/customerstatement/customer-statement';

const defaultStatement: CustomerStatement = {
  file_type: FileType.PDF,
  period: StatementPeriods.TODAY,
  period_type: 'RELATIVE',
  custom_date: null,
  custom_range: null,
  attachment_type: StatementTypes.OUTSTANDING_TRANSACTIONS,
};

const RelativeStatement: CustomerStatement = {
  file_type: FileType.PDF,
  period: StatementPeriods.TODAY,
  period_type: 'RELATIVE',
  custom_date: null,
  custom_range: null,
  attachment_type: StatementTypes.OUTSTANDING_TRANSACTIONS,
};
const AbsoluteStatement: CustomerStatement = {
  file_type: FileType.PDF,
  period: StatementPeriods.CUSTOM_DATE,
  period_type: 'ABSOLUTE',
  custom_date: dayjs().format(),
  custom_range: null,
  attachment_type: StatementTypes.OUTSTANDING_TRANSACTIONS,
};
type Actions =
  | { type: FormName; statement: CustomerStatement }
  | { type: 'Template_Upload'; statement: CustomerStatement }
  | { type: 'Update_Type'; period_type: StatementPeriodType }
  | { type: 'Reset'; period_type: StatementPeriodType };

const DATE_FORMAT = 'YYYY-MM-DD';
function customerStatementReducer(state: CustomerStatement, action: Actions) {
  switch (action.type) {
    case FormName.ATTACHMENT_TYPE:
      return produce(state, (draft) => {
        draft.attachment_type = action.statement.attachment_type
          ? StatementTypes.OPEN_TRANSACTION_STATEMENT_CONSOLIDATED
          : StatementTypes.OUTSTANDING_TRANSACTIONS;
        return draft;
      });
    case FormName.FILE_FORMAT:
      return produce(state, (draft) => {
        draft.file_type = action.statement.file_type;
        return draft;
      });
    case FormName.PERIOD:
      return produce(state, (draft) => {
        if (state.attachment_type === StatementTypes.OUTSTANDING_TRANSACTIONS) {
          draft.period = action.statement.custom_date
            ? StatementPeriods.CUSTOM_DATE
            : action.statement.period;
          draft.custom_date = action.statement.custom_date
            ? dayjs(action.statement.custom_date).format(DATE_FORMAT)
            : null;
          draft.custom_range = null;
        }
        if (state.attachment_type === StatementTypes.ALL_TRANSACTIONS) {
          draft.period = action.statement.custom_range
            ? StatementPeriods.CUSTOM_RANGE
            : action.statement.period;
          draft.custom_date = null;
          draft.custom_range = action.statement.custom_range?.map((range) =>
            dayjs(range).format(DATE_FORMAT)
          ) as unknown as [Date, Date];
        }
        return draft;
      });

    case 'Template_Upload':
      return produce(state, (draft) => {
        draft = action.statement;
        return draft;
      });
    case 'Update_Type':
      return produce(state, (draft) => {
        draft.period_type = action.period_type;
        return draft;
      });
    case 'Reset':
      return produce(state, (draft) => {
        return action.period_type === 'RELATIVE'
          ? (draft = RelativeStatement)
          : action.period_type === 'ABSOLUTE'
          ? (draft = AbsoluteStatement)
          : draft;
      });

    default:
      return produce(state, (draft) => {
        draft.custom_range = null;
        draft.custom_range = null;
        return draft;
      });
  }
}

function useCustomerStatement(periodType: StatementPeriodType, statement?: CustomerStatement) {
  const [enable, setEnable] = useState(false);
  const [statements, dispatchStatementUpdate] = useReducer(
    customerStatementReducer,
    initializeValues()
  );

  function initializeValues() {
    return periodType === 'RELATIVE'
      ? RelativeStatement
      : periodType === 'ABSOLUTE'
      ? AbsoluteStatement
      : defaultStatement;
  }

  const memoisedStatement = useMemo(() => statement, [statement]);
  function handleEnable(status: boolean) {
    setEnable(status);
  }
  function handleRemove() {
    setEnable(false);
    dispatchStatementUpdate({ type: 'Reset', period_type: periodType });
  }

  function handleFormChange(formName: FormName, statement: CustomerStatement) {
    dispatchStatementUpdate({ type: formName, statement: statement });
  }

  function handleUpdatePeriodType(periodType: StatementPeriodType) {
    dispatchStatementUpdate({ type: 'Update_Type', period_type: periodType });
  }

  const updatePeriodType = useCallback(handleUpdatePeriodType, []);

  function handleStatementEffect() {
    if (!memoisedStatement) {
      setEnable(false);
      return;
    }
    setEnable(true);

    dispatchStatementUpdate({ type: 'Template_Upload', statement: memoisedStatement });
  }

  function handleTemplateUpload(statement?: CustomerStatement) {
    if (!statement) {
      setEnable(false);
      return;
    }
    setEnable(true);

    dispatchStatementUpdate({ type: 'Template_Upload', statement });
  }

  useEffect(handleStatementEffect, [memoisedStatement]);
  return {
    enable,
    handleEnable,
    handleRemove,
    statements,
    handleFormChange,
    updatePeriodType,
    handleTemplateUpload,
  };
}

export default useCustomerStatement;
