import { GenericSelectOption } from 'components/Common/FilterComponents/types';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { uniq } from 'lodash';
import { ActivityType } from 'types/activities/activity-types';
import { ActivitiesStatusParams, ActivityStatusConfig } from 'types/api/inbox/activity';
import { AccountConfigKey } from 'types/entities/account';
import { SubscriptionTypeList, WriteOffOption } from './predicate';
/*original: [
  "parent:DISPUTE - child:OPEN",
  "parent:DISPUTE - child:WAITING_ON_CUSTOMER",
  "parent:TASK - child:OPEN",
  "parent:ESCALATION - child:OPEN",
  "parent:DISPUTE - child:CLOSED",
  "DISPUTE",
  "parent:TASK - child:CLOSED",
  "TASK",
  "parent:ESCALATION - child:CLOSED",
  "ESCALATION"
]

From above selected values we need two things for backend request
a. {
	"entity_types": [
		"DISPUTE",
		"TASK",
		"ESCALATION"
	]
}

b.{"dispute_status":["OPEN","WAITING_ON_CUSTOMER","CLOSED"],"task_filter":["UPCOMING","DUE_TODAY","OVERDUE"],"escalation_status":["OPEN","CLOSED"]}

1.getEntityTypesTransformer 
this will gives us the a. result

2.getActivityStatusTransformer 
this will gives us the b. result based on the config we pass eg:activityStatusConfig.


3.getStatusConfigToValueTransformer
this is for future purpose we need to convert b. to original result.
*/

export function getEntityTypesTransformer(inputArray: string[]) {
  const pattern = /^parent:.+ - child:.+$/;
  const values: { parent: string[]; child: string[] } = { parent: [], child: [] };

  const defaultParent = inputArray.filter((str) => !pattern.test(str));

  inputArray
    .filter((str) => pattern.test(str))
    .forEach((f) => {
      const parts = f.split(' - ');
      parts.forEach((part) => {
        const [key, value] = part.split(':');
        if (key === 'parent') {
          values.parent.push(value);
        }
      });
    });

  return { parent: uniq([...values.parent, ...defaultParent]) };
}

export function getActivityStatusTransformer(
  statusConfig: Partial<ActivityStatusConfig>,
  statusValues: string[]
) {
  const result: ActivitiesStatusParams = {};
  const pattern = /^parent:.+ - child:.+$/;
  const status = statusValues.filter((str) => pattern.test(str));
  status.forEach((value) => {
    const [parent, child] = value.split(' - ');
    const statusType = parent.split(':')[1].trim() as ActivityType;
    const status = child.split(':')[1].trim();

    if (!statusConfig[statusType]) {
      return;
    }
    const configKey = statusConfig[statusType] as keyof ActivitiesStatusParams;

    if (!result[configKey]) {
      result[configKey] = [];
    }

    (result[configKey] as string[])?.push(status) || ((result[configKey] as string[]) = [status]);
  });
  return result;
}
//this can be needed in future
//  function getStatusConfigToValueTransformer(
//   statusConfig: Partial<ActivityStatusConfig>,
//   filter: ActivitiesStatusParams
// ) {
//   const statusArray: string[] = [];

//   Object.entries(filter).forEach(([statusType, statuses]) => {
//     const statusKey = Object.keys(statusConfig).find(
//       (key) => statusConfig[key as unknown as ActivityType] === statusType
//     );

//     if (statusKey) {
//       statuses.forEach((status: string) => {
//         const statusString = `parent:${statusKey} - child:${status}`;
//         statusArray.push(statusString);
//       });
//     }
//   });

//   return statusArray;
// }

export function useGetInboxSubscribedInvoiceStatuses(): GenericSelectOption<
  ActivityType,
  'children'
>[] {
  const showWriteOff = useConfig(AccountConfigKey.SHOW_WRITEOFF);
  return showWriteOff ? [...SubscriptionTypeList, ...WriteOffOption] : SubscriptionTypeList;
}
