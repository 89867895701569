import { BaseCustomer } from 'types/entities/customer';
import { InvoiceStatus } from 'types/entities/invoice';
import { SavedFilterViewInterface, TFilterViews } from '../aging/types';
import { IInvoicesQueryParams as InvoicesQueryParams } from '../customer/types';

export interface OpenInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  followup_status: InvoiceFollowupStatus[];
  due_days_min: number;
  due_days_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  last_activity_from: string;
  last_activity_to: string;
  last_activity_range: string;
  next_followup_from: string;
  next_followup_to: string;
  next_followup_range: string;
  promise_pay_from: string;
  promise_pay_to: string;
  promise_pay_range: string;
  filter_type: 'OPEN' | 'CLOSED';
}

export interface DraftInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  followup_status: InvoiceFollowupStatus[];
  due_days_min: number;
  due_days_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  last_activity_from: string;
  last_activity_to: string;
  last_activity_range: string;
  next_followup_from: string;
  next_followup_to: string;
  next_followup_range: string;
  promise_pay_from: string;
  promise_pay_to: string;
  promise_pay_range: string;
  filter_type: 'OPEN' | 'CLOSED' | 'DRAFT';
}

export interface ClosedInvoicesListFilters extends InvoicesQueryParams {
  amount_min: number;
  amount_max: number;
  issue_date_from: string;
  issue_date_to: string;
  issue_date_range: string;
  closed_date_from: string;
  closed_date_to: string;
  closed_date_range: string;
  filter_type: 'OPEN' | 'CLOSED';
}

export interface InvoiceListProps {
  filterViews: SavedFilterViewInterface<TFilterViews>;
  shouldTransitionToInvoiceDetails: boolean;
}

export const OpenInvoiceStatusDropDownList = [
  {
    label: 'Payment Pending',
    value: InvoiceStatus.PENDING,
  },
  {
    label: 'In Dispute',
    value: InvoiceStatus.DISPUTE,
  },
  {
    label: 'Partially Paid',
    value: InvoiceStatus.PARTIAL_PAYMENT,
  },
  {
    label: 'Fully Paid',
    value: InvoiceStatus.FULL_PAYMENT,
  },
];

export enum InvoiceFollowupStatus {
  CONTACT_MISSING = 'CONTACT_MISSING',
  INVALID_EMAIL_ID = 'INVALID_EMAIL_ID',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_OPENED = 'EMAIL_OPENED',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  VIEWED_ATTACHMENT = 'VIEWED_ATTACHMENT',
  RESPONDED = 'RESPONDED',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  PTP_OPEN = 'PTP_OPEN',
  PTP_CLOSED = 'PTP_CLOSED',
  ESCALATION = 'ESCALATION',
  ESCALATION_OPEN = 'ESCALATION_OPEN',
  ESCALATION_CLOSED = 'ESCALATION_CLOSED',
  OPEN = 'OPEN',
  WAITING_ON_CUSTOMER = 'WAITING_ON_CUSTOMER',
  CLOSED = 'CLOSED',
  DISPUTE_OPEN = 'DISPUTE_OPEN',
  DISPUTE_WAITING_ON_CUSTOMER = 'DISPUTE_WOC',
  DISPUTE_CLOSED = 'DISPUTE_CLOSED',
}

//CONTACT_MISSING//INVALID_EMAIL_ID//VIEWED_ATTACHMENT
/* commented few statuses => https://growfin.atlassian.net/browse/GRO-567 */
export const InvoiceFollowupStatusDropDownList = [
  // {
  //   label: 'Missing Contacts',
  //   value: EInvoiceFollowupStatus.CONTACT_MISSING,
  // },
  // {
  //   label: 'Invalid Email Address',
  //   value: EInvoiceFollowupStatus.INVALID_EMAIL_ID,
  // },
  {
    label: 'Emails sent',
    value: InvoiceFollowupStatus.EMAIL_SENT,
  },
  {
    label: 'Emails opened',
    value: InvoiceFollowupStatus.EMAIL_OPENED,
  },
  {
    label: 'Emails not delievered',
    value: InvoiceFollowupStatus.EMAIL_BOUNCED,
  },
  // {
  //   label: 'Emails with viewed attachments',
  //   value: EInvoiceFollowupStatus.VIEWED_ATTACHMENT,
  // },
  {
    label: 'Response received',
    value: InvoiceFollowupStatus.RESPONDED,
  },
  {
    label: 'Promise to Pay - Open',
    value: InvoiceFollowupStatus.PTP_OPEN,
  },
  {
    label: 'Promise to Pay - Closed',
    value: InvoiceFollowupStatus.PTP_CLOSED,
  },
  {
    label: 'Escalation - Open',
    value: InvoiceFollowupStatus.ESCALATION_OPEN,
  },
  {
    label: 'Escalation - Closed',
    value: InvoiceFollowupStatus.ESCALATION_CLOSED,
  },
  {
    label: 'Dispute - Open',
    value: InvoiceFollowupStatus.DISPUTE_OPEN,
  },
  {
    label: 'Dispute - Waiting on Customer',
    value: InvoiceFollowupStatus.DISPUTE_WAITING_ON_CUSTOMER,
  },
  {
    label: 'Dispute - Closed',
    value: InvoiceFollowupStatus.DISPUTE_CLOSED,
  },
];

export interface InvoiceDropdownInterfaceProps {
  id: number;
  invoice_no: string;
  balance_due_amount?: number;
  promise_to_pay_amount?: number;
  invoice_amount?: number;
  invoice_date?: string;
  currency: string;
  invoice_title?: string;
  invoice_status: InvoiceStatus;
  customer: BaseCustomer;
  customer_id: number;
  customer_name?: string;
  customer_no?: string;
  is_parent_customer?: boolean;
  balance_due_amount_base_currency?: number;
}
export type ValidFollowupStatus = Exclude<
  InvoiceFollowupStatus,
  | InvoiceFollowupStatus.CONTACT_MISSING
  | InvoiceFollowupStatus.INVALID_EMAIL_ID
  | InvoiceFollowupStatus.VIEWED_ATTACHMENT
>;

export const invoiceFollowupStatusConfig: Record<
  ValidFollowupStatus,
  { label: string; style: { backgroundColor: string; textColor: string } }
> = {
  [InvoiceFollowupStatus.EMAIL_SENT]: {
    label: 'Email Sent',
    style: {
      backgroundColor: 'var(--blue-1)',
      textColor: 'var(--blue-7)',
    },
  },
  [InvoiceFollowupStatus.EMAIL_OPENED]: {
    label: 'Email Opened',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
  [InvoiceFollowupStatus.EMAIL_BOUNCED]: {
    label: 'Email Bounced',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-8)',
    },
  },

  [InvoiceFollowupStatus.RESPONDED]: {
    label: 'Responded',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-7)',
    },
  },
  [InvoiceFollowupStatus.PROMISE_TO_PAY]: {
    label: 'Promise to Pay',
    style: {
      backgroundColor: 'var(--yellow-1)',
      textColor: 'var(--yellow-8)',
    },
  },
  [InvoiceFollowupStatus.PTP_OPEN]: {
    label: 'PTP Open',
    style: {
      backgroundColor: 'var(--yellow-2)',
      textColor: 'var(--yellow-9)',
    },
  },
  [InvoiceFollowupStatus.PTP_CLOSED]: {
    label: 'PTP Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-7)',
    },
  },
  [InvoiceFollowupStatus.ESCALATION]: {
    label: 'Escalation',
    style: {
      backgroundColor: 'var(--orange-1)',
      textColor: 'var(--orange-7)',
    },
  },
  [InvoiceFollowupStatus.ESCALATION_OPEN]: {
    label: 'Escalation Open',
    style: {
      backgroundColor: 'var(--orange-2)',
      textColor: 'var(--orange-8)',
    },
  },
  [InvoiceFollowupStatus.ESCALATION_CLOSED]: {
    label: 'Escalation Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-7)',
    },
  },
  [InvoiceFollowupStatus.OPEN]: {
    label: 'Open',
    style: {
      backgroundColor: 'var(--blue-1)',
      textColor: 'var(--blue-8)',
    },
  },
  [InvoiceFollowupStatus.WAITING_ON_CUSTOMER]: {
    label: 'Waiting on Customer',
    style: {
      backgroundColor: 'var(--gray-1)',
      textColor: 'var(--gray-8)',
    },
  },
  [InvoiceFollowupStatus.CLOSED]: {
    label: 'Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
  [InvoiceFollowupStatus.DISPUTE_OPEN]: {
    label: 'Dispute Open',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-7)',
    },
  },
  [InvoiceFollowupStatus.DISPUTE_WAITING_ON_CUSTOMER]: {
    label: 'Dispute WOC',
    style: {
      backgroundColor: 'var(--yellow-1)',
      textColor: 'var(--yellow-8)',
    },
  },
  [InvoiceFollowupStatus.DISPUTE_CLOSED]: {
    label: 'Dispute Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-7)',
    },
  },
};
