import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowFlex, GrowFormHeader, GrowTypography } from '@sinecycle/growcomponents';
import { Col, Popover, Row } from 'antd';
import { AnchorProps } from 'antd/lib';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { StyledAnchor } from 'components/Settings/RolesAndPermissions/EditPrivileges';
import {
  actionTypesConfig,
  getActionGroupConfig,
  OptionLabel,
} from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import { cloneDeep, filter, includes, map, mapValues } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { AccountConfigKey } from 'types/entities/account';
import { ActionActivityTypes, ActionTypeOption } from '../types';
import ActionOptions from './ActionOptions';

interface AddActionModalProps {
  open?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  onSelect?: (value?: ActionTypeOption) => void;
  entityType?: 'INVOICE' | 'CUSTOMER';
  onOpenChange?: (open: boolean) => void;
  afterOpenChange?: (open: boolean) => void;
  filterList?: ActionActivityTypes[];
}

const excludedActionTypes = [
  ActionActivityTypes.APPROVAL_REQUEST,
  ActionActivityTypes.INVOICE_CUSTOM_FIELD,
  ActionActivityTypes.FLAG_INVOICE,
];
const excludedActionTypesInvoice = [
  ActionActivityTypes.EDIT_COLLECTION_STATUS,
  ActionActivityTypes.APPROVAL_REQUEST,
];

function AddActionModal(props: AddActionModalProps) {
  const {
    open,
    onClose,
    children,
    onSelect,
    entityType,
    onOpenChange,
    filterList,
    afterOpenChange,
  } = props;
  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);
  const [current, setCurrent] = useState(`#General`);
  const ref = useRef<HTMLDivElement | null>(null);
  const isEnabled = useConfig(AccountConfigKey.APPROVAL_REQUEST);
  function getFilteredOptions() {
    if (entityType === 'CUSTOMER') {
      const config = mapValues(cloneDeep(actionTypesConfig), (config) => {
        if (config.options) {
          return {
            ...config,
            options: config.options.filter((f) => !excludedActionTypes.includes(f.id)),
          };
        }
        return config;
      });

      return getActionGroupConfig(config);
    }

    if (entityType === 'INVOICE' && !isEnabled) {
      const config = mapValues(cloneDeep(actionTypesConfig), (config) => {
        if (config.options) {
          return {
            ...config,
            options: config.options.filter((f) => !excludedActionTypesInvoice.includes(f.id)),
          };
        }
        return config;
      });

      return getActionGroupConfig(config);
    }
    return getActionGroupConfig(cloneDeep(actionTypesConfig));
  }
  const actionGroupConfig = getFilteredOptions();

  const filteredData = map(actionGroupConfig, (section) => ({
    ...section,
    options: filter(section.options, (option) => !includes(filterList, option.id)),
  }));

  const options: AnchorProps['items'] = map(filteredData, (actionGroup) => {
    const Label = (
      <GrowFlex justify="space-between" align="center">
        <div className="tw-flex tw-items-center tw-gap-2">
          <GrowTypography.Text>{actionGroup.section}</GrowTypography.Text>
          <GrowTypography.Text>{`(${actionGroup.options.length})`}</GrowTypography.Text>
        </div>

        <FontAwesomeIcon icon={['far', 'chevron-right']} className="icon" />
      </GrowFlex>
    );
    return {
      title: Label,
      key: actionGroup.section,
      href: `#${actionGroup.section}`,
    };
  });

  function getAnchorMenuOptions() {
    return filteredData.map((action) => {
      return {
        key: action?.section ?? '',
        label: <GrowTypography.Text strong>{action?.section}</GrowTypography.Text>,
        children: action?.options.map((f) => {
          return {
            key: f.id,
            label: (
              <GrowFlex align="center" gap={24}>
                <OptionLabel option={f} />
                <FontAwesomeIcon icon={['far', 'plus']} className="icon" />
              </GrowFlex>
            ),
          };
        }),
      };
    });
  }

  const anchorMenuOptions = getAnchorMenuOptions();

  function handleGetContainer() {
    return ref.current as HTMLElement;
  }

  useEffect(() => {
    setTargetOffset(ref.current?.clientHeight);
  }, []);

  const Header = (
    <div onClick={(e) => e.stopPropagation()}>
      <GrowFormHeader
        title={<GrowTypography.Text strong>Add Action</GrowTypography.Text>}
        onClose={onClose}
      />
    </div>
  );
  const Content = (
    <Row className="tw-w-[640px]">
      <Col span={24} style={{ borderBottom: '1px solid var(--gray-4)' }}>
        {Header}
      </Col>
      <Col span={24}>
        <Row wrap={false} className="tw-h-[400px]  tw-overflow-hidden">
          <Col flex={'160px'}>
            <div
              className="tw-p-8 tw-bg-gray-2 tw-h-full tw-overflow-auto tw-flex tw-flex-col tw-gap-8"
              style={{ borderBottomLeftRadius: 'var(--br-3)' }}
            >
              <StyledAnchor
                getCurrentAnchor={() => current}
                className="no-padding"
                items={options}
                getContainer={handleGetContainer}
                affix={false}
                showInkInFixed={false}
                targetOffset={targetOffset}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onChange={(value) => {
                  setCurrent(value.length ? value : '#General');
                }}
              />
            </div>
          </Col>
          <Col
            flex={1}
            style={{ overflow: 'auto', height: 'inherit' }}
            id="wy-scroll-layout"
            ref={ref}
          >
            <>
              {anchorMenuOptions?.map((acnchor) => {
                return (
                  <div className="tw-flex tw-gap-4 tw-flex-col" key={acnchor.key}>
                    <ActionOptions
                      selectedOptions={[acnchor]}
                      onOptionClick={(value, setcion) => {
                        const config = filteredData.find((f) => f.section === setcion);
                        const selectedValue = config?.options.find((f) => f.id === value.key);
                        onSelect?.(selectedValue);
                        onClose?.();
                      }}
                    />
                  </div>
                );
              })}
            </>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Popover
      open={open}
      placement="right"
      content={Content}
      overlayInnerStyle={{ padding: 0 }}
      autoAdjustOverflow
      trigger={['click']}
      destroyTooltipOnHide
      overlayClassName="ant-popover-body-no-padding-rich insert-placeholder-popover"
      onOpenChange={onOpenChange}
      afterOpenChange={afterOpenChange}
    >
      {children}
    </Popover>
  );
}

export { AddActionModal };
