import { DatePicker } from 'components/BaseComponents/AntComponents';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { DefaultAbsoluteOperand } from 'types/entities/invoice-segments/operands';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';

interface EqualsDateFilterProps {
  value?: DateWorkFlowFilterOperator<Dayjs>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<Dayjs>) => void;
}
export default function EqualsDateFilter(props: EqualsDateFilterProps) {
  const format = useSelector(dateFormatSelector);
  const dateOperand = props.value?.operands as DefaultAbsoluteOperand<Dayjs>;
  const equalsValue =
    props?.value?.type === 'EQ' ? dayjs(dateOperand?.value_holder.value) : dayjs();

  function handleEqualValueChange(date: dayjs.Dayjs | null) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        if (!isNull(date)) {
          const dateOperand = draftFilter.operands as DefaultAbsoluteOperand<Dayjs>;
          dateOperand.value_holder.value = date;
        }
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <StyledFormItem shouldUpdate rules={[{ required: true }]}>
      <DatePicker
        defaultValue={equalsValue}
        onChange={handleEqualValueChange}
        format={format}
        allowClear={false}
      />
    </StyledFormItem>
  );
}
