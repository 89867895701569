import { Button, Dropdown, Form, Menu } from 'antd';
import { FormInstance } from 'antd/es/form';
import { DefaultOptionType } from 'antd/lib/select';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { StyledFormItem, StyledIconButton } from 'components/Common/Styles/Styles';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { cloneDeep } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { CustomField } from 'types/entities/custom-field';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { BaseWorkFlowFilter } from '../../../EventCondition/type';
import { findEntityEventConditionOptions } from '../../../Hooks';
import { WorkFlowTexts } from '../../../text';
import { WorkflowFiltersComponent } from '../WorkFlowFiltersComponent';
import { useFindCustomOptions, useGetFeatureFlagBasedOptions } from '../util';
import { WorkflowConditionSearch } from './ConditionSearch';
import { WorkFlowDeleteIcon, WorkFlowNoResultText, WorkFlowPlusIcon } from './Helper';
import { StyledFlex, StyledSelect } from './style';

const AddConditionButtonStyle = {
  color: 'var(--primary-7)',
  width: 'fit-content',
  marginTop: 'var(--space-4)',
};

export interface ConditionListProps {
  form: FormInstance<any>;
  name?: string;
  selectedEvent?: WorkFlowEventTypes;
  initialValue?: BaseWorkFlowFilter[];
  customFields?: CustomField[];
}
export type WorkFlowTriggerListOptionsType = {
  label: string;
  key: WorkFlowEventTypes;
  value: WorkFlowEventTypes;
  title?: string;
};
const t = {
  searchPlaceHolder: 'Search Conditions',
  remove: 'Remove',
};

export default function TriggerConditionList(props: ConditionListProps) {
  const entity: EVENT_TRIGGER_CONDITION_KEYS = useFormWatch('entity', props.form);
  const conditionEvent = findEntityEventConditionOptions(entity);
  const [filteredOptions, setFilteredOptions] = useState<DefaultOptionType[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const { options: optionList } = useFindCustomOptions(
    entity,
    conditionEvent as WorkFlowTriggerListOptionsType[],
    false,
    props.customFields
  );
  const optionalConditionOptions = useGetFeatureFlagBasedOptions(entity, optionList);

  return (
    <Form.List name="condition_list" initialValue={cloneDeep(props?.initialValue)}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const selectedEvent: WorkFlowEventTypes = props.form.getFieldValue([
                'condition_list',
                name,
                'type',
              ]);

              const FiltersComponent = selectedEvent && (
                <WorkflowFiltersComponent
                  form={props.form}
                  selectedEvent={selectedEvent}
                  name={name}
                  customFields={props.customFields}
                />
              );

              return (
                <Flex key={name} gap="var(--space-8)" align="flex-start">
                  <GrowToolTip title={t.remove}>
                    <StyledIconButton icon={WorkFlowDeleteIcon} onClick={() => remove(name)} />
                  </GrowToolTip>

                  <StyledFormItem
                    {...restField}
                    name={[name, `type`]}
                    rules={[
                      {
                        required: true,
                        message: WorkFlowTexts.eventError,
                      },
                    ]}
                    initialValue={props.form.getFieldValue('optional_event')}
                  >
                    <StyledSelect
                      options={optionalConditionOptions}
                      disabled
                      showSearch
                      style={{ width: '164px' }}
                      suffixIcon={<></>}
                    />
                  </StyledFormItem>
                  {FiltersComponent}
                </Flex>
              );
            })}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}
            >
              {({ getFieldValue, setFieldValue }) => {
                const selectedEventsArray =
                  getFieldValue(['condition_list'])?.map((obj: BaseWorkFlowFilter) => {
                    if (!isNaN(Number(obj?.type))) {
                      return Number(obj?.type);
                    }
                    return obj?.type;
                  }) || [];

                const remainingOptions =
                  optionalConditionOptions &&
                  optionalConditionOptions?.filter(
                    (option) => option && !selectedEventsArray.includes(option.value)
                  );

                function handleSearch(value: ChangeEvent<HTMLInputElement>) {
                  setSearchValue(value.target.value);
                  const searchedFilters = remainingOptions?.filter((option) =>
                    (option?.title ?? (option?.label as string))
                      ?.toLowerCase()
                      .includes(value.target.value.toString())
                  );
                  if (searchedFilters) setFilteredOptions(searchedFilters);
                }
                function handleInitialValues(open: boolean) {
                  setSearchValue('');
                  if (remainingOptions) setFilteredOptions(remainingOptions);
                }
                const menuItems = filteredOptions?.map((option) => {
                  return { key: option.key, label: option.label, value: option.value };
                });

                const MenuItem = (
                  <StyledFlex direction="column" gap="var(--space-8)">
                    <WorkflowConditionSearch
                      placeHolder={t.searchPlaceHolder}
                      onChange={handleSearch}
                      searchValue={searchValue}
                    />

                    <Menu
                      items={menuItems}
                      onClick={(info) => {
                        add();
                        const customId = props.customFields?.find(
                          (custom) => `${custom.display_name}-${custom.id}` === info.key
                        )?.id;
                        setFieldValue(['optional_event'], customId ?? info.key);
                      }}
                      style={{ boxShadow: 'none', maxHeight: '250px', overflow: 'scroll' }}
                    />
                    {!menuItems.length && WorkFlowNoResultText}
                  </StyledFlex>
                );

                return (
                  <>
                    {remainingOptions?.length ? (
                      <Dropdown
                        placement="bottomLeft"
                        trigger={['click']}
                        onOpenChange={(open) => {
                          handleInitialValues(open);
                        }}
                        destroyPopupOnHide
                        dropdownRender={() => {
                          return MenuItem;
                        }}
                      >
                        <Button icon={WorkFlowPlusIcon} style={AddConditionButtonStyle}>
                          {WorkFlowTexts.addCondition}
                        </Button>
                      </Dropdown>
                    ) : null}
                  </>
                );
              }}
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
}
