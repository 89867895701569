import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown } from 'antd';
import { MenuOptions } from 'components/BaseComponents/Menu/Antd/type';
import { DocPreview } from 'components/HigherOrderComponent/DocViewer';
import { head } from 'lodash';
import { useAnonFilePreviewQuery, useFilePreviewQuery } from 'queries/attachments';
import { useState } from 'react';
import { FileType } from 'types/common/attachments';
import { removeFileExtension } from 'util/remove-file-extension';

type PDFInvoices = { name: string; id: string | number; version?: string };
interface ViewInvoiceButtonProps {
  invoices: PDFInvoices[];
  title?: string;
  text?: string;
  isAnonymousymous?: boolean;
  hash?: string;
}
const invoiceButtonStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: 'var(--space-8)',
  padding: 'var(--space-8)',
  color: 'var(--primary-7)',
};
export function InvoicePDF(props: ViewInvoiceButtonProps) {
  const [currentDocUrl, setCurrentDocUrl] = useState('');
  const { mutateAsync, isLoading } = useFilePreviewQuery();
  const { mutateAsync: anonymousPreview, isLoading: anonmousLoading } = useAnonFilePreviewQuery();
  const moreThanOneInvoice = props.invoices.length > 1;

  function getItems(): MenuOptions {
    return props.invoices.map((invoice) => {
      return {
        key: invoice.id,
        label: invoice.name,
        onClick: () => {
          handleSingleInvoiceOpen(invoice.id, invoice?.version);
        },
      };
    });
  }

  const items = getItems();

  function handleSingleInvoiceOpen(invoiceId?: number | string, version?: string) {
    if (!invoiceId) return;
    if (props.isAnonymousymous) {
      anonymousPreview(invoiceId).then((value) => {
        setCurrentDocUrl(value);
      });
    } else {
      mutateAsync({ id: invoiceId, version }).then((value) => {
        setCurrentDocUrl(value);
      });
    }
  }

  function handleInvoiceButtonClick() {
    // in case of multiple invoices, trigger button should only open dropdown
    if (props.invoices.length === 1) {
      handleSingleInvoiceOpen(head(props.invoices)?.id, head(props.invoices)?.version);
    }
  }

  if (!props.invoices?.length) return null;
  return (
    <>
      <Dropdown
        menu={{
          items: moreThanOneInvoice ? items : [],
          style: { maxHeight: '200px', overflow: 'auto' },
        }}
      >
        <Button
          onClick={handleInvoiceButtonClick}
          style={invoiceButtonStyle}
          loading={isLoading || anonmousLoading}
          icon={<FontAwesomeIcon icon={['far', 'file-pdf']} color="var(--red-7)" />}
        >
          {removeFileExtension(head(props.invoices)?.name ?? '')}
          {moreThanOneInvoice && (
            <FontAwesomeIcon icon={['far', 'chevron-down']} color="var(--primary-7)" />
          )}
        </Button>
      </Dropdown>
      <DocPreview
        width="60%"
        closeIcon={false}
        open={Boolean(currentDocUrl)}
        onClose={() => setCurrentDocUrl('')}
        destroyOnClose={true}
        docType={FileType.PDF}
        docUrl={currentDocUrl}
      />
    </>
  );
}
