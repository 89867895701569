import { RegionItem } from 'store/regions/types';
import { match } from 'ts-pattern';
import {
  EventSnapshotAttachments,
  EventSnapshotAttachmentType,
  SnapShotEvent,
} from 'types/common/events';
import { BusinessUnit } from 'types/entities/business-unit';
import { CustomField } from 'types/entities/custom-field';
import { Subsidiary } from 'types/entities/subsidiary';
import { CustomerSnapshot } from './Customer/CustomerSnapShot';
import { InvoiceSnapshot } from './Invoice/InvoiceSnapshot';

interface EventSnapShotProps {
  snapshot?: SnapShotEvent;
  baseCurrency?: string;
  locale?: string;
  dateFormat?: string;
  isAnonymous?: boolean;
  attachments?: EventSnapshotAttachments<EventSnapshotAttachmentType>;
  hash?: string;
  bussinessUnits?: BusinessUnit[];
  region?: RegionItem[];
  subsidiary?: Subsidiary[];
  customField?: CustomField[];
}
function EventSnapShot(props: EventSnapShotProps) {
  const {
    snapshot,
    baseCurrency,
    locale,
    dateFormat,
    isAnonymous,
    attachments,
    hash,
    bussinessUnits,
    region,
    subsidiary,
    customField,
  } = props;

  return match(snapshot)
    .with({ entity_type: 'INVOICE' }, (props) => {
      return (
        <InvoiceSnapshot
          value={props.context}
          dateFormat={dateFormat}
          locale={locale}
          baseCurrency={baseCurrency}
          isAnonymous={isAnonymous}
          attachments={attachments}
          hash={hash}
        />
      );
    })
    .with({ entity_type: 'CUSTOMER' }, (props) => {
      return (
        <CustomerSnapshot
          value={props.context}
          dateFormat={dateFormat}
          locale={locale}
          baseCurrency={baseCurrency}
          isAnonymous={isAnonymous}
          attachments={attachments}
          hash={hash}
          bussinessUnits={bussinessUnits}
          region={region}
          subsidiary={subsidiary}
          customField={customField}
        />
      );
    })
    .otherwise(() => {
      return null;
    });
}

export { EventSnapShot };
