import { combineReducers } from '@reduxjs/toolkit';
import AllCustomers from 'store/AllCustomers';
import CollectionPlanning from 'store/CollectionPlanning';
import UIelementsStore from 'store/UI';
import activityFeedReducer from 'store/activity-feed/activity-feed';
import agingBucketsReducer from 'store/aging-buckets';
import agingReducer from 'store/aging/aging';
import asyncRequestsReducer from 'store/async-fetches/fetches';
import authenticationReducer from 'store/authentication/authentication';
import businessUnitsReducer from 'store/business-units/business-units';
import collectionFollowupReducer from 'store/collection-followup/collection-followup';
import collectionFollowupsReducer from 'store/collection-followups/collection-followups';
import collectionPeriod from 'store/collection-period';
import collectionStatusReducer from 'store/collection-status/collection-status';
import followupStrategyReducer from 'store/collection-strategy';
import followupRuleReducer from 'store/collection-strategy-rule';
import collectorsManagement from 'store/collectors-management';
import modifyCollectionTarget from 'store/collectors-management/modify-collection-target';
import collectorsPerformance from 'store/collectors-performace';
import customFieldsReducer from 'store/custom-fields';
import customerSegmentsReducer from 'store/customer-segments/customer-segments';
import customerTerritoriesReducer from 'store/customer-territories/customer-territories';
import customerDetailsReducer from 'store/customer/customer';
import emailTemplateReducer from 'store/email-template/email-template';
import emailTemplatesReducer from 'store/email-templates/email-templates';
import inboxActivitiesReducer from 'store/inbox-activities';
import insert from 'store/insert';
import integrationsReducer from 'store/integrations';
import internalContactsReducer from 'store/internal-contacts/internal-contacts';
import invoiceFlagReducer from 'store/invoice-flag/invoice-flag';
import invoiceSegmentManagementReducer from 'store/invoice-segments';
import invoiceReducerV2 from 'store/invoice-v2/invoice';
import InvoiceReducer from 'store/invoice/invoice';
import emailTemplateNew from 'store/new-emailtemplate';
import paymentList from 'store/payments';
import privilgeReducer from 'store/privilges/privilege';
import receivableDisplayReducer from 'store/receivable-display/receivable-display';
import regionsReducer from 'store/regions/regions';
import reportingTagsReducer from 'store/reporting-tags/reporting-tags';
import searchReducer from 'store/search/search';
import userNotificationsReducer from 'store/user-notifications';
import userReducer from 'store/user/user';
const appReducer = combineReducers({
  authentication: authenticationReducer,
  aging: agingReducer,
  agingBuckets: agingBucketsReducer,
  collectionFollowups: collectionFollowupsReducer,
  collectionFollowup: collectionFollowupReducer,
  users: userReducer,
  internalContacts: internalContactsReducer,
  emailTemplates: emailTemplatesReducer,
  emailTemplate: emailTemplateReducer,
  customerDetail: customerDetailsReducer,
  ActivityFeed: activityFeedReducer,
  businessUnits: businessUnitsReducer,
  regions: regionsReducer,
  search: searchReducer,
  customerSegements: customerSegmentsReducer,
  customerTerritories: customerTerritoriesReducer,
  invoice: InvoiceReducer,
  receivableDisplay: receivableDisplayReducer,
  collectionStatus: collectionStatusReducer,
  privilges: privilgeReducer,
  asyncRequests: asyncRequestsReducer,
  integrations: integrationsReducer,
  reportingTags: reportingTagsReducer,
  invoiceFlag: invoiceFlagReducer,
  inboxActivities: inboxActivitiesReducer,
  userNotifications: userNotificationsReducer,
  customFields: customFieldsReducer,
  followupStrategy: followupStrategyReducer,
  followupRule: followupRuleReducer,
  invoiceSegments: invoiceSegmentManagementReducer,
  insert,
  emailTemplateNew,
  AllCustomers,
  paymentList,
  invoiceReducerV2,
  CollectionPlanning,
  collectorsManagement,
  modifyCollectionTarget,
  collectionPeriod,
  collectorsPerformance,
  UElementsStore: UIelementsStore,
});

export type RootState = ReturnType<typeof appReducer>;

export default appReducer;
