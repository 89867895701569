import { ActionTypesRendererProps } from '../types';
import actionComponentMapType from './actionComponentMapType';

export default function ActionTypesRenderer({
  actionType,
  onUploadFilesOpen,
  isInvoiceLevel,
  form,
  actionData,
  emailFormData,
  errors,
  handleEmailContentChange,
  baseForm,
}: ActionTypesRendererProps) {
  const commonProps = {
    isInvoiceLevel,
    form,
    baseForm,
    data: actionData?.action,
  };

  const actionComponentMap = actionComponentMapType({
    commonProps,
    onUploadFilesOpen,
    emailFormData,
    errors,
    handleEmailContentChange,
    id: actionData?.id,
  });

  const actionComponentData = actionType ? actionComponentMap[actionType] : null;

  if (!actionComponentData) {
    return null;
  }

  const { component: ActionComponent, props: componentProps } = actionComponentData;

  return <ActionComponent {...componentProps} data={actionData?.action} key={actionData?.id} />;
}
