import { FormInstance, SelectProps } from 'antd';
import {
  delayedExecutionConfig,
  delayedExecutionConfigObject,
} from 'components/Common/Email/Form/DeliverySettings/types';
import { EmailStrategyFormProps } from 'components/Common/Email/Form/EmailForm/EmailForm';
import { stakeHolderListAttributes } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { Dayjs } from 'dayjs';
import React from 'react';
import { NonUndefined } from 'react-hook-form';
import { AttachmentIdResponse } from 'services/attachments';
import { AssignedToUsers } from 'store/activity-feed/type';
import { RuleErrors } from 'store/collection-strategy-rule/initial-state';
import {
  ApproverCFContact,
  ApproverContactContact,
  PocContact,
  UserContact,
} from 'types/entities/collection-strategy/contact-type';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { CalendarDaysEventData } from 'types/entities/collection-strategy/rule-event';
import { PocUserType } from 'types/entities/contact';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { DateSelectKeys } from '../../common/types';
import { UploadFileProps, WorkflowActionRule, WorkFlowRule } from '../type';
import { AddApproval } from './ActionRenderComponents/createActivity/AddApproval';
import AddNote from './ActionRenderComponents/createActivity/AddNote';
import AddPtpAction from './ActionRenderComponents/createActivity/AddPtpAction';
import AddTask from './ActionRenderComponents/createActivity/AddTask';
import RaiseDisputeAction from './ActionRenderComponents/createActivity/RaiseDisputeAction';
import RaiseEscalation from './ActionRenderComponents/createActivity/RaiseEscalation';
import WorkflowEmailForm from './ActionRenderComponents/createActivity/WorkflowEmail';
import CustomerCustomField from './ActionRenderComponents/others/customerCustomField';
import FlagInvoices from './ActionRenderComponents/others/flagInvoices';
import InvoiceCustomField from './ActionRenderComponents/others/InvoiceCustomField';
import UpdateCollectionOwner from './ActionRenderComponents/others/updateCollectionOwner';
import UpdateCollectionStatus from './ActionRenderComponents/others/updateCollectionStatus';
import UpdateCollectionStrategy from './ActionRenderComponents/others/updateCollectionStrategy';
import { EmailErrors } from './hooks/useEmailFormHandler';

export type APPROVALSTATUS = 'APPROVED' | 'REJECTED';
export type ApprovalEntityType = 'APPROVAL_REQUEST';
export type ApprovalEventType = 'APPROVAL_REQUEST_UPDATE';
export type ApprovalRule = {
  id?: string;
  trigger_type?: WorkFlowRule['trigger_type'];
  entity_type?: ApprovalEntityType;
  event_type?: ApprovalEventType;
  condition: ApprovalRequestCondition;
  actions: WorkflowActionRule<ActionTypes>[];
  level?: number; //for ui only
};
export type ApporvalFollowupWorkflow = {
  title?: string;
  workflow_rules: Array<ApprovalRule>;
  start_date?: string;
  enabled?: boolean;
  id?: string;
  root_parent_id?: number | string;
};

export interface ApprovalRequestStatusCondition {
  type: 'STATUS';
  operator: {
    type: 'IN' | 'NOT_IN';
    operands: {
      type: 'ABSOLUTE';
      value_holder: ApprovalValueHolder[];
    };
  };
  mandatory: boolean;
}
export interface ApprovalRequestCondition {
  id?: string | number;
  operator: 'AND' | 'OR';
  conditions: ApprovalRequestStatusCondition[];
  type: 'APPROVAL_REQUEST_CONDITION';
}

export type ApprovalValueHolder = {
  value: APPROVALSTATUS;
};
export type ApprovalToResponse = {
  contact_from?: stakeHolderListAttributes;
  contact_reply_to?: stakeHolderListAttributes;
  contact_to_list?: stakeHolderListAttributes[];
  contact_cc_list?: stakeHolderListAttributes[];
  contact_bcc_list?: stakeHolderListAttributes[];
};
export type ApprovalType = 'ALL' | 'ANY' | 'FIRST_RESPONSE';
export type ApprovalWorkFlowAction = {
  to?: Array<ApprovalContact>;
  approval_type?: ApprovalType;
};
export type ApprovalAction = {
  id?: string;
  type: ActionActivityTypes.APPROVAL_REQUEST;
  followup_workflow: ApporvalFollowupWorkflow;
} & ApprovalWorkFlowAction;
export type ApprovalActionBaseDTO = {
  id?: string | number;
  type: ActionActivityTypes.APPROVAL_REQUEST;
  action: ApprovalAction;
  actionId?: string | number;
};

export interface EmailContentData {
  emailFormData?: Partial<EmailActionData>;
  errors?: EmailErrors;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
}

export interface ActionComponentMapInterface {
  [key: string]: {
    component:
      | typeof WorkflowEmailForm
      | typeof RaiseDisputeAction
      | typeof AddPtpAction
      | typeof AddTask
      | typeof RaiseEscalation
      | typeof AddNote
      | typeof CustomerCustomField
      | typeof FlagInvoices
      | typeof InvoiceCustomField
      | typeof UpdateCollectionOwner
      | typeof UpdateCollectionStatus
      | typeof UpdateCollectionStrategy
      | typeof AddApproval;
    props: any;
  };
}

export interface WorkflowEmailMapProps {
  onUploadFilesOpen: (data: boolean) => void;
  emailFormData: Partial<EmailActionData>;
  errors: EmailErrors;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
}

export interface ActionTypeOption {
  id: ActionActivityTypes;
  label: string;
  icon: React.ReactNode;
  showCheckbox: boolean;
  entity?: string;
  style: { backgroundColor: string };
  isCutomField?: boolean;
}

interface ActionTypeConfig {
  label: string;
  title: string;
  options: ActionTypeOption[];
}

export interface ActionTypesConfig {
  [key: string]: ActionTypeConfig;
}

export interface defaultActionableEntityConfig {
  [key: string]: {
    invoiceLevel: boolean;
  };
}

export interface GeneratedOption {
  label: JSX.Element;
  value?: ActionActivityTypes;
  showCheckbox: boolean;
}

export interface GeneratesOptionType {
  label: string;
  title: string;
  options: GeneratedOption[];
}

export interface GenerateLabelOption {
  option?: ActionTypeOption;
}

export type SelectedTypeOption = GeneratedOption | undefined;

export interface IconContainerProps {
  backgroundColor: string;
}

export enum ActionsReducer {
  UPDATE_DISPUTE_DESCRIPTION = 'UPDATE_DISPUTE_DESCRIPTION',
}

export interface ActionTypeIconContainerProps {
  backgroundColor: string;
}

export interface EmailActionType extends Partial<EmailActionData> {
  id?: number;
  invoice_delivery?: boolean;
  type: ActionActivityTypes.EMAIL;
  delayed_execution_config: delayedExecutionConfig;
}

export interface DelayedExecutionConfig {
  enabled: true;
  tz: string;
  tz_type: string;
  fixed_days: number[];
  any_time: boolean;
  fixed_time: string;
  any_days: boolean;
}

interface DisputeAttachmentResponse extends AttachmentIdResponse {
  url: string;
}

export interface DisputeActionType {
  type: ActionActivityTypes.DISPUTE;
  id: number | string;
  dispute_type: {
    id: number;
  };
  file_upload_ids?: number[];
  attachments?: DisputeAttachmentResponse[];
  dispute_owner: {
    type: string;
    participant_relation: string | null;
    value: string;
    association_level: string;
  }[];
  description: string;
  title: string;
}

export interface DisputeActionData extends Omit<DisputeActionType, 'description'> {
  dispute_description: string;
}

export interface EscalationActionType {
  assign_to: stakeHolderListAttributes[];
  description: string;
  type: ActionActivityTypes.ESCALATION;
  actionable_entity: boolean;
  id?: number | string;
}

export interface EscalationDataAction {
  escalation_assign_to: stakeHolderListAttributes[];
  escalation_description: string;
  type: ActionActivityTypes.ESCALATION;
  actionable_entity: boolean;
}

export type TaskDataAction = {
  actionable_entity: boolean;
  task_assign_to: stakeHolderListAttributes[];
  date_select_offset: DateSelectKeys.ON | DateSelectKeys.AFTER;
  due_date_offset?: number;
  task_description: string;
  title: string;
  type: ActionActivityTypes.TASK;
};

export type ActionTypes =
  | EmailActionType
  | DisputeActionType
  | EscalationActionType
  | TaskActionType
  | PromiseToPayActionType
  | NoteActionType
  | CustomerCustomFieldType
  | InvoiceCustomFieldType
  | CollectionStrategyActionType
  | CollectionOwnerActionType
  | CollectionStatusActionType
  | FlagInvoicesActionType
  | ApprovalAction;

export interface ActionTypeFormProps extends EmailContentData {
  onUploadFilesOpen?: (initialValue: boolean) => void;
  onClose?: () => void;
  form: FormInstance;
  actionRule: WorkflowActionRule<ActionTypes>[];
  entityType?: 'CUSTOMER' | 'INVOICE';
  baseForm: FormInstance;
  selectedItem?: WorkflowActionRule<ActionTypes>;
  actionType?: any;
  onInsert: (value?: any) => void;
  isInvoiceLevel?: boolean;
  onInvoiceLevel?: (value: boolean) => void;
}

export interface EmailActionsData {
  DAYS: string;
  TIME: string;
  TIME_ZONE: string;
  fixed_days: CalendarDaysEventData;
  fixed_time: Dayjs;
  type: ActionActivityTypes.EMAIL;
  tz: string;
  tz_type: string;
}
export interface ApprovalData {
  type: ActionActivityTypes.APPROVAL_REQUEST;
}

export interface PromiseToPayData {
  due_date_offset?: number;
  date_select_offset: DateSelectKeys.ON | DateSelectKeys.AFTER;
  ptp_description: string;
  ptp_owner: stakeHolderListAttributes[];
  type: ActionActivityTypes.PROMISE_TO_PAY;
}

export type DataObject = (
  | EmailActionsData
  | PromiseToPayData
  | TaskDataAction
  | DisputeActionData
  | EscalationDataAction
  | NoteActionType
  | CustomerCustomFieldTypeData
  | InvoiceCustomFieldTypeData
  | CollectionStrategyActionData
  | CollectionOwnerActionType
  | CollectionStatusActionData
  | FlagInvoicesActionData
  | ApprovalData
) & {
  actionable_entity?: boolean;
};

export enum ActionActivityTypes {
  EMAIL = 'EMAIL',
  DISPUTE = 'DISPUTE',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  TASK = 'TASK',
  ESCALATION = 'ESCALATION',
  NOTE = 'NOTE',
  CUSTOMER_CUSTOM_FIELD = 'CUSTOMER_CUSTOM_FIELD',
  INVOICE_CUSTOM_FIELD = 'INVOICE_CUSTOM_FIELD',
  COLLECTION_STRATEGY = 'COLLECTION_STRATEGY',
  COLLECTION_OWNER = 'COLLECTION_OWNER',
  FLAG_INVOICE = 'FLAG_INVOICE',
  CUSTOMER = 'CUSTOMER',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  INVOICE = 'INVOICE',
  EDIT_STRATEGY = 'EDIT_STRATEGY',
  EDIT_COLLECTION_STATUS = 'EDIT_COLLECTION_STATUS',
  CALL_LOG = 'CALL_LOG',
  USER_CATEGORY = 'USER_CATEGORY',
  APPROVAL_REQUEST = 'APPROVAL_REQUEST',
}

export interface ApprovalUserContact extends UserContact {
  label: string;
  value: string | number;
  email: string;
}

export interface ApprovalApproverCFContact extends ApproverCFContact {
  label: string;
  value: string | number;
}
export interface ApprovalApproverContactContact extends ApproverContactContact {
  label: string;
  value: string;
}

export interface ApprovalPOCcontactOptions extends PocContact {
  label: string;
  value: PocUserType;
}
export type ApprovalContact = UserContact | ApproverCFContact | ApproverContactContact | PocContact;
export type ApprovalContactOptions =
  | ApprovalUserContact
  | ApprovalApproverCFContact
  | ApprovalApproverContactContact
  | ApprovalPOCcontactOptions;
export type ApprovalUserOptions = NonUndefined<
  SelectProps<Array<ApprovalContact>, ApprovalContactOptions>['options']
>;

export interface ActionTypesRendererProps extends EmailContentData {
  actionType: string;
  onUploadFilesOpen?: (open: boolean) => void;
  isInvoiceLevel: boolean;
  form: FormInstance;
  actionData?: WorkflowActionRule<ActionTypes>;
  baseForm: FormInstance;
}

export interface ActionComponentMapTypeProps {
  onUploadFilesOpen?: (open: boolean) => void;
  commonProps: {
    isInvoiceLevel: boolean;
    form: FormInstance;
    baseForm: FormInstance;
  };
  emailFormData?: Partial<EmailActionData>;
  errors?: EmailErrors;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
  actionData?: WorkflowActionRule<ActionTypes>;
  options?: ApprovalUserOptions;
  id?: any;
}

export interface AdditionalPropData {
  isInvoiceLevel: boolean;
  form: FormInstance;
  baseForm: FormInstance;
}

export interface WorkflowEmailFormProps extends EmailStrategyFormProps {
  data?: Partial<EmailActionData> & delayedExecutionConfigObject;
  isInvoiceLevel: boolean;
  form: FormInstance;
  emailFormData?: Partial<EmailActionData>;
  errors?: EmailErrors;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
  id?: ApprovalUserOptions;
}

export type RaiseDisputeActionType = {
  actionable_entity: boolean;
  title: string;
  dispute_type_ids: number[];
  description: string;
  dispute_owner: AssignedToUsers[];
  attachments: DisputeAttachmentResponse[];
};

export type PromiseToPayActionType = {
  due_date_offset: number;
  description: string;
  ptp_owner: stakeHolderListAttributes[];
  type: ActionActivityTypes.PROMISE_TO_PAY;
  id?: number | string;
};

export interface TaskActionType {
  assign_to: stakeHolderListAttributes[];
  title: string;
  description: string;
  due_date_offset: number;
  type: ActionActivityTypes.TASK;
  id?: number | string;
}

export interface NoteActionType {
  notes: string;
  type: ActionActivityTypes.NOTE;
  id?: number | string;
}

export interface CustomerCustomFieldType {
  field_id: number;
  value: string;
  type: ActionActivityTypes.CUSTOMER_CUSTOM_FIELD | ActionActivityTypes.CUSTOM_FIELD;
  id?: number | string;
}

interface SelectFieldLabel {
  value: number;
  label: string;
}

export interface CustomerCustomFieldTypeData
  extends Omit<CustomerCustomFieldType, 'value' | 'field_id'> {
  customer_custom_value: string;
  field_id: SelectFieldLabel;
  id?: number | string;
}

export interface InvoiceCustomFieldType {
  field_id: number;
  value: string;
  type: ActionActivityTypes.INVOICE_CUSTOM_FIELD | ActionActivityTypes.CUSTOM_FIELD;
  id?: number | string;
}

export interface InvoiceCustomFieldTypeData
  extends Omit<InvoiceCustomFieldType, 'value' | 'field_id'> {
  invoice_custom_value: string;
  field_id: SelectFieldLabel;
}

export interface CollectionStrategyActionType {
  value: string;
  type: ActionActivityTypes.CUSTOMER | ActionActivityTypes.EDIT_STRATEGY;
  field: ActionActivityTypes.EDIT_STRATEGY;
  id?: number | string;
}

export interface CollectionStrategyActionData extends Omit<CollectionStrategyActionType, 'value'> {
  collection_strategy_value: string;
}

export interface CollectionOwnerActionType {
  name: ActionActivityTypes.COLLECTION_OWNER;
  type: ActionActivityTypes.USER_CATEGORY;
  user_ids: number[];
  id?: number | string;
}

export interface CollectionStatusActionType {
  field: ActionActivityTypes.EDIT_COLLECTION_STATUS;
  type: ActionActivityTypes.CUSTOMER | ActionActivityTypes.EDIT_COLLECTION_STATUS;
  value: string;
  id?: number | string;
}

export interface CollectionStatusActionData extends Omit<CollectionStatusActionType, 'value'> {
  collection_status_value: string;
}

export interface FlagInvoicesActionType {
  value: string;
  type: ActionActivityTypes.FLAG_INVOICE | ActionActivityTypes.INVOICE;
  field: ActionActivityTypes.FLAG_INVOICE;
  id?: number | string;
}

export interface FlagInvoicesActionData extends Omit<FlagInvoicesActionType, 'value'> {
  flag_invoice_value: string;
}

export type AddTaskAction = {
  title: string;
  description: string;
  due_date_offset: number;
  assign_to: AssignedToUsers[];
};

export interface RaiseDisputeActionTypeProps extends AdditionalPropData {
  data?: RaiseDisputeActionType;
}

export interface AddPtpActionProps extends AdditionalPropData {
  data?: PromiseToPayActionType;
}

export interface AddTaskActionProps extends AdditionalPropData {
  data?: AddTaskAction;
}

export interface AddApprovalProps extends AdditionalPropData {
  data?: ApprovalWorkFlowAction;
  options?: ApprovalUserOptions;
}
export interface UpdateCollectionStrategyProps extends AdditionalPropData {
  data?: CollectionStrategyActionType;
}

export interface UpdateCollectionStatusProps extends AdditionalPropData {
  data?: CollectionStatusActionType;
}

export interface UpdateCollectionOwnerProps extends AdditionalPropData {
  data?: CollectionOwnerActionType;
}

export interface InvoiceCustomFieldProps extends AdditionalPropData {
  data?: InvoiceCustomFieldType;
}

export interface FlagInvoicesProps extends AdditionalPropData {
  data?: FlagInvoicesActionType;
}

export interface CustomerCustomFieldProps extends AdditionalPropData {
  data?: CustomerCustomFieldType;
}

export interface ActionTypeTitleMapProps {
  [key: string]: React.ElementType;
}

export interface ActionProps extends EmailContentData {
  actionRule?: WorkflowActionRule<ActionTypes>[];
  onActionRemove: (data: WorkflowActionRule<ActionTypes>[], type: string) => void;
  actionRuleList?: WorkflowActionRule<ActionTypes>[];
  workflowMeta: WorkflowMetaDto;
  form: FormInstance;
  baseForm: FormInstance;
  onClick?: (value?: 'inital') => void;
  selected?: boolean;
  mode: 'DISPLAY' | 'DETAILS';
  selectedItem?: WorkflowActionRule<ActionTypes>;
  onInsert: (value?: any) => void;
  actionType?: any;
  loading?: boolean;
  enabled?: boolean;
  isInvoiceLevel?: boolean;
  onInvoiceLevel?: (value: boolean) => void;
}

export interface ActionRulesListProps {
  value?: WorkflowActionRule<ActionTypes>[];
  onClick: () => void;
  onActionEdit: (data: WorkflowActionRule<ActionTypes>) => void;
  form: FormInstance;
  onInsert: (value?: any) => void;
  entityType?: 'CUSTOMER' | 'INVOICE';
}

export interface ValidateEmailErrors {
  errorsList: RuleErrors;
  showErrors: boolean;
}

export type OptionsType = { label: string; value: number };

export interface ActionTitleProps<T> {
  item: T;
  strong?: boolean;
}

export type ItemAssignToListProp = EscalationActionType;

export interface ConstructActionProps {
  data?: DataObject;
  uploadAttachments?: (props: UploadFileProps) => Promise<number[] | undefined>;
  file_upload_ids?: File[];
  emailFormData?: Partial<EmailActionData>;
  workflowData?: WorkflowMetaDto;
  prevData?: any;
  isNewaction?: boolean;
}

export interface ActionTypesSelectProps {
  form: FormInstance;
  actionType: string;
  actionOptions: GeneratesOptionType[];
  actionRule: WorkflowActionRule<ActionTypes>[];
}
