import { StatusCellConfig } from '@sinecycle/growcomponents';
import { StyledTable } from 'components/InvoiceDetails/InvoiceDetailsBody/LineItems/style';
import { CreditStatementsTable } from './CreditTable';

interface CreditAdjustmentsStatementsProps {
  customerId: number;
}
export enum CreditMemoStatus {
  VOID = 'VOID',
  FULLY_APPLIED = 'FULLY_APPLIED',
  UNAPPLIED = 'UNAPPLIED',
  PARTIALLY_APPLIED = 'PARTIALLY_APPLIED',
}

const creditMemoStatusConfig: Record<CreditMemoStatus, StatusCellConfig> = {
  [CreditMemoStatus.FULLY_APPLIED]: {
    style: {
      textColor: 'var(--green-9)',
      backgroundColor: 'var(--green-1)',
    },

    label: 'Fully Applied',
  },
  [CreditMemoStatus.PARTIALLY_APPLIED]: {
    style: {
      textColor: 'var(--orange-9)',
      backgroundColor: 'var(--orange-1)',
    },
    label: 'Partially Applied',
  },
  [CreditMemoStatus.UNAPPLIED]: {
    style: {
      textColor: 'var(--red-9)',
      backgroundColor: 'var(--red-1)',
    },
    label: 'Unapplied',
  },
  [CreditMemoStatus.VOID]: {
    style: {
      textColor: 'var(--volcano-9)',
      backgroundColor: 'var(--volcano-1)',
    },
    label: 'Void',
  },
};

export function getCreditMemoStatus(status: CreditMemoStatus) {
  return creditMemoStatusConfig[status];
}

const CreditAdjustmentsStatements = (props: CreditAdjustmentsStatementsProps) => {
  return (
    <StyledTable style={{ padding: 'var(--space-24)', height: 'calc(-210px + 100vh)' }}>
      <CreditStatementsTable {...props} />
    </StyledTable>
  );
};

export default CreditAdjustmentsStatements;
