import { Select, Skeleton } from 'antd';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { WorkFlowTexts } from '../../text';
import { MandatoryFormItemProps } from '../type';
import { WorkFlowTriggerListOptionsType } from './ConditionList/TriggerConditionList';
import { useFindCustomOptions, useGetFeatureFlagBasedOptions } from './util';

export function TriggerMandatoryEvent(props: MandatoryFormItemProps) {
  const mandatoryOptions = props.derivedOptions;
  const { options: conditionListOptions } = useFindCustomOptions(
    props.entity,
    mandatoryOptions as WorkFlowTriggerListOptionsType[],
    true,
    props.customField
  );

  const mandatoryEventOptions = useGetFeatureFlagBasedOptions(props.entity, conditionListOptions);
  const eventInitailValue =
    props.triggerType === 'LIVE'
      ? props.mandatory?.type === 'INVOICE_CUSTOM_FIELD' ||
        props.mandatory?.type === 'CUSTOMER_CUSTOM_FIELD'
        ? props.mandatory.id
        : props.mandatory?.type ?? props.mandatoryEvent
      : undefined;

  function eventFilterOption(input: string, option?: WorkFlowTriggerListOptionsType) {
    const label = option?.title ?? (option?.label as string);
    const labelInput = label?.toLowerCase();
    return labelInput?.includes(input.toLowerCase());
  }

  return (
    <>
      {props.isLoading ? (
        <Skeleton.Input />
      ) : (
        <StyledFormItem
          name={'selected_event'}
          rules={[
            {
              required: true,
              message: WorkFlowTexts.eventError,
            },
          ]}
          initialValue={eventInitailValue}
        >
          <Select
            options={mandatoryEventOptions}
            showSearch
            style={{ width: '207px' }}
            onChange={props.handleselectEvent}
            placeholder={WorkFlowTexts.eventPlaceholder}
            loading={Boolean(props.isLoading)}
            dropdownStyle={{ minWidth: 'fit-content' }}
            filterOption={eventFilterOption}
          />
        </StyledFormItem>
      )}
    </>
  );
}
