import { CheckboxOptionType, RadioChangeEvent } from 'antd';
import { StyledRadioGroup } from 'components/BaseComponents/Buttons/Switcher';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import produce from 'immer';
import { head } from 'lodash';
import { InAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import { WorkFlowInOperator } from '../../EventCondition/operator';

interface SingleDateFilterProps {
  value?: WorkFlowInOperator<boolean>;
  onChange?: (updatedFilter: WorkFlowInOperator<boolean>) => void;
}
const workFlowBooleanOperationOptions: CheckboxOptionType[] = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export default function BooleanFilter(props: SingleDateFilterProps) {
  const booleanValueOperand = props?.value?.operands as InAbsoluteOperands<boolean>;
  const initialValue = head(booleanValueOperand?.value_holder)?.value;
  function handleStatusChanges(status: boolean) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        const booleanValueOperand = draftFilter?.operands as InAbsoluteOperands<boolean>;
        booleanValueOperand.value_holder = [{ value: status }];
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  function handleValueChange(e: RadioChangeEvent) {
    const status = e.target.value;

    handleStatusChanges(status);
  }

  return (
    <StyledFormItem shouldUpdate>
      <StyledRadioGroup
        options={workFlowBooleanOperationOptions}
        defaultValue={initialValue}
        onChange={handleValueChange}
        optionType="button"
      />
    </StyledFormItem>
  );
}
