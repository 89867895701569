import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import dayjs from 'dayjs';
import produce from 'immer';
import { ValidateStatus } from 'types/entities/invoice-segments';
import SearchableSelect from '../../../../../../Common/SearchableSelect/SearchableSelect';
import { DateWorkFlowOperatorTypes } from '../../../type';
import {
  DefaultRelativeOperand,
  WorkFlowBetweenRelativeOperands,
  WorkFlowGeneralRelativeOperands,
} from '../../EventCondition/operator';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';
import { WorkFlowOperationOptions } from '../predicate';
import RelativeBetweenFilter from './RelativeBetweenFilter';
import RelativeSingleFilter from './RelativeSingleFilter';
import { RelativeCurrentDateTag } from './utils';

interface WorkFlowRelativeDateFilterProps {
  value?: DateWorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<unknown>) => void;
  percision?: number;
  singleFieldMoreInfo?: string;
}

export type DateFilterRange = {
  from: RangeDate;
  to: RangeDate;
};

export type RangeDate = {
  value: dayjs.Dayjs;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};

const relativeOperandValue = {
  type: 'DATE',
  relative_day: 'CURRENT_DATE',
};

function RelativeDateFilter(props: WorkFlowRelativeDateFilterProps) {
  const currentType = props?.value?.type;

  function handleOperatorChange(value: DateWorkFlowOperatorTypes) {
    const updatedFilter = produce(props.value, (draftFilter) => {
      if (!props.value || !draftFilter) return;

      const filterValue = draftFilter.operands as WorkFlowGeneralRelativeOperands;
      const betweenRelativeOperand = draftFilter.operands as WorkFlowBetweenRelativeOperands;

      const defaultRelativeOperand = {
        ...relativeOperandValue,
        offset: filterValue?.value?.offset ?? 0,
      };
      draftFilter.type = value;
      switch (value) {
        case 'BTWN':
          const betweenOperand = props?.value?.operands as WorkFlowBetweenRelativeOperands;
          betweenRelativeOperand.value = {
            from: {
              ...defaultRelativeOperand,
              offset_type: 'BEFORE',
              offset: betweenOperand?.value?.from?.offset ?? 0,
            } as DefaultRelativeOperand,
            to: {
              ...defaultRelativeOperand,
              offset_type: 'AFTER',
              offset: betweenOperand?.value?.to?.offset ?? 0,
            } as DefaultRelativeOperand,
          };
          break;
        case 'EQ':
          filterValue.value = {
            ...relativeOperandValue,
            offset: 0,
            offset_type: 'ON',
          } as DefaultRelativeOperand;
          break;
        case 'LT':
          filterValue.value = {
            ...defaultRelativeOperand,
            offset_type: 'BEFORE',
          } as DefaultRelativeOperand;
          break;
        default:
          filterValue.value = {
            ...defaultRelativeOperand,
            offset_type: 'AFTER',
          } as DefaultRelativeOperand;
          break;
      }
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <SearchableSelect
      options={WorkFlowOperationOptions}
      onChange={handleOperatorChange}
      value={props?.value?.type ?? 'IN'}
    />
  );
  const FilterRenderer = (
    <Flex
      gap="var(--space-8)"
      align="flex-start"
      style={{ alignSelf: 'center', flexWrap: 'wrap', maxWidth: '400px' }}
    >
      {Operators}
      <HideWrapper hide={currentType !== 'BTWN'}>
        <RelativeBetweenFilter value={props.value} onChange={props.onChange} />
      </HideWrapper>
      <HideWrapper hide={currentType !== 'EQ'}>{RelativeCurrentDateTag}</HideWrapper>
      <HideWrapper hide={currentType === 'EQ' || currentType === 'BTWN'}>
        <StyledFormItem shouldUpdate>
          <RelativeSingleFilter value={props.value} onChange={props.onChange} showDaysOf={true} />
        </StyledFormItem>
      </HideWrapper>
    </Flex>
  );

  return (
    <Flex direction="column" gap="var(--space-8)" align="center">
      {FilterRenderer}
    </Flex>
  );
}

const WorkFlowRelativeDateFilter = () => <></>;

WorkFlowRelativeDateFilter.FilterField = RelativeDateFilter;

export { WorkFlowRelativeDateFilter };
