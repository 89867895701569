import { HideWrapper } from '@sinecycle/growcomponents';
import { FilePreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import CustomerStatementPreview from 'components/Common/AssociatedFiles/CustomerStatement/Preview';
import PreviewAssociatedFiles from 'components/Common/AssociatedFiles/PreviewAssociatedFiles';
import { AFiles } from 'components/Common/AssociatedFiles/type';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { FileIcon } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/NewEmail/new-email';
import { head } from 'lodash';
import { useGetDocumentTypesLists } from 'queries/documents/documents';
import { useMemo } from 'react';
import {
  Attachments,
  DocumentAttachment,
  EmailAttachment,
  GenerativeAttachment,
} from 'store/email/types';
import { match } from 'ts-pattern';
import { AccountConfigKey } from 'types/entities/account';
import { DocumentTypeFilterKeys } from 'types/entities/documents';
import { Unpacked } from 'types/utils/utils';
import useCustomerStatement from 'util/hooks/useCustomerStatement';
import { cleanArrayPredicate } from 'util/predicates';

interface EmailAttachmentsProps {
  attachments?: Attachments;
  isInvoiceLevel?: boolean;
}

function useEmailAttachments(props: EmailAttachmentsProps) {
  const { attachments, isInvoiceLevel } = props;
  const isCustomerStatementEnabled = useConfig(AccountConfigKey.CUSTOMER_STATEMENT);
  const filter: DocumentTypeFilterKeys = {
    entity_types: isInvoiceLevel ? ['CUSTOMER', 'INVOICE'] : ['CUSTOMER'],
  };
  const getStatement = attachments?.find(
    (attachment) => attachment?.type === 'GENERATIVE_ATTACHMENT'
  ) as GenerativeAttachment;
  const { statements, enable } = useCustomerStatement('RELATIVE', head(getStatement?.list));
  const { data: documents } = useGetDocumentTypesLists(filter);
  const renderFiles = useMemo(
    () => (fileName: string) => {
      return AFiles.map((file) => (
        <PreviewAssociatedFiles
          key={file.id}
          associatedFile={{ ...file, name: fileName }}
          onRemove={() => {}}
          hideClose
        />
      ));
    },
    []
  );
  const renderDocuments = useMemo(
    () => (props: DocumentAttachment, type: ActionableEntity) => {
      const selectedDocuments =
        type === ActionableEntity.INVOICE
          ? props.invoice_document_type_ids
          : props.customer_document_type_ids;

      return (
        documents?.list
          .filter((document) => document.enabled)
          .map((document) => ({
            file_name: document.name,
            checked: selectedDocuments?.includes(document.id),
            id: String(document.id),
            mode: 'BULK',
            level: type,
            entity: document.entity_type,
            typeId: String(document.id),
          }))
          .map((attachment) => (
            <FilePreview
              fileName={attachment.file_name}
              fileId={attachment.id}
              preview={false}
              previewIcon={FileIcon}
              hidePreview
              key={attachment.id}
            />
          )) ?? []
      );
    },
    [documents]
  );
  const renderAttachments = useMemo(
    () => (props?: EmailAttachment) => {
      return props?.list?.map((attachment) => (
        <FilePreview
          key={attachment.id}
          fileName={attachment.file_name}
          fileId={attachment.id}
          hidePreview
        />
      ));
    },
    []
  );
  const renderGenerativeAttachment = useMemo(
    () => () => {
      return (
        <HideWrapper hide={!isCustomerStatementEnabled}>
          <CustomerStatementPreview
            fileType={statements.file_type}
            onRemove={() => {}}
            hide={!enable}
            hideClose
            statementType={statements.attachment_type}
          />
        </HideWrapper>
      );
    },
    [isCustomerStatementEnabled, statements, enable]
  );
  const renderAttachment = useMemo(
    () => (attachment: Unpacked<Attachments>) => {
      const type = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;

      return match(attachment)
        .with({ type: 'ALL_OPEN_INVOICES' }, () => renderFiles('All Open Invoices'))
        .with({ type: 'CURRENT_INVOICE' }, () => renderFiles('Invoice File'))
        .with({ type: 'DOCUMENT' }, (props) => renderDocuments(props, type))
        .with({ type: 'ATTACHMENT' }, (props) =>
          renderAttachments({ ...props, list: props.list ?? [] })
        )
        .with({ type: 'GENERATIVE_ATTACHMENT' }, () => renderGenerativeAttachment())
        .otherwise(() => null);
    },
    [isInvoiceLevel, renderFiles, renderDocuments, renderAttachments, renderGenerativeAttachment]
  );

  const attachmentComponent = useMemo(() => {
    return (
      attachments
        ?.map((attachment) => <>{renderAttachment(attachment)}</>)
        ?.filter(cleanArrayPredicate)
        .map((attachment, index) => <>{attachment}</>) ?? []
    );
  }, [attachments, renderAttachment]);

  return { attachmentComponent };
}

export { useEmailAttachments };
