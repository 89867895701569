import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowText } from 'components/BaseComponents/Typography';
import { WorkflowCardTitleProps } from './types';

export default function WorkflowCardTitle({
  title,
  icon,
  hasData,
  onClick,
}: WorkflowCardTitleProps) {
  const success = (
    <FontAwesomeIcon
      icon={hasData ? ['fas', 'check-circle'] : ['far', 'check-circle']}
      color={hasData ? 'var(--green-8)' : 'var(--gray-7)'}
    />
  );
  return (
    <div
      className="tw-flex tw-items-center tw-justify-between"
      onClick={() => {
        onClick?.();
      }}
    >
      <div className="tw-flex tw-items-center tw-gap-8">
        <FontAwesomeIcon icon={icon} style={{ fontSize: 'var(--fs-16)' }} color="var(--gray-7)" />
        <GrowText
          size="var(--fs-16)"
          weight="var(--fs-semibold)"
          style={{ color: 'var(--gray-9)' }}
        >
          {title}
        </GrowText>
      </div>
      {success}
    </div>
  );
}
