import { GrowCheckBox, GrowTypography } from '@sinecycle/growcomponents';
import { Card, Checkbox, Col } from 'antd';
import { toLower } from 'lodash';

interface OptionProps {
  configurable: boolean | undefined;
  displayName: string | undefined;
  description: string | undefined;
  id: number | undefined;
  onChange: (checked: boolean) => void;
}

type CheckBoxProps = React.ComponentProps<typeof Checkbox>['onChange'];
type ChangeEvent = Parameters<NonNullable<CheckBoxProps>>[0];
function Option(props: Readonly<OptionProps>) {
  const { configurable, description, displayName, id, onChange } = props;
  const isDescAndTitleAreSame = toLower(description) === toLower(displayName);
  function handleChange(event: ChangeEvent) {
    onChange(event.target.checked);
  }
  const Desc = (
    <>
      {isDescAndTitleAreSame || !description ? (
        <></>
      ) : (
        <GrowTypography.Text style={{ color: 'var(--gray-7)', marginLeft: 'var(--space-24)' }}>
          {description}
        </GrowTypography.Text>
      )}
    </>
  );

  const Title = (
    <GrowCheckBox
      disabled={!configurable}
      defaultChecked={!configurable}
      value={id}
      design="border-design"
      onChange={handleChange}
      style={{ width: '100%', display: 'flex', alignItems: 'center' }}
    >
      <GrowTypography.Text fs="16">{displayName}</GrowTypography.Text>
    </GrowCheckBox>
  );

  return (
    <Col span={12} style={{ lineHeight: 1.5 }}>
      <Card.Meta style={{ width: '100%' }} title={Title} description={Desc} />
    </Col>
  );
}

export default Option;
