export const WorkFlowTexts = {
  drawerTitle: 'Add Trigger',
  editTrigger: 'Edit Trigger',
  entity: 'Entity',
  type: 'Type',
  entityPlaceHolder: 'Select Entity',
  conditions: 'Conditions',
  optional: '(Optional)',
  ofTheFollowing: 'of the following conditions',
  eventPlaceholder: 'Select Event',
  moreInfo: 'The workflow commences upon the initiation of an event.',
  frequency: 'Frequency',
  entityTypeError: 'Please choose type',
  entityError: 'Please choose entity',
  eventError: 'Please choose event',
  calenderEventError: 'Please choose the periodic type',
  operatorError: 'Please choose operator',
  addCondition: 'Add condition',
  trigger: 'Trigger',
  override_trigger: `Making changes to the entity will reset all configured actions in the workflow. Are you sure you want to apply these changes?`,
};
