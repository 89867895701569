/**
 * This entire file requires a cleanup,
 * Added to the list of techdebts in Heisenburg
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Skeleton, Tooltip } from 'antd';
import { RcFile } from 'antd/lib/upload';
import SelectComponent, { IProps } from 'components/BaseComponents/ASelect';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import RichEmailContentEditor from 'components/BaseComponents/RTE/components/RichEmailContentEditor';
import { RichEmailSubjectEditor } from 'components/BaseComponents/RTE/components/RichEmailSubjectEditor';
import ToggleSwitchComponent from 'components/BaseComponents/Switch';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { FilePreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import AssociatedFiles from 'components/Common/AssociatedFiles';
import AssociatedCustomerStatement from 'components/Common/AssociatedFiles/CustomerStatement';
import CustomerStatementPreview from 'components/Common/AssociatedFiles/CustomerStatement/Preview';
import PreviewAssociatedFiles from 'components/Common/AssociatedFiles/PreviewAssociatedFiles';
import { AFiles, AssociatedFile } from 'components/Common/AssociatedFiles/type';
import FileUploadDrawer, { UploadDrawerPosition } from 'components/Common/FileUploadDrawer';
import { RecepientList } from 'components/Common/SelectComponents/RecepientList';
import UploadFiles from 'components/Common/Upload';
import HideWrapper from 'components/Common/Util/HideWrapper';
import InsertEmailTemplates from 'components/EmailTemplates';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ControlledFormItem } from 'components/HigherOrderComponent/GenerateForm';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { Attachments } from 'components/Inbox/EmailConversations/Conversations/Body/ConversationBody/Attachments';
import { EmailFormType } from 'components/Inbox/EmailConversations/EmailThread/type';
import { useAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { useCurrentUser } from 'hooks/use-current-user';
import produce from 'immer';
import { find, head, isEmpty, isEqual, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppPageName } from 'router/constants/page-info';
import { AttachmentIdResponse } from 'services/attachments';
import { accountDetailsSelector } from 'store/authentication/authentication';
import { EmailTemplateItem } from 'store/email-template/types';
import {
  AllOpenInvoicesAttachment,
  DocumentAttachment,
  GenerativeAttachment,
  ReceivablePlaceHolder,
} from 'store/email/types';
import { InvoiceDropdownInterfaceProps } from 'store/invoice/type';
import { Emailable, EmailableType } from 'types/activities/email';
import { Attachment } from 'types/common/attachments';
import { StatementPeriodType } from 'types/customerstatement/customer-statement';
import { AccountConfigKey } from 'types/entities/account';
import { BaseCustomer } from 'types/entities/customer';
import { EmailTemplates, FolderType } from 'types/entities/email-templates';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import useCustomerStatement from 'util/hooks/useCustomerStatement';
import useOpen from 'util/hooks/useOpen';
import useUploadFileList from 'util/hooks/useUploadFileList';
import { ActionableEntity, InvoiceFollowupAction } from '..';
import { SelectReciepientType } from '../style';
import { createView as t } from '../texts';
import { EEmailParams, IComposeEmail, stakeHolderListAttributes } from '../type';
import { Recepients } from './Recepients';

interface propsType extends IComposeEmail {
  allowUpload?: boolean;
  allowAttachInvoice?: boolean;
  emailFor?: ActionableEntity;
  fromType?: EmailableType;
  ignoredInvoiceGroups?: ReceivablePlaceHolder[];
  folderType?: FolderType;
  validFolders?: FolderType[];
  invoiceFollowupAction?: InvoiceFollowupAction;
  placeHolderStyles?: React.CSSProperties;
  periodType?: StatementPeriodType;
  invoiceFileName?: string;
  emailType?: EmailFormType;
  drawerClassName?: string;
  extraFilesComp?: React.ReactNode;
  documents?: AssociatedFile[];
  handleUpdateDocument?: (value: AssociatedFile[]) => void;
  page?: AppPageName;
  isInbox?: boolean;
  showCustomerSelect?: boolean;
  attachements?: Attachment[];
  hideFrom?: boolean;
  loading?: Boolean;
  customers?: BaseCustomer[];
  onToChange?: (value: stakeHolderListAttributes[]) => void;
  onCCChange?: (value: stakeHolderListAttributes[]) => void;
  isParentCustomer?: boolean;
  invoiceList?: InvoiceDropdownInterfaceProps[] | InvoiceBasicDetails[];
}

const questionCircleIcon = (
  <FontAwesomeIcon
    icon={['far', 'circle-question']}
    style={{ color: 'var(--geekblue-6)', cursor: 'pointer' }}
  />
);
export const FileIcon = (
  <FontAwesomeIcon icon={['far', 'files']} color="var(--blue-4)" fontSize={16} />
);

interface FromOption extends stakeHolderListAttributes {
  is_AR?: boolean;
}

const ComposeEmail = (props: propsType) => {
  const [to, setTo] = useState<Array<stakeHolderListAttributes>>([]);
  const [cc, setCC] = useState<Array<stakeHolderListAttributes>>([]);
  const [ccVisible, setCCVisible] = useState((props.cc ?? []).length > 0);
  const [bcc, setBCC] = useState<Array<stakeHolderListAttributes>>([]);
  const [bccVisible, setBCCVisible] = useState((props.bcc ?? []).length > 0);
  const [from, setFrom] = useState<stakeHolderListAttributes | undefined>();
  const [subject, setSubject] = useState<string>(props.subject ?? '');
  const [body, setBody] = useState<string>(props.body ?? '');
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<
    EmailTemplateItem | EmailTemplates
  >();
  const isSingleCustomer = props.customers?.length === 1;
  const isParentCustomer = isSingleCustomer ? head(props.customers)?.is_parent_customer : undefined;
  const isSingleInvoice = props.invoiceList?.length === 1;
  const isParentFromInvoiceList = isSingleInvoice
    ? head(props.invoiceList as InvoiceDropdownInterfaceProps[])?.is_parent_customer
    : false;
  const { filteredList, getLabel } = useAREmailData();
  const [attachedFiles, setAttachedFiles] = useState(props.attachements);
  const isCustomerStatementEnabled = useConfig(AccountConfigKey.CUSTOMER_STATEMENT);
  const [attachInvoiceVisible, setAttachInvoiceVisible] = useState<boolean>(false);
  const hideConsolidatedSOAAction =
    props.isParentCustomer || isParentFromInvoiceList || isParentCustomer;
  function getOptionsFrom() {
    const defaultOption: FromOption = {
      type: EmailableType.USER,
      is_AR: false,
      id: currentLoggedInUser?.id,
      email: currentLoggedInUser?.email,
      label: currentLoggedInUser?.email,
    };

    const ar: FromOption[] = map(filteredList, (list) => {
      const Label = getLabel(list.email, list.subsidiary_ids);
      return {
        type: EmailableType.ACCOUNT,
        is_AR: true,
        id: list?.id as unknown as number,
        email: list?.email,
        label: Label as unknown as string,
      };
    });
    return {
      MY_INBOX: {
        key: 'MY_INBOX',
        label: 'My Email',
        options: [defaultOption],
      },
      AR_INBOX: {
        key: 'AR_INBOX',
        label: 'AR Email',
        options: ar,
      },
    };
  }

  const [attachAllOpenInvoicesVisible, setAttachAllOpenInvoicesVisible] = useState<boolean>(false);
  const [associatedFiles, setAssociatedFiles] = useState(initializeAssociatedFiles);

  const customersStatement = initializeCustomerStatement();
  const periodType = initializePeriodType();
  const isWorkflowEnabled = useConfig(AccountConfigKey.WORKFLOW_ENABLED);
  const isInvoiceLevel = props.emailFor === ActionableEntity.INVOICE;
  const { statements, handleEnable, handleRemove, enable, handleFormChange } = useCustomerStatement(
    periodType,
    customersStatement
  );

  const recipientEntity = props.emailFor;
  const hasArEmailPrivilege = usePrivilegeStatus([ERolesAndPrivileges.AR_EMAIL]);
  const { control, setValue, trigger, getValues, resetField, reset, unregister } = useFormContext();

  function checkEmptyContent(content: string) {
    const emptySubjectAndbodyContent = '<div><br></div>';
    return isEqual(content, emptySubjectAndbodyContent) || isEmpty(content);
  }

  const shouldConfirmBeforeTemplateInsert = useCallback(() => {
    const { body, subject } = getValues();
    const emptyBody = checkEmptyContent(body);
    const emptySubject = checkEmptyContent(subject);

    return !emptyBody || !emptySubject;
  }, [getValues]);

  const {
    handleFileChange,
    fileList,
    uploadedFileIds,
    uploadedFiles,
    handleFileRemove,
    uploading,
    handleBeforeUpload,
  } = useUploadFileList<AttachmentIdResponse>({ key: 'id' });
  const accountAREmail = useSelector(accountDetailsSelector);
  const currentLoggedInUser = useCurrentUser();
  const dispatch = useDispatch();
  function handleAttachmentFileChange(id?: number | string) {
    if (!id) return;
    const updatedFiles = attachedFiles?.filter((file) => file.id !== id) || [];
    setAttachedFiles([...updatedFiles]);
  }
  const CurrentThreadAttachments = (
    <>
      {props.emailType === EmailFormType.FORWARD && attachedFiles ? (
        <Attachments
          attachments={attachedFiles}
          hideTitle={true}
          onRemove={handleAttachmentFileChange}
        />
      ) : null}
    </>
  );

  const { open, toggleOpen, onClose } = useOpen({ open: false });

  function handleHideInvoiceDelivery() {
    return !isWorkflowEnabled || (isWorkflowEnabled && !isInvoiceLevel);
  }

  const hideInvoiceDelivery = useMemo(handleHideInvoiceDelivery, [
    isWorkflowEnabled,
    isInvoiceLevel,
  ]);

  function initializePeriodType() {
    return props.periodType ?? 'RELATIVE';
  }
  function initializeAssociatedFiles() {
    if (!props.invoiceFileName) return AFiles;
    return AFiles.map((file) => {
      return { ...file, name: props.invoiceFileName };
    });
  }

  function initializeCustomerStatement() {
    if (!selectedEmailTemplate?.id) return;
    if (!selectedEmailTemplate.attachments) return;
    const value = find(selectedEmailTemplate.attachments, {
      type: 'GENERATIVE_ATTACHMENT',
    }) as GenerativeAttachment;
    if (!value || !value.list.length) return;

    return value.list[0];
  }

  function handleAssociatedFileChange(id?: number | string, status?: boolean) {
    if (!id) return;
    const updatedFiles = produce(associatedFiles, (draft) => {
      const isExist = draft.findIndex((file) => file.id === id);

      draft[isExist].checked = status;
      return draft;
    });
    setAssociatedFiles(updatedFiles);
    handleAttachInvoices(status as boolean);
  }

  function handleAttachInvoices(status: boolean) {
    const invoices = getValues(EEmailParams.invoice_ids);
    if (props.invoiceFollowupAction === InvoiceFollowupAction.STATEMENT_OF_INVOICES) {
      if (invoices && Boolean(invoices.length)) {
        setAttachInvoiceVisible(status);
      } else {
        setAttachAllOpenInvoicesVisible(status);
      }
      return;
    }
    if (props.emailFor === ActionableEntity.INVOICE) {
      setAttachInvoiceVisible(status);
    } else {
      setAttachAllOpenInvoicesVisible(status);
    }
  }

  const getFormatedEmailData = (userList: Emailable[] | Emailable): stakeHolderListAttributes[] => {
    return Array.isArray(userList)
      ? userList?.map((user) => {
          return {
            email: user.email as string,
            type: user.type ?? EmailableType.CONTACT,
            id: user.id,
            title: user.title,
            contact_type: user.type === EmailableType.CONTACT ? user.contact_type : undefined,
            label: `${user.email} ${user.title ? `(${user.title})` : ''}`,
          };
        })
      : [];
  };

  function getSelectedDocuments() {
    if (!props.documents) return [];
    return props.documents.filter((f) => Boolean(f.checked));
  }

  const selectedDocuments = useMemo(getSelectedDocuments, [props.documents]);

  useEffect(() => {
    const ids = selectedDocuments?.map((value) => {
      if (value.mode === 'SINGLE' && value.level === ActionableEntity.CUSTOMER) {
        return value.id;
      }
      return undefined;
    });
    setValue(EEmailParams.customer_document_ids, ids);
  }, [selectedDocuments, setValue]);
  useEffect(() => {
    const ids = selectedDocuments?.map((value) => {
      if (value.mode === 'BULK' && value.level === ActionableEntity.CUSTOMER) {
        return value.id;
      }
      return undefined;
    });

    setValue(EEmailParams.customer_document_type_ids, ids);
  }, [selectedDocuments, setValue]);
  useEffect(() => {
    const customerDocuments = selectedDocuments.filter((f) => f.entity === 'CUSTOMER');
    const invoiceDocuments = selectedDocuments.filter((f) => f.entity === 'INVOICE');
    if (customerDocuments.length) {
      setValue(
        EEmailParams.customer_document_type_ids,
        customerDocuments.map((f) => f.id)
      );
    }
    if (invoiceDocuments.length) {
      setValue(
        EEmailParams.invoice_document_type_ids,
        invoiceDocuments.map((f) => f.id)
      );
    }
  }, [selectedDocuments, setValue]);
  useEffect(() => {
    const ids = selectedDocuments?.map((value) => {
      if (value.mode === 'SINGLE' && value.level === ActionableEntity.INVOICE) {
        return value.id;
      }
      return undefined;
    });
    setValue(EEmailParams.invoice_document_ids, ids);
  }, [selectedDocuments, setValue]);
  // split into 2
  // merge stakeHolderListAttributes & emailableList
  useEffect(() => {
    if (props.showRelativeOptionsList) {
      return;
    }
    if (Array.isArray(props.to) && props.to.length) {
      setTo(getFormatedEmailData(props.to));
    }
    if (Array.isArray(props.cc) && props.cc.length) {
      setCCVisible(true);
      setCC(getFormatedEmailData(props.cc));
    }
    if (props.subject) {
      setSubject(props.subject);
    }
  }, [
    accountAREmail,
    currentLoggedInUser.sender_email,
    currentLoggedInUser.id,
    props.showRelativeOptionsList,
    hasArEmailPrivilege,
    props.cc,
    props.to,
    props.subject,
    props.fromType,
  ]);

  useEffect(() => {
    setValue(EEmailParams.file_upload_ids, uploadedFileIds);
  }, [setValue, uploadedFileIds]);
  useEffect(() => {
    if (props.subject) {
      setSubject(props.subject);
    }
  }, [props.subject]);

  useEffect(() => {
    setValue(EEmailParams.to, to);
  }, [setValue, to]);

  useEffect(() => {
    setValue(EEmailParams.cc, cc);
  }, [cc, setValue]);

  useEffect(() => {
    setValue(EEmailParams.bcc, bcc);
  }, [bcc, setValue]);

  useEffect(() => {
    setValue(EEmailParams.from, {
      ...from,
      id: from?.id,
    });
  }, [from, setValue]);

  useEffect(() => {
    setValue(EEmailParams.subject, subject);
  }, [setValue, subject]);

  useEffect(() => {
    setValue(EEmailParams.body, body);
  }, [body, setValue]);

  useEffect(() => {
    setValue(EEmailParams.attach_current_invoice, attachInvoiceVisible, {
      shouldValidate: true,
    });
  }, [attachInvoiceVisible, setValue]);

  useEffect(() => {
    setValue(EEmailParams.attach_all_open_invoices, attachAllOpenInvoicesVisible, {
      shouldValidate: true,
    });
  }, [attachAllOpenInvoicesVisible, setValue]);

  useEffect(() => {
    setValue(EEmailParams.generative_attachments, enable ? [statements] : []);
  }, [enable, setValue, statements]);
  useEffect(() => {
    if (props.emailType === EmailFormType.FORWARD) {
      setValue(EEmailParams.attachments, attachedFiles);
    }
  }, [attachedFiles, setValue, props.emailType]);

  useEffect(() => {
    if (props.isInbox) return;
    setValue(EEmailParams.followup_type, props.emailFor);
  }, [props.emailFor, props.isInbox, setValue]);

  // remove this.
  useEffect(() => {
    if (props.to && props.to.length) {
      setTo(
        getFormatedEmailData(props.to).map((recepient) => {
          delete recepient.label;
          return recepient;
        }) as stakeHolderListAttributes[]
      );
    }
  }, [props.to]);

  useEffect(() => {
    const defaultOption: FromOption = {
      type: EmailableType.USER,
      id: currentLoggedInUser?.id,
      email: currentLoggedInUser?.email,
      label: currentLoggedInUser?.email,
    };
    setFrom(props.from ?? defaultOption);
  }, [currentLoggedInUser?.email, currentLoggedInUser?.id, props.from]);

  useEffect(() => {
    setSubject(props.subject ?? '');
  }, [props.subject]);

  useEffect(() => {
    setBody(props.body ?? '');
  }, [props.body]);

  useEffect(() => {
    if (props.invoiceFollowupAction) {
      setAttachInvoiceVisible(false);
      setAttachAllOpenInvoicesVisible(false);
    }
  }, [props.invoiceFollowupAction, setValue]);

  useEffect(() => {
    if (!props.loading) {
      return;
    }
    setTo([]);
    setCC([]);
    setBCC([]);
    setAttachedFiles([]);
  }, [props.loading]);

  function handleInsertTemplate(selectedTemplate: EmailTemplates) {
    const invoices = getValues(EEmailParams.invoice_ids);

    setSelectedEmailTemplate(selectedTemplate);
    if (!selectedTemplate?.id) {
      return;
    }
    setValue(EEmailParams.subject, selectedTemplate.subject);
    setValue(EEmailParams.body, selectedTemplate.body);
    const value = find(selectedTemplate.attachments, {
      type: 'ALL_OPEN_INVOICES',
    }) as AllOpenInvoicesAttachment;

    const status = value ? Boolean(value.type) : false;

    if (props.invoiceFollowupAction === InvoiceFollowupAction.STATEMENT_OF_INVOICES) {
      if (invoices && Boolean(invoices.length)) {
        setAttachInvoiceVisible(status);
      } else {
        setAttachAllOpenInvoicesVisible(status);
      }
    } else if (props.emailFor === ActionableEntity.INVOICE) {
      setAttachInvoiceVisible(status);
    } else {
      setAttachAllOpenInvoicesVisible(status);
    }

    const predicate = (previousState: AssociatedFile[]) =>
      produce(previousState, (draft) => {
        draft[0].checked = status;
        return draft;
      });

    setAssociatedFiles(predicate);

    const templateDocuments = find(selectedTemplate.attachments, {
      type: 'DOCUMENT',
    }) as DocumentAttachment;

    if (templateDocuments) {
      handleInsertDocuments(templateDocuments);
      return;
    }
    const updatedValues = props.documents?.map((f) => {
      return { ...f, checked: false };
    });
    props.handleUpdateDocument?.(updatedValues as any);
    const event = new CustomEvent('update-document', { detail: updatedValues });
    document.dispatchEvent(event);
  }

  function handleInsertDocuments(templateDocument: DocumentAttachment) {
    const customerIds = templateDocument.customer_document_type_ids
      ? templateDocument.customer_document_type_ids?.map((f) => String(f))
      : [];
    const invoiceIds = templateDocument.invoice_document_type_ids
      ? templateDocument.invoice_document_type_ids?.map((f) => String(f))
      : [];
    const ids = [...(customerIds ?? []), ...(invoiceIds ?? [])];
    const updatedValues = props.documents?.map((f) => {
      if (ids.includes(String(f.typeId))) {
        return { ...f, checked: true };
      }
      return { ...f, checked: false };
    });
    props.handleUpdateDocument?.(updatedValues as any);
    const event = new CustomEvent('update-document', { detail: updatedValues });
    document.dispatchEvent(event);
  }

  function getSignature() {
    const parser = new DOMParser();
    const { body } = getValues();
    const doc = parser.parseFromString(body, 'text/html').body;
    const hasSignature = doc.querySelector('.signature');

    if (hasSignature) {
      const signature = find(filteredList, { id: from?.id });
      return signature?.signature?.content;
    }
    return;
  }

  function onSubjectChange(updatedHeader: string | null) {
    setSubject(updatedHeader ?? '');
    trigger(EEmailParams.subject);
  }

  function onBodyChange(updatedBody?: string | null) {
    setBody(updatedBody ?? '');
    trigger(EEmailParams.body);
  }

  function handleInvoiceDeliveryChange(checked: boolean) {
    setValue(EEmailParams.invoice_delivery, checked);
  }
  const CustomerStatement = (
    <HideWrapper hide={!isCustomerStatementEnabled}>
      <AssociatedCustomerStatement
        checked={enable}
        onChange={handleEnable}
        key={String(enable)}
        statement={statements}
        onFormChange={handleFormChange}
        hideConsolidatedAction={
          isSingleCustomer || isSingleInvoice ? !hideConsolidatedSOAAction : false
        }
      />
    </HideWrapper>
  );

  const AssociatedFileComp = (
    <Flex direction="column" gap="--space-8">
      <AssociatedFiles
        associatedFiles={associatedFiles}
        onChange={handleAssociatedFileChange}
        extra={CustomerStatement}
      />
      {props.extraFilesComp}
    </Flex>
  );

  const PreviewAssociatedFileComp = (
    <>
      {associatedFiles
        .filter((file) => Boolean(file.checked))
        .map((file) => {
          return (
            <div>
              <PreviewAssociatedFiles
                key={file.id}
                associatedFile={file}
                onRemove={handleAssociatedFileChange}
              />
            </div>
          );
        })}
    </>
  );

  const CustomerStatementPreviewComp = (
    <HideWrapper hide={!isCustomerStatementEnabled}>
      <div>
        <CustomerStatementPreview
          fileType={statements.file_type}
          onRemove={handleRemove}
          hide={!enable}
          statementType={statements.attachment_type}
        />
      </div>
    </HideWrapper>
  );

  const ExtraPreview = (
    <>
      {CustomerStatementPreviewComp}
      {PreviewAssociatedFileComp}
      {selectedDocuments.map((f) => {
        return (
          <FilePreview
            previewIcon={f.mode === 'BULK' ? FileIcon : undefined}
            hidePreview={f.mode === 'BULK'}
            fileId={Number(f.id)}
            previewId={f.previewId}
            fileSize={f.file_size}
            fileName={f.file_name as string}
            key={f.id}
            onRemove={(id) => {
              const updatedFiles = produce(props.documents, (draft) => {
                if (!draft) return [];
                const isExist = draft.findIndex((file) => file.id === String(id));

                draft[isExist].checked = false;
                return draft;
              });
              props.handleUpdateDocument?.(updatedFiles as any);

              const event = new CustomEvent('update-document', { detail: updatedFiles });
              document.dispatchEvent(event);
            }}
          />
        );
      })}
    </>
  );

  const AllAttachments = (
    <>
      {CurrentThreadAttachments}
      {ExtraPreview}
    </>
  );

  return (
    <Flex direction="column">
      <SelectReciepientType className="align-items-center">
        <Col span={12}>
          <Texto color="--gray-9">{t.to.label}</Texto>
        </Col>
        <Col span={12} className="text-end">
          <Button type="link" onClick={() => setCCVisible(!ccVisible)}>
            {t.cc.label}
          </Button>
          <Button type="link" onClick={() => setBCCVisible(!bccVisible)}>
            {t.bcc.label}
          </Button>
        </Col>
      </SelectReciepientType>
      <Row>
        <Col span={24}>
          {props.loading ? (
            <Skeleton.Input style={{ width: '100%' }} />
          ) : (
            <ControlledFormItem fieldName={EEmailParams.to} control={control} validateParam="email">
              <Recepients
                placeHolder={t.to.placeholder}
                selectedValues={to}
                emailRecepients={props.groupedOptions}
                showRelativeOptionsList={props.showRelativeOptionsList}
                onChangeCallback={(usersList: stakeHolderListAttributes[]) => {
                  setTo(usersList);
                  props.onToChange?.(usersList);
                }}
                entity={recipientEntity}
              />
            </ControlledFormItem>
          )}
        </Col>
      </Row>
      {ccVisible && (
        <>
          <Row style={{ marginTop: 2 }}>
            <Col span={12}>
              <Texto color="--gray-9">{t.cc.label}</Texto>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {props.loading ? (
                <Skeleton.Input style={{ width: '100%' }} />
              ) : (
                <ControlledFormItem
                  fieldName={EEmailParams.cc}
                  control={control}
                  validateParam="email"
                >
                  <Recepients
                    entity={recipientEntity}
                    placeHolder={t.cc.placeholder}
                    selectedValues={cc}
                    emailRecepients={props.groupedOptions}
                    showRelativeOptionsList={props.showRelativeOptionsList}
                    onChangeCallback={(usersList: stakeHolderListAttributes[]) => {
                      setCC(usersList);
                      props.onCCChange?.(usersList);
                    }}
                  />
                </ControlledFormItem>
              )}
            </Col>
          </Row>
        </>
      )}
      {bccVisible && (
        <>
          <Row style={{ marginTop: 2 }}>
            <Col span={12}>
              <Texto color="--gray-9">{t.bcc.label}</Texto>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {props.loading ? (
                <Skeleton.Input style={{ width: '100%' }} />
              ) : (
                <ControlledFormItem
                  fieldName={EEmailParams.bcc}
                  control={control}
                  validateParam="email"
                >
                  <Recepients
                    entity={recipientEntity}
                    placeHolder={t.bcc.placeholder}
                    selectedValues={bcc}
                    emailRecepients={props.groupedOptions}
                    showRelativeOptionsList={props.showRelativeOptionsList}
                    onChangeCallback={(usersList: stakeHolderListAttributes[]) => {
                      setBCC(usersList);
                    }}
                  />
                </ControlledFormItem>
              )}
            </Col>
          </Row>
        </>
      )}
      <HideWrapper hide={Boolean(props.hideFrom)}>
        <>
          <Row style={{ marginTop: 2 }}>
            <Col span={24}>
              <Texto color="--gray-9">{t.from.label}</Texto>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ControlledFormItem fieldName={EEmailParams.from} control={control}>
                <SelectComponent
                  placeholder={t.from.placeholder}
                  className="filter-drop-down"
                  allowClear={true}
                  shouldFormat={true}
                  labelAtrributeName={'label'}
                  valueAtrributeName={'id'}
                  optionsStyle="grouped"
                  selectedValues={from?.email}
                  dropdownRender={(options: React.ReactElement) => {
                    return <RecepientList styleType={'assignedToStyle'} options={options} />;
                  }}
                  groupedOptions={getOptionsFrom()}
                  onChangeCallBack={(usersList: IProps<stakeHolderListAttributes>) => {
                    if (usersList?.valueobject?.id) {
                      setFrom({
                        id: usersList.valueobject?.id as number,
                        type: usersList?.valueobject?.type ?? EmailableType.USER,
                        title: usersList.valueobject.title,
                        email: usersList?.valueobject?.email,
                      });
                    } else {
                      setFrom(undefined);
                    }
                  }}
                />
              </ControlledFormItem>
            </Col>
          </Row>
        </>
      </HideWrapper>

      <Row align="middle" justify="space-between" style={{ marginBottom: 'var(--space-8)' }}>
        <Col>
          <InsertEmailTemplates
            confirmBeforeInsert={shouldConfirmBeforeTemplateInsert}
            validFolders={props.validFolders}
            onTemplateSelect={handleInsertTemplate}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 2 }}>
        <Col span={24}>
          <Texto color="--gray-9">{t.subject.label}</Texto>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ControlledFormItem fieldName={EEmailParams.subject} control={control}>
            <RichEmailSubjectEditor
              value={subject}
              onChange={onSubjectChange}
              validFolders={props.validFolders}
              onTemplateSelect={handleInsertTemplate}
              placeHoldersWrapperOverrides={props.placeHolderStyles}
            />
          </ControlledFormItem>
        </Col>
      </Row>
      <Row style={{ marginTop: 2 }}>
        <Col span={24}>
          <Texto color="--gray-9">{t.body.label}</Texto>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ControlledFormItem fieldName={EEmailParams.body} control={control}>
            <RichEmailContentEditor
              folderType={props.folderType}
              onChange={onBodyChange}
              signature={getSignature()}
              placeholder={t.body.placeholder}
              onTemplateSelect={handleInsertTemplate}
              validFolders={props.validFolders}
              placeHoldersWrapperOverrides={props.placeHolderStyles}
              popoverPlacement="top"
            />
          </ControlledFormItem>
        </Col>
      </Row>
      <Flex direction="column" align="flex-start" gap="var(--space-12)">
        <HideWrapper hide={hideInvoiceDelivery}>
          <Flex gap="var(--space-4)" align="center">
            <ToggleSwitchComponent
              label="Consider as Invoice Delivery"
              onChange={handleInvoiceDeliveryChange}
            />
            <Tooltip title={'For invoices that are already delivered, this email will not be sent'}>
              {questionCircleIcon}
            </Tooltip>
          </Flex>
        </HideWrapper>
        {props.allowUpload && (
          <UploadFiles<RcFile>
            showLoading={uploading}
            onRemove={handleFileRemove}
            fileList={uploadedFiles as RcFile[]}
            onClick={toggleOpen}
            extra={AllAttachments}
          >
            <FileUploadDrawer
              open={open}
              mask={false}
              width={480}
              forceRender
              onClose={onClose}
              fileList={fileList}
              extra={AssociatedFileComp}
              action="/api/attachments"
              onChange={handleFileChange}
              className={props.drawerClassName}
              beforeUpload={handleBeforeUpload}
              positionSpacing={props.isInbox ? undefined : UploadDrawerPosition.UPLOAD_POSITION}
            />
          </UploadFiles>
        )}
      </Flex>

      {/* {attachInvoiceVisible && (<Row className="mt-4">
      <Col span={24}>
        <Radio.Group value={selectedAttachmentTypeOption} onChange={onAttachInvoiceTypeChange}>
          {attachmentTypeOptions.map((attachmentTypeOption, index) => {
            return (<Radio.Button key={index} value={attachmentTypeOption.value}>{attachmentTypeOption.label}</Radio.Button>)
          })}
        </Radio.Group>
      </Col>
    </Row>)}
    {attachInvoiceVisible && attachOpenInvoices === true && (<Row className="mt-4">
      <Col span={24}>
        <Checkbox options={openInvoiceAttachmentTypeOptions} value={selectedopenInvoiceAttachmentTypeOptions} onChange={onAttachInvoiceMethodChange} />
      </Col>
    </Row>)} */}
    </Flex>
  );
};

export default ComposeEmail;
