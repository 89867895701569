import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { WorkflowButtonsProps } from './types';
import { useWorkflowOwner } from '../WorkflowCardList/Hooks';

export default function WorkflowButtons(props: WorkflowButtonsProps) {
  const { toggleTriggerOpen, buttonTitle, disabled, disabledText, size = 'small' } = props;
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? null : (
        <Tooltip title={disabled ? disabledText : ''}>
          <GrowButton
            size={size}
            type="default"
            icon={<FontAwesomeIcon icon={['far', 'plus-circle']} />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleTriggerOpen();
            }}
            {...props}
          >
            {buttonTitle}
          </GrowButton>
        </Tooltip>
      )}
    </>
  );
}
