import dayjs from 'dayjs';
import produce from 'immer';
import { cloneDeep, forEach, isString, map, unset } from 'lodash';
import { WorkFlowEventTypes, WorkflowMetaDto } from 'types/entities/workflow';
import { ActionTypes } from './CustomAutomations/Action/types';
import { handleValidatedFields } from './CustomAutomations/Trigger/EventCondition/Helper';
import { WorkFlowTriggerFormResponse } from './CustomAutomations/Trigger/type';
import { ProcessFormDataProps, WorkflowActionRule } from './CustomAutomations/type';

/**
 *
 * @param workflowMeta
 * @param values
 * @param entityUpdated
 * @returns WorkflowMetaDto
 *
 * This function modifies the exsiting workflow meta by adding the modified trigger
 * here we are reseting the actions is the trigger entity is changed.
 */
export function modifyTriggerData(
  workflowMeta: WorkflowMetaDto,
  values: WorkFlowTriggerFormResponse,
  entityUpdated: boolean
): WorkflowMetaDto {
  return {
    ...workflowMeta,
    workflow_rules: map(workflowMeta.workflow_rules, (rule) => {
      const triggerMetaData = handleValidatedFields(values, rule.condition.id);
      const updatedRule = {
        ...rule,
        ...triggerMetaData,
      };

      if (entityUpdated) {
        updatedRule.actions = [];
      }

      return updatedRule;
    }),
  };
}

/**
 *
 * @param workflowMeta
 * @param actionMeta
 * @returns WorkflowMetaDto
 *
 * This function modifies the exsiting workflow meta by adding the modified actions array
 */
export function modifyActionData(
  workflowMeta: WorkflowMetaDto,
  actionMeta: WorkflowActionRule<ActionTypes>[]
): WorkflowMetaDto {
  return {
    ...workflowMeta,
    workflow_rules: map(workflowMeta?.workflow_rules, (rule) => ({
      ...rule,
      actions: actionMeta,
    })),
  };
}

/**
 *
 * @param data
 * @returns WorkflowMetaDto
 *
 * This function removed the id created by uuid to maintain uniqueness
 * between different actions we should remove them before sending it to the api
 */
export function removeActionIdString(data: WorkflowMetaDto) {
  const workflowData = cloneDeep(data);

  forEach(workflowData?.workflow_rules, (rule) => {
    forEach(rule.actions, (action) => {
      if (isString(action.id)) {
        unset(action, 'id');
      }
    });
  });

  return workflowData;
}

/**
 *
 * @param currentMeta
 * @param actionDataResult
 * @param triggerMeta
 * @param entityUpdated
 * @returns WorkflowMetaDto
 *
 * This function is called when new form is created since we are using form as store
 * while creating new form if we are having workflow_rules then modify the existing one or else
 * create new object
 */
export function processFormData({
  currentMeta,
  actionDataResult,
  triggerMeta,
  entityUpdated,
}: ProcessFormDataProps) {
  if (currentMeta && currentMeta.workflow_rules && currentMeta.workflow_rules.length) {
    return produce(currentMeta.workflow_rules, (draft) => {
      draft.forEach((_, index) => {
        const draftRule = draft[index];
        Object.assign(draftRule, triggerMeta);
        if (actionDataResult && !entityUpdated) {
          draftRule.actions = actionDataResult;
        }
        if (entityUpdated) {
          draftRule.actions = [];
        }
      });
    });
  } else {
    return [
      {
        ...triggerMeta,
        ...(actionDataResult ? { actions: actionDataResult } : {}),
      },
    ];
  }
}

export function isAfterCurrentDate(dateValue?: string) {
  const today = dayjs();
  return dateValue
    ? dayjs(dateValue, 'YYYY-MM-DD').startOf('day').isAfter(today.startOf('day'), 'day')
    : false;
}
export function isBeforeCurrentDate(dateValue?: string) {
  const today = dayjs();
  return dateValue
    ? dayjs(dateValue, 'YYYY-MM-DD').startOf('day').isBefore(today.startOf('day'), 'day')
    : false;
}

export const ConditionListInvoiceStatusLabels: Record<WorkFlowEventTypes, string> = {
  INVOICE_VALUE: 'Invoice Amount',
  DUE_DAYS: 'Invoice Age',
  INVOICE_STATUS: 'Invoice Status',
  ISSUE_DATE: 'Invoice Issue Date',
  INVOICE_DUE_DATE: 'Invoice Due Date',
  INVOICE_BALANCE: 'Invoice Balance',
  INVOICE_CREATE: 'Invoice Created',
  PAYEMENT_CREATE: 'Payment Create',
  PAYMENT_STATUS: 'Payment Status',
  PAYMENT_BALANCE: 'Payment Balance',
  CREDIT_MEMO_STATUS: 'Credit Memo Status',
  CREDIT_MEMO_BALANCE: 'Credit Memo Balance',
  CREDIT_MEMO_CREATED: 'Credit Memo Created',
  INVOICE_PDF: 'Invoice PDF',
  DELIVERY_FLAG: 'Invoice Email',
  DISPUTE_TYPE: 'Dispute Type',
  DISPUTE_ASSIGNEE: 'Dispute Owner',
  DISPUTE_STATUS: 'Dispute Status',
  TASK_STATUS: 'Task Status',
  TASK_ASSIGNEE: 'Task Owner',
  PTP_DATE: 'PTP Date',
  PTP_STATUS: 'PTP Status',
  PTP_AMOUNT: 'PTP Amount',
  ESCALATION_STATUS: 'Escalation Status',
  ESCALATION_ASSIGNEE: 'Escalation Owner',
  COLLECTION_OWNER: 'Collection Owner Changed',
  CREDIT_TERM: 'Credit Term Changed',
  OPEN_INVOICE_COUNT: 'No. of Open Invoices Changed',
  OUTSTANDING_VALUE: 'Customer Outstanding Value Changed',
  PRIMARY_CONTACT: 'Primary Contact Changed',
  SECONDARY_CONTACT: 'Secondary Contact Changed',
  DUE_DATE: 'Due date',
  INVOICE_CUSTOM_FIELD: 'Invoice custom Field',
  CUSTOMER_CUSTOM_FIELD: 'Customer custom field',
  INVOICE_SUBSIDIARY: 'Subsidiary',
  CUSTOMER_SUBSIDIARY: 'Subsidiary',
  INVOICE_UPDATE: 'Invoice Update',
  INVOICE_FOLLOWUP_STATUS: 'Invoice Followup status',
  OVERDUE_DAYS: 'Overdue days',
  INVOICE_PAYMENT_STATUS: 'Invoice payment status',
  INVOICE_BALANCE_DUE_TX: 'Invoice Balance Due Transaction',
  INVOICE_DUE: 'Invoice Due',
  INVOICE_IS_OVERDUE: 'Invoice Is Overdue',
  INVOICE_OVERDUE: 'Invoice Overdue',
  INVOICE_TAX: 'Invoice Tax',
  INVOICE_IS_DUE: 'Invoice Is Due',
};

export function getConditionStatusLabel(status?: WorkFlowEventTypes) {
  if (!status) return;
  return ConditionListInvoiceStatusLabels[status];
}
