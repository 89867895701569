import { useQuery } from '@tanstack/react-query';
import { getCustomersCreditsAdjustments } from 'services/customers';
import { ICreditAdjustmentsParams } from 'store/customer/types';
import { CreditMemo } from 'types/activities/credit-memo';
import { PaginationResponse } from 'types/api/common';

interface useCreditAdjustmentQueryProps {
  customerId: number;
  param: ICreditAdjustmentsParams;
  onSuccessCallBack: (data: PaginationResponse<CreditMemo>) => void;
}

export function useCreditAdjustmentQuery(props: useCreditAdjustmentQueryProps) {
  return useQuery({
    queryKey: ['credit-notes', props.param],
    queryFn: () => getCustomersCreditsAdjustments(props.customerId, props.param),
    cacheTime: 0,
    staleTime: 0,
    onSuccess(data) {
      props.onSuccessCallBack(data);
    },
  });
}
