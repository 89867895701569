import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { UploadFileProps } from 'components/Settings/WorkFlowAutomation/CustomAutomations/type';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { saveAttachment } from 'services/attachments';
import ky from 'services/ky';
import {
  deleteWorkFlowList,
  filterWorkFlowList,
  getWorkFlowList,
  getWorkFlowMeta,
  modifyWorkflowMeta,
  submitWorkflowMeta,
  toggleWorkFlowFollowup,
} from 'services/workflows';
import { UserBasic } from 'types/entities/user';
import { WorkFlowFiltersDTO, WorkflowMetaDto } from 'types/entities/workflow';

const t = {
  deleteSuccess: 'Workflow deleted successfully',
  upload_failed: 'Failed to upload. Try again!',
};

const HALF_DAY = 12 * 60 * 60 * 1000;
export interface ScheduleFormProps {
  start_date?: string;
  end_date?: string;
}
export interface ToggleParamProps {
  toggleState: string;
  id: string;
  scheduleData: ScheduleFormProps;
}

export function useWorkFlowListGetQuery() {
  return useQuery({
    queryKey: ['workflow-list'],
    retry: false,
    queryFn: () => getWorkFlowList(),
  });
}

export function useApplyFilterWorkFlowList(params: Partial<WorkFlowFiltersDTO>) {
  return useQuery({
    queryKey: ['workflow-list', params],
    retry: false,
    queryFn: () => filterWorkFlowList(params),
  });
}

export function useUpdateToggleWorkFlow() {
  return useMutation({
    mutationFn: (params: Partial<ToggleParamProps>) => toggleWorkFlowFollowup(params),
    retry: false,

    onError() {
      notify.error('Failed to update workflow');
    },
  });
}

export function useGetWorkflowMeta(params: string, onSuccess: (data: WorkflowMetaDto) => void) {
  return useQuery({
    queryKey: ['workflow-meta', params],
    enabled: !!params,
    queryFn: () => getWorkFlowMeta(params),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useSubmitWorkflowMetaMutation() {
  return useMutation({
    mutationKey: ['submit-workflow-meta'],
    mutationFn: (params: Partial<WorkflowMetaDto>) => submitWorkflowMeta(params),
    retry: false,
    onSuccess() {},
    onError() {},
  });
}

export function useModifyWorkflowMetaMutation() {
  return useMutation({
    mutationKey: ['modify-workflow-meta'],
    mutationFn: (params: Partial<WorkflowMetaDto>) => modifyWorkflowMeta(params),
    retry: false,
    onSuccess() {},
    onError() {},
  });
}

export function useWorkFlowDeleteQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteWorkFlowList(id),
    retry: false,
    onSuccess() {
      queryClient.invalidateQueries(['workflow-list']);
      notify.success(t.deleteSuccess);
    },
    onError() {},
  });
}

export function useWorkflowAttachmentsMutation() {
  return useMutation({
    mutationFn: (props: UploadFileProps) => saveAttachment(props.file, props.type),
    retry: false,
    onError() {
      notify.error(t.upload_failed);
    },
  });
}

export function useGetWorkflowOwners() {
  const { isWorkflowOwner } = useWorkflowOwner();
  return useQuery({
    queryKey: ['workflow-owners'],
    queryFn: () => ky.get('users/workflow-owners').json<UserBasic[]>(),
    staleTime: HALF_DAY,
    cacheTime: HALF_DAY,
    enabled: !isWorkflowOwner,
  });
}

export function useGetWorkflowApprovers() {
  const { isWorkflowOwner } = useWorkflowOwner();
  return useQuery({
    queryKey: ['approvers-list'],
    queryFn: () => ky.get('users/approvers').json<UserBasic[]>(),
    staleTime: HALF_DAY,
    cacheTime: HALF_DAY,
    enabled: !isWorkflowOwner,
  });
}
