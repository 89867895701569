import { CalendarDaysRuleEvent, CalendarEventType } from 'components/Common/CalenderEvent/type';

type PartialEventData<T extends BaseRuleEvent> = {
  id: T['id'];
  type: T['type'];
  data: Partial<T['data']>;
};

/* START HERE */
export type FollowupRuleEvent =
  | InvoiceAgeRuleEvent
  | CalendarDaysRuleEvent
  | StatusChangeRuleEvent
  | ManualRuleEvent;

export type FollowupRuleEventForm =
  | PartialEventData<InvoiceAgeRuleEvent>
  | PartialEventData<CalendarDaysRuleEvent>
  | PartialEventData<StatusChangeRuleEvent>
  | PartialEventData<ManualRuleEvent>;

/* EVENT TYPES */
export enum FollowupEventType {
  INVOICE_AGE = 'INVOICE_AGE',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MANUAL = 'MANUAL',
  CALENDAR = 'CALENDAR',
}

export enum InvoiceDaysType {
  ISSUE_DATE = 'ISSUE_DATE',
  DUE_DATE = 'DUE_DATE',
  EXP_PAY_DATE = 'EXP_PAY_DATE',
  PAUSE_DATE = 'PAUSE_DATE',
}

export enum StatusChangeEventEntityType {
  INVOICE = 'INVOICE',
  INVOICE_FOLLOWUP = 'INVOICE_FOLLOWUP',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  ESCALATION = 'ESCALATION',
  DISPUTE = 'DISPUTE',
  EMAIL = 'EMAIL',
}

/* STATUS CHANGE EVENT TYPE LIFECYCLE STATES */
export enum InvoiceLifecycleStage {
  PARTIAL_PAYMENT = 'PARTIAL_PAYMENT',
  PAID = 'PAID',
  CLOSED = 'CLOSED',
}

export enum EmailLifecycleStage {
  CUSTOMER_RESPONDED = 'CUSTOMER_RESPONDED',
  SENT = 'SENT',
}

export enum DisputeLifecycleStage {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum EscalationLifecycleStage {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum PromiseToPayLifecycleStage {
  OPEN = 'OPEN',
  BROKEN = 'BROKEN',
  CLOSED = 'CLOSED',
}

/* EVENT DATA */
export interface InvoiceDaysEventData {
  days: number;
  type: InvoiceDaysType;
}

export interface CalendarDaysEventData {
  type: CalendarEventType;
  days: number[];
}

export type StatusChangeEventData =
  | InvoiceStatusChangeEventData
  | DisputeStatusChangeEventData
  | PromiseToPayStatusChangeEventData
  | EscalationStatusChangeEventData
  | EmailStatusChangeEventData;

interface BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType;
  status: string;
  offset: number;
}

interface InvoiceStatusChangeEventData extends BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType.INVOICE;
  status: InvoiceLifecycleStage;
}

interface DisputeStatusChangeEventData extends BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType.DISPUTE;
  status: DisputeLifecycleStage;
}

interface PromiseToPayStatusChangeEventData extends BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType.PROMISE_TO_PAY;
  status: PromiseToPayLifecycleStage;
}

interface EscalationStatusChangeEventData extends BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType.ESCALATION;
  status: EscalationLifecycleStage;
}

interface EmailStatusChangeEventData extends BaseStatusChangeEventData {
  entity: StatusChangeEventEntityType.EMAIL;
  status: EmailLifecycleStage;
}

/* EVENT */
export interface BaseRuleEvent {
  id: number | string;
  type: FollowupEventType;
  data: unknown;
}

export interface InvoiceAgeRuleEvent extends BaseRuleEvent {
  type: FollowupEventType.INVOICE_AGE;
  data: InvoiceDaysEventData;
}

interface ManualRuleEvent extends BaseRuleEvent {
  type: FollowupEventType.MANUAL;
  data: null;
}

export interface StatusChangeRuleEvent extends BaseRuleEvent {
  type: FollowupEventType.STATUS_CHANGE;
  data: StatusChangeEventData;
}
