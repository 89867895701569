import useCollectionStrategiesData from 'components/Common/SelectComponents/hooks/usecollectionStrategiesData';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { ActionTitleProps, CollectionStrategyActionType } from '../../types';
import { actionIcons } from '../ActionIcons';
import CustomFieldTitle from 'components/Settings/WorkFlowAutomation/common/CustomFieldTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { find } from 'lodash';
import { t } from '../../text';

export default function CollectionStrategyTitle(
  props: ActionTitleProps<CollectionStrategyActionType>
) {
  // const { item } = props;
  // const { data, isLoading } = useCollectionStrategiesData();
  // const collectionStrategyName = find(
  //   data,
  //   (strategyItem) => strategyItem.id === parseInt(item?.value)
  // );

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.EDIT_STRATEGY.title}
      icon={actionIcons.EDIT_STRATEGY.icon}
      backgroundColor={actionIcons.EDIT_STRATEGY.style.backgroundColor}
      title={actionIcons.EDIT_STRATEGY.title}
      strong={props.strong}
    />
  );
}
