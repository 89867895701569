import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import {
  createCustomerContact,
  getCustomerContacts,
  getPrimaryAndSecondaryContacts,
  updateCustomerContact,
} from 'components/HigherOrderComponent/KeyActivitesContainer/ProfileCards/CustomerContacts/queries/contacts';
import { CustomerContact } from 'components/HigherOrderComponent/KeyActivitesContainer/ProfileCards/types';
import { clone, findIndex, isEqual } from 'lodash';
import { ContactsType } from 'types/entities/contact';
import formatFullName from 'util/format-full-name';
const HALF_DAY = 12 * 60 * 60 * 1000;
const t = {
  customerContactCreationsuccesText: 'Customer Contact added',
  queryKey: 'contact-list',
  createSuccess: (contactName: string, updateMode?: boolean) =>
    `‘${contactName}’ successfully ${updateMode ? 'updated' : 'added'}.`,
  updateSuccess: 'Contact updation successful!',
  errorTextCreate: 'Contact creation failed!',
  errorTextUpdate: 'Contact updation failed!',
  contactTypePrimary: 'Primary',
  queryKeyContacts: 'contact-list-customer-level',
};

type UpsertContactMutationProp = {
  clearAndCloseModal: () => void;
};

export function useGetCustomersQuery({
  queryKey,
  baseUrl,
  entityId,
}: {
  queryKey: string[];
  baseUrl: string;
  entityId: number;
}) {
  return useQuery({
    queryKey,
    queryFn: () => getCustomerContacts({ baseUrl, id: entityId }),
  });
}

export function useUpdateContact({ clearAndCloseModal }: UpsertContactMutationProp) {
  const { handleSuccess } = useHandleOnSuccesOfContactUpsert(
    t.updateSuccess,
    clearAndCloseModal,
    true
  );
  return useMutation({
    mutationFn: updateCustomerContact,
    onSuccess: handleSuccess,
    onError: async ({ response }) => {
      const errResponse = await (response as Response).json();
      notify.error(errResponse?.message ?? t.errorTextUpdate);
    },
  });
}

export function useCreateContact({ clearAndCloseModal }: UpsertContactMutationProp) {
  const { handleSuccess } = useHandleOnSuccesOfContactUpsert(
    t.customerContactCreationsuccesText,
    clearAndCloseModal
  );
  return useMutation({
    mutationFn: createCustomerContact,
    onSuccess: handleSuccess,
    onError: async ({ response }) => {
      const errResponse = await (response as Response).json();
      notify.error(errResponse?.message ?? t.errorTextCreate);
    },
  });
}

// Contacts with contact_type === primary is expected to show on top of the list
// The following method checks the contact type and add or update to correspoding position to list.

function useHandleOnSuccesOfContactUpsert(
  succesMessage: string,
  clearAndCloseModal: () => void,
  updateMode?: boolean
) {
  const queryClient = useQueryClient();
  function handleSuccess(response: CustomerContact) {
    const noMatchingIndex = -1;
    const isContactPrimaryType = isEqual(response.contact_type?.display_text, t.contactTypePrimary);
    const contactsList = clone(queryClient.getQueryState([t.queryKeyContacts])?.data);
    if (contactsList && Array.isArray(contactsList)) {
      const itemIndex = findIndex(contactsList, (existingContact) =>
        isEqual(existingContact?.id, response?.id)
      );
      const contactNotFound = isEqual(itemIndex, noMatchingIndex);
      if (contactNotFound) {
        const updateMethod = isContactPrimaryType ? 'unshift' : 'push';
        contactsList[updateMethod](response);
      }
      if (isContactPrimaryType && !contactNotFound) {
        contactsList.splice(itemIndex, 1, response);
      } else contactsList[itemIndex] = response;
      notify.success(succesMessage, {
        description: t.createSuccess(formatFullName(response), updateMode),
      });
    }
    queryClient.setQueryData([t.queryKeyContacts], contactsList);
    clearAndCloseModal();
  }
  return { handleSuccess };
}

export function useGetContacts(contactType: ContactsType) {
  return useQuery({
    queryKey: [`get-${contactType}`],
    queryFn: () => getPrimaryAndSecondaryContacts(contactType),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}
