import { QueryKey } from '@tanstack/react-query';
import { EmailConversation } from 'types/activities/email';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { Body } from './Body';

interface ParentEmailProps {
  defaultOpen: boolean;
  conversation: EmailConversation;
  data: EmailThreadResponse;
  queryKey?: QueryKey;
  readOnly?: boolean;
}

function ParentEmail(props: ParentEmailProps) {
  return <Body {...props} />;
}

export { ParentEmail };
