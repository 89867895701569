import { Form, Input } from 'antd';

interface ActionTypeProps {
  value: any;
}

function ActionType(props: ActionTypeProps) {
  return (
    <Form.Item hidden initialValue={{ value: props.value }} name="type">
      <Input hidden />
    </Form.Item>
  );
}

export { ActionType };
