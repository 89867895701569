// import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { ShowIfActive } from 'components/Common/FilterComponents/Common/ShowIfActive';
import { DateRangeWrapper } from 'components/Common/FilterComponents/FilterWrapper/DateRangeWrapper';
import { MinMaxWrapper } from 'components/Common/FilterComponents/FilterWrapper/MinMaxWrapper';
import { MultiSelectCheckboxWrapper } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapper';
import { MultiSelectCheckboxWrapperWithSearch } from 'components/Common/FilterComponents/FilterWrapper/MultiSelectCheckboxWrapperWithSearch';
import { StandardFilterGroup } from 'components/Common/FilterComponents/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { User } from 'types/entities/user';

import { CustomerSeacrhFilter } from 'components/BaseComponents/Filters/CustomerSearch';
import { SubsidiaryFilter } from 'components/Common/EntityFilter';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useGetInvoiceStatusOptions } from 'components/Common/hooks/useGetFilteredInvoiceStatus';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ClosedInvoicestatusList } from 'constants/collection-activities/invoice-statuses';
import { isEmpty, isEqual } from 'lodash';
import { useBillingContactsQuery } from 'queries/users';
import { getAllDisputeTypes } from 'services/dispute-type';
import { searchInvoiceContacts } from 'services/invoice';
import { getCollectionManagers, getSalesManagers } from 'services/users';
import styled from 'styled-components';
import { Emailable } from 'types/activities/email';
import { AccountConfigKey } from 'types/entities/account';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { useClosedInvoiceListFilter } from '../../hooks/useClosedInvoiceListFilter';
import {
  ArrayKeys,
  PrimitiveKeys,
  updateArrayPayload,
  updatePrimitivePayload,
} from '../../reducers/filter-values-reducer';
import { FilterKeys } from '../../reducers/type';
import { Contacts } from '../Contacts';
import { CustomFieldFilters } from '../CustomField';

type InvoiceFilterProps = ReturnType<typeof useClosedInvoiceListFilter>;

const t = {
  placeholder: {
    collectionOwner: 'Search Collection Owners',
    CSMmanagers: 'Search CSM Managers',
    accountManager: 'Search Account Managers',
    collectionManager: 'Search Collection Managers',
    salesManager: 'Search Sales Managers',
    billingContact: 'Search Billing Contact',
    customers: 'Search Customers',
  },
  emptyText: {
    collectionOwner: 'No Collection Owner Found',
    CSMmanagers: 'No CSM Managers Found',
    accountManager: 'No Account Managers Found',
    collectionManager: 'No Collection Managers Found',
    salesManager: 'No Sales Managers Found',
    billingContacts: 'No Billing Contacts Found',
    customers: 'No Customers Found',
  },
};

const StyledFilterDropdownTitle = styled.div`
  padding-bottom: var(--space-8);
  color: var(--gray-gray-9);
  font-weight: var(--fs-semibold);
`;

export function useInvoiceFilter(props: InvoiceFilterProps) {
  const { filterValues, updateFilterValues, filterGroups, updateFilterGroups } = props;
  const [collectionManagers, setCollectionManagers] = useState<User[]>([]);
  const [salesManagers, setSalesManagers] = useState<User[]>([]);
  const { data: billingContacts } = useBillingContactsQuery();
  const advancedContactEnabled = useConfig(AccountConfigKey.ADVANCED_CONTACTS);
  function updateArrayValue<T extends ArrayKeys>(payload: updateArrayPayload<T>) {
    const { key, value } = payload;

    updateFilterValues({
      type: 'UPDATE_ARRAY',
      payload: { key, value },
    });
  }

  function updatePrimitiveValue<T extends PrimitiveKeys>(
    updatedValues: Array<updatePrimitivePayload<T>>
  ) {
    updateFilterValues({
      type: 'UPDATE_PRIMITIVES',
      payload: updatedValues,
    });
  }

  function onRemoveFilter(groupId: string, filterId: string) {
    updateFilterGroups({
      type: 'UPDATE_FILTER',
      payload: { groupId: groupId, filterId: filterId, updatedValues: { active: false } },
    });
  }
  function initialDataFetchEffect() {
    getCollectionManagers()
      .then((data) => {
        setCollectionManagers(data);
      })
      .catch((e) => {
        console.log(e);
      });
    getSalesManagers()
      .then((data) => {
        setSalesManagers(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function computedCollectionManagerOptionsMemo() {
    if (collectionManagers && Boolean(collectionManagers.length)) {
      return collectionManagers.map((manager) => ({
        value: manager.id,
        label: `${manager.first_name} ${manager.last_name ?? ''}`,
      }));
    }
    return [];
  }
  function computedSalesManagerOptionsMemo() {
    if (salesManagers && Boolean(salesManagers.length)) {
      return salesManagers.map((manager) => ({
        value: manager.id,
        label: `${manager.first_name} ${manager.last_name ?? ''}`,
      }));
    }
    return [];
  }

  function computedBillingContactOptions(contacts: Emailable[] | undefined) {
    if (contacts && Boolean(contacts.length)) {
      return contacts
        .filter((contact) => !!contact.email)
        .map((contact, contactItemsIndex) => {
          const firstName = contact?.first_name ?? '';
          const lastName = contact?.last_name ?? '';
          const isNameEmpty = isEmpty(firstName) && isEmpty(lastName);
          let label = isNameEmpty ? contact?.email ?? '' : `${firstName} ${lastName}`;
          const contactHasValidName = !isNameEmpty;

          if (contactHasValidName) {
            const contactWithDuplicateName = contacts?.find(
              (item, findContactIndex) =>
                !isEqual(findContactIndex, contactItemsIndex) &&
                isEqual([item.first_name ?? '', item.last_name ?? ''], [firstName, lastName])
            );
            label += contactWithDuplicateName && contact.email ? `(${contact.email})` : '';
          }

          return {
            value: contact.id,
            label,
          };
        });
    }
    return [];
  }

  async function searchCollectionManagers(keyword: string) {
    if (collectionManagers && Boolean(collectionManagers.length)) {
      return collectionManagers.filter(
        (manager) =>
          manager.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (manager.last_name && manager.last_name.toLowerCase().includes(keyword.toLowerCase())) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()}${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            )) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()} ${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            ))
      );
    }
    return [];
  }
  async function searchSalesManagers(keyword: string) {
    if (salesManagers && Boolean(salesManagers.length)) {
      return salesManagers.filter(
        (manager) =>
          manager.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (manager.last_name && manager.last_name.toLowerCase().includes(keyword.toLowerCase())) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()}${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            )) ||
          (manager.last_name &&
            `${manager.first_name.toLowerCase()} ${manager.last_name.toLowerCase()}`.includes(
              keyword.toLowerCase()
            ))
      );
    }
    return [];
  }

  const handleSearchCollectionManagers = useCallback(searchCollectionManagers, [
    collectionManagers,
  ]);
  const handleSearchSalesManagers = useCallback(searchSalesManagers, [salesManagers]);
  useEffect(initialDataFetchEffect, []);
  /** InvoiceStatusFilter  start */
  const ClosedInvoiceList = useGetInvoiceStatusOptions(ClosedInvoicestatusList, false);
  const invoiceStatusFilterConfig = {
    name: 'invoice-status',
    label: 'Invoice status',
    options: ClosedInvoiceList,
  };

  const InvoiceStatusFilter = (
    <MultiSelectCheckboxWrapper
      key={FilterKeys.INVOICE_STATUS}
      filterConfig={invoiceStatusFilterConfig}
      value={filterValues.status}
      showClear
      onSubmit={(value) => updateArrayValue({ key: 'status', value })}
    />
  );
  /** InvoiceStatusFilter  end */

  /** Due Date Filter start */
  /**
   * Product team requested commenting this for time being
   */

  // const DueDateFilterConfig = {
  //   name: 'filter-by-due-date',
  //   label: 'Due Date',
  // };

  // const DueDateFilterId = FilterKeys.DUE_DATE;

  // const DueDateFilterFilter = (
  //   <ShowIfActive
  //     filterGroupId={StandardFilterGroup.INVOICE}
  //     filterId={DueDateFilterId}
  //     filterGroups={filterGroups}
  //     key={DueDateFilterId}
  //   >
  //     <DateRangeWrapper
  //       key={FilterKeys.DUE_DATE}
  //       filterConfig={DueDateFilterConfig}
  //       value={{
  //         from: filterValues.due_date_from,
  //         to: filterValues.due_date_to,
  //         type: filterValues.due_date_range,
  //       }}
  //       showClear
  //       onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, DueDateFilterId)}
  //       onSubmit={({ from, to, type }) =>
  //         updatePrimitiveValue([
  //           { key: 'due_date_from', value: from },
  //           { key: 'due_date_to', value: to },
  //           { key: 'due_date_range', value: type },
  //         ])
  //       }
  //     />
  //   </ShowIfActive>
  // );
  /** Due Date Filter end */

  /** TotalBalanceFilter start */
  const totalBalanceFilterConfig = { name: 'total-balance', label: 'Due Amount' };
  const totalBalanceFilterId = FilterKeys.TOTAL_DUE_AMOUNT;

  const TotalBalanceFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={totalBalanceFilterId}
      filterGroups={filterGroups}
      key={totalBalanceFilterId}
    >
      <MinMaxWrapper
        prefix={{ type: 'ACCOUNT_CURRENCY' }}
        filterConfig={totalBalanceFilterConfig}
        value={{ min: filterValues.invoice_amount_min, max: filterValues.invoice_amount_max }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, totalBalanceFilterId)}
        onSubmit={(value) =>
          updatePrimitiveValue([
            { key: 'invoice_amount_min', value: value.min },
            { key: 'invoice_amount_max', value: value.max },
          ])
        }
      />
    </ShowIfActive>
  );
  /** TotalBalanceFilter end */

  /** IssueDateFilter start */
  const IssueDateFilterConfig = {
    name: 'filter-by-due-date',
    label: 'Issued date Range',
  };

  const IssueDateFilterId = FilterKeys.ISSUED_DATE;

  const IssueDateFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={IssueDateFilterId}
      filterGroups={filterGroups}
      key={IssueDateFilterId}
    >
      <DateRangeWrapper
        filterConfig={IssueDateFilterConfig}
        value={{
          from: filterValues.issue_date_from,
          to: filterValues.issue_date_to,
          type: filterValues.issue_date_range,
        }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, IssueDateFilterId)}
        onSubmit={({ from, to, type }) =>
          updatePrimitiveValue([
            { key: 'issue_date_from', value: from },
            { key: 'issue_date_to', value: to },
            { key: 'issue_date_range', value: type },
          ])
        }
      />
    </ShowIfActive>
  );
  /** IssueDateFilter end */

  /** CollectionManagerFilter start */
  const collectionManagerOptions = useMemo(computedCollectionManagerOptionsMemo, [
    collectionManagers,
  ]);

  const collectionManagerFilterConfig = {
    name: 'collection-manager',
    label: 'Collection Manager',
    options: collectionManagerOptions,
    shouldIncludeUnassigned: false,
  };

  const CollectionManagerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.COLLECTION_MANAGER_IDS}
      filterGroups={filterGroups}
      key={FilterKeys.COLLECTION_MANAGER_IDS}
    >
      <MultiSelectCheckboxWrapperWithSearch<User, number[]>
        filterConfig={collectionManagerFilterConfig}
        value={filterValues.collection_manager_ids}
        showClear
        onClear={() =>
          onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.COLLECTION_MANAGER_IDS)
        }
        onSubmit={(value) => {
          updateArrayValue({ key: 'collection_manager_ids', value });
        }}
        onSearch={handleSearchCollectionManagers}
        placeholder={t.placeholder.collectionManager}
        emptyText={t.emptyText.collectionManager}
      />
    </ShowIfActive>
  );
  /**   /** CollectionManagerFilter  end */

  /** SalesManagerFilter start */
  const salesManagerOptions = useMemo(computedSalesManagerOptionsMemo, [salesManagers]);

  const salesManagerFilterConfig = {
    name: 'sales-manager',
    label: 'AM/CSM Team Lead',
    options: salesManagerOptions,
    shouldIncludeUnassigned: false,
  };

  const SalesManagerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.SALES_MANAGER_IDS}
      filterGroups={filterGroups}
      key={FilterKeys.SALES_MANAGER_IDS}
    >
      <MultiSelectCheckboxWrapperWithSearch<User, number[]>
        labelAttribute={['first_name', 'last_name']}
        valueAttribute="id"
        filterConfig={salesManagerFilterConfig}
        value={filterValues.sales_manager_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.SALES_MANAGER_IDS)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'sales_manager_ids', value });
        }}
        onSearch={handleSearchSalesManagers}
        placeholder={t.placeholder.salesManager}
        emptyText={t.emptyText.salesManager}
      />
    </ShowIfActive>
  );
  /**   /** SalesManagerFilter  end */

  /** Closed Date Range start */
  const ClosedDateFilterConfig = {
    name: 'filter-by-closed-date',
    label: 'Closed Date Range',
  };

  const ClosedDateFilterId = FilterKeys.CLOSED_DATE;

  const ClosedDateFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={ClosedDateFilterId}
      filterGroups={filterGroups}
      key={ClosedDateFilterId}
    >
      <DateRangeWrapper
        filterConfig={ClosedDateFilterConfig}
        value={{
          from: filterValues.closed_date_from,
          to: filterValues.closed_date_to,
          type: filterValues.closed_date_range,
        }}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, ClosedDateFilterId)}
        onSubmit={({ from, to, type }) =>
          updatePrimitiveValue([
            { key: 'closed_date_from', value: from },
            { key: 'closed_date_to', value: to },
            { key: 'closed_date_range', value: type },
          ])
        }
      />
    </ShowIfActive>
  );
  /** IssueDateFilter end */

  /** BillingContactFilter start */

  const billingContactComputedList = useCallback(computedBillingContactOptions, []);
  const billingContactoptions = useMemo(
    () => billingContactComputedList(billingContacts),
    [billingContactComputedList, billingContacts]
  );
  const BillingContactFilterTitle = (
    <StyledFilterDropdownTitle>Billing Contacts</StyledFilterDropdownTitle>
  );

  const billingContactFilterConfig = {
    name: 'billing-contacts',
    label: 'Billing Contacts',
    options: billingContactoptions,
    customTitle: BillingContactFilterTitle,
    shouldIncludeUnassigned: true,
  };

  function handleBillingContactFilterSubmit(value: number[]) {
    updatePrimitiveValue([
      {
        key: 'is_billing_contact_assigned',
        value: value.length ? !value.includes(-1) : undefined,
      },
    ]);
    updateArrayValue({ key: 'contact_ids', value });
  }

  const BillingContactFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterGroups={filterGroups}
      filterId={FilterKeys.BILLING_CONTACT}
      key={FilterKeys.BILLING_CONTACT}
    >
      <MultiSelectCheckboxWrapperWithSearch<Emailable, number[]>
        filterConfig={billingContactFilterConfig}
        value={filterValues.contact_ids}
        shouldDebounce
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, FilterKeys.BILLING_CONTACT)}
        onSubmit={handleBillingContactFilterSubmit}
        valueAttribute="id"
        labelAttribute={['first_name', 'last_name']}
        onSearch={searchInvoiceContacts}
        placeholder={t.placeholder.billingContact}
        emptyText={t.emptyText.billingContacts}
        style={{ width: '100%' }}
        onSearchDataTransform={billingContactComputedList}
      />
    </ShowIfActive>
  );

  /** BillingContactFilter End */

  /** SubsidiaryFilter  start */
  const subdsidiaryFilterId = FilterKeys.SUBSIDIARY;
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);

  const SubsidiaryFilterJSX = isSubsidiaryEnabled ? (
    <HideWrapper hide={!isSubsidiaryEnabled}>
      <SubsidiaryFilter
        value={filterValues.subsidiary_ids}
        onClear={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, subdsidiaryFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'subsidiary_ids', value });
          updatePrimitiveValue([{ key: 'is_subsidiary_not_assigned', value: value.includes(-1) }]);
        }}
      />
    </HideWrapper>
  ) : null;
  /** SubsidiaryFilter  end */

  /**
   * Customer Contacts start
   */

  const CustomerContacts = <Contacts {...props} />;

  /**
   * Customer Contacts end
   */

  const CustomerFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.CUSTOMER}
      filterId={FilterKeys.CUSTOMER_DROPDOWN}
      filterGroups={filterGroups}
      key={FilterKeys.CUSTOMER_DROPDOWN}
    >
      <CustomerSeacrhFilter
        value={filterValues.customer_ids}
        onSubmit={(value) => {
          updateArrayValue({ key: 'customer_ids', value });
        }}
        onRemove={() => onRemoveFilter(StandardFilterGroup.CUSTOMER, FilterKeys.CUSTOMER_DROPDOWN)}
      />
    </ShowIfActive>
  );
  const disputeTypeFetchCallback = useCallback(async () => {
    const options = await getAllDisputeTypes();

    return options?.map((disputeType) => ({
      value: disputeType.id,
      label: disputeType.name,
    }));
  }, []);

  const disputeTypeFilterConfig = {
    name: 'Dispute Type',
    label: 'Dispute Type',
    options: disputeTypeFetchCallback,
  };

  const disputeTypeFilterId = FilterKeys.DISPUTE_TYPE;

  const DisputeTypeFilter = (
    <ShowIfActive
      filterGroupId={StandardFilterGroup.INVOICE}
      filterId={disputeTypeFilterId}
      filterGroups={filterGroups}
      key={disputeTypeFilterId}
    >
      <MultiSelectCheckboxWrapper
        filterConfig={disputeTypeFilterConfig}
        value={filterValues.dispute_type_ids}
        showClear
        onClear={() => onRemoveFilter(StandardFilterGroup.INVOICE, disputeTypeFilterId)}
        onSubmit={(value) => {
          updateArrayValue({ key: 'dispute_type_ids', value });
        }}
      />
    </ShowIfActive>
  );

  const InvoiceCustomFieldFilter = (
    <CustomFieldFilters
      key={FilterKeys.CUSTOM_FIELD}
      {...props}
      customFieldType={CustomFieldEntityType.INVOICE}
    />
  );

  const CustomerCustomFieldFilter = (
    <CustomFieldFilters
      key={FilterKeys.CUSTOM_FIELD}
      {...props}
      customFieldType={CustomFieldEntityType.CUSTOMER}
    />
  );
  /** CustomFields  end */
  const DefaultFilterComponents = [InvoiceStatusFilter, SubsidiaryFilterJSX];

  const ExtraFilterComponents = [
    TotalBalanceFilter,
    IssueDateFilter,
    ClosedDateFilter,
    CollectionManagerFilter,
    SalesManagerFilter,
    advancedContactEnabled && BillingContactFilter,
    advancedContactEnabled && CustomerContacts,
    CustomerFilter,
    DisputeTypeFilter,
    InvoiceCustomFieldFilter,
    CustomerCustomFieldFilter,
  ];

  return {
    DefaultFilterComponents,
    ExtraFilterComponents,
  };
}
