import { Form } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { FormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { WorkflowForms } from '../../../type';
import { t } from '../../text';
import { ActionActivityTypes } from '../../types';
import { ActionType } from '../ActionType';

export default function AddNote(props: any) {
  const { data, form } = props;

  const Description = (
    <FormItem
      label={t.notes}
      name="notes"
      rules={[{ required: true, message: t.enter_notes_required }]}
      initialValue={data?.notes}
    >
      <DescriptionTextArea placeholder={t.enter_notes} />
    </FormItem>
  );

  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <AddNoteReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          {Description}
          <ActionType value={ActionActivityTypes.NOTE} />
        </Form>
      )}
    </>
  );
}

export function AddNoteReadOnly(props: any) {
  const { data } = props;
  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent label="Notes" content={<TextBlock value={data?.notes} />} />
    </div>
  );
}
