import { getBusinessUnitsThunk } from 'controllers/business-unit';
import { getCollectionStatusesThunk } from 'controllers/collection-status';
import { getReceivableDisplayThunk } from 'controllers/receivable-display';
import { getRegionsThunk } from 'controllers/region';
import { getActiveUsersThunk, getUsersThunk } from 'controllers/user';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCollectionPeriod } from 'services/collection-period';
import { getCollectionPlan } from 'services/collection-planning';
import { getEmailTemplatesFolder } from 'services/email-template-folders';
import { getUserNotificationStatus } from 'services/user-notification';
import { appLoadingProgressHandler } from 'store/authentication/authentication';
import { CollectionPeriodActions } from 'store/collection-period';
import { setInitialFolderId } from 'store/email-template/email-template';
import { setInserts } from 'store/insert';
import { AppDispatch } from 'store/store';
import { setUserNotifications } from 'store/user-notifications';
import { PromiseAllWithProgress } from './PromiseAllWithProgress';

const TOTAL_INITIAL_API_CALLS = 12;
export const TOTAL_LOADER_PROGRESS = TOTAL_INITIAL_API_CALLS + 2;
// 2 points => 1  for booting react ; 1 for authenticating user

export function useInitialDataFetcher() {
  const dispatch: AppDispatch = useDispatch();

  const loadIntitalData = useCallback(() => {
    async function fetchAndSaveUserStatus() {
      try {
        const notificationStatus = await getUserNotificationStatus();
        return dispatch(setUserNotifications(notificationStatus));
      } catch (e) {
        console.log('Error fetching user notification status');
      }
    }
    async function fetchAndSaveEmailTemplateFolder() {
      try {
        const emailTemplateFolders = await getEmailTemplatesFolder();
        const folderId = emailTemplateFolders.folders[0].id;
        dispatch(setInitialFolderId({ folderId }));

        return dispatch(setInserts(emailTemplateFolders));
      } catch (e) {
        console.log('Error fetching email templates');
      }
    }
    async function getCollectionPeriodInfo() {
      try {
        const collectionPeriodInfo = await fetchCollectionPeriod();

        return dispatch(CollectionPeriodActions.updateCollectionPeriod(collectionPeriodInfo));
      } catch (e) {
        console.log(' Error fetching Collection periods ', e);
      }
    }
    async function getCollectionPlanInfo() {
      try {
        const collectionPeriodInfo = await getCollectionPlan();

        return dispatch(CollectionPeriodActions.updateCollectionPlanInfo(collectionPeriodInfo));
      } catch (e) {
        console.log('Error fetching Collection Plan Info', e);
      }
    }

    fetchAndSaveEmailTemplateFolder();

    // Update the TOTAL_INITIAL_API_CALLS if you change API calls here
    return PromiseAllWithProgress(
      [
        dispatch(getBusinessUnitsThunk()),
        dispatch(getRegionsThunk()),
        dispatch(getUsersThunk()),
        dispatch(getActiveUsersThunk()),
        dispatch(getReceivableDisplayThunk()),
        fetchAndSaveUserStatus(),
        getCollectionPeriodInfo(),
        getCollectionPlanInfo(),
        dispatch(getCollectionStatusesThunk()),
      ],
      (progress, len) => {
        const extraProgressSteps = TOTAL_LOADER_PROGRESS - len;
        dispatch(
          appLoadingProgressHandler((progress + extraProgressSteps) / TOTAL_LOADER_PROGRESS)
        );
      }
    );
  }, [dispatch]);

  return { loadIntitalData };
}
