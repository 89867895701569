import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import styled from 'styled-components';
import { getEntriesFromMap } from 'util/array';

const WeekDay = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? 'var(--geekblue-6)' : 'var(--gray-3)')};
  color: ${({ selected }) => (selected ? 'var(--gray-1)' : 'var(--primary-7)')};
  border-radius: 12px;
  padding: var(--space-8) var(--space-16);
  cursor: pointer;
  text-transform: uppercase;
`;

export const weekDays = (() => {
  const map = new Map<number, string>();

  map.set(1, 'Sun');
  map.set(2, 'Mon');
  map.set(3, 'Tue');
  map.set(4, 'Wed');
  map.set(5, 'Thu');
  map.set(6, 'Fri');
  map.set(7, 'Sat');

  return map;
})();
const weekDaysList = getEntriesFromMap(weekDays);

const t = {
  selectDays: 'Select dates from a Week',
};

interface WeekDaysSelectorProps {
  onChange?: (weekNumber: number[]) => void;
  value: number[];
  hideLabel?: boolean;
}

export function WeekDaysSelector(props: WeekDaysSelectorProps) {
  const { onChange, value, hideLabel } = props;

  function handleDaySelect(selectedDay: number) {
    const valuePresent = value.includes(selectedDay);

    const newDaysArray = valuePresent
      ? value.filter((day) => day !== selectedDay)
      : [...value, selectedDay];

    onChange && onChange(newDaysArray.sort());
  }

  return (
    <Flex direction="column" gap="--space-8">
      {!hideLabel && <Texto>{t.selectDays}</Texto>}
      <Flex gap="--space-8" wrap="wrap">
        {weekDaysList.map(([dayNumber, dayText]) => (
          <WeekDay
            selected={value.includes(Number(dayNumber))}
            key={dayNumber}
            onClick={() => handleDaySelect(Number(dayNumber))}
          >
            {dayText}
          </WeekDay>
        ))}
      </Flex>
    </Flex>
  );
}
