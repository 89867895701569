import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, Spin, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { GrowText } from 'components/BaseComponents/Typography';
import { find } from 'lodash';
import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerEntity, updateCustomerDetailsHandler } from 'store/customer/customer';
import { BaseCollectionStrategy, InvoiceStrategyStatus } from 'types/entities/collection-strategy';
import useOpen from 'util/hooks/useOpen';
import { RecepientList } from '../RecepientList';
import useCollectionStrategiesData from '../hooks/usecollectionStrategiesData';
import { CollectionStrategySelectDropDown } from '../style';
import StrategyWarningIcon from './StrategyWarningIcon';
const t = {
  setFollowup: 'Set a collection followup...',
  clearStrategy: 'Clear Strategy',
};

const selectIconStyle: React.CSSProperties = { transform: 'translateY(-2px)' };
const selectStyle: React.CSSProperties = { width: 360 };

interface Props {
  value?: number;
  onChange?: (newValue: number, data?: BaseCollectionStrategy[]) => void;
  saving?: boolean;
  allowClear?: boolean;
  style?: React.CSSProperties;
  placeHolder?: string;
  compact?: boolean;
  open?: boolean;
  handleOpenChange?: (open: boolean) => void;
}

function CollectionStrategySelect(props: Props) {
  const { value, onChange, saving, allowClear } = props;
  const { open: popoverVisible, toggleOpen } = useOpen({ open: false });
  const { collectionStrategyOptions, isLoading, data } = useCollectionStrategiesData();
  const resultantValue = data?.find((strategy) => strategy.id === value)?.id;
  const isDisabledStrategy = find(data, { id: value, enabled: false });
  const customer = useSelector(customerEntity);
  const dispatch = useDispatch();
  const DownArrow = (
    <DownOutlined
      className={resultantValue ? `select-arrow-down` : ''}
      onClick={handleDropdownOpen}
    />
  );

  const ClearIcon = (
    <GrowToolTip title={t.clearStrategy} arrow={false} placement="topLeft">
      <FontAwesomeIcon
        style={selectIconStyle}
        size="lg"
        color="var(--red-7)"
        icon={['far', 'minus-circle']}
      />
    </GrowToolTip>
  );

  const SuffixIcon = (
    <Flex align="center" gap="8px" style={{ pointerEvents: 'auto', opacity: '1' }}>
      {saving ? (
        <Spin style={selectIconStyle} size="small" />
      ) : (
        <>
          {isDisabledStrategy && <StrategyWarningIcon strategyId={resultantValue} />}
          {DownArrow}
        </>
      )}
    </Flex>
  );

  const Placeholder = (
    <Flex align="center" gap={'var(--space-8)'}>
      <FontAwesomeIcon
        // @ts-expect-error
        icon={['fak', 'strategy']}
        fontSize={'var(--fs-14)'}
        color={'var(--gold-6)'}
      />
      <GrowText color="var(--gray-6)">{t.setFollowup}</GrowText>
    </Flex>
  );

  const finalStyle = useMemo(
    () => ({
      ...selectStyle,
      ...props.style,
    }),
    [props.style]
  );

  function handleChange(strategyId: number) {
    const selectedStrategy = data?.find((item) => item.id === strategyId);
    const collection_strategy_V2 = selectedStrategy
      ? {
          ...selectedStrategy,
          invoice_strategy_status: InvoiceStrategyStatus.RUNNING,
        }
      : undefined;
    onChange?.(strategyId);
    dispatch(
      updateCustomerDetailsHandler({
        ...customer.customerDetail,
        collection_strategy_V2,
      })
    );
  }
  function handleDropdownOpen() {
    toggleOpen();
  }

  const CollectionStrategySelectCompo = (
    <CollectionStrategySelectDropDown
      allowClear={allowClear ? { clearIcon: ClearIcon } : false}
      style={finalStyle}
      placeholder={props.placeHolder ? props.placeHolder : Placeholder}
      options={collectionStrategyOptions}
      loading={isLoading || saving}
      disabled={saving}
      suffixIcon={SuffixIcon}
      value={resultantValue}
      onChange={(strategyId) => handleChange(strategyId as number)}
      open={popoverVisible}
      onDropdownVisibleChange={toggleOpen}
      dropdownRender={(options) => (
        <RecepientList
          styleType={collectionStrategyOptions?.length > 1 ? 'strategySelectStyle' : undefined}
          options={options}
        />
      )}
      defaultOpen
      optionRender={(option) => <Tooltip title={option.label}>{option.label}</Tooltip>}
    />
  );
  const CompactSelect = (
    <Select
      placeholder={t.setFollowup}
      open={props.open}
      onDropdownVisibleChange={props.handleOpenChange}
      dropdownRender={(options) => (
        <RecepientList styleType={'strategySelectStyle'} options={options} />
      )}
      optionRender={(option) => <Tooltip title={option.label}>{option.label}</Tooltip>}
      style={props.style}
      onChange={(strategyId) => handleChange(strategyId as number)}
      value={resultantValue}
      options={collectionStrategyOptions}
      loading={isLoading || saving}
      disabled={saving}
      defaultOpen
    />
  );
  return <>{props.compact ? CompactSelect : CollectionStrategySelectCompo}</>;
}
export default memo(CollectionStrategySelect);
