import { SelectProps } from 'antd';
import { useGetCollectionOwner } from 'components/HigherOrderComponent/KeyActivitesContainer/ProfileCards/InternalContacts/InternalContactHooks';
import { head } from 'lodash';
import { WorkFlowEventTypes } from 'types/entities/workflow';

export default function useCollectionOwnerUtil(type?: WorkFlowEventTypes) {
  const { data: collectionOwnerUsers, isLoading: collectionOwnerLoading } = useGetCollectionOwner();

  const collectionOwnerUsersOption: SelectProps['options'] =
    collectionOwnerUsers?.map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      valueobject: user,
    })) ?? [];
  const collectionOwnerInitialValue = head(collectionOwnerUsers)?.id;
  return {
    collectionOwnerUsers,
    collectionOwnerUsersOption,
    collectionOwnerLoading,
    collectionOwnerInitialValue,
  };
}
