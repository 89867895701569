import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowFlex, GrowTypography } from '@sinecycle/growcomponents';
import { AutoComplete, Input, Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
interface BasicSearchComponentProps {
  placeholder?: string;
  searchCallBack: (search: string) => void;
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
  shouldReset: boolean;
  onClearCallBack?: () => void;
  allowClear?: boolean;
  contentOnly?: boolean;
  charLimt?: {
    limit?: number;
  };
  totalRecords?: number;
}

function SearchComponent(props: BasicSearchComponentProps) {
  const {
    placeholder,
    searchCallBack,
    style,
    shouldDebounce = false,
    onClearCallBack,
    allowClear,
    charLimt,
    totalRecords,
  } = props;
  const [value, setValue] = useState<string | undefined>();

  const SearchIcon = <FontAwesomeIcon icon={['far', 'search']} color="var(--gray-9)" />;

  const ClearIcon = (
    <FontAwesomeIcon
      icon={['far', 'times-circle']}
      color="var(--gray-7)"
      className="cursor-pointer"
    />
  );

  function handleChange(value: string) {
    searchCallBack?.(value);
  }

  const onChange = useCallback(handleChange, [searchCallBack]);
  function handleOnClear() {
    if (value) {
      setValue(undefined);
      onClearCallBack && onClearCallBack();
    }
  }

  function handleReset() {
    if (props.shouldReset) {
      setValue(undefined);
    }
  }

  useEffect(handleReset, [props.shouldReset]);

  const debouncedSearch = useMemo(() => {
    return debounce(onChange, shouldDebounce ? 1000 : 0);
  }, [onChange, shouldDebounce]);

  function getInfo() {
    const hasTypedSingleCharacter = value && value.length >= 1; // Check if at least one character is typed
    const isWithinLimit = charLimt?.limit && value && value.length < charLimt.limit; // Check if within the limit
    if (hasTypedSingleCharacter && isWithinLimit) {
      return charLimt?.limit ? `Type ${charLimt.limit - value.length} more characters` : '';
    }
    return; // Return undefined if conditions are not met
  }
  const Info = getInfo();

  return (
    <Tooltip title={Info} placement="right" open={Boolean(Info)}>
      <GrowFlex style={{ width: '100%' }} vertical space="8">
        <AutoComplete
          onSearch={debouncedSearch}
          onChange={(value) => {
            setValue(value);
          }}
          value={value}
          onClear={() => {
            handleOnClear();
          }}
          style={{ width: '100%' }}
          allowClear={
            allowClear
              ? {
                  clearIcon: <Tooltip title="Clear">{ClearIcon}</Tooltip>,
                }
              : undefined
          }
        >
          <Input
            width={'100%'}
            prefix={props.contentOnly ? undefined : SearchIcon}
            placeholder={placeholder}
            style={style}
            value={value}
          />
        </AutoComplete>
        {totalRecords && value ? (
          <GrowTypography.Text style={{ color: 'var(--gray-7)' }}>
            {totalRecords > 1 ? `${totalRecords} results found` : `${totalRecords} result found`}
          </GrowTypography.Text>
        ) : null}
      </GrowFlex>
    </Tooltip>
  );
}

export default SearchComponent;
