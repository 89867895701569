import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowCheckBox, GrowTypography } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { actionTypesConfig } from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import WorkflowButtons from 'components/Settings/WorkFlowAutomation/common/workflowButtons';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { find, flatMap, map, values } from 'lodash';
import { useState } from 'react';
import ActionTypesRenderer from '../ActionConfigurations/actionTypesRenderer';
import { getActionType } from '../ActionConfigurations/ActionTypeTitle';
import { AddActionModal } from '../AddActions/AddActionModal';
import useExtractSelectedType from '../hooks/useExtractSelectedType';
import useHideInvoiceLevelBox from '../hooks/useHideInvoiceLevelBox';
import { t } from '../text';
import { ActionTypeFormProps, ActionTypesConfig } from '../types';
import { isInvoiceLevelAction } from '../utils';

function getActionTypesWithCheckbox(config: ActionTypesConfig) {
  return flatMap(values(config), (category) =>
    map(category.options, (option) => ({
      type: option.id,
      showCheckbox: option.showCheckbox,
    }))
  );
}

export default function ActionForm(props: ActionTypeFormProps) {
  const {
    onUploadFilesOpen,
    form,
    actionRule,
    entityType,
    baseForm,
    onInvoiceLevel,
    isInvoiceLevel,
  } = props;

  const actionType = useFormWatch('type', form);
  const selectedType = props.selectedItem
    ? getActionType(props.selectedItem)
    : props?.actionType?.value?.value;
  const isCheckBox = find(getActionTypesWithCheckbox(actionTypesConfig), { type: selectedType });
  const extractFieldLabel = useExtractSelectedType();

  const [actionOpen, setActionOpen] = useState(false);
  const isCheckedHidden = useHideInvoiceLevelBox(isCheckBox?.showCheckbox, entityType);
  const { isWorkflowOwner } = useWorkflowOwner();
  const selectedAction = extractFieldLabel(selectedType);
  const AddActions = (
    <AddActionModal
      open={actionOpen}
      onClose={() => {
        setActionOpen(false);
      }}
      entityType={entityType}
      onSelect={(value) => {
        const result = isInvoiceLevelAction(value?.id, actionRule);
        form.setFieldsValue({
          actionable_entity: result,
          is_action_saved: false,
          previous_actionable_entity: actionType?.value,
          type: extractFieldLabel(value?.id as any),
          parent_id: undefined,
          rule_id: undefined,
        });

        props.onInsert({ value: extractFieldLabel(value?.id as any) });
      }}
      onOpenChange={(open) => {
        setActionOpen(open);
      }}
    >
      <div>
        <WorkflowButtons
          buttonTitle={t.action}
          size="middle"
          toggleTriggerOpen={() => {
            setActionOpen(!actionOpen);
            form.setFieldsValue({
              is_new_action: true,
              parent_id: undefined,
              rule_id: undefined,
              id: undefined,
              status: undefined,
            });

            props.onInsert();
          }}
          disabled={!entityType}
          disabledText={t.set_trigger}
        />
      </div>
    </AddActionModal>
  );

  const SelectedType = (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <GrowTypography.Text
        strong={!isWorkflowOwner}
        style={{ color: isWorkflowOwner ? '#737373' : 'var(--gary-9)' }}
      >
        Type
      </GrowTypography.Text>

      {isWorkflowOwner ? (
        <>{selectedAction.value ? selectedAction.label : null}</>
      ) : (
        <>
          {selectedType ? (
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-12 tw-py-4 tw-px-8 tw-border-gray-5 tw-border tw-rounded tw-border-solid  tw-min-w-[260px] tw-cursor-default">
              {selectedAction.value ? selectedAction.label : null}
            </div>
          ) : (
            AddActions
          )}
        </>
      )}
    </div>
  );

  const InvoiceLevelCheckBox = (
    <Flex align="center" style={{ marginTop: 'var(--space-24)' }}>
      <GrowCheckBox
        design="border-design"
        checked={isInvoiceLevel}
        onChange={(e) => {
          props.form.setFieldValue('actionable_entity', e.target.checked);
          onInvoiceLevel?.(e.target.checked);
        }}
      >
        {t.invoice_level}
      </GrowCheckBox>
      <Tooltip showArrow={false} title={t.invoice_level_info} placement="topRight">
        <FontAwesomeIcon color="var(--gray-7)" icon={['far', 'info-circle']} />
      </Tooltip>
    </Flex>
  );
  const ActionRenderer = (
    <ActionTypesRenderer
      actionType={selectedType}
      onUploadFilesOpen={onUploadFilesOpen}
      isInvoiceLevel={Boolean(isInvoiceLevel)}
      form={form}
      actionData={props.selectedItem}
      emailFormData={props.emailFormData}
      errors={props.errors}
      handleEmailContentChange={props.handleEmailContentChange}
      baseForm={baseForm}
    />
  );

  const ActionTypesComponent = (
    <Flex direction="row" justify="space-between" align="center">
      {SelectedType}
      <HideWrapper hide={isCheckedHidden || Boolean(isWorkflowOwner)}>
        {InvoiceLevelCheckBox}
      </HideWrapper>
    </Flex>
  );

  return (
    <div className="tw-flex tw-flex-col tw-gap-12 tw-overflow-auto !tw-h-[calc(100vh-350px)]">
      {ActionTypesComponent}
      {ActionRenderer}
    </div>
  );
}
