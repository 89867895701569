import { GrowTypography } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { ActionTypeIconContainer } from '../CustomAutomations/Action/style';
import { ActionTileTitleProps } from './types';

export default function ActionTileTitle(props: ActionTileTitleProps) {
  const { tooltipTitle, icon, title, backgroundColor, strong, titleStyle, custom } = props;
  return (
    <Flex gap={'var(--space-8)'} align="center">
      <Tooltip title={tooltipTitle}>
        <ActionTypeIconContainer backgroundColor={backgroundColor}>{icon}</ActionTypeIconContainer>
      </Tooltip>
      {custom ? (
        custom
      ) : (
        <GrowTypography.Text
          ellipsis={{ tooltip: true }}
          style={{ maxWidth: '200px', color: 'var(--primary-7)', ...titleStyle }}
          strong={strong}
          key={String(strong)}
        >
          {title}
        </GrowTypography.Text>
      )}
    </Flex>
  );
}
