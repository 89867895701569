import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { includes } from 'lodash';
import { ActivityCopyLinkProps, ActivityType } from 'types/activities/activity-types';

const Anchorlink = <FontAwesomeIcon icon={['far', 'link']} color={'var(--gray-8)'} fontSize={12} />;

export default function useGetActivityLink(activitiesMode?: boolean) {
  function ActivityCopyLink(props: ActivityCopyLinkProps) {
    const { data, customer, type } = props;

    const activityType = includes([ActivityType.PAYMENT, ActivityType.CREDIT_MEMO], type)
      ? 'ALL'
      : type;

    const CopyableData = {
      icon: Anchorlink,
      tooltips: 'Copy link',
      text: `${window.origin}/customers/${customer?.id}?current_section=ACTIVITIES&current_activity_tab=${activityType}&activity_type=${type}&activity_id=${data?.id}&view=modal&customer=${customer?.name}&customer_id=${customer?.id}&customer_no=${customer?.customer_no}`,
    };

    return activitiesMode ? <TypographyText copyable={CopyableData} /> : null;
  }

  return ActivityCopyLink;
}
