import { GrowDrawer, GrowFormHeader } from '@sinecycle/growcomponents';
import { CSS } from '@stitches/react';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { getActivityDetails } from 'components/CollectionActivities/activityCardUtils';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { Style } from 'lib/style/style';
import { ELastContactActivity } from 'store/activity-feed/type';
import { ActivityDetailsBaseProps, ActivityType } from 'types/activities/activity-types';
import { ActivitySubcriptionType } from 'types/api/inbox/activity';
import { BaseCustomer } from 'types/entities/customer';
import useOpen from 'util/hooks/useOpen';

interface AssociatedDrawerActivityProps extends ActivityDetailsBaseProps {
  open: boolean;
  onClose: () => void;
  onOpenChange?: (open: boolean) => void;
}

interface AssociatedActivityProps {
  type: Exclude<ActivityType, 'DOCUMENT'>;
  id: number;
  date?: string;
  queryKey?: string;
  customer?: Pick<BaseCustomer, 'name' | 'id'>;
  subscriptionType?: ActivitySubcriptionType;
  css?: CSS;
  isModal?: boolean;
}
const StyledWrapper = Style(Flex, {
  padding: 'var(--space-12) var(--space-16)',
  borderRadius: 'var(--br-1)',
  border: '1px solid var(--gray-4)',
  background: 'var(--gray-2)',
});
const Title = (
  <GrowText strong size="var(--fs-20)">
    Associated Activity
  </GrowText>
);

function AssociatedActivityDrawer(props: AssociatedDrawerActivityProps) {
  const { onClose, open, id, type, hideActions, queryKey, customer, subscriptionType } = props;

  const Component = getActivityDetails({
    type,
    id,
    hideActions,
    queryKey,
    customer,
    subscriptionType,
    isListFetched: true,
  });

  const DrawerHeader = <GrowFormHeader title={Title} onClose={onClose} />;

  return (
    <GrowDrawer
      open={open}
      title={DrawerHeader}
      onClose={onClose}
      closable={false}
      width="70vw"
      destroyOnClose
      design="growfin_design_default"
      maskClosable
      push={false}
      styles={{ body: { padding: '0', height: '100%' } }}
    >
      {Component}
    </GrowDrawer>
  );
}

function AssociatedActivity(props: AssociatedActivityProps) {
  const { type, id, queryKey, customer, subscriptionType, css, isModal } = props;
  const config = getKeyActivityConfig(type as unknown as ELastContactActivity);
  const { onClose, open, toggleOpen } = useOpen({ open: false });
  const { setEntitySearchParams } = useEntitySearchParams();

  function openAssociatedActivity() {
    if (isModal) {
      setEntitySearchParams({
        [EActivityQueryParams.ASSOCIATED_ACTIVITY]: true,
        [EActivityQueryParams.ASSOCIATED_ACTIVITY_ID]: id,
        [EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE]: type,
      });
    } else {
      toggleOpen();
    }
  }

  return (
    <>
      <StyledWrapper
        justify="space-between"
        align="center"
        style={{ width: '100%' }}
        onClick={openAssociatedActivity}
        className="cursor-pointer"
      >
        <Flex gap="var(--space-8)" align="center">
          <ActivityIconButton size="small" viewMode icon={config?.Icon} css={css} />
          <GrowText strong color="var(--primary-7)">
            {config?.cardLabel}
          </GrowText>
        </Flex>
      </StyledWrapper>
      <AssociatedActivityDrawer
        id={id}
        type={type}
        hideActions
        open={open}
        onClose={onClose}
        queryKey={queryKey}
        customer={customer}
        subscriptionType={subscriptionType}
      />
    </>
  );
}

export { AssociatedActivity };
