import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography, StatusTag } from '@sinecycle/growcomponents';
import { GrowText } from 'components/BaseComponents/Typography';
import { ConditionListInvoiceStatusLabelsInvoice } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import {
  WorkFlowInvoiceEventFilterType,
  WorkFlowOperatorLabel,
  WorkFlowStatusOperatorLabel,
} from 'components/Settings/WorkFlowAutomation/utils';
import { BasicWorkFlowOperatorTypes } from '../../type';
import { dateFilters } from './Helper';
import { DisplayOperand } from './Operands/DisplayOperand';
import { DisplayCustomFieldFilterName } from './Operands/Helper/DisplayCustomFieldFilter';
import { DisplayConditionListProps } from './TriggerTypeBasedDisplaySeparator';
const t = { is: 'is' };
export function TriggerDisplayConditions(props: DisplayConditionListProps) {
  const {
    condition,
    color,
    strong,
    text,
    selectedEvent,
    entityType,
    eventCreated: invoieCreated,
  } = props;

  const eventType = condition?.type as WorkFlowInvoiceEventFilterType;

  const renderSelectedEvent = (
    <GrowText strong={strong} color={color}>
      {selectedEvent}
    </GrowText>
  );

  const InvoiceText = (
    <div className="tw-flex tw-items-center tw-gap-2">
      <FontAwesomeIcon icon={['far', 'file-text']} color="var(--gray-7)" />
      <GrowTypography.Text>
        {ConditionListInvoiceStatusLabelsInvoice[invoieCreated as WorkFlowInvoiceEventFilterType]}
      </GrowTypography.Text>
    </div>
  );

  const renderInvoie = (
    <StatusTag
      text={InvoiceText}
      style={{
        borderColor: '',
        backgroundColor: 'var(--geekblue-1)',
        textColor: 'var(--gray-9)',
      }}
    />
  );

  const renderConditionType = (
    <>
      {condition?.operator && (
        <>
          {eventType === 'INVOICE_STATUS' ? (
            <GrowTypography.Text>
              {WorkFlowStatusOperatorLabel[
                condition?.operator?.type as BasicWorkFlowOperatorTypes
              ]?.toLowerCase()}
            </GrowTypography.Text>
          ) : (
            <GrowTypography.Text>
              {WorkFlowOperatorLabel[
                condition.operator.type as BasicWorkFlowOperatorTypes
              ]?.toLowerCase()}
            </GrowTypography.Text>
          )}
        </>
      )}
    </>
  );
  const renderOperandValue = (
    <>
      {condition ? (
        <DisplayOperand
          condition={condition}
          color={color}
          strong={strong}
          text={text}
          selectedEvent={selectedEvent}
          entityType={entityType}
          customFields={props.customFields}
        />
      ) : (
        <></>
      )}
    </>
  );
  return (
    <GrowTypography.Paragraph style={{ margin: 0 }}>
      {props.condition?.id && <DisplayCustomFieldFilterName {...props} />}
      {selectedEvent && <> {renderSelectedEvent}</>}
      {invoieCreated && <> {renderInvoie}</>}
      {dateFilters.includes(eventType) && (
        <>
          {' '}
          <GrowText strong={strong} color={color}>
            {t.is}
          </GrowText>
        </>
      )}{' '}
      {renderConditionType}{' '}
      <GrowText strong={strong} color={color}>
        {renderOperandValue}
      </GrowText>
    </GrowTypography.Paragraph>
  );
}
