import { Radio } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useMemo } from 'react';
import { CalendarDaysEventData } from 'types/entities/collection-strategy/rule-event';
import { MonthSelector } from './MonthDaySelector';
import { WeekDaysSelector } from './WeekDaySelector';
import { CalendarEventType } from './type';

interface CalenderSelectType {
  label: string;
  value: CalendarEventType;
}
interface CalenderTriggerInputProps {
  type?: CalendarEventType;
  days?: number[];
  value?: Partial<CalendarDaysEventData>;
  onChange?: (value: CalendarDaysEventData) => void;
  options: CalenderSelectType[];
  hideChooserLabel?: boolean;
  hideLabel?: boolean;
}

export function CalenderTriggerInput(props: CalenderTriggerInputProps) {
  const { value, onChange, hideChooserLabel, hideLabel } = props;

  const state = useMemo(() => {
    return {
      type: value?.type ?? props.options[0].value,
      days: value?.days ?? [],
    };
  }, [value, props.options]);

  function handleOnChange(updatedValue: CalendarEventType) {
    onChange?.({
      days: [],
      type: updatedValue,
    });
  }

  function handleDaysSelect(selectedDays: number[]) {
    onChange?.({
      ...state,
      days: selectedDays,
    });
  }

  const Chooser = (
    <Radio.Group
      onChange={(e) => handleOnChange(e.target.value)}
      value={state.type}
      optionType="button"
      options={props.options}
    />
  );
  const WeekComponent = (
    <Flex direction="column" gap="--space-4">
      <WeekDaysSelector value={state.days} onChange={handleDaysSelect} hideLabel={hideLabel} />
    </Flex>
  );
  const DateComponent = (
    <Flex direction="column" gap="--space-4">
      <MonthSelector value={state.days} onChange={handleDaysSelect} />
    </Flex>
  );
  const DayChooser = (
    <>
      <HideWrapper hide={state.type !== CalendarEventType.DAY_OF_WEEK}>{WeekComponent}</HideWrapper>
      <HideWrapper hide={state.type !== CalendarEventType.DAY_OF_MONTH}>
        {DateComponent}
      </HideWrapper>
    </>
  );

  return (
    <Flex direction="column" gap="--space-24">
      {!hideChooserLabel && Chooser}
      {DayChooser}
    </Flex>
  );
}
