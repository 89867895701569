import { Form } from 'components/BaseComponents/Form';
import { useForm, useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { RadioGroup } from 'components/BaseComponents/Radio';
import { FilterStrip } from 'components/Common/FilterComponents/style';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { documentViewOptions } from 'components/Documents/predicate';
import { useMemo } from 'react';
import { DocumentTypeFilterKeyForm, DocumentTypeFilterKeys } from 'types/entities/documents';

const filterStripStyle: React.CSSProperties = {
  minHeight: 60,
  boxShadow: 'var(--shadow-1)',
  position: 'relative',
};

function useDocumentTypesFilters() {
  const [form] = useForm<DocumentTypeFilterKeyForm>();

  const formValues = useFormWatch<DocumentTypeFilterKeyForm>([], form);

  function getFilterValues(): DocumentTypeFilterKeys {
    if (!formValues) return { entity_types: [] };
    return formValues.entity_types === 'ALL'
      ? { entity_types: ['CUSTOMER', 'INVOICE'] }
      : { entity_types: [formValues.entity_types] };
  }
  const DocumentView = (
    <StyledFormItem name="entity_types" initialValue={'ALL'}>
      <RadioGroup options={documentViewOptions} optionType="button" buttonStyle="solid" />
    </StyledFormItem>
  );

  const FilterForm = (
    <Form form={form} name="documentFilters" initialValues={{ entity_types: 'ALL' }}>
      {DocumentView}
    </Form>
  );

  const FilterArea = <FilterStrip style={filterStripStyle}>{FilterForm}</FilterStrip>;

  const filterValues = useMemo(getFilterValues, [formValues]);

  return { FilterArea, filterValues };
}

export { useDocumentTypesFilters };
