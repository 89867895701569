import produce from 'immer';
import { assign, cloneDeep, filter, findIndex, head, map } from 'lodash';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { WorkFlowRule } from '../type';
import {
  ActionActivityTypes,
  ApprovalAction,
  ApprovalRequestCondition,
  ApprovalRule,
  APPROVALSTATUS,
  ApprovalWorkFlowAction,
} from './types';
function createCondition(status: APPROVALSTATUS): ApprovalRequestCondition {
  return {
    operator: 'AND',
    conditions: [
      {
        type: 'STATUS',
        operator: {
          type: 'IN',
          operands: {
            type: 'ABSOLUTE',
            value_holder: [{ value: status }],
          },
        },
        mandatory: true,
      },
    ],
    type: 'APPROVAL_REQUEST_CONDITION',
  };
}

function createApprovalWorkflowRule(status: APPROVALSTATUS, data?: WorkFlowRule): ApprovalRule {
  return {
    trigger_type: data?.trigger_type,
    entity_type: 'APPROVAL_REQUEST',
    event_type: 'APPROVAL_REQUEST_UPDATE',
    condition: createCondition(status),
    actions: [],
  };
}

function getApprovalDefaultData(
  workflowData?: WorkflowMetaDto,
  action?: ApprovalWorkFlowAction
): ApprovalAction {
  const data = head(workflowData?.workflow_rules);
  return {
    type: ActionActivityTypes.APPROVAL_REQUEST,
    followup_workflow: {
      title: workflowData?.title,
      workflow_rules: [
        createApprovalWorkflowRule('APPROVED', data),
        createApprovalWorkflowRule('REJECTED', data),
      ],
      start_date: workflowData?.start_date,
      enabled: workflowData?.enabled,
      root_parent_id: workflowData?.id,
    },
    ...action,
  };
}

export { getApprovalDefaultData };

export function updateActionsInRule(
  workflowRules: ApprovalRule[],
  ruleId?: string | number,
  newActions?: any
): ApprovalRule[] {
  return map(workflowRules, (rule) => {
    if (String(rule.id) === String(ruleId)) {
      const updatedActions = cloneDeep(rule.actions ?? []);
      const existingActionIndex = findIndex(
        updatedActions,
        (action) => String(action.id) === String(newActions?.id)
      );

      if (existingActionIndex === -1) {
        updatedActions.push(cloneDeep(newActions));
      } else {
        updatedActions[existingActionIndex] = assign(
          {},
          updatedActions[existingActionIndex],
          newActions
        );
      }

      return { ...rule, actions: updatedActions };
    }

    if (rule.actions.length) {
      return {
        ...rule,
        actions: rule.actions.map((ruleAction) => {
          if (ruleAction.action.type === ActionActivityTypes.APPROVAL_REQUEST) {
            const update = updateActionsInRule(
              ruleAction.action.followup_workflow.workflow_rules,
              ruleId,
              newActions
            );

            return {
              ...ruleAction,
              action: {
                ...ruleAction.action,
                followup_workflow: {
                  ...ruleAction.action?.followup_workflow,
                  workflow_rules: update,
                },
              },
            };
          }

          return ruleAction;
        }),
      };
    }

    return rule; // Return rule unchanged if no updates are needed
  });
}

export function updateModifyCFData(workflowRules: ApprovalRule[]): any[] {
  return map(workflowRules, (rule) => {
    const modifiedActions = rule.actions.map((action) => {
      switch (action.type) {
        case ActionActivityTypes.INVOICE_CUSTOM_FIELD:
        case ActionActivityTypes.CUSTOMER_CUSTOM_FIELD:
          return {
            ...action,
            action: {
              ...action.action,
              type: action.type,
            },
          };

        case ActionActivityTypes.APPROVAL_REQUEST: {
          const approvalAction = action.action as ApprovalAction;
          return {
            ...action,
            action: {
              ...approvalAction,
              followup_workflow: {
                ...approvalAction.followup_workflow,
                workflow_rules: updateModifyCFData(approvalAction.followup_workflow.workflow_rules),
              },
            },
          };
        }

        default:
          return action; // Return unmodified action for other cases
      }
    });

    return { ...rule, actions: modifiedActions };
  });
}
export function updateWorkflowData(data?: WorkflowMetaDto) {
  if (!data) return;

  return produce(data, (draft) => {
    if (!draft) return;

    const modifyActions = (actions: any[]): any[] => {
      return actions?.map((action) => {
        switch (action.type) {
          case ActionActivityTypes.INVOICE_CUSTOM_FIELD:
          case ActionActivityTypes.CUSTOMER_CUSTOM_FIELD:
            return {
              ...action,
              action: {
                ...action.action,
                type: action.type,
              },
            };

          case ActionActivityTypes.APPROVAL_REQUEST: {
            const approvalAction = action.action as ApprovalAction;
            return {
              ...action,
              action: {
                ...approvalAction,
                followup_workflow: {
                  ...approvalAction.followup_workflow,
                  workflow_rules: approvalAction.followup_workflow.workflow_rules
                    ? map(approvalAction.followup_workflow.workflow_rules, (rule) => ({
                        ...rule,
                        actions: modifyActions(rule.actions),
                      }))
                    : [],
                },
              },
            };
          }

          default:
            return action; // Return unmodified action for other cases
        }
      });
    };

    draft.workflow_rules = map(draft.workflow_rules, (rule) => ({
      ...rule,
      actions: modifyActions(rule.actions),
    }));
  });
}
export function removeActionsInRule(
  workflowRules: ApprovalRule[],
  ruleId?: string | number,
  actionIdToRemove?: string | number
): ApprovalRule[] {
  return map(workflowRules, (rule) => {
    if (String(rule.id) === String(ruleId)) {
      // Use lodash's `filter` to remove the action with the specified actionIdToRemove
      const updatedActions = filter(
        rule.actions,
        (action) => String(action.id) !== String(actionIdToRemove)
      );

      return { ...rule, actions: updatedActions };
    }

    if (rule.actions.length) {
      return {
        ...rule,
        actions: map(rule.actions, (ruleAction) => {
          if (ruleAction.action.type === ActionActivityTypes.APPROVAL_REQUEST) {
            const updatedWorkflowRules = removeActionsInRule(
              ruleAction.action.followup_workflow.workflow_rules,
              ruleId,
              actionIdToRemove
            );

            return {
              ...ruleAction,
              action: {
                ...ruleAction.action,
                followup_workflow: {
                  ...ruleAction.action.followup_workflow,
                  workflow_rules: updatedWorkflowRules,
                },
              },
            };
          }

          return ruleAction;
        }),
      };
    }

    return rule; // Return rule unchanged if no updates are needed
  });
}
