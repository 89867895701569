import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HideWrapper } from '@sinecycle/growcomponents';
import { Card, FormInstance } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import WorkflowButtons from 'components/Settings/WorkFlowAutomation/common/workflowButtons';
import WorkflowCardTitle from 'components/Settings/WorkFlowAutomation/common/workflowCardTitle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { WorkflowActionRule } from '../../type';
import { CardStyles } from '../../WorkFlowForm/WorkFlowForm';
import { getActionType } from '../ActionConfigurations/ActionTypeTitle';
import { AddActionModal } from '../AddActions/AddActionModal';
import useExtractSelectedType from '../hooks/useExtractSelectedType';
import useGetEntityType from '../hooks/useGetEntity';
import { t } from '../text';
import { ActionActivityTypes, ActionTypes } from '../types';
import { isInvoiceLevelAction } from '../utils';
import ActionRulesList from './actionRulesList';

interface ActionListProps {
  selected?: boolean;
  onClick?: (value?: 'intital') => void;
  form: FormInstance;
  baseForm: FormInstance;
  actionRule?: WorkflowActionRule<ActionTypes>[];
  onActionRemove: (data: WorkflowActionRule<ActionTypes>[], type: string) => void;
  actionRuleList?: WorkflowActionRule<ActionTypes>[];
  workflowMeta: WorkflowMetaDto;
  onSelect: (value?: WorkflowActionRule<ActionTypes>) => void;
  onInsert: (value?: any) => void;
  entityType?: 'INVOICE' | 'CUSTOMER';
  loading?: boolean;
}

export const filterList = [
  ActionActivityTypes.APPROVAL_REQUEST,
  ActionActivityTypes.FLAG_INVOICE,
  ActionActivityTypes.COLLECTION_OWNER,
  ActionActivityTypes.EDIT_STRATEGY,
  ActionActivityTypes.USER_CATEGORY,
  ActionActivityTypes.EDIT_COLLECTION_STATUS,
];
function ActionList(props: ActionListProps) {
  const { form, onClick, selected, onSelect, onInsert } = props;
  const { workflow_id } = useParams();
  const [actionOpen, setActionOpen] = useState(false);

  const actionRule = (
    workflow_id ? props.actionRule : form.getFieldValue(['actions'])
  ) as WorkflowActionRule<ActionTypes>[];
  const actionRuleDataList = workflow_id ? props.actionRule : props.actionRuleList;
  const entityType = useGetEntityType(props.workflowMeta);
  const actionType = useFormWatch('type', form);
  const extractFieldLabel = useExtractSelectedType();

  const fillteredList = actionRule
    ?.map((rule) => {
      const actionType = getActionType(rule);
      if (rule.type && actionType && filterList.includes(actionType)) {
        return getActionType(rule);
      }

      return;
    })
    .filter(Boolean);
  function onActionEdit(item: WorkflowActionRule<ActionTypes>) {
    form.setFieldsValue({
      id: item.isNested ? item.actionId : item.id,
      type: getActionType(item),
      selected_item: item,
      status: item.status,
      parent_id: item.parent_id,
      rule_id: item.rule_id,
      current_rule_id: undefined,
      last_action: undefined,
    });
    onSelect(item);
  }

  const AddActions = (
    <AddActionModal
      open={actionOpen}
      onClose={() => {
        setActionOpen(false);
      }}
      filterList={fillteredList as any}
      entityType={entityType}
      onSelect={(value) => {
        const result = isInvoiceLevelAction(value?.id, actionRule);
        form.setFieldsValue({
          actionable_entity: result,
          is_action_saved: false,
          previous_actionable_entity: actionType?.value,
          type: extractFieldLabel(value?.id as any),
          parent_id: undefined,
          rule_id: undefined,
          id: undefined,
          current_rule_id: undefined,
          last_action: undefined,
        });

        onSelect();
        onInsert({ value: extractFieldLabel(value?.id as any) });
      }}
      onOpenChange={(open) => {
        setActionOpen(open);
      }}
    >
      <span>
        <WorkflowButtons
          buttonTitle={t.action}
          toggleTriggerOpen={() => {
            setActionOpen(!actionOpen);
            form.setFieldsValue({
              is_new_action: true,
              parent_id: undefined,
              rule_id: undefined,
              status: undefined,
              last_action: undefined,
            });

            props.onClick?.();
          }}
          disabled={!entityType}
          disabledText={t.set_trigger}
        />
      </span>
    </AddActionModal>
  );
  const boltIcon: IconProp = ['far', 'bolt'];
  const CardTitle = (
    <WorkflowCardTitle
      title={t.actions}
      icon={boltIcon}
      hasData={Boolean(actionRuleDataList?.length)}
    />
  );

  useEffect(() => {
    if (actionRuleDataList?.length) {
      form.setFieldsValue({
        is_action_saved: true,
      });
    }
  }, [actionRuleDataList?.length, form]);
  return (
    <div
      onClick={() => {
        onClick?.('intital');
      }}
    >
      <Card
        title={CardTitle}
        className="tw-border-primary-5 tw-cursor-pointer"
        bordered={selected}
        hoverable
        styles={CardStyles}
        loading={props.loading}
      >
        <HideWrapper hide={!actionRuleDataList?.length}>
          <ActionRulesList
            entityType={entityType}
            value={actionRuleDataList}
            onClick={() => {
              props.onClick?.();
            }}
            onActionEdit={(data) => {
              onActionEdit(data);
              onClick?.();
            }}
            form={form}
            onInsert={(value) => {
              onInsert(value);
            }}
          />
        </HideWrapper>

        {props.loading ? null : AddActions}
      </Card>
    </div>
  );
}

export { ActionList };
