import { GrowDate, GrowNumberFormat, GrowTypography } from '@sinecycle/growcomponents';
import { match } from 'ts-pattern';
import { CustomFieldSnapSot, formatBooleanValue } from 'types/entities/custom-field';
import { getDateComponent, getNumberComponent, getTextComponent } from './CustomFieldTexts';

interface CustomFieldSnapShotTextProps {
  field: CustomFieldSnapSot;
  dateFormat?: string;
  locale?: string;
}

function CustomFieldSnapShotText(props: CustomFieldSnapShotTextProps) {
  const { locale, dateFormat } = props;
  return match(props.field.data)
    .with({ type: 'TEXT' }, (props) => {
      return getTextComponent(props.value);
    })
    .with({ type: 'NUMBER' }, (props) => {
      return getNumberComponent(parseInt(props.value), locale, 'DEFAULT');
    })
    .with({ type: 'DECIMAL' }, (props) => {
      return getNumberComponent(parseInt(props.value), locale, 'DECIMAL');
    })
    .with({ type: 'DATE' }, (props) => {
      return getDateComponent(props.value, dateFormat);
    })
    .with({ type: 'DATETIME' }, (props) => {
      return getDateComponent(props.value, dateFormat, true);
    })
    .with({ type: 'BOOLEAN' }, (props) => {
      return getTextComponent(formatBooleanValue(props.value));
    })
    .otherwise((props) => {
      return getTextComponent(props.value);
    });
}

export { CustomFieldSnapShotText };
