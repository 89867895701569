import { Col, Row } from 'antd';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import produce from 'immer';
import { useState } from 'react';
import { RangeItem } from 'types/entities/invoice-segments';
import { WorkFlowGeneralRelativeOperands } from '../../EventCondition/operator';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';
import SingleNumericField from '../CommonFilterHelper/SingleNumericField';
import { handleNumericValidation } from '../Validation';
import { BetweenFilterTexts, RelativeCurrentDateTag } from './utils';

interface InvoiceBalanceAmountFilterProps {
  showDaysOf?: boolean;
  value?: DateWorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<unknown>) => void;
  singleFieldMoreInfo?: string;
  prefix?: string;
  precision?: number;
  name?: number;
}

const DaysOf = <GrowText color="var(--gray-9)">{BetweenFilterTexts.daysOf}</GrowText>;
export default function RelativeSingleFilter(props: InvoiceBalanceAmountFilterProps) {
  const singleFilterValue = props.value?.operands as WorkFlowGeneralRelativeOperands;
  const numericFilterValue = singleFilterValue?.value?.offset ?? 0;
  const [validatedValue, setValidatedValue] = useState<RangeItem>({
    value: numericFilterValue as number,
  });
  function handleValueChange(value: number | null) {
    const validationStatus = handleNumericValidation(value, Boolean(props.precision));
    const updatedFilter = produce(props.value, (draftFilter) => {
      if (draftFilter) {
        const filter = draftFilter.operands as WorkFlowGeneralRelativeOperands;
        filter.value.offset = value;
      }
    });
    setValidatedValue((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        value,
        errorMsg: validationStatus.validateStatus === 'success' ? null : validationStatus.errorMsg,
        validateStatus: validationStatus.validateStatus === 'success' ? 'success' : 'error',
      };
    });
    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const SingleNumbericField = (
    <StyledFormItem
      validateStatus={validatedValue?.validateStatus}
      help={validatedValue?.errorMsg}
      label={Boolean(props.showDaysOf) && DaysOf}
      className={'label-space'}
      labelCol={{ style: { order: 2 } }}
    >
      <SingleNumericField
        onChange={handleValueChange}
        prefix={props.prefix}
        value={validatedValue.value}
        percision={props.precision ?? 0}
        style={{ width: '50px' }}
      />
    </StyledFormItem>
  );
  const SingleRelativeFilter = (
    <Row wrap={false}>
      <Col span={13}>{SingleNumbericField}</Col>
      <Col span={11}>{RelativeCurrentDateTag}</Col>
    </Row>
  );

  return <>{SingleRelativeFilter}</>;
}
