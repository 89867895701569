import { useQueryClient } from '@tanstack/react-query';
import { Form } from 'antd';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import CollectionOwnerSelect from 'components/Common/CollectionOwner';
import { EditFieldsFormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { find, head } from 'lodash';
import { CollectionOwnerProps } from 'services/users';
import { WorkflowForms } from '../../../type';
import { t } from '../../text';
import { ActionActivityTypes, UpdateCollectionOwnerProps } from '../../types';
import { ActionType } from '../ActionType';

export default function UpdateCollectionOwner(props: UpdateCollectionOwnerProps) {
  const { data, form } = props;
  const { isWorkflowOwner } = useWorkflowOwner();
  const queryClient = useQueryClient();
  const collectionOwnerData: CollectionOwnerProps[] | undefined = queryClient.getQueryData([
    'collection-owner-users',
  ]);
  const value = find(collectionOwnerData, { id: head(data?.user_ids) });
  const name = value ? value.id : undefined;

  return (
    <>
      {isWorkflowOwner ? (
        <UpdateCollectionOwnerReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          <EditFieldsFormItem
            label={t.collection_owner}
            name={'user_ids'}
            initialValue={name}
            rules={[{ required: true, message: t.collection_owner_required }]}
          >
            <CollectionOwnerSelect placeholder={t.select_collection_owner} />
          </EditFieldsFormItem>
          <ActionType value={ActionActivityTypes.USER_CATEGORY} />
        </Form>
      )}
    </>
  );
}

export function UpdateCollectionOwnerReadOnly(props: UpdateCollectionOwnerProps) {
  const { data } = props;

  const queryClient = useQueryClient();
  const collectionOwnerData: CollectionOwnerProps[] | undefined = queryClient.getQueryData([
    'collection-owner-users',
  ]);
  const value = find(collectionOwnerData, { id: head(data?.user_ids) });

  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent
        label={t.collection_owner}
        content={`${value?.first_name} ${value?.last_name ?? ''}`}
      />
    </div>
  );
}
