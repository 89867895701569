import { SelectProps } from 'antd';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { WorkFlowConditionOptions, WorkFlowInvoiceEventOptions } from '../../common/Predicate';
import { CustomerConditionOptions } from '../../common/types';

export function findEntityEventOptions(
  entity: EVENT_TRIGGER_CONDITION_KEYS
): SelectProps['options'] | undefined {
  switch (entity) {
    case 'INVOICE':
      return WorkFlowInvoiceEventOptions;
    // case 'PAYMENT':
    //   return PaymentConditionOptions;
    // case 'CREDIT_MEMO':
    //   return CreditMemoConditionOptions;
    // case 'DISPUTE':
    //   return DisputeConditionOptions;
    // case 'TASK':
    //   return TaskConditionOptions;
    // case 'PTP':
    //   return PTPConditionOptions;
    // case 'ESCALATION':
    //   return EscalationConditionOptions;
    case 'CUSTOMER':
      return CustomerConditionOptions;
  }
}

export function findEntityEventConditionOptions(
  entity: EVENT_TRIGGER_CONDITION_KEYS
): SelectProps['options'] | undefined {
  switch (entity) {
    case 'INVOICE':
      return WorkFlowConditionOptions;
    // case 'PAYMENT':
    //   return PaymentConditionOptions;
    // case 'CREDIT_MEMO':
    //   return CreditMemoConditionOptions;
    // case 'DISPUTE':
    //   return DisputeConditionOptions;
    // case 'TASK':
    //   return TaskConditionOptions;
    // case 'PTP':
    //   return PTPConditionOptions;
    // case 'ESCALATION':
    //   return EscalationConditionOptions;
    case 'CUSTOMER':
      return CustomerConditionOptions;

    default:
      return;
  }
}
