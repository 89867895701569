import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { useParams, useSearchParams } from 'lib/router';
import { omit } from 'lodash';
import {
  ApprovalRequestConfig,
  ApprovalRequestReport,
  UserApprovalRequestMapAnon,
  UserApprovalRequestMapDV,
  UserApprovalRequestUpdate,
} from 'pages/Approvals/type';
import { AttachmentLinkResponse } from 'services/attachments';
import ky from 'services/ky';

const baseUrl = 'v1/approval-requests';

export async function getAttachmentAnonUrl(attachmentId: number | string, hash?: string) {
  return (
    await ky
      .get(`${baseUrl}/anon/${hash}/expiry-link?id=${attachmentId}`)
      .json<AttachmentLinkResponse>()
  ).url;
}
export function useApprovalReport(filters?: ApprovalRequestConfig) {
  const params = omit(filters, 'filters');
  return useQuery({
    queryKey: ['approval-request-report', filters],
    queryFn: () =>
      ky
        .post(`${baseUrl}/generate-report`, {
          searchParams: { ...params },
          json: { ...filters?.filters },
        })
        .json<ApprovalRequestReport>(),
  });
}

export function useApprovalAnonymousReport() {
  const { hash } = useParams();
  return useQuery({
    queryKey: ['annon-report', hash],
    queryFn: () => ky.get(`${baseUrl}/anon/${hash}`).json<UserApprovalRequestMapAnon>(),
    enabled: Boolean(hash),
  });
}
export function useUpdateAnonymousApprovalStatus() {
  const { hash } = useParams();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['anon-update-approval'],
    mutationFn: (value: UserApprovalRequestUpdate) =>
      ky.post(`${baseUrl}/anon/${hash}`, { json: value }).json<UserApprovalRequestMapAnon>(),
    onSuccess(data) {
      queryClient.setQueryData(['annon-report', hash], { ...data });
      notify.success(data.status === 'APPROVED' ? 'Approved' : 'Rejected');
    },
    onError() {
      notify.error('Failed to update');
    },
  });
}

export function useApprovalDetailsReport() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('approval_id');
  return useQuery({
    queryKey: ['approvals-details', id],
    queryFn: () => ky.get(`${baseUrl}/${id}`).json<UserApprovalRequestMapDV>(),
    enabled: Boolean(id),
  });
}
export function useUpdateApprovalStatus() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const id = searchParams.get('approval_id');
  return useMutation({
    mutationKey: ['upate-approval-status'],
    mutationFn: (value: UserApprovalRequestUpdate) =>
      ky.post(`${baseUrl}/${id}`, { json: value }).json<UserApprovalRequestMapDV>(),
    onSuccess(data) {
      setSearchParams({});
      queryClient.setQueryData(['approvals-details', id], () => {
        return { ...data };
      });
      notify.success(data.status === 'APPROVED' ? 'Approved' : 'Rejected');
      queryClient.invalidateQueries(['approval-request-report']);
    },
    onError(error) {
      notify.error('Failed to update');
    },
  });
}
