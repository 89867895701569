import { WorkFlowRule } from 'components/Settings/WorkFlowAutomation/CustomAutomations/type';
import { WorkFlowStatus } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { AppPageName } from 'router/constants/page-info';
import { UserBasic } from './user';

const EventTypes = [
  'INVOICE_CREATE',
  'ISSUE_DATE',
  'INVOICE_STATUS',
  'INVOICE_DUE_DATE',
  'DUE_DAYS',
  'INVOICE_VALUE',
  'INVOICE_BALANCE',
  'INVOICE_PDF',
  'DELIVERY_FLAG',
  'PAYEMENT_CREATE',
  'PAYMENT_STATUS',
  'PAYMENT_BALANCE',
  'CREDIT_MEMO_STATUS',
  'CREDIT_MEMO_BALANCE',
  'CREDIT_MEMO_CREATED',
  'DISPUTE_ASSIGNEE',
  'DUE_DATE',
  'DISPUTE_STATUS',
  'DISPUTE_TYPE',
  'DUE_DATE',
  'TASK_STATUS',
  'TASK_ASSIGNEE',
  'PTP_DATE',
  'PTP_STATUS',
  'PTP_AMOUNT',
  'ESCALATION_STATUS',
  'DUE_DATE',
  'ESCALATION_ASSIGNEE',
  'PRIMARY_CONTACT',
  'CREDIT_TERM',
  'SECONDARY_CONTACT',
  'COLLECTION_OWNER',
  'OUTSTANDING_VALUE',
  'OPEN_INVOICE_COUNT',
  'DISPUTE_TYPE',
  'INVOICE_CUSTOM_FIELD',
  'CUSTOMER_CUSTOM_FIELD',
  'INVOICE_SUBSIDIARY',
  'CUSTOMER_SUBSIDIARY',
  'INVOICE_UPDATE',
  'INVOICE_FOLLOWUP_STATUS',
  'OVERDUE_DAYS',
  'INVOICE_PAYMENT_STATUS',
  'INVOICE_BALANCE_DUE_TX',
  'INVOICE_DUE',
  'INVOICE_IS_OVERDUE',
  'INVOICE_OVERDUE',
  'INVOICE_TAX',
  'INVOICE_IS_DUE',
  'INVOICE_IS_OVERDUE',
] as const;
export type WorkFlowEventTypes = (typeof EventTypes)[number];

export interface WorkFlowListDTO {
  id: number;
  title: string;
  enabled: boolean;
  start_date: string;
  end_date: string;
  last_updated_by: UserBasic;
  last_updated_at: string;
  event_type?: WorkFlowEventTypes;
  default_workflow: boolean;
  workflow_type: AppPageName;
  owner?: UserBasic;
  last_run_at?: string;
  created_by?: UserBasic;
  created_at?: string;
}

export interface WorkFlowFiltersDTO {
  entity_type?: WorkFlowStatus[];
  sort_by_column: WorkFlowSortByFilterEnum;
}
export enum WorkFlowSortByFilterEnum {
  LAST_UPDATED_AT = 'LAST_UPDATED_AT',
  // LAST_RUN_AT = 'LAST_RUN_AT', for future use commenting now
}
//this is needed
export type WorkFlowSortByFilter =
  // | WorkFlowSortByFilterEnum.LAST_RUN_AT  for future use commenting now
  WorkFlowSortByFilterEnum.LAST_UPDATED_AT;

export interface WorkflowMetaDto {
  title?: string;
  id?: string | number;
  description?: string;
  enabled: boolean;
  deleted?: boolean;
  start_date: string;
  end_date?: string;
  workflow_rules: WorkFlowRule[];
  sequence?: number;
  last_updated_by?: UserBasic;
  owner_id?: string | number;
}
