import { useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { useParams } from 'lib/router';
import { head, last } from 'lodash';
import { useModifyWorkflowMetaMutation } from 'queries/workflow';
import { Forms } from 'rc-field-form/lib/FormContext';
import { useCallback } from 'react';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { updateWorkflowData } from '../Action/approval-utils';
import { ApprovalRule } from '../Action/types';
import { findActionById } from '../Action/utils';
import { WorkflowForms } from '../type';

interface useUpdateWorkflowProps {
  successText?: string;
  failureText?: string;
}
function useUpdateWorkflow(props: useUpdateWorkflowProps) {
  const { successText, failureText } = props;
  const queryClient = useQueryClient();
  const { workflow_id } = useParams();
  const { mutateAsync: update } = useModifyWorkflowMetaMutation();
  async function handleUpdate(
    value: Partial<WorkflowMetaDto>,
    parentId?: string | number,
    ruleId?: string | number,
    forms?: Forms
  ): Promise<WorkflowMetaDto> {
    const sanitized = updateWorkflowData(value as WorkflowMetaDto);
    if (sanitized) {
      try {
        const data = await update(sanitized);
        queryClient.setQueryData(['workflow-meta', workflow_id], data);
        notify.success(successText);
        const actionForm = forms?.[WorkflowForms.ACTION_FORM];
        const isNewActions = actionForm?.getFieldValue('is_new_action');
        if (parentId && ruleId && isNewActions) {
          const currentRuleValue = findActionById(data, ruleId) as ApprovalRule;
          const action = last(currentRuleValue?.actions)?.id;
          actionForm?.setFieldsValue({
            id: action,
            current_rule_id: undefined,
            last_action: last(currentRuleValue?.actions),
            is_new_action: false,
          });
        } else if (isNewActions) {
          const action = last(head(data.workflow_rules)?.actions)?.id;
          actionForm?.setFieldsValue({
            id: action,
            current_rule_id: undefined,
            last_action: last(head(data.workflow_rules)?.actions),
            is_new_action: false,
          });
        }

        return data;
      } catch (error) {
        notify.error(failureText);
        throw error;
      }
    }
    throw new Error('Sanitization failed');
  }
  const onUpdate = useCallback(handleUpdate, [
    failureText,
    queryClient,
    successText,
    update,
    workflow_id,
  ]);
  return { handleUpdate: onUpdate };
}

export { useUpdateWorkflow };
