import { Select, SelectProps } from 'antd';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { DefaultOptionType } from 'antd/lib/select';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import produce from 'immer';
import { flatten } from 'lodash';
import { CustomField } from 'types/entities/custom-field';
import { InAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import { Subsidiary } from 'types/entities/subsidiary';
import { WorkFlowFilterOperator } from '../../EventCondition/type';

const MultiSelectFilterFields = [
  'CUSTOMER_CUSTOM_FIELD',
  'INVOICE_CUSTOM_FIELD',
  'CREDIT_TERM',
  'INVOICE_SUBSIDIARY',
  'CUSTOMER_SUBSIDIARY',
] as const;
export type MultiSelectFilterFieldsType = typeof MultiSelectFilterFields[number];
export type DynamicFilterGeneratorData = CustomField[] | string[] | Subsidiary[];
export type DynamicFilterGeneratorValueType = string[] | number[];
interface CustomFiledCreditTermOptionsProps<T extends DynamicFilterGeneratorData> {
  conditionType: MultiSelectFilterFieldsType;
  selectedField?: number;
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
  options?: (string | number)[];
  value?: WorkFlowFilterOperator<unknown>;
  data?: T;
}
export type StringRangeItem = {
  value: string[];
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};

export function findCustomAndContactOptionMatches<T extends DynamicFilterGeneratorData>(
  conditionType: MultiSelectFilterFieldsType,
  data?: T,
  field?: number[] | string[]
) {
  switch (conditionType) {
    case 'CUSTOMER_CUSTOM_FIELD':
    case 'INVOICE_CUSTOM_FIELD':
      const customFieldData = data as CustomField[];
      const allOptions = customFieldData?.map((field) => field.options);
      return (field as string[])?.filter((option) => flatten(allOptions).includes(option));
    case 'CREDIT_TERM':
      const creditTerms = data?.map((field) => field);
      return (field as string[])?.filter((option) => flatten(creditTerms).includes(option));
    case 'INVOICE_SUBSIDIARY':
    case 'CUSTOMER_SUBSIDIARY':
      const subsidiaryData = data as Subsidiary[];

      return subsidiaryData
        .filter((subsidiary) => (field as number[])?.includes(subsidiary.id))
        .map((subsidiary) => subsidiary.id);
  }
}
function findOptions(
  conditionType: MultiSelectFilterFieldsType,
  data?: DynamicFilterGeneratorData,
  selectedField?: number
) {
  switch (conditionType) {
    case 'CUSTOMER_CUSTOM_FIELD':
    case 'INVOICE_CUSTOM_FIELD':
      const customFields = data as CustomField[];
      const customField = customFields?.find((field) => selectedField === field?.id);
      const customFieldOptions: SelectProps['options'] = customField?.options?.map((field) => {
        return { label: field, value: field, key: field };
      });
      return customFieldOptions;

    case 'CREDIT_TERM':
      const creditTermData = data as string[];
      const creditTermOptions: SelectProps['options'] = creditTermData?.map((field) => {
        return { label: field, value: field, key: field };
      });
      return creditTermOptions;
    case 'INVOICE_SUBSIDIARY':
    case 'CUSTOMER_SUBSIDIARY':
      const subsidiaryData = data as Subsidiary[];
      const subsidiaryDataOptions: SelectProps['options'] = subsidiaryData?.map((field) => {
        return { label: field.display_name, value: field.id, key: field.id };
      });
      return subsidiaryDataOptions;
  }
}
function MultiSelectFilterField<T extends DynamicFilterGeneratorData>(
  props: CustomFiledCreditTermOptionsProps<T>
) {
  const options = findOptions(props.conditionType, props.data, props.selectedField);
  const customFieldOperand = props.value?.operands as InAbsoluteOperands<string>;
  const customFieldValues = customFieldOperand?.value_holder?.map((field) => {
    return field?.value;
  });
  const showSearch = props?.options?.length && props.options.length > 10 ? true : false;
  const matchedCustomValues = findCustomAndContactOptionMatches(
    props.conditionType,
    props.data,
    customFieldValues
  );

  const Help =
    Boolean(matchedCustomValues) && matchedCustomValues?.length ? <></> : <>Please select option</>;
  const fieldValidation =
    Boolean(matchedCustomValues) && matchedCustomValues?.length ? 'success' : 'error';

  function handleSelectCsutomField(value: string[] | number[]) {
    const customFieldValues = value?.map((item) => {
      return { value: item };
    });
    const updatedFilter =
      props.value &&
      produce(props.value, (draft) => {
        if (draft.operands) {
          const inAbsOperand = draft.operands as InAbsoluteOperands<unknown>;
          inAbsOperand.value_holder = customFieldValues;
        }
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  function filterOptionGenerator(input: string, option?: DefaultOptionType) {
    const label = option?.label as string;
    const labelInput = label?.toLowerCase();
    return labelInput.includes(input.toLowerCase());
  }
  return (
    <StyledFormItem shouldUpdate validateStatus={fieldValidation} help={Help}>
      <Select
        placeholder={'Please select options'}
        mode="multiple"
        showSearch={showSearch}
        style={{ maxWidth: '100%', minWidth: '200px' }}
        dropdownStyle={{ minWidth: 'fit-content' }}
        onChange={handleSelectCsutomField}
        options={options}
        value={matchedCustomValues}
        filterOption={(input, option) => {
          return filterOptionGenerator(input, option);
        }}
      />
    </StyledFormItem>
  );
}

export { MultiSelectFilterField };
