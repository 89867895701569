import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import useGetActivityConversation from 'components/Inbox/EmailActivities/hooks/useGetActivityConversation';
import { useMemo } from 'react';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { EmailDetailsLayout } from './EmailDetailsLayout';

interface EmailDetailsProps extends ActivityDetailsBaseProps {}
function EmailDetails(props: EmailDetailsProps) {
  const { entitySearchParams } = useEntitySearchParams();
  const emailId = useMemo(
    () =>
      entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE]
        ? entitySearchParams[EActivityQueryParams.ACTIVITY_ID]
        : undefined,
    [entitySearchParams]
  );

  const { transformedData: customerConversationData, isLoading: customerConversationLoading } =
    useGetActivityConversation('customers', props.customer as any, emailId);

  return (
    <EmailDetailsLayout
      data={customerConversationData}
      loading={customerConversationLoading}
      queryKey={['email-activity-thread', entitySearchParams[EActivityQueryParams.ACTIVITY_ID]]}
    />
  );
}

export { EmailDetails };
