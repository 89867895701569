import { Validation } from 'types/entities/invoice-segments';
import { NUMBER_ONLY_REGEX, NUMBER_WITH_DECIMAL_REGEX } from 'util/regex';

export function handleNumericValidation(value?: number | null, decimal?: boolean): Validation {
  const validationText = decimal ? 'Value should be a number' : 'Decimal values are not allowed';
  const regex = decimal ? NUMBER_WITH_DECIMAL_REGEX : NUMBER_ONLY_REGEX;

  if (!value && value !== 0) {
    return {
      validateStatus: 'error',
      errorMsg: 'Value should not be empty',
    };
  } else if (!regex.test(value as unknown as string)) {
    return {
      validateStatus: 'error',
      errorMsg: validationText,
    };
  } else {
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  }
}
export function handleStringValidation(value?: string[]): Validation {
  return value?.length
    ? {
        validateStatus: 'success',
        errorMsg: null,
      }
    : {
        validateStatus: 'error',
        errorMsg: 'Value should not be empty',
      };
}
