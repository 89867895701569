import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { ActionTitleProps, NoteActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function NoteTitle(props: ActionTitleProps<NoteActionType>) {
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.NOTE.title}
      icon={actionIcons.NOTE.icon}
      backgroundColor={actionIcons.NOTE.style.backgroundColor}
      title={actionIcons.NOTE.title}
      strong={props.strong}
    />
  );
}
