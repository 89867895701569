import styled from 'styled-components';

export const STable = styled.div`
  .ant-table-thead {
    line-height: normal;

    .ant-table-cell {
      background-color: var(--gray-4);
    }

    .title {
      font-size: var(--fs-12);
      color: var(--gray-7);
    }
  }

  .status-options {
    display: flex;

    .select-date {
      margin-left: auto;
      width: 250px;
    }
  }

  .ant-radio-button-wrapper {
    margin: 0 5px;
    border-width: 1px;

    &::before {
      display: none;
    }
  }

  /* align table pagination icons */
  .ant-pagination .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
