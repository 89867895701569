import { removeEmailTemplateInsert } from 'store/insert';
import {
  getEmailTemplate,
  getEmailTemplateClone,
  removeEmailTemplate,
} from '../services/email-templates';
import {
  addToFetchingHandler,
  AsyncFetches,
  clearFetchingHandler,
} from '../store/async-fetches/fetches';
import { getEmailTemplateHandler, initialState } from '../store/email-template/email-template';
import { EmailTemplateResponseState } from '../store/email-templates/types';
import { AppThunk } from '../store/store';

export const getEmailTemplateThunk = (
  id: number
): AppThunk<Promise<{ payload: EmailTemplateResponseState; type: string }>> => {
  return async (dispatch) => {
    try {
      dispatch(addToFetchingHandler(AsyncFetches.GET_EMAIL_TEMPLATE));
      return dispatch(
        getEmailTemplateHandler(
          id === -1 ? initialState : { status: 200, emailTemplate: await getEmailTemplate(id) }
        )
      );
    } catch (err: any) {
      return dispatch(
        getEmailTemplateHandler({
          status: 500,
          message: err.message,
          emailTemplate: initialState.emailTemplate,
        })
      );
    } finally {
      dispatch(clearFetchingHandler(AsyncFetches.GET_EMAIL_TEMPLATE));
    }
  };
};

export const removeEmailTemplateThunk = (
  emailTemplateId: number,
  folderId: number
): AppThunk<Promise<{ payload: { id: number; folderId?: number }; type: string }>> => {
  return async (dispatch) => {
    try {
      await removeEmailTemplate(emailTemplateId);
      return dispatch(
        removeEmailTemplateInsert(Object.assign({}, { id: emailTemplateId, folderId: folderId }))
      );
    } catch (err) {
      return dispatch(removeEmailTemplateInsert({ id: emailTemplateId }));
    }
  };
};

export const getEmailTemplateThunkClone = (
  id: number
): AppThunk<Promise<{ payload: EmailTemplateResponseState; type: string }>> => {
  return async (dispatch) => {
    try {
      dispatch(addToFetchingHandler(AsyncFetches.GET_EMAIL_TEMPLATE));
      return dispatch(
        getEmailTemplateHandler({ status: 200, emailTemplate: await getEmailTemplateClone(id) })
      );
    } catch (err: any) {
      return dispatch(
        getEmailTemplateHandler({
          status: 500,
          message: err.message,
          emailTemplate: initialState.defaultEmailTemplate,
        })
      );
    } finally {
      dispatch(clearFetchingHandler(AsyncFetches.GET_EMAIL_TEMPLATE));
    }
  };
};
