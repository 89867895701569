import styled from 'styled-components';
import { STable } from '../../../BaseComponents/Table/style';

export const StyledTable = styled(STable)<{ minHeight?: string | undefined }>`
  --fixed-tabs-height: 150px;
  --fixed-tabs-and-header-height: 210px;
  --payments-table-min-height: calc(
    100vh - var(--app-header-height) - var(--fixed-tabs-and-header-height)
  );
  --credit-notes-table-min-height: calc(
    100vh - var(--app-header-height) - var(--fixed-tabs-height)
  );

  &.loading {
    .ant-empty {
      display: none;
    }
  }

  .bulk-action-container {
    width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .ant-table-thead {
    .ant-table-cell {
      background-color: var(--gray-2);
    }
  }
  .ant-table-pagination,
  .ant-pagination {
    margin: 0;
    padding: var(--space-12);
    background-color: var(--gray-1);
  }
  &.ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      border-color: var(--primary-7) !important;
    }
    .ant-checkbox-inner::after {
      height: 2px !important;
    }
  }
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--gray-1) !important;

      &::after {
        border: 1px solid var(--primary-6);
        border-top: 0;
        border-left: 0;
      }
    }
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    content: none;
  }
  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none !important ;
  }

  .ant-table-body {
    min-height: ${(props) =>
      `${props?.minHeight ?? 'calc(100vh - var(--app-header-height) - var(--fixed-tabs-height))'}`};

    .ant-table-placeholder td {
      border-bottom: unset;
    }
  }

  .column-header {
    padding: 0;
    height: 28px;
  }
`;

export const LineItemsWrapper = styled.div`
  padding: var(--space-24);
  height: calc(-288px + 100vh);
`;
