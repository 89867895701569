import { Table } from '@sinecycle/growcomponents';
import { cellComponents } from 'components/BaseComponents/GrowTable/cellComponents';
import { CUSTOMER_DETAILS_EVENT } from 'events/customer-details';
import { dispatchAppEvent } from 'lib/pub-sub';
import { omit } from 'lodash';
import { useCreditAdjustmentQuery } from 'queries/statements';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { accountConfigSelector } from 'store/authentication/authentication';
import { CreditAdjustmentFilters } from 'store/customer/types';
import { defaultColumnDefs } from 'util/table';
import { useColumns } from './columns';

interface CreditStatementsProps {
  customerId?: number;
  customerName?: string;
}

function CreditStatementsTable(props: CreditStatementsProps) {
  const configList = useSelector(accountConfigSelector);
  const parentConfig = configList?.parent_child_config;
  const [statementFilters, setStatementFilters] = useState<CreditAdjustmentFilters>({
    sort_by: 'ASC',
    sort_col: 'AMOUNT',
    page: 1,
    size: 10,
    total_records: 0,
    view_child: parentConfig?.view_child,
  });

  const { data, isLoading } = useCreditAdjustmentQuery({
    customerId: props.customerId as number,
    param: omit(statementFilters, 'total_records'),
    onSuccessCallBack(data) {
      setStatementFilters((prev) => {
        return { ...prev, page: data.current_page, total_records: data.total_records };
      });
    },
  });
  const { columns } = useColumns(statementFilters);

  return (
    <Table
      loading={isLoading}
      gridProps={{
        columnDefs: columns,
        rowData: data?.list,
        defaultColDef: { ...defaultColumnDefs, spanHeaderHeight: true, sortable: false },
        animateRows: true,
        headerHeight: 40,
        suppressMovableColumns: true,
        components: cellComponents,
        unSortIcon: true,
        enableCellTextSelection: true,
      }}
      tablePaginationProps={{ pageSize: statementFilters.size }}
      tableState={{
        selectAll: {
          select_all: false,
          selected: false,
        },
        selectedRows: [],
        currentPage: statementFilters.page,
        bulk_records: statementFilters.total_records,
      }}
      handlePageChange={(page, size) => {
        setStatementFilters((prev) => {
          return { ...prev, page, size };
        });
      }}
      sortMode={{
        type: 'remote',
        onSortChange(values) {
          setStatementFilters((value) => {
            return {
              ...value,
              sort_col: ['AMOUNT_BASE'].includes(values.sortCol) ? 'AMOUNT' : values.sortCol,
              sort_by: values.sortOrder as string,
            };
          });
          dispatchAppEvent({
            type: CUSTOMER_DETAILS_EVENT.SORTING,
            payload: {
              tab: 'Credit Notes',
              column: values.sortCol,
            },
          });
        },
      }}
    />
  );
}

export { CreditStatementsTable };
