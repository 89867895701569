import { FormInstance } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { TriggerEntityLabels } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import {
  WorkFlowFolloupOperatorLabel,
  WorkFlowInvoiceEventFilterType,
} from 'components/Settings/WorkFlowAutomation/utils';
import { head } from 'lodash';
import { CSSProperties } from 'styled-components';
import { CustomField } from 'types/entities/custom-field';
import { InAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import { WorkFlowRule } from '../../type';
import { BaseWorkFlowFilter } from '../EventCondition/type';
import { WorkFlowsCreateFilters } from '../Filters/type';
import { WorkFlowEventLabelConfig } from '../Predicate';
import { findMandatoryConditionsByEntityType, findOptionalConditions } from './Helper';
import { TriggerDisplayConditions } from './TriggerDisplayConditions';
import { TriggerTypeBasedDisplaySeparator } from './TriggerTypeBasedDisplaySeparator';
import { WorkflowStatusDisplay } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';

export interface DisplayTriggerCardProps {
  triggerData: WorkFlowRule;
  onDrawerClick?: () => void;
  form: FormInstance;
  customFields?: CustomField[];
}
interface statusProps {
  value: unknown;
}
const t = {
  edit: 'Edit',
  conditions: 'Conditions',
  operatorText: ' of the following conditions ',
  if: 'If ',
  pdfAvailable: 'Invoice PDF available',
  pdfNotAvailable: 'Invoice PDF not available',
  emailDelivered: 'Invoice email delivered',
  emailNotDelivered: 'Invoice email not delivered',
};
const editButtonStyle: CSSProperties = {
  textAlign: 'left',
  borderRadius: 'var(--space-4)',
  color: 'var(--primary-7)',
  width: 'fit-content',
};

export default function DisplayTriggerCard(props: DisplayTriggerCardProps) {
  const booleanEvents = ['INVOICE_PDF', 'DELIVERY_FLAG'];
  const entityTypeLabel = TriggerEntityLabels[props?.triggerData?.trigger_type];
  const selectedEvent =
    props?.triggerData?.event_type &&
    WorkFlowEventLabelConfig[props.triggerData?.entity_type][props?.triggerData?.event_type];
  const conditionList = findOptionalConditions(props.triggerData);

  const mandatory = findMandatoryConditionsByEntityType(
    props?.triggerData?.trigger_type,
    props.triggerData
  );

  const eventCreated = props.triggerData?.condition?.conditions?.find((condition) => {
    const invoiceCretedCondition = condition?.type as WorkFlowInvoiceEventFilterType;
    return WorkFlowsCreateFilters.includes(invoiceCretedCondition);
  });

  const mandatoryEventProps = {
    condition: mandatory,
    entityType: entityTypeLabel,
    selectedEvent,
    eventCreated: eventCreated?.type,
    customFields: props.customFields,
    color: 'var(--gray-9)',
  };

  const EntityType = (
    <GrowText size="var(--fs-14)" color="var(--gray-7)">
      {entityTypeLabel}
    </GrowText>
  );

  const ConditionText = <GrowText color="var(--gray-7)">{t.conditions}</GrowText>;
  const OperatorText = (
    <GrowText color="var(--gray-9)">
      {t.if}
      <GrowText strong>
        {WorkFlowFolloupOperatorLabel[props?.triggerData?.condition?.operator]?.toUpperCase()}
      </GrowText>
      {t.operatorText}
    </GrowText>
  );
  const MandatoryEvent = <TriggerTypeBasedDisplaySeparator {...mandatoryEventProps} />;

  function conditionMapPreicate(condition: BaseWorkFlowFilter, index: number) {
    const workFlowOperator = condition?.operator?.operands as InAbsoluteOperands<unknown>;
    const hasBooleanEvents = booleanEvents?.includes(condition?.type);
    const pdfStatus = displayPDFStatus(workFlowOperator?.value_holder);
    const emailStatus = displayDeliveryFlagStatus(workFlowOperator?.value_holder);
    const type = condition?.type as WorkFlowInvoiceEventFilterType;
    const conditionListProps = {
      condition,
      selectedEvent: WorkFlowEventLabelConfig[props.triggerData?.entity_type][type],
      color: 'var(--gray-9)',
      entityType: entityTypeLabel,
      mandatory: false,
      text: type === 'DUE_DAYS' ? 'days' : '',
      customFields: props.customFields,
    };

    function displayPDFStatus(status: statusProps[]) {
      const headStatus = head(status)?.value;
      return (
        <GrowText color={'var(--gray-9)'}>
          {headStatus ? t.pdfAvailable : t.pdfNotAvailable}
        </GrowText>
      );
    }
    function displayDeliveryFlagStatus(status: statusProps[]) {
      const headStatus = head(status)?.value;
      return (
        <GrowText color={'var(--gray-9)'}>
          {headStatus ? t.emailDelivered : t.emailNotDelivered}
        </GrowText>
      );
    }

    return (
      <li key={condition?.type}>
        <HideWrapper hide={condition?.type !== 'INVOICE_PDF'}>{pdfStatus}</HideWrapper>
        <HideWrapper hide={condition?.type !== 'DELIVERY_FLAG'}>{emailStatus}</HideWrapper>
        <HideWrapper hide={hasBooleanEvents}>
          <TriggerDisplayConditions {...conditionListProps} />
        </HideWrapper>
      </li>
    );
  }

  const ConditionList = <ol>{conditionList?.map(conditionMapPreicate)}</ol>;

  const ConditionListDisplayArea = (
    <Flex direction="column" gap="var(--space-4)">
      {ConditionText}
      {OperatorText}
      {ConditionList}
    </Flex>
  );

  const EventType = WorkflowStatusDisplay(props?.triggerData?.event_type);
  return (
    <Flex direction="column" gap="var(--space-16)">
      <Flex direction="column" gap="var(--space-12)">
        <Flex direction="column" gap="var(--space-4)">
          {EntityType}
          <>{EventType}</>
        </Flex>
        <HideWrapper hide={!conditionList?.length}>{ConditionListDisplayArea}</HideWrapper>
      </Flex>
    </Flex>
  );
}
