import { ContactType, ParentContactType } from 'types/entities/contact';

export const ContactTypeLabel: Record<ContactType | ParentContactType, string> = {
  [ContactType.PRIMARY_CONTACT]: 'Primary Contact',
  [ContactType.SECONDARY_CONTACT]: 'Secondary Contact',
  [ParentContactType.PRIMARY_CONTACT_PARENT]: 'Primary Contact (Parent)',
  [ParentContactType.SECONDARY_CONTACT_PARENT]: 'Secondary Contact (Parent)',
  [ContactType.OTHER_CONTACT]: 'Other Contact',
  [ContactType.PURCHASER]: 'Purchaser',
  [ContactType.PAYER]: 'Payer',
};
