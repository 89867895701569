import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowTypography } from '@sinecycle/growcomponents';
import { Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledIconButton, StyledPreviewItem } from 'components/Common/Styles/Styles';
import { getDocTypeIcon } from 'components/HigherOrderComponent/DocViewer';
import { FileType, ValidFileType } from 'types/common/attachments';
import { StatementTypes } from 'types/customerstatement/customer-statement';

interface CustomerStatementPreviewProps {
  hide?: boolean;
  fileType: FileType;
  onRemove: () => void;
  hideClose?: boolean;
  statementType: StatementTypes;
}

const t = {
  statement: 'Customer Statement',
  SOAStatement: 'Consolidated Statement',
};
function CustomerStatementPreview(props: Readonly<CustomerStatementPreviewProps>) {
  if (props.hide) return null;
  const previewText =
    props.statementType === StatementTypes.OUTSTANDING_TRANSACTIONS ? t.statement : t.SOAStatement;
  const FileIcon = getDocTypeIcon(props.fileType as ValidFileType);
  const Title = <GrowTypography.Text>{previewText}</GrowTypography.Text>;

  const CloseIcon = <FontAwesomeIcon icon={['far', 'times']} color="var(--gray-7)" />;
  const CloseButton = (
    <Tooltip placement="top" title="Remove" mouseEnterDelay={0.5}>
      <StyledIconButton
        icon={CloseIcon}
        size="small"
        style={{ width: '12px', height: '12px', display: 'flex' }}
        onClick={props.onRemove}
      />
    </Tooltip>
  );
  const FileInfo = (
    <Flex align="center" gap="--space-8">
      {FileIcon}
      {Title}
    </Flex>
  );
  return (
    <StyledPreviewItem align="center" justify="space-between" gap="--space-8">
      {FileInfo}
      {props.hideClose ? null : CloseButton}
    </StyledPreviewItem>
  );
}

export default CustomerStatementPreview;
