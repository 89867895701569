import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import { ActivityDetailsLayoutProps } from 'types/activities/activity-types';
import { Escalation } from 'types/activities/escalation';
import { EscalationDetailsBody } from './EscalationDetailsBody';
import { EscalationDetailsInfo } from './EscalationDetailsInfo';

function EsclationDetailsLayout(props: ActivityDetailsLayoutProps<Escalation>) {
  const {
    customer,
    hideActions,
    mode,
    subscriptionType,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
    isLoading,
    data,
    isUnauthorized,
    isReadOnly,
    hideCopyLink,
  } = props;
  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <EscalationDetailsBody
          data={data}
          isLoading={isLoading}
          hideActions={hideActions}
          customer={customer}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
          isReadOnly={isReadOnly}
          hideCopyLink={hideCopyLink}
        />
      }
      info={
        <EscalationDetailsInfo
          loading={isLoading}
          data={data}
          customer={customer}
          activitiesMode={activitiesMode}
          isReadOnly={isReadOnly}
        />
      }
    />
  );
}

export { EsclationDetailsLayout };
