import { GrowDate, GrowNumberFormat, GrowTypography } from '@sinecycle/growcomponents';

function getTextComponent(value?: string) {
  return <GrowTypography.Text>{value ?? '-'}</GrowTypography.Text>;
}

function getNumberComponent(
  value?: number,
  locale?: string,
  mode?: React.ComponentProps<typeof GrowNumberFormat>['options']['mode']
) {
  return (
    <GrowNumberFormat
      value={value ?? 0}
      options={{
        locale,
        mode,
      }}
    />
  );
}

function getDateComponent(value?: string, dateFormat?: string, showTime?: boolean) {
  return <GrowDate value={value ?? ''} format={dateFormat ?? ''} showTime={showTime} />;
}
export { getDateComponent, getNumberComponent, getTextComponent };
