import { InputNumber, Select } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { DateOffsetSelectOptions } from './ActionPredicate';
import { FormItem } from './style';
import { DateOffsetSelectProps, DateSelectKeys } from './types';

const t = {
  enter_date_offset: 'Enter date offset',
};

export default function DateOffsetSelect(props: DateOffsetSelectProps) {
  const { title, label, initialValue, form, onText, value } = props;

  const dateSelectOffset = useFormWatch('date_select_offset', form);

  return (
    <Flex align="center" gap={'var(--space-12)'}>
      <FormItem label={label} name="date_select_offset" initialValue={initialValue}>
        <Select
          defaultValue={DateSelectKeys.ON}
          options={DateOffsetSelectOptions}
          style={{ maxWidth: '83px' }}
        />
      </FormItem>
      <HideWrapper hide={dateSelectOffset !== DateSelectKeys.ON}>
        <GrowText>{onText}</GrowText>
      </HideWrapper>
      <HideWrapper hide={dateSelectOffset === DateSelectKeys.ON}>
        <Flex gap={'var(--space-8)'} align="center">
          <FormItem
            name="due_date_offset"
            rules={[{ required: true, message: t.enter_date_offset }]}
            style={{ margin: 0 }}
            initialValue={value}
          >
            <InputNumber min={1} />
          </FormItem>
          <GrowText>{title}</GrowText>
        </Flex>
      </HideWrapper>
    </Flex>
  );
}
