import { RadioChangeEvent } from 'antd';
import { StyledRadioGroup } from 'components/BaseComponents/Buttons/Switcher';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Dayjs } from 'dayjs';
import produce from 'immer';
import { useState } from 'react';
import { InvoiceSegmentOperandType } from 'types/entities/invoice-segments/operands';
import { DateWorkFlowOperatorTypes } from '../../type';
import { getDefaultFilterDataFromType } from '../EventCondition/Helper';
import { DateWorkFlowFilterOperator } from '../EventCondition/type';
import { WorkFlowDateOptionType } from '../type';
import { WorkFlowDateRangeFilter } from './DateRangeFilterHelper/WorkFlowDateRangeFilter';
import { WorkFlowRelativeDateFilter } from './RelativeDateFilter/WorkFlowRelativeDateFilter';
import { defaultDateOption, defaultRelativeDateData } from './predicate';

interface WorkFlowDefaultDateFilterProps {
  value?: DateWorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<unknown>) => void;
  percision?: number;
  singleFieldMoreInfo?: string;
}

export default function WorkFlowDefaultDateFilter(props: WorkFlowDefaultDateFilterProps) {
  const [selectedOption, setSelectedOption] = useState<WorkFlowDateOptionType>(
    props?.value?.operands?.type ? props?.value?.operands?.type : InvoiceSegmentOperandType.ABSOLUTE
  );
  const defaultFilterData = getDefaultFilterDataFromType('ISSUE_DATE');

  function dateOptionOnchange(e: RadioChangeEvent) {
    setSelectedOption(e.target.value);
    if (e.target.value === 'ABSOLUTE') {
      const updateDateRange =
        props.value &&
        produce(props.value, (draft) => {
          if (defaultFilterData) {
            draft.type = defaultFilterData?.operator.type as DateWorkFlowOperatorTypes;
            draft.operands = defaultFilterData?.operator
              .operands as DateWorkFlowFilterOperator<Dayjs>['operands'];
          }
        });
      updateDateRange && props?.onChange?.(updateDateRange);
    }
    if (e.target.value === 'RELATIVE') {
      const updateDateRange =
        props.value &&
        produce(props.value, (draftfilter) => {
          draftfilter.type = defaultRelativeDateData?.operator.type as DateWorkFlowOperatorTypes;
          draftfilter.operands = defaultRelativeDateData?.operator
            .operands as DateWorkFlowFilterOperator<number>['operands'];
        });
      updateDateRange && props?.onChange?.(updateDateRange);
    }
  }

  function filterRenderer() {
    switch (selectedOption) {
      case 'ABSOLUTE':
        return (
          <WorkFlowDateRangeFilter.FilterField
            value={props.value ? props.value : defaultFilterData?.operator}
            onChange={props.onChange}
            {...props}
          />
        );
      case 'RELATIVE':
        return (
          <WorkFlowRelativeDateFilter.FilterField
            value={props.value ? props.value : defaultRelativeDateData?.operator}
            onChange={props.onChange}
          />
        );
      default:
        return <></>;
    }
  }
  const DifferentDateRadioButton = (
    <StyledRadioGroup
      options={defaultDateOption}
      defaultValue={
        props?.value?.operands?.type
          ? props?.value?.operands?.type
          : InvoiceSegmentOperandType.ABSOLUTE
      }
      optionType="button"
      onChange={dateOptionOnchange}
    />
  );
  const Filters = filterRenderer();
  return (
    <Flex direction="column" justify="center" align="flex-start" gap="var(--space-8)">
      {DifferentDateRadioButton}
      {Filters}
    </Flex>
  );
}
