import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowText } from 'components/BaseComponents/Typography';

const t = {
  noResult: 'No result',
};

export const WorkFlowPlusIcon = (
  <FontAwesomeIcon
    icon={['far', 'plus-circle']}
    color={'var(--primary-7)'}
    style={{ fontSize: 'var(--fs-14)' }}
  />
);
export const WorkFlowSearchIcon = (
  <FontAwesomeIcon icon={['far', 'search']} fontSize={'var(--fs-14)'} />
);
export const WorkFlowDeleteIcon = (
  <FontAwesomeIcon className="delete-icon" icon={['far', 'minus-circle']} color="var(--red-7)" />
);
export const WorkFlowNoResultText = <GrowText color="var(--gray-7)">{t.noResult}</GrowText>;
