import { FormInstance } from 'antd';

import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import WorkflowButtons from 'components/Settings/WorkFlowAutomation/common/workflowButtons';
import { memo, useState } from 'react';
import { WorkflowActionRule } from '../../type';
import useExtractSelectedType from '../hooks/useExtractSelectedType';
import { t } from '../text';
import { ActionActivityTypes, ActionTypes, APPROVALSTATUS } from '../types';
import { isInvoiceLevelAction, processApprovalLevels } from '../utils';
import { AddActionModal } from './AddActionModal';

interface AddActionsProps {
  form: FormInstance;
  isNewAction: boolean;
  parentId?: string | number;
  workflowId?: boolean;
  actionRule?: WorkflowActionRule<ActionTypes>[];
  disabled?: boolean;
  status?: APPROVALSTATUS;
  childId?: string | number;
  onInsert: (value?: any) => void;
  actionId?: string | number;
  entityType?: 'CUSTOMER' | 'INVOICE';
  onClick?: () => void;
  filterList?: ActionActivityTypes[];
  onSelection?: (value?: string | number) => void;
  isSelected?: boolean;
}

function AddActions(props: AddActionsProps) {
  const {
    form,
    actionRule,
    disabled,
    isNewAction,
    parentId,
    status,
    childId,
    onInsert,
    filterList,
    onSelection,
    isSelected,
  } = props;
  const [actionOpen, setActionOpen] = useState(false);
  const actionType = useFormWatch('type', form);
  const extractFieldLabel = useExtractSelectedType();
  const result = processApprovalLevels(actionRule, childId, filterList);
  return (
    <AddActionModal
      open={actionOpen}
      onClose={() => {
        setActionOpen(false);
      }}
      entityType={props.entityType}
      onSelect={(value) => {
        const result = isInvoiceLevelAction(value?.id, actionRule ?? []);
        form.setFields([
          {
            name: 'actionable_entity',
            value: result,
            touched: true,
          },
          {
            name: 'id',
            value: undefined,
            touched: true,
          },
          {
            name: 'is_action_saved',
            value: false,
            touched: true,
          },
          {
            name: 'previous_actionable_entity',
            value: actionType?.value,
            touched: true,
          },
          {
            name: 'type',
            value: extractFieldLabel(value?.id as any),
            touched: true,
          },
          {
            name: 'parent_id',
            value: parentId,
            touched: true,
          },
          {
            name: 'status',
            value: status,
            touched: true,
          },
          {
            name: 'rule_id',
            value: childId,
            touched: true,
          },
          {
            name: 'selected_item',
            value: undefined,
            touched: true,
          },
          {
            name: 'current_rule_id',
            value: childId,
            touched: true,
          },
        ]);

        props.onClick?.();
        onInsert({ value: extractFieldLabel(value?.id as any) });
        onSelection?.(childId);
      }}
      onOpenChange={(open) => {
        setActionOpen(open);
      }}
      filterList={result ? result : []}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setActionOpen(!actionOpen);
        }}
        style={
          isSelected
            ? {
                background: 'var(--primary-1)',
                padding: 'var(--space-8)',
                borderRadius: 'var(--br-1)',
                cursor: 'pointer',
              }
            : undefined
        }
      >
        <WorkflowButtons
          buttonTitle={t.action}
          toggleTriggerOpen={() => {
            setActionOpen(!actionOpen);
            form.setFields([
              { name: 'is_new_action', value: isNewAction, touched: true },
              { name: 'current_rule_id', value: undefined, touched: true },
              { name: 'last_action', value: undefined, touched: true },
            ]);
          }}
          disabled={disabled}
          disabledText={t.set_trigger}
        />
      </div>
    </AddActionModal>
  );
}

export default memo(AddActions);
