import { Col, Row, Spin } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardProps } from 'antd/lib';
import { useSearchParams } from 'lib/router';
import { head, omit } from 'lodash';
import { useEffect, useState } from 'react';
import { isAfterCurrentDate, isBeforeCurrentDate } from '../../Helper';
import { WorkFlowFormProps } from '../../common/types';
import Action from '../Action';
import { ActionList } from '../Action/ActionForm/ActionList';
import { ActionTypes } from '../Action/types';
import Trigger from '../Trigger';
import { WorkflowActionRule } from '../type';

type SelectedSection = 'TRIGGER' | 'ACTION';

export const CardStyles: CardProps['styles'] = {
  header: {
    backgroundColor: 'var(--gray-2)',
    padding: '0 var(--space-20)',
  },
  body: {
    padding: 'var(--space-16) var(--space-20)',
    overflowX: 'auto',
  },
};

export function findStatusType(startDate: string, enabled: boolean, endDate?: string) {
  const isStartLaterType = isAfterCurrentDate(startDate);
  const isStartDateBeforeCurrentDate = isBeforeCurrentDate(startDate);
  const isEndDateBeforeCurrentDate = isBeforeCurrentDate(endDate);
  const isWorkFlowStopped = isStartDateBeforeCurrentDate && isEndDateBeforeCurrentDate;
  const RunningworkFlowHideCondition = isWorkFlowStopped || isStartLaterType || !enabled;
  const ScheduledWorkFlowCondition = !isStartLaterType || !enabled;
  const StoppedWorkFlowCondition = !isWorkFlowStopped || !enabled;
  return { RunningworkFlowHideCondition, ScheduledWorkFlowCondition, StoppedWorkFlowCondition };
}
export default function WorkFlowForm(props: WorkFlowFormProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selected = searchParams.get('selected_section') as SelectedSection;
  const [selectedItem, setSelectedItem] = useState<WorkflowActionRule<ActionTypes>>();
  const [isInvoiceLevel, setIsInvoiceLevel] = useState<boolean>(
    selectedItem?.actionable_entity === 'INVOICE'
  );

  const last_action = props.form.actionForm.getFieldsValue(['last_action']);

  useEffect(() => {
    if (last_action?.last_action) {
      setSelectedItem(last_action?.last_action);
    }
  }, [last_action]);

  const [selectedActionType, setActionType] = useState();
  const allValues = props.form.actionForm.getFieldsValue();

  const resetValues = omit(allValues, ['type', 'parent_id', 'rule_id']);
  const defaultValue = head(head(props.workflowMeta.workflow_rules)?.actions);
  const enabled = props?.workflowMeta?.enabled;
  return (
    <div className="tw-bg-background-1 tw-p-12 tw-pr-[0px]">
      <Row
        gutter={[8, 8]}
        className="tw-w-full"
        wrap={false}
        style={{ height: 'calc(100vh - calc(64px + 56px + 24px))' }}
      >
        <Col
          flex={'336px'}
          className="tw-overflow-y-auto tw-overflow-x-hidden "
          style={{ height: 'inherit' }}
        >
          <div className="tw-flex tw-flex-col tw-gap-4">
            <Trigger
              triggerRule={props?.rule}
              form={props.form.triggerForm}
              workflowMeta={props.workflowMeta}
              actionForm={props.form.actionForm}
              mode="DISPLAY"
              onClick={() => {
                setSearchParams({ selected_section: 'TRIGGER' });
                setSelectedItem(undefined);
                setActionType(undefined);
                props.form.actionForm.setFieldsValue({
                  id: undefined,
                  status: undefined,
                  current_rule_id: undefined,
                  last_action: undefined,
                });
              }}
              selected={selected === 'TRIGGER'}
              loading={props.loading}
            />
            <FontAwesomeIcon icon={['far', 'arrow-down']} color="var(--gray-7)" fontSize={24} />
            <ActionList
              actionRule={props?.rule?.actions}
              onActionRemove={props.onActionRemove}
              actionRuleList={props.actionRuleList}
              workflowMeta={props.workflowMeta}
              baseForm={props.form.baseForm}
              form={props.form.actionForm}
              loading={props.loading}
              onClick={(value) => {
                setSearchParams({ selected_section: 'ACTION' });
                if (value !== 'intital') {
                  return;
                }
                props.form.actionForm.setFieldValue('id', defaultValue?.id);
                props.form.actionForm.setFieldValue('status', undefined);
                props.form.actionForm.setFieldValue('last_action', undefined);
                setSelectedItem(defaultValue);
              }}
              selected={selected === 'ACTION'}
              onSelect={(value) => {
                setSelectedItem(value);
                setIsInvoiceLevel(value?.actionable_entity === 'INVOICE');
              }}
              onInsert={(value) => {
                setActionType(value);
                setSelectedItem(undefined);
                props.onInsert?.(value);
                setIsInvoiceLevel(false);
                props.form.actionForm.resetFields(Object.keys(resetValues));
              }}
            />
          </div>
        </Col>
        <Col flex={2} className="tw-overflow-hidden tw-pb-32">
          <Spin spinning={props.loading}>
            <div className="tw-overflow-hidden tw-rounded ">
              <>
                {selected === 'TRIGGER' ? (
                  <Trigger
                    workflowMeta={props.workflowMeta}
                    triggerRule={props?.rule}
                    form={props.form.triggerForm}
                    actionForm={props.form.actionForm}
                    mode="DETAILS"
                    key="details-trigger"
                  />
                ) : (
                  <Action
                    actionRule={props?.rule?.actions}
                    isInvoiceLevel={isInvoiceLevel}
                    onInvoiceLevel={(value) => {
                      setIsInvoiceLevel(value);
                    }}
                    onActionRemove={(data, type) => {
                      props.onActionRemove(data, type);
                      setActionType(undefined);
                      setSelectedItem(undefined);
                    }}
                    emailFormData={props.emailFormData}
                    errors={props.errors}
                    handleEmailContentChange={props.handleEmailContentChange}
                    actionRuleList={props.actionRuleList}
                    workflowMeta={props.workflowMeta}
                    baseForm={props.form.baseForm}
                    form={props.form.actionForm}
                    onClick={(value) => {
                      setSearchParams({ selected_section: 'TRIGGER' });
                      if (value === 'inital') {
                        props.form.actionForm.setFieldValue('id', defaultValue?.id);
                        setSelectedItem(defaultValue);
                      }
                    }}
                    selected={selected === 'ACTION'}
                    mode="DETAILS"
                    selectedItem={selectedItem}
                    onInsert={(value) => {
                      setActionType(value);
                      setSelectedItem(undefined);
                      props.onInsert?.(value);
                      setIsInvoiceLevel(false);
                    }}
                    actionType={selectedActionType}
                    loading={props.loading}
                    enabled={enabled}
                  />
                )}
              </>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}
