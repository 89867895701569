import { CellRenderProps } from '@sinecycle/growcomponents';
import FollowupAction from 'components/HigherOrderComponent/KeyActivitesContainer/FollowupAction';
import { useSelector } from 'react-redux';
import { accountConfigSelector } from 'store/authentication/authentication';
import { IInvoices } from 'store/customer/types';
interface InvoiceRendererProps<T> extends CellRenderProps<T> {
  data: T;
  isFromInvoiceList?: boolean;
}
function FollowUpAction(props: InvoiceRendererProps<IInvoices>) {
  const { data, isFromInvoiceList } = props;
  const configList = useSelector(accountConfigSelector);
  const viewChild = configList?.parent_child_config.view_child;
  return (
    <FollowupAction
      customerId={data?.customer_id}
      nextFollowup={data.next_followup_task}
      openTaskCount={data.open_task_count}
      viewChild={isFromInvoiceList ? false : viewChild}
      invoiceList={[
        {
          id: data.id,
          invoice_no: data.invoice_no,
          balance_due_amount: data.balance_due_amount,
          invoice_date: data.invoice_date,
          invoice_status: data.status,
          currency: data.currency,
          customer_id: data.customer_id,
          customer_name: data.customer_name,
          customer_no: data.customer_no,
          customer: data.customer,
          invoice_amount: data.amount,
          is_parent_customer: data?.is_parent_customer,
        },
      ]}
      context="Table"
    />
  );
}

export { FollowUpAction };
