import { DatePicker } from 'components/BaseComponents/AntComponents';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { DateChange } from 'components/CustomerDetails/CustomerDetailsHeaderNew/DownloadStatement/DownloadStatement';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { BetweenAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import { DateWorkFlowBetweenOperator } from '../../EventCondition/operator';
import { DateFilterRange } from './WorkFlowDateRangeFilter';

interface BetweenIssueDateProps {
  value?: DateWorkFlowBetweenOperator<Dayjs>;
  onChange?: (updatedFilter: DateWorkFlowBetweenOperator<Dayjs>) => void;
}
const { RangePicker } = DatePicker;

export default function BetweenIssueDate(props: BetweenIssueDateProps) {
  const range: DateFilterRange = initializeBetweenValue();
  const format = useSelector(dateFormatSelector);
  function initializeBetweenValue(): DateFilterRange {
    const rangeOperand = props.value?.operands as BetweenAbsoluteOperands<Dayjs>;
    const from = (rangeOperand?.value_holder as BetweenAbsoluteOperands<Dayjs>['value_holder'])
      ?.from.value;
    const to = (rangeOperand?.value_holder as BetweenAbsoluteOperands<Dayjs>['value_holder'])?.to
      .value;

    return {
      from: {
        value: dayjs(from) ?? dayjs(),
      },
      to: {
        value: dayjs(to) ?? dayjs(),
      },
    };
  }
  function handleFromChange(values: DateChange) {
    const updatedFilter = produce(props.value, (draftFilter) => {
      const rangeOperand = draftFilter?.operands as BetweenAbsoluteOperands<Dayjs>;
      const operandsValue = rangeOperand.value_holder as BetweenAbsoluteOperands<
        Dayjs | string
      >['value_holder'];
      if (values?.[0]) operandsValue.from.value = values?.[0];
      if (values?.[1]) operandsValue.to.value = values?.[1];
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <StyledFormItem>
      <RangePicker
        defaultValue={[range.from.value, range.to.value]}
        format={format}
        allowClear={false}
        style={{ width: '240px' }}
        onChange={handleFromChange}
      />
    </StyledFormItem>
  );
}
