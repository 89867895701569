import { RcFile, UploadFile } from 'antd/lib/upload';
import { notify } from 'components/BaseComponents/Notifications';
import { FilePreview, FileUploadPreview } from 'components/BaseComponents/UploadFiles/FilePreview';
import FileUploadDrawer, { UploadDrawerPosition } from 'components/Common/FileUploadDrawer';
import { maxTotalFileUploadLimit } from 'components/Common/FileUploadDrawer/constants';
import { UploadFilesFile, UploadStatus } from 'components/Common/FileUploadDrawer/type';
import UploadFiles from 'components/Common/Upload';
import produce from 'immer';
import { useState } from 'react';
import { AttachmentIdResponse } from 'services/attachments';
import { Attachment } from 'types/common/attachments';
import { FileUploadData } from 'types/entities/collection-strategy/rule-action';
import useDisableStatus from 'util/hooks/useDisableStatus';
import useOpen from 'util/hooks/useOpen';
import { Info } from 'util/hooks/useUploadFileList';
interface UploadFileProps {
  onUpload: (fileUpload: FileUploadData) => void;
  handleRemove(fileUploadId: number): void;
  value?: FileUploadData[];
  statement: React.ReactNode;
  statementPreview: React.ReactNode;
  onUploadFilesOpen?: (open: boolean) => void;
  uploadPositionSpacing?: UploadDrawerPosition;
}

function UploadFileComponent(props: UploadFileProps) {
  const {
    onUpload,
    handleRemove,
    value,
    onUploadFilesOpen,
    uploadPositionSpacing = UploadDrawerPosition.UPLOAD_POSITION,
  } = props;

  const [fileList, setFileList] = useState<UploadFilesFile<AttachmentIdResponse>[]>([]);
  const [loading, setLoading] = useState(false);
  const { open, toggleOpen, onClose } = useOpen({ open: false });
  const [totalFileSize, setTotalFileSize] = useState(0);

  const { handleDispatchEvent } = useDisableStatus();
  function handleUploading(status: UploadStatus, filesList: UploadFile<any>[]) {
    if (status === 'uploading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
    const fileStatuses: UploadStatus[] = filesList.map((file) => {
      return file.status;
    });
    if (fileStatuses.includes('uploading')) {
      handleDispatchEvent(true);
    } else {
      handleDispatchEvent(false);
    }
  }

  function handleFileChange(info: Info) {
    const allTotalFileSize = info.fileList.reduce(
      (totalFileSize: number, file) => totalFileSize + (file.size === undefined ? 0 : file?.size),
      0
    );
    const currentFileSize = info.file.size as number;
    const currentFileStatus = info.file.status as string;

    if (currentFileStatus === 'removed') {
      setTotalFileSize(totalFileSize - currentFileSize);
    }
    if (allTotalFileSize < maxTotalFileUploadLimit) {
      setFileList(info.fileList);
      handleUploading(info.file.status, info.fileList);
    }
    switch (info.file.status) {
      case 'done':
        onUpload({
          uploadId: info.file.response.id,
          fileName: info.file.name,
          fileSize: info.file.size,
          fileObjectUrl: URL.createObjectURL(info.file.originFileObj as Blob),
        });
        break;

      case 'removed':
        handleRemove(info.file.response.id);
        break;
      default:
        setLoading(false);
        break;
    }
  }

  function handleFileRemove(fileId: number) {
    if (!fileId) return;
    const updatedFiles = produce(fileList, (draft) => {
      return draft.filter((draftFile) => draftFile.response?.id !== fileId);
    });
    setFileList(updatedFiles);
    handleRemove(fileId);
    const updatedFileSize = updatedFiles.reduce(
      (totalFileSize: number, file) => totalFileSize + (file.size === undefined ? 0 : file.size),
      0
    );
    setTotalFileSize(updatedFileSize);
  }

  function handleBeforeUpload(file: RcFile, fileList: RcFile[]) {
    const currentSelectedFileSize = fileList.reduce(
      (totalFileSize: number, file) => totalFileSize + (file.size === undefined ? 0 : file?.size),
      0
    );
    const overallSize = totalFileSize + currentSelectedFileSize;
    if (overallSize >= maxTotalFileUploadLimit) {
      notify.error('File(s) exceeding 20MB cannot be attached');
      return false;
    }
    setTotalFileSize(overallSize);
    return true;
  }

  function onFileUploadClose() {
    onClose();
    onUploadFilesOpen && onUploadFilesOpen(true);
  }

  const CustomFilePreview = <FilesPreview fileUploads={value} onRemove={handleFileRemove} />;

  const FileUpload = (
    <UploadFiles
      fileList={[]}
      onClick={() => {
        toggleOpen();
        onUploadFilesOpen && onUploadFilesOpen(open);
      }}
      showLoading={loading}
      customRenderer={CustomFilePreview}
      extra={props.statementPreview}
    >
      <FileUploadDrawer
        zIndex={1_000_000}
        forceRender
        open={open}
        mask
        width={480}
        onClose={onFileUploadClose}
        fileList={fileList}
        action="/api/attachments"
        onChange={handleFileChange}
        extra={props.statement}
        beforeUpload={handleBeforeUpload}
        positionSpacing={uploadPositionSpacing}
      />
    </UploadFiles>
  );
  return FileUpload;
}

interface FilesPreviewProps {
  fileUploads?: FileUploadData[];
  onRemove?: (uploadId: number) => void;
}
export function FilesPreview({ fileUploads, onRemove }: FilesPreviewProps) {
  if (!fileUploads) return null;
  if (!fileUploads.length) return null;

  return (
    <>
      {fileUploads.map(({ uploadId, fileName, fileObjectUrl, fileSize }) => (
        <FileUploadPreview
          key={uploadId}
          previewUrl={fileObjectUrl}
          fileName={fileName}
          fileSize={fileSize}
          onRemove={onRemove && (() => onRemove(uploadId))}
        />
      ))}
    </>
  );
}

interface AttachmentsPreviewProps {
  attachments?: Attachment[];
}
export function AttachmentsPreview({ attachments }: AttachmentsPreviewProps) {
  if (!attachments) return null;

  return (
    <>
      {attachments.map((attachment) => (
        <FilePreview key={attachment.id} fileId={attachment.id} fileName={attachment.file_name} />
      ))}
    </>
  );
}

interface EmailFileUploadProps {
  value?: FileUploadData[];
  onChange?: (newValue: FileUploadData[]) => void;
  statement: React.ReactNode;
  statementPreview: React.ReactNode;
  onUploadFilesOpen?: (open: boolean) => void;
  uploadPositionSpacing?: UploadDrawerPosition;
}
export function EmailFileUpload(props: EmailFileUploadProps) {
  const { value = [], onChange, statement, statementPreview, onUploadFilesOpen } = props;

  function handleRemove(fileUploadId: number) {
    onChange && onChange(value.filter((fileUpload) => fileUpload.uploadId !== fileUploadId));
  }

  function handleUpload(fileUpload: FileUploadData) {
    onChange && onChange([...value, fileUpload]);
  }

  return (
    <UploadFileComponent
      onUpload={handleUpload}
      handleRemove={handleRemove}
      value={value}
      statement={statement}
      statementPreview={statementPreview}
      onUploadFilesOpen={onUploadFilesOpen}
      uploadPositionSpacing={props.uploadPositionSpacing}
    />
  );
}
