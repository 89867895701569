import { LayoutMode } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import { RaiseADisputeActivity } from 'store/activity-feed/type';
import { ActivitySubcriptionType } from 'types/api/inbox/activity';
import { BaseCustomer } from 'types/entities/customer';
import { CallLog } from './callLog';
import { CreditMemo } from './credit-memo';
import { Escalation } from './escalation';
import { Note } from './note';
import { Payment } from './payment';
import { PromiseToPay } from './promise-to-pay';
import { Task } from './task';
import { WriteOff } from './write-off';

export enum ActivityType {
  EMAIL = 'EMAIL',
  CALL_LOG = 'CALL_LOG',
  ESCALATION = 'ESCALATION',
  DISPUTE = 'DISPUTE',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  WRITE_OFF = 'WRITE_OFF',
  NOTE = 'NOTE',
  TASK = 'TASK',
  PAYMENT = 'PAYMENT',
  CREDIT_MEMO = 'CREDIT_MEMO',
  DOCUMENT = 'DOCUMENT',
}

export interface ActivityCopyLinkProps {
  data?:
    | CallLog
    | CreditMemo
    | RaiseADisputeActivity
    | Escalation
    | Note
    | Payment
    | PromiseToPay
    | Task
    | WriteOff;
  customer: Pick<BaseCustomer, 'id' | 'name' | 'customer_no'> | undefined;
  type: ActivityType;
}

export enum ActivityAction {
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_OPENED = 'EMAIL_OPENED',
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',

  RESPONDED = 'RESPONDED',
  VIEWED_ATTACHMENT = 'VIEWED_ATTACHMENT',

  DISPUTE_OPEN = 'DISPUTE_OPEN',
  DISPUTE_CLOSED = 'DISPUTE_CLOSED',
  DISPUTE_WAITING_ON_CUSTOMER = 'DISPUTE_WOC',

  ESCALATION_OPEN = 'ESCALATION_OPEN',
  ESCALATION_CLOSED = 'ESCALATION_CLOSED',

  PTP_OPEN = 'PTP_OPEN',
  PTP_CLOSED = 'PTP_CLOSED',

  CONTACT_MISSING = 'CONTACT_MISSING',
  INVALID_EMAIL_ID = 'INVALID_EMAIL_ID',
}

export enum FollowupActivity {
  OPEN_INVOICE_BALANCE = 'OPEN_INVOICE_BALANCE',
  ASSIGNED = 'ASSIGNED',
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  FOLLOWED_UP = 'FOLLOWED_UP',
  NOT_FOLLOWED_UP = 'NOT_FOLLOWED_UP',
  VIEWED = 'VIEWED',
  NOT_VIEWED = 'NOT_VIEWED',
  RESPONDED = 'RESPONDED',
  NOT_RESPONDED = 'NOT_RESPONDED',
}

/****************** INFERENCES *************************/
export type LastActivity = ActivityType | ActivityAction;

export type LastActivityType = Exclude<ActivityType, ActivityType.TASK>;

export type LastFollowupActivityType = ActivityType.EMAIL | ActivityType.CALL_LOG;

export type TaskAssociatedActivityType =
  | ActivityType.ESCALATION
  | ActivityType.WRITE_OFF
  | ActivityType.DISPUTE
  | ActivityType.CALL_LOG
  | ActivityType.PROMISE_TO_PAY
  | ActivityType.NOTE;

export type FollowupStatus =
  | FollowupActivity
  | ActivityAction
  | ActivityType.PROMISE_TO_PAY
  | ActivityType.ESCALATION
  | InvoiceCollectionActivity;

export enum InvoiceCollectionActivity {
  EMAIL_NOT_SENT = 'EMAIL_NOT_SENT',
  EMAIL_DELIVERED = 'EMAIL_DELIVERED',
  INVOICE_FLAGGED = 'INVOICE_FLAGGED',
}

export interface ActivityDetailsBaseProps {
  id?: number;
  type: Exclude<ActivityType, 'DOCUMENT'>;
  customer?: Pick<BaseCustomer, 'name' | 'id' | 'customer_no'>;
  hideActions?: boolean;
  queryKey?: string;
  mode?: LayoutMode;
  subscriptionType?: ActivitySubcriptionType;
  isListFetched?: boolean;
  onDelete?: (id: string, type?: ActivityType) => void;
  deleteLoading?: boolean;
  editLoading?: boolean;
  activitiesMode?: boolean;
  isReadOnly?: boolean;
  hideCopyLink?: boolean;
}

export interface ActivityDetailsLayoutProps<T> extends ActivityDetailsBaseProps {
  data?: T;
  isLoading: boolean;
  isUnauthorized?: boolean;
  isReadOnly?: boolean;
}
export type ActivityDetailsBase = Omit<ActivityDetailsBaseProps, 'type' | 'id'>;

export interface UpdateKeyActivitiesParams<T> {
  value: T;
  message?: {
    success: string;
    error: string;
  };
}
