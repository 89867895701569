import ActionTypeTitle from '../ActionConfigurations/ActionTypeTitle';
import { ActionRulesListProps } from '../types';

export default function ActionRulesList(props: ActionRulesListProps) {
  const { value, onActionEdit, form, onInsert, entityType } = props;

  return (
    <div className="tw-flex tw-flex-col tw-gap-12 tw-mb-12">
      {value?.length
        ? value?.map((item) => {
            return (
              <ActionTypeTitle
                entityType={entityType}
                actionItem={item}
                onActionEdit={onActionEdit}
                form={form}
                list={value}
                key={item.id}
                onInsert={onInsert}
                onClick={props.onClick}
              />
            );
          })
        : null}
    </div>
  );
}
