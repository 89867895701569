import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Tooltip } from 'antd';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import RichEmailContentEditor from 'components/BaseComponents/RTE/components/RichEmailContentEditor';
import { RichEmailSubjectEditor } from 'components/BaseComponents/RTE/components/RichEmailSubjectEditor';
import ToggleSwitchComponent from 'components/BaseComponents/Switch';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { RuleActionError } from 'components/CollectionStrategy/common/ErrorField';
import { UploadDrawerPosition } from 'components/Common/FileUploadDrawer';
import { RelativeContactSelect } from 'components/Common/SelectComponents/RelativeContactSelect';
import HideWrapper from 'components/Common/Util/HideWrapper';
import InsertEmailTemplates from 'components/EmailTemplates';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { ControlledFormItem as Controller } from 'components/HigherOrderComponent/GenerateForm';
import produce from 'immer';
import { deSerializePlaceholder } from 'lib/RichText/TipTapEditor/utils/adapters';
import { find, isEmpty, isEqual } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RuleErrors, defaultEmailValues } from 'store/collection-strategy-rule/initial-state';
import { DunningRuleEmailField } from 'store/collection-strategy-rule/validations';
import {
  AllOpenInvoicesAttachment,
  DocumentAttachment,
  EmailAttachment,
  GenerativeAttachment,
} from 'store/email/types';
import styled from 'styled-components';
import { AccountConfigKey } from 'types/entities/account';
import { FollowupType } from 'types/entities/collection-strategy';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { EmailTemplates, FolderType } from 'types/entities/email-templates';
import { AttachmentsPreview, EmailFileUpload } from '../FileUpload';
import useStatement from '../Statement/useStatement';

const FieldNames = {
  from: 'from',
  to: 'to',
  cc: 'cc',
  bcc: 'bcc',
  replyTo: 'reply_to',
  attachCurrentInvoice: 'attach_current_invoice',
  attachAllInvoices: 'attach_all_invoices',
  subject: 'subject',
  body: 'body',
  attachments: 'attachments',
  fileUploadIds: 'file_upload_ids',
  fileUploads: 'fileUploads',
  invoiceDelivery: 'invoice_delivery',
  generative_attachments: 'generative_attachments',
};
const FieldLabels = {
  from: 'From',
  to: 'To',
  cc: 'CC',
  bcc: 'BCC',
  subject: 'Subject',
  body: 'Body',
  bodyPlaceholder: 'Please enter the email content.',
  replyTo: 'Reply To',
  attachments: 'Attachments',
  attachInvoicePdf: 'Attach Invoice PDF',
  attachCurrentInvoice: 'Attach Invoice',
  attachAllInvoices: 'Attach All Open Invoices',
  generative_attachments: 'generative_attachments',
  composeNewEmail: 'Compose Mail',
  considerInvoiceDelivery: 'Consider as Invoice Delivery',
  invoiceDeliveryInfo: 'For invoices that are already delivered, this email will not be sent',
};

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-8);
`;
const linkButtonStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
};
const fromFieldStyle: React.CSSProperties = {
  gap: '0',
};

export interface EmailStrategyFormProps {
  value?: Partial<EmailActionData>;
  onChange?: (newValue: Partial<EmailActionData>) => void;
  followupType: FollowupType;
  showErrors?: boolean;
  errors?: RuleErrors<DunningRuleEmailField>;
  onUploadFilesOpen?: (open: boolean) => void;
  isInvoiceLevel?: boolean;
  enableInvoiceDelivery?: boolean;
  uploadPositionSpacing?: UploadDrawerPosition;
}

const questionCircleIcon = (
  <FontAwesomeIcon
    icon={['far', 'circle-question']}
    color="var(--geekblue-6)"
    style={{ cursor: 'pointer' }}
  />
);

export function EmailForm(props: EmailStrategyFormProps) {
  const { onChange, value, followupType, isInvoiceLevel, enableInvoiceDelivery } = props;
  const [showCc, setShowCc] = useState(Boolean(value?.cc?.length));
  const [showBcc, setShowBcc] = useState(Boolean(value?.bcc?.length));
  const [showReplyTo, setShowReplyTo] = useState(Boolean(value?.reply_to?.type));

  const formMethods = useForm<EmailActionData>({
    defaultValues: {
      ...defaultEmailValues,
      ...value,
      subject: deSerializePlaceholder(value?.subject),
    },
  });

  const {
    AssociatedFileComp,
    ExtraPreview,
    handleTemplateUpload,
    handleTemplateAttachFiles,
    handleInsertDocuments,
    resetDocuments,
  } = useStatement(formMethods, followupType);

  const { control, watch, setValue, getValues } = formMethods;

  const isWorkflowEnabled = useConfig(AccountConfigKey.WORKFLOW_ENABLED);

  function handleHideInvoiceDelivery() {
    return !isWorkflowEnabled || (isWorkflowEnabled && isInvoiceLevel);
  }

  const hideInvoiceDelivery = useMemo(handleHideInvoiceDelivery, [
    isWorkflowEnabled,
    isInvoiceLevel,
  ]);

  function getAttachments() {
    const values = find(value?.attachments, { type: 'ATTACHMENT' }) as EmailAttachment;
    return values ? values.list : undefined;
  }

  const at = useMemo(getAttachments, [value?.attachments]);
  useEffect(() => {
    if (!onChange) return;

    const debouncedOnChange = onChange && debounce(onChange, 500);

    function handleWatch(value: any) {
      debouncedOnChange && debouncedOnChange(value);
    }

    const subscription = watch((value) => handleWatch(value));
    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  const validEmailTemplateFolders = useMemo(() => {
    return followupType === FollowupType.INVOICE
      ? [FolderType.SINGLE_INVOICE, FolderType.SINGLE_CUSTOMER]
      : [FolderType.SINGLE_CUSTOMER];
  }, [followupType]);

  function handleCc() {
    if (showCc) {
      setShowCc(false);
    } else {
      setShowCc(true);
    }
  }
  function handleBcc() {
    if (showBcc) {
      setShowBcc(false);
    } else {
      setShowBcc(true);
    }
  }
  function handleReplyTo() {
    if (showReplyTo) {
      setShowReplyTo(false);
    } else {
      setShowReplyTo(true);
    }
  }
  function handleTemplateSelect(selectedEmailTemplate: EmailTemplates) {
    setValue('subject', selectedEmailTemplate.subject);
    setValue('body', selectedEmailTemplate.body);

    const statement = find(selectedEmailTemplate.attachments, {
      type: 'GENERATIVE_ATTACHMENT',
    }) as GenerativeAttachment;

    if (statement && Boolean(statement.list.length)) {
      handleTemplateUpload(statement.list[0]);
    } else {
      handleTemplateUpload(undefined);
    }

    const previousValues = getValues('attachments') ?? [];

    const allInvoices = find(selectedEmailTemplate.attachments, {
      type: 'ALL_OPEN_INVOICES',
    }) as AllOpenInvoicesAttachment;

    const updatedValues = produce(previousValues, (draft) => {
      const isExist = draft.findIndex((f) => f.type === 'ALL_OPEN_INVOICES');
      const isExist2 = draft.findIndex((f) => f.type === 'CURRENT_INVOICE');

      if (!allInvoices || !allInvoices.type) return draft;

      if (isExist !== -1) {
        draft[isExist] = {
          type: followupType === FollowupType.INVOICE ? 'CURRENT_INVOICE' : 'ALL_OPEN_INVOICES',
        };
      } else if (isExist2 !== -1) {
        draft[isExist2] = {
          type: followupType === FollowupType.INVOICE ? 'CURRENT_INVOICE' : 'ALL_OPEN_INVOICES',
        };
      } else {
        draft.push({
          type: followupType === FollowupType.INVOICE ? 'CURRENT_INVOICE' : 'ALL_OPEN_INVOICES',
        });
      }
    });
    setValue('attachments', updatedValues);

    handleTemplateAttachFiles(allInvoices ? Boolean(allInvoices.type) : false);
    const templateDocuments = find(selectedEmailTemplate.attachments, {
      type: 'DOCUMENT',
    }) as DocumentAttachment;

    if (templateDocuments) {
      handleInsertDocuments(templateDocuments);
    } else {
      resetDocuments();
    }
  }

  function checkValidContent(content: string) {
    const emptySubjectAndbodyContent = '<div><br></div>';
    return !isEqual(content, emptySubjectAndbodyContent) || !isEmpty(content);
  }

  const shouldConfirmBeforeTemplateInsert = useCallback(() => {
    const { body, subject } = getValues();
    const validBody = checkValidContent(body);
    const validSubject = checkValidContent(subject);

    return validBody || validSubject;
  }, [getValues]);

  const CcLinkButtons = (
    <Flex gap="--space-12">
      <Button style={linkButtonStyle} type="link" onClick={handleCc}>
        {FieldLabels.cc}
      </Button>
      <Button style={linkButtonStyle} type="link" onClick={handleBcc}>
        {FieldLabels.bcc}
      </Button>
      <Button style={linkButtonStyle} type="link" onClick={handleReplyTo}>
        {FieldLabels.replyTo}
      </Button>
    </Flex>
  );

  const FromField = (
    <FormField style={fromFieldStyle}>
      <Flex justify="space-between" align="center">
        <Texto>{FieldLabels.from}</Texto>
        {CcLinkButtons}
      </Flex>
      <Controller fieldName={FieldNames.from} control={control} noImplicitGapForError>
        <RelativeContactSelect mode="single" showAREmail />
      </Controller>
    </FormField>
  );

  const ToField = (
    <FormField>
      <Texto>{FieldLabels.to}</Texto>
      <Controller fieldName={FieldNames.to} control={control} noImplicitGapForError>
        <RelativeContactSelect mode="multi" showEmailableOptions />
      </Controller>
      <RuleActionError
        errors={props.errors}
        errorField={DunningRuleEmailField.to}
        showError={props.showErrors}
      />
    </FormField>
  );

  const CcField = (
    <FormField>
      <Texto>{FieldLabels.cc}</Texto>
      <Controller fieldName={FieldNames.cc} control={control} noImplicitGapForError>
        <RelativeContactSelect mode="multi" showEmailableOptions />
      </Controller>
    </FormField>
  );

  const BccField = (
    <FormField>
      <Texto>{FieldLabels.bcc}</Texto>
      <Controller fieldName={FieldNames.bcc} control={control} noImplicitGapForError>
        <RelativeContactSelect mode="multi" showEmailableOptions />
      </Controller>
    </FormField>
  );

  const ReplyToField = (
    <FormField>
      <Texto>{FieldLabels.replyTo}</Texto>
      <Controller fieldName={FieldNames.replyTo} control={control} noImplicitGapForError>
        <RelativeContactSelect mode="single" allowClear showAREmail />
      </Controller>
    </FormField>
  );

  const SubjectField = (
    <FormField>
      <Texto>{FieldLabels.subject}</Texto>
      <Controller
        fieldName={FieldNames.subject}
        control={control}
        customOnChangeField="onChangeImmediate"
        noImplicitGapForError
      >
        <RichEmailSubjectEditor
          value={FieldLabels.subject}
          onChange={(modified: string | null) => modified && setValue('subject', modified)}
          validFolders={validEmailTemplateFolders}
          onTemplateSelect={handleTemplateSelect}
        />
      </Controller>
      <RuleActionError
        errors={props.errors}
        errorField={DunningRuleEmailField.subject}
        showError={props.showErrors}
      />
    </FormField>
  );

  const BodyField = (
    <FormField>
      <Texto>{FieldLabels.body}</Texto>
      <Controller
        fieldName={FieldNames.body}
        control={control}
        customValueField="content"
        customOnChangeField="onChangeImmediate"
        noImplicitGapForError
      >
        <RichEmailContentEditor
          value={FieldLabels.body}
          onChange={(modified: string | null) => modified && setValue('body', modified)}
          placeholder={FieldLabels.bodyPlaceholder}
          onTemplateSelect={handleTemplateSelect}
          validFolders={validEmailTemplateFolders}
        />
      </Controller>
      <RuleActionError
        errors={props.errors}
        errorField={DunningRuleEmailField.body}
        showError={props.showErrors}
      />
    </FormField>
  );

  const UploadField = (
    <Controller fieldName={FieldNames.fileUploads} control={control} noImplicitGapForError>
      <EmailFileUpload
        statement={AssociatedFileComp}
        statementPreview={ExtraPreview}
        onUploadFilesOpen={props.onUploadFilesOpen}
        uploadPositionSpacing={props.uploadPositionSpacing}
      />
    </Controller>
  );

  const attachmentPresent = Boolean(at?.length);

  const AttachmentField = attachmentPresent && (
    <Flex gap="--space-8" align="center" wrap="wrap">
      <Texto>{FieldLabels.attachments}</Texto>
      <AttachmentsPreview attachments={at} />
    </Flex>
  );

  const EmailTemplatesView = (
    <Flex align="center" justify="space-between">
      <Flex.Child>
        <TypographyText strong>{FieldLabels.composeNewEmail}</TypographyText>
      </Flex.Child>
      <Flex.Child>
        <InsertEmailTemplates
          confirmBeforeInsert={shouldConfirmBeforeTemplateInsert}
          validFolders={validEmailTemplateFolders}
          onTemplateSelect={handleTemplateSelect}
        />
      </Flex.Child>
    </Flex>
  );

  const InvoiceDelivery = (
    <HideWrapper hide={!hideInvoiceDelivery}>
      <Flex gap="var(--space-4)" align="center">
        <Controller fieldName={FieldNames.invoiceDelivery} control={control} noImplicitGapForError>
          <ToggleSwitchComponent
            checked={getValues('invoice_delivery')}
            label={FieldLabels.considerInvoiceDelivery}
            onChange={(value) => setValue('invoice_delivery', value)}
          />
        </Controller>
        <Tooltip title={FieldLabels.invoiceDeliveryInfo}>{questionCircleIcon}</Tooltip>
      </Flex>
    </HideWrapper>
  );

  return (
    <Flex direction="column" gap="--space-12">
      <FormProvider {...formMethods}>
        {FromField}
        {ToField}
        {showCc && CcField}
        {showBcc && BccField}
        {showReplyTo && ReplyToField}
        {EmailTemplatesView}
        {SubjectField}
        {BodyField}
        {attachmentPresent && <Divider style={{ margin: 'var(--space-4) 0' }} />}
        {AttachmentField}
        <Divider style={{ margin: 'var(--space-4) 0' }} />
        {enableInvoiceDelivery && InvoiceDelivery}
        {UploadField}
      </FormProvider>
    </Flex>
  );
}
