import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton } from '@sinecycle/growcomponents';
import { FormInstance, Tooltip, Tree } from 'antd';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { get, head } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { generateId } from 'util/generateId';
import { WorkflowActionRule } from '../../type';
import { filterList } from '../ActionForm/ActionList';
import AddActions from '../AddActions/AddActions';
import { ActionTypeIconContainer, ActionTypeTitleContainer } from '../style';
import {
  ActionActivityTypes,
  ActionTypeTitleMapProps,
  ActionTypes,
  ApprovalAction,
  ApprovalRule,
} from '../types';
import { ApprovalTitle } from './TitleComponents/ApprovalTitle';
import CallLogTitle from './TitleComponents/CallLogTitle';
import CollectionOwnerTitle from './TitleComponents/CollectionOwnerTitle';
import CollectionStatusTitle from './TitleComponents/CollectionStatusTitle';
import CollectionStrategyTitle from './TitleComponents/CollectionStrategyTitle';
import CustomerCustomFieldTitle from './TitleComponents/CustomerCustomFieldTitle';
import DisputeTitle from './TitleComponents/DisputeTitle';
import EmailTitle from './TitleComponents/EmailTitle';
import EscalationTitle from './TitleComponents/EscalationTitle';
import FlagInvoicesTitle from './TitleComponents/FlagInvoicesTitle';
import InvoiceCustomFieldTitle from './TitleComponents/InvoiceCustomFieldTitle';
import NoteTitle from './TitleComponents/NoteTitle';
import PromiseToPayTitle from './TitleComponents/PromiseToPayTitle';
import TaskTitle from './TitleComponents/TaskTitle';

export const StyledTree = styled(Tree)`
  .ant-tree-treenode {
    width: 100%;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    width: 100%;
    align-items: baseline;
    gap: var(--space-8);
    z-index: 2;

    &:hover {
      background-color: transparent;
    }
  }

  .ant-tree-title {
    width: 100%;
  }

  .ant-tree .ant-tree-checkbox + span:hover {
    background-color: transparent;
  }

  .Approve,
  .Reject .Approve {
    &.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
      content: '' !important;
      height: auto !important;
      top: -12px !important;
      bottom: -20px !important;
      right: -18px !important;
    }
  }
  &.action-detail {
    .Approve,
    .Reject .Approve {
      &.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
        content: '' !important;
        height: 24px !important;
        top: -6px !important;
        bottom: 8px !important;
        right: -18px !important;
        border-radius: var(--br-3);
      }
    }
    .ant-tree-node-content-wrapper {
      padding-right: 0 !important;
    }
  }

  .Reject {
    &.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
      content: none !important;
    }
  }

  .ant-tree-switcher-leaf-line {
    &::before {
      border-inline-end: 2px solid var(--gray-5) !important;
    }

    &::after {
      content: none !important;
    }
  }
`;
export interface ActionTypeTitleProps {
  actionItem: WorkflowActionRule<ActionTypes>;
  onActionEdit: (data: WorkflowActionRule<ActionTypes>) => void;
  form: FormInstance;
  list?: WorkflowActionRule<ActionTypes>[];
  isNested?: boolean;
  onInsert: (value: any) => void;
  selected?: string;
  parent_id?: string | number;
  rule_id?: string | number;
  entityType?: 'CUSTOMER' | 'INVOICE';
  onClick?: () => void;
  isSelected?: boolean;
}

export const getActionType = (action?: WorkflowActionRule<ActionTypes>) =>
  match(action)
    .with(
      { type: ActionActivityTypes.CUSTOMER, action: { type: ActionActivityTypes.CUSTOMER } },
      (props) => {
        return props.action.field;
      }
    )
    .with(
      { type: ActionActivityTypes.INVOICE, action: { type: ActionActivityTypes.INVOICE } },
      (props) => {
        return props.action.field;
      }
    )
    .with(
      {
        type: ActionActivityTypes.INVOICE_CUSTOM_FIELD,
        action: { type: ActionActivityTypes.CUSTOM_FIELD },
      },
      (props) => {
        return props.type;
      }
    )
    .with(
      {
        type: ActionActivityTypes.CUSTOMER_CUSTOM_FIELD,
        action: { type: ActionActivityTypes.CUSTOM_FIELD },
      },
      (props) => {
        return props.type;
      }
    )
    .otherwise((props) => {
      return props?.type;
    });
export default function ActionTypeTitle(props: ActionTypeTitleProps) {
  const { actionItem, onActionEdit, form, isNested, onClick } = props;

  const selectedId = form.getFieldValue(['id']);

  const isSelected = isNested ? actionItem.actionId === selectedId : actionItem.id === selectedId;
  const ActionTypeTitleMap: ActionTypeTitleMapProps = {
    EMAIL: EmailTitle,
    DISPUTE: DisputeTitle,
    ESCALATION: EscalationTitle,
    PROMISE_TO_PAY: PromiseToPayTitle,
    TASK: TaskTitle,
    NOTE: NoteTitle,
    CUSTOMER_CUSTOM_FIELD: CustomerCustomFieldTitle,
    INVOICE_CUSTOM_FIELD: InvoiceCustomFieldTitle,
    EDIT_STRATEGY: CollectionStrategyTitle,
    USER_CATEGORY: CollectionOwnerTitle,
    EDIT_COLLECTION_STATUS: CollectionStatusTitle,
    FLAG_INVOICE: FlagInvoicesTitle,
    CALL_LOG: CallLogTitle,
    APPROVAL_REQUEST: ApprovalTitleWrapper,
  };

  const type = getActionType(props?.actionItem);
  const Component = type ? ActionTypeTitleMap[type] : undefined;

  return (
    <>
      {Component ? (
        <>
          {type === ActionActivityTypes.APPROVAL_REQUEST ? (
            <Component {...props} onClick={onClick} />
          ) : (
            <ActionTypeTitleContainer
              align="center"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onActionEdit({ ...actionItem, isNested });
                form.setFieldValue('is_new_action', false);
              }}
              className={isSelected ? 'selected' : ''}
            >
              <Component item={actionItem.action} strong={isSelected} />
            </ActionTypeTitleContainer>
          )}
        </>
      ) : null}
    </>
  );
}

function ApprovalTitleWrapper(props: ActionTypeTitleProps) {
  const { actionItem, onActionEdit, form, list, isNested, onClick } = props;
  const workflowAction = actionItem?.action as ApprovalAction;
  const currentselected = form.getFieldValue(['id']);

  const isSelected = currentselected === actionItem.id;
  const pa = list?.find((f) => f.type === ActionActivityTypes.APPROVAL_REQUEST)?.id;

  return (
    <>
      <ActionTypeTitleContainer
        align="center"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          onActionEdit({ ...actionItem, isNested });
          form.setFieldValue('is_new_action', false);
        }}
        className={isSelected ? 'selected' : ''}
      >
        <ApprovalTitle item={actionItem} strong={isSelected} />
      </ActionTypeTitleContainer>
      <>
        {workflowAction?.followup_workflow?.workflow_rules?.map((rule) => {
          return (
            <SendApprovalAddAction
              item={rule}
              actionItem={actionItem}
              onActionEdit={onActionEdit}
              form={form}
              list={list}
              key={generateId()}
              onInsert={props.onInsert}
              parent_id={pa}
              onClick={onClick}
            />
          );
        })}
      </>
    </>
  );
}

interface SendApprovalAddActionProp extends ActionTypeTitleProps {
  item: ApprovalRule;
}

function SendApprovalAddAction(props: SendApprovalAddActionProp) {
  const actions = useMemo(() => props?.item?.actions, [props?.item?.actions]);
  const currentselected = props.form.getFieldValue(['current_rule_id']);

  const fillteredList = actions
    ?.map((rule) => {
      const actionType = getActionType(rule);
      if (rule.type && actionType && filterList.includes(actionType)) {
        return getActionType(rule);
      }

      return;
    })
    .filter((item): item is ActionActivityTypes => !!item);
  const { isWorkflowOwner } = useWorkflowOwner();
  const condition = head(
    head(get(props.item, 'condition').conditions)?.operator.operands.value_holder
  )?.value;

  const Icon = (
    <FontAwesomeIcon
      icon={condition === 'APPROVED' ? ['far', 'thumbs-up'] : ['far', 'thumbs-down']}
      color={condition === 'APPROVED' ? 'var(--green-8)' : 'var(--red-7)'}
    />
  );

  const ruleId = props.item.id;
  const Icons = (
    <Tooltip title={condition === 'APPROVED' ? 'Approve' : 'Reject'}>
      <ActionTypeIconContainer
        style={{ border: 0 }}
        backgroundColor={condition === 'APPROVED' ? 'var(--green-1)' : 'var(--red-1)'}
      >
        {Icon}
      </ActionTypeIconContainer>
    </Tooltip>
  );

  const comp = useMemo(() => {
    return (
      <div className="tw-w-full tw-flex tw-flex-col tw-gap-16">
        <>
          {actions.map((action) => {
            return (
              <ActionTypeTitle
                actionItem={{
                  ...action,
                  parent_id: props.parent_id ? props.parent_id : props.actionItem.parent_id,
                  rule_id: ruleId,
                  actionId: action?.id,
                  status: condition,
                }}
                key={action.id}
                entityType={props.entityType}
                form={props.form}
                list={props.list}
                onActionEdit={props.onActionEdit}
                onInsert={props.onInsert}
                isNested
                onClick={props.onClick}
              />
            );
          })}
        </>
        {isWorkflowOwner ? (
          <>
            {actions.length ? null : (
              <span>
                <GrowButton type="text" disabled>
                  No action
                </GrowButton>
              </span>
            )}
          </>
        ) : (
          <div>
            <AddActions
              filterList={fillteredList}
              isNewAction
              form={props.form}
              actionRule={props.list}
              status={condition}
              parentId={props.parent_id ? props.parent_id : props.actionItem.parent_id}
              childId={ruleId}
              onInsert={props.onInsert}
              entityType={props.entityType}
              onClick={props.onClick}
              isSelected={Boolean(currentselected === ruleId)}
            />
          </div>
        )}
      </div>
    );
  }, [
    actions,
    condition,
    currentselected,
    fillteredList,
    isWorkflowOwner,
    props.actionItem.parent_id,
    props.entityType,
    props.form,
    props.list,
    props.onActionEdit,
    props.onClick,
    props.onInsert,
    props.parent_id,
    ruleId,
  ]);
  return (
    <StyledTree
      showIcon
      selectable={false}
      defaultExpandAll
      showLine
      icon={Icons}
      virtual={false}
      blockNode
      className={currentselected === ruleId ? 'selected-d' : ''}
    >
      <Tree.TreeNode
        title={<>{comp}</>}
        className={condition === 'APPROVED' ? 'Approve' : 'Reject'}
      />
    </StyledTree>
  );
}
