import { FormItemNames } from 'components/Common/ActivitiesForm/types';
import { castArray, head, isArray, uniq } from 'lodash';
import { ActivityType } from 'types/activities/activity-types';
import { ActionableEntity } from '../Email';
import { useFormCustomTransformerProps } from '../type';

export default function useFormCustomTransformer() {
  function onActivityFormTransform(props: useFormCustomTransformerProps) {
    const { data, customProps, noteRefid, customerId, invoices, selectAll } = props;
    if (!customProps) return {};
    const { type } = customProps;

    if (
      (selectAll && type === ActivityType.TASK) ||
      (selectAll && type === ActivityType.ESCALATION)
    ) {
      return { invoice_ids: invoices };
    }

    if (type === ActivityType.PROMISE_TO_PAY) {
      const amountDetails = data.ptpTable.map((item: Record<FormItemNames, any>) => {
        return {
          invoice_id: item.id,
          amount: item.amount,
        };
      });
      return {
        amount_details: amountDetails,
        type: ActionableEntity.CUSTOMER,
      };
    }

    if (type === ActivityType.CALL_LOG || type === ActivityType.TASK) {
      return { customer_ids: uniq(castArray(customerId)) };
    }

    if (type === ActivityType.NOTE) {
      return {
        ref_id_list: castArray(noteRefid),
        type: invoices ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER,
      };
    }

    if (type === ActivityType.ESCALATION) {
      const customerIdData = isArray(customerId) ? customerId : castArray(customerId);
      return {
        customer_id:
          (customerIdData as number[]).length > 1 ? null : head(customerIdData as number[]),
      };
    }

    return {};
  }

  function onActivityAssociatedFormTransform(props: useFormCustomTransformerProps) {
    const { data, customProps } = props;

    if (!customProps) return {};
    const { type, associatedData, parentType } = customProps;
    const invoiceIds = associatedData?.invoice_ids;
    const customerIds = associatedData?.customer_id;
    if (type === ActivityType.TASK && parentType === ActivityType.CALL_LOG) {
      return {
        customer_ids: uniq(castArray(customerIds)),
        associated_activity_id: associatedData?.id,
        associated_activity_type: parentType,
        description: data.callLogDescription,
        invoice_ids: invoiceIds,
      };
    }

    if (type === ActivityType.TASK && parentType === ActivityType.NOTE) {
      return {
        customer_ids: uniq(castArray(customerIds)),
        associated_activity_id: associatedData?.id,
        associated_activity_type: parentType,
        description: data.noteDescription,
        invoice_ids: invoiceIds,
      };
    }

    if (type === ActivityType.TASK && parentType === ActivityType.DISPUTE) {
      return {
        customer_ids: uniq(castArray(customerIds)),
        associated_activity_id: associatedData?.id,
        associated_activity_type: parentType,
        description: data.disputeDescription,
        invoice_ids: invoiceIds,
      };
    }

    if (type === ActivityType.TASK && parentType === ActivityType.ESCALATION) {
      return {
        customer_ids: uniq(castArray(customerIds)),
        associated_activity_id: associatedData?.id,
        associated_activity_type: parentType,
        description: data.escalationDescription,
        invoice_ids: invoiceIds,
      };
    }

    if (type === ActivityType.TASK && parentType === ActivityType.PROMISE_TO_PAY) {
      return {
        customer_ids: uniq(castArray(customerIds)),
        associated_activity_id: associatedData?.id,
        associated_activity_type: parentType,
        description: data.ptpDescription,
        invoice_ids: invoiceIds,
      };
    }
    return {};
  }

  return { onActivityFormTransform, onActivityAssociatedFormTransform };
}
