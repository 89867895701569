import ConditionFilterFieldAndDisplay from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Common/CommonConditionFilter/ConditionFilterFieldAndDisplay';
import { find, head } from 'lodash';
import { DefaultContactAbsoluteOperand } from 'types/entities/invoice-segments/operands';
import { WorkFlowFilterOperator } from '../EventCondition/type';
import DynamicFilterGenerator from '../Filters/CommonFilterHelper/DynamicFilterGenerator/DynamicFilterGenerator';
import { DestructureValueType } from '../Filters/CommonFilterHelper/DynamicFilterGenerator/utils';
import useCollectionOwnerUtil from '../Filters/CommonFilterHelper/hooks/useCollectionOwnerUtils';
interface CollectionOwnerFieldProps {
  type: 'DISPLAY' | 'FILTER';
  value?: WorkFlowFilterOperator<DestructureValueType>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<DestructureValueType>) => void;
  id?: number;
}
export default function CollectionOwnerField(props: CollectionOwnerFieldProps) {
  const { collectionOwnerUsersOption, collectionOwnerLoading, collectionOwnerUsers } =
    useCollectionOwnerUtil();
  const contactValue = props.value?.operands as DefaultContactAbsoluteOperand;
  const initialValue = head(contactValue?.value_holder)?.value;
  const collectionOwnerResult =
    collectionOwnerUsers && find(collectionOwnerUsers, { id: props.id });
  const FilterComponent = (
    <>
      {collectionOwnerUsers && (
        <DynamicFilterGenerator
          value={props.value}
          onChange={props.onChange}
          options={collectionOwnerUsersOption}
          data={collectionOwnerUsers}
          conditionType={'COLLECTION_OWNER'}
          initialValue={initialValue}
        />
      )}
    </>
  );
  const DisplayComponent = (
    <>
      {collectionOwnerResult?.first_name} {collectionOwnerResult?.last_name}
    </>
  );

  return (
    <ConditionFilterFieldAndDisplay
      type={props.type}
      filterCompoenent={FilterComponent}
      displayComponent={DisplayComponent}
      loading={collectionOwnerLoading}
    />
  );
}
