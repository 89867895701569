import { Button } from 'antd';
import styled from 'styled-components';

export const StyledIconButton = styled(Button)`
  &.ant-btn {
    border: 0;
    background: none;
    box-shadow: none;
    &[ant-click-animating-without-extra-node='true']::after,
    .ant-click-animating-node {
      display: none;
      animation: none;
      background: none;
    }
  }
`;
