import { Texto } from 'components/BaseComponents/Typography/Texto';

interface ErrorProps {
  error?: string;
  errorVisible?: boolean;
  showImplicitGapForError?: boolean;
}

export function ErrorMessage(props: ErrorProps) {
  const { errorVisible, showImplicitGapForError, error } = props;

  if (!errorVisible) return null;

  if (showImplicitGapForError) {
    const style: React.CSSProperties = {
      visibility: !errorVisible ? 'hidden' : undefined,
    };

    return (
      <Texto style={style} color="--red-7">
        {error ?? 'dummy-hidden-string'}
      </Texto>
    );
  }

  return <Texto color="--red-7">{error}</Texto>;
}
