import { Input, Select } from 'antd';

import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { DatePicker } from 'components/BaseComponents/AntComponents';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { SingleValuesInput } from 'components/CustomField/NumberCustomFieldFilter/SingleValuesInput';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import dayjs from 'dayjs';
import { chain, map, some } from 'lodash';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { CustomField, CustomFieldUIType } from 'types/entities/custom-field';
import useGetBaseCurrencySymbol from 'util/hooks/useGetBaseCurrencySymbol';
import { actionIcons } from '../CustomAutomations/Action/ActionConfigurations/ActionIcons';
import {
  ActionActivityTypes,
  ActionTypeOption,
  ActionTypesConfig,
  GenerateLabelOption,
  defaultActionableEntityConfig,
} from '../CustomAutomations/Action/types';
import { customFieldSearch } from '../CustomAutomations/Action/utils';
import { SelectOptionIcon, StyledActionOptionFlex } from './style';
import { CustomerValuesSelectProps, DateSelectKeys } from './types';
const t = {
  on: 'On',
  after: 'After',
  select_value: 'Select value',
  value: 'Value',
  value_required: 'Value required',
};

type ActionGroupConfig = {
  section: string;
  options: ActionTypeOption[];
};
// commenting some actions since those will be released in future
export const actionTypesConfig: ActionTypesConfig = {
  GENERAL: {
    label: 'General',
    title: 'General',
    options: [
      {
        id: ActionActivityTypes.EMAIL,
        label: 'Send Email',
        showCheckbox: true,
        ...actionIcons.EMAIL,
      },
      {
        id: ActionActivityTypes.APPROVAL_REQUEST,
        label: 'Send Approval',
        showCheckbox: false,
        ...actionIcons.APPROVAL_REQUEST,
      },
    ],
  },
  OTHERS: {
    label: 'Others',
    title: 'Others',
    options: [
      // {
      //   id: ActionActivityTypes.DISPUTE,
      //   label: 'Raise Dispute',
      //   showCheckbox: false,
      //   entity: ActionableEntity.INVOICE,
      //   ...actionIcons.DISPUTE,
      // },
      {
        id: ActionActivityTypes.ESCALATION,
        label: 'Raise Escalation',
        showCheckbox: true,
        ...actionIcons.ESCALATION,
      },
      // {
      //   id: ActionActivityTypes.PROMISE_TO_PAY,
      //   label: 'Add PTP',
      //   showCheckbox: false,
      //   entity: ActionableEntity.INVOICE,
      //   ...actionIcons.PROMISE_TO_PAY,
      // },
      {
        id: ActionActivityTypes.TASK,
        label: 'Add Task',
        showCheckbox: true,
        ...actionIcons.TASK,
      },
      {
        id: ActionActivityTypes.NOTE,
        label: 'Add Note',
        showCheckbox: true,
        ...actionIcons.NOTE,
      },
      // {
      //   id: ActionActivityTypes.CALL_LOG,
      //   label: 'Add Call log',
      //   showCheckbox: true,
      //   ...actionIcons.CALL_LOG,
      // },
      {
        id: ActionActivityTypes.CUSTOMER_CUSTOM_FIELD,
        label: 'Update Customer Custom Fields',
        showCheckbox: false,
        ...actionIcons.CUSTOMER_CUSTOM_FIELD,
        isCutomField: true,
      },
      {
        id: ActionActivityTypes.INVOICE_CUSTOM_FIELD,
        label: 'Update Invoice Custom Fields',
        entity: ActionableEntity.INVOICE,
        showCheckbox: false,
        ...actionIcons.INVOICE_CUSTOM_FIELD,
        isCutomField: true,
      },
      {
        id: ActionActivityTypes.EDIT_STRATEGY,
        label: 'Update Collection Strategy',
        showCheckbox: false,
        ...actionIcons.EDIT_STRATEGY,
      },
      {
        id: ActionActivityTypes.USER_CATEGORY,
        label: 'Update Collection Owner',
        showCheckbox: false,
        entity: ActionableEntity.CUSTOMER,
        ...actionIcons.USER_CATEGORY,
      },
      {
        id: ActionActivityTypes.EDIT_COLLECTION_STATUS,
        label: 'Update Collection Status',
        showCheckbox: false,
        entity: ActionableEntity.CUSTOMER,
        ...actionIcons.EDIT_COLLECTION_STATUS,
      },
      {
        id: ActionActivityTypes.FLAG_INVOICE,
        label: 'Flag Invoices',
        showCheckbox: false,
        entity: ActionableEntity.INVOICE,
        ...actionIcons.FLAG_INVOICE,
      },
    ],
  },
};

export function getActionGroupConfig(config: ActionTypesConfig): ActionGroupConfig[] {
  return chain(config)
    .map((config) => {
      return {
        section: config.label,
        options: config.options,
      };
    })
    .value();
}

export const defaultActionableEntity: defaultActionableEntityConfig = {
  [ActionActivityTypes.DISPUTE]: {
    invoiceLevel: true,
  },
  [ActionActivityTypes.PROMISE_TO_PAY]: {
    invoiceLevel: true,
  },
  [ActionActivityTypes.USER_CATEGORY]: {
    invoiceLevel: false,
  },
  [ActionActivityTypes.EDIT_COLLECTION_STATUS]: {
    invoiceLevel: false,
  },
  [ActionActivityTypes.FLAG_INVOICE]: {
    invoiceLevel: true,
  },
  [ActionActivityTypes.INVOICE_CUSTOM_FIELD]: {
    invoiceLevel: true,
  },
  [ActionActivityTypes.APPROVAL_REQUEST]: {
    invoiceLevel: true,
  },
};

export function OptionLabel(props: GenerateLabelOption) {
  const { option } = props;
  return (
    <StyledActionOptionFlex align="center" gap="var(--space-8)">
      <SelectOptionIcon
        justify="center"
        align="center"
        style={{
          ...option?.style,
          borderRadius: 'var(--br-2)',
        }}
      >
        {option?.icon}
      </SelectOptionIcon>
      {option?.label}
    </StyledActionOptionFlex>
  );
}

/**
 *
 * @param actionRule
 * @param entityType
 * @returns GeneratesOptionType[]
 *
 * This function generates actions types options to create new actions additionally
 * for the update actions it can be created only once so it is filtered and also
 * based on entity type some options are hidden since some actions can't be performed at invoice
 * level.
 */

export const DateOffsetSelectOptions = [
  {
    label: t.on,
    value: DateSelectKeys.ON,
  },
  {
    label: t.after,
    value: DateSelectKeys.AFTER,
  },
];

/**
 *
 * @param data
 * @returns { label: string, value: string }[]
 *
 * This hook is used for custom field options
 * and getValue retrived the current value based on field id.
 */
export function generateCustomActionOptions(data?: CustomField[]) {
  return chain(data)
    .map((item) => ({
      label: <CustomFieldIconWithText hasSymbol>{item.display_name}</CustomFieldIconWithText>,
      value: item.id,
      title: item.display_name,
    }))
    .value();
}

/**
 *
 * @param props
 * @returns JSX.Element
 *
 * This is used in custom field options to display various based on the ui type.
 */
export function CustomFieldSelect(props: CustomerValuesSelectProps) {
  const { selectedOption, value, form, formKey } = props;
  const format = useSelector(dateFormatSelector);
  const { currencySymbol } = useGetBaseCurrencySymbol();
  const CurrencySymbol = selectedOption?.data_type === 'CURRENCY' ? currencySymbol : undefined;

  switch (selectedOption?.ui_type) {
    case CustomFieldUIType.DROPDOWN: {
      const options = map(selectedOption?.options, (items) => {
        return {
          label: items,
          value: items,
        };
      });
      const isValueDeleted = some(options, (option) => option.value === value);

      return (
        <Select
          showSearch
          filterOption={(input, option) => customFieldSearch(input, option)}
          value={isValueDeleted ? value : undefined}
          options={options}
          placeholder={t.select_value}
          onChange={(value) => {
            form.setFieldValue(formKey, value);
          }}
        />
      );
    }
    case CustomFieldUIType.NONE:
    case CustomFieldUIType.TEXT_BOX: {
      if (selectedOption?.data_type === 'NUMBER') {
        return (
          <SingleValuesInput
            dataType={'NUMBER'}
            defaultValue={parseInt(value as string)}
            handleSingleValueChange={(value) => {
              form.setFieldValue(formKey, value);
            }}
            currencySymbol={CurrencySymbol}
            width="290px"
          />
        );
      }

      return (
        <Input
          value={value}
          prefix={CurrencySymbol}
          onChange={(value) => {
            form.setFieldValue(formKey, value.target.value);
          }}
        />
      );
    }
    case CustomFieldUIType.TEXT_AREA: {
      return (
        <DescriptionTextArea
          value={value}
          onChange={(value) => {
            form.setFieldValue(formKey, value.target.value);
          }}
        />
      );
    }
    case CustomFieldUIType.DATEPICKER: {
      return (
        <DatePicker
          value={value ? dayjs(dayjs(value).format(format)) : null}
          onChange={(_, dateString) => {
            const dateResult = dayjs(dateString as dayjs.ConfigType).format('YYYY-MM-DD');
            const date = dateResult === 'Invalid Date' ? null : dateResult;
            form.setFieldValue(formKey, date);
          }}
          format={format}
          style={{ width: '290px' }}
        />
      );
    }
    default: {
      return null;
    }
  }
}
