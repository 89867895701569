import { CalendarEventType } from './type';

const CalenderEventLabel: Record<CalendarEventType, string> = {
  DAILY: 'Daily',
  DAY_OF_WEEK: 'Weekly',
  DAY_OF_MONTH: 'Monthly',
};

const PeriodicRadioOptions = [
  {
    label: CalenderEventLabel.DAILY,
    value: CalendarEventType.DAILY,
  },
  {
    label: CalenderEventLabel.DAY_OF_WEEK,
    value: CalendarEventType.DAY_OF_WEEK,
  },
  {
    label: CalenderEventLabel.DAY_OF_MONTH,
    value: CalendarEventType.DAY_OF_MONTH,
  },
];

export function generatePeriodicOptions(excludeOptions: CalendarEventType[] = []) {
  return PeriodicRadioOptions.filter((option) => !excludeOptions.includes(option.value));
}
