import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CalendarEventType } from 'components/Common/CalenderEvent/type';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { BaseWorkFlowFilter } from '../EventCondition/type';
import { CalenderValuesSeparator } from './CalenderValuesSeparator';

interface PeriodicDisplayProps {
  periodicType: CalendarEventType;
  condition: BaseWorkFlowFilter;
}

export const PeriodicLabel = {
  [CalendarEventType.DAILY]: 'Daily',
  [CalendarEventType.DAY_OF_MONTH]: 'Every Month:',
  [CalendarEventType.DAY_OF_WEEK]: 'Every Week:',
};
export default function PeriodicDisplay(props: PeriodicDisplayProps) {
  const DayOfWeek = (
    <Flex gap="var(--space-4)" align="center">
      <GrowText color="var(--primary-7)" strong>
        {PeriodicLabel[props.periodicType]}
      </GrowText>
      <CalenderValuesSeparator calenderValue={props.condition} />
    </Flex>
  );
  const DayOfMonth = (
    <Flex gap="var(--space-4)" align="center">
      <GrowText color="var(--primary-7)" strong>
        {PeriodicLabel[props.periodicType]}
      </GrowText>
      <CalenderValuesSeparator calenderValue={props.condition} includeWeekdayNames={false} />
    </Flex>
  );
  const Daily = (
    <GrowText color={'var(--primary-7)'} strong>
      {PeriodicLabel[props.periodicType]}
    </GrowText>
  );
  return (
    <>
      <HideWrapper hide={props.periodicType !== CalendarEventType.DAY_OF_WEEK}>
        {DayOfWeek}
      </HideWrapper>
      <HideWrapper hide={props.periodicType !== CalendarEventType.DAY_OF_MONTH}>
        {DayOfMonth}
      </HideWrapper>
      <HideWrapper hide={props.periodicType !== CalendarEventType.DAILY}>{Daily}</HideWrapper>
    </>
  );
}
