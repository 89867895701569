import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from 'antd';
import { GrowToolTip } from 'components/BaseComponents/Tooltip';
import { GrowText } from 'components/BaseComponents/Typography';
import { CSSProperties } from 'react';

const LabelStyle: CSSProperties = {
  padding: 'var(--space-4) var(--space-8)',
  margin: 'var(--space-0)',
};

export const BetweenFilterTexts = {
  days: 'days',
  allAreZeros: 'Example: If current date is today, the date is set to ',
  hasValueText: 'Example: If current date is today, the date period is between ',
  currentDate: 'Current Date',
  daysOf: 'days of',
  currentDateToolTip: 'The date on which the workflow is run is treated as the current date.',
};

const CurrentDateTag = (
  <Tag color="var(--gray-3)" style={{ padding: 'var(--space-8)', margin: 'var(--space-0)' }}>
    <GrowText color="var(--gray-9)">{BetweenFilterTexts.currentDate}</GrowText>
  </Tag>
);
export const RelativeCurrentDateTag = (
  <GrowToolTip title={BetweenFilterTexts.currentDateToolTip}>{CurrentDateTag}</GrowToolTip>
);
export const InfoIcon = (
  <FontAwesomeIcon
    icon={['far', 'info-circle']}
    color="var(--geekblue-6)"
    fontSize="var(--fs-12)"
    style={{ paddingTop: 'var(--space-2)' }}
  />
);
export const FromLabel = (
  <Tag style={LabelStyle} color="var(--gray-1)">
    <FontAwesomeIcon color="var(--gray-8)" fontSize="var(--fs-14)" icon={['far', 'minus']} />
  </Tag>
);
export const ToLabel = (
  <Tag style={LabelStyle} color="var(--gray-1)">
    <FontAwesomeIcon color="var(--gray-8)" fontSize="var(--fs-14)" icon={['far', 'plus']} />
  </Tag>
);
