import { CSS } from '@stitches/react';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { ActivityPaths } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { ViewMode } from 'components/Inbox/CollectionActivitiesV2/Activities/ToggleActivityReadButton';
import { ActivityType } from 'types/activities/activity-types';
import { CallLog } from 'types/activities/callLog';
import { Commento } from 'types/activities/comment';
import { CreditMemo } from 'types/activities/credit-memo';
import { Dispute } from 'types/activities/dispute';
import { Escalation, EscalationType } from 'types/activities/escalation';
import { Note } from 'types/activities/note';
import { Payment } from 'types/activities/payment';
import { PromiseToPay, PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { Task, TaskStatus } from 'types/activities/task';
import { WriteOff } from 'types/activities/write-off';
import { ActivityDetail, SubscribedEntityType } from 'types/api/inbox/activity';

export type AdditionalActivityCardProps = {
  viewMode?: ViewMode;
  activitiesMode?: boolean;
  activityBg?: CSS;
  onStatusUpdate?: (data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus) => void;
};

export type InboxActivityCardProps<T extends ActivityType> = {
  [key in Lowercase<T>]: ActivityDetail<T>;
} & AdditionalActivityCardProps;

type CollectionActivity =
  | CallLog
  | Dispute
  | Escalation
  | Task
  | WriteOff
  | Note
  | PromiseToPay
  | Payment
  | CreditMemo;

export interface BaseSecondaryActivityDetailProps<T extends CollectionActivity> {
  comments: Commento[];
  activityId: number;
  customerId: number;
  propagateChange: PropagateChange<T>;
}

type PropagateChange<T extends CollectionActivity> = (newValue: Partial<T>) => void;

type ActivityUrlConfig = Record<Exclude<ActivityType, 'DOCUMENT'>, ActivityPaths>;

export const activityUrlConfig: Exclude<ActivityUrlConfig, 'DOCUMENT'> = {
  [ActivityType.EMAIL]: 'email_conversations',
  [ActivityType.CALL_LOG]: 'call-logs',
  [ActivityType.ESCALATION]: 'escalations',
  [ActivityType.DISPUTE]: 'disputes',
  [ActivityType.PROMISE_TO_PAY]: 'promise-to-pay',
  [ActivityType.WRITE_OFF]: 'write-offs',
  [ActivityType.NOTE]: 'notes',
  [ActivityType.TASK]: 'tasks',
  [ActivityType.PAYMENT]: 'payments',
  [ActivityType.CREDIT_MEMO]: 'credit-memos',
};

export interface ActivityCardActionsProps {
  activity?: SubscribedEntityType;
  onStatusUpdate?: (data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus) => void;
  extraActions?: JSX.Element;
  isEmail?: boolean;
}

export interface ActivityEventsPayload {
  loading: boolean;
  id: string | number;
  nextPageLoading?: Boolean;
}

export enum PaymentMode {
  ACH = 'ACH',
  CREDIT_CARD = 'CREDIT_CARD',
  CHECK = 'CHECK',
  CASH = 'CASH',
  OTHER = 'OTHER',
  BANK_TRANSFER = 'BANK_TRANSFER',
  BANK_REMITTANCE = 'BANK_REMITTANCE',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  PAYPAL = 'PAYPAL',
  DEBIT_CARD = 'DEBIT_CARD',
  OFFLINE_PAYMENT = 'OFFLINE_PAYMENT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}
