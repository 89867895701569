import { ColProps, GridColumnProps, SortOrder, getSort } from '@sinecycle/growcomponents';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { useGetCustomerNameFormatter } from 'hooks/useGetCustomerNameFormatter';
import { head } from 'lodash';
import { CreditAdjustmentFilters } from 'store/customer/types';
import { CreditMemo } from 'types/activities/credit-memo';
import { AccountConfigKey } from 'types/entities/account';
import { getCreditMemoStatus } from '.';
import { AmountCell, BaseCurrencyCell, TransActionCurrencyCell } from './Amount';

export function useColumns(filter: CreditAdjustmentFilters) {
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  const isParentChildEnabled = useConfig(AccountConfigKey.PARENT_CHILD_ENABLED);
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();
  const columns: GridColumnProps<CreditMemo> = [
    {
      colId: 'REFERENCE_NO',
      lockPosition: true,
      field: 'reference',
      headerName: 'Reference #',
      sortable: false,
      cellRenderer: 'textCell',
      width: 120,
      pinned: 'left',
      resizable: true,
    },
    {
      colId: 'PAID_BY',
      headerName: 'Paid By',
      sortable: false,
      initialHide: !isParentChildEnabled,
      lockVisible: !isParentChildEnabled,
      cellClass: 'ag-center-aligned-cell',
      headerClass: 'ag-center-aligned-header',
      valueGetter: (param) => {
        const options = {
          customerName: param.data?.customer?.name,
          customerNumber: param.data?.customer?.customer_no,
        };
        return getCustomerNameFormatter(options);
      },
    },
    {
      headerName: 'Reason',
      field: 'note',
      cellRenderer: 'textCell',
      valueGetter: (params) => {
        return params.data?.note?.length ? params.data.note : '-';
      },
    },

    {
      headerName: 'Credit Amount',
      groupId: 'AMOUNT',
      headerClass: 'gf-group-header-center',
      cellRenderer: isMulticurrencyEnabled ? undefined : AmountCell,
      children: [
        {
          colId: 'AMOUNT_CUSTOMER',
          headerName: 'Transaction Currency',
          columnGroupShow: 'closed',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: TransActionCurrencyCell,
          headerClass: 'ag-right-aligned-header',
          hide: !isMulticurrencyEnabled,
          maxWidth: 180,
        },
        {
          colId: 'AMOUNT_BASE',
          headerName: 'Base Currency',
          columnGroupShow: 'closed',
          cellClass: 'ag-right-aligned-cell',
          cellRenderer: BaseCurrencyCell,
          headerClass: 'ag-right-aligned-header',
          maxWidth: 160,
          sortable: true,
          sort: head(getSort(filter.sort_by as SortOrder)) as ColProps<CreditMemo>['sort'],
        },
      ],
    },
    {
      headerName: 'Applied Amount',
      cellRenderer: 'multiCcell',
      field: 'applied_amount_data',
      sortable: false,
      valueGetter: (params) => {
        return { amount: params.data?.applied_amount_data };
      },
    },
    {
      headerName: 'Unapplied Amount',
      cellRenderer: 'multiCcell',
      field: 'unapplied_amount_data',
      sortable: false,
      valueGetter: (params) => {
        return { amount: params.data?.unapplied_amount_data };
      },
    },

    {
      colId: 'SUBSIDIARY',
      lockPosition: false,
      field: 'subsidiary.display_name',
      sortable: false,
      headerName: 'Subsidiary',
      cellRenderer: 'textCell',
      hide: !isSubsidiaryEnabled,
      maxWidth: 160,
    },
    {
      colId: 'INVOICES',
      field: 'invoices',
      cellRenderer: 'invoices',
      headerName: 'Invoices',
      maxWidth: 240,
    },
    {
      colId: 'CREATED_BY',
      lockPosition: false,
      field: 'created_by',
      sortable: false,
      headerName: 'Created by',
      cellRenderer: 'textCell',
      width: 150,
      maxWidth: 160,
      valueGetter: (params) => {
        return `${params.data?.created_by.first_name ?? ''} ${
          params.data?.created_by.last_name ?? ''
        }`;
      },
    },
    {
      colId: 'STATUS',
      field: 'status',
      maxWidth: 160,
      cellRenderer: 'statusCell',
      headerName: 'Status',
      cellRendererParams: {
        statusCellProps: {
          getter: getCreditMemoStatus,
        },
      },
    },
    {
      colId: 'CLOSED_BY',
      lockPosition: false,
      field: 'closed_by',
      sortable: false,
      headerName: 'Closed',
      cellRenderer: 'textCell',
      width: 150,
      resizable: false,
      autoHeight: false,
      valueGetter: (params) => {
        return `${params.data?.closed_by?.first_name ?? ''} ${
          params.data?.closed_by?.last_name ?? ''
        }`;
      },
    },
  ];
  return { columns };
}
