import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WorkFlowFilterOperator } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/EventCondition/type';
import { WorkFlowOperationOptions } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import produce from 'immer';
import { head, isUndefined } from 'lodash';
import { useState } from 'react';
import { RangeItem } from 'types/entities/invoice-segments';
import {
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
  WorkFlowAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import SearchableSelect from '../../../../../Common/SearchableSelect/SearchableSelect';
import { BasicWorkFlowOperatorTypes } from '../../type';
import SingleNumericField from './CommonFilterHelper/SingleNumericField';
import BetweenNumericValues from './NumericFilterHelper/BetweenNumericValues';
import { handleNumericValidation } from './Validation';
const t = { errorMsg: 'Please fill this field' };
interface InvoiceBalanceAmountFilterProps {
  selectedFilter?: WorkFlowEventTypes;
  value?: WorkFlowFilterOperator<number | boolean>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<number | boolean>) => void;
  singleFieldMoreInfo?: string;
  prefix?: string;
  precision?: number;
  singleFieldInputWidth: string;
  betweenText?: string;
}
export type NumericWorkFlowOperatorTypes = Exclude<BasicWorkFlowOperatorTypes, 'CHG'>;
export function NumericFilter(props: InvoiceBalanceAmountFilterProps) {
  const { value: numericFilterValue, onChange: numericFilterOnchange } = props;
  const operatorOptions = optionGenerator();
  const absOperand = numericFilterValue?.operands as DefaultAbsoluteOperand<unknown>;
  const InOperands = numericFilterValue?.operands as InAbsoluteOperands<number>;
  const currentType = numericFilterValue?.type;
  const eqValue = head(InOperands?.value_holder)?.value;
  const singleValue = absOperand?.value_holder?.value as number;
  const finalValue = singleValue ?? eqValue;

  const [validatedValue, setValidatedValue] = useState<RangeItem>({
    value: finalValue,
    errorMsg: isUndefined(finalValue) ? t.errorMsg : '',
    validateStatus: isUndefined(finalValue) ? 'error' : 'success',
  });

  function handleOperatorChange(value: string) {
    const operatorValue = value as NumericWorkFlowOperatorTypes;
    const updatedFilter = produce(numericFilterValue, (draftFilter) => {
      if (!draftFilter?.type) {
        return;
      }
      const absOperand = draftFilter.operands as WorkFlowAbsoluteOperands<number | boolean | null>;
      draftFilter.type = operatorValue;
      absOperand.value_holder =
        value === 'BTWN'
          ? {
              from: { value: 1 },
              to: { value: 2 },
            }
          : value === 'IN'
          ? [{ value: validatedValue.value }]
          : value === 'ANY'
          ? { value: true }
          : { value: validatedValue.value };
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  function optionGenerator() {
    if (props.selectedFilter === 'OUTSTANDING_VALUE') {
      return [...WorkFlowOperationOptions, { label: 'Any Change', value: 'ANY' }];
    }
    return WorkFlowOperationOptions;
  }
  function handleValueChange(value: number | null) {
    const validationStatus = handleNumericValidation(value, Boolean(props.precision));
    const updatedFilter =
      numericFilterValue &&
      produce(numericFilterValue, (draftFilter) => {
        if (draftFilter) {
          const absOperand = draftFilter.operands as
            | InAbsoluteOperands<number | boolean | null>
            | DefaultAbsoluteOperand<number | boolean | null>;
          absOperand.value_holder = draftFilter.type === 'IN' ? [{ value }] : { value };
        }
      });
    setValidatedValue((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        value,
        errorMsg: validationStatus.validateStatus === 'success' ? null : validationStatus.errorMsg,
        validateStatus: validationStatus.validateStatus === 'success' ? 'success' : 'error',
      };
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <SearchableSelect
      value={props?.value?.type ?? 'GOE'}
      options={operatorOptions}
      onChange={handleOperatorChange}
    />
  );

  const SingleNumbericField = (
    <StyledFormItem
      shouldUpdate
      label={props.singleFieldMoreInfo}
      validateStatus={validatedValue?.validateStatus}
      help={validatedValue?.errorMsg}
      className={props.singleFieldMoreInfo ? 'label-space' : ''}
      labelCol={{ style: { order: 2 } }}
    >
      <SingleNumericField
        onChange={handleValueChange}
        value={validatedValue.value}
        percision={props.precision}
        prefix={props.prefix}
        style={{ width: props.singleFieldInputWidth }}
      />
    </StyledFormItem>
  );

  return (
    <Flex gap="--space-8" align="flex-start">
      {Operators}
      <HideWrapper hide={currentType !== 'BTWN'}>
        <BetweenNumericValues
          value={props?.value as WorkFlowFilterOperator<number>}
          onChange={numericFilterOnchange}
          prefix={props.prefix}
          precision={props.precision ?? 0}
          singleFieldInputWidth={props.singleFieldInputWidth}
          betweenText={props.betweenText}
        />
      </HideWrapper>
      <HideWrapper hide={currentType === 'BTWN' || currentType === 'ANY'}>
        {SingleNumbericField}
      </HideWrapper>
    </Flex>
  );
}
