import { CalendarEventType } from 'components/Common/CalenderEvent/type';
import produce from 'immer';
import { EventType, WorkFlowRule } from '../../type';
import {
  BaseWorkFlowFilter,
  WorkFlowBaseCalenderFilters,
  WorkFlowFilterOperator,
} from '../EventCondition/type';

export interface CustomConditionConstructor {
  id?: number;
  type: number;
  operator: WorkFlowFilterOperator<unknown>;
  mandatory?: boolean;
}

export const dateFilters = ['ISSUE_DATE', 'INVOICE_DUE_DATE'];

export function findOptionalConditions(triggerData: WorkFlowRule) {
  return triggerData?.condition?.conditions?.filter((condition) => {
    const eventCondition = condition as BaseWorkFlowFilter;
    const calenderCondition = condition as WorkFlowBaseCalenderFilters;
    return !(
      (eventCondition && Boolean(eventCondition?.mandatory)) ||
      (calenderCondition && Object.values(CalendarEventType)?.includes(calenderCondition.type))
    );
  });
}

export function generateOptionalConditions(conditions: BaseWorkFlowFilter[]) {
  const modifyCustomConditions = conditions?.map((condition) => {
    if (condition?.id) {
      const modifyCustomCondition =
        condition &&
        produce(condition, (draft: CustomConditionConstructor) => {
          if (draft.id) {
            draft.type = draft.id;
          }
        });
      return modifyCustomCondition;
    } else return condition;
  });
  return modifyCustomConditions;
}
export function findMandatoryConditionsByEntityType(
  entityType?: EventType,
  triggerData?: WorkFlowRule
) {
  return triggerData?.condition?.conditions?.find((condition) => {
    if (triggerData?.trigger_type === 'LIVE') {
      const eventCondition = condition as BaseWorkFlowFilter;
      return eventCondition && Boolean(eventCondition?.mandatory);
    } else if (entityType === 'PERIODIC') {
      const calenderCondition = condition as WorkFlowBaseCalenderFilters;
      return (
        calenderCondition && Object.values(CalendarEventType)?.includes(calenderCondition?.type)
      );
    }
    return undefined;
  });
}
