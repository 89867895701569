import { Descriptions } from 'antd';
import SelectComponent from 'components/BaseComponents/ASelect';
import { GrowCard } from 'components/BaseComponents/Card';
import { GrowRow } from 'components/BaseComponents/GrowGrid';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Style } from 'lib/style/style';
import styled from 'styled-components';

export const ActivityContent = Style(Descriptions, {
  '.ant-descriptions-view': {
    '.ant-descriptions-item': {
      paddingBottom: 'var(--space-4) !important',
    },
    'td.ant-descriptions-item': {
      paddingBottom: '0 !important',
    },
  },

  '.ant-descriptions-item-content': {
    lineHeight: '1.2',
  },
  '&.snapshot': {
    '.ant-descriptions-item-content': {
      paddingBottom: 'var(--space-12) !important',
    },
    '.ant-descriptions-row:last-child': {
      '.ant-descriptions-item-content': {
        paddingBottom: '0 !important',
      },
    },
  },

  variants: {
    info: {
      true: {
        '.ant-descriptions-view': {
          'td.ant-descriptions-item': {
            paddingBottom: '0 !important',
          },
        },
      },
    },
    info2: {
      true: {
        '.ant-descriptions-view': {
          'td.ant-descriptions-item': {
            paddingTop: 'var(--space-24) !important',
            paddingBottom: '0 !important',
          },
        },
      },
    },
  },
});
interface ActivityCardProps {
  isActive?: boolean;
}

export const ActivityCardContainer = styled.div<ActivityCardProps>`
  border-radius: var(--br-1);
  margin-bottom: var(--space-16);
  border: 1px solid ${(props) => (props.isActive ? 'var(--primary-6)' : 'var(--gray-3)')};
  min-height: 100px;
  background: var(--gray-1);

  &:hover {
    border: 1px solid ${(props) => (props.isActive ? 'var(--primary-6)' : 'var(--primary-3)')};
  }
`;

export const DetailsLayoutContainer = Style(GrowRow, {
  height: '100%',
  variants: {
    mode: {
      inbox: {
        boxShadow: 'var(--shadow-2)',
      },
      approvals: {
        boxShadow: 'var(--shadow-2)',
      },
    },
  },
});

export const ActivityCardDetails = Style(GrowCard, {
  border: 0,
  height: '100%',
  overflow: 'auto',

  variants: {
    mode: {
      inbox: {
        borderRadius: '8px 0px 0px 8px',
      },
      approvals: {
        borderRadius: '0px 0px 0px 8px',
      },
    },
  },
});
export const ActivityCardInfo = Style(GrowCard, {
  border: 0,
  borderLeft: '1px solid var(--gray-5)',
  background: 'var(--gray-2)',
  height: '100%',
  overflow: 'auto',

  variants: {
    mode: {
      inbox: {
        borderRadius: '0px 8px 8px 0px',
      },
      approvals: {
        borderRadius: '0px 0px 8px 8px',
      },
    },
  },
});

export const ActivityTitle = Style(Flex);

export const ActivityLoader = styled.div`
  width: 1170px;
  height: 650px;
`;

export const StyledDisputeStatus = Style(SelectComponent, {
  '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: '1px solid var(--gray-6) !important',
  },

  '.ant-select-arrow': {
    color: 'var(--primary-7)',
  },

  '&:hover': {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid var(--primary-7) !important',
    },
  },

  variants: {
    status: {
      OPEN: {
        '.ant-select-selection-item': {
          color: 'var(--red-7) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--red-1) !important',
        },
      },
      CLOSED: {
        '.ant-select-selection-item': {
          color: 'var(--green-8) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--green-1) !important',
        },
      },
      WAITING_ON_CUSTOMER: {
        '.ant-select-selection-item': {
          color: 'var(--orange-8) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--orange-1) !important',
        },
      },
    },
  },
});

export const StyledPTPStatus = Style(SelectComponent, {
  '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: '1px solid var(--gray-6) !important',
  },

  '.ant-select-arrow': {
    color: 'var(--primary-7)',
  },

  '&:hover': {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: '1px solid var(--primary-7) !important',
    },
  },

  variants: {
    status: {
      OPEN: {
        '.ant-select-selection-item': {
          color: 'var(--red-7) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--red-1) !important',
        },
      },
      CLOSED: {
        '.ant-select-selection-item': {
          color: 'var(--green-8) !important',
        },

        '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
          background: 'var(--green-1) !important',
        },
      },
    },
  },
});

/**  Activites Details View Style end */
