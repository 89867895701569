import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { useCustomerCustomFieldQuery, useInvoiceCustomFieldQuery } from 'queries/custom-field';
import { CustomFieldPage } from 'types/entities/custom-field';
import { InvoiceSegmentOperandType } from 'types/entities/invoice-segments/operands';
import { WorkFlowStatusesFilters } from '../../EventCondition/type';

export function useGetCustomFieldHooks(entity: EVENT_TRIGGER_CONDITION_KEYS) {
  const enabled = entity === 'INVOICE' || entity === 'CUSTOMER';
  const { data: customerCustomField, isLoading: customerCustomFieldLoading } =
    useCustomerCustomFieldQuery(CustomFieldPage.WORKFLOW, enabled);

  const { data: invoiceCustomField, isLoading: invoiceCustomFieldLoading } =
    useInvoiceCustomFieldQuery(CustomFieldPage.WORKFLOW, undefined, undefined, enabled);
  const customFields = customerCustomField &&
    invoiceCustomField && [...invoiceCustomField, ...customerCustomField];
  return {
    customerCustomField,
    customerCustomFieldLoading,
    invoiceCustomField,
    invoiceCustomFieldLoading,
    customFields,
  };
}

export function getCustomFieldInitialValue() {
  const defaultCustomFielddropdownTypeValue: WorkFlowStatusesFilters = {
    operator: {
      type: 'IN',
      operands: {
        type: InvoiceSegmentOperandType.ABSOLUTE,
        value_holder: [],
      },
    },
    mandatory: false,
  };

  return defaultCustomFielddropdownTypeValue;
}
