import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import styled from 'styled-components';

interface SideBarTitleProps {
  label: string;
  icon?: React.ReactNode;
}

const StyledDiv = styled.div`
  svg {
    width: 13px;
    height: 14px;
    fill: currentColor;
    color: currentColor;
  }
`;

function SideBarTitle(props: Readonly<SideBarTitleProps>) {
  return (
    <Flex align="center" gap="var(--space-12)">
      {props.icon && <StyledDiv>{props.icon}</StyledDiv>}
      <Texto size="14" color="currentColor" className="anchor-text">
        {props.label}
      </Texto>
    </Flex>
  );
}

export default SideBarTitle;
