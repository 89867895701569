import { StyledRadioGroup } from 'components/BaseComponents/Buttons/Switcher';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { EventType } from '../../../type';
import { WorkFlowTexts } from '../../text';
import { TriggerEntityOptions } from 'components/Settings/WorkFlowAutomation/common/Predicate';

export function TriggerEntityFormField(props: { entityValue?: EventType }) {
  return (
    <StyledFormItem
      name="trigger_type"
      hidden
      rules={[
        {
          required: true,
          message: WorkFlowTexts.entityTypeError,
        },
      ]}
      initialValue={props.entityValue ?? 'LIVE'}
    >
      <StyledRadioGroup options={TriggerEntityOptions} optionType="button" />
    </StyledFormItem>
  );
}
