import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchComponent from '../Common/SearchComponent';
import SkeletonLoader from '../Common/SkeletonLoader';

import { concat, get, head, nth, uniqBy } from 'lodash';
import { FilterDropdown } from '../FilterDropdown';
import { MultiCheckBoxPagination } from '../Filters/MultiSelectCheckbox';
import { FilterConfig, FilterType, SelectOption } from '../types';
import { useGenerateOptionsWithSearch } from './hooks/useGenerateOptionsWithSearch';
import { FilterConfigWrapperProps } from './type';

interface BaseFilterBlockProps<T, M> {
  value?: T;
  onSubmit?: (value: T, valueObj?: M) => void;
  onClear?: () => void;
  showClear?: boolean;
  onCancel?: () => void;
}

type SearchOptions<T> = {
  [P in keyof T]: T[P];
};
type onSearchOptions<M> =
  | SelectOption[]
  | {
      value: SearchOptions<M>[keyof M];
      label: string | SearchOptions<M>[keyof M];
      valueObj: SearchOptions<M>;
    }[];
type attributeKeys<F> = keyof F;

interface SearchCharLimit {
  enabled: boolean;
  limit: number;
}
interface MultiSelectFilterBlockProps<T, M> extends BaseFilterBlockProps<T, M> {
  filterConfig: FilterConfigWrapperProps;
  placeholder?: string;
  onSearch?: (value: string) => Promise<SearchOptions<M>[]>;
  onPageChange?: (page: number, text?: string) => Promise<SearchOptions<M>[]>;
  valueAttribute?: attributeKeys<M>;

  labelAttribute?: attributeKeys<M> | attributeKeys<M>[];
  style?: React.CSSProperties;
  shouldDebounce?: boolean;
  searchCharLimit?: SearchCharLimit;
  emptyText?: string;
  optionsPredicate?: (option: SearchOptions<M>) => SelectOption;
  hide?: boolean;
  onSearchDataTransform?: (contacts: SearchOptions<M>[] | undefined) => {
    value: number;
    label: string;
  }[];
  onSearchCallback?: (value: string) => void;
  pagination?: MultiCheckBoxPagination;
  paginationLoading?: boolean;
  paginationkeys?: {
    pagination: attributeKeys<M>;
    record: attributeKeys<M>;
  };
}
type ArrayKey = number[] | string[];

export const generalSearchLimitConfig = {
  limit: 1,
  enabled: false,
};
function getDefaultOptionsPredicate<M>(
  valueAttribute: keyof M,
  labelAttribute: keyof M | (keyof M)[]
): (option: SearchOptions<M>) => SelectOption {
  return (option: SearchOptions<M>) => {
    return {
      value: option[valueAttribute],
      label: Array.isArray(labelAttribute)
        ? `${option[head(labelAttribute) as keyof M]} ${
            option[nth(labelAttribute, 1) as keyof M] ?? ''
          }`
        : option[labelAttribute],
      valueObj: option,
    };
  };
}

export function MultiSelectCheckboxWrapperWithSearch<M, T extends ArrayKey>(
  props: MultiSelectFilterBlockProps<T, M>
) {
  const {
    filterConfig,
    onClear,
    onSubmit,
    value,
    showClear = true,
    placeholder,
    onSearch,
    shouldDebounce,
    labelAttribute = 'label',
    valueAttribute = 'value',
    style,
    emptyText,
    onSearchCallback,
    searchCharLimit: seacrhCharLimit,
    pagination,
    onPageChange,
    paginationkeys,
  } = props;
  const [
    computedOptions,
    setComputedOptions,
    handleResetToDefaultOptions,
    updateComputedOptions,
    updateOptionsWithSelectedValue,
    updateOptionsWithSelectedValueNonSearch,
  ] = useGenerateOptionsWithSearch(filterConfig.options, filterConfig.shouldIncludeUnassigned);

  const [shouldReset, setShouldReset] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number | undefined>(pagination?.currentPage ?? 1);
  const [totalPages, setTotalPages] = useState<number | undefined>(pagination?.totalPages);
  const [totalRecords, setTotalRecords] = useState<number | undefined>();
  const [keyWord, setKeyWord] = useState<string>();
  const [selectedValues, setSelectedValues] = useState<T>();
  const [paginationLoading, setpaginationLoading] = useState(false);

  function processData(
    data: SearchOptions<M>[],
    defaultOptionsPredicate: (option: SearchOptions<M>) => SelectOption,
    onLoading?: () => void,
    isConcat?: boolean,
    nonSearch?: boolean
  ) {
    let options: onSearchOptions<M>;
    if (props?.onSearchDataTransform && data.length) {
      options = props?.onSearchDataTransform(data);
    } else {
      options = props?.optionsPredicate
        ? data.map(props.optionsPredicate)
        : data.map(defaultOptionsPredicate);
    }
    onLoading?.();

    const modifiedOptions = isConcat ? uniqBy(concat(computedOptions, options), 'value') : options;

    if (selectedValues?.length) {
      if (nonSearch) {
        updateOptionsWithSelectedValueNonSearch(selectedValues, modifiedOptions);
      } else {
        updateOptionsWithSelectedValue(selectedValues, modifiedOptions);
      }
    } else {
      setComputedOptions(modifiedOptions);
    }
  }

  const onProcessData = useCallback(processData, [
    computedOptions,
    props,
    selectedValues,
    setComputedOptions,
    updateOptionsWithSelectedValue,
    updateOptionsWithSelectedValueNonSearch,
  ]);
  function handleSubmit(value: T) {
    onSubmit && onSubmit(value);
    setShouldReset(true);
    updateComputedOptions(value);
    setLoading(false);
    setCurrentPage(1);
    setTotalPages(undefined);
    setTotalRecords(undefined);
    setKeyWord(undefined);
  }
  function handleClear() {
    onClear && onClear();
    onSubmit && onSubmit([] as unknown as T);
    setShouldReset(true);
    setLoading(false);
    setCurrentPage(1);
    setTotalPages(pagination?.totalPages);
    setTotalRecords(undefined);
    setKeyWord(undefined);
  }

  function handleCancel() {
    props.onCancel?.();
    if (value) {
      updateComputedOptions(value);
    } else {
      handleResetToDefaultOptions();
      setSelectedValues(undefined);
    }

    setShouldReset(true);
    setLoading(false);
    setCurrentPage(1);
    setTotalPages(pagination?.totalPages);
    setTotalRecords(undefined);
    setKeyWord(undefined);
  }

  function handleClearSearch() {
    if (selectedValues) {
      updateComputedOptions(selectedValues);
    } else {
      handleResetToDefaultOptions();
    }

    setShouldReset(true);
    setLoading(false);
    setKeyWord(undefined);
    setCurrentPage(1);
    setTotalPages(pagination?.totalPages);
    setTotalRecords(undefined);
  }
  const handleOnClear = useCallback(handleClearSearch, [
    selectedValues,
    pagination?.totalPages,
    updateComputedOptions,
    handleResetToDefaultOptions,
  ]);

  function handleDropdownVisibleChange(visible: boolean) {
    if (visible) {
      setShouldReset(false);
      setTotalPages(pagination?.totalPages);
      setCurrentPage(pagination?.currentPage ?? 1);
    } else {
      handleCancel();
      setTotalRecords(undefined);
      setCurrentPage(undefined);
    }
  }

  const handleDebouncedSearch = useCallback(handleSearch, [
    labelAttribute,
    onProcessData,
    onSearch,
    paginationkeys?.pagination,
    paginationkeys?.record,
    valueAttribute,
  ]);

  function handleSearch(keyword: string) {
    setLoading(true);
    setCurrentPage(1);
    const defaultOptionsPredicate = getDefaultOptionsPredicate(
      valueAttribute as keyof M,
      labelAttribute as keyof M
    );

    if (onSearch) {
      onSearch(keyword)
        .then((data) => {
          const total = get(head(data), paginationkeys?.pagination as keyof M) as number;
          const record = get(head(data), paginationkeys?.record as keyof M) as number;
          setTotalPages(total);
          setTotalRecords(record);
          onProcessData(data, defaultOptionsPredicate, () => {
            setLoading(false);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function handleSearchCallBack(search: string) {
    if (onSearchCallback) {
      onSearchCallback(search);
    } else if (search.trim() === '') {
      if (selectedValues?.length) {
        updateComputedOptions(selectedValues);
      } else {
        handleResetToDefaultOptions();
      }
    } else {
      handleDebouncedSearch(search);
    }
  }

  const searchCallBack = useCallback(handleSearchCallBack, [
    handleDebouncedSearch,
    handleResetToDefaultOptions,
    onSearchCallback,
    selectedValues,
    updateComputedOptions,
  ]);

  function search(keyword: string) {
    const isKeywordWithinLimit =
      !seacrhCharLimit?.enabled || keyword?.trim()?.length >= seacrhCharLimit?.limit;
    setKeyWord(keyword);
    if (isKeywordWithinLimit) {
      searchCallBack(keyword);
    } else if (!keyword.length) {
      searchCallBack(keyword);
    }
  }
  const onSearchBefore = useCallback(search, [
    seacrhCharLimit?.enabled,
    seacrhCharLimit?.limit,
    searchCallBack,
  ]);
  function handleSearchDropdownConfigs() {
    const SearchComp = (
      <SearchComponent
        placeholder={placeholder}
        searchCallBack={onSearchBefore}
        style={style}
        shouldReset={shouldReset}
        shouldDebounce={shouldDebounce}
        onClearCallBack={handleOnClear}
        allowClear
        charLimt={{
          limit: seacrhCharLimit?.limit,
        }}
        contentOnly={props.filterConfig.contentOnly}
        totalRecords={totalRecords}
      />
    );

    return {
      enableSearch: true,
      searchComponent: SearchComp,
      loading,
      skeletonLoader: <SkeletonLoader />,
      emptyText,
      keyWord,
    };
  }

  const searchDropdownConfigs = useMemo(handleSearchDropdownConfigs, [
    placeholder,
    onSearchBefore,
    style,
    shouldReset,
    shouldDebounce,
    handleOnClear,
    seacrhCharLimit?.limit,
    props.filterConfig.contentOnly,
    totalRecords,
    loading,
    emptyText,
    keyWord,
  ]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  function handlePagination() {
    const value = currentPage ? currentPage + 1 : 1;
    setCurrentPage(value);
    setpaginationLoading(true);

    const defaultOptionsPredicate = getDefaultOptionsPredicate(
      valueAttribute as keyof M,
      labelAttribute as keyof M
    );

    onPageChange?.(value, keyWord)
      .then((data) => {
        onProcessData(
          data,
          defaultOptionsPredicate,
          () => {
            setpaginationLoading(false);
          },
          true,
          true
        );
      })
      .catch(() => {});
  }
  const onPagination = useCallback(handlePagination, [
    currentPage,
    keyWord,
    labelAttribute,
    onPageChange,
    onProcessData,
    valueAttribute,
  ]);

  function getConfig(): FilterConfig {
    return {
      ...filterConfig,
      options: computedOptions,
      type: FilterType.MULTI_SELECT_CHECKBOX,
      pagination: {
        hasPagination: Boolean(pagination),
        onPagination,
        currentPage,
        totalPages,
        loading: paginationLoading,
      },

      valueToString(value) {
        if (!value || !value.length) return 'None';

        const getStringOfValueLabels = () =>
          (computedOptions ?? [])
            .filter((option) => value.includes(option.value))
            .map((option) => option.label)
            .join(', ');

        if (keyWord) return getStringOfValueLabels();
        return value.length === computedOptions.length ? 'All' : getStringOfValueLabels();
      },
    };
  }

  const filterConfigs = useMemo(getConfig, [
    computedOptions,
    currentPage,
    filterConfig,
    keyWord,
    onPagination,
    pagination,
    paginationLoading,
    totalPages,
  ]);

  if (props.hide) return null;

  return (
    <FilterDropdown
      value={selectedValues}
      filterConfig={filterConfigs}
      onSubmit={handleSubmit}
      onClear={handleClear}
      showClear={showClear}
      onCancel={handleCancel}
      search={searchDropdownConfigs}
      onDropdownVisibilityChange={handleDropdownVisibleChange}
      trigger={['click']}
      onChange={(value) => {
        setSelectedValues(value);
      }}
    />
  );
}
