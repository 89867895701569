import { QueryKey } from '@tanstack/react-query';
import { ConversationBody } from 'components/Inbox/EmailConversations/Conversations/Body/ConversationBody/ConversationBody';
import { EmailThreadResponse } from 'types/api/inbox/email';

interface EmailDetailsLayoutProps {
  data?: EmailThreadResponse;
  isReadOnly?: boolean;
  queryKey?: QueryKey;
  loading?: boolean;
}

function EmailDetailsLayout(props: EmailDetailsLayoutProps) {
  const { data, isReadOnly, loading, queryKey } = props;
  return (
    <ConversationBody
      data={data}
      loader={loading}
      hideCustomerDetails
      mode={'activities'}
      isDetails
      readOnly={isReadOnly}
      queryKey={queryKey}
    />
  );
}

export { EmailDetailsLayout };
