import { Tag } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { ERolesAndPrivileges } from 'components/HigherOrderComponent/Privileged/privileges';
import { useSelector } from 'react-redux';
import { userPrivilegesSelector } from 'store/authentication/authentication';
import styled from 'styled-components';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { getConditionStatusLabel } from '../Helper';
import { getEventTypeGroup, workFlowStatusConfig } from '../common/WorkFlowStatusPredicate';

const t = {
  stats: 'Stats',
};

const StyledTag = styled(Tag)`
  width: fit-content;
  padding: var(--space-4) var(--space-8);
  border-radius: var(--br-2);
`;

// export enum StatsConfig {
//for future use commenting now
// UNASSIGNED = 'Unassigned',
// MANUALLY_ASSIGNED = 'Manually Assigned',
// EVENT_TRIGGER = 'Event trigger',
// ACTIONS_ATTEMPTED = 'Actions attempted',
// ACTIONS_COMPLETED = 'Actions completed',}
const Stats = (
  <GrowText color="var(--gray-9)" strong size="var(--fs-14)">
    {t.stats}
  </GrowText>
);

export function generateDefaultStats() {
  //for future use
  return (
    <Flex direction="column" gap="var(--space-8)">
      <Flex direction="column" gap="6px">
        {Stats}
        <Flex justify="space-between" style={{ width: '165px' }}>
          {/* <GrowText color="var(--gray-8)">{StatsConfig.UNASSIGNED}</GrowText> */}
          <GrowText color="var(--gray-9)">32</GrowText>
        </Flex>
        <Flex justify="space-between" style={{ width: '165px' }}>
          {/* <GrowText color="var(--gray-8)">{StatsConfig.MANUALLY_ASSIGNED}</GrowText> */}
          <GrowText color="var(--gray-9)">12</GrowText>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function WorkflowStatusDisplay(eventType?: WorkFlowEventTypes) {
  if (!eventType) return;
  const group = getEventTypeGroup(eventType);
  const EntityTypeLabel = getConditionStatusLabel(eventType);

  if (!group) return;

  const { icon, style } = workFlowStatusConfig[group];

  return (
    <StyledTag color={style.backgroundColor} style={{ margin: '0' }}>
      <Flex gap="var(--space-4)" align="center">
        <GrowText size="var(--fs-12)">{icon}</GrowText>
        <GrowText color={style.color} size="var(--fs-12)">
          {EntityTypeLabel}
        </GrowText>
      </Flex>
    </StyledTag>
  );
}

export function useWorkflowOwner() {
  const privilegesList = useSelector(userPrivilegesSelector);

  const isWorkflowOwner =
    privilegesList?.includes(ERolesAndPrivileges.WORKFLOW_OWNER) &&
    !privilegesList?.includes(ERolesAndPrivileges.CREATE_MANAGE_WORKFLOW);
  return { isWorkflowOwner };
}
