import { Row } from 'antd';
import styled from 'styled-components';

export const SEditPrivileges = styled.div`
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-1);
  border-radius: var(--br-1);

  .privilege-group.checkbox-group {
    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        display: flex;
        margin-bottom: var(--space-16);
        align-items: center;
      }
    }
  }
`;

export const PrivilegesBody = styled(Row)`
  background: var(--gray-2);
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-content-holder {
    display: none;
  }

  .ant-tabs-tab-active {
    border: 0;
    background: var(--blue-6);

    .ant-tabs-tab-btn {
      color: var(--gray-1);
    }
  }
`;
