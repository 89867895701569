import { Divider, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EPages } from 'components/Settings/RolesAndPermissions/roles-config';
import { difference, isEqual } from 'lodash';
import { useContext, useState } from 'react';

import { GrowCheckBox, GrowCheckBoxGroup } from '@sinecycle/growcomponents';
import { CheckboxValueType } from 'components/BaseComponents/Checkbox/Antd/Antd';
import { IPrivileges } from 'store/privilges/type';
import { rolesAndPrivilegesContext } from '../../Context';
import Option from './Options';

interface PrivilegesOptionsProps {
  privilegesList: IPrivileges[];
  checkAllLabel: React.ReactNode;
  page: EPages;
}

const getListIdPredicate = (list: IPrivileges) => list.id;
const filterEnablePredicate = (list: IPrivileges) => list.enabled;
const getConfigurablePredicate = (list: IPrivileges) => list.configurable;

const filterNotEnablePredicate = (list: IPrivileges) => !list.enabled;
function PrivilegesOptions(props: Readonly<PrivilegesOptionsProps>) {
  const { checkAllLabel, privilegesList, page } = props;
  const [indeterminate, setIndeterminate] = useState(handleIntermediateState);
  const [checkAll, setCheckAll] = useState(handleCheckAllState);
  const [defaultCheckedList] = useState<CheckboxValueType[]>(handleDefaultCheckedList);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(handleCheckList);
  const isGroupConfigurable = Boolean(privilegesList.filter(getConfigurablePredicate).length);
  const context = useContext(rolesAndPrivilegesContext);
  function handleDefaultCheckedList() {
    return privilegesList.map(getListIdPredicate);
  }
  function handleCheckList() {
    return privilegesList.filter(filterEnablePredicate).map(getListIdPredicate);
  }
  function handleIntermediateState() {
    const defaultList = privilegesList.map(getListIdPredicate);
    const defaultChecked = privilegesList.filter(filterEnablePredicate).map(getListIdPredicate);

    return !isEqual(defaultList, defaultChecked);
  }
  function handleCheckAllState() {
    const defaultList = privilegesList.map(getListIdPredicate);
    const defaultChecked = privilegesList.filter(filterEnablePredicate).map(getListIdPredicate);

    return isEqual(defaultList, defaultChecked);
  }

  const groupCheckBoxIntermediateState = isGroupConfigurable
    ? indeterminate
    : !defaultCheckedList.length;

  const groupCheckBoxCheckedState = isGroupConfigurable
    ? checkAll
    : Boolean(defaultCheckedList.length);

  const configurablePrivilegeList = privilegesList
    .filter((list) => list.enabled)
    .filter((enabledList) => !enabledList.configurable)
    .map((configurableList) => configurableList.id);

  const notEnabledList = privilegesList.filter(filterNotEnablePredicate).map(getListIdPredicate);

  const CheckBoxOptions = (
    <>
      {privilegesList.map((privilege) => {
        return (
          <Option
            key={privilege.id}
            description={privilege.description}
            displayName={privilege.display_name}
            configurable={privilege.configurable}
            id={privilege.id}
            onChange={(checked) => handleCheckBoxOptionsChange(checked, privilege.id)}
          />
        );
      })}
    </>
  );

  function handleCheckBoxOptionsChange(checked: boolean, id: number) {
    context?.updatePrivileges({ type: 'UPDATE', value: [{ id, enabled: checked }], page });
    if (checked) {
      setCheckedList((prevState) => [...prevState, id]);

      return;
    }
    setCheckAll(false);
    const modifiedCheckedList = checkedList.filter((listId) => listId !== id);
    setCheckedList(modifiedCheckedList);
  }

  function handleCheckAllChange(e: CheckboxChangeEvent) {
    setCheckAll(e.target.checked);
    setIndeterminate(false);
    handleCheckAllDispatch(e.target.checked);
    if (e.target.checked) {
      if (defaultCheckedList.length) {
        setCheckedList(defaultCheckedList);
      } else {
        setCheckedList(notEnabledList);
      }
    } else {
      setCheckedList(configurablePrivilegeList);
    }
  }

  function handleCheckBoxGroupChange(list: CheckboxValueType[]) {
    setIndeterminate(!!list.length && list.length < defaultCheckedList.length);
    setCheckAll(list.length === defaultCheckedList.length);
  }

  function handleCheckAllDispatch(checked: boolean) {
    if (defaultCheckedList.length) {
      const selectedCheckedList = difference(
        defaultCheckedList,
        configurablePrivilegeList
      ) as number[];
      const modified = selectedCheckedList.map((id) => {
        return { id, enabled: checked };
      });
      context?.updatePrivileges({ type: 'UPDATE', value: modified, page });
    }
  }

  return (
    <>
      <GrowCheckBox
        onChange={handleCheckAllChange}
        indeterminate={groupCheckBoxIntermediateState}
        checked={groupCheckBoxCheckedState}
        disabled={!isGroupConfigurable}
        defaultChecked={!isGroupConfigurable}
        design="border-design"
        style={{ width: '100%', display: 'flex', alignItems: 'center' }}
      >
        {checkAllLabel}
      </GrowCheckBox>
      <Divider />
      <GrowCheckBoxGroup
        design="border-design"
        value={checkedList}
        onChange={(value) => {
          handleCheckBoxGroupChange(value as CheckboxValueType[]);
        }}
        style={{ width: '100%' }}
      >
        <Row gutter={[16, 16]} justify="space-between" style={{ width: '100%' }}>
          {CheckBoxOptions}
        </Row>
      </GrowCheckBoxGroup>
    </>
  );
}

export default PrivilegesOptions;
