import { GrowTypography } from '@sinecycle/growcomponents';
import { Select, SelectProps, Tag } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import UserInfoAvatar from 'components/HigherOrderComponent/UserInfoAvatar';
import { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { usersSelector } from 'store/user/user';
import styled from 'styled-components';
import { User } from 'types/entities/user';
import { CustomTagProps } from '../ActivitiesForm/types';
import { tagRender } from 'components/BaseComponents/ASelect/tag-render';

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    height: 28px !important;
  }
`;

interface UserSelectProps {
  mode?: SelectProps['mode'];
  value?: SelectProps['value'];
  onChange: SelectProps['onChange'];
  size?: SizeType;
  optionType: 'AVATAR' | 'WITHOUT_AVATAR';
  width?: string;
  style?: CSSProperties;
}
const t = {
  placeholder: 'Please select users',
};

export function UserSelect(props: UserSelectProps) {
  const users = useSelector(usersSelector);

  function withoutAvatarPredicate(user: User) {
    return {
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      valueobject: user,
      fullname: `${user.first_name} ${user.last_name}`,
    };
  }
  function withAvatarPredicate(user: User) {
    return {
      label: (
        <UserInfoAvatar
          name={[user.first_name, user.last_name ?? '']}
          size="small"
          title={user.designation ?? ''}
          hideImgAvatar={true}
          align="center"
          style={{
            userName: { color: 'var(--gray-9)' },
            userNameTruncateWidth: '160px',
          }}
        />
      ),
      value: user.id,
      valueobject: user,
      fullname: `${user.first_name} ${user.last_name}`,
    };
  }
  const usersFormatedList = users?.map((user, index) => {
    return props.optionType === 'AVATAR' ? withAvatarPredicate(user) : withoutAvatarPredicate(user);
  });

  return props.mode === 'multiple' ? (
    <StyledSelect
      mode="multiple"
      onChange={props.onChange}
      showSearch
      value={props.value}
      options={usersFormatedList}
      optionFilterProp="fullname"
      style={props.style ? props.style : { width: '200px' }}
      dropdownStyle={{ minWidth: 'fit-content' }}
      maxTagCount={'responsive'}
      tagRender={tagRender}
    />
  ) : (
    <Select
      size={props.size ? props.size : 'large'}
      onChange={props.onChange}
      showSearch
      value={props.value ? props.value : undefined}
      options={usersFormatedList}
      optionFilterProp="fullname"
      placeholder={t.placeholder}
      dropdownStyle={{ minWidth: 'fit-content' }}
      style={props.style ? props.style : { width: '200px' }}
      tagRender={tagRender}
    />
  );
}
