import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton, GrowTypography } from '@sinecycle/growcomponents';
import { Card, Popconfirm, Space } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import { ShowEmailPreview } from 'components/Common/Email/Form/Preview/ShowEmailPreview';
import WorkflowFooter from 'components/Settings/WorkFlowAutomation/common/WorkflowFooter';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { useParams } from 'react-router-dom';
import { getActionType } from '../ActionConfigurations/ActionTypeTitle';
import useGetEntityType from '../hooks/useGetEntity';
import useRemoveAction from '../hooks/useRemoveAction';
import { t } from '../text';
import { ActionActivityTypes, ActionProps } from '../types';
import { validateEmailError } from '../utils';
import ActionForm from './actionForm';

export function Action(props: ActionProps) {
  const { form, baseForm } = props;
  const { workflow_id } = useParams();
  const actionType = props.selectedItem?.action.type
    ? getActionType(props.selectedItem)
    : props?.actionType?.value?.value;
  const actionRuleDataList = workflow_id ? props.actionRule : props.actionRuleList;
  const entityType = useGetEntityType(props.workflowMeta);
  const status = form.getFieldValue(['status']);
  const isNewAction = form.getFieldValue(['is_new_action']);
  const title = status === 'APPROVED' ? t.approval : t.rejection;
  const { isWorkflowOwner } = useWorkflowOwner();
  const desc =
    status === 'APPROVED'
      ? `${props.selectedItem ? t.approval_edit : t.add_approval}`
      : `${props.selectedItem ? t.rejection_edit : t.add_rejection}`;
  const titles = isWorkflowOwner
    ? [t.action_for, title, t.of_the_event]
    : [`${props.selectedItem ? t.edit_action : t.add_action_for}`, title, t.of_the_event];
  const Title = (
    <Space>
      {titles.map((text, index) => (
        <GrowTypography.Text
          key={index}
          strong
          fs="20"
          className={
            text === title ? (status === 'APPROVED' ? 'tw-text-green-8' : 'tw-text-red-7') : ''
          }
        >
          {text}
        </GrowTypography.Text>
      ))}
    </Space>
  );

  const updateActionData = useRemoveAction(actionRuleDataList!);

  function successCallback() {
    form
      .validateFields()
      .then((value) => {
        const emailError = props.emailFormData && validateEmailError({ ...props.emailFormData });
        if (actionType === ActionActivityTypes.EMAIL && emailError?.showErrors) {
          return;
        }

        form.submit();
      })
      .catch((err) => {});
  }

  function onClose() {
    form.resetFields();
    props.handleEmailContentChange({});
    props.onInsert();
  }
  const isSaved = form.getFieldsValue(['is_action_saved']);

  const enableFooterPopConfirm =
    (Boolean(actionRuleDataList?.length) && Boolean(props.enabled)) || isSaved?.is_action_saved;

  const ConfirmationTitle = (
    <PopConfirmContent
      title={'You want to remove this action?'}
      desc={t.override_actions}
      descStyles={{
        maxWidth: '200px',
      }}
    />
  );
  const RemoveButton = (
    <Popconfirm
      placement="topRight"
      icon={false}
      title={ConfirmationTitle}
      onConfirm={() => {
        updateActionData(
          props.selectedItem?.id ?? '',
          props.selectedItem?.parent_id,
          props.selectedItem?.rule_id
        );
        props.onInsert(undefined);

        props.onClick?.();
        form.resetFields();
      }}
    >
      <GrowButton icon={<FontAwesomeIcon icon={['far', 'trash-alt']} />} iconButton="without_bg" />
    </Popconfirm>
  );
  const Extra = <>{isNewAction ? null : RemoveButton}</>;
  const footer = (
    <WorkflowFooter
      onClose={onClose}
      onSubmit={successCallback}
      renderButton={Extra}
      confirmationText={t.override_actions}
      form={form}
      enablePopConfirm={enableFooterPopConfirm}
      extra={
        actionType === 'EMAIL' ? <ShowEmailPreview emailFormData={props.emailFormData!} /> : null
      }
    />
  );

  return (
    <Card
      className="tw-h-[calc(100vh-180px)] "
      style={{
        cursor: 'default',
        boxShadow:
          '0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000D',
      }}
      classNames={{
        actions: 'tw-sticky tw-bottom-[0px] tw-z-4 [&>li]:tw-m-[0px]',
        body: 'tw-h-full tw-overflow-auto',
        header: '!tw-border-transparent !tw-pb-[0px] !tw-px-24 !tw-pt-24',
      }}
      bordered
      hoverable
      actions={(actionType || props.selectedItem) && !isWorkflowOwner ? [footer] : []}
      title={
        status ? (
          Title
        ) : (
          <GrowTypography.Text fs="20">
            {isWorkflowOwner ? 'Action' : props.selectedItem ? 'Edit Action ' : t.add_action}
          </GrowTypography.Text>
        )
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-24">
        {status ? <GrowTypography.Text>{desc}</GrowTypography.Text> : null}
        <ActionForm
          actionRule={workflow_id ? props.actionRule : form.getFieldValue(['actions'])}
          form={form}
          emailFormData={props.emailFormData}
          errors={props.errors}
          handleEmailContentChange={props.handleEmailContentChange}
          entityType={entityType}
          baseForm={baseForm}
          selectedItem={props.selectedItem}
          actionType={props.actionType}
          onInsert={props.onInsert}
          onInvoiceLevel={props.onInvoiceLevel}
          isInvoiceLevel={props.isInvoiceLevel}
        />
      </div>
    </Card>
  );
}

export default Action;
