import ConditionFilterFieldAndDisplay from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Common/CommonConditionFilter/ConditionFilterFieldAndDisplay';
import { find, head, isNull, uniqWith } from 'lodash';
import { useGetContacts } from 'queries/contacts/customer';
import { ContactsType } from 'types/entities/contact';
import {
  DefaultContactAbsoluteOperand,
  UsersValueOperand,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowFilterOperator } from '../EventCondition/type';
import DynamicFilterGenerator from '../Filters/CommonFilterHelper/DynamicFilterGenerator/DynamicFilterGenerator';
import { DestructureValueType } from '../Filters/CommonFilterHelper/DynamicFilterGenerator/utils';

interface CollectionOwnerFieldProps {
  type: 'DISPLAY' | 'FILTER';
  value?: WorkFlowFilterOperator<DestructureValueType>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<DestructureValueType>) => void;
  displayValue?: UsersValueOperand;
  selectedFilter: ContactsType;
}
export default function ContactsField(props: CollectionOwnerFieldProps) {
  const { data: contactData, isLoading } = useGetContacts(props.selectedFilter);

  const uniqueContacts = uniqWith(contactData, (contact1, contact2) => {
    return (
      contact1.first_name === contact2.first_name &&
      contact1.last_name === contact2.last_name &&
      contact1.email === contact2.email
    );
  });
  const contactOptions = uniqueContacts?.map((user) => {
    const firstName = isNull(user.first_name) ? '' : user.first_name;
    const lasttName = isNull(user.last_name) ? '' : user.last_name;

    return {
      label: `${firstName} ${lasttName}`,
      value: `${user.first_name}_${user.last_name}_${user.email}`,
    };
  });
  const contactValue = props.value?.operands as DefaultContactAbsoluteOperand;
  const initialValue = head(contactValue?.value_holder)?.value;
  const contactResult =
    contactData &&
    find(contactData, {
      email: props.displayValue?.email,
      first_name: props.displayValue?.first_name,
      last_name: props.displayValue?.last_name,
    });
  const FilterComponent = (
    <>
      {contactData && (
        <DynamicFilterGenerator
          value={props.value}
          onChange={props.onChange}
          options={contactOptions}
          data={contactData}
          conditionType={props.selectedFilter}
          initialValue={`${initialValue?.first_name}_${initialValue?.last_name}_${initialValue?.email}`}
        />
      )}
    </>
  );
  const DisplayComponent = (
    <>
      {contactResult?.first_name} {contactResult?.last_name}
    </>
  );

  return (
    <ConditionFilterFieldAndDisplay
      type={props.type}
      filterCompoenent={FilterComponent}
      displayComponent={DisplayComponent}
      loading={isLoading}
    />
  );
}
