import { GrowFlex, GrowTypography } from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { InfoIcon } from 'components/Common/Icons';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { CallLog } from 'types/activities/callLog';
import { ActionTitleProps } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function FlagInvoicesTitle(props: ActionTitleProps<CallLog>) {
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.FLAG_INVOICE.title}
      icon={actionIcons.FLAG_INVOICE.icon}
      backgroundColor={actionIcons.FLAG_INVOICE.style.backgroundColor}
      title={actionIcons.FLAG_INVOICE.title}
      strong={props.strong}
    />
  );
}
