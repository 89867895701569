import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { amountFormats, CustomFieldIconWithText, GrowTypography } from '@sinecycle/growcomponents';
import { DescriptionsProps } from 'antd';
import {
  CustomerBlock,
  LabelBlock,
  MoreTextBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { ActivityContent } from 'components/CollectionActivities/style';
import { CustomFieldSnapShotText } from 'components/Common/CustomField/CustomFieldSnapshotText';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { ActionTypeIconContainer } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/style';
import { extractCurrentCustomField } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/utils';
import { PageLink } from 'lib/router';
import { find, get, map } from 'lodash';
import { RegionItem } from 'store/regions/types';
import {
  EventContext,
  EventSnapshotAttachments,
  EventSnapshotAttachmentType,
} from 'types/common/events';
import { BusinessUnit } from 'types/entities/business-unit';
import { CustomField } from 'types/entities/custom-field';
import { StatementViews } from 'types/entities/invoice';
import { Subsidiary } from 'types/entities/subsidiary';
import { SnapShotContent } from '../SnapShotContent';

interface CustomerSnapshotProps {
  value: EventContext;
  dateFormat?: string;
  locale?: string;
  baseCurrency?: string;
  isAnonymous?: boolean;
  attachments?: EventSnapshotAttachments<EventSnapshotAttachmentType>;
  hash?: string;
  bussinessUnits?: BusinessUnit[];
  region?: RegionItem[];
  subsidiary?: Subsidiary[];
  customField?: CustomField[];
}

function CustomerSnapshot(props: CustomerSnapshotProps) {
  const {
    value,
    locale,
    isAnonymous,
    attachments,
    baseCurrency,
    hash,
    bussinessUnits,
    region,
    subsidiary,
    customField,
    dateFormat,
  } = props;

  const customerContext = get(value, 'customer');
  const Icon = (
    <ActionTypeIconContainer backgroundColor={'var(--cyan-1)'}>
      <FontAwesomeIcon icon={['far', 'briefcase']} color="var(--cyan-7)" />
    </ActionTypeIconContainer>
  );
  const Businessunit = find(bussinessUnits, { id: customerContext.business_unit_ids })?.name;
  const Region = find(region, { id: customerContext.region_ids })?.name;
  const Subsidiary = subsidiary
    ?.filter((subsidiary) => customerContext.subsidiary_ids.includes(subsidiary.id))
    .map((subsidiary) => subsidiary.display_name);
  const customFields: DescriptionsProps['items'] = map(customerContext?.custom_fields, (field) => {
    return {
      key: `${field.id}`,
      label: (
        <CustomFieldIconWithText hasSymbol style={{ color: '#737373' }}>
          {extractCurrentCustomField(customField, field.id)?.fieldName}
        </CustomFieldIconWithText>
      ),
      children: <CustomFieldSnapShotText field={field} locale={locale} dateFormat={dateFormat} />,
    };
  });

  const customerDetails: DescriptionsProps['items'] = [
    {
      key: 'CUSTOMER_NAME',
      label: <LabelBlock label="Customer Name" />,
      children: (
        <CustomerBlock
          id={value.customer.id}
          name={value.customer.name}
          customerNumber={value.customer?.customer_no}
        />
      ),
    },
    {
      key: 'AMOUNT',
      label: <LabelBlock label="Amount Due" />,
      children: (
        <GrowTypography.Text>
          {amountFormats.currency(customerContext.outstanding_value, {
            currency: baseCurrency ?? 'USD',
            locale,
          })}
        </GrowTypography.Text>
      ),
    },
    {
      key: 'CREDIT_TERM',
      label: <LabelBlock label="Credit Term" />,
      children: <TextBlock value={customerContext.credit_term} />,
    },
    {
      key: 'BU',
      label: 'Business unit',
      children: <TextBlock value={Businessunit} />,
    },
    {
      key: 'REGION',
      label: <LabelBlock label="Region" />,
      children: <TextBlock value={Region} />,
    },
    {
      key: 'SUB',
      label: (
        <LabelBlock label={Subsidiary && Subsidiary?.length > 1 ? 'Subidaries' : 'Subsidiary'} />
      ),
      children: <MoreTextBlock value={Subsidiary} title="Subidaries" />,
    },
    ...customFields,

    //in future we will introdcue the documents
    // {
    //   key: 'DOCUMENTS',
    //   label: 'Documents',
    //   children: (
    //     <ApprovalDocuments
    //       isAnonymous={isAnonymous}
    //       attachments={attachments ? attachments : value.attachments}
    //       hash={hash}
    //     />
    //   ),
    // },
  ];

  const params = new URLSearchParams({
    [EActivityQueryParams.CURRENT_SECTION]: StatementViews.INVOICES,
    [EActivityQueryParams.CURRENT_VIEW]: StatementViews.OPEN_INVOICES,
  });

  const Details = (
    <ActivityContent
      items={customerDetails}
      column={2}
      colon={false}
      layout="vertical"
      contentStyle={{
        paddingBottom: 'var(--space-12)',
      }}
      className="snapshot"
      labelStyle={{ color: 'var(--gray-7)' }}
    />
  );

  const Label = (
    <div className="tw-flex tw-items-center tw-gap-8" style={{ height: '100%' }}>
      <CustomerBlock
        id={value.customer.id}
        name={value.customer.name}
        strong
        style={{ fontSize: 'var(--fs-16)' }}
      />
      <PageLink
        pathParams={{ id: value.customer.id }}
        appPage="CUSTOMER_DETAILS"
        target={'_blank'}
        searchParams={params}
        style={{ display: 'flex', cursor: 'pointer' }}
      >
        <FontAwesomeIcon icon={['far', 'arrow-up-right-from-square']} color="var(--gray-7)" />
      </PageLink>
    </div>
  );

  return (
    <SnapShotContent
      defaultActiveKey={['DETAILS']}
      collapsible="disabled"
      expandIcon={() => {
        return Icon;
      }}
      items={[
        {
          key: 'DETAILS',
          label: Label,
          children: Details,
        },
      ]}
    />
  );
}

export { CustomerSnapshot };
