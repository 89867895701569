import { FormInstance } from 'antd';
import { generateCustomActionOptions } from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import { useAccountLevelCustomFieldsQueryWorkflow } from 'queries/custom-fields';
import { CustomFieldEntityType } from 'types/entities/custom-field';
import { ActionActivityTypes } from '../types';
import { extractCurrentCustomField } from '../utils';
import useExtractCustomFieldIds from './useExtractCustomFieldIds';

interface useCustomerFieldProps {
  fieldId?: number;
  form?: FormInstance;
}

/**
 *
 * @param field
 * @returns { customerOptions, fieldName, isLoading, selectedLabel, customFieldList }
 *
 * This hook is used to return options also the currently selected label we send the
 * options list by filtering the already used custom fields.
 */
export default function useCustomerFieldData(props?: useCustomerFieldProps) {
  const { data, isLoading } = useAccountLevelCustomFieldsQueryWorkflow(
    CustomFieldEntityType.CUSTOMER
  );

  const currentFieldsIds = useExtractCustomFieldIds(
    props?.form,
    ActionActivityTypes.CUSTOMER_CUSTOM_FIELD
  );
  const { fieldName, fieldLabel, customFieldList } = extractCurrentCustomField(
    data,
    props?.fieldId
  );
  const filteredOptions = data
    ?.filter((item) => !currentFieldsIds.get(item.id))
    ?.filter((item) => item.editable);
  const customerOptions = generateCustomActionOptions(filteredOptions);

  return {
    fieldName,
    isLoading,
    customerOptions,
    selectedLabel: fieldLabel,
    customFieldList,
  };
}
