import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { invoiceStatusListLabel } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import { head } from 'lodash';
import { InvoiceStatus } from 'types/entities/invoice';
import { InAbsoluteOperands } from 'types/entities/invoice-segments/operands';

interface INInvoiceStatusProps {
  value: InAbsoluteOperands<InvoiceStatus>;
  color: string;
  strong?: boolean;
  text?: string;
}
export default function InvoiceStatusINDisplay(props: INInvoiceStatusProps) {
  const status = head(props.value?.value_holder)?.value;
  const label = status && invoiceStatusListLabel[status];
  return (
    <Flex gap="var(--space-4)">
      <GrowText color={props.color} strong={props.strong}>
        {label}
      </GrowText>
      <GrowText color={props.color} strong={props.strong}>
        {props.text}
      </GrowText>
    </Flex>
  );
}
