import {
  actionTypesConfig,
  OptionLabel,
} from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import { flatMap } from 'lodash';
import { ActionActivityTypes } from '../types';

/**
 *
 * @returns { extractFieldLabel, actionOptionsConfigList }
 *
 * This hook is used to form a label for the currently selected action type.
 * also useful when some actions which can be created only once take this label
 * to set in the dropdown field.
 */
export default function useExtractSelectedType() {
  const actionOptionsConfigList = new Map(
    flatMap(actionTypesConfig, (category) => category.options.map((option) => [option.id, option]))
  );

  function extractFieldLabel(value: ActionActivityTypes) {
    const newActionType = actionOptionsConfigList.get(value);
    return {
      label: <OptionLabel option={newActionType} />,
      value: newActionType?.id,
      showCheckbox: newActionType?.showCheckbox,
    };
  }

  return extractFieldLabel;
}
