import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowDrawer } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { EmailPreview } from 'components/Common/Email/Form/Preview/EmailPreview';
import styled from 'styled-components';
import { ValidEmailActionData } from 'types/entities/collection-strategy/rule-action';

interface EmailPreviewDrawerProps {
  visible: boolean;
  onClose: () => void;
  emailData: ValidEmailActionData;
  handleOpenChange: (open: boolean) => void;
}

const headerStyle: React.CSSProperties = {
  background: 'var(--gray-2)',
  padding: 'var(--space-16) var(--space-24)',
};

const StyledDrawer = styled(GrowDrawer)`
  box-shadow: var(--shadow-2);
  transition: opacity 1s ease-out;
  transition: translate 1s ease-out;
  .ant-drawer-body {
    overflow-x: hidden;
  }
  .preview-body {
    overflow: auto;
  }
`;

const t = {
  emailPreview: 'Email Preview',
};

export function EmailPreviewDrawer(props: EmailPreviewDrawerProps) {
  const { visible, onClose, emailData, handleOpenChange } = props;

  const Header = (
    <Flex style={headerStyle} justify="space-between">
      <Texto size="16">{t.emailPreview}</Texto>
      <FontAwesomeIcon
        size="lg"
        className="cursor-pointer"
        icon={['far', 'arrow-right']}
        onClick={onClose}
      />
    </Flex>
  );

  return (
    <StyledDrawer
      open={visible}
      closeIcon={false}
      closable={false}
      onClose={onClose}
      title={Header}
      design="growfin_design_default"
      destroyOnClose
      push={false}
      afterOpenChange={handleOpenChange}
      width={'var(--compose-email-width)'}
    >
      {/* to destroy and re-create Preview Component on Close */}
      <EmailPreview emailData={emailData} />
    </StyledDrawer>
  );
}
