import { GrowTypography } from '@sinecycle/growcomponents';
import { Col, Row } from 'antd';

interface Option {
  key: string;
  label: React.ReactNode;
  children?: Array<{ label: React.ReactNode; key: string }>;
}

interface SelectedOptionsListProps {
  selectedOptions?: Option[];
  onOptionClick?: (option: Option, section: string) => void;
}

export default function ActionOptions({
  selectedOptions,
  onOptionClick,
}: SelectedOptionsListProps): JSX.Element {
  return (
    <div className="tw-p-16">
      {selectedOptions?.map((option) => (
        <div key={option?.key} className="tw-flex tw-flex-col tw-gap-16" id={option?.key}>
          <GrowTypography.Text strong>{option?.label}</GrowTypography.Text>
          <Row gutter={[16, 16]}>
            {option.children?.map((child) => (
              <Col
                key={child?.key}
                span={24}
                className="hover:tw-bg-[var(--primary-1)] tw-p-4 tw-cursor-pointer tw-rounded"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (onOptionClick) {
                    onOptionClick(child, option.key);
                  }
                }}
              >
                {child.label}
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  );
}
