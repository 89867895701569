import useGetCollectionOwnerData from 'components/Common/CollectionOwner/hooks/useGetCollectionOwnerData';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import CustomFieldTitle from 'components/Settings/WorkFlowAutomation/common/CustomFieldTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { find, head } from 'lodash';
import { t } from '../../text';
import { ActionTitleProps, CollectionOwnerActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function CollectionOwnerTitle(props: ActionTitleProps<CollectionOwnerActionType>) {
  // const { item } = props;
  // const { collectionOwnerUsers, isLoading } = useGetCollectionOwnerData();

  // const collectionOwnerName = find(collectionOwnerUsers, { id: head(item.user_ids) });

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.USER_CATEGORY.title}
      icon={actionIcons.USER_CATEGORY.icon}
      backgroundColor={actionIcons.USER_CATEGORY.style.backgroundColor}
      title={actionIcons.USER_CATEGORY.title}
      strong={props.strong}
    />
  );
}
