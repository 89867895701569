import { Button, Descriptions, Divider } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CommentsBlock } from 'components/CollectionActivities/Comment/CommentsBlock';
import { ActionButtons } from 'components/CollectionActivities/Common/Details/ActionButons/ActionButtons';
import {
  AmountBlock,
  AttachmentsBlock,
  InvoicesBlock,
  LabelBlock,
  LineItemBlock,
  TextBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { ViewInvoicesButton } from 'components/CollectionActivities/Common/Details/HeaderButtons';
import { SubscriptionType } from 'components/CollectionActivities/Common/SubscriptionType';
import useActivityBackButton from 'components/CollectionActivities/hooks/useActivityBackButton';
import useGetActivityLink from 'components/CollectionActivities/hooks/useGetActivityLink';
import { ActivityContent, ActivityTitle } from 'components/CollectionActivities/style';
import { getActivityUnitItems } from 'components/CollectionActivities/utils';
import { OPEN_TYPE, useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import HideWrapper from 'components/Common/Util/HideWrapper';
import ActivitiesModal from 'components/HigherOrderComponent/KeyActivitesContainer/ActivitiesModal';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { getKeyActivityConfig } from 'components/HigherOrderComponent/KeyActivitesContainer/key-acivities-config';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';

import { ActivityIconButton } from 'components/Inbox/CollectionActivitiesV2/Activities/style';
import { castArray } from 'lodash';
import { useSelector } from 'react-redux';
import { ELastContactActivity, RaiseADisputeActivity } from 'store/activity-feed/type';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';
import useOpen from 'util/hooks/useOpen';

interface DisputeDetailsProps extends ActivityDetailsBase {
  data?: RaiseADisputeActivity;
  isLoading: boolean;
}

const t = {
  deleteTitle: 'Delete Dispute',
  deleteDescription: 'Are you sure you want to Delete the Dispute entry?',
};

const config = getKeyActivityConfig(ELastContactActivity.EMAIL);

function DisputeDetailsBody(props: DisputeDetailsProps) {
  const {
    hideActions = false,
    data,
    isLoading,
    subscriptionType,
    onDelete,
    deleteLoading,
    editLoading,
    customer,
    activitiesMode,
  } = props;
  const { cardLabel, Icon } = getActivityUnitItems(ActivityType.DISPUTE);
  const { entitySearchParams, setEntitySearchParams, removeEntitySearchParams } =
    useEntitySearchParams();
  const { open, toggleOpen, onClose } = useOpen({
    open: Boolean(entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.EMAIL),
  });
  const baseCurrency = useSelector(baseCurrencySelector);
  const ActivityCopyLink = useGetActivityLink(activitiesMode);
  const ActivityBackButton = useActivityBackButton();
  const customers = data?.customer ? castArray(data.customer) : undefined;
  const Title = (
    <ActivityTitle align="center" gap="var(--space-8)">
      <ActivityBackButton />
      <ActivityIconButton
        icon={Icon}
        size="small"
        css={{ '--activityBg': 'var(--volcano-1)' }}
        viewMode
        loading={isLoading}
        hideBackground
      />
      {!isLoading && data ? <GrowText>{`${cardLabel}(${data?.dispute_no})`}</GrowText> : null}
      <ActivityCopyLink data={data} customer={customer} type={ActivityType.DISPUTE} />
    </ActivityTitle>
  );

  const Header = (
    <Flex justify="space-between">
      {Title}
      <ActionButtons
        hide={hideActions}
        onDelete={onDelete}
        id={data?.id}
        deleteLoading={deleteLoading}
        editLoading={editLoading}
        title={t.deleteTitle}
        description={t.deleteDescription}
        activity={data}
        type={ActivityType.DISPUTE}
      />
    </Flex>
  );

  const InvoiceArea =
    !isLoading && data ? <InvoicesBlock invoices={data.invoices} isBaseCurrency /> : null;
  const LineItem = !isLoading && data ? <LineItemBlock item={data.invoice_line_items} /> : null;
  const Amount =
    !isLoading && data ? (
      <AmountBlock
        amount={data.amount.value}
        amountComp={
          <AmountWithCurrencySplit
            type="single"
            showTooltip
            amount={data.amount.value as number}
            currency={data.amount.currency as string}
            baseAmount={data.amount.value_in_transformed_currency as number}
            baseCurrency={data?.amount.transformed_currency ?? baseCurrency}
            formatOptions={{ notation: 'compact' }}
          />
        }
      />
    ) : null;

  const Note =
    !isLoading && data ? (
      <Flex direction="column" gap="var(--space-8)">
        <TextBlock value={data.title} strong size="var(--fs-20)" />
        <TextBlock value={data.description} />
      </Flex>
    ) : null;
  const Attachments =
    !isLoading && data?.attachments?.length ? (
      <ActivityContent layout="vertical" colon={false}>
        <Descriptions.Item label={<LabelBlock label="Attachments" />}>
          <AttachmentsBlock attachments={data.attachments ?? []} hideTitle />
        </Descriptions.Item>
      </ActivityContent>
    ) : null;

  const Actions = (
    <Flex style={{ marginTop: 'var(--space-8)' }} gap="var(--space-8)">
      {!isLoading && data ? <ViewInvoicesButton invoices={data.invoices} text="Invoice" /> : null}
      {!isLoading && data ? (
        <Button
          icon={config?.Icon}
          onClick={() => {
            toggleOpen();
            setEntitySearchParams({ [EActivityQueryParams.OPEN]: OPEN_TYPE.EMAIL });
          }}
          style={{ color: 'var(--primary-7)' }}
        >
          {config?.label}
        </Button>
      ) : null}
    </Flex>
  );

  const Sub = !isLoading && data ? <SubscriptionType subscriptionType={subscriptionType} /> : null;
  const Content = (
    <>
      <Flex gap="var(--space-12)" direction="column">
        <Flex direction="column" gap="var(--space-8)">
          {Header}
          {Sub}
        </Flex>
        <Flex gap="var(--space-16)" direction="column">
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item>{Note}</Descriptions.Item>
          </ActivityContent>
          {Attachments}
          {Actions}
          <ActivityContent layout="vertical" colon={false}>
            <Descriptions.Item label={<LabelBlock label="Invoices" />} span={1.2}>
              {InvoiceArea}
            </Descriptions.Item>
          </ActivityContent>
          <HideWrapper hide={!data?.invoice_line_items.length}>
            <ActivityContent layout="vertical" colon={false}>
              <Descriptions.Item label={<LabelBlock label="Invoice line items" />}>
                {LineItem}
              </Descriptions.Item>
            </ActivityContent>
          </HideWrapper>
          <Divider style={{ margin: 'var(--space-16) 0', borderColor: 'var(--gray-5)' }} />
        </Flex>
      </Flex>
      <Flex direction="column">
        <ActivityContent layout="vertical" colon={false}>
          <Descriptions.Item label={<LabelBlock label="Dispute Amount" />} span={1}>
            {Amount}
          </Descriptions.Item>
        </ActivityContent>
      </Flex>
    </>
  );

  const Footer =
    data && !isLoading ? (
      <CommentsBlock
        customerId={data.customer.id}
        activityId={Number(data.id)}
        activityType={ActivityType.DISPUTE}
        comments={data.comments}
        isOpen={entitySearchParams[EActivityQueryParams.OPEN] === OPEN_TYPE.COMMENTS}
      />
    ) : null;
  return (
    <>
      {Content}
      <Divider style={{ margin: 'var(--space-24) 0', borderColor: 'var(--gray-5)' }} />
      {Footer}
      <ActivitiesModal
        quickAction={open ? ELastContactActivity.EMAIL : undefined}
        invoiceList={data?.invoices}
        customerId={data?.customer.id}
        customers={customers}
        resetToIntialState={() => {
          onClose();
          removeEntitySearchParams([EActivityQueryParams.OPEN]);
        }}
        entity={ActionableEntity.INVOICE}
        overrideEmailStyles={{ position: 'fixed' }}
        mask={activitiesMode}
        isInbox={activitiesMode}
      />
    </>
  );
}

export { DisputeDetailsBody };
