import { Select, SelectProps } from 'antd';
import useGetCollectionOwnerData from './hooks/useGetCollectionOwnerData';

interface SelectCollectionOwnerProps extends SelectProps {
  onChange?: (selectedValues: any | any[], options: any) => void;
}

type AntSelectProps = Parameters<typeof Select>[0];

export default function CollectionOwnerSelect(props: SelectCollectionOwnerProps) {
  const { value, onChange } = props;
  const { collectionOwnerUsersOptions } = useGetCollectionOwnerData();

  const filterOption: AntSelectProps['filterOption'] = (search, optionOrGroup) => {
    if (!optionOrGroup) return false;

    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase());
  };

  return (
    <Select
      showSearch
      value={value}
      options={collectionOwnerUsersOptions}
      onChange={onChange}
      filterOption={filterOption}
      {...props}
    />
  );
}
