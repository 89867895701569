import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers/rootReducer';
import { CRUD_ACTIONS } from '../business-units/types';
import { AllRegionsResponseState, RegionItem } from './types';

const initialState: AllRegionsResponseState = {
  status: 0,
  message: '',
  regions: [],
};

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    getAllRegionsHandler(state, action: PayloadAction<AllRegionsResponseState>) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.regions = action.payload.regions ?? [];
    },

    updateRegionsHandler(
      state,
      action: PayloadAction<{
        type: CRUD_ACTIONS;
        Region: RegionItem;
      }>
    ) {
      let regions = [] as RegionItem[];

      switch (action.payload.type) {
        case CRUD_ACTIONS.CREATE:
          regions = [...(state.regions ? state.regions : []), action.payload.Region];
          break;
        case CRUD_ACTIONS.UPDATE:
          regions = state.regions
            ? state.regions.map((unit) => {
                if (unit.id === action.payload.Region.id) {
                  return action.payload.Region;
                } else {
                  return unit;
                }
              })
            : [];
          break;

        case CRUD_ACTIONS.DELETE:
          regions = state.regions
            ? state.regions.filter((unit) => {
                return unit.id !== action.payload.Region.id;
              })
            : [];
          break;
      }

      state.regions = regions;
    },
  },
});

export const { getAllRegionsHandler, updateRegionsHandler } = regionsSlice.actions;

export default regionsSlice.reducer;

const regionsSelectors = {
  regionsSelector: (state: RootState) => state.regions,
};

export const { regionsSelector } = regionsSelectors;
