import { ApprovalTitle } from 'components/Approvals/ApprovalTitle';
import { EventSnapShot } from 'components/Common/Snapshot/EventSnapShot';
import { useParams } from 'lib/router';
import { ApprovalRequestDV, UserApprovalRequestMapAnon } from 'pages/Approvals/type';

interface ApprovalDetailsContentProps {
  data?: ApprovalRequestDV;
  accountDetails?: UserApprovalRequestMapAnon['account'];
  id?: number;
}
function ApprovalAnonymousDetailsContent(props: ApprovalDetailsContentProps) {
  const { data, accountDetails, id } = props;
  const { hash } = useParams();
  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <ApprovalTitle title={`AP-${id} ${data?.created_by_workflow?.title}`} />
      <EventSnapShot
        snapshot={data?.entity_snapshot}
        attachments={data?.attachments}
        isAnonymous
        baseCurrency={accountDetails?.currency}
        dateFormat={accountDetails?.date_format}
        locale={accountDetails?.locale}
        hash={hash}
      />
    </div>
  );
}

export { ApprovalAnonymousDetailsContent };
