import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import CustomFieldTitle from 'components/Settings/WorkFlowAutomation/common/CustomFieldTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import useInvoiceFieldData from '../../hooks/useInvoiceFieldData';
import { ActionTitleProps, InvoiceCustomFieldType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function InvoiceCustomFieldTitle(props: ActionTitleProps<InvoiceCustomFieldType>) {
  // const { item } = props;
  // const { fieldName, isLoading } = useInvoiceFieldData({
  //   fieldId: item?.field_id,
  // });
  // const field = (
  //   <CustomFieldIconWithText hasSymbol strong={false}>
  //     {fieldName}
  //   </CustomFieldIconWithText>
  // );
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.INVOICE_CUSTOM_FIELD.title}
      icon={actionIcons.INVOICE_CUSTOM_FIELD.icon}
      backgroundColor={actionIcons.INVOICE_CUSTOM_FIELD.style.backgroundColor}
      title={actionIcons.INVOICE_CUSTOM_FIELD.title}
      strong={props.strong}
    />
  );
}
