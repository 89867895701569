import { dunningRuleActionLabels } from 'constants/collection-activities/dunning-rule';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RuleErrorField } from 'store/collection-strategy-rule/validations';
import { FollowupType } from 'types/entities/collection-strategy';
import { FollowupRuleActionType } from 'types/entities/collection-strategy/rule-action';
import {
  FollowupRuleScopeType,
  InvoiceSegmentScope,
} from 'types/entities/collection-strategy/rule-scope';
import { InvoiceSegment } from 'types/entities/invoice-segments';
import { dunningRuleActions } from '.';
import {
  dunningRuleActionTypeSelector,
  dunningRuleErrorSelector,
  dunningRuleErrorsSelector,
  dunningRuleErrorsVisibleSelector,
  dunningRuleFollowupTypeSelector,
  dunningRuleScopeSelector,
  dunningRuleTitleSelector,
} from './selectors';

interface Options {
  followupType?: FollowupType;
  reset?: boolean;
}

export function useToggleFollowupRuleDrawer() {
  const dispatch = useDispatch();

  const toggleFollowupRuleDrawer = useCallback(
    (state: boolean, options: Options = {}) => {
      const { followupType, reset } = options;

      dispatch(dunningRuleActions.toggleDrawer({ visible: state, followupType, reset }));
    },
    [dispatch]
  );

  return toggleFollowupRuleDrawer;
}

export function useRuleTitle() {
  const dispatch = useDispatch();
  const title = useSelector(dunningRuleTitleSelector);

  const setTitle = useCallback(
    (newTitle: string) => {
      dispatch(dunningRuleActions.updateTitle(newTitle));
    },
    [dispatch]
  );

  return { title, setTitle };
}

export function useRuleActionType() {
  const actionType = useSelector(dunningRuleActionTypeSelector);
  const dispatch = useDispatch();

  const setActionType = useCallback(
    (newActionType: FollowupRuleActionType) => {
      dispatch(dunningRuleActions.updateActionType(newActionType));
    },
    [dispatch]
  );

  return { actionType, setActionType };
}

const staticActionOptions = Object.entries(dunningRuleActionLabels).map((keyval) => {
  return {
    value: keyval[0] as FollowupRuleActionType,
    label: keyval[1],
  };
});

export function useRuleActionOptions() {
  const followupType = useSelector(dunningRuleFollowupTypeSelector);

  // statement followup does not have escalation
  const validActionOptions =
    followupType === FollowupType.STATEMENT
      ? staticActionOptions.filter((option) => option.value !== FollowupRuleActionType.ESCALATION)
      : staticActionOptions;

  return validActionOptions;
}

export function useRuleInvoiceSegment() {
  const followupScopes = useSelector(dunningRuleScopeSelector) ?? [];
  const dispatch = useDispatch();

  const invoiceSegmentScopes = followupScopes
    .filter((scope) => scope.type === FollowupRuleScopeType.INVOICE_SEGMENT)
    .map((scope) => scope.id);

  const setInvoiceSegment = useCallback(
    (segmentId: number, segment: InvoiceSegment) => {
      const invoiceSegmentScope: InvoiceSegmentScope = {
        id: segmentId,
        type: FollowupRuleScopeType.INVOICE_SEGMENT,
        data: {
          title: segment.title,
        },
      };

      dispatch(dunningRuleActions.saveInvoiceSegmentScope(invoiceSegmentScope));
    },
    [dispatch]
  );

  const clearInvoiceSegment = useCallback(() => {
    dispatch(dunningRuleActions.removeInvoiceSegmentScope());
  }, [dispatch]);

  return { invoiceSegmentScopes, setInvoiceSegment, clearInvoiceSegment };
}

export function useDunningRuleErrors() {
  const showError = useSelector(dunningRuleErrorsVisibleSelector);
  const errors = useSelector(dunningRuleErrorsSelector);
  const dispatch = useDispatch();

  const setShowErrors = useCallback(
    (showError: boolean) => {
      dispatch(dunningRuleActions.setShowErrors(showError));
    },
    [dispatch]
  );

  return { showError, errors, setShowErrors };
}

export function useRuleErrorField(fieldKey: RuleErrorField) {
  const error = useSelector(dunningRuleErrorSelector(fieldKey));
  const showError = useSelector(dunningRuleErrorsVisibleSelector);

  return { error, showError };
}
