import { GrowFlex, GrowTypography } from '@sinecycle/growcomponents';

interface OnBehalfProps {
  assignedUser?: {
    first_name?: string;
    last_name?: string;
  };
}

function OnBehalf({ assignedUser }: OnBehalfProps) {
  return (
    <GrowFlex
      vertical
      space="4"
      style={{ padding: 'var(--space-4) var(--space-8)', background: 'var(--geekblue-1)' }}
    >
      <GrowTypography.Text fs="12" className="tw-text-gray-8">
        Action taken on behalf of
      </GrowTypography.Text>
      <GrowTypography.Text fs="12">{`${assignedUser?.first_name ?? ''} ${
        assignedUser?.last_name ?? ''
      }`}</GrowTypography.Text>
    </GrowFlex>
  );
}
export { OnBehalf };
