import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import { AttachmentsPreview, FilesPreview } from 'components/Common/Email/Form/FileUpload';
import { useRelativeContactLabel } from 'components/Common/SelectComponents/RelativeContactSelect';
import { find } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getEmailPreview } from 'services/email';
import {
  AllOpenInvoicesAttachment,
  CurrentInvoiceAttachment,
  EmailAttachment,
} from 'store/email/types';
import styled from 'styled-components';
import { ValidEmailActionData } from 'types/entities/collection-strategy/rule-action';

interface EmailPreviewProps {
  emailData: ValidEmailActionData;
}

const t = {
  from: 'From',
  to: 'To',
  cc: 'CC',
  bcc: 'BCC',
  replyTo: 'Reply To',
  subject: 'Subject',
  body: 'Body',
  attachedInvoice: 'Attached Invoice',
  invoice: 'Invoice',
  attachments: 'Attachments',
};

type EmailResponse = {
  subject: string;
  body: string;
};

const PdfButton = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-8);
  cursor: pointer;
  border: 1px solid var(--gray-5);
  border-radius: var(--br-1);
  padding: var(--space-8) var(--space-16);
  background: var(--gray-2);
`;

export function EmailPreview({ emailData }: EmailPreviewProps) {
  const [emailPreviewResponse, setEmailPreviewResponse] = useState<EmailResponse>();
  const [loading, setLoading] = useState(false);

  const { subject, body, from, to, cc, bcc, reply_to, attachments, fileUploads } = emailData;

  function getAttachments() {
    const val = find(attachments, { type: 'ATTACHMENT' }) as EmailAttachment;
    return val ? val.list : [];
  }
  function getCurrentInvoice() {
    return find(attachments, { type: 'CURRENT_INVOICE' }) as CurrentInvoiceAttachment;
  }
  function getAllInvoice() {
    return find(attachments, { type: 'ALL_OPEN_INVOICES' }) as AllOpenInvoicesAttachment;
  }

  const currentInvoice = useMemo(getCurrentInvoice, [attachments]);
  const AllInvoice = useMemo(getAllInvoice, [attachments]);
  const attachment = useMemo(getAttachments, [attachments]);
  useEffect(() => {
    setLoading(true);
    getEmailPreview(subject, body)
      .then((preview) => setEmailPreviewResponse(preview))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [body, subject]);

  const { getRelativeContactLabel } = useRelativeContactLabel();

  if (loading) return <Skeleton />;

  const From = (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.from}</Texto>
      <Texto weight="semibold">{getRelativeContactLabel(from)}</Texto>
    </Flex>
  );

  const To = (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.to}</Texto>
      <Texto weight="semibold">
        {to.map((to, index) => getRelativeContactLabel(to)).join(', ')}
      </Texto>
    </Flex>
  );

  const CC = cc && (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.cc}</Texto>
      <Texto weight="semibold">{cc.map((cc) => getRelativeContactLabel(cc)).join(', ')}</Texto>
    </Flex>
  );

  const BCC = bcc && (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.bcc}</Texto>
      <Texto weight="semibold">{bcc.map((bcc) => getRelativeContactLabel(bcc)).join(', ')}</Texto>
    </Flex>
  );

  const ReplyTo = reply_to && (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.replyTo}</Texto>
      <Texto weight="semibold">{getRelativeContactLabel(reply_to)}</Texto>
    </Flex>
  );

  const Subject = (
    <Flex direction="column" gap="--space-8" wrap="wrap">
      <Texto>{t.subject}</Texto>
      <div>
        <Texto weight="semibold">{emailPreviewResponse?.subject ?? subject}</Texto>
      </div>
    </Flex>
  );

  const Body = (
    <Flex direction="column" gap="--space-8">
      <Texto>{t.body}</Texto>
      <div className="preview-body">
        <div dangerouslySetInnerHTML={{ __html: emailPreviewResponse?.body ?? body }} />
      </div>
    </Flex>
  );

  const InvoicePdf = ((Boolean(currentInvoice) && Boolean(currentInvoice.type)) ||
    (Boolean(AllInvoice) && Boolean(AllInvoice.type))) && (
    <Flex direction="column" gap="--space-8">
      <Texto>{t.attachedInvoice}</Texto>
      <PdfButton>
        <FontAwesomeIcon icon={['far', 'file-pdf']} color="var(--red-5)" size="lg" />
        <Texto color="var(--blue-6">{t.invoice}</Texto>
      </PdfButton>
    </Flex>
  );

  const showAttachments = Boolean(attachment?.length) || Boolean(fileUploads?.length);

  const Attachments = showAttachments && (
    <Flex direction="column" gap="--space-8">
      <Texto>{t.attachments}</Texto>
      <Flex>
        {Boolean(attachments?.length) && <AttachmentsPreview attachments={attachment} />}
        {fileUploads && <FilesPreview fileUploads={fileUploads} />}
      </Flex>
    </Flex>
  );

  return (
    <Flex direction="column" gap="--space-24">
      {From}
      {To}
      {CC}
      {BCC}
      {ReplyTo}
      {Subject}
      {Body}
      {Attachments}
      {InvoicePdf}
    </Flex>
  );
}
