import { head, map } from 'lodash';
import { WorkflowMetaDto } from 'types/entities/workflow';

/**
 *
 * @param data
 * @returns { "INVOICE" | "CUSTOMER" | undefined }
 *
 * retrives the current entity in the trigger
 */
export default function useGetEntityType(data: WorkflowMetaDto) {
  return head(map(data?.workflow_rules, 'entity_type'));
}
