import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePickerComponent } from 'components/BaseComponents/Calendar';
import { EditOnHover } from 'components/BaseComponents/EditOnHover';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { DateBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import useUpdateActivity from 'components/CollectionActivities/hooks/useUpdateActivity';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { Dayjs } from 'dayjs';
import { map } from 'lodash';
import { useUpdateKeyActivities } from 'queries/activites';
import { useEffect, useState } from 'react';
import { UpdateKeyActivitiesParams } from 'types/activities/activity-types';
import { Task } from 'types/activities/task';
import useOpen from 'util/hooks/useOpen';
import { TaskDueDate } from './TaskStatus';

interface DueDateProps {
  dueDate: string;
  onChangeCallBack?: (dueDate: string) => void;
  data?: Task;
  readOnly?: boolean;
}

export function DueDate(props: DueDateProps) {
  const { data, onChangeCallBack, readOnly } = props;
  const { open, onClose, toggleOpen } = useOpen({ open: false });
  const { mutateAsync: updateKeyActivities } = useUpdateKeyActivities<Task>();
  const [dueDate, setDueDate] = useState(props.dueDate);
  const { updateActivity } = useUpdateActivity<Task>();
  function handleUpdateKeyActivities(params: UpdateKeyActivitiesParams<Task>) {
    const { value, message } = params;

    const modifiedValues: Task = {
      ...value,
      invoice_ids: map(value.invoices, 'id'),
    };
    if (!data) return;
    updateKeyActivities({
      params: { ...modifiedValues },
      id: value.id,
      activity: 'tasks',
    })
      .then(() => {
        updateActivity(data, value);
        notify.success(message?.success);
        onChangeCallBack?.(dueDate);
      })
      .finally(() => {
        onClose();
      });
  }

  useEffect(() => {
    setDueDate((dueDate) => (dueDate === props.dueDate ? dueDate : props.dueDate));
  }, [props.dueDate]);

  const EditMode = (
    <Flex align="center" gap="var(--space-8)">
      <DatePickerComponent
        currentDate={dueDate}
        size="middle"
        onChangeCallBack={(dateObj: Dayjs, stringDate) => {
          setDueDate(stringDate as string);
        }}
        allowClear={false}
      />
      <Flex className="quick-action" gap="var(--space-8)">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={['fal', 'times']}
          onClick={() => {
            setDueDate(props.dueDate);
            onClose?.();
          }}
        />
        <FontAwesomeIcon
          icon={['fal', 'check']}
          className="cursor-pointer"
          onClick={() => {
            if (dueDate && data) {
              handleUpdateKeyActivities({ value: { ...data, due_date: dueDate } });
            }
          }}
        />
      </Flex>
    </Flex>
  );

  const ViewMode = (
    <Flex align="center" gap="var(--space-4)" direction="column">
      <EditOnHover onEdit={toggleOpen} hoverMode="CELL" hideEdit={readOnly}>
        <DateBlock dateString={dueDate} dateOnly />
      </EditOnHover>
      {data ? (
        <HideWrapper hide={open}>
          <TaskDueDate dueDate={data.due_date} status={data.status} />
        </HideWrapper>
      ) : null}
    </Flex>
  );

  return <>{open ? EditMode : ViewMode}</>;
}
