import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { sideMenuLabel } from 'components/Settings/RolesAndPermissions/EditPrivileges';
import { EPages } from 'components/Settings/RolesAndPermissions/roles-config';
import { camelCase, startCase } from 'lodash';
import { deleteUsersFromRoles, getPrivilegesForRoles, getRolesForAccount } from 'services/roles';
import { createNewUser, getAllowedLead, updateUser } from 'services/users';
import { IPrivileges } from 'store/privilges/type';
import { Roles } from 'store/roles/types';
import { User, UserRole } from 'types/entities/user';

const t = {
  success_text: 'User successfully removed',
  error_text: 'Something went wrong',
};

const HALF_DAY = 12 * 60 * 60 * 1000;
const ONE_MINUTE = 60 * 1000;
export function useRolesAndPermissionsQuery() {
  return useQuery({
    queryKey: ['roles'],
    queryFn: () => getRolesForAccount(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
    retry: false,
  });
}

export function useUserDeletionQuery(roleId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: number) => {
      return deleteUsersFromRoles(userId, roleId);
    },
    retry: false,
    onSuccess: (_, userId: number) => {
      const currentData = queryClient.getQueriesData<Roles[]>(['roles']);

      if (!currentData.length) return;
      const [queryKey, queryData] = currentData[0];
      if (!queryData) return;
      const newData = queryData.map((data) => {
        if (data.id === roleId) {
          const modified = data.users.filter((user) => user.id !== userId);

          return { ...data, users: modified };
        }
        return data;
      });
      queryClient.setQueryData(queryKey, newData);

      notify.success(t.success_text);
    },
    onError: () => {
      notify.error(t.error_text);
    },
  }).mutateAsync;
}

export function useReportingManagersQuery(role: UserRole, userId?: number) {
  return useQuery({
    queryKey: ['allowed-leads', userId, role],
    queryFn: () => {
      return getAllowedLead(role, userId);
    },
    cacheTime: ONE_MINUTE,
    staleTime: ONE_MINUTE,
    retry: false,
    enabled: Boolean(role) && Boolean(role.length),
  });
}

async function handleGetPrivileges(roleId: number) {
  const privileges = await getPrivilegesForRoles(roleId);

  const rolesAndPrivilegeTree = new Map<
    EPages,
    { key: EPages; value: IPrivileges[]; label: string }
  >();
  const otherRolesAndPrivileges = new Map<
    EPages,
    { key: EPages; value: IPrivileges[]; label: string }
  >();

  for (const key of Object.keys(sideMenuLabel) as EPages[]) {
    rolesAndPrivilegeTree.set(key, { key, value: [], label: sideMenuLabel[key] });
  }

  for (const privilege of privileges) {
    if (privilege.module) {
      for (const module of privilege.module) {
        const existingPrivilege = rolesAndPrivilegeTree.get(module);
        if (existingPrivilege) {
          existingPrivilege.value.push(privilege);
        } else {
          let existingOtherPrivileges = otherRolesAndPrivileges.get(module);
          if (!existingOtherPrivileges) {
            existingOtherPrivileges = {
              key: module,
              value: [],
              label: startCase(camelCase(module)),
            };
            otherRolesAndPrivileges.set(module, existingOtherPrivileges);
          }
          existingOtherPrivileges.value.push(privilege);
        }
      }
    }
  }
  return { rolesAndPrivilegeTree, otherRolesAndPrivileges };
}

export function useUserPrivilegesQuery(roleId: number) {
  return useQuery({
    queryKey: ['privileges', roleId.toString()],
    queryFn: () => handleGetPrivileges(roleId),
    cacheTime: 0,
  });
}

export function useCreateUserMutation() {
  return useMutation({
    mutationFn: (data: User) => createNewUser(data),
    retry: false,
  });
}

export function useUpdateExisitingUser() {
  return useMutation({
    mutationFn: ({ id, data }: { id: number; data: User }) => updateUser(id, data),
    retry: false,
  });
}
