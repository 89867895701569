import { FormInstance } from 'antd';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { Dayjs } from 'dayjs';
import { Forms } from 'rc-field-form/lib/FormContext';
import { UploadFileTypes } from 'services/attachments';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { WorkFlowEventTypes, WorkflowMetaDto } from 'types/entities/workflow';
import { EVENT_TRIGGER_CONDITION_KEYS } from '../WorkflowCardList/type';
import { WorkFlowInvoiceEventFilterType } from '../utils';
import { ActionActivityTypes, ActionTypes, DataObject, GeneratedOption } from './Action/types';
import { ScheduleOptionType } from './SchedulingComponent/type';
import { BaseWorkFlowFilter } from './Trigger/EventCondition/type';
import { WorkFlowTriggerFormResponse } from './Trigger/type';

export const TriggerType = ['LIVE', 'PERIODIC'] as const;
export type EventType = (typeof TriggerType)[number];

export type WorkFlowConditionOptionsType = {
  label: string;
  key: WorkFlowInvoiceEventFilterType;
  value: WorkFlowInvoiceEventFilterType;
};
export type WorkFlowEventOptionsType = {
  label: string;
  key: WorkFlowInvoiceEventFilterType;
  value: WorkFlowInvoiceEventFilterType;
};

export interface WorkflowActionRule<T extends ActionTypes> {
  id?: number | string;
  actionable_entity?: ActionableEntity.CUSTOMER | ActionableEntity.INVOICE;
  action: T;
  deleted?: boolean;
  actionId?: number | string;
  type?: ActionActivityTypes;
  parent_id?: string | number;
  rule_id?: string | number;
  isNested?: boolean;
  status?: string;
}

export interface ConstructActionRule {
  id?: number;
  actionable_entity: ActionableEntity.CUSTOMER | ActionableEntity.INVOICE;
  deleted?: boolean;
  actionId?: number;
}

export const WorkFlowFilterOperator = [
  'GOE',
  'LOE',
  'EQ',
  'BTWN',
  'IN',
  'GT',
  'LT',
  'ANY',
  'CHG',
] as const;
export type WorkFlowOperatorTypes = (typeof WorkFlowFilterOperator)[number];
export type BasicWorkFlowOperatorTypes = Exclude<WorkFlowOperatorTypes, 'GT' | 'LT'>;

export type DateWorkFlowOperatorTypes = Exclude<
  WorkFlowOperatorTypes,
  'GOE' | 'LOE' | 'IN' | 'ANY' | 'CHG'
>;

export const WorkFlowCreditContactOperator = ['ANY', 'IN'] as const;
export type WorkFlowCreditContactOperatorTypes = (typeof WorkFlowCreditContactOperator)[number];

export const WorkFlowFollowupOperatorType = ['AND', 'OR'] as const;
export type WorkFlowOperator = (typeof WorkFlowFollowupOperatorType)[number];

export interface WorkFlowConditions {
  type?: string;
  conditions: BaseWorkFlowFilter[];
  operator: WorkFlowOperator;
  id?: number;
}
export interface WorkFlowRule {
  id: number | string;
  condition: WorkFlowConditions;
  actions: WorkflowActionRule<ActionTypes>[];
  trigger_type: EventType;
  event_type?: WorkFlowEventTypes;
  entity_type: EVENT_TRIGGER_CONDITION_KEYS;
  updated_at?: string;
}

export interface CustomWorkFlowFormProps {
  workflowMeta?: WorkflowMetaDto;
  isLoading?: boolean;
  isSuccess?: boolean;
}

export interface ActionFormFields {
  actions: WorkflowActionRule<ActionTypes>[];
  emailData: Partial<EmailActionData>;
}

export interface HeaderProps {
  editTitle: boolean;
  handleEditTitlechange: (value: boolean) => void;
  isEnabled: ValidationProps;
  form: FormInstance;
  modalToggleOpen: () => void;
  successCallBack: () => void;
  openModal?: boolean;
  id?: string;
  workflowEnable?: boolean;
  owner_id?: string | number;
  meta?: WorkflowMetaDto;
  loading?: boolean;
}

export interface SetFormDataSubmitProps {
  forms: Forms;
}

export interface SaveWorkflowFormProps extends SetFormDataSubmitProps {
  values: string;
  onSuccess: () => void;
}

export interface SetTriggerFormDataProps extends SetFormDataSubmitProps {
  values: WorkFlowTriggerFormResponse;
  actionReset: () => void;
}
export interface WorkFlowScheduleProps {
  workFlowStartDate?: Dayjs;
  workFlowEndDate?: Dayjs;
  scheduleType: ScheduleOptionType;
  workFlowStartNowEndDate?: Dayjs;
}
export interface SetScheduleFormDataProps extends SetFormDataSubmitProps {
  values: WorkFlowScheduleProps;
  onSuccess: () => void;
}

export interface SetActionFromDataProps extends SetFormDataSubmitProps {
  values: DataObject;
  emailFormData?: Partial<EmailActionData>;
  setActionRuleList: (data: WorkflowActionRule<ActionTypes>[]) => void;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
  mode?: 'UPDATE';
}

export interface ProcessFormDataProps {
  currentMeta: WorkflowMetaDto;
  triggerMeta?: Omit<WorkFlowRule, 'actions'>;
  actionDataResult?: WorkflowActionRule<ActionTypes>[];
  entityUpdated?: boolean;
}

export enum WorkflowForms {
  ACTION_FORM = 'actionForm',
  TRIGGER_FORM = 'triggerForm',
  WORKFLOW_BASE_FORM = 'workFlowbaseForm',
  WORKFLOW_SCHEDULE_FORM = 'scheduleForm',
}

interface ValidationProps {
  condition: boolean;
  actions: boolean;
}

export interface SaveTriggerValidationProps {
  isEnabled: ValidationProps;
}

export interface UploadFileProps {
  file: File[];
  type: UploadFileTypes;
}

export interface constructActionProps {
  data: DataObject;
  actionId: string;
  actions: WorkflowActionRule<ActionTypes>[];
  type?: GeneratedOption;
  file_upload_ids: File[];
  uploadAttachments: (props: UploadFileProps) => Promise<number[] | undefined>;
  emailFormData?: Partial<EmailActionData>;
  isNewAction: boolean;
  parentId?: string;
  grandParentId?: string;
  workflowData?: WorkflowMetaDto;
  status?: string;
  ruleId?: string;
}
