import {
  BroadCastChannelEvent,
  BroadCastChannelType,
  BroadCastEventType,
} from 'lib/realtime/broadcast.type';
import { IS_PROD } from 'util/http-utils';
// import { UserItemForActivation } from '../components/Login/ActivateAccount/types';
import { logoutUser } from '../services/authentication';
import { logoutHandler } from '../store/authentication/authentication';
import { AppThunk, resetRootState } from '../store/store';
import { AuthenticationResponseState } from './../store/authentication/types';

export const logoutThunk = (): AppThunk<
  Promise<{ payload: AuthenticationResponseState; type: string }>
> => {
  return async (dispatch) => {
    try {
      const loggedOutResponse = await logoutUser();
      const dispatchReturnObj = dispatch(logoutHandler({ status: loggedOutResponse.status }));
      const signOutChannel = new BroadcastChannel(BroadCastChannelType.SIGNOUT_CHANNEL);
      const loogedOutEventType = BroadCastEventType.LOGGED_OUT;
      const payload: BroadCastChannelEvent = {
        channel: BroadCastChannelType.SIGNOUT_CHANNEL,
        event: loogedOutEventType,
      };
      signOutChannel.postMessage(payload);
      dispatch(resetRootState());

      // Force refresh; So url won't persist as redirect_uri on logout
      IS_PROD && window.location.replace('/');
      return dispatchReturnObj;
    } catch (err: any) {
      return dispatch(logoutHandler({ status: 500, message: err.message }));
    }
  };
};
