import { StatusTag } from '@sinecycle/growcomponents';
import { DateRangeOption } from 'components/BaseComponents/DateRangeSelect/types';
import { ParticipantRelation } from 'store/contacts/contacts';
import {
  InboxDefaultLabels,
  InboxEmailConversationType,
  InboxEmailMode,
  InboxEmailSearchParam,
} from 'types/api/inbox/email';
import { Attachment } from 'types/common/attachments';
import { ContactType } from 'types/entities/contact';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { UserBasic } from 'types/entities/user';
type StatusTagConfig = React.ComponentProps<typeof StatusTag>;

export enum EmailContactType {
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  SECONDARY_CONTACT = 'SECONDARY_CONTACT',
  OTHER_CONTACT = 'OTHER_CONTACT',
  PURCHASER = 'PURCHASER',
  PAYER = 'PAYER',
}

export enum EmailableType {
  CONTACT = 'CONTACT',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  AR_EMAIL = 'AR_EMAIL',
}

const EmailLabelsConfig: Record<InboxDefaultLabels, StatusTagConfig> = {
  [InboxDefaultLabels.COLLECTION_STRATEGY]: {
    text: 'Collection Strategy',
    style: {
      backgroundColor: 'var(--orange-1)',
      textColor: 'var(--gray-9)',
    },
  },
  [InboxDefaultLabels.INVOICE_DELIVERY]: {
    text: 'Invoice Delivery',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--gray-9)',
    },
  },
  [InboxDefaultLabels.MANUAL_FOLLOWUP]: {
    text: '',
    style: {
      backgroundColor: '',
      textColor: '',
    },
  },
};

export function getLabelConfig(label: InboxDefaultLabels) {
  return EmailLabelsConfig[label];
}

export enum EmailConversationParticipantType {
  TO = 'TO',
  FROM = 'FROM',
  CC = 'CC',
  BCC = 'BCC',
}

enum EmailStatusEnum {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  BOUNCED = 'BOUNCED',
  READ = 'READ',
}

interface EmailStakeHolderProps {
  id: number;
  email?: string;
  title: string;
  first_name: string;
  last_name?: string;
  participant_relation?: ParticipantRelation;
  label?: string;
}

interface EmailAccount extends EmailStakeHolderProps {
  type: EmailableType.ACCOUNT;
  last_name: string;
}

interface EmailContact extends EmailStakeHolderProps {
  contact_type: ContactType;
  type: EmailableType.CONTACT;
}

interface EmailUser extends EmailStakeHolderProps {
  phone: string;
  designation: string;
  photo_url: string;
  last_name: string;
  type: EmailableType.USER;
}

export type Emailable = EmailAccount | EmailContact | EmailUser;

interface EmailStatus {
  id: number;
  deleted: boolean;
  status: EmailStatusEnum;
}

export interface EmailConversationParticipant {
  emailable?: Emailable;
  type: EmailConversationParticipantType;
  first_name: string;
  last_name: string;
  email: string;
  delivered: boolean;
  opened: boolean;
  deleted: boolean;
  id: number;
  statuses: EmailStatus[];
  created_at: string;
  updated_at: string;
}
export interface EmailConversation {
  id: number;
  body: string;
  subject: string;
  customer: BaseCustomer;
  invoices: InvoiceBasicDetails[];
  from?: EmailConversationParticipant;
  to?: EmailConversationParticipant[];
  cc?: EmailConversationParticipant[];
  bcc?: EmailConversationParticipant[];
  opened: boolean;
  thread_id: number;
  reply_received: boolean;
  sent_at?: string;
  attachments: Attachment[];
  incoming: boolean;
  created_by?: UserBasic;
  label?: InboxDefaultLabels;
}
export interface EmailThread {
  seen: boolean;
  read?: boolean;
  thread_id: number;
  latest_conversation: EmailConversation;
  current_page?: number;
}

export interface EmailFilters {
  [InboxEmailSearchParam.fromDate]: string;
  [InboxEmailSearchParam.toDate]: string;
  [InboxEmailSearchParam.dateRange]: DateRangeOption;
  [InboxEmailSearchParam.page]: number;
  [InboxEmailSearchParam.pageSize]: number;
  [InboxEmailSearchParam.labels]?: InboxDefaultLabels;
  [InboxEmailSearchParam.emailType]?: InboxEmailConversationType;
  [InboxEmailSearchParam.emailMode]?: InboxEmailMode;
  customer_ids?: string[];
  is_unread?: boolean;
  has_attachments?: boolean;
  is_my_inbox?: boolean;
  invoice_ids?: string[];
  ar_email_id?: string;
}
