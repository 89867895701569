import { Task } from 'types/activities/task';
import { DetailsLayout } from '../Common/Details/DetailsLayout';
import { TaskDetailsProps } from './TaskDetails';
import { TaskDetailsBody } from './TaskDetailsBody';
import { TaskDetailsInfo } from './TaskDetailsInfo';
import { ActivityDetailsLayoutProps } from 'types/activities/activity-types';

function TaskDetailsLayout(props: ActivityDetailsLayoutProps<Task>) {
  const {
    customer,
    hideActions,
    queryKey,
    mode,
    subscriptionType,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
    isLoading,
    data,
    isUnauthorized,
    isReadOnly,
    hideCopyLink,
  } = props;
  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <TaskDetailsBody
          data={data}
          isLoading={isLoading}
          customer={customer}
          hideActions={hideActions}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
          isReadOnly={isReadOnly}
          hideCopyLink={hideCopyLink}
        />
      }
      info={
        <TaskDetailsInfo
          loading={isLoading}
          data={data}
          queryKey={queryKey}
          customer={customer}
          activitiesMode={activitiesMode}
          isReadOnly={isReadOnly}
        />
      }
    />
  );
}

export { TaskDetailsLayout };
