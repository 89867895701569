import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import GrowPopover from 'components/BaseComponents/PopOver';
import { GrowText } from 'components/BaseComponents/Typography';
import { usePrivilegeStatus } from 'components/HigherOrderComponent/Privileged';
import { PageLink } from 'lib/router';
import { useSelector } from 'react-redux';
import { pagePrivileges } from 'router/constants/page-info';
import { strategyV2EnabledSelector } from 'store/authentication/authentication';
const t = {
  setFollowup: 'Set a collection followup...',
  clearStrategy: 'Clear Strategy',
  warningTitle: 'Inactive Strategy',
  wariningDesc1: 'The assigned collection strategy is disabled.',
  warningDesc2: 'Please enable this strategy to run.',
  enableStrategy: 'Enable Strategy',
  contactAdmin: 'Please contact admin to enable this strategy.',
};
const WarningDesc1 = (
  <GrowText color="var(--gray-9)" size="var(--fs-14)">
    {t.wariningDesc1}
  </GrowText>
);
const WariningTitle = (
  <GrowText color="var(--orange-6)" strong size={'var(--fs-14)'}>
    {t.warningTitle}
  </GrowText>
);
const HasPrevilageText = (
  <GrowText color="var(--gray-9)" size="var(--fs-14)">
    {t.warningDesc2}
  </GrowText>
);
const HasNoPrevilageText = (
  <GrowText color="var(--gray-9)" size="var(--fs-14)">
    {t.contactAdmin}
  </GrowText>
);
const ExternalLinkIcon = (
  <FontAwesomeIcon icon={['far', 'external-link']} color="var(--primary-7)" />
);
const StrategyLink = (
  <Flex gap="var(--space-4)" align="center">
    <GrowText color="var(--primary-7)">{t.enableStrategy}</GrowText> {ExternalLinkIcon}
  </Flex>
);
export default function StrategyWarningIcon(props: { strategyId?: number }) {
  const strategyV2Enabled = useSelector(strategyV2EnabledSelector);
  const hasStrategy = usePrivilegeStatus(
    strategyV2Enabled
      ? pagePrivileges('COLLECTION_STRATEGIES')
      : pagePrivileges('COLLECTION_FOLLOWUPS'),
    {
      shallowCheck: true,
    }
  );
  const StrategyPageLink = (
    <>
      {hasStrategy && props.strategyId ? (
        <PageLink
          appPage="COLLECTION_STRATEGY"
          target="_blank"
          pathParams={{ id: props.strategyId }}
        >
          <Button style={{ width: 'fit-content' }}>{StrategyLink}</Button>
        </PageLink>
      ) : (
        <></>
      )}
    </>
  );
  const WarningDesc = (
    <>
      {WarningDesc1}
      {hasStrategy ? HasPrevilageText : HasNoPrevilageText}
      {StrategyPageLink}
    </>
  );
  const WariningTootlTipTitle = (
    <Flex gap="var(--space-8)" direction="column" style={{ width: '232px' }}>
      {WariningTitle}
      {WarningDesc}
    </Flex>
  );
  const WarningIcon = (
    <GrowPopover content={WariningTootlTipTitle} arrow={false}>
      <FontAwesomeIcon color="var(--orange-6)" icon={['far', 'exclamation-circle']} />
    </GrowPopover>
  );

  return <>{WarningIcon}</>;
}
