import {
  CellRenderProps,
  defaultCellComponents,
  GrowFlex,
  GrowTypography,
} from '@sinecycle/growcomponents';
import { Popover } from 'antd';
import { match } from 'ts-pattern';
import { DateText } from '../Date/DateText';
import { AmountColumnProps } from './AmountCell';
import { cellComponents, CellComponentsKeys } from './cellComponents';
import { CustomColumnProps } from './CustomColumn';
import { ExtraInfo } from './ExtraInfo';
import { NumberCell } from './NumberCell';
type CellWithExtraInfoValues<T> = {
  cellValue: T;
  extraValue?:
    | { type: 'date'; value: string; title?: string }
    | { type: 'text'; value: string; title?: string }
    | { type: 'number'; value: number; title?: string };
};

type CellValueType<T> = AmountColumnProps & number & CustomColumnProps<T>;
interface CellWithExtraInfoProps<T>
  extends CellRenderProps<T, CellWithExtraInfoValues<CellValueType<T>>> {
  componentKey: Exclude<
    CellComponentsKeys,
    'cellWithExtraInfo' | 'tags' | 'subsidiary' | 'multiCcell' | 'textWithLinkCell' | 'dateWithTime'
  >;
}

function CellWithExtraInfo<T>(props: CellWithExtraInfoProps<T>) {
  function getComponent() {
    if (props.componentKey) {
      const CellComponent = cellComponents[props.componentKey];
      return CellComponent({ ...props, value: props.value.cellValue, fieldData: props.fieldData });
    } else {
      return defaultCellComponents.textCell({ ...props, value: props.value.cellValue });
    }
  }

  const extraValue = props.value.extraValue?.value;
  function getExtraComponent() {
    return match(props.value?.extraValue)
      .with({ type: 'date' }, (dateValue) => {
        return (
          <DateText
            {...props}
            value={dateValue.value}
            textProps={{ style: { color: 'var(--gray-8)' } }}
          />
        );
      })
      .with({ type: 'number' }, (numberValue) => {
        return (
          <NumberCell
            {...props}
            value={numberValue.value}
            textProps={{ style: { color: 'var(--gray-8)' } }}
          />
        );
      })
      .with({ type: 'text' }, (textValue) => {
        return defaultCellComponents.textCell({ ...props, value: textValue.value });
      })
      .otherwise(() => null);
  }

  const Component = getComponent();
  const ExtraComponent = getExtraComponent();
  const PopOverContent = (
    <GrowFlex vertical>
      {props.value?.extraValue?.title ? (
        <GrowTypography.Text>{props.value.extraValue.title}</GrowTypography.Text>
      ) : null}
      {ExtraComponent}
    </GrowFlex>
  );

  return (
    <Popover content={extraValue ? PopOverContent : undefined} placement="right">
      <div>{extraValue ? <ExtraInfo value={Component} /> : Component}</div>
    </Popover>
  );
}

export { CellWithExtraInfo };
