import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { ActionTitleProps, PromiseToPayActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function PromiseToPayTitle(props: ActionTitleProps<PromiseToPayActionType>) {
  const { item } = props;

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.PROMISE_TO_PAY.title}
      icon={actionIcons.PROMISE_TO_PAY.icon}
      backgroundColor={actionIcons.PROMISE_TO_PAY.style.backgroundColor}
      title={`PTP ${
        !item?.due_date_offset ? 'on creation date' : `After ${item?.due_date_offset} days`
      }`}
      strong={props.strong}
    />
  );
}
