import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WorkFlowOffsetType } from '../../../EventCondition/operator';
import { TriggerDisplayTexts } from '../DisplayOperand';

interface InvoiceStatusBTWNProps {
  offsetValue: number | null;
  offsetType: WorkFlowOffsetType;
  color: string;
  strong?: boolean;
}
export default function DisplayRelativeValue(props: InvoiceStatusBTWNProps) {
  return (
    <Flex gap="var(--space-4)">
      <HideWrapper hide={props.offsetType === 'ON'}>
        <GrowText color={props.color} strong={props.strong}>
          {props?.offsetValue} {TriggerDisplayTexts.daysOf}
        </GrowText>
      </HideWrapper>
      <GrowText color={props.color} strong={props.strong}>
        {TriggerDisplayTexts.currentDate}
      </GrowText>
    </Flex>
  );
}
