import { useQueryClient } from '@tanstack/react-query';
import { CollectionStrategyActionType } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Action/types';
import { chain, flatMap } from 'lodash';
import { useGetCollectionStrategiesQuery } from 'queries/Customers/customers';
import { BaseCollectionStrategy } from 'types/entities/collection-strategy';

export default function useCollectionStrategiesData(currentData?: CollectionStrategyActionType) {
  const { data, isLoading } = useGetCollectionStrategiesQuery();

  const collectionStrategyOptions = chain(data)
    .groupBy((item) => (item.enabled ? 'Active' : 'Inactive'))
    .map((groupedOptions, key) => ({
      label: key,
      options: groupedOptions.map((item) => ({
        label: item.title,
        value: item.id,
        title: '',
      })),
    }))
    .sortBy((group) => group.label !== 'Active')
    .value();

  const optionsMap = new Map(
    flatMap(collectionStrategyOptions, 'options').map((option) => [option.value.toString(), option])
  );

  /**
   * adding isStrategyDeleted here since make can make use if it to check
   * if the strategy is deleted not. currently this key is used in workflow.
   */
  return {
    collectionStrategyOptions,
    isLoading,
    data,
    isStrategyDeleted: !optionsMap.get(currentData?.value),
  };
}
export function useGetCollectionStrategyInitialValue(strategyId?: number) {
  const queryClient = useQueryClient();
  const collectionStrategies = queryClient.getQueryData<BaseCollectionStrategy[]>([
    'collection-followup',
  ]);
  return collectionStrategies?.find((strategy) => strategy.id === strategyId)?.id;
}
