import { CreditMemoStatus } from 'components/CustomerDetails/Body/Statements/CreditAdjustmentsStatements';

export const creditMemoStatusOptions = [
  {
    label: 'Fully Applied',
    value: CreditMemoStatus.FULLY_APPLIED,
  },
  {
    label: 'Partially Applied',
    value: CreditMemoStatus.PARTIALLY_APPLIED,
  },
  {
    label: 'Unapplied',
    value: CreditMemoStatus.UNAPPLIED,
  },
  {
    label: 'Void',
    value: CreditMemoStatus.VOID,
  },
];
export const WorkFlowsCreateFilters = [
  'INVOICE_CREATE',
  'PAYEMENT_CREATE',
  'CREDIT_MEMO_CREATED',
  'INVOICE_UPDATE',
];
