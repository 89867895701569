import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select, SelectProps } from 'antd';
import { FormInstance } from 'antd/es/form';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import produce from 'immer';
import { head } from 'lodash';
import { useState } from 'react';
import { InvoiceStatus } from 'types/entities/invoice';
import { BetweenAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import { WorkFlowFilterOperator } from '../../EventCondition/type';
import { Range } from './type';
interface BetweenStatusProps {
  form: FormInstance<any>;
  options: SelectProps['options'];
  value?: WorkFlowFilterOperator<InvoiceStatus>;
  name?: number;
  onChange?: (updatedFilter: WorkFlowFilterOperator<InvoiceStatus>) => void;
}
const RightArrow = (
  <FontAwesomeIcon
    size="lg"
    icon={['fal', 'arrow-right']}
    style={{ color: 'var(--gray-9)', fontSize: 'var(--fs-14)' }}
  />
);
export default function BetweenStatus(props: BetweenStatusProps) {
  const [range, setRange] = useState<Range>(initializeBetweenValue);
  const statusToOptions = props?.options?.filter((option) => option.value !== range.from.value);
  const statusFromOptions = props?.options?.filter((option) => option.value !== range.to.value);
  function initializeBetweenValue(): Range {
    const from = (props?.value?.operands as BetweenAbsoluteOperands<InvoiceStatus>).value_holder
      .from.value;
    const to = (props?.value?.operands as BetweenAbsoluteOperands<InvoiceStatus>).value_holder.to
      .value;

    return {
      from: {
        value: from,
      },
      to: {
        value: to,
      },
    };
  }

  function handleFromChange(status: InvoiceStatus) {
    if (status) {
      const updatedFilter = produce(props.value, (draftFilter) => {
        const operandsValue = draftFilter?.operands as BetweenAbsoluteOperands<InvoiceStatus>;
        operandsValue.value_holder.from.value = status;
      });

      updatedFilter && props?.onChange?.(updatedFilter);
      setRange((prevRange) => ({
        ...prevRange,
        from: {
          ...prevRange.from,
          value: status,
        },
      }));
    }
  }

  function handleToChange(status: InvoiceStatus) {
    if (status) {
      const updatedFilter = produce(props.value, (draftFilter) => {
        const operandsValue = draftFilter?.operands as BetweenAbsoluteOperands<InvoiceStatus>;
        operandsValue.value_holder.to.value = status;
      });

      updatedFilter && props?.onChange?.(updatedFilter);

      setRange((prevRange) => ({
        ...prevRange,
        to: {
          ...prevRange.to,
          value: status,
        },
      }));
    }
  }
  return (
    <>
      <StyledFormItem shouldUpdate style={{ width: '120px' }}>
        <Select
          options={statusFromOptions}
          onChange={handleFromChange}
          showSearch
          style={{ width: '120px' }}
          value={range.from.value ?? head(props?.options)?.value}
        />
      </StyledFormItem>
      <Flex.Child alignSelf="center">
        <TypographyText style={{ color: 'var(--primary-10)' }}>{RightArrow}</TypographyText>
      </Flex.Child>
      <StyledFormItem shouldUpdate style={{ width: '140px' }}>
        {statusToOptions && (
          <Select
            options={statusToOptions}
            onChange={handleToChange}
            style={{ width: '120px' }}
            showSearch
            value={range.to.value ?? props?.options?.[1].value}
          />
        )}
      </StyledFormItem>
    </>
  );
}
