import { QueryKey } from '@tanstack/react-query';
import { Empty, Skeleton } from 'antd';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { ParentEmail } from '../ConversationBody/ParentEmail';
import { Replies } from '../ConversationBody/Replies';

interface ContentProps {
  loader?: boolean;
  data?: EmailThreadResponse;
  queryKey?: QueryKey;
  readOnly?: boolean;
}

function Content(props: ContentProps) {
  const { loader, data, queryKey, readOnly } = props;
  const Loader = (
    <Skeleton
      avatar
      loading={loader}
      paragraph={{ rows: 2 }}
      title={false}
      style={{ marginTop: 'var(--space-12)' }}
    >
      <Empty />
    </Skeleton>
  );

  const Comp = (
    <>
      {data && !loader ? (
        <ParentEmail
          conversation={data.parent_email}
          defaultOpen={!data.replies.length}
          data={data}
          queryKey={queryKey}
          readOnly={readOnly}
        />
      ) : (
        <Skeleton avatar loading={loader} paragraph={{ rows: 2 }} title={false} />
      )}
      {data && !loader ? (
        <Replies replies={data.replies} data={data} queryKey={queryKey} readOnly={readOnly} />
      ) : (
        <>
          {Loader}
          {Loader}
          {Loader}
          {Loader}
          {Loader}
        </>
      )}
    </>
  );

  return <div className="content-wrapper">{Comp}</div>;
}

export { Content };
