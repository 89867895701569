import { CellRenderProps, SortOrder } from '@sinecycle/growcomponents';
import { GenericRadioOptions } from 'components/BaseComponents/Radio/Antd/type';
import { ActivityKeysPayload } from 'components/Common/ActivitiesModal/type';
import { ContextInitialState } from 'pages/CollectionActivities/types';
import { AssignedToUsers } from 'store/activity-feed/type';
import { CustomFieldOptions } from 'types/entities/custom-field';
import { BaseCustomer } from 'types/entities/customer';
import { DisputeType } from 'types/entities/dispute-type';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { UserBasic } from 'types/entities/user';
import { CustomerContact } from 'types/page-filter';
import { MultiCurrencySplit, SummaryData } from './../../../types/common/multi-currency';
import { DateRangeOption } from './../../BaseComponents/DateRangeSelect/types';
import { disputeReducerPredicates } from './predicate';
export const DisputeViewsStatus = ['OPEN', 'WAITING_ON_CUSTOMER', 'CLOSED'];
export type DisputeViews = 'ALL' | 'OPEN' | 'WAITING_ON_CUSTOMER' | 'CLOSED';
type DisputeSummaryKeys = 'total_amount';
export type DisputeStatus = Exclude<DisputeViews, 'ALL'>;
type DisputeFiltersValues = {
  view: DisputeViews;
  sort_by: SortOrder;
  sort_col: string;
  participant_ids: number[];
  from_date: string;
  to_date: string;
  from_amount: string;
  to_amount: string;
  from_age: string;
  to_age: string;
  user_association: CustomerContact[];
  custom_fields: CustomFieldOptions[];
  dispute_date_range: DateRangeOption;
  dispute_age_range: string;
  dispute_type_ids: number[];
  customer_ids: number[];
};

export type DisputeStatusConfig = Record<
  DisputeStatus,
  {
    label: string;
    style: {
      backgroundColor: string;
      textColor: string;
    };
  }
>;

export type DisputeFilters = Partial<DisputeFiltersValues>;

export type DisputeFilterViews = {
  id: string;
  name: string;
  system_defined: boolean;
  description?: string;
  disputes_report_filter: DisputeFilters;
};

export type DisputeList = {
  id: string;
  title: string;
  customer: BaseCustomer;
  dispute_date: string;
  types: DisputeType[];
  dispute_days: number;
  status: DisputeStatus;
  invoices: InvoiceBasicDetails[];
  participants: UserBasic[];
  participant_config?: AssignedToUsers[];
  closed_date: string;
  dispute_no: string;
  amount: MultiCurrencySplit;
};

export type DisputeSummary = {
  [key in DisputeSummaryKeys]: SummaryData;
};

export type DisputeReport = {
  summary: DisputeSummary;
  list: DisputeList[];
  current_page: number;
  total_pages: number;
  total_records: number;
};

type Action<T extends keyof typeof disputeReducerPredicates> = Parameters<
  (typeof disputeReducerPredicates)[T]
>['1'];

export type DisputeActions =
  | {
      type: 'updateCurrentView';
      payload: Action<'updateCurrentView'>;
    }
  | {
      type: 'updateSwitcherView';
      payload: Action<'updateSwitcherView'>;
    }
  | {
      type: 'updateCurrentFilter';
      payload: Action<'updateCurrentFilter'>;
    }
  | {
      type: 'updateSortingCurrentFilter';
      payload: Action<'updateSortingCurrentFilter'>;
    }
  | {
      type: 'updateDetail';
      payload: Action<'updateDetail'>;
    }
  | {
      type: 'updateFilterViewSave';
      payload: Action<'updateFilterViewSave'>;
    }
  | {
      type: 'updateFilterViewSaveAs';
      payload: Action<'updateFilterViewSaveAs'>;
    }
  | {
      type: 'deleteFilterViews';
      payload: Action<'deleteFilterViews'>;
    }
  | {
      type: 'updateSelectAll';
      payload: Action<'updateSelectAll'>;
    }
  | {
      type: 'resetState';
    }
  | {
      type: 'updateCurrentPage';
      payload: Action<'updateCurrentPage'>;
    }
  | {
      type: 'getFilterViews';
      payload: Action<'getFilterViews'>;
    }
  | {
      type: 'updateRecords';
      payload: Action<'updateRecords'>;
    }
  | {
      type: 'updateSelectedRows';
      payload: Action<'updateSelectedRows'>;
    }
  | {
      type: 'updateEdiMode';
      payload: Action<'updateEdiMode'>;
    }
  | {
      type: 'updateDeleteRows';
      payload: Action<'updateDeleteRows'>;
    }
  | {
      type: 'resetSelection';
    }
  | {
      type: 'initializeSummary';
      payload: Action<'initializeSummary'>;
    }
  | {
      type: 'updateTableState';
      payload: Action<'updateTableState'>;
    };

type DisputeContextValues = {
  state: ContextInitialState<DisputeFilterViews, DisputeList, DisputeList>;
  dispatch: React.Dispatch<DisputeActions>;
};

export type DisputeContext = DisputeContextValues | null;

export type DisputeRadioOptions = GenericRadioOptions<DisputeViews>;

export type DisputeCellRenderer = CellRenderProps<DisputeList>;
export type DisputeStatusOptions = GenericRadioOptions<DisputeStatus>;

export interface disputeListConfigInterface {
  page?: number;
  size?: number;
  filters?: DisputeFilters;
  isExport?: boolean;
}

export enum DisputesQuickActions {
  COMMENT = 'COMMENT',
  CHANGE_DISPUTE_OWNER = 'CHANGE_DISPUTE_OWNER',
  CHANGE_DISPUTE_STATUS = 'CHANGE_DISPUTE_STATUS',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum DisputesEventTypes {
  SET_QUICK_ACTION = 'SET_QUICK_ACTION',
  SET_BULK_ACTION = 'SET_BULK_ACTION',
  TRIGGER_SORT_ACTION = 'TRIGGER_SORT_ACTION',
}

export interface ActivitiesDisputePayload {
  activityKeys: ActivityKeysPayload;
  activityData?: DisputeReport;
}
