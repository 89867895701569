import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { ValidateStatus } from 'types/entities/invoice-segments';
import {
  BetweenAbsoluteOperands,
  DefaultAbsoluteOperand,
} from 'types/entities/invoice-segments/operands';
import SearchableSelect from '../../../../../../Common/SearchableSelect/SearchableSelect';
import { DateWorkFlowOperatorTypes } from '../../../type';
import { DateWorkFlowBetweenOperator } from '../../EventCondition/operator';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';
import SingleDateFieldFilter from '../CommonFilterHelper/SingleDateFieldFilter';
import { WorkFlowOperationOptions } from '../predicate';
import BetweenIssueDate from './BetweenIssueDate';
import EqualsDateFilter from './EqualsDateFilter';

interface DateRangeFilterProps {
  value?: DateWorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<unknown>) => void;
  percision?: number;
  singleFieldMoreInfo?: string;
}
type DateOperand =
  | DefaultAbsoluteOperand<Dayjs | Dayjs[]>
  | BetweenAbsoluteOperands<Dayjs | Dayjs[]>;
export type DateFilterRange = {
  from: RangeDate;
  to: RangeDate;
};

export type RangeDate = {
  value: dayjs.Dayjs;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};
function validateDate(dateValue?: dayjs.Dayjs) {
  return dayjs(dateValue).isValid() ? dayjs(dateValue) : dayjs();
}
export function DateRangeFilter(props: DateRangeFilterProps) {
  const currentType = props?.value?.type;
  function handleOperatorChange(value: DateWorkFlowOperatorTypes) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        draftFilter.type = value;
        const dateOperand = draftFilter.operands as DateOperand;
        const dateRangeOperand = draftFilter.operands as DefaultAbsoluteOperand<Dayjs | Dayjs[]>;
        dateOperand.value_holder =
          value === 'BTWN'
            ? {
                from: {
                  value: dayjs().subtract(7, 'day').startOf('day'),
                },
                to: {
                  value: dayjs().endOf('day'),
                },
              }
            : {
                value: validateDate(dateRangeOperand?.value_holder.value as dayjs.Dayjs),
              };
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <SearchableSelect
      options={WorkFlowOperationOptions}
      onChange={handleOperatorChange}
      value={props?.value?.type ?? 'IN'}
    />
  );
  const FilterRenderer = (
    <Flex gap="--space-8" align="flex-start" style={{ alignSelf: 'center', flexWrap: 'wrap' }}>
      {Operators}
      <HideWrapper hide={currentType !== 'BTWN'}>
        <BetweenIssueDate
          value={props?.value as DateWorkFlowBetweenOperator<Dayjs>}
          onChange={props.onChange}
        />
      </HideWrapper>
      <HideWrapper hide={currentType !== 'EQ'}>
        <EqualsDateFilter
          value={props.value as DateWorkFlowFilterOperator<Dayjs>}
          onChange={props.onChange}
        />
      </HideWrapper>
      <HideWrapper hide={currentType === 'EQ' || currentType === 'BTWN'}>
        <StyledFormItem shouldUpdate>
          <SingleDateFieldFilter
            value={props.value as DateWorkFlowFilterOperator<Dayjs>}
            onChange={props.onChange}
          />
        </StyledFormItem>
      </HideWrapper>
    </Flex>
  );

  return (
    <Flex direction="column" gap="var(--space-8)" justify="center" align="center">
      {FilterRenderer}
    </Flex>
  );
}
const WorkFlowDateRangeFilter = () => <></>;

WorkFlowDateRangeFilter.FilterField = DateRangeFilter;

export { WorkFlowDateRangeFilter };
