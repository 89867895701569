import { DateRangeOption } from 'components/BaseComponents/DateRangeSelect/types';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import { CustomerInfoProps } from 'store/activity-feed/type';
import { ActivityType, TaskAssociatedActivityType } from 'types/activities/activity-types';
import { EscalationType } from 'types/activities/escalation';
import { PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { TaskStatus } from 'types/activities/task';
import { PaginationProps } from 'types/common/pagination';
import { SourceApp } from 'types/common/source-app';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { UserBasic } from 'types/entities/user';
import { PaginationSearchParams } from '../common';

export enum ActivityStatus {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
}
export enum InboxActivityMode {
  ASSIGNED = 'ASSIGNED',
  SUBSCRIBED = 'SUBSCRIBED',
}
export enum ActivitySubcriptionType {
  OWNER = 'OWNER',
  ASSIGNED = 'ASSIGNED',
  COMMENT_TAGGED = 'COMMENT_TAGGED',
  ESCALATION_TAGGED = 'ESCALATION_TAGGED',
  NOTE_TAGGED = 'NOTE_TAGGED',
  PTP_TAGGED = 'PTP_TAGGED',
  COMMENTED = 'COMMENTED',
}
export enum PerformedActionType {
  COMMENT_ADDED = 'COMMENT_ADDED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  TAGGED = 'TAGGED',
  ASSIGNEE_CHANGED = 'ASSIGNEE_CHANGED',
  DATA_UPDATED = 'DATA_UPDATED',
  DATE_CHANGED = 'DATE_CHANGED',
  CREATED = 'CREATED',
  REPLY = 'REPLY',
  SENT = 'SENT',
}

export type InboxActivityType =
  | ActivityType.DISPUTE
  | ActivityType.ESCALATION
  | ActivityType.TASK
  | ActivityType.CALL_LOG
  | ActivityType.NOTE
  | ActivityType.PAYMENT
  | ActivityType.WRITE_OFF
  | ActivityType.PROMISE_TO_PAY
  | ActivityType.CREDIT_MEMO;

enum InboxUISearchParams {
  activityId = 'activity_id',
  activityMode = 'activity_mode',
  activityType = 'activity_type',
  selectedEntities = 'selected_entities',
}

export enum InboxActivityStatusParams {
  disputeStatus = 'dispute_status',
  escalationStatus = 'escalation_status',
  ptpStatus = 'ptp_status',
  taskStatus = 'task_filter',
}
enum InboxRequestParams {
  page = 'page',
  pageSize = 'page_size',
  activityId = 'activity_id',
  entityType = 'entity_types',
  activityType = 'activity_type',
  status = 'status',
  fromDate = 'created_date_from',
  toDate = 'created_date_to',
  dateRange = 'created_date_range',
  read = 'read',
}
export const InboxActivitySearchParam = {
  ...InboxUISearchParams,
  ...InboxRequestParams,
  ...InboxActivityStatusParams,
} as const;

export interface ActivitiesStatusParams {
  [InboxActivitySearchParam.disputeStatus]?: Array<DisputeStatus>;
  [InboxActivitySearchParam.escalationStatus]?: Array<EscalationType>;
  [InboxActivitySearchParam.ptpStatus]?: Array<PromiseToPayStatus>;
  [InboxActivitySearchParam.taskStatus]?: Array<
    Extract<TaskStatus, TaskStatus.OPEN | TaskStatus.CLOSED>
  >;
}
export interface ActivitiesParams extends PaginationSearchParams {
  [InboxActivitySearchParam.entityType]?: InboxActivityType[];
  [InboxActivitySearchParam.activityId]?: number;
  [InboxActivitySearchParam.activityMode]?: InboxActivityMode;
  [InboxActivitySearchParam.status]?: ActivityStatus;
  [InboxActivitySearchParam.read]?: boolean;
  [InboxActivitySearchParam.fromDate]?: string;
  [InboxActivitySearchParam.toDate]?: string;
  [InboxActivitySearchParam.dateRange]?: DateRangeOption;
}

export type InboxActivityQueryParam = Pick<
  ActivitiesParams,
  InboxRequestParams.page | InboxRequestParams.pageSize
>;
interface BaseCardResponse {
  id: number;
}
interface EscalationCardResponse extends BaseCardResponse {
  status: EscalationType;
}
interface TaskCardResponse extends BaseCardResponse {
  status: TaskStatus;
  due_date: string;
  associated_activity_type: TaskAssociatedActivityType;
  associated_activity_id: number;
  due_days: number;
}
interface DisputeCardResponse extends BaseCardResponse {
  status: DisputeStatus;
  source_app: SourceApp;
}
interface PromiseToPayCardResponse extends BaseCardResponse {
  status: PromiseToPayStatus;
  source_app: SourceApp;
}
interface CallLogCardResponse extends BaseCardResponse {}
interface NoteCardResponse extends BaseCardResponse {}

interface PaymentCardResponse extends BaseCardResponse {
  mode?: string;
  currency: string;
  amount: number;
}
interface CreditMemoCardResponse extends BaseCardResponse {}
interface WriteOffCardResponse extends BaseCardResponse {}

export interface LatestActivity {
  type: PerformedActionType;
  actor: UserBasic;
  previous_value?: string | number;
  current_value?: string | number;
  createdDate?: string;
  showAvatar?: boolean;
}

type EntityCardResponse<T extends ActivityType> = T extends ActivityType.CALL_LOG
  ? CallLogCardResponse
  : T extends ActivityType.CREDIT_MEMO
  ? CreditMemoCardResponse
  : T extends ActivityType.DISPUTE
  ? DisputeCardResponse
  : T extends ActivityType.ESCALATION
  ? EscalationCardResponse
  : T extends ActivityType.NOTE
  ? NoteCardResponse
  : T extends ActivityType.PAYMENT
  ? PaymentCardResponse
  : T extends ActivityType.PROMISE_TO_PAY
  ? PromiseToPayCardResponse
  : T extends ActivityType.TASK
  ? TaskCardResponse
  : T extends ActivityType.WRITE_OFF
  ? WriteOffCardResponse
  : BaseCardResponse;

export type ActivityDetail<T extends ActivityType> = {
  title: string;
  description: string;
  read: boolean;
  user_activity_id: number;
  customer_info: CustomerInfoProps;
  customer: BaseCustomer;
  invoices: InvoiceBasicDetails[];
  comment_count: number;
  created_by: UserBasic;
  created_at: string;
  latest_activity: LatestActivity;
  subscription_type: ActivitySubcriptionType;
  entity_type: T;
  entity_detail: EntityCardResponse<T>;
  ptp_date?: string;
  ptp_amount?: string;
  currency?: string;
  amount?: number;
  updated_at?: string;
};

export type AssignedEntityType =
  | ActivityDetail<ActivityType.TASK>
  | ActivityDetail<ActivityType.DISPUTE>
  | ActivityDetail<ActivityType.ESCALATION>;

export type SubscribedEntityType =
  | ActivityDetail<ActivityType.TASK>
  | ActivityDetail<ActivityType.DISPUTE>
  | ActivityDetail<ActivityType.ESCALATION>
  | ActivityDetail<ActivityType.CALL_LOG>
  | ActivityDetail<ActivityType.CREDIT_MEMO>
  | ActivityDetail<ActivityType.NOTE>
  | ActivityDetail<ActivityType.PAYMENT>
  | ActivityDetail<ActivityType.PROMISE_TO_PAY>
  | ActivityDetail<ActivityType.WRITE_OFF>
  | ActivityDetail<ActivityType.EMAIL>;
``;
export interface AssignedActivities extends PaginationProps {
  unread_count: number;
  data: AssignedEntityType[];
}

export interface SubscribedActivities extends PaginationProps {
  unread_count: number;
  data: SubscribedEntityType[];
}

export interface InboxActivitiesFilter {
  assignmentTypes: InboxActivityType[];
  assignmentStatus: ActivityStatus;
}

export interface InboxActivitiesFilters extends ActivitiesParams, ActivitiesStatusParams {}

export type ActivityStatusConfig = Record<ActivityType, InboxActivityStatusParams>;
