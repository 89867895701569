import { Select, SelectProps } from 'antd';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { triggerEventOptions } from 'components/Settings/WorkFlowAutomation/common/WorkFlowStatusPredicate';
import { WorkFlowTexts } from '../../text';

export function TriggerEventTypeField(props: {
  entityValue?: EVENT_TRIGGER_CONDITION_KEYS;
  onChange: SelectProps['onChange'];
}) {
  return (
    <StyledFormItem
      name="entity"
      rules={[
        {
          required: true,
          message: WorkFlowTexts.entityError,
        },
      ]}
      initialValue={props.entityValue}
    >
      <Select
        options={triggerEventOptions as SelectProps['options']}
        style={{ width: '260px' }}
        placeholder={WorkFlowTexts.entityPlaceHolder}
        onChange={props.onChange}
      />
    </StyledFormItem>
  );
}
