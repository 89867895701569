import { Popover } from 'antd';
interface StatusPopoverProps {
  title: React.ReactNode;
  content: React.ReactNode;
  children: React.ReactNode;
}
function StatusPopover(props: StatusPopoverProps) {
  const { title, content, children } = props;
  return (
    <Popover title={title} placement="left" content={content}>
      <div>{children}</div>
    </Popover>
  );
}

export default StatusPopover;
