import { dateFormats } from '@sinecycle/growcomponents';
import { removeEmailContentClass } from 'components/BaseComponents/RTE/Toolbar/common/nodes/EmailContent/EmailContent';
import { EEmailParams } from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import { removeClassFromHtml } from 'lib/RichText/TipTapEditor/utils/adapters';
import { Emailable, EmailConversation } from 'types/activities/email';
import { EmailThreadResponse } from 'types/api/inbox/email';
import { EmailFormType } from './type';

export const defaultNewEmailValues = {
  [EEmailParams.to]: [] as Emailable[],
  [EEmailParams.from]: {} as Emailable,
  [EEmailParams.cc]: [] as Emailable[],
  [EEmailParams.bcc]: [] as Emailable[],
  [EEmailParams.subject]: '',
  [EEmailParams.body]: '',
};

function isNotCurrentUser(recipient: Emailable, currentUserEmail?: string) {
  return recipient.email !== currentUserEmail;
}

function getToRecipients(
  emailConversation: EmailConversation,
  currentUserEmail?: string
): Emailable[] {
  const to: Emailable[] = [];
  if (emailConversation?.to?.length) {
    emailConversation.to.forEach((toEntry) => {
      if (toEntry.emailable && isNotCurrentUser(toEntry.emailable, currentUserEmail)) {
        to.push(toEntry.emailable);
      }
    });
  }

  return to;
}

function getToBasedOnEmailConversationType(
  emailConversation: EmailConversation,
  currentUserEmail?: string
): Emailable[] {
  if (!emailConversation?.from?.emailable) return [];
  return emailConversation?.from?.emailable &&
    isNotCurrentUser(emailConversation.from.emailable, currentUserEmail)
    ? [emailConversation.from.emailable]
    : getToRecipients(emailConversation, currentUserEmail);
}

function getCcBasedOnEmailActionType(
  emailConversation: EmailConversation,
  currentUserEmail?: string
) {
  const cc: Emailable[] = [];

  if (
    emailConversation?.from?.emailable &&
    isNotCurrentUser(emailConversation.from.emailable, currentUserEmail)
  ) {
    emailConversation?.cc?.forEach((ccEntry) => {
      if (ccEntry.emailable && isNotCurrentUser(ccEntry.emailable, currentUserEmail)) {
        cc.push(ccEntry.emailable);
      }
    });

    emailConversation?.to?.forEach((toEntry) => {
      if (
        toEntry.emailable &&
        isNotCurrentUser(toEntry.emailable, currentUserEmail) &&
        toEntry.email !== emailConversation.from?.email
      ) {
        cc.push(toEntry.emailable);
      }
    });
  } else {
    emailConversation?.cc?.forEach((ccEntry) => {
      if (ccEntry.emailable && isNotCurrentUser(ccEntry.emailable, currentUserEmail)) {
        cc.push(ccEntry.emailable);
      }
    });
  }

  return cc;
}

// This is to preset the to address for reply / forward email form
export function generateToRecipients(
  formType: EmailFormType,
  emailThread: EmailThreadResponse | undefined,
  currentThreadId: number,
  currentUserEmail?: string
): Emailable[] {
  if (!emailThread) return [];

  if (!emailThread || (formType && formType === EmailFormType.FORWARD)) return [];
  if (emailThread.replies.length) {
    const current = emailThread.replies.find((thread) => thread.id === currentThreadId);
    if (current) {
      return getToBasedOnEmailConversationType(current, currentUserEmail);
    }
  }
  return getToBasedOnEmailConversationType(emailThread.parent_email, currentUserEmail);
}

export function generateCcRecipients(
  formType: EmailFormType,
  emailThread: EmailThreadResponse | null,
  currentThreadId: number,
  currentUserEmail?: string
): Emailable[] {
  if (!emailThread || (formType && formType === EmailFormType.FORWARD)) return [];

  if (formType === EmailFormType.REPLY_ALL) {
    if (emailThread.replies.length) {
      const current = emailThread.replies.find((thread) => thread.id === currentThreadId);
      if (current) {
        return getCcBasedOnEmailActionType(current, currentUserEmail);
      }
    }
    return getCcBasedOnEmailActionType(emailThread.parent_email, currentUserEmail);
  }
  return [];
}

export function getSubjectFor(formType: EmailFormType, emailThread: EmailThreadResponse | null) {
  if (!emailThread) return '';
  return formType === EmailFormType.FORWARD
    ? `Fwd: ${emailThread.parent_email.subject}`
    : `Re: ${emailThread.parent_email.subject}`;
}

function getContentBody(htmlString: string) {
  return `${removeClassFromHtml(htmlString, 'grow-email-placeholder')}`;
}

function getForwardBodyTemplate(content: EmailConversation, format: string) {
  return `<p class="forward-content">
   -----<strong>Forwarded message</strong>----
<p style="color: var(--gray-7)">
   From: ${content.from?.first_name} ${content.from?.last_name ?? ''}
   <span style="color: var(--gray-7)">&lt;${content.from?.email}&gt;</span>
</p>
<p style="color: var(--gray-7)">Date: ${dateFormats.date(
    content.sent_at,
    format
  )} at ${dateFormats.time(content.sent_at)} </p>
<p style="color: var(--gray-7)">Subject:${content.subject}</p>
<p style="color: var(--gray-7)">
   To: ${content.to?.map((f) => {
     return `&lt;${f.email}&gt`;
   })}
</p>
<p></p>
<p></p>
<div class="forward-body">${getContentBody(removeEmailContentClass(content.body))}</div>
</p>

`;
}

function getReplyBodyTemplate(content: EmailConversation, format: string) {
  return `<div class="gf-quote">
   <p style="color: var(--gray-7)" >
      On ${dateFormats.date(content.sent_at, format)} at ${dateFormats.time(content.sent_at)} ${
    content.from?.first_name
  }${content.from?.last_name}&lt;${content.from?.email}&gt; wrote:
   </p>
   <blockquote>${getContentBody(content.body)}</blockquote>
</div>
`;
}

function getBodyEmailContent(content: EmailConversation, formType: EmailFormType, format: string) {
  return formType === EmailFormType.FORWARD
    ? getForwardBodyTemplate(content, format)
    : `<p></p>
      <p></p>
      <div class='reply-email'>${getReplyBodyTemplate(content, format)}</div>
`;
}

export function getBodyFor(
  formType: EmailFormType | undefined,
  emailThread: EmailThreadResponse | undefined,
  currentThreadId: number,
  format: string
) {
  if (!emailThread || !formType) return '';

  if (!emailThread.replies.length)
    return getBodyEmailContent(emailThread.parent_email, formType, format);
  const currentThread = emailThread.replies.find((f) => f.id === currentThreadId);

  if (currentThread) {
    return `${getBodyEmailContent(currentThread, formType, format)} `;
  }
}
