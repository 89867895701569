import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { FilterConfigWrapperProps } from 'components/Common/FilterComponents/FilterWrapper/type';
import { SelectOption } from 'components/Common/FilterComponents/types';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import {
  EVENT_TRIGGER_CONDITION_KEYS,
  EntityTypes,
  EventEntityTypesConfig,
  WorkFlowStatus,
  WorkFlowStatusConfig,
  customWorkFlowStatus,
} from '../WorkflowCardList/type';
import { SelectOptionIcon } from './style';

export const workFlowStatusConfig: Record<EVENT_TRIGGER_CONDITION_KEYS, WorkFlowStatusConfig> = {
  INVOICE: {
    label: 'Invoice',
    value: 'INVOICE',
    style: {
      backgroundColor: 'var(--geekblue-1)',
      color: 'var(--geekblue-6)',
    },
    icon: <FontAwesomeIcon color="var(--geekblue-5)" icon={['far', 'file-lines']} />,
    eventTypes: EventEntityTypesConfig['INVOICE'],
  },

  // PAYMENT: {
  //   label: 'Payment',
  //   value: 'PAYMENT',
  //   style: {
  //     backgroundColor: 'var(--green-1)',
  //     color: 'var(--green-8)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','credit-card']} color="var(--green-8)" />,
  //   eventTypes: EventEntityTypesConfig['PAYMENT'],
  // },
  // CREDIT_MEMO: {
  //   label: 'Credit Memo',
  //   value: 'CREDIT_MEMO',
  //   style: {
  //     backgroundColor: 'var(--lime-1)',
  //     color: 'var(--green-6)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','coin']} color="var(--green-6)" />,
  //   eventTypes: EventEntityTypesConfig['CREDIT_MEMO'],
  // },

  //for future use commenting for now
  // CUSTOMER: {
  //   label: 'Customer Added',
  //   value: EVENT_TRIGGER_CONDITION_KEYS.CUSTOMER,
  //   style: {
  //     backgroundColor: 'var(--cyan-7)',
  //     textColor: 'var(--cyan-8)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','briefcase']} color="var(--cyan-7)" />,
  // },
  // PROMISE_TO_PAY: {
  //   label: 'P2P created',
  //   value: EVENT_TRIGGER_CONDITION_KEYS.PROMISE_TO_PAY,
  //   style: {
  //     backgroundColor: 'var(--lime-1)',
  //     color: 'var(--green-6)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','coin']} color="var(--green-6)" />,
  //   eventTypes: EventEntityTypesConfig['CREDIT_MEMO'],
  // },
  // DISPUTE: {
  //   label: 'Dispute',
  //   value: 'DISPUTE',
  //   style: {
  //     backgroundColor: 'var(--volcano-1)',
  //     color: 'var(--volcano-6)',
  //   },
  //   icon: <FontAwesomeIcon color="var(--volcano-6)" icon={['far','comment-times']} />,
  //   eventTypes: EventEntityTypesConfig['DISPUTE'],
  // },
  // TASK: {
  //   label: 'Task',
  //   value: 'TASK',
  //   style: {
  //     backgroundColor: 'var(--magenta-1)',
  //     color: 'var(--magenta-5)',
  //   },
  //   icon: <FontAwesomeIcon color="var(--magenta-5)" icon={['far','check']} />,
  //   eventTypes: EventEntityTypesConfig['TASK'],
  // },
  // PTP: {
  //   label: 'Promise To Pay',
  //   value: 'PTP',
  //   style: {
  //     backgroundColor: 'var(--lime-1)',
  //     color: 'var(--lime-8)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','handshake']} color="var(--lime-8)" />,
  //   eventTypes: EventEntityTypesConfig['TASK'],
  // },
  // ESCALATION: {
  //   label: 'Escalation',
  //   value: 'ESCALATION',
  //   style: {
  //     backgroundColor: 'var(--volcano-1)',
  //     color: 'var(--volcano-8)',
  //   },
  //   icon: <FontAwesomeIcon icon={['far','exclamation-circle']} color="var(--volcano-8)" />,
  //   eventTypes: EventEntityTypesConfig['ESCALATION'],
  // },

  CUSTOMER: {
    label: 'Customer',
    value: 'CUSTOMER',
    style: {
      backgroundColor: 'var(--cyan-1)',
      color: 'var(--cyan-7)',
    },
    icon: <FontAwesomeIcon icon={['far', 'briefcase']} color="var(--cyan-7)" />,
    eventTypes: EventEntityTypesConfig['CUSTOMER'],
  },
};

function getWorkFlowStatus(status: WorkFlowStatus) {
  return workFlowStatusConfig[status];
}

function generateOptions() {
  return customWorkFlowStatus.map((status: WorkFlowStatus) => {
    const workFlowStatus = getWorkFlowStatus(status);
    const Label = (
      <Flex align="center" gap="var(--space-8)" style={{ fontSize: 'var(--fs-14)' }}>
        <SelectOptionIcon
          justify="center"
          align="center"
          style={{
            ...workFlowStatus.style,
            borderRadius: 'var(--br-2)',
          }}
        >
          {workFlowStatus.icon}
        </SelectOptionIcon>
        {workFlowStatus.label}
      </Flex>
    );
    return {
      key: workFlowStatus.value,
      label: Label,
      value: workFlowStatus.value,
    };
  });
}

export const triggerEventOptions: SelectOption[] = generateOptions();

export const workFlowFilterConfig: FilterConfigWrapperProps = {
  name: 'Entity',
  label: 'Entity',
  options: triggerEventOptions,
  valueToString(value: WorkFlowStatus[], defaultValueToString) {
    if (value?.length === triggerEventOptions.length) {
      return 'All';
    }
    if (value?.length) {
      const predictValues = (value: WorkFlowStatus) => getWorkFlowStatus(value).label;
      return value.map(predictValues);
    }

    return (!value || !value.length) && 'All';
  },
};

export function getEventTypeGroup(eventType: WorkFlowEventTypes) {
  return EntityTypes.find((group: EVENT_TRIGGER_CONDITION_KEYS) =>
    workFlowStatusConfig[group].eventTypes.includes(eventType)
  );
}
