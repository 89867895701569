const createView = {
  callDetails: {
    title: 'Call Details',
    placeholder: 'Type in the call details...',
  },
  callDate: 'Call Date',
  attachments: 'Attachments',
  relativeOptions: {
    primaryContact: 'Primary Contact',
    secondaryContact: 'Secondary Contact',
    otherContact: 'Other Contact',
    accountOwner: 'Account Manager',
    collectionOwner: 'Collection Owner',
    CustomerSuccessManager: 'Customer Success Manager',
  },
  to: {
    placeholder: 'Add Recipients',
    label: 'To',
  },
  cc: {
    placeholder: 'Add Recipients',
    label: 'CC',
  },
  bcc: {
    placeholder: 'Add Recipients',
    label: 'BCC',
  },
  from: {
    label: 'From',
    placeholder: 'Add Recipient',
  },
  composeNewEmail: 'Compose Mail',
  emailTemplate: 'Select a Email Template...',
  subject: {
    label: 'Subject',
    placeholder: 'Add a Subject',
  },
  body: {
    label: 'Body',
    placeholder: 'Add content of the mail',
  },
  attachInvoice: 'Attach Invoice PDF',
  notFound: 'Not found',
};

const validationErrors = {
  mandatoryField: 'This field is mandatory',
  correctEmailAddress: 'Please enter the correct Email Address',
  subject: {
    minCharacter: 'Subject should have atleast 2 characters',
  },
};

export { createView, validationErrors };
