import { Flex } from 'components/BaseComponents/Layout/Flex';
import {
  CreatedBlock,
  CustomerBlock,
  LabelBlock,
} from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { ActivityDetailsBase, ActivityType } from 'types/activities/activity-types';

import { HideWrapper } from '@sinecycle/growcomponents';
import { useQueryClient } from '@tanstack/react-query';
import { Empty } from 'components/BaseComponents/Empty/Empty';
import { CollectionActivityTypes } from 'components/Common/ActivitiesModal/ActivitiesFormEdit';
import { useEntitySearchParams } from 'components/Common/hooks/useEntitySearchParams';
import { EActivityQueryParams } from 'components/HigherOrderComponent/KeyActivitesContainer/type';
import { useActivityFilterParams } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityFilterParams';
import { handleCreateCustomEventCallBack } from 'components/Inbox/CollectionActivitiesV2/hooks/useActivityUpdation';
import { inboxQueryKeys } from 'queries/inbox';
import { Task } from 'types/activities/task';
import { PerformedActionType } from 'types/api/inbox/activity';
import { useEventBus } from 'util/hooks/useCustomEventEmitter';
import { AssignTaskOwner } from './Common/AssignTaskOwner';
import { DueDate } from './Common/DueDate';
import { MarkAsComplete } from './Common/MarkAsComplete';
import { TaskStatusComp } from './Common/TaskStatus';

interface DisputeInfoProps extends ActivityDetailsBase {
  data?: Task;
  loading: boolean;
}

function TaskDetailsInfo(props: DisputeInfoProps) {
  const { data, loading, queryKey, activitiesMode, isReadOnly } = props;
  const queryClient = useQueryClient();
  const { filters, searchParams } = useActivityFilterParams();
  function handleRefetch() {
    queryClient.refetchQueries([queryKey]);
  }

  const { emitEvent } = useEventBus();
  const { entitySearchParams } = useEntitySearchParams();
  const activityId =
    entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_ID] ||
    entitySearchParams[EActivityQueryParams.ACTIVITY_ID];
  const activityType =
    entitySearchParams[EActivityQueryParams.ASSOCIATED_ACTIVITY_TYPE] ||
    entitySearchParams[EActivityQueryParams.ACTIVITY_TYPE];

  const Status =
    data && !loading ? <TaskStatusComp status={data.status} dueDays={data.due_days} /> : null;
  const taskQueryKey = ['collection_activity', Number(activityId), activityType];

  const DueDateComp =
    data && !loading ? (
      <DueDate
        dueDate={data.due_date}
        data={data}
        readOnly={isReadOnly}
        onChangeCallBack={(value) => {
          handleCreateCustomEventCallBack('updating_query', {
            id: String(data.id),
            activity: ActivityType.TASK,
            value: {
              action: PerformedActionType.DATE_CHANGED,
              date: value,
              status: data.status,
            },
          });
          queryClient.invalidateQueries({ queryKey: taskQueryKey });
          emitEvent({
            event: 'updatedActivity',
            data: {
              data: queryClient.getQueryData<CollectionActivityTypes>(taskQueryKey),
              enabled: !entitySearchParams[EActivityQueryParams.QUERY_KEY],
            },
          });
          queryClient.invalidateQueries([
            inboxQueryKeys.assigned_activities,
            filters,
            searchParams,
          ]);
        }}
      />
    ) : null;

  const Created =
    data && !loading ? (
      <CreatedBlock createdBy={data.created_by} createdDate={data.created_date} />
    ) : null;
  const AssignedBy =
    data && !loading ? (
      <Empty isEmpty={!data.assigned_to_list?.length}>
        <Flex direction="column" gap="var(--space-4)">
          {data.assigned_to_list?.map((user) => {
            return (
              <AssignTaskOwner
                readOnly={isReadOnly}
                key={user.id}
                selectedUser={user}
                data={data}
                onChangeCallBack={() => {
                  handleCreateCustomEventCallBack('updating_query', {
                    id: String(data.id),
                    activity: ActivityType.TASK,
                    value: {
                      action: PerformedActionType.ASSIGNEE_CHANGED,
                    },
                  });
                }}
              />
            );
          })}
        </Flex>
      </Empty>
    ) : null;

  const Customer =
    props.customer && !loading ? (
      <CustomerBlock
        id={props.customer.id}
        name={props.customer.name}
        customerNumber={props.customer.customer_no}
      />
    ) : null;

  const Action = data ? (
    <MarkAsComplete
      taskId={data.id}
      status={data.status}
      data={data}
      onChangeCallback={(status) => {
        handleRefetch();
        handleCreateCustomEventCallBack('updating_query', {
          id: String(data.id),
          activity: ActivityType.TASK,
          value: {
            status,
            date: data.due_date,
            action: PerformedActionType.STATUS_CHANGED,
          },
        });
      }}
      activitiesMode={activitiesMode}
    />
  ) : null;
  return (
    <Flex direction="column" gap="var(--space-16)">
      <HideWrapper hide={Boolean(isReadOnly)}>{Action}</HideWrapper>
      <InfoContent content={Status} label={<LabelBlock label="Status" color="var(--gray-7)" />} />
      <InfoContent
        content={DueDateComp}
        label={<LabelBlock label="Due Date" color="var(--gray-7)" />}
      />
      <InfoContent
        content={AssignedBy}
        label={<LabelBlock label="Task Owners" color="var(--gray-7)" />}
      />
      <InfoContent content={Created} label={<LabelBlock label="Created" color="var(--gray-7)" />} />
      <HideWrapper hide={Boolean(isReadOnly)}>
        <InfoContent
          content={Customer}
          label={<LabelBlock label="Customer" color="var(--gray-7)" />}
        />
      </HideWrapper>
    </Flex>
  );
}

export { TaskDetailsInfo };
