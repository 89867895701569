import { Form } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { FormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { WorkflowForms } from '../../../type';
import { t } from '../../text';
import { ActionActivityTypes, FlagInvoicesProps } from '../../types';
import { ActionType } from '../ActionType';

export default function FlagInvoices(props: FlagInvoicesProps) {
  const { data, form } = props;
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <FlagInvoicesReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          <FormItem
            name={'flag_invoice_value'}
            label={t.comments}
            initialValue={data?.value}
            rules={[{ required: true, message: t.comments_required }]}
          >
            <DescriptionTextArea placeholder={t.enter_comments} />
          </FormItem>
          <ActionType value={ActionActivityTypes.FLAG_INVOICE} />
        </Form>
      )}
    </>
  );
}

export function FlagInvoicesReadOnly(props: FlagInvoicesProps) {
  const { data } = props;

  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent label={t.comments} content={data?.value} />
    </div>
  );
}
