import { CSS } from '@stitches/react';
import { ActivityType } from 'types/activities/activity-types';
import { ActivitySchemaMapping } from '../type';

export function getActivityBg(activity?: ActivityType): CSS {
  switch (activity) {
    case ActivityType.DISPUTE:
    case ActivityType.ESCALATION:
      return { '--activityBg': 'var(--volcano-1)' };
    case ActivityType.CALL_LOG:
    case ActivityType.NOTE:
      return { '--activityBg': 'var(--blue-1)' };
    case ActivityType.CREDIT_MEMO:
    case ActivityType.PAYMENT:
      return { '--activityBg': 'var(--green-1)' };
    case ActivityType.PROMISE_TO_PAY:
      return { '--activityBg': 'var(--lime-1)' };
    case ActivityType.WRITE_OFF:
      return { '--activityBg': 'var(--gray-2)' };
    case ActivityType.TASK:
      return { '--activityBg': 'var(--magenta-1)' };
    case ActivityType.EMAIL:
      return { '--activityBg': 'var(--geekblue-1)' };
    default:
      return {};
  }
}

export function getActivitySchemaMapping(
  activityType?: ActivityType,
  hasChild?: boolean
): ActivitySchemaMapping {
  switch (activityType) {
    case ActivityType.DISPUTE:
      return {
        updated_at: 'updated_at',
        title: 'parent.title',
        description: 'parent.description',
        customer_info: 'parent.customer_info',
        customer: 'parent.customer',
        invoices: 'parent.invoices',
        comment_count: 'parent.comment_count',
        created_by: 'parent.created_by',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        entity_detail: {
          status: 'parent.status',
          source_app: 'parent.source_app',
          id: 'parent.id',
        },
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.CALL_LOG:
      return {
        description: 'parent.description',
        comment_count: 'parent.comment_count',
        created_by: 'parent.created_by',
        customer: 'parent.customer',
        customer_info: 'parent.customer_info',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        invoices: 'parent.invoices',
        entity_detail: { id: 'parent.id' },
        updated_at: 'updated_at',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.NOTE:
      return {
        title: 'parent.notes',
        comment_count: 'parent.comment_count',
        invoices: ['parent.invoice'],
        created_by: 'parent.created_by',
        customer: 'parent.customer',
        customer_info: 'parent.customer_info',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        entity_detail: { id: 'parent.id' },
        updated_at: 'updated_at',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.PROMISE_TO_PAY:
      return {
        title: 'parent.description',
        comment_count: 'parent.comment_count',
        invoices: ['parent.invoices'],
        created_by: 'parent.created_by',
        ptp_date: 'parent.pay_date',
        ptp_amount: 'parent.amount',
        customer: 'parent.customer',
        customer_info: 'parent.customer_info',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        entity_detail: {
          status: 'parent.status',
          source_app: 'parent.source_app',
          id: 'parent.id',
        },
        updated_at: 'updated_at',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.ESCALATION:
      return {
        description: 'parent.description',
        comment_count: 'parent.comment_count',
        invoices: 'parent.invoices',
        created_by: 'parent.created_by',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        customer_info: 'parent.customer_info',
        entity_detail: { status: 'parent.status', id: 'parent.id' },
        updated_at: 'updated_at',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.TASK:
      return {
        title: 'parent.title',
        updated_at: 'updated_at',
        comment_count: 'parent.comment_count',
        invoices: 'parent.invoices',
        created_by: 'parent.created_by',
        created_at: 'parent.created_date',
        entity_type: 'parent_type',
        customer_info: 'parent.customer_info',
        entity_detail: {
          status: 'parent.status',
          due_date: 'parent.due_date',
          id: 'parent.id',
          due_days: 'parent.due_days',
        },
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.PAYMENT:
      return {
        updated_at: 'updated_at',
        comment_count: 'parent.comment_count',
        description: 'parent.notes',
        created_at: 'parent.created_at',
        created_by: 'parent.created_by',
        customer: 'parent.customer',
        customer_info: 'parent.customer_info',
        entity_detail: {
          amount: 'parent.amount',
          currency: 'parent.currency',
          mode: 'parent.mode',
          id: 'parent.id',
        },
        invoices: 'parent.invoices',
        entity_type: 'parent_type',
        parent_update: 'parent.updated_at',
        child_update: 'child.updated_at',
      };
    case ActivityType.WRITE_OFF:
      return {
        updated_at: 'updated_at',
        comment_count: 'parent.comment_count',
        description: 'parent.description',
        created_at: 'parent.created_date',
        created_by: 'parent.created_by',
        customer_info: 'parent.customer_info',
        entity_detail: {
          id: 'parent.id',
        },
        invoices: 'parent.invoices',
        entity_type: 'parent_type',
        amount: 'parent.amount',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };
    case ActivityType.CREDIT_MEMO:
      return {
        updated_at: 'updated_at',
        comment_count: 'parent.comment_count',
        title: 'parent.note',
        created_at: 'parent.created_date',
        created_by: 'parent.created_by',
        customer: 'parent.customer',
        customer_info: 'parent.customer_info',
        entity_detail: {
          id: 'parent.id',
        },
        invoices: 'parent.invoices',
        entity_type: 'parent_type',
        parent_update: 'parent.updated_date',
        child_update: 'child.updated_date',
      };

    case ActivityType.EMAIL:
      return hasChild
        ? {
            title: 'child.subject',
            description: 'child.body',
            created_at: 'child.sent_at',
            created_by: 'child.created_by',
            entity_detail: {
              id: 'parent.id',
            },
            invoices: 'child.invoices',
            entity_type: 'child_type',
            customer_info: 'customer_info',
            updated_date: 'child.sent_at',
            parent_update: 'parent.sent_at',
            child_update: 'child.sent_at',
            updated_at: 'updated_at',
          }
        : {
            title: 'parent.subject',
            description: 'parent.body',
            created_at: 'parent.sent_at',
            created_by: 'parent.created_by',
            customer_info: 'customer_info',
            entity_detail: {
              id: 'parent.id',
            },
            invoices: 'parent.invoices',
            entity_type: 'parent_type',
            parent_update: 'parent.sent_at',
            child_update: 'child.sent_at',
            updated_at: 'updated_at',
          };
    default:
      return undefined;
  }
}
