import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep, isArray, isObject, isString, transform } from 'lodash';
import { useParams } from 'react-router-dom';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { useUpdateWorkflow } from '../../hooks/useUpdateWorkflow';
import { WorkflowActionRule, WorkFlowRule } from '../../type';
import { removeActionsInRule } from '../approval-utils';
import { ActionTypes, ApprovalAction } from '../types';

export function removeIdsStartingWithUI(data: unknown): unknown {
  if (isArray(data)) {
    return data.map((item) => removeIdsStartingWithUI(item)); // Process each item and return the updated array
  } else if (isObject(data)) {
    return transform(
      data,
      (result: any, value: any, key) => {
        if (key === 'id' && isString(value) && value.startsWith('UI')) {
          // Skip this key-value pair
          return;
        } else {
          // Recursively process the value
          result[key] = removeIdsStartingWithUI(value);
        }
      },
      {}
    );
  }
  return data; // Return primitive values as is
}

export function removeData(
  data?: WorkflowActionRule<ActionTypes>[],
  id?: string | number,
  parentId?: string | number,
  ruleId?: string | number,
  actionId?: string | number
) {
  if (parentId) {
    const parentIdStr = String(parentId);
    const ruleIdStr = String(ruleId);
    const actionIdStr = String(actionId);

    // Find the selected action from data
    const selectedValue = data?.find((f) => String(f.id) === String(parentId));
    const selectedAction = cloneDeep(selectedValue?.action) as ApprovalAction;

    if (!selectedAction) {
      return data; // Return unchanged data if no selected action is found
    }

    // Create a new `selectedAction` object with updated workflow rules
    const updatedSelectedAction = {
      ...selectedAction,
      followup_workflow: {
        ...selectedAction.followup_workflow,
        workflow_rules: removeActionsInRule(
          selectedAction.followup_workflow.workflow_rules,
          ruleIdStr,
          actionIdStr
        ),
      },
    };

    // Update the data array with the modified selected action
    return data?.map((action) =>
      String(action.id) === parentIdStr ? { ...action, action: updatedSelectedAction } : action
    );
  }

  // Remove the top-level action by ID
  const idStr = String(id);
  return data?.filter((action) => String(action?.id) !== idStr);
}

/**
 *
 * @param form
 * @param actionRuleData
 * @param onActionRemove
 * @param baseForm
 * @returns { updateActionData(value: WorkflowActionRule<ActionTypes>): void }
 *
 * This hook is used to remove the existing/already created actions based on that it is
 * removed and updated in the query/form state based on workflow_id also it is updated to the baseform also.
 */
export default function useRemoveAction(actionRuleData: WorkflowActionRule<ActionTypes>[]) {
  const { handleUpdate } = useUpdateWorkflow({
    successText: 'Action removed successfully',
    failureText: 'Failed to remove action',
  });
  const { workflow_id } = useParams();
  const queryClient = useQueryClient();

  const workFlowMeta = queryClient.getQueryData<WorkflowMetaDto>(['workflow-meta', workflow_id]);

  function updateActionData(
    id?: string | number,
    parentId?: string | number,
    ruleId?: string | number
  ) {
    const result = removeData(actionRuleData, id, parentId, ruleId, id);

    if (workflow_id) {
      const data = workFlowMeta?.workflow_rules.map((item) => {
        return {
          ...item,
          actions: result,
        };
      }) as WorkFlowRule[];
      handleUpdate({
        ...workFlowMeta,
        workflow_rules: data,
      });
    }
  }

  return updateActionData;
}
