import { CheckboxOptionType } from 'antd';
import { InvoiceStatus } from 'types/entities/invoice';
import { WorkFlowSortByFilterEnum } from 'types/entities/workflow';
import {
  BasicWorkFlowOperatorTypes,
  EventType,
  WorkFlowConditionOptionsType,
  WorkFlowCreditContactOperatorTypes,
  WorkFlowOperator,
  WorkFlowOperatorTypes,
} from '../CustomAutomations/type';
import { EVENT_TRIGGER_CONDITION_KEYS } from '../WorkflowCardList/type';
import {
  WorkFlowCreditContactOperatorLabel,
  WorkFlowInvoiceEventFilterType,
  WorkFlowInvoiceEventTypes,
  WorkFlowOperatorLabel,
  WorkFlowStatusOperatorLabel,
} from '../utils';

const t = {
  on: 'On',
  after: 'After',
  select_value: 'Select value',
  value: 'Value',
  OR: 'Any',
  AND: 'All',
};

export const ConditionListInvoiceStatusLabelsInvoice: Record<
  WorkFlowInvoiceEventFilterType,
  string
> = {
  INVOICE_VALUE: 'Invoice Amount',
  DUE_DAYS: 'Invoice Age',
  INVOICE_STATUS: 'Invoice Status',
  ISSUE_DATE: 'Invoice Date',
  INVOICE_DUE_DATE: 'Due Date',
  INVOICE_BALANCE: 'Invoice Balance',
  INVOICE_CREATE: 'Invoice Created',
  INVOICE_PDF: 'Invoice PDF available',
  DELIVERY_FLAG: 'Invoice Email Delivered',
  INVOICE_SUBSIDIARY: 'Subsidiary',
  INVOICE_UPDATE: 'Invoice Updated',
  INVOICE_FOLLOWUP_STATUS: 'Followup Status',
  OVERDUE_DAYS: 'Due days',
  INVOICE_PAYMENT_STATUS: 'Payment Status',
  INVOICE_BALANCE_DUE_TX: 'Balance in invoice currency',
  INVOICE_DUE: 'Due Amount',
  INVOICE_OVERDUE: 'Overdue Amount',
  INVOICE_TAX: 'Tax witheld',
  INVOICE_IS_OVERDUE: 'Is Overdue',
  INVOICE_IS_DUE: 'Is Due',
} as const;

const EventInvoiceStatusLabelsInvoice: Record<WorkFlowInvoiceEventFilterType, string> = {
  INVOICE_VALUE: 'Invoice Amount updated',
  DUE_DAYS: 'Invoice Age',
  INVOICE_STATUS: 'Invoice Status updated',
  ISSUE_DATE: 'Invoice Date updated',
  INVOICE_DUE_DATE: 'Due Date updated',
  INVOICE_BALANCE: 'Invoice Balance',
  INVOICE_CREATE: 'Invoice Created',
  INVOICE_PDF: 'Invoice PDF available',
  DELIVERY_FLAG: 'Invoice Email Delivered',
  INVOICE_SUBSIDIARY: 'Subsidiary',
  INVOICE_UPDATE: 'Invoice Updated',
  INVOICE_FOLLOWUP_STATUS: 'Followup Status changed',
  OVERDUE_DAYS: 'Due days updated',
  INVOICE_PAYMENT_STATUS: 'Payment Status changed',
  INVOICE_BALANCE_DUE_TX: 'Balance in invoice currency',
  INVOICE_DUE: 'Invoice Due',
  INVOICE_OVERDUE: 'Invoice Overdue',
  INVOICE_TAX: 'Invoice Tax witheld',
  INVOICE_IS_OVERDUE: 'Is Overdue',
  INVOICE_IS_DUE: 'Is Due',
} as const;
const EventInvoiceLabels: Record<WorkFlowInvoiceEventTypes, string> = {
  INVOICE_CREATED: 'Invoice Created',
  INVOICE_UPDATE: 'Invoice Updated',
} as const;

export const workFlowSortByOptions = [
  { label: 'Last Updated', value: WorkFlowSortByFilterEnum.LAST_UPDATED_AT },
  // { label: 'Last Run', value: WorkFlowSortByFilterEnum.LAST_RUN_AT }, for future use commenting now
];

export const TriggerEntityLabels: Record<EventType, string> = {
  LIVE: 'Event',
  PERIODIC: 'Period',
};

export const TriggerEntityOptions: CheckboxOptionType[] = [
  { label: TriggerEntityLabels.LIVE, value: 'LIVE' },
  { label: TriggerEntityLabels.PERIODIC, value: 'PERIODIC', disabled: true },
];

export const WorkFlowConditionsConfig: Record<EVENT_TRIGGER_CONDITION_KEYS, string> = {
  INVOICE: 'INVOICE_CONDITION',
  // PAYMENT: 'PAYMENT_CONDITION',
  // CREDIT_MEMO: 'CREDIT_MEMO_CONDITION',
  // DISPUTE: 'DISPUTE_CONDTION',
  // TASK: 'TASK_CONDITION',
  // PTP: 'PTP_CONDITION',
  // ESCALATION: 'ESCALATION_CONDITION',
  CUSTOMER: 'CUSTOMER_CONDITION',
};

export const WorkFlowInvoiceEventOptions: WorkFlowConditionOptionsType[] = [
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_CREATE,
    value: 'INVOICE_CREATE',
    key: 'INVOICE_CREATE',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_UPDATE,
    value: 'INVOICE_UPDATE',
    key: 'INVOICE_UPDATE',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_STATUS,
    key: 'INVOICE_STATUS',
    value: 'INVOICE_STATUS',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.ISSUE_DATE,
    key: 'ISSUE_DATE',
    value: 'ISSUE_DATE',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_VALUE,
    key: 'INVOICE_VALUE',
    value: 'INVOICE_VALUE',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_BALANCE,
    key: 'INVOICE_BALANCE',
    value: 'INVOICE_BALANCE',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_FOLLOWUP_STATUS,
    key: 'INVOICE_FOLLOWUP_STATUS',
    value: 'INVOICE_FOLLOWUP_STATUS',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_PAYMENT_STATUS,
    key: 'INVOICE_PAYMENT_STATUS',
    value: 'INVOICE_PAYMENT_STATUS',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.OVERDUE_DAYS,
    key: 'OVERDUE_DAYS',
    value: 'OVERDUE_DAYS',
  },
  {
    label: EventInvoiceStatusLabelsInvoice.INVOICE_DUE_DATE,
    key: 'INVOICE_DUE_DATE',
    value: 'INVOICE_DUE_DATE',
  },
];
export const WorkFlowConditionOptions: WorkFlowConditionOptionsType[] = [
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_STATUS,
    key: 'INVOICE_STATUS',
    value: 'INVOICE_STATUS',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_DUE_DATE,
    key: 'INVOICE_DUE_DATE',
    value: 'INVOICE_DUE_DATE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.ISSUE_DATE,
    key: 'ISSUE_DATE',
    value: 'ISSUE_DATE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_VALUE,
    key: 'INVOICE_VALUE',
    value: 'INVOICE_VALUE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_BALANCE,
    key: 'INVOICE_BALANCE',
    value: 'INVOICE_BALANCE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_FOLLOWUP_STATUS,
    key: 'INVOICE_FOLLOWUP_STATUS',
    value: 'INVOICE_FOLLOWUP_STATUS',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_PAYMENT_STATUS,
    key: 'INVOICE_PAYMENT_STATUS',
    value: 'INVOICE_PAYMENT_STATUS',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.OVERDUE_DAYS,
    key: 'OVERDUE_DAYS',
    value: 'OVERDUE_DAYS',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_BALANCE_DUE_TX,
    key: 'INVOICE_BALANCE_DUE_TX',
    value: 'INVOICE_BALANCE_DUE_TX',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_DUE,
    key: 'INVOICE_DUE',
    value: 'INVOICE_DUE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_OVERDUE,
    key: 'INVOICE_OVERDUE',
    value: 'INVOICE_OVERDUE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_TAX,
    key: 'INVOICE_TAX',
    value: 'INVOICE_TAX',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_IS_DUE,
    key: 'INVOICE_IS_DUE',
    value: 'INVOICE_IS_DUE',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice.INVOICE_IS_OVERDUE,
    key: 'INVOICE_IS_OVERDUE',
    value: 'INVOICE_IS_OVERDUE',
  },
];

type WorkFlowOperationOptionsType = {
  label: string;
  value: BasicWorkFlowOperatorTypes;
};

export const WorkFlowOperationOptions: WorkFlowOperationOptionsType[] = [
  {
    label: WorkFlowOperatorLabel.BTWN,
    value: 'BTWN',
  },
  {
    label: WorkFlowOperatorLabel.LOE,
    value: 'LOE',
  },
  {
    label: WorkFlowOperatorLabel.GOE,
    value: 'GOE',
  },
  {
    label: WorkFlowOperatorLabel.IN,
    value: 'IN',
  },
];
type WorkFlowCreditContactOptionsType = {
  label: string;
  value: WorkFlowCreditContactOperatorTypes;
};

export const WorkFlowCreditContactOperationOptions: WorkFlowCreditContactOptionsType[] = [
  {
    label: WorkFlowCreditContactOperatorLabel.ANY,
    value: 'ANY',
  },
  {
    label: WorkFlowCreditContactOperatorLabel.IN,
    value: 'IN',
  },
];

type WorkFlowInvoiceStatusOperationOptionsType = {
  label: string;
  value: WorkFlowOperatorTypes;
};

export const InvoiceStatusChangedOptions: WorkFlowInvoiceStatusOperationOptionsType[] = [
  {
    label: WorkFlowStatusOperatorLabel.IN,
    value: 'IN',
  },
  {
    label: WorkFlowStatusOperatorLabel.CHG,
    value: 'CHG',
  },
];

type WorkFlowOperatorOptionsType = {
  label: string;
  value: WorkFlowOperator;
};

export const WorkFlowOperatorOptions: WorkFlowOperatorOptionsType[] = [
  {
    label: t.OR,
    value: 'OR',
  },
  {
    label: t.AND,
    value: 'AND',
  },
];

export const invoiceStatusListLabel: Record<InvoiceStatus, string> = {
  [InvoiceStatus.PENDING]: 'Pending',
  [InvoiceStatus.REOPEN]: 'Re-Open',
  [InvoiceStatus.DISPUTE]: 'Dispute',
  [InvoiceStatus.PARTIAL_PAYMENT]: 'Partial Paid',
  [InvoiceStatus.FULL_PAYMENT]: 'Paid',
  [InvoiceStatus.WRITE_OFF]: 'Write Off',
  [InvoiceStatus.CLOSED]: 'Closed',
  [InvoiceStatus.VOID]: 'Void',
  [InvoiceStatus.DRAFT]: 'Draft',
};

export const invoiceStatusList = [
  {
    label: invoiceStatusListLabel.PENDING,
    value: InvoiceStatus.PENDING,
  },
  {
    label: invoiceStatusListLabel.VOID,
    value: InvoiceStatus.VOID,
  },
  {
    label: invoiceStatusListLabel.PARTIAL_PAYMENT,
    value: InvoiceStatus.PARTIAL_PAYMENT,
  },
  {
    label: invoiceStatusListLabel.FULL_PAYMENT,
    value: InvoiceStatus.FULL_PAYMENT,
  },
  {
    label: invoiceStatusListLabel.CLOSED,
    value: InvoiceStatus.CLOSED,
  },
  {
    label: invoiceStatusListLabel.REOPEN,
    value: InvoiceStatus.REOPEN,
  },
  {
    label: invoiceStatusListLabel.DISPUTE,
    value: InvoiceStatus.DISPUTE,
  },
];
