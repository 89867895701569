import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TypographyText } from 'components/BaseComponents/AntTypography/AntTypography';
import { useForm } from 'components/BaseComponents/Form/Antd/Antd';

import { GrowFormFooter, GrowFormHeader, GrowModal } from '@sinecycle/growcomponents';
import { useQueryClient } from '@tanstack/react-query';
import { Form } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { notify } from 'components/BaseComponents/Notifications';
import { RadioGroup } from 'components/BaseComponents/Radio';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { useCreateADocumentType, useEditDocumentType } from 'queries/documents/documents';
import { CreateADocumentTypes, DocumentTypesList } from 'types/entities/documents';
import { entityLevelOptions } from '../predicate';
import { DocumentDescForm, DocumentTypeForm } from './FormComponents';

interface DocumentTypesModalProps {
  open: boolean;
  onClose: () => void;
  value?: DocumentTypesList;
  mode?: 'ADD' | 'EDIT';
}
const t = {
  title: 'Add Document Type',
  close: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  editTitle: 'Edit Document Type',
};
function DocumentTypesModal(props: DocumentTypesModalProps) {
  const [form] = useForm<CreateADocumentTypes>();
  const { onClose, open, value, mode = 'ADD' } = props;
  const createADocumentType = useCreateADocumentType();
  const editADocumentType = useEditDocumentType();
  const queryClient = useQueryClient();

  function handleCreateADocument(values: CreateADocumentTypes) {
    createADocumentType
      .mutateAsync(values)
      .then(() => {
        queryClient.invalidateQueries(['document-types-list']);
        notify.success('Document Type created successfully');
        onClose();
      })
      .catch(() => notify.error('Failed to create Document Type'));
  }

  function handleEditDocumentsTypes(values: CreateADocumentTypes) {
    editADocumentType
      .mutateAsync({ ...values, id: value?.id })
      .then(() => {
        queryClient.invalidateQueries(['document-types-list']);
        onClose();
        notify.success('Document Type edited successfully');
      })

      .catch(() => notify.error('Failed to edit a Document Type'));
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((value) => {
        if (mode === 'ADD') {
          handleCreateADocument(value);
        } else {
          handleEditDocumentsTypes(value);
        }
      })
      .catch(() => {});
  }

  const DocumentIcon = (
    <FontAwesomeIcon icon={['fal', 'file-plus']} color="var(--geekblue-6)" fontSize={24} />
  );
  const EditIcon = (
    <FontAwesomeIcon icon={['fal', 'file-edit']} color="var(--geekblue-6)" fontSize={24} />
  );

  const ModalTitle = (
    <Flex align="center" justify="flex-start" gap="var(--space-12)">
      {mode === 'EDIT' ? EditIcon : DocumentIcon}
      <TypographyText strong style={{ fontSize: 'var(--fs-20)' }}>
        {mode === 'EDIT' ? t.editTitle : t.title}
      </TypographyText>
    </Flex>
  );
  const Header = (
    <Flex align="center" justify="space-between">
      {ModalTitle}
    </Flex>
  );
  const Title = <GrowFormHeader mode="modal" title={Header} onClose={onClose} />;

  const EntityLevel = (
    <StyledFormItem
      name="entity_type"
      label="Level"
      style={{ width: '100%' }}
      preserve={false}
      className="sp-1"
      initialValue="CUSTOMER"
      rules={[{ required: true, message: 'This is a required field.' }]}
    >
      <RadioGroup options={entityLevelOptions} optionType="button" />
    </StyledFormItem>
  );
  const Type = (
    <StyledFormItem
      name="name"
      label="Type"
      style={{ width: '100%' }}
      preserve={false}
      className="sp-1"
      rules={[{ required: true, message: 'This is a required field.' }]}
    >
      <DocumentTypeForm />
    </StyledFormItem>
  );
  const Desc = (
    <StyledFormItem
      name="description"
      label="Description"
      style={{ width: '100%' }}
      preserve={false}
      className="sp-1"
    >
      <DocumentDescForm />
    </StyledFormItem>
  );

  const Enabled = <StyledFormItem name="enabled" initialValue={true} hidden />;
  const footer = (
    <GrowFormFooter
      cancelButtonProps={{ onClick: onClose }}
      saveButtonProps={{
        onClick: handleSubmit,
        loading: createADocumentType.isLoading,
        disabled: createADocumentType.isLoading,
      }}
    />
  );
  return (
    <GrowModal
      open={open}
      closable={false}
      title={Title}
      okText={t.save}
      footer={footer}
      type={'activities'}
      centered
      width={640}
      destroyOnClose
      onCancel={onClose}
    >
      <Form name="document-types" form={form} layout="vertical" initialValues={{ ...value }}>
        {EntityLevel}
        {Type}
        {Desc}
        {Enabled}
      </Form>
    </GrowModal>
  );
}

export { DocumentTypesModal };
