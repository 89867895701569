import { Form } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const SelectOptionIcon = styled(Flex)`
  width: var(--space-24);
  height: var(--space-24);
  border-radius: var(--br-2);
  border: 1px solid var(--gray-4);
`;

export const StyledActionOptionFlex = styled(Flex)`
  font-size: 'var(--fs-14)';
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: var(--space-4);
  }
`;

export const EditFieldsFormItem = styled(FormItem)`
  width: 290px;
`;
