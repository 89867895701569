import { Col, Row } from 'antd';
import { TypographyParagraph } from 'components/BaseComponents/AntTypography/AntTypography';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import dayjs from 'dayjs';
import produce from 'immer';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { Range } from 'types/entities/invoice-segments';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import { WorkFlowBetweenRelativeOperands } from '../../EventCondition/operator';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';
import SingleNumericField from '../CommonFilterHelper/SingleNumericField';
import { handleNumericValidation } from '../Validation';
import { BetweenFilterTexts, FromLabel, InfoIcon, RelativeCurrentDateTag, ToLabel } from './utils';

interface BetweenDateFilterProps {
  value?: DateWorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<unknown>) => void;
  prefix?: string;
  precision?: number;
  fieldName?: string;
}

export default function RelativeBetweenFilter(props: BetweenDateFilterProps) {
  const relativeBetweenValue = props.value?.operands as WorkFlowBetweenRelativeOperands;
  const format = useSelector(dateFormatSelector);
  const [range, setRange] = useState<Range>(initializeBetweenValue);
  const moreInfo = renderMoreInfo();

  const OperatorRespresentation = (
    <Row align="middle" justify="space-between">
      <Col span={4.5}>{FromLabel}</Col>
      <Col span={14.5}>{RelativeCurrentDateTag}</Col>
      <Col span={5}>{ToLabel}</Col>
    </Row>
  );
  const FromFieldItem = (
    <StyledFormItem
      validateStatus={range.from.validateStatus}
      help={range.from.errorMsg}
      label={BetweenFilterTexts.days}
      className="label-space"
      labelCol={{ style: { order: 2 } }}
    >
      <SingleNumericField
        onChange={handleFromChange}
        prefix={props.prefix}
        value={range.from.value}
        percision={props.precision ?? 0}
        style={{ width: '50px' }}
      />
    </StyledFormItem>
  );
  const ToFieldItem = (
    <StyledFormItem
      label={BetweenFilterTexts.days}
      validateStatus={range.to.validateStatus}
      help={range.to.errorMsg}
      className="label-space"
      labelCol={{ style: { order: 2 } }}
    >
      <SingleNumericField
        onChange={handleToChange}
        value={range.to.value}
        prefix={props.prefix}
        percision={props.precision ?? 0}
        style={{ width: '50px' }}
      />
    </StyledFormItem>
  );
  const BetweenFilter = (
    <Row style={{ width: '100%' }}>
      <Col span={6.5}>{FromFieldItem}</Col>
      <Col span={11}>{OperatorRespresentation}</Col>
      <Col span={6.5}>{ToFieldItem}</Col>
    </Row>
  );
  const MoreInfoDesc = (
    <TypographyParagraph
      ellipsis={{
        rows: 2,
      }}
      style={{ color: 'var(--gray-9)', margin: 0 }}
    >
      {moreInfo}
    </TypographyParagraph>
  );
  const MoreInfo = (
    <Flex align="flex-start" gap="var(--space-4)">
      {InfoIcon}
      {MoreInfoDesc}
    </Flex>
  );
  function initializeBetweenValue(): Range {
    const from = relativeBetweenValue.value?.from?.offset;
    const to = relativeBetweenValue.value?.to?.offset;
    return {
      from: {
        value: from ?? 0,
      },
      to: {
        value: to ?? 0,
      },
    };
  }

  function handleFromChange(value: number | null) {
    const validationStatus = handleNumericValidation(value, Boolean(props.precision));
    setRange((previousValue) => {
      return {
        from: { ...validationStatus, value },
        to: previousValue.to,
      };
    });
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        const filter = draftFilter.operands as WorkFlowBetweenRelativeOperands;
        filter.value.from.offset = value;
      });
    updatedFilter && props?.onChange?.(updatedFilter);
  }

  function handleToChange(value: number | null) {
    const validationStatus = handleNumericValidation(value, Boolean(props.precision));
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        const filter = draftFilter.operands as WorkFlowBetweenRelativeOperands;
        filter.value.to.offset = value;
      });
    updatedFilter && props?.onChange?.(updatedFilter);
    setRange((previousValue) => {
      return {
        from: previousValue.from,
        to: { ...validationStatus, value },
      };
    });
  }

  function renderMoreInfo() {
    if (range.from.value === 0 && range.to.value === 0) {
      return (
        <GrowText color="var(--gray-8)">
          {BetweenFilterTexts.allAreZeros} {getCustomFormattedDate(dayjs(), format)}
        </GrowText>
      );
    }
    const beforeCurrentDate = dayjs()
      .subtract(range.from.value as number, 'day')
      .format(format);
    const afterCurrentDate = dayjs()
      .add(range.to.value as number, 'day')
      .format(format);
    return (
      <GrowText color="var(--gray-8)">
        {BetweenFilterTexts.hasValueText} {beforeCurrentDate} and {afterCurrentDate}
      </GrowText>
    );
  }
  return (
    <>
      {BetweenFilter} {MoreInfo}
    </>
  );
}
