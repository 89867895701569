import { UsersValueOperand } from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
export type DestructureValueType = number | UsersValueOperand | undefined;
export interface DynamicFilterGeneratorData {
  first_name?: string;
  last_name?: string;
  email?: string;
  id?: number;
}
export default function useProcessDynamicFilterFunctions() {
  function getMatchedValues<T extends DynamicFilterGeneratorData>(
    conditionType: WorkFlowEventTypes,
    data: T[],
    value?: DestructureValueType | string
  ) {
    switch (conditionType) {
      case 'PRIMARY_CONTACT':
      case 'SECONDARY_CONTACT':
        const matchedContact = data?.find(
          (contact) => `${contact.first_name}_${contact.last_name}_${contact.email}` === value
        );
        return matchedContact;
      case 'COLLECTION_OWNER':
        const ownermatchedContact = data?.find((contact) => contact.id === value);
        return ownermatchedContact;
    }
  }
  function destructureValue<T extends DynamicFilterGeneratorData>(
    conditionType: WorkFlowEventTypes,
    data?: T
  ): DestructureValueType {
    switch (conditionType) {
      case 'PRIMARY_CONTACT':
      case 'SECONDARY_CONTACT':
        return data
          ? {
              first_name: data?.first_name,
              last_name: data?.last_name,
              email: data?.email,
            }
          : undefined;
      case 'COLLECTION_OWNER':
        return data ? data.id : undefined;
    }
  }
  function generateStructure<T extends DynamicFilterGeneratorData>(
    conditionType: WorkFlowEventTypes,
    data?: T
  ): string | number | undefined {
    switch (conditionType) {
      case 'PRIMARY_CONTACT':
      case 'SECONDARY_CONTACT':
        return data ? `${data?.first_name}_${data?.last_name}_${data?.email}` : undefined;
      case 'COLLECTION_OWNER':
        return data ? data.id : undefined;
    }
  }
  return { getMatchedValues, destructureValue, generateStructure };
}
