import { FormInstance } from 'antd';
import { BaseOptionType } from 'antd/lib/cascader';
import { DefaultOptionType } from 'antd/lib/select';
import { GroupedOptions } from 'components/BaseComponents/ASelect';
import {
  delayedExecutionConfig,
  DeliveryOptions,
} from 'components/Common/Email/Form/DeliverySettings/types';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import {
  RECIPIENT_OPT_GROUP_KEYS,
  stakeHolderListAttributes,
} from 'components/HigherOrderComponent/KeyActivitesContainer/Email/type';
import dayjs from 'dayjs';
import {
  assign,
  castArray,
  chain,
  cloneDeep,
  find,
  get,
  head,
  isUndefined,
  map,
  omit,
  set,
  some,
  toLower,
} from 'lodash';
import { UploadFileTypes } from 'services/attachments';
import { validateEmail } from 'store/collection-strategy-rule/validations';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { CustomField } from 'types/entities/custom-field';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { generateId } from 'util/generateId';
import { defaultActionableEntity } from '../../common/ActionPredicate';
import { constructActionProps, WorkflowActionRule } from '../type';
import { getActionType } from './ActionConfigurations/ActionTypeTitle';
import { getApprovalDefaultData, updateActionsInRule } from './approval-utils';
import {
  ActionActivityTypes,
  ActionTypes,
  ApprovalAction,
  ApprovalRule,
  ApprovalWorkFlowAction,
  ConstructActionProps,
  EmailActionsData,
  ValidateEmailErrors,
} from './types';

/**
 *
 * @param props
 * @returns Promise<ActionTypes>
 *
 * This function creates each of the actions based on the type the changes are made and returned
 */
export async function createActionActivity(props: ConstructActionProps): Promise<ActionTypes> {
  const {
    data,
    file_upload_ids,
    uploadAttachments,
    workflowData,
    emailFormData,
    prevData,
    isNewaction,
  } = props;
  switch (data?.type) {
    case ActionActivityTypes.EMAIL: {
      const delayedExecutionConfig = constructDelayedExecutionConfig(data);

      return assign({}, emailFormData, {
        type: data.type,
        delayed_execution_config: delayedExecutionConfig,
      });
    }
    case ActionActivityTypes.PROMISE_TO_PAY: {
      const actionData = omit(data, ['date_select_offset', 'ptp_description']);

      return assign({}, actionData, {
        due_date_offset: data?.due_date_offset || 0,
        description: data.ptp_description,
      });
    }
    case ActionActivityTypes.TASK: {
      const actionData = omit(data, ['date_select_offset', 'task_description', 'task_assign_to']);

      return assign({}, actionData, {
        due_date_offset: data?.due_date_offset || 0,
        assign_to: data.task_assign_to,
        description: data.task_description,
      });
    }
    case ActionActivityTypes.ESCALATION: {
      const actionData = omit(data, ['escalation_assign_to', 'escalation_description']);

      return assign({}, actionData, {
        assign_to: data.escalation_assign_to,
        description: data.escalation_description,
      });
    }
    case ActionActivityTypes.DISPUTE: {
      const actionData = omit(data, 'dispute_description');
      const uploadIds =
        file_upload_ids &&
        (await uploadAttachments?.({
          file: file_upload_ids,
          type: UploadFileTypes.DISPUTE,
        }));

      return assign({}, actionData, {
        file_upload_ids: uploadIds || [],
        description: data.dispute_description,
      });
    }
    case ActionActivityTypes.EDIT_STRATEGY: {
      const actionData = omit(data, ['collection_strategy_value', 'actionable_entity']);

      return assign({}, actionData, {
        value: data.collection_strategy_value.toString(),
        field: ActionActivityTypes.EDIT_STRATEGY,
        type: ActionActivityTypes.CUSTOMER,
      });
    }
    case ActionActivityTypes.FLAG_INVOICE: {
      const actionData = omit(data, 'flag_invoice_value');

      return assign({}, actionData, {
        field: ActionActivityTypes.FLAG_INVOICE,
        value: data.flag_invoice_value.toString(),
        type: ActionActivityTypes.INVOICE,
      });
    }
    case ActionActivityTypes.USER_CATEGORY: {
      return assign({}, data, {
        user_ids: castArray(data.user_ids),
        name: ActionActivityTypes.COLLECTION_OWNER,
      });
    }
    case ActionActivityTypes.EDIT_COLLECTION_STATUS: {
      const actionData = omit(data, 'collection_status_value');

      return assign({}, actionData, {
        value: data.collection_status_value.toString(),
        field: ActionActivityTypes.EDIT_COLLECTION_STATUS,
        type: ActionActivityTypes.CUSTOMER,
      });
    }
    case ActionActivityTypes.CUSTOMER_CUSTOM_FIELD: {
      const actionData = omit(data, 'customer_custom_value');

      return assign({}, actionData, {
        field_id: data.field_id.value,
        value: data.customer_custom_value,
      });
    }
    case ActionActivityTypes.INVOICE_CUSTOM_FIELD: {
      const actionData = omit(data, 'invoice_custom_value');

      return assign({}, actionData, {
        field_id: data.field_id.value,
        value: data.invoice_custom_value,
      });
    }
    case ActionActivityTypes.APPROVAL_REQUEST: {
      const entityData = omit(data, [
        'actionable_entity',
        'type',
      ]) as unknown as ApprovalWorkFlowAction;
      const approvalDefaultData = getApprovalDefaultData(workflowData, entityData);
      const perevData = { ...(prevData as any)?.action, ...entityData };

      return assign({}, isNewaction ? approvalDefaultData : perevData);
    }
    default: {
      const entityData = omit(data, 'actionable_entity') as ActionTypes;
      return assign({}, entityData);
    }
  }
}

/**
 *
 * @param data
 * @param actionId
 * @param actions
 * @param type
 * @param file_upload_ids
 * @param uploadAttachments
 * @param emailFormData
 * @param isNewAction
 * @returns ActionTypes[]
 *
 * This is the heart of the workflow actions where we create the meta for each actions
 * it either overrides the exising actions or else creates a new action with a unique id based on uuid for frontend
 * purposes and return all the actions
 */
export async function constructAction(props: constructActionProps) {
  const {
    data,
    actionId,
    actions,
    type,
    file_upload_ids,
    uploadAttachments,
    emailFormData,
    isNewAction,
    workflowData,
    ruleId,
  } = props;

  const actionsData = cloneDeep(actions);
  const act = typeof type === 'string' ? type : type?.value;
  const { actionData, actionDataIndex } = getExistingActionsData(
    actionsData,
    actionId,
    isNewAction,
    act
  );

  const actionsObject = assign(
    {},
    { ...actionData },
    {
      actionable_entity: computeActionableEntity(act, data?.actionable_entity),
      action: await createActionActivity({
        data,
        uploadAttachments,
        file_upload_ids,
        emailFormData,
        workflowData,
        isNewaction: isNewAction,
        prevData: actionData,
      }),
    }
  );

  if (props.parentId) {
    const selectedValue = actions.find((f) => String(f.id) === String(props.parentId));
    const selectedAction = selectedValue?.action as ApprovalAction;

    const updatedSelectedAction = {
      ...selectedAction,
      followup_workflow: {
        ...selectedAction?.followup_workflow,
        workflow_rules: updateActionsInRule(
          selectedAction?.followup_workflow?.workflow_rules || [],
          ruleId,
          {
            ...actionsObject,
          }
        ),
      },
    };
    return actions.map((action) =>
      String(action.id) === String(props.parentId)
        ? { ...action, action: updatedSelectedAction }
        : action
    );
  }

  if (actionDataIndex !== -1) {
    set(actions, actionDataIndex, actionsObject);
  } else {
    actions.push(actionsObject);
  }

  return actions;
}

/**
 *
 * @param type
 * @param actionableEntity
 * @returns { ActionableEntity.INVOICE | ActionableEntity.CUSTOMER }
 *
 * Some actions by default have actionable entity as customer or invoice based on the
 * entity or action that is being done so it checks the defaultActionableEntity object or
 * the actionableEntity to determine the actionable entity.
 */
export function computeActionableEntity(
  type?: string,
  actionableEntity?: boolean
): ActionableEntity {
  if ((type && defaultActionableEntity[type]?.invoiceLevel) || actionableEntity)
    return ActionableEntity.INVOICE;

  return ActionableEntity.CUSTOMER;
}

/**
 *
 * @param actions
 * @param actionId
 * @param isNewAction
 * @param type
 * @returns { actionDataIndex: number, actionData: WorkflowActionRule<ActionTypes> | { id: string } }
 *
 * This function retrives the exisiting action object with its index so that we can replace/override
 * the exsiting action at the same index if it is not retrived we are creating a id using uuid
 * to maintain uniquiness for each action this works only when we create new form
 */
export function getExistingActionsData(
  actions: WorkflowActionRule<ActionTypes>[],
  actionId: string,
  isNewAction: boolean,
  type?: string
) {
  const id = `UI_${generateId()}`;
  const existingDataIndex = actions?.findIndex((item) => !isNewAction && item.id === actionId);
  const existingData: WorkflowActionRule<ActionTypes> | undefined = findActionById(
    actions,
    actionId
  );

  const actType = getActionType(existingData);

  const isActionOverride = actType && type !== actType;

  const actionData = !isNewAction && !isActionOverride && existingData ? existingData : { id };

  return { actionDataIndex: existingDataIndex, actionData };
}

/**
 *
 * @param data
 *
 * @returns delayedExecutionConfig
 *
 * This function created delayed execution config object which is used for email action
 * for delivery settings it parses out the hour, minute and seconds and fills other data.
 */
function constructDelayedExecutionConfig(data: EmailActionsData): delayedExecutionConfig {
  const dayjsObj = data.fixed_time && dayjs(data.fixed_time);
  const days =
    data.DAYS === DeliveryOptions.ANY_DAYS || isUndefined(data.DAYS)
      ? Array.from({ length: 7 }, (_, index) => index + 1)
      : data.fixed_days.days;
  const tzType = data.tz_type === DeliveryOptions.CUSTOMER_TIMEZONE ? 'CUSTOMER_TZ' : 'GIVEN_TZ';
  const fixedTime = {
    hour: parseInt(dayjsObj?.format('HH')),
    minute: parseInt(dayjsObj?.format('mm')),
    second: parseInt(dayjsObj?.format('ss')),
  };

  return {
    tz: data.tz,
    tz_type: tzType,
    fixed_days: days,
    any_time: data.TIME === DeliveryOptions.ANY_TIME || isUndefined(data.TIME),
    fixed_time: fixedTime,
    any_days: data.DAYS === DeliveryOptions.ANY_DAYS || isUndefined(data.DAYS),
  };
}

/**
 *
 * @param data
 * @param id
 * @returns WorkflowActionRule<ActionTypes>[]
 *
 * This function removes the action based on id this is used on useRemoveAction hook
 */
export function removeAction(data: WorkflowActionRule<ActionTypes>[], id: number | string) {
  return data.filter((item) => item.id !== id);
}

/**
 *
 * @param emailData
 * @returns errorsList, showErrors
 *
 * Checks if there are any errors in the email during validation
 */
export function validateEmailError(emailData: Partial<EmailActionData>): ValidateEmailErrors {
  const errorsList = validateEmail(emailData);
  const showErrors = Object.values(errorsList).some((val) => val);
  return { errorsList, showErrors };
}

/**
 *
 * @param type
 * @param actionRule
 * @returns boolean
 *
 * This function returns a boolean value indicating whether the ActionableEntity
 * is invoice type or not this is used during the runtime execution to show the checkbox
 * checked based on the api response of each action.
 */
export function isInvoiceLevelAction(
  type: string | unknown,
  actionRule: WorkflowActionRule<ActionTypes>[]
) {
  return actionRule?.some(
    (item) => item.action.type === type && item.actionable_entity === ActionableEntity.INVOICE
  );
}

/**
 *
 * @param actionRule
 * @param form
 * @returns { WorkflowActionRule<ActionTypes> | undefined }
 *
 * This function returns a workflow action rule when we used the action type dropdown
 * it checks whether we have any existing workflow action in the response and returns it
 * if we are creating new action by clicking the button this returns undefined.
 */
export function mapActionData(
  actionRule: WorkflowActionRule<ActionTypes>[],
  form: FormInstance,
  id: string | number
): WorkflowActionRule<ActionTypes> | undefined {
  const isNewAction = form.getFieldValue('is_new_action');

  return !isNewAction
    ? actionRule?.find((item) => {
        return item.id === id;
      })
    : undefined;
}

/**
 *
 * @param workflowMeta
 * @returns { WorkflowActionRule<ActionTypes>[] | undefined }
 *
 * Extracts and returns the workflow actions array
 */
export function extractActionsData(workflowMeta?: WorkflowMetaDto) {
  return head(map(workflowMeta?.workflow_rules, 'actions'));
}

/**
 *
 * @param action
 * @returns boolean
 *
 * Some actions have types of same name so we are maitaining a seperate type name for FE
 * this utils return a boolean to sanitize and change the keys
 */
export function isCustomerFieldAction(action: WorkflowActionRule<ActionTypes>) {
  return (
    action.action.type === ActionActivityTypes.EDIT_STRATEGY ||
    action.action.type === ActionActivityTypes.EDIT_COLLECTION_STATUS
  );
}

/**
 *
 * @param actionItem
 * @param field
 * @returns boolean
 *
 * Some actions have types of same name so we are maitaining a seperate type name for FE
 * this utils updates the type of the action to the field name since we are using field names
 * as types here for these actions mentioned below.
 */
export function parseActionType(
  actionItem: WorkflowActionRule<ActionTypes>,
  field: ActionActivityTypes | number
) {
  switch (field) {
    case ActionActivityTypes.EDIT_STRATEGY:
    case ActionActivityTypes.FLAG_INVOICE:
    case ActionActivityTypes.EDIT_COLLECTION_STATUS:
      actionItem.action.type = field;
      break;
    default:
      return;
  }
}

/**
 *
 * @param groupedOptions
 * @param item
 * @returns { (string | null | undefined)[] }
 *
 * This is used to extract the names from the assigned to users used in task, escalation etc...
 * since we are having different entities like customer, invoices, others, etc...
 * we are extracting it here and returning
 */
export function extractAssignToLabel(
  groupedOptions: GroupedOptions<
    stakeHolderListAttributes,
    RECIPIENT_OPT_GROUP_KEYS.INVOICE | RECIPIENT_OPT_GROUP_KEYS.CUSTOMER
  >,
  item: stakeHolderListAttributes[]
) {
  return map(item, (assign) => {
    const associationLevel = assign.association_level || RECIPIENT_OPT_GROUP_KEYS.OTHERS;

    if (associationLevel) {
      const options = get(groupedOptions, [associationLevel, 'options'], []);
      let matchingOption: stakeHolderListAttributes;

      if (associationLevel === RECIPIENT_OPT_GROUP_KEYS.OTHERS) {
        matchingOption = find(options, { id: assign.id });
      } else {
        matchingOption = find(options, (obj) => {
          return obj.email === assign.email || obj.user_type === assign.value;
        });
      }

      return isStakeHolder(matchingOption) ? matchingOption.label : null;
    }
  }).filter(Boolean);
}

/**
 *
 * @param option
 * @returns boolean
 *
 * Some entity users have email, label and others keys but for users(others)
 * they have just if so to check if we are checking is they are stakeholders
 */
function isStakeHolder(option: stakeHolderListAttributes) {
  return option && typeof option === 'object' && 'label' in option;
}

/**
 *
 * @param actionRule
 * @returns ActionActivityTypes[]
 *
 * This is used to extract the current Action types used in the workflow.
 */
export function extractActionTypes(
  actionRule?: WorkflowActionRule<ActionTypes>[]
): ActionActivityTypes[] {
  return chain(actionRule).map('action.type').uniq().value();
}

/**
 *
 * @param actionRule
 * @returns ActionActivityTypes[]
 *
 * This function is used to extract the current Action types but excluded the custom actions
 * useful to hide action types refer generatesActionOptionTypes function.
 *
 */
export function excludeCustomFieldTypes(actionRule?: WorkflowActionRule<ActionTypes>[]) {
  return chain(extractActionTypes(actionRule))
    .filter(
      (item) =>
        item !== ActionActivityTypes.CUSTOMER_CUSTOM_FIELD &&
        item !== ActionActivityTypes.INVOICE_CUSTOM_FIELD
    )
    .value();
}

/**
 *
 * @param input
 * @param option
 * @returns boolean
 *
 * Search logic for action types
 */
export function actionTypesSearch(input: string, option?: DefaultOptionType | BaseOptionType) {
  if (get(option, 'options')) {
    return some(get(option, 'options'), (groupOption) => {
      const label = get(groupOption, 'label');
      const labelInput = get(label, 'props.option.label', '').replace(/ +/g, '').toLowerCase();
      return labelInput.includes(toLower(input));
    });
  }
  return false;
}

/**
 *
 * @param data
 * @param fieldId
 * @returns { FieldName, fieldLabel, customFieldList }
 *
 * This function is used in custom fields since we have a map of the custom field
 * object we can get the custom field data using its id and return the field name and label.
 */
export function extractCurrentCustomField(data?: CustomField[], fieldId?: number) {
  const customFieldList = new Map(map(data, (item) => [item.id, item]));

  return {
    fieldName: customFieldList.get(fieldId ?? 0)?.display_name,
    fieldLabel: {
      label: customFieldList.get(fieldId ?? 0)?.display_name,
      value: fieldId,
    },
    customFieldList,
  };
}

/**
 *
 * @param input
 * @param option
 * @returns
 *
 * Search logic for custom fields
 */
export function customFieldSearch(input: string, option?: DefaultOptionType) {
  const label = option?.title ?? (option?.label as string);
  const labelInput = label.toLowerCase();
  return labelInput.includes(input.toLowerCase());
}

export function addLevelToApprovalRequests(list: any) {
  // Ensure the input is wrapped in an array if it's not already
  if (!Array.isArray(list)) {
    list = [list];
  }

  function traverse(item: any, level = 0) {
    if (item && item.type === 'APPROVAL_REQUEST') {
      item.level = level;
    }

    // Check for nested approval requests
    if (item && typeof item === 'object') {
      for (const key in item) {
        if (Array.isArray(item[key])) {
          item[key].forEach((nestedItem) => traverse(nestedItem, level + 1));
        } else if (typeof item[key] === 'object') {
          traverse(item[key], level + 1);
        }
      }
    }
  }

  list.forEach((entry: any) => {
    traverse(entry.action, 0);
  });

  return list;
}

export function findActionById(data: any, targetId: string | number): any {
  // If the current object is an array, iterate through its elements
  if (Array.isArray(data)) {
    for (const item of data) {
      const result = findActionById(item, targetId);
      if (result) {
        return result;
      }
    }
  }
  // If the current object is an object, check for 'id' and traverse its keys
  else if (typeof data === 'object' && data !== null) {
    if (data.id === targetId) {
      return data; // Match found
    }
    for (const key in data) {
      const result = findActionById(data[key], targetId);
      if (result) {
        return result;
      }
    }
  }
  // If no match found, return null
  return null;
}

/**
 * Adds levels to approval request actions without mutating the original data.
 *
 * @param {Object} node - The root object to traverse.
 * @param {number} currentLevel - The current level in the hierarchy (default is 0).
 * @returns {Object} A new object with the added levels.
 */
export function addLevels(node: any, currentLevel = 0) {
  if (!node || typeof node !== 'object') return node;

  const newNode = { ...node, level: currentLevel };

  if (newNode?.action && newNode?.action.followup_workflow) {
    const workflow = newNode?.action?.followup_workflow;
    newNode.action.followup_workflow = {
      ...workflow,
      workflow_rules: workflow?.workflow_rules?.map((rule: WorkflowActionRule<ActionTypes>) =>
        addLevels(rule, currentLevel + 1)
      ),
    };
  }

  if (newNode.actions) {
    newNode.actions = newNode.actions.map((action: WorkflowActionRule<ActionTypes>) =>
      addLevels(action, currentLevel + 1)
    );
  }

  return newNode;
}

/**
 * Finds the maximum level in the hierarchy.
 *
 * @param {Object} node - The root object to traverse.
 * @returns {number} The maximum level found in the structure.
 */

/**
 * Finds the maximum level in a nested structure, limited to 3 levels.
 */
export function findMaxLevel(node: any, currentLevel = 0): number {
  if (!node || typeof node !== 'object' || currentLevel >= 4) {
    return node?.level || 0;
  }

  let maxLevel = node.level || 0;

  // Check nested followup_workflow
  if (node.action?.followup_workflow) {
    const workflow = node.action.followup_workflow;
    maxLevel = Math.max(
      maxLevel,
      ...workflow.workflow_rules.map((rule: any) => findMaxLevel(rule, currentLevel + 1))
    );
  }

  // Check nested actions
  if (node.actions) {
    maxLevel = Math.max(
      maxLevel,
      ...node.actions.map((action: any) => findMaxLevel(action, currentLevel + 1))
    );
  }

  return maxLevel;
}

/**
 * Retrieves the condition value and level of a specific rule.
 */
function getConditionAndLevel(
  approvalRequest?: WorkflowActionRule<ActionTypes>,
  ruleId?: string | number
): { level: number; conditionValue: any } {
  const rules = (approvalRequest?.action as ApprovalAction)?.followup_workflow?.workflow_rules;
  const rule = findActionById(rules, ruleId ?? '') as ApprovalRule;

  const conditionValue = head(
    head(get(rule, 'condition')?.conditions)?.operator.operands.value_holder
  )?.value;

  return { level: rule?.level || 0, conditionValue };
}

/**
 * Determines if the levels should include APPROVAL_REQUEST based on the max level reached.
 */
function determineApprovalLevels(
  { conditionValue, level }: { conditionValue: any; level: number },
  hasMaxReached?: number,
  filteredList?: ActionActivityTypes[]
): ActionActivityTypes[] {
  if (hasMaxReached === 4) {
    // Changed to 3 levels
    return [...(filteredList ?? []), ActionActivityTypes.APPROVAL_REQUEST];
  }
  return filteredList ?? [];
}

/**
 * Main function that processes levels, respecting the 3-level limit.
 */
function processApprovalLevels(
  list?: WorkflowActionRule<ActionTypes>[],
  ruleId?: string | number,
  filteredList?: ActionActivityTypes[]
): ActionActivityTypes[] | undefined {
  const approvalRequest = list?.find((f) => f.type === ActionActivityTypes.APPROVAL_REQUEST);

  const levels = addLevels(approvalRequest);
  const hasMaxReached = findMaxLevel(levels);

  const ruleData = getConditionAndLevel(levels, ruleId);

  return determineApprovalLevels(ruleData, hasMaxReached, filteredList);
}

export { processApprovalLevels };
