import { Collapse, CollapseProps } from 'antd';
import styled from 'styled-components';

interface SnapShotContentProps extends CollapseProps {}

const StyledCollapse = styled(Collapse)`
  border-color: var(--gray-4);
  background-color: var(--gray-2);

  .ant-collapse-expand-icon {
    padding-inline-end: var(--space-8) !important;
  }

  .ant-collapse-content {
    border-top: 0;
    max-height: 320px;
    overflow: auto;
  }
  .ant-collapse-header {
    cursor: default !important;
  }
  .ant-collapse-header-text {
    display: flex;
    align-items: center;
    height: 24px;
  }
`;
function SnapShotContent(props: SnapShotContentProps) {
  return <StyledCollapse {...props} />;
}

export { SnapShotContent };
