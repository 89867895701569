import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { RuleObject } from 'antd/lib/form';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { EVENT_TRIGGER_CONDITION_KEYS } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/type';
import { ConditionListInvoiceStatusLabelsInvoice } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import { head, isNull } from 'lodash';
import { AccountConfigKey } from 'types/entities/account';
import { CustomField } from 'types/entities/custom-field';
import {
  BetweenAbsoluteOperands,
  DefaultAbsoluteOperand,
  EqualToAbsoluteOperands,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowOperatorTypes } from '../../../type';
import {
  WorkFlowBetweenRelativeOperands,
  WorkFlowGeneralRelativeOperands,
} from '../../EventCondition/operator';
import { WorkFlowFilterOperator } from '../../EventCondition/type';
import { WorkFlowTexts } from '../../text';
import { invoiceOptionalConditionsOptionProps } from '../type';
import { WorkFlowTriggerListOptionsType } from './ConditionList/TriggerConditionList';

export type customOptionsProps = {
  options: WorkFlowTriggerListOptionsType[];
};

export const EventLabel = (
  <GrowText color="var(--gray-9)" strong>
    {WorkFlowTexts.entity}
  </GrowText>
);
export const EntityLabel = (
  <GrowText color="var(--gray-9)" strong>
    {WorkFlowTexts.type}
  </GrowText>
);
export const TypeMoreInfo = <GrowText color="var(--gray-8)">{WorkFlowTexts.moreInfo}</GrowText>;
export const FrequencyText = (
  <GrowText weight="var(--fs-semibold)" size="var(--fs-14)" color="var(--gray-9)">
    {WorkFlowTexts.frequency}
  </GrowText>
);
export const ConditionText = (
  <Flex>
    <GrowText weight="var(--fs-semibold)" color="var(--gray-9)">
      {WorkFlowTexts.conditions}
    </GrowText>
    <GrowText weight="var(--fs-semibold)" color="var(--gray-7)">
      {WorkFlowTexts.optional}
    </GrowText>
  </Flex>
);

const invoiceOptionalConditions: invoiceOptionalConditionsOptionProps[] = [
  {
    label: ConditionListInvoiceStatusLabelsInvoice?.DELIVERY_FLAG,
    key: 'DELIVERY_FLAG',
    value: 'DELIVERY_FLAG',
  },
  {
    label: ConditionListInvoiceStatusLabelsInvoice?.INVOICE_PDF,
    key: 'INVOICE_PDF',
    value: 'INVOICE_PDF',
  },
];
const InvoiceSubsidiaryOption: WorkFlowTriggerListOptionsType[] = [
  {
    key: 'INVOICE_SUBSIDIARY',
    value: 'INVOICE_SUBSIDIARY',
    label: 'Subsidiary',
  },
];
const CustomerSubsidiaryOption: WorkFlowTriggerListOptionsType[] = [
  {
    key: 'CUSTOMER_SUBSIDIARY',
    value: 'CUSTOMER_SUBSIDIARY',
    label: 'Subsidiary',
  },
];
export function useFindCustomOptions(
  entity: EVENT_TRIGGER_CONDITION_KEYS,
  options: WorkFlowTriggerListOptionsType[],
  mandatory?: boolean,
  customFields?: CustomField[]
): customOptionsProps {
  const resultantOptions =
    entity === 'INVOICE' && !mandatory
      ? options && [...options, ...invoiceOptionalConditions]
      : options;

  const updateConfig =
    customFields &&
    customFields?.map((customField) => {
      return {
        key: `${customField.display_name}-${customField.id}`,
        label: (
          <CustomFieldIconWithText hasSymbol>{customField.display_name}</CustomFieldIconWithText>
        ),
        value: customField.id,
        title: customField.display_name,
      };
    });

  const fields = Array.isArray(updateConfig)
    ? resultantOptions && [...resultantOptions, ...updateConfig]
    : resultantOptions;
  return {
    options: fields as WorkFlowTriggerListOptionsType[],
  };
}

export function operatorValidator(_: RuleObject, operatorValue: WorkFlowFilterOperator<unknown>) {
  const { type, operands } = operatorValue;
  switch (type as WorkFlowOperatorTypes) {
    case 'IN':
      const inOperand = operands as InAbsoluteOperands<number | string>;

      if (isNull(head(inOperand?.value_holder)?.value) || !inOperand?.value_holder.length) {
        return Promise.reject();
      }
      break;
    case 'EQ':
      const eqOperand = operands as EqualToAbsoluteOperands<number | string>;
      if (isNull(eqOperand?.value_holder?.value)) {
        return Promise.reject();
      }
      break;
    case 'BTWN':
      const betweenAbsOperand = operands as BetweenAbsoluteOperands<unknown>;
      const betweenRelativeOperand = operands as WorkFlowBetweenRelativeOperands;

      if (operands.type === 'RELATIVE') {
        if (
          isNull(betweenRelativeOperand.value.from.offset) ||
          isNull(betweenRelativeOperand.value.to.offset)
        ) {
          return Promise.reject();
        }
      }

      if (
        isNull(betweenAbsOperand?.value_holder?.from?.value) ||
        isNull(betweenAbsOperand?.value_holder?.to?.value)
      ) {
        return Promise.reject();
      }
      break;
    case 'GT':
    case 'LT':
      const relativeOperand = operands as WorkFlowGeneralRelativeOperands;
      if (operands.type === 'RELATIVE' && isNull(relativeOperand.value.offset)) {
        return Promise.reject();
      }
      break;
    case 'GOE':
    case 'LOE':
      const generalAbsOperand = operands as DefaultAbsoluteOperand<unknown>;
      if (isNull(generalAbsOperand?.value_holder.value)) {
        return Promise.reject();
      }
      break;

    default:
      break;
  }

  return Promise.resolve();
}
export function useGetFeatureFlagBasedOptions(
  entity: EVENT_TRIGGER_CONDITION_KEYS,
  derivedOptions: WorkFlowTriggerListOptionsType[]
): WorkFlowTriggerListOptionsType[] {
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  if (!isSubsidiaryEnabled) {
    return derivedOptions;
  }
  switch (entity) {
    case 'INVOICE':
      return derivedOptions && [...derivedOptions, ...InvoiceSubsidiaryOption];
    case 'CUSTOMER':
      return derivedOptions && [...derivedOptions, ...CustomerSubsidiaryOption];
  }
}

export function useGetFeatureFlagBasedEventOptions(
  entity: EVENT_TRIGGER_CONDITION_KEYS,
  derivedOptions: WorkFlowTriggerListOptionsType[]
): WorkFlowTriggerListOptionsType[] {
  const isSubsidiaryEnabled = useConfig(AccountConfigKey.SUBSIDIARY_ENABLED);
  if (!isSubsidiaryEnabled) {
    return derivedOptions;
  }
  switch (entity) {
    case 'INVOICE':
      return derivedOptions && [...derivedOptions];
    case 'CUSTOMER':
      return derivedOptions && [...derivedOptions, ...CustomerSubsidiaryOption];
  }
}
