import {
  DateRangeFilter,
  MinMaxFilter,
  MultiSelectCheckboxFilter,
  RadioFilter,
  RangeFilter,
} from '../Filters';
import CustomFieldRange from '../Filters/CustomFiledRange';
import { DayRangeFilter } from '../Filters/DayRange';
import { SingleSelect } from '../Filters/SingleSelect';
import { TreeMultiSelectFilter } from '../Filters/TreeMultiSelect/TreeMultiSelect';
import { FilterConfig, FilterType } from '../types';

// the value provided here is InternalValue
type InputFilterActionProps = {
  onChange: (value: any) => void;
  value: any;
};

export function getInputFilterAction(filterConfig: FilterConfig, params: InputFilterActionProps) {
  const { value, onChange } = params;
  const { label, name, options = [] } = filterConfig;

  switch (filterConfig.type) {
    case FilterType.DATE:
      return; // <Date label={label} value={value} name={key} onChange={onChange} />
    case FilterType.DATE_RANGE:
      return (
        <DateRangeFilter
          value={value}
          onChange={onChange}
          additionalProps={filterConfig.additionalProps}
        />
      );
    case FilterType.MULTI_SELECT:
      return; // <MultiSelect label={label} value={value} name={key} onChange={onChange} />
    case FilterType.MULTI_SELECT_CHECKBOX:
      return (
        <MultiSelectCheckboxFilter
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          options={options}
          allowSelectAndUnselectAll={filterConfig?.allowSelectAndUnselectAll}
          pagination={filterConfig.pagination}
        />
      );
    case FilterType.RADIO:
      return (
        <RadioFilter
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          options={options}
        />
      );
    case FilterType.SINGLE_SELECT:
      return <SingleSelect label={label} value={value} onChange={onChange} options={options} />;
    case FilterType.SINGLE_SELECT_CHECKBOX:
      return; // <SingleSelectCheckBox label={label} value={value} name={key} onChange={onChange} />
    case FilterType.RANGE:
      return (
        <RangeFilter
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          additionalProps={filterConfig.additionalProps}
        />
      );
    case FilterType.TOGGLE:
      return; // Toggle has No Dropdown Content
    case FilterType.MIN_MAX:
      return <MinMaxFilter value={value} name={name} onChange={onChange} />;
    case FilterType.DAY_RANGE:
      return (
        <DayRangeFilter
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          additionalProps={filterConfig.additionalProps}
        />
      );
    case FilterType.CUSTOM_FIELD_RANGE:
      return (
        <CustomFieldRange
          label={label}
          value={value}
          name={name}
          onChange={onChange}
          additionalProps={filterConfig.additionalProps}
        />
      );
    case FilterType.TREE_MULTI_SELECT:
      return (
        <TreeMultiSelectFilter
          label={label}
          value={value}
          name={name}
          options={filterConfig.options}
          onChange={onChange}
          additionalProps={filterConfig.additionalProps}
        />
      );
    default:
      return null;
  }
}
