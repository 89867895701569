import { GrowTypography } from '@sinecycle/growcomponents';
import { EditorCanvas } from 'components/BaseComponents/RTE/Editor';
import { sanitizeBodyAndSubject } from 'components/BaseComponents/RTE/util/sanitizeSubject';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { useRichTextEditor } from 'lib/RichText/TipTapEditor/hooks/useRichTextEditor';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { ActionTitleProps } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function EmailTitle(props: ActionTitleProps<Partial<EmailActionData>>) {
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.EMAIL.title}
      icon={actionIcons.EMAIL.icon}
      backgroundColor={actionIcons.EMAIL.style.backgroundColor}
      title={actionIcons.EMAIL.title}
      strong={props.strong}
    />
  );
}
