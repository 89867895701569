import { Skeleton } from 'antd';
import produce from 'immer';
import { head } from 'lodash';
import { useGeteDisputeTypeQuery } from 'queries/dispute-type';
import { InAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import SearchableSelect from '../../../../../../Common/SearchableSelect/SearchableSelect';
import { SingleStatusFilterProps } from '../CollectionOwnerFilter.tsx/CollectionOwnerEqFilter';

export default function DisputeTypesFilter(props: SingleStatusFilterProps) {
  const { data: disputeTypes, isLoading } = useGeteDisputeTypeQuery();
  const disputeTypeValue = props.value?.operands as InAbsoluteOperands<number>;
  const collectionOwnerUsersOption =
    disputeTypes?.map((user) => ({
      label: `${user.name}`,
      value: user.id,
    })) ?? [];

  function handleValueChange(value: string | number) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        if (draftFilter) {
          const disputeOperand = draftFilter.operands as InAbsoluteOperands<string>;
          disputeOperand.value_holder = [{ value: value as string }];
        }
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <>
      {isLoading ? (
        <Skeleton.Input />
      ) : (
        <SearchableSelect
          value={
            props.value
              ? head(disputeTypeValue.value_holder)?.value
              : head(collectionOwnerUsersOption)?.value
          }
          options={collectionOwnerUsersOption}
          onChange={handleValueChange}
        />
      )}
    </>
  );
}
