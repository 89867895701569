import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GrowButton } from '@sinecycle/growcomponents';
import { ButtonProps, FormInstance } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { EmailActionData } from 'types/entities/collection-strategy/rule-action';
import { CustomField } from 'types/entities/custom-field';
import { WorkflowMetaDto } from 'types/entities/workflow';
import { EmailErrors } from '../CustomAutomations/Action/hooks/useEmailFormHandler';
import { ActionTypes } from '../CustomAutomations/Action/types';
import { WorkFlowRule, WorkflowActionRule } from '../CustomAutomations/type';

export enum DateSelectKeys {
  ON = 'ON',
  AFTER = 'AFTER',
}

export interface WorkFlowFormProps {
  rule?: WorkFlowRule;
  onActionRemove: (data: WorkflowActionRule<ActionTypes>[], type: string) => void;
  emailFormData?: Partial<EmailActionData>;
  errors?: EmailErrors;
  handleEmailContentChange: (data: Partial<EmailActionData>) => void;
  actionRuleList?: WorkflowActionRule<ActionTypes>[];
  workflowMeta: WorkflowMetaDto;
  saveToggleOpen: () => void;
  modalOpenStatus: boolean;
  closeModal: () => void;
  form: {
    baseForm: FormInstance;
    triggerForm: FormInstance;
    actionForm: FormInstance;
  };
  loading?: boolean;
  onInsert?: (value: any) => void;
}

export interface WorkflowButtonsProps extends ButtonProps {
  toggleTriggerOpen: () => void;
  buttonTitle: string;
  disabledText?: string;
  size?: React.ComponentProps<typeof GrowButton>['size'];
}

export interface WorkflowCardTitleProps {
  title: string;
  icon: IconProp;
  hasData?: boolean;
  onClick?: () => void;
}

export const WorkFlowPayementEventFilter = [
  'PAYMENT_CREATE',
  'PAYMENT_STATUS',
  'PAYMENT_BALANCE',
] as const;

export type WorkFlowPayementEventFilterType = (typeof WorkFlowPayementEventFilter)[number];

export const WorkFlowCreditMemoEventFilter = [
  'CREDIT_MEMO_BALANCE',
  'CREDIT_MEMO_CREATED',
  'CREDIT_MEMO_STATUS',
] as const;

export type WorkFlowCreditMemoEventFilterType = (typeof WorkFlowCreditMemoEventFilter)[number];

export const WorkFlowDisputeEventFilter = [
  'DISPUTE_ASSIGNEE',
  'DUE_DATE',
  'DISPUTE_STATUS',
  'DISPUTE_TYPE',
] as const;

export type WorkFlowDisputeEventFilterType = (typeof WorkFlowDisputeEventFilter)[number];

export const WorkFlowTaskEventFilter = ['TASK_ASSIGNEE', 'DUE_DATE', 'TASK_STATUS'] as const;

export type WorkFlowTaskEventFilterType = (typeof WorkFlowTaskEventFilter)[number];

export const WorkFlowPTPEventFilter = ['PTP_DATE', 'PTP_STATUS', 'PTP_AMOUNT'] as const;

export type WorkFlowPTPEventFilterType = (typeof WorkFlowPTPEventFilter)[number];

export const WorkFlowEscalationEventFilter = [
  'ESCALATION_STATUS',
  'DUE_DATE',
  'ESCALATION_ASSIGNEE',
] as const;

export type WorkFlowEscalationEventFilterType = (typeof WorkFlowEscalationEventFilter)[number];

export const WorkFlowCustomerEventFilter = [
  'PRIMARY_CONTACT',
  'CREDIT_TERM',
  'SECONDARY_CONTACT',
  'COLLECTION_OWNER',
  'OUTSTANDING_VALUE',
  'OPEN_INVOICE_COUNT',
  'CUSTOMER_SUBSIDIARY',
] as const;

export type WorkFlowCustomerEventFilterType = (typeof WorkFlowCustomerEventFilter)[number];

export const PaymentConditionFilterLabels: Record<WorkFlowPayementEventFilterType, string> = {
  PAYMENT_BALANCE: 'Payment Balance',
  PAYMENT_CREATE: 'Payment Create',
  PAYMENT_STATUS: 'Payment Status ',
} as const;

export const CreditMemoConditionFilterLabels: Record<WorkFlowCreditMemoEventFilterType, string> = {
  CREDIT_MEMO_BALANCE: 'Credit Memo Balance',
  CREDIT_MEMO_CREATED: 'Credit Memo Create',
  CREDIT_MEMO_STATUS: 'Credit Memo Status ',
} as const;

export const DisputeConditionFilterLabels: Record<WorkFlowDisputeEventFilterType, string> = {
  DISPUTE_ASSIGNEE: 'Dispute Owner',
  DISPUTE_STATUS: 'Dispute Status',
  DISPUTE_TYPE: 'Dispute Type',
  DUE_DATE: 'Dispute Age',
} as const;

export const TaskConditionFilterLabels: Record<WorkFlowTaskEventFilterType, string> = {
  DUE_DATE: 'Task Due Date',
  TASK_ASSIGNEE: 'Task Owner',
  TASK_STATUS: 'Task Status ',
} as const;

export const PTPConditionFilterLabels: Record<WorkFlowPTPEventFilterType, string> = {
  PTP_AMOUNT: 'PTP Amount',
  PTP_DATE: 'PTP Date',
  PTP_STATUS: 'PTP Status',
} as const;

export const EscalationConditionFilterLabels: Record<WorkFlowEscalationEventFilterType, string> = {
  DUE_DATE: 'Escalation Due Date',
  ESCALATION_ASSIGNEE: 'Escalation Owner',
  ESCALATION_STATUS: 'Escalation Status',
} as const;

export const CustomerConditionFilterLabels: Record<WorkFlowCustomerEventFilterType, string> = {
  COLLECTION_OWNER: 'Collection Owner Changed',
  CREDIT_TERM: 'Credit Term Changed',
  OPEN_INVOICE_COUNT: 'No. of Open Invoices Changed',
  OUTSTANDING_VALUE: 'Customer Outstanding Value Changed',
  PRIMARY_CONTACT: 'Primary Contact Changed',
  SECONDARY_CONTACT: 'Secondary Contact Changed',
  CUSTOMER_SUBSIDIARY: 'Subsidiary',
} as const;

export type PaymentOptionsType = {
  label: string;
  value: WorkFlowPayementEventFilterType;
  key: WorkFlowPayementEventFilterType;
};

export const PaymentConditionOptions: PaymentOptionsType[] = [
  {
    label: PaymentConditionFilterLabels.PAYMENT_BALANCE,
    value: 'PAYMENT_BALANCE',
    key: 'PAYMENT_BALANCE',
  },
  {
    label: PaymentConditionFilterLabels.PAYMENT_STATUS,
    value: 'PAYMENT_STATUS',
    key: 'PAYMENT_STATUS',
  },
  {
    label: PaymentConditionFilterLabels.PAYMENT_CREATE,
    value: 'PAYMENT_CREATE',
    key: 'PAYMENT_CREATE',
  },
];

export type DisputeOptionsType = {
  label: string;
  value: WorkFlowDisputeEventFilterType;
  key: WorkFlowDisputeEventFilterType;
};

export const DisputeConditionOptions: DisputeOptionsType[] = [
  {
    label: DisputeConditionFilterLabels.DISPUTE_ASSIGNEE,
    value: 'DISPUTE_ASSIGNEE',
    key: 'DISPUTE_ASSIGNEE',
  },
  {
    label: DisputeConditionFilterLabels.DISPUTE_STATUS,
    value: 'DISPUTE_STATUS',
    key: 'DISPUTE_STATUS',
  },
  {
    label: DisputeConditionFilterLabels.DISPUTE_TYPE,
    value: 'DISPUTE_TYPE',
    key: 'DISPUTE_TYPE',
  },
  {
    label: DisputeConditionFilterLabels.DUE_DATE,
    value: 'DUE_DATE',
    key: 'DUE_DATE',
  },
];

type CreditMemoOptionsType = {
  label: string;
  value: WorkFlowCreditMemoEventFilterType;
  key: WorkFlowCreditMemoEventFilterType;
};

export const CreditMemoConditionOptions: CreditMemoOptionsType[] = [
  {
    label: CreditMemoConditionFilterLabels.CREDIT_MEMO_BALANCE,
    value: 'CREDIT_MEMO_BALANCE',
    key: 'CREDIT_MEMO_BALANCE',
  },
  {
    label: CreditMemoConditionFilterLabels.CREDIT_MEMO_STATUS,
    value: 'CREDIT_MEMO_STATUS',
    key: 'CREDIT_MEMO_STATUS',
  },
  {
    label: CreditMemoConditionFilterLabels.CREDIT_MEMO_CREATED,
    value: 'CREDIT_MEMO_CREATED',
    key: 'CREDIT_MEMO_CREATED',
  },
];

export type TaskOptionsType = {
  label: string;
  value: WorkFlowTaskEventFilterType;
  key: WorkFlowTaskEventFilterType;
};

export const TaskConditionOptions: TaskOptionsType[] = [
  {
    label: TaskConditionFilterLabels.TASK_ASSIGNEE,
    value: 'TASK_ASSIGNEE',
    key: 'TASK_ASSIGNEE',
  },
  {
    label: TaskConditionFilterLabels.TASK_STATUS,
    value: 'TASK_STATUS',
    key: 'TASK_STATUS',
  },
  {
    label: TaskConditionFilterLabels.DUE_DATE,
    value: 'DUE_DATE',
    key: 'DUE_DATE',
  },
];
export type PTPOptionsType = {
  label: string;
  value: WorkFlowPTPEventFilterType;
  key: WorkFlowPTPEventFilterType;
};

export const PTPConditionOptions: PTPOptionsType[] = [
  {
    label: PTPConditionFilterLabels.PTP_AMOUNT,
    value: 'PTP_AMOUNT',
    key: 'PTP_AMOUNT',
  },
  {
    label: PTPConditionFilterLabels.PTP_DATE,
    value: 'PTP_DATE',
    key: 'PTP_DATE',
  },
  {
    label: PTPConditionFilterLabels.PTP_STATUS,
    value: 'PTP_STATUS',
    key: 'PTP_STATUS',
  },
];
export type EscalationOptionsType = {
  label: string;
  value: WorkFlowEscalationEventFilterType;
  key: WorkFlowEscalationEventFilterType;
};

export const EscalationConditionOptions: EscalationOptionsType[] = [
  {
    label: EscalationConditionFilterLabels.DUE_DATE,
    value: 'DUE_DATE',
    key: 'DUE_DATE',
  },
  {
    label: EscalationConditionFilterLabels.ESCALATION_ASSIGNEE,
    value: 'ESCALATION_ASSIGNEE',
    key: 'ESCALATION_ASSIGNEE',
  },
  {
    label: EscalationConditionFilterLabels.ESCALATION_STATUS,
    value: 'ESCALATION_STATUS',
    key: 'ESCALATION_STATUS',
  },
];
type CustomerOptionsType = {
  label: string;
  value: WorkFlowCustomerEventFilterType;
  key: WorkFlowCustomerEventFilterType;
};

export const CustomerConditionOptions: CustomerOptionsType[] = [
  {
    label: CustomerConditionFilterLabels.COLLECTION_OWNER,
    value: 'COLLECTION_OWNER',
    key: 'COLLECTION_OWNER',
  },
  {
    label: CustomerConditionFilterLabels.CREDIT_TERM,
    value: 'CREDIT_TERM',
    key: 'CREDIT_TERM',
  },
  {
    label: CustomerConditionFilterLabels.OPEN_INVOICE_COUNT,
    value: 'OPEN_INVOICE_COUNT',
    key: 'OPEN_INVOICE_COUNT',
  },
  {
    label: CustomerConditionFilterLabels.OUTSTANDING_VALUE,
    value: 'OUTSTANDING_VALUE',
    key: 'OUTSTANDING_VALUE',
  },
  {
    label: CustomerConditionFilterLabels.PRIMARY_CONTACT,
    value: 'PRIMARY_CONTACT',
    key: 'PRIMARY_CONTACT',
  },
  {
    label: CustomerConditionFilterLabels.SECONDARY_CONTACT,
    value: 'SECONDARY_CONTACT',
    key: 'SECONDARY_CONTACT',
  },
];
export interface DateOffsetSelectProps {
  title: string;
  label: string;
  initialValue: string;
  form: FormInstance;
  onText?: string;
  value?: number;
}

export interface CustomerValuesSelectProps {
  selectedOption?: CustomField;
  value?: string | number;
  form: FormInstance;
  formKey: string;
}

export interface ActionTileTitleProps {
  tooltipTitle: string;
  icon: ReactElement;
  title: string;
  backgroundColor: string;
  strong?: boolean;
  custom?: React.ReactNode;
  titleStyle?: React.CSSProperties;
}

export interface CustomFieldTitleProps {
  title: string;
  backgroundColor: string;
  icon: JSX.Element;
  field?: ReactNode;
  value?: string;
  isLoading?: boolean;
  strong?: boolean;
  name?: string;
  fieldStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
}
