import { Form } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import AssignOwners from 'components/Common/ActivitiesForm/FormElements/AssignOwners';
import { useComputeInternalContactRecepient } from 'components/Common/ActivitiesForm/Utils';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import { FormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { WorkflowForms } from '../../../type';
import AssigneeReadOnly from '../../ActionConfigurations/TitleComponents/AssigneeReadOnly';
import { t } from '../../text';
import { ActionActivityTypes } from '../../types';
import { computeActionableEntity, extractAssignToLabel } from '../../utils';
import { ActionType } from '../ActionType';

export default function RaiseEscalation(props: any) {
  const { isInvoiceLevel, data, form } = props;
  const assigneeOptionTypes = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;
  const { isWorkflowOwner } = useWorkflowOwner();
  const AssignTo = (
    <FormItem
      label={t.assign_to}
      name="escalation_assign_to"
      rules={[{ required: true, message: t.assigned_to_required }]}
      initialValue={data?.assign_to}
      preserve={false}
    >
      <AssignOwners
        placeholder={t.add_members}
        listHeight={140}
        context={'STRATERGY'}
        assigneeOptionType={assigneeOptionTypes}
        maxTagCount={'responsive'}
        value={data?.assign_to}
      />
    </FormItem>
  );

  const Description = (
    <FormItem
      label={t.description}
      name="escalation_description"
      rules={[{ required: true, message: t.enter_escalation_description }]}
      initialValue={data?.description}
      preserve={false}
    >
      <DescriptionTextArea placeholder={t.description_of_escalation} value={data?.description} />
    </FormItem>
  );

  return (
    <>
      {isWorkflowOwner ? (
        <RaiseEscalationReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          {AssignTo}
          {Description}
          <ActionType value={ActionActivityTypes.ESCALATION} />
        </Form>
      )}
    </>
  );
}

export function RaiseEscalationReadOnly(props: any) {
  const { isInvoiceLevel, data } = props;
  const assigneeOptionTypes = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;

  const actionableEntity = computeActionableEntity(
    ActionActivityTypes.ESCALATION,
    Boolean(assigneeOptionTypes)
  );
  const { computedGroupedOptions: groupedOptions } = useComputeInternalContactRecepient({
    entity: actionableEntity,
  });
  const assignees = extractAssignToLabel(groupedOptions, data?.assign_to);

  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent
        label="Assign To"
        content={<AssigneeReadOnly assignees={assignees as string[]} />}
      />
      <InfoContent label="Description" content={<TextBlock value={data?.description} />} />
    </div>
  );
}
