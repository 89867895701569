import { Form, Input } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import AssignOwners from 'components/Common/ActivitiesForm/FormElements/AssignOwners';
import { useComputeInternalContactRecepient } from 'components/Common/ActivitiesForm/Utils';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import DateOffsetSelect from 'components/Settings/WorkFlowAutomation/common/Date-select';
import { DateSelectKeys } from 'components/Settings/WorkFlowAutomation/common/types';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { FormItem } from '../../../../common/style';
import { WorkflowForms } from '../../../type';
import AssigneeReadOnly from '../../ActionConfigurations/TitleComponents/AssigneeReadOnly';
import { t } from '../../text';
import { ActionActivityTypes, AddTaskActionProps } from '../../types';
import { computeActionableEntity, extractAssignToLabel } from '../../utils';
import { ActionType } from '../ActionType';

export default function AddTask(props: AddTaskActionProps) {
  const { isInvoiceLevel, form, data } = props;

  const { isWorkflowOwner } = useWorkflowOwner();
  const assigneeOptionTypes = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;
  const Title = (
    <FormItem
      label={t.task_title}
      name="title"
      rules={[{ required: true, message: t.task_title_needed }]}
      initialValue={data?.title}
    >
      <Input placeholder={t.enter_task_title} />
    </FormItem>
  );

  const AssignTaskTo = (
    <FormItem
      label={t.assign_task_to}
      name="task_assign_to"
      rules={[{ required: true, message: 'task owner is required' }]}
      initialValue={data?.assign_to}
    >
      <AssignOwners
        placeholder={t.add_members}
        listHeight={140}
        context={'STRATERGY'}
        assigneeOptionType={assigneeOptionTypes}
      />
    </FormItem>
  );

  const Description = (
    <FormItem
      label={t.description}
      name="task_description"
      rules={[{ required: true, message: t.enter_task_description }]}
      initialValue={data?.description}
    >
      <DescriptionTextArea placeholder={t.task_note} />
    </FormItem>
  );

  const DueDate = (
    <DateOffsetSelect
      title={t.task_creation_date}
      onText={t.task_creation_date_on}
      label={t.due_date}
      initialValue={!data?.due_date_offset ? DateSelectKeys.ON : DateSelectKeys.AFTER}
      form={form}
      value={data?.due_date_offset}
    />
  );

  return (
    <>
      {isWorkflowOwner ? (
        <AddTaskReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          {Title}
          {AssignTaskTo}
          {Description}
          {DueDate}
          <ActionType value={ActionActivityTypes.TASK} />
        </Form>
      )}
    </>
  );
}

export function AddTaskReadOnly(props: AddTaskActionProps) {
  const { isInvoiceLevel, data } = props;

  const assigneeOptionTypes = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;

  const actionableEntity = computeActionableEntity(
    ActionActivityTypes.TASK,
    Boolean(assigneeOptionTypes)
  );
  const { computedGroupedOptions: groupedOptions } = useComputeInternalContactRecepient({
    entity: actionableEntity,
  });
  const assignees = extractAssignToLabel(groupedOptions, data?.assign_to as any);
  const dateOffest = !data?.due_date_offset ? DateSelectKeys.ON : DateSelectKeys.AFTER;
  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent label="Task Title" content={<TextBlock value={data?.title} />} />
      <InfoContent
        label="Assign Task To"
        content={<AssigneeReadOnly assignees={assignees as string[]} />}
      />
      <InfoContent label="Description" content={<TextBlock value={data?.description} />} />
      {data?.due_date_offset ? (
        <InfoContent
          label={t.due_date}
          content={
            <TextBlock
              value={`${dateOffest === DateSelectKeys.ON ? 'On' : 'After'} ${
                data?.due_date_offset
              }  from the task creation date`}
            />
          }
        />
      ) : null}
    </div>
  );
}
