import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GrowButton, GrowTypography } from '@sinecycle/growcomponents';
import { Form, Input, Popconfirm } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
  getUserApprovalStatusConfig,
  UserApprovalRequestUpdate,
  UserApprovalStatus,
} from 'pages/Approvals/type';

type ButtonProps = React.ComponentProps<typeof GrowButton>;
interface ApprovalActionbuttonProps {
  validation: boolean;
  title: React.ReactNode;
  okText: string;
  buttonProps: ButtonProps;
  buttonText: string;
  onSave?: (value: ApprovalForm) => void;
}

interface ApprovalForm {
  comments?: string;
}

function ApprovalActionbutton(props: ApprovalActionbuttonProps) {
  const { validation, title, buttonProps, okText, buttonText, onSave } = props;
  const [form] = useForm<ApprovalForm>();

  const Comments = (
    <Form form={form} layout="vertical" requiredMark={validation}>
      <Form.Item
        name="comments"
        rules={[{ required: validation, message: 'Comments is required' }]}
        label="Comments"
        style={{ marginBottom: 'var(--space-16)' }}
      >
        <Input.TextArea placeholder="Enter comments" />
      </Form.Item>
    </Form>
  );

  async function handleConfirm() {
    try {
      const values = await form.validateFields();
      onSave?.(values);
      form.resetFields();
    } catch (error) {
      throw new Error('Validation failed');
    }
  }
  function handleCancel() {
    form.resetFields();
  }

  return (
    <Popconfirm
      title={title}
      okText={okText}
      trigger={['click']}
      placement="left"
      icon={false}
      description={Comments}
      destroyTooltipOnHide
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      overlayInnerStyle={{ padding: 'var(--space-16)' }}
      okButtonProps={{
        style: {
          background: validation ? 'var(--red-7)' : 'var(--primary-7)',
        },
      }}
    >
      <GrowButton {...buttonProps}>{buttonText}</GrowButton>
    </Popconfirm>
  );
}

interface ApprovalActionProps {
  status: UserApprovalStatus;
  onSubmit?: (value: UserApprovalRequestUpdate) => void;
  loading?: boolean;
}
function ApprovalAction(props: ApprovalActionProps) {
  const { status, onSubmit, loading } = props;

  const text = status === 'APPROVED' ? 'Approve' : 'Reject';
  const saveText = status === 'APPROVED' ? 'Yes, Approve' : 'Yes, Reject';

  const config = getUserApprovalStatusConfig(status);
  const buttonIconColor =
    status === 'APPROVED' ? 'var(--gray-1)' : config?.statusConfig.style.textColor;
  const Title = (
    <div className="tw-flex tw-items-center tw-gap-8">
      <FontAwesomeIcon
        icon={config?.icon as IconProp}
        color={config?.statusConfig.style.textColor}
      />
      <GrowTypography.Text>{text}</GrowTypography.Text>
    </div>
  );
  function OnSave(value: ApprovalForm) {
    onSubmit?.({ status, comment: value.comments });
  }

  return (
    <ApprovalActionbutton
      validation={status === 'REJECTED'}
      title={Title}
      okText={saveText}
      buttonProps={{
        type: status === 'APPROVED' ? 'primary' : 'default',
        icon: <FontAwesomeIcon icon={config?.icon as IconProp} color={buttonIconColor} />,
        style: {
          backgroundColor: status === 'APPROVED' ? 'var(--green-8)' : '',
          color: buttonIconColor,
        },
        loading,
      }}
      buttonText={text}
      onSave={OnSave}
    />
  );
}

export { ApprovalAction };
