import { Form } from 'antd';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import CollectionStrategySelect from 'components/Common/SelectComponents/CollectionStrategy/CollectionStrategySelect';
import useCollectionStrategiesData, {
  useGetCollectionStrategyInitialValue,
} from 'components/Common/SelectComponents/hooks/usecollectionStrategiesData';
import { EditFieldsFormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { find } from 'lodash';
import { WorkflowForms } from '../../../type';
import { t } from '../../text';
import { ActionActivityTypes, UpdateCollectionStrategyProps } from '../../types';
import { ActionType } from '../ActionType';

export default function UpdateCollectionStrategy(props: UpdateCollectionStrategyProps) {
  const { data, form } = props;
  const value = data?.value ? parseInt(data?.value) : undefined;
  const collectionStrategiesId = useGetCollectionStrategyInitialValue(value);
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <UpdateCollectionStrategyReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          <EditFieldsFormItem
            name={'collection_strategy_value'}
            label={t.collection_strategy}
            initialValue={collectionStrategiesId}
            rules={[{ required: true, message: t.collection_strategy_required }]}
          >
            <CollectionStrategySelect
              allowClear
              style={{ width: '290px' }}
              placeHolder={t.select_collection_strategy}
            />
          </EditFieldsFormItem>
          <ActionType value={ActionActivityTypes.EDIT_STRATEGY} />
        </Form>
      )}
    </>
  );
}

export function UpdateCollectionStrategyReadOnly(props: UpdateCollectionStrategyProps) {
  const { data } = props;
  const value = data?.value ? parseInt(data?.value) : undefined;
  const { data: collections } = useCollectionStrategiesData();
  const collectionStrategyName = find(collections, (strategyItem) => strategyItem.id === value);

  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent label={t.collection_strategy} content={collectionStrategyName?.title} />
    </div>
  );
}
