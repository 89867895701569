import { DatePicker } from 'components/BaseComponents/AntComponents';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { DefaultAbsoluteOperand } from 'types/entities/invoice-segments/operands';
import { DateWorkFlowFilterOperator } from '../../EventCondition/type';

interface SingleDateFilterProps {
  value?: DateWorkFlowFilterOperator<Dayjs>;
  onChange?: (updatedFilter: DateWorkFlowFilterOperator<Dayjs>) => void;
}

export default function SingleDateFieldFilter(props: SingleDateFilterProps) {
  const currentdDateValue = props.value?.operands as DefaultAbsoluteOperand<Dayjs>;
  const formattedValue = props?.value ? dayjs(currentdDateValue?.value_holder?.value) : dayjs();
  const format = useSelector(dateFormatSelector);
  function handleValueChange(date: dayjs.Dayjs | null) {
    const updatedFilter = produce(props.value, (draftFilter) => {
      if (date && draftFilter) {
        const currentdDateValue = draftFilter.operands as DefaultAbsoluteOperand<Dayjs>;
        currentdDateValue.value_holder.value = date;
      }
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <StyledFormItem shouldUpdate>
      <DatePicker
        defaultValue={formattedValue}
        onChange={handleValueChange}
        format={format}
        allowClear={false}
      />
    </StyledFormItem>
  );
}
