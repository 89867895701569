import { RadioGroup } from 'components/BaseComponents/Radio';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { WorkFlowOperatorOptions } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import { WorkFlowOperator } from '../../../type';
import { WorkFlowTexts } from '../../text';

export function TriggerFollowUpOperator(props: { operator?: WorkFlowOperator }) {
  return (
    <StyledFormItem
      name={'followup_operator'}
      rules={[
        {
          required: true,
          message: WorkFlowTexts.operatorError,
        },
      ]}
      initialValue={props.operator ?? 'AND'}
    >
      <RadioGroup
        size="small"
        options={WorkFlowOperatorOptions}
        optionType="button"
        style={{ borderRadius: 'var(--space-8)' }}
      />
    </StyledFormItem>
  );
}
