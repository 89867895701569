import ActivitiesModalLoader from 'components/CollectionActivities/Common/ActivitiesModalLoader';
import useEmitActivityEvents from 'components/CollectionActivities/hooks/useEmitActivityEvents';
import { ActivityLoader } from 'components/CollectionActivities/style';
import { useCollectionActivityQuery } from 'queries/activites';
import { ActivityDetailsBaseProps } from 'types/activities/activity-types';
import { Note } from 'types/activities/note';
import { NotesDetailsLayout } from './NotesDetailsLayout';

interface NoteDetailsProps extends ActivityDetailsBaseProps {}
function NoteDetails(props: NoteDetailsProps) {
  const {
    id,
    customer,
    type,
    hideActions,
    mode,
    subscriptionType,
    isListFetched,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
  } = props;
  const { data, isLoading, error } = useCollectionActivityQuery<Note>({ type, id, isListFetched });
  const isUnauthorized = error ? error?.status === 403 : false;
  useEmitActivityEvents(isLoading);
  if (activitiesMode && isLoading) {
    return (
      <ActivityLoader>
        <ActivitiesModalLoader />
      </ActivityLoader>
    );
  }

  return (
    <NotesDetailsLayout
      {...props}
      data={data}
      isLoading={isLoading}
      isUnauthorized={isUnauthorized}
    />
  );
}

export { NoteDetails };
