import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { CollectionStatusItem } from 'types/entities/collection-followup';
import { CollectionStatusBadge } from './style';

interface OptionRenderProps {
  option: CollectionStatusItem;
  mode?: string;
}

export default function OptionRender(props: OptionRenderProps) {
  const { option, mode } = props;

  return (
    <div className="collection-status" color={option.color_code}>
      <Flex gap="var(--space-4)" align="center">
        <CollectionStatusBadge
          color={option.color_code}
          className={mode === 'CELL' ? 'cell' : ''}
        />
        <GrowText
          ellipsis={{
            tooltip: {
              title: option.label,
              placement: 'right',
            },
          }}
          size={mode === 'DETAILS' ? 'var(--space-16)' : 'var(--space-14)'}
        >
          {option.label}
        </GrowText>
      </Flex>
    </div>
  );
}
