import { map } from 'lodash';
import { useEnityCustomFieldQuery } from 'queries/custom-field';
import { useGetWorkflowApprovers } from 'queries/workflow';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usersSelector } from 'store/user/user';
import { BaseRecepientsType } from 'types/entities/collection-strategy/contact-type';
import { CustomFieldPage } from 'types/entities/custom-field';
import { useWorkflowOwner } from '../../WorkflowCardList/Hooks';
import {
  ApprovalApproverCFContact,
  ApprovalApproverContactContact,
  ApprovalContact,
  ApprovalUserContact,
} from '../Action/types';

export function useEmailGroupedOptions(value?: ApprovalContact[]) {
  const { data: invoiceCf, isLoading: invoiceCfLoading } = useEnityCustomFieldQuery(
    CustomFieldPage.APPROVER
  );
  const users = useSelector(usersSelector);
  const { data: approvers, isLoading: approversLoading } = useGetWorkflowApprovers();
  const { isWorkflowOwner } = useWorkflowOwner();
  const getCfmails = useCallback((): ApprovalApproverCFContact[] => {
    return map([...(invoiceCf ?? [])], (customField) => ({
      type: BaseRecepientsType.APPROVER_CF,
      id: customField.id,
      label: customField.display_name,
      value: customField.id,
    }));
  }, [invoiceCf]);

  const getAppovers = useCallback((): ApprovalUserContact[] => {
    return map([...(approvers ?? [])], (users) => ({
      type: BaseRecepientsType.USER,
      id: users.id,
      label: users.email,
      value: users.id,
      email: users.email,
    }));
  }, [approvers]);

  const getUsers = useCallback((): ApprovalUserContact[] => {
    return map([...(users ?? [])], (users) => ({
      type: BaseRecepientsType.USER,
      id: users.id,
      label: users.email,
      value: users.id,
      email: users.email,
    }));
  }, [users]);

  const loading = invoiceCfLoading || approversLoading;

  const customFileds = getCfmails();
  const approversList = getAppovers();

  function filterApproversList() {
    // Extract constant emails from the `value` array
    const constantEmails = value
      ?.filter((item) => item.type === BaseRecepientsType.APPROVER_CONSTANT)
      .map((item) => item.email);

    // Filter out approvers whose emails are in the `constantEmails` list
    const filteredApprovalList = approversList.filter(
      (approver) => approver.email && !constantEmails?.includes(approver.email)
    );

    // Find items in `value` that are of type APPROVER_CONSTANT but not in `approversList`
    const nonExistentValues = value?.filter(
      (item) => item.type === BaseRecepientsType.APPROVER_CONSTANT
    ) as ApprovalApproverContactContact[];
    // Map `nonExistentValues` to the desired format
    const values: ApprovalApproverContactContact[] = nonExistentValues?.map((item) => ({
      type: BaseRecepientsType.APPROVER_CONSTANT,
      email: item.email,
      label: item.email,
      value: item.email,
    }));

    return { filteredApprovalList, values };
  }
  const { filteredApprovalList, values } = filterApproversList();

  const userOptions = getUsers();
  const combinedOptions =
    !loading && invoiceCf && approvers
      ? [...customFileds, ...filteredApprovalList, ...(values ?? [])]
      : [];

  const readOnlyOptions = [...customFileds, ...userOptions];
  return { loading, combinedOptions: isWorkflowOwner ? readOnlyOptions : combinedOptions };
}
