import { useGetCollectionOwner } from 'components/HigherOrderComponent/KeyActivitesContainer/ProfileCards/InternalContacts/InternalContactHooks';

export default function useGetCollectionOwnerData() {
  const { data: collectionOwnerUsers, isLoading } = useGetCollectionOwner();

  const collectionOwnerUsersOptions =
    collectionOwnerUsers?.map((user) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
      valueobject: user,
    })) ?? [];

  return { collectionOwnerUsersOptions, collectionOwnerUsers, isLoading };
}
