import { CustomFieldIconWithText, GrowFlex } from '@sinecycle/growcomponents';
import { Form, Select, Skeleton, Spin } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { CustomFieldSelect } from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import { EditFieldsFormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { WorkflowForms } from '../../../type';
import useInvoiceFieldData from '../../hooks/useInvoiceFieldData';
import { t } from '../../text';
import { ActionActivityTypes, InvoiceCustomFieldProps } from '../../types';
import { customFieldSearch } from '../../utils';
import { ActionType } from '../ActionType';

export default function InvoiceCustomField(props: InvoiceCustomFieldProps) {
  const { form, data, baseForm } = props;
  const { isWorkflowOwner } = useWorkflowOwner();
  const selectedFieldId = useFormWatch('field_id', form);
  const { invoiceOptions, selectedLabel, customFieldList, isLoading } = useInvoiceFieldData({
    form: baseForm,
    fieldId: data?.field_id,
  });

  const InvoiceFieldSelect = (
    <EditFieldsFormItem
      label={t.customer_field_name}
      name={'field_id'}
      initialValue={customFieldList.get(data?.field_id ?? 0) ? selectedLabel : undefined}
      rules={[{ required: true, message: t.field_name_required }]}
    >
      <Select
        showSearch
        filterOption={(input, option) => customFieldSearch(input, option)}
        placeholder={t.select_invoice_customer_field}
        onChange={(val) => {
          const customFieldLabel = customFieldList.get(val);
          form.setFieldsValue({
            field_id: {
              label: customFieldLabel?.display_name,
              value: customFieldLabel?.id,
            },
            invoice_custom_value: '',
          });
        }}
        options={invoiceOptions}
        loading={isLoading}
        disabled={isLoading}
        labelRender={(option) => (
          <CustomFieldIconWithText hasSymbol>{option?.label}</CustomFieldIconWithText>
        )}
        suffixIcon={isLoading ? <Spin size="small" /> : undefined}
      />
    </EditFieldsFormItem>
  );

  const InvoiceValueSelect = (
    <EditFieldsFormItem
      label={selectedFieldId?.label ? t.value : ''}
      name={'invoice_custom_value'}
      initialValue={data?.value}
      rules={[{ required: true, message: t.value_required }]}
    >
      <CustomFieldSelect
        selectedOption={customFieldList.get(selectedFieldId?.value || data?.field_id)}
        form={form}
        formKey={'invoice_custom_value'}
      />
    </EditFieldsFormItem>
  );

  return (
    <>
      {isWorkflowOwner ? (
        <InvoiceCustomFieldReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          {isLoading ? (
            <GrowFlex space="24" vertical>
              <Skeleton.Input />
              <Skeleton.Input />
            </GrowFlex>
          ) : (
            <>
              {InvoiceFieldSelect}
              {InvoiceValueSelect}
            </>
          )}

          <ActionType value={ActionActivityTypes.INVOICE_CUSTOM_FIELD} />
        </Form>
      )}
    </>
  );
}

export function InvoiceCustomFieldReadOnly(props: InvoiceCustomFieldProps) {
  const { data } = props;

  const { fieldName, isLoading } = useInvoiceFieldData({
    fieldId: data?.field_id,
  });
  const field = (
    <CustomFieldIconWithText hasSymbol strong={false}>
      {fieldName}
    </CustomFieldIconWithText>
  );
  return (
    <Skeleton active loading={isLoading}>
      <div className="tw-flex tw-flex-col tw-gap-24">
        <InfoContent label="Field Name" content={field} />
        <InfoContent label="Value" content={<TextBlock value={data?.value} />} />
      </div>
    </Skeleton>
  );
}
