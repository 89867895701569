import { useQueryClient } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { ContactTypeItem } from 'components/Settings/ContactTypes/ContactTypeCard';
import { head, map } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allUsers } from 'services/users';
import { ParticipantRelationKeys } from 'store/contacts/contacts';
import { getActiveUsersHandler, usersSelector } from 'store/user/user';
import { EmailableType } from 'types/activities/email';
import {
  ArEmailContact,
  BaseRecepientsType,
  CustomerContact,
  CustomerParentContact,
  PocContact,
  RelativeContact,
  UserContact,
} from 'types/entities/collection-strategy/contact-type';
import { ContactType, ParentContactType } from 'types/entities/contact';
import {
  ContactGroupLabel,
  generateUniqueID,
  getCustomerContactType,
  isCustomerParentContactType,
  useCustomerContactTypes,
  useInternalContactForEntity,
  useRelativeContactTypes,
} from '../Contact/ContactType';
import { RecepientList } from './RecepientList';

const defaultSelectStyle: React.CSSProperties = { width: '100%' };

const { Option, OptGroup } = Select;

function getSelectValue(value: RelativeContact) {
  switch (value.type) {
    case BaseRecepientsType.USER:
      return value.id;
    case BaseRecepientsType.CONTACT_TYPE:
    case BaseRecepientsType.POC:
      return value.value;
    case BaseRecepientsType.AR_EMAIL:
      return value.id;
    default:
      return value;
  }
}

const setSelectedValue = (isCustomerContact: ReturnType<typeof useCustomerContactTypes>[1]) => {
  return (value: ReturnType<typeof getSelectValue>, options?: OptionType[]) => {
    if (head(options)?.type === BaseRecepientsType.AR_EMAIL) {
      return {
        type: BaseRecepientsType.AR_EMAIL,
        id: value,
      } as ArEmailContact;
    }

    if (typeof value === 'number') {
      return getUserOptionFromId(value);
    }

    if (value === BaseRecepientsType.AR_EMAIL) {
      return {
        type: BaseRecepientsType.AR_EMAIL,
      } as ArEmailContact;
    }

    const option = options?.find((option) => option?.record?.email === value);

    if (option && option.record && option.record.type === EmailableType.CONTACT) {
      return {
        type: BaseRecepientsType.CONTACT_TYPE,
        participant_relation: option.record.participant_relation,
        contact_type_identifier: option.record.contact_type_identifier,
        contact_type_id: option.record.id,
      } as CustomerContact;
    }

    if (isCustomerParentContactType(value as ParentContactType)) {
      return {
        type: BaseRecepientsType.CONTACT_TYPE,
        value: getCustomerContactType(value as ContactType | ParentContactType),
        participant_relation: ParticipantRelationKeys.PARENT,
      } as unknown as CustomerContact;
    }

    const isInternalContact = option?.record && option.record.type === EmailableType.USER;

    return {
      type: BaseRecepientsType.POC,
      value: option?.record?.user_type ?? value,
      ...(isInternalContact && { association_level: option?.record?.association_level }),
    } as PocContact;
  };
};

function getUserOptionFromId(userId: number): UserContact {
  return {
    type: BaseRecepientsType.USER,
    id: userId,
  };
}

type AntSelectProps = Parameters<typeof Select>[0];
const filterContacts: AntSelectProps['filterOption'] = (search, optionOrGroup) => {
  if (!optionOrGroup) return false;
  const isGroup = Array.isArray(optionOrGroup.options);
  if (isGroup) return false;

  if (typeof optionOrGroup.children === 'string') {
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }

  if (optionOrGroup.type === BaseRecepientsType.AR_EMAIL) {
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase());
  }

  return false;
};

interface BaseRelativeContactSelectProps {
  showEmailableOptions?: boolean;
  showAREmail?: boolean;
}

type RelativeContactSelectProps = BaseRelativeContactSelectProps &
  (
    | ({ mode: 'multi' } & Omit<RelativeContactMultiSelectProps, 'optGroups'>)
    | ({ mode: 'single' } & Omit<RelativeContactSingleSelectProps, 'optGroups'>)
  );

export function getTransformedSelection(selection: RelativeContact) {
  if (selection.type === 'CONTACT_TYPE' && selection.contact_type_id) {
    const entityType =
      selection.participant_relation === 'PARENT' &&
      selection.contact_type_identifier === 'CUSTOMER'
        ? 'PARENT_CUSTOMER'
        : selection.contact_type_identifier === 'CUSTOMER'
        ? 'CUSTOMER_BILLING_CONTACT'
        : 'INVOICE_BILLING_CONTACT';

    return {
      ...selection,
      value: generateUniqueID(entityType, selection.contact_type_id),
    } as CustomerContact | CustomerParentContact;
  }

  if (selection.type === 'POC') {
    const entityType =
      selection.association_level === 'CUSTOMER'
        ? 'CUSTOMER_INTERNAL_CONTACT'
        : 'INVOICE_INTERNAL_CONTACT';

    return {
      ...selection,
      value: generateUniqueID(entityType, selection.value),
    } as PocContact;
  }

  return selection;
}

export function RelativeContactSelect(props: RelativeContactSelectProps) {
  const users = useSelector(usersSelector) ?? [];
  const dispatch = useDispatch();
  const { showEmailableOptions, showAREmail } = props;
  const contactType = useRelativeContactTypes();

  const customerContactType = contactType.customer ?? [];
  const invoiceContactType = contactType.invoice ?? [];
  const { internalContact: internalContactsCustomer } = useInternalContactForEntity('CUSTOMER');
  const { filteredList, getLabel } = useAREmailData();
  useEffect(() => {
    if (!users.length) {
      allUsers()
        .then((users) => dispatch(getActiveUsersHandler({ status: 200, users })))
        .catch((e) => console.log(e));
    }
  }, [dispatch, users.length]);

  const userOptions = users?.map((user) => ({
    label: `${user.first_name} ${user.last_name} (${user.email})`,
    value: user.id,
  }));

  const arEmailList = map(filteredList, (list) => {
    return {
      label: list.email,
      value: list.id,
      subsidiary_ids: list.subsidiary_ids,
    };
  });

  const UserOptGroup = (
    <OptGroup label={ContactGroupLabel['USERS']}>
      {userOptions.map((userOption) => (
        <Option key={userOption.value} value={userOption.value}>
          {userOption.label}
        </Option>
      ))}
    </OptGroup>
  );

  const PocOptGroup = (
    <OptGroup label={ContactGroupLabel['INTERNAL_CONTACT']}>
      {internalContactsCustomer?.map((internalContact) => (
        <Option key={internalContact.email} value={internalContact.email} record={internalContact}>
          {internalContact.label}
        </Option>
      ))}
    </OptGroup>
  );

  const CustomerContactTypeOptGroup = !!customerContactType.length && (
    <OptGroup label={ContactGroupLabel['CUSTOMER_CONTACT']}>
      {customerContactType.map((contactType) => (
        <Option key={contactType.email} value={contactType.email} record={contactType}>
          {contactType.label}
        </Option>
      ))}
    </OptGroup>
  );

  const InvoiceContactTypeOptGroup = !!invoiceContactType.length && (
    <OptGroup label={ContactGroupLabel['INVOICE_CONTACT']}>
      {invoiceContactType.map((contactType) => (
        <Option key={contactType.email} value={contactType.email} record={contactType}>
          {contactType.label}
        </Option>
      ))}
    </OptGroup>
  );

  const ArEmailGroup = (
    <OptGroup label="AR Email">
      {arEmailList.map((userOption) => (
        <Option
          key={userOption.value}
          value={userOption.value}
          type={BaseRecepientsType.AR_EMAIL}
          label={userOption.label}
        >
          {getLabel(userOption.label, userOption?.subsidiary_ids)}
        </Option>
      ))}
    </OptGroup>
  );

  const OptGroups = (
    <>
      {showAREmail ? ArEmailGroup : null}
      {showEmailableOptions ? CustomerContactTypeOptGroup : null}
      {showEmailableOptions ? InvoiceContactTypeOptGroup : null}
      {props.mode === 'single' ? PocOptGroup : null}
      {UserOptGroup}
    </>
  );

  const selectStyle = useMemo(
    () => ({
      ...defaultSelectStyle,
      ...props.style,
    }),
    [props.style]
  );

  if (props.mode === 'single') {
    return (
      <RelativeContactSingleSelect
        value={props.value ? getTransformedSelection(props.value) : undefined}
        onChange={props.onChange}
        optGroups={OptGroups}
        style={selectStyle}
        allowClear={props.allowClear}
      />
    );
  }

  const transformedValue: RelativeContact[] | undefined = props.value?.map(getTransformedSelection);

  return (
    <RelativeContactMultiSelect
      value={transformedValue}
      onChange={props.onChange}
      optGroups={OptGroups}
      style={selectStyle}
    />
  );
}

interface RelativeContactSingleSelectProps {
  value?: RelativeContact;
  onChange?: (newValue: RelativeContact) => void;
  optGroups: React.ReactNode;
  allowClear?: boolean;
  style?: React.CSSProperties;
}

function RelativeContactSingleSelect(props: RelativeContactSingleSelectProps) {
  const selectValue = props.value ? getSelectValue(props.value) : undefined;
  const [, isCustomerContactType] = useCustomerContactTypes();

  function handleChange(
    value: ReturnType<typeof getSelectValue>,
    options: OptionType | OptionType[]
  ) {
    const newValue = setSelectedValue(isCustomerContactType)(
      value,
      !Array.isArray(options) ? [options] : []
    );
    props.onChange && props.onChange(newValue);
  }

  return (
    <Select
      showSearch
      allowClear={props.allowClear}
      value={selectValue}
      onChange={handleChange}
      filterOption={filterContacts}
      style={props.style}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      dropdownRender={(options) => (
        <RecepientList styleType={'assignedToStyle'} options={options} />
      )}
    >
      {props.optGroups}
    </Select>
  );
}

interface RelativeContactMultiSelectProps {
  value?: RelativeContact[];
  onChange?: (newValue: RelativeContact[]) => void;
  optGroups: React.ReactNode;
  style?: React.CSSProperties;
}

interface OptionType extends DefaultOptionType {
  record?: NonNullable<ReturnType<typeof useRelativeContactTypes>['customer']>[0];
  type?: string;
}

function RelativeContactMultiSelect(props: RelativeContactMultiSelectProps) {
  const selectValue = props.value ? props.value.map((value) => getSelectValue(value)) : undefined;
  const [, isCustomerContactType] = useCustomerContactTypes();

  function handleChange(values: typeof selectValue, options: OptionType | OptionType[]) {
    if (!values) return undefined;

    if (Array.isArray(options)) {
      const newValues = values.map((value) =>
        setSelectedValue(isCustomerContactType)(value, options)
      );
      props.onChange && props.onChange(newValues);
    }
  }

  return (
    <Select
      showSearch
      value={selectValue}
      onChange={handleChange}
      style={props.style}
      mode="multiple"
      filterOption={filterContacts}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      dropdownRender={(options: ReactNode) => (
        <RecepientList styleType={'assignedToStyle'} options={options} />
      )}
    >
      {props.optGroups}
    </Select>
  );
}

export function useRelativeContactLabel() {
  const users = useSelector(usersSelector);
  const queryClient = useQueryClient();

  const contacts = queryClient.getQueryData<ContactTypeItem[]>(['contact-types-list']);

  const getRelativeContactLabel = useCallback(
    (relativeContact: RelativeContact) => {
      if (relativeContact.type === BaseRecepientsType.AR_EMAIL) {
        return `AR Email`;
      }
      if (relativeContact.type === BaseRecepientsType.CONTACT_TYPE) {
        return contacts
          ? contacts.find((f) => f.id === relativeContact.contact_type_id)?.display_text
          : 'Relative Contact';
      }
      if (relativeContact.type === BaseRecepientsType.POC) {
        return 'Internal Contact';
      }
      if (relativeContact.type === BaseRecepientsType.USER) {
        return users?.find((user) => user.id === relativeContact.id)?.email ?? 'Account User';
      }
    },
    [contacts, users]
  );

  return { getRelativeContactLabel };
}
