import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WorkFlowCreditContactOperationOptions } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import produce from 'immer';
import {
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import SearchableSelect from '../../../../../../../Common/SearchableSelect/SearchableSelect';
import { WorkFlowCreditContactOperatorTypes } from '../../../../type';
import { WorkFlowFilterOperator } from '../../../EventCondition/type';
import {
  DynamicFilterGeneratorData,
  MultiSelectFilterField,
  MultiSelectFilterFieldsType,
} from '../MultiSelectFilterField';
interface InvoiceBalanceAmountFilterProps<T extends DynamicFilterGeneratorData> {
  value?: WorkFlowFilterOperator<unknown>;
  conditionType: MultiSelectFilterFieldsType;
  data: T;
  initialValue?: string;
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
}

export default function MultiSelectFilterCondition<T extends DynamicFilterGeneratorData>(
  props: InvoiceBalanceAmountFilterProps<T>
) {
  const currentType = props?.value?.type;

  function handleOperatorChange(value: string) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        draftFilter.type = value as WorkFlowCreditContactOperatorTypes;
        const statusOperand = draftFilter.operands as
          | InAbsoluteOperands<unknown>
          | DefaultAbsoluteOperand<unknown>;
        statusOperand.value_holder = value === 'ANY' ? { value: true } : [];
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <SearchableSelect
      options={WorkFlowCreditContactOperationOptions}
      onChange={handleOperatorChange}
      value={props?.value?.type ?? 'IN'}
      style={{ width: 'fit-content' }}
    />
  );

  return (
    <Flex gap="--space-8" align="flex-start" style={{ alignSelf: 'center' }}>
      {Operators}
      <HideWrapper hide={currentType !== 'IN'}>
        <MultiSelectFilterField
          conditionType={props.conditionType}
          value={props.value}
          onChange={props.onChange}
          data={props.data}
        />
      </HideWrapper>
    </Flex>
  );
}
