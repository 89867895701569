import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import AssignOwners from 'components/Common/ActivitiesForm/FormElements/AssignOwners';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import DateOffsetSelect from 'components/Settings/WorkFlowAutomation/common/Date-select';
import { DateSelectKeys } from 'components/Settings/WorkFlowAutomation/common/types';
import { FormItem } from '../../../../common/style';
import { t } from '../../text';
import { ActionActivityTypes, AddPtpActionProps } from '../../types';
import { Form } from 'antd';
import { WorkflowForms } from '../../../type';
import { ActionType } from '../ActionType';

export default function AddPtpAction(props: AddPtpActionProps) {
  const { isInvoiceLevel, form, data } = props;
  const assigneeOptionTypes = isInvoiceLevel ? ActionableEntity.INVOICE : ActionableEntity.CUSTOMER;

  const PtpOwner = (
    <FormItem
      label={t.ptp_owner}
      name="ptp_owner"
      rules={[{ required: true, message: t.ptp_owner_needed }]}
      initialValue={data?.ptp_owner}
    >
      <AssignOwners
        placeholder={t.add_members}
        listHeight={140}
        context={'STRATERGY'}
        assigneeOptionType={assigneeOptionTypes}
      />
    </FormItem>
  );

  const PtpDate = (
    <DateOffsetSelect
      title={t.ptp_creation_date}
      label={t.ptp_date}
      initialValue={!data?.due_date_offset ? DateSelectKeys.ON : DateSelectKeys.AFTER}
      form={form}
    />
  );

  const Description = (
    <FormItem
      label={t.promissory_note}
      name="ptp_description"
      rules={[{ required: true, message: t.promissory_note }]}
      initialValue={data?.description}
    >
      <DescriptionTextArea placeholder={t.promissory_note} />
    </FormItem>
  );

  return (
    <Form
      name={WorkflowForms.ACTION_FORM}
      style={{ height: '100%' }}
      requiredMark={false}
      form={form}
      layout="vertical"
      preserve={false}
    >
      {PtpOwner}
      {PtpDate}
      {Description}
      <ActionType value={ActionActivityTypes.PROMISE_TO_PAY} />
    </Form>
  );
}
