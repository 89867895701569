import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import { StrategyErrorField } from 'store/collection-strategy/validations';
import { FollowupTrackType } from 'types/entities/collection-strategy';

export const strategySliceSelector = (state: RootState) => state.followupStrategy;

export const strategySelector = createSelector(
  strategySliceSelector,
  (strategySlice) => strategySlice.strategy
);

export const strategyErrorsSelector = createSelector(
  strategySliceSelector,
  (strategySlice) => strategySlice.errors
);

export const strategyErrorSelector = (key: StrategyErrorField) =>
  createSelector(strategySliceSelector, (strategySlice) => strategySlice.errors[key]);

export const strategyErrorsVisibleSelector = createSelector(
  strategySliceSelector,
  (strategySlice) => strategySlice.showErrors
);

export const followupTitleSelector = createSelector(strategySelector, (strategy) => strategy.title);
export const followupEnableSelector = createSelector(
  strategySelector,
  (strategy) => strategy.enabled
);

// INVOICE FOLLOWUP
export const invoiceFollowupSelector = createSelector(
  strategySelector,
  (strategy) => strategy.invoice_followup
);
export const invoiceFollowupEnabledSelector = createSelector(
  invoiceFollowupSelector,
  (invoiceFollowup) => invoiceFollowup.enabled
);
export const invoiceFollowupRecurringActionSelector = createSelector(
  invoiceFollowupSelector,
  (invoiceFollowup) => invoiceFollowup.recurring_action
);
export const invoiceFollowupAutoResumeSelector = createSelector(
  invoiceFollowupSelector,
  (invoiceFollowup) => invoiceFollowup.auto_resume
);
export const invoiceFollowupTypeSelector = createSelector(
  invoiceFollowupSelector,
  (invoiceFollowup) => invoiceFollowup.serial_followup
);
export const invoiceFollowupTracksSelector = createSelector(
  invoiceFollowupSelector,
  (invoiceFollowup) => invoiceFollowup.tracks
);
export const invoiceFollowupAgeTrackSelector = createSelector(
  invoiceFollowupTracksSelector,
  (invoiceFollowupTracks) => {
    return invoiceFollowupTracks.find((track) => track.track_type === FollowupTrackType.AGE_TRACK);
  }
);
export const invoiceFollowupDunningRulesSelector = createSelector(
  invoiceFollowupAgeTrackSelector,
  (ageTrack) => {
    if (!ageTrack) return [];

    return ageTrack.dunning_rules;
  }
);
export const invoiceFollowupDunningRulesCountSelector = createSelector(
  invoiceFollowupDunningRulesSelector,
  (dunningRules) => dunningRules.length
);
export const invoiceFollowupPausingRulesSelector = createSelector(
  invoiceFollowupAgeTrackSelector,
  (ageTrack) => {
    if (!ageTrack) return [];

    return ageTrack.pausing_rules;
  }
);
export const invoiceFollowupStoppingRulesSelector = createSelector(
  invoiceFollowupAgeTrackSelector,
  (ageTrack) => {
    if (!ageTrack) return [];

    return ageTrack.stopping_rules;
  }
);

// STATEMENT FOLLOWUP
export const statementFollowupSelector = createSelector(
  strategySelector,
  (strategy) => strategy.statement_followup
);
export const statementFollowupEnabledSelector = createSelector(
  statementFollowupSelector,
  (statementFollowup) => statementFollowup.enabled
);
export const statementFollowupTrackSelector = createSelector(
  statementFollowupSelector,
  (statementFollowup) =>
    statementFollowup.tracks.find((track) => track.track_type === FollowupTrackType.PERIODIC)
);
export const statementFollowupDunningRuleSelector = createSelector(
  statementFollowupTrackSelector,
  (statementFollowupTrack) => (statementFollowupTrack ? statementFollowupTrack.dunning_rules : [])
);
export const statementFollowupDunningRuleCountSelector = createSelector(
  statementFollowupDunningRuleSelector,
  (dunningRules) => dunningRules.length
);
export const statementFollowupPausingRuleSelector = createSelector(
  statementFollowupSelector,
  (statementFollowup) => statementFollowup.pause_and_resume
);

// FOLLOWUP SETTINGS
export const settingsSelector = createSelector(strategySelector, (strategy) => strategy.settings);
export const emailDeliverySelector = createSelector(
  settingsSelector,
  (settings) => settings.email_delivery
);
export const enableEmailDeliverySelector = createSelector(
  emailDeliverySelector,
  (emailDelivery) => emailDelivery.enabled
);
export const emailDeliveryDaysSelector = createSelector(
  emailDeliverySelector,
  (emailDelivery) => emailDelivery.delivery_days
);
export const emailDeliveryTimeSelector = createSelector(
  emailDeliverySelector,
  (emailDelivery) => emailDelivery.delivery_time
);
