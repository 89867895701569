import { FormInstance } from 'antd';

export enum DeliveryOptions {
  ANY_DAYS = 'any_days',
  CHOSEN_DAYS = 'chosen_days',
  ANY_TIME = 'any_time',
  FIXED_TIME = 'fixed_time',
  CUSTOMER_TIMEZONE = 'customer_timezone',
  SELECT_TIMEZONE = 'select_timezone',
}

export enum DeliveryOptionId {
  DAYS = 'DAYS',
  TIME = 'TIME',
  TZ_TYPE = 'tz_type',
  FIXED_TIME = 'fixed_time',
}

export interface delayedExecutionConfig {
  tz: string;
  tz_type: string;
  fixed_days: number[];
  fixed_time: { hour: number; minute: number; second: number };
  any_days: boolean;
  any_time: boolean;
}

export interface delayedExecutionConfigObject {
  delayed_execution_config: delayedExecutionConfig;
}

export interface DeliverySettingsProps {
  delayedExecutionConfig?: delayedExecutionConfig;
  form: FormInstance;
}

export type Option = { label: string; value: string };
