import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import CustomFieldTitle from 'components/Settings/WorkFlowAutomation/common/CustomFieldTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { collectionStatusSelector } from 'store/collection-status/collection-status';
import { t } from '../../text';
import { ActionTitleProps, CollectionStatusActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function CollectionStatusTitle(props: ActionTitleProps<CollectionStatusActionType>) {
  // const { item } = props;

  // const collectionStatusesList = useSelector(collectionStatusSelector);
  // const collectionStatusName = find(
  //   collectionStatusesList.collectionStatuses,
  //   (statusItem) => statusItem.id === parseInt(item?.value)
  // );

  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.EDIT_COLLECTION_STATUS.title}
      icon={actionIcons.EDIT_COLLECTION_STATUS.icon}
      backgroundColor={actionIcons.EDIT_COLLECTION_STATUS.style.backgroundColor}
      title={actionIcons.EDIT_COLLECTION_STATUS.title}
      strong={props.strong}
    />
  );
}
