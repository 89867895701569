import { Input, InputRef } from 'antd';
import { ChangeEvent, useEffect, useRef } from 'react';
import { WorkFlowSearchIcon } from './Helper';

interface WorkflowConditionSearchProps {
  placeHolder?: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  searchValue?: string;
}
export function WorkflowConditionSearch(props: WorkflowConditionSearchProps) {
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'start',
    });
  }, []);

  return (
    <Input
      prefix={WorkFlowSearchIcon}
      placeholder={props.placeHolder}
      onChange={props.onChange}
      value={props.searchValue}
      allowClear
      ref={inputRef}
    />
  );
}
