import { GrowFlex, GrowTypography } from '@sinecycle/growcomponents';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { CustomerStatement, statementsTexts } from 'types/customerstatement/customer-statement';

interface OutstandingStatementsProps {
  hide?: boolean;
  statementType?: 'OUTSTANDING_TRANSACTIONS';
  statement?: CustomerStatement;
}
const t = {
  outStandingTransaction: 'Outstanding Transactions',
};
function OutstandingStatements(props: Readonly<OutstandingStatementsProps>) {
  const format = useSelector(dateFormatSelector);
  if (props.hide) return null;
  const dateToday = dayjs().format(format);
  const DateToday = (
    <>
      <GrowTypography.Text style={{ color: 'var(--gray-7)' }}>
        {statementsTexts.as_of}
      </GrowTypography.Text>
      <GrowTypography.Text>{dateToday}</GrowTypography.Text>
    </>
  );
  return (
    <GrowFlex space="4">
      <GrowTypography.Text>{t.outStandingTransaction}</GrowTypography.Text>
      {DateToday}
    </GrowFlex>
  );
}

export default OutstandingStatements;
