import { UserBasic } from '../user';

export enum InvoiceSegmentOperandType {
  ABSOLUTE = 'ABSOLUTE',
  RELATIVE = 'RELATIVE',
}
enum InvoiceSegmentRelativeOperandType {
  CUSTOMER_BALANCE = 'CUSTOMER_BALANCE',
  INVOICE_BALANCE = 'INVOICE_BALANCE',
  INVOICE_AMOUNT = 'INVOICE_AMOUNT',
  CURRENT_DATE = 'CURRENT_DATE',
  PREDICTED_PAY_DATE = 'PREDICTED_PAY_DATE',
  LATEST_PTP_DATE = 'LATEST_PTP_DATE',
}
export type UsersValueOperand = Partial<UserBasic>;
interface BaseOperand {
  type: InvoiceSegmentOperandType;
}
export interface RelativeOperand extends BaseOperand {
  type: InvoiceSegmentOperandType.RELATIVE;
}
interface AbsoluteOperand extends BaseOperand {
  type: InvoiceSegmentOperandType.ABSOLUTE;
}
interface BaseAbsoluteValueProps<T extends unknown> {
  value: T;
}
interface ContatAbsoluteValueProps {
  value: UsersValueOperand;
}
interface DefaultRelativeOperand extends RelativeOperand {
  type: InvoiceSegmentOperandType.RELATIVE;
  value: InvoiceSegmentRelativeOperandType;
}
export interface DefaultAbsoluteOperand<T extends unknown> extends AbsoluteOperand {
  value_holder: BaseAbsoluteValueProps<T>;
}
export interface DefaultContactAbsoluteOperand extends AbsoluteOperand {
  value_holder: ContatAbsoluteValueProps[];
}
export interface DateINAbsoluteOperand<T extends unknown> extends AbsoluteOperand {
  value_holder: BaseAbsoluteValueProps<T>[];
}

export interface GreaterThanOrEqualsRelativeOperands extends DefaultRelativeOperand {}
export interface GreaterThanOrEqualsAbsoluteOperands<T extends unknown>
  extends DefaultAbsoluteOperand<T> {}
export interface GreaterThanAbsoluteOperands<T extends unknown> extends DefaultAbsoluteOperand<T> {}

export interface LessThanOrEqualsRelativeOperands extends DefaultRelativeOperand {}
export interface LessThanOrEqualsAbsoluteOperands<T extends unknown>
  extends DefaultAbsoluteOperand<T> {}
export interface LessThanAbsoluteOperands<T extends unknown> extends DefaultAbsoluteOperand<T> {}

export interface DateEQAbsoluteOperands<T extends unknown> extends DefaultAbsoluteOperand<T> {}
export interface EqualToRelativeOperands extends DefaultRelativeOperand {}
export interface EqualToAbsoluteOperands<T extends unknown> extends DefaultAbsoluteOperand<T> {}

export interface InRelativeOperands extends RelativeOperand {
  value: InvoiceSegmentRelativeOperandType[];
}
export interface InAbsoluteOperands<T extends unknown> extends AbsoluteOperand {
  value_holder: BaseAbsoluteValueProps<T>[];
}
export interface BetweenRelativeOperands extends RelativeOperand {
  value: {
    from: InvoiceSegmentRelativeOperandType;
    to: InvoiceSegmentRelativeOperandType;
  };
}
export interface BetweenAbsoluteOperands<T extends unknown> extends AbsoluteOperand {
  value_holder: {
    from: BaseAbsoluteValueProps<T>;
    to: BaseAbsoluteValueProps<T>;
  };
}

export type WorkFlowAbsoluteOperands<T> =
  | DefaultAbsoluteOperand<T>
  | BetweenAbsoluteOperands<T>
  | InAbsoluteOperands<T>;

interface DeprecatedAbsoluteOperand {
  type: 'DEPRECATED_ABSOLUTE';
}
export interface DeprecatedBetweenAbsoluteOperands<T extends unknown>
  extends DeprecatedAbsoluteOperand {
  value: {
    from: T;
    to: T;
  };
}
export interface DeprecatedInAbsoluteOperands<T extends unknown> extends DeprecatedAbsoluteOperand {
  value: T[];
}
interface DeprecatedDefaultAbsoluteOperand<T extends unknown> extends DeprecatedAbsoluteOperand {
  value: T;
}

export interface DeprecatedGreaterThanAbsoluteOperands<T extends unknown>
  extends DeprecatedDefaultAbsoluteOperand<T> {}
export interface DeprecatedLessThanAbsoluteeOperands<T extends unknown>
  extends DeprecatedDefaultAbsoluteOperand<T> {}
export interface DprecatedEqualToAbsoluteOperands<T extends unknown>
  extends DeprecatedDefaultAbsoluteOperand<T> {}
