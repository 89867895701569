import CreditTermFilter from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Common/CreditTermFilter';
import { find, head, isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { usersSelector } from 'store/user/user';
import { UsersValueOperand } from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import CollectionOwnerField from '../../Common/CollectionOwnerField';
import ContactsField from '../../Common/ContactsField';
import SubsidaryFilter from '../../Common/SubsidiaryFilter';

export interface GetDisplayNampeProps {
  selectedEventType: WorkFlowEventTypes;
  displayValue: unknown[];
}
export default function GetDisplayName(props: GetDisplayNampeProps) {
  const users = useSelector(usersSelector);

  if (!isNumber(props.displayValue)) {
    switch (props.selectedEventType) {
      case 'PRIMARY_CONTACT':
      case 'SECONDARY_CONTACT':
        const contactDisplayValue = head(props.displayValue);
        return (
          <ContactsField
            type={'DISPLAY'}
            displayValue={contactDisplayValue as UsersValueOperand}
            selectedFilter={props.selectedEventType}
          />
        );
      case 'CREDIT_TERM':
        return <CreditTermFilter type={'DISPLAY'} selectedValue={props.displayValue as string[]} />;
      case 'INVOICE_SUBSIDIARY':
      case 'CUSTOMER_SUBSIDIARY':
        return (
          <SubsidaryFilter
            type={'DISPLAY'}
            selectedValue={props.displayValue as number[]}
            conditionType={props.selectedEventType}
          />
        );
      default:
        return <>{props.displayValue}</>;
    }
  }

  switch (props.selectedEventType) {
    case 'DISPUTE_ASSIGNEE':
    case 'TASK_ASSIGNEE':
    case 'ESCALATION_ASSIGNEE':
      const filteredusers = users && find(users, { id: props.displayValue });
      return (
        <>
          {filteredusers?.first_name} {filteredusers?.last_name}
        </>
      );
    case 'COLLECTION_OWNER':
      return <CollectionOwnerField type={'DISPLAY'} id={props.displayValue} />;

    default:
      return <>{props.displayValue}</>;
  }
}
