import { WorkFlowFilterOperator } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/EventCondition/type';
import produce from 'immer';
import { Range } from 'types/entities/invoice-segments';
import { BetweenAbsoluteOperands } from 'types/entities/invoice-segments/operands';
import RangeNumericFilter from './RangeNumericFilter';

interface BetweenNumericValuesProps {
  value?: WorkFlowFilterOperator<number>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<number>) => void;
  prefix?: string;
  precision?: number;
  fieldName?: string;
  singleFieldInputWidth?: string;
  betweenText?: string;
}

export default function BetweenNumericValues(props: BetweenNumericValuesProps) {
  const initialValues = initializeBetweenValue();

  function initializeBetweenValue(): Range {
    const from = (props?.value?.operands as BetweenAbsoluteOperands<number>)?.value_holder?.from
      ?.value as number;
    const to = (props?.value?.operands as BetweenAbsoluteOperands<number>)?.value_holder?.to
      ?.value as number;
    return {
      from: {
        value: from ?? 1,
      },
      to: {
        value: to ?? 2,
      },
    };
  }

  function handleFromChange(value: number | null) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        const operandsValue = draftFilter?.operands as BetweenAbsoluteOperands<number | null>;
        operandsValue.value_holder.from.value = value;
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  function handleToChange(value: number | null) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        const operandsValue = draftFilter?.operands as BetweenAbsoluteOperands<number | null>;
        operandsValue.value_holder.to.value = value;
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  return (
    <RangeNumericFilter
      fromOnChange={handleFromChange}
      toOnChange={handleToChange}
      prefix={props.prefix}
      precision={props.precision}
      singleFieldInputWidth={props.singleFieldInputWidth}
      betweenText={props.betweenText}
      initialRangeValues={initialValues}
    />
  );
}
