import { Select } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    color: var(--primary-10);
  }
`;
export const StyledFlex = styled(Flex)`
  padding: var(--space-12);
  background-color: var(--gray-1);
  box-shadow: var(--shadow-2);
  border: 1px solid var(--gray-4);

  .ant-dropdown-menu-item {
    &:hover {
      background-color: var(--primary-1);
    }
  }
`;
