import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Skeleton, Tooltip } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import useOpen from 'util/hooks/useOpen';
import { ConversationActions } from '../../Header/ConversationHeader';
import { Content } from '../Content/Content';
import { Heading } from './Heading';
import { Conversation } from './style';
import { ConversationBodyProps } from './type';

const t = {
  Maximize: 'Maximize',
  Close: 'Close',
  minimize: 'Minimize',
};

const maskStyles: React.CSSProperties = {
  height: '100%',
  width: '100%',
  background: 'rgba(0, 0, 0, 0.53)',
  position: 'fixed',
  zIndex: '999',
  inset: 0,
};

const ExpandIcon = <FontAwesomeIcon icon={['far', 'expand']} color="var(--primary-7)" />;
const CloseIcon = <FontAwesomeIcon icon={['far', 'times']} color="var(--primary-7)" />;
const Minimize = <FontAwesomeIcon icon={['far', 'minus']} color="var(--primary-7)" />;
function ConversationBody(props: ConversationBodyProps) {
  const { loader, data, mode, queryKey, hideCustomerDetails, isDetails, readOnly } = props;
  const { open, toggleOpen, onClose } = useOpen({ open: false });

  const ExpandButton = (
    <Tooltip title={open ? (isDetails ? t.minimize : t.Close) : t.Maximize} trigger={['hover']}>
      <Button
        icon={open ? (isDetails ? Minimize : CloseIcon) : ExpandIcon}
        onClick={toggleOpen}
        className="inbox-maximize"
        loading={loader}
      />
    </Tooltip>
  );

  const Extra = loader ? (
    <Skeleton.Input style={{ width: '50px', height: '30px' }} />
  ) : (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-8">
      {open ? null : (
        <ConversationActions
          invoices={data?.parent_email?.invoices}
          customerId={data?.parent_email?.customer?.id}
          customerCurrency={data?.parent_email?.customer?.currency}
        />
      )}
      {ExpandButton}
    </div>
  );

  const Header =
    !loader && data ? (
      <Heading
        latestConversation={data.parent_email}
        actions={Extra}
        hideCustomerDetails={hideCustomerDetails}
        readOnly={readOnly}
      />
    ) : (
      <Flex gap="--space-8" direction="column" style={{ width: '100%' }}>
        <Skeleton.Input active style={{ width: '500px' }} />
        <Skeleton.Input style={{ width: '50px', height: '20px' }} />
      </Flex>
    );

  return (
    <Row gutter={[4, 4]}>
      <Col span={24}>
        {open ? <Conversation /> : null}
        {open ? (
          <div className="cursor-pointer content-mask" onClick={onClose} style={maskStyles} />
        ) : null}
        <Conversation title={Header} modal={open} mode={mode}>
          <Content data={data} loader={loader} queryKey={queryKey} readOnly={readOnly} />
        </Conversation>
      </Col>
    </Row>
  );
}

export { ConversationBody };
