import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { ActionTitleProps, TaskActionType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function TaskTitle(props: ActionTitleProps<TaskActionType>) {
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.TASK.title}
      icon={actionIcons.TASK.icon}
      backgroundColor={actionIcons.TASK.style.backgroundColor}
      title={actionIcons.TASK.title}
      strong={props.strong}
    />
  );
}
