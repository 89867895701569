import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { head, nth } from 'lodash';
import { DateRange } from 'types/api/collectors-dashboard';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);

export const formatToDefault = 'YYYY-MM-DD';

// date utils can be needed
const formatListOne = ['DD-MM-YYYY', 'DD/MM/YYYY', 'DD MMM, YYYY'];
const formatListTwo = ['MM-DD-YYYY', 'MM/DD/YYYY', 'MMM DD, YYYY'];
export const formats = {
  DEFAULT: 'D MMM YYYY',
  DATE_FORMAT_1: 'DD MMM YYYY',
  WITH_TIME: 'D MMM YYYY h:mm A',
  DATE_MONTH: 'DD MMM',
  DATE_MONTH_2: 'MMM DD',
  D_MMM: 'D MMM',
  MONTH: 'M',
  YEAR: 'YYY',
  TIME: 'h:mm A',
};

export const formatYearMonth = (yearMonth: string, format: string) => {
  return dayjs(yearMonth, 'YYYYMM').format(format);
};

export const formatMonth = (month: number, format: string) => {
  return dayjs()
    .month(month - 1)
    .format(format);
};

export const getTimeFromString = (time: string, format: string = 'HH:mm:ss') => {
  return dayjs(time, format);
};
export const formatTime = (time: Dayjs, formatString: string = 'HH:mm:ss') => {
  return time.format(formatString);
};

export function getCustomFormattedDate(date: string | Dayjs | Date, formatString: string) {
  return dayjs(date).format(formatString);
}

export function getFormattedDate(
  date: string | Dayjs | Date | undefined,
  format?: string,
  type?: keyof typeof formats
) {
  if (date) {
    return getCustomFormattedDate(date, format ?? formats[type ?? 'DEFAULT']);
  }
}

export const getFormattedTime = (date: string | undefined) => {
  if (date) {
    return dayjs(date).format('h:mm a');
  }
};

type FormatOptions = {
  dateFormatType?: string;
  timeFormatType?: string;
};
const defaultFormat: FormatOptions = {
  dateFormatType: 'D MMM YYYY',
  timeFormatType: 'hh:mm A',
};
export const getDateTimeArray = (date: string, options?: FormatOptions) => {
  const dayjsDate = dayjs(date),
    formatOptions = { ...defaultFormat, ...options };

  return [
    dayjsDate.format(formatOptions.dateFormatType),
    dayjsDate.format(formatOptions.timeFormatType),
  ];
};

export const isPastDate = (date: string) => {
  return dayjs(date).diff(dayjs(), 'hours') < 0;
};

export const getRelativeDaysFromNow = (date: string) => {
  const inputDate = dayjs(date);
  const currentDate = dayjs();
  if (inputDate.isSame(currentDate, 'day')) {
    return 'Today';
  }

  return currentDate.to(inputDate);
};

export const getCurrentDate = (format?: string) => {
  const formatToUse = format ?? 'DD MMM YYYY';

  return dayjs().format(formatToUse);
};

export const getDateObj = (date: string | Dayjs) => dayjs(date);

export const getCurrentMonthYear = (format?: string) => {
  const formatToUse = format ?? 'MMM YYYY';

  return dayjs().format(formatToUse);
};

export const getCurrentMonth = (format?: string) => {
  const formatToUse = format ?? 'M';

  return dayjs().format(formatToUse);
};

export const getCurrentYear = (format?: string) => {
  const formatToUse = format ?? 'YYYY';

  return dayjs().format(formatToUse);
};

export const getFormattedDateRange = (dateRange: DateRange, format: string) => {
  const to = nth(dateRange, 1);
  const fromDate = getDateShortFormat(head(dateRange)?.toString() as string, format);
  const toDate = (to && getDateShortFormat(to.toString(), format)) ?? '';

  return `${fromDate} - ${toDate}`;
};

export const getDateStringForRequestPayload = (date?: string | Date | Dayjs) => {
  return date ? dayjs(date).format(formatToDefault) : undefined;
};
export function monthYearText(monthYear: number) {
  return dayjs(monthYear.toString(), 'YYYYMM').format('MMM YYYY');
}

export function getDateShortFormat(date: string, format: string) {
  if (formatListOne.includes(format)) {
    return getCustomFormattedDate(date, formats.DATE_MONTH);
  } else if (formatListTwo.includes(format)) {
    return getCustomFormattedDate(date, formats.DATE_MONTH_2);
  }
  return getCustomFormattedDate(date, formats.DATE_MONTH);
}
