import { GrowTypography } from '@sinecycle/growcomponents';
import { Card } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { useParams } from 'react-router-dom';
import { useWorkflowOwner } from '../../WorkflowCardList/Hooks';
import WorkflowFooter from '../../common/WorkflowFooter';
import WorkflowCardTitle from '../../common/workflowCardTitle';
import { CardStyles } from '../WorkFlowForm/WorkFlowForm';
import { WorkFlowRule } from '../type';
import DisplayTriggerCard from './DisplayCard/DisplayTriggerCard';
import {
  findMandatoryConditionsByEntityType,
  findOptionalConditions,
  generateOptionalConditions,
} from './DisplayCard/Helper';
import { useGetCustomFieldHooks } from './Filters/CustomFieldFilter/customFieldHooks';
import TriggerForm from './TriggerDrawerForm/TriggerForm';
import { WorkFlowTexts } from './text';
import { TriggerProps } from './type';

export default function Trigger(props: TriggerProps) {
  const { form, actionForm, mode, onClick, selected, loading, workflowMeta } = props;
  const { workflow_id } = useParams();
  const formFields = useFormWatch([], form);
  const { isWorkflowOwner } = useWorkflowOwner();
  const triggerRuleData = workflow_id
    ? props.triggerRule
    : form.getFieldsValue(['trigger']).trigger;
  const entity = formFields?.entity ?? triggerRuleData?.entity_type;

  const isSaved = form.getFieldsValue(['entityUpdate'])?.entityUpdate;
  const { customerCustomField, invoiceCustomField, customFields } = useGetCustomFieldHooks(entity);
  function successCallBack() {
    form
      .validateFields()
      .then(() => {
        form.submit();
      })
      .catch((error) => {});
  }

  function cancelCallBack() {
    const prevCondition = generateOptionalConditions(findOptionalConditions(triggerRuleData));

    const mandatory = findMandatoryConditionsByEntityType(
      triggerRuleData?.trigger_type,
      triggerRuleData
    );

    const eventInitailValue =
      mandatory?.type === 'INVOICE_CUSTOM_FIELD' || mandatory?.type === 'CUSTOMER_CUSTOM_FIELD'
        ? mandatory.id
        : mandatory?.type ?? triggerRuleData?.event_type;

    form.setFieldsValue({
      condition_list: prevCondition,
      selected_event: eventInitailValue,
      mandatory_operator: mandatory?.operator,
      followup_operator: triggerRuleData?.condition?.operator,
    });
  }
  const footer = (
    <WorkflowFooter
      onClose={cancelCallBack}
      onSubmit={successCallBack}
      confirmationText={WorkFlowTexts.override_trigger}
      enablePopConfirm={workflowMeta?.enabled || !isSaved}
      form={form}
    />
  );
  const CardTitle = (
    <WorkflowCardTitle
      title={WorkFlowTexts.trigger}
      icon={['far', 'bullhorn']}
      hasData={Boolean(triggerRuleData)}
    />
  );

  const TriggerDetails = formFields && (
    <Card
      className="tw-h-[calc(100vh-180px)] tw-overflow-hidden"
      classNames={{
        actions: 'tw-sticky tw-bottom-[0px] tw-z-10 [&>li]:tw-m-[0px]',
        body: 'tw-h-full tw-overflow-auto',
        header: '!tw-border-transparent !tw-pb-[0px] !tw-px-24 !tw-pt-24',
      }}
      style={{
        boxShadow:
          '0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000D',
      }}
      actions={isWorkflowOwner ? [] : [footer]}
      bordered
      hoverable
      title={<GrowTypography.Text fs="20">{'Trigger'}</GrowTypography.Text>}
    >
      {isWorkflowOwner ? (
        <DisplayTriggerCard
          triggerData={triggerRuleData as WorkFlowRule}
          form={form}
          customFields={customFields}
        />
      ) : (
        <TriggerForm
          value={triggerRuleData}
          form={form}
          formFields={formFields}
          customerCustomField={customerCustomField}
          invoiceCustomField={invoiceCustomField}
          customFields={customFields}
          actionForm={actionForm}
        />
      )}
    </Card>
  );
  const DisplayTrigger = (
    <Card
      title={CardTitle}
      className="tw-cursor-pointer tw-border-primary-5 "
      bordered={selected}
      styles={CardStyles}
      onClick={onClick}
      loading={loading}
      hoverable
    >
      {triggerRuleData ? (
        <DisplayTriggerCard
          triggerData={triggerRuleData as WorkFlowRule}
          form={form}
          customFields={customFields}
        />
      ) : (
        <GrowTypography.Text fs="12" className="tw-text-gray-7">
          No event added
        </GrowTypography.Text>
      )}
    </Card>
  );
  return <>{mode === 'DISPLAY' ? DisplayTrigger : TriggerDetails}</>;
}
