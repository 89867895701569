export enum EPages {
  DASHBOARD = 'DASHBOARD',
  AR_AGING = 'AR_AGING',
  CUSTOMER_DETAIL = 'CUSTOMER_DETAIL',
  INVOICE_DETAIL = 'INVOICE_DETAIL',
  INVOICE_LIST = 'INVOICE_LIST',
  COLLECTION_FOLLOWUP = 'COLLECTION_FOLLOWUP',
  SETTINGS = 'SETTINGS',
  INBOX = 'INBOX',
  ALL_CUSTOMERS = 'ALL_CUSTOMERS',
  GENERAL = 'GENERAL',
  REPORTS = 'REPORTS',
  COLLECTIONS = 'COLLECTIONS',
}
