import ky from 'services/ky';
import { ContactsType } from 'types/entities/contact';
import { CustomerContact } from '../../types';
import { InitialFilterValuesType } from '../UpsertContacts';

/*
 TODO Move this file to src/queries when contact configuration is allowed from different part of the app
*/

interface DeleteCustomerParam {
  contactId: number;
}

interface DeleteInvoiceCustomerParam extends DeleteCustomerParam {
  invoiceId: number;
}

export async function getPrimaryAndSecondaryContacts(contactType: ContactsType) {
  const url = `contacts?contact_type=${contactType}`;
  return await ky.get(url).json<CustomerContact[]>();
}

// COMMON FOR [INVOICE, CUSTOMER] LEVEL

export async function getCustomerContacts(params: { id: number; baseUrl: string }) {
  const url = `${params?.baseUrl}/${params.id}/contacts`;
  return (await ky.get(url).json()) as CustomerContact[];
}

// CUSTOMER LEVEL

export async function createCustomerContact(options: {
  formValues: InitialFilterValuesType;
  customerId: number;
}) {
  const url = `customers/${options.customerId}/contact`;
  return (await ky
    .post(url, {
      json: options.formValues,
    })
    .json()) as CustomerContact;
}

export async function updateCustomerContact(options: {
  formValues: InitialFilterValuesType;
  contactId: number;
}) {
  const url = `contacts/${options.contactId}`;
  return (await ky
    .put(url, {
      json: options.formValues,
    })
    .json()) as CustomerContact;
}

export async function removeCustomerContact(options: DeleteCustomerParam) {
  const url = `contacts/${options?.contactId}/`;
  return await ky.delete(url);
}

// INVOICE LEVEL

export async function unAssignInvoiceCustomerContact(options: DeleteInvoiceCustomerParam) {
  const url = `invoices/${options.invoiceId}?contact_id=${options?.contactId}`;
  return ky.delete(url).json();
}

export async function assignInvoiceCustomerContact(options: DeleteInvoiceCustomerParam) {
  const url = `invoices/${options.invoiceId}?contact_id=${options?.contactId}`;
  return (await ky.put(url).json()) as CustomerContact;
}
