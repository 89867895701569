import { GrowTypography } from '@sinecycle/growcomponents';
import { Tag } from 'antd';
import { CustomTagProps } from 'components/Common/ActivitiesForm/types';

function tagRender(props: CustomTagProps) {
  const { closable, onClose, label } = props;
  return (
    <Tag
      onClose={onClose}
      closable={closable}
      style={{
        paddingBlock: 'var(--space-2)',
        backgroundColor: 'var(--gray-2)',
      }}
      className="tw-flex tw-items-center tw-mr-4"
    >
      <GrowTypography.Text ellipsis style={{ maxWidth: '180px' }} fs="12">
        {label}
      </GrowTypography.Text>
    </Tag>
  );
}

export { tagRender };
