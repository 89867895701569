import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ActivityActions,
  ExecutionsActionDetailRequestParam,
  ExecutionsReport,
} from 'components/Settings/WorkFlowAutomation/Executions/type';
import { useSearchParams } from 'react-router-dom';
import {
  ExecutionsApprovalRequestParams,
  getExecutionActionDetailsView,
  getExecutionsApprovalDetails,
  getExecutionsDetailedView,
  getExecutionsList,
} from 'services/executions';

export function useExecutionsReport(filters?: ExecutionsReport) {
  return useQuery({
    queryKey: ['executions-report', filters],
    queryFn: () => getExecutionsList(filters),
  });
}

export function useExecutionsDetailsReport() {
  const [searchParams] = useSearchParams();
  const workflow_id = searchParams.get('workflow_id') ?? '';
  const event_id = searchParams.get('event_id') ?? '';
  return useQuery({
    queryKey: ['approvals-details', workflow_id, event_id],
    queryFn: () => getExecutionsDetailedView({ workflow_id, event_id }),
    enabled: Boolean(workflow_id) && Boolean(event_id),
  });
}

export function useExecutionActionDetails<T extends ActivityActions>(
  params: Partial<ExecutionsActionDetailRequestParam> & {
    enabled?: boolean;
  }
) {
  const defaultEnabled = Boolean(params.qualified_action_id) && Boolean(params.execution_id);
  return useQuery({
    queryKey: ['execution-action-details', params.qualified_action_id, params.execution_id],
    queryFn: () => getExecutionActionDetailsView<T>(params),
    enabled: params.enabled ? params.enabled : defaultEnabled,
  });
}

export function useExecutionActionDetailsMutation<T extends ActivityActions>() {
  return useMutation({
    mutationFn: (params: Partial<ExecutionsActionDetailRequestParam>) =>
      getExecutionActionDetailsView<T>(params),
  });
}

export function useExecutionActionApprovalDetails(params: ExecutionsApprovalRequestParams) {
  return useQuery({
    queryKey: ['execution-action-approval-details', params.approval_id, params.workflow_id],
    queryFn: () => getExecutionsApprovalDetails(params),
    enabled: Boolean(params.approval_id) && Boolean(params.workflow_id),
  });
}
