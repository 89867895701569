import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import {
  CustomFieldDataType,
  DecimalFields,
  getValidationRegex,
  getValidationText,
} from 'types/entities/custom-field';

interface SingleValuesInputProps {
  defaultValue: number;
  handleSingleValueChange: (value: number | null) => void;
  currencySymbol?: string;
  handleErrorStatus?: (isError: boolean) => void;
  inputWidth?: React.CSSProperties['width'];
  dataType: CustomFieldDataType;
  width?: string;
}

export function SingleValuesInput(props: SingleValuesInputProps) {
  const [form] = useForm();
  const regEx = getValidationRegex(props?.dataType as DecimalFields);
  const errorText = getValidationText(props?.dataType as DecimalFields);
  const validationRule: Rule[] = [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value && value !== 0) {
          props.handleErrorStatus && props.handleErrorStatus(true);
          return Promise.reject(new Error('Value should not be empty'));
        }
        if (!regEx.test(value as unknown as string)) {
          props.handleErrorStatus && props.handleErrorStatus(true);
          return Promise.reject(errorText);
        }
        props.handleErrorStatus && props.handleErrorStatus(false);
        return Promise.resolve();
      },
    }),
  ];

  return (
    <Form layout="horizontal" form={form}>
      <StyledFormItem
        shouldUpdate
        fieldId="operands_value"
        name="operands_value"
        rules={validationRule}
        style={{ width: props.inputWidth }}
        initialValue={props.defaultValue}
      >
        <InputNumber
          prefix={props.currencySymbol}
          onChange={props.handleSingleValueChange}
          controls={false}
          style={{ width: props?.width ? props.width : '140px' }}
          value={form.getFieldValue('operands_value')}
          precision={props?.dataType === 'DECIMAL' ? 2 : 0}
        />
      </StyledFormItem>
    </Form>
  );
}
