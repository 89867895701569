import { Form } from 'antd';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import { FormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { t } from '../../text';

import { WorkflowForms } from '../../../type';
import { ActionActivityTypes } from '../../types';
import { ActionType } from '../ActionType';

export default function AddCallLog(props: any) {
  const { data, form } = props;

  const Description = (
    <FormItem
      label={t.call_details}
      name="call_log"
      rules={[{ required: true, message: t.call_details_required }]}
      initialValue={data?.call_log}
    >
      <DescriptionTextArea placeholder={t.enter_call_details} />
    </FormItem>
  );

  return (
    <Form
      name={WorkflowForms.ACTION_FORM}
      style={{ height: '100%' }}
      requiredMark={false}
      form={form}
      layout="vertical"
      preserve={false}
    >
      {Description}
      <ActionType value={ActionActivityTypes.CALL_LOG} />
    </Form>
  );
}
