import { Flex } from 'components/BaseComponents/Layout/Flex';
import { Texto } from 'components/BaseComponents/Typography/Texto';
import styled from 'styled-components';
import { monthDaysGenerator } from 'util/months-generator';
const MonthDay = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? 'var(--geekblue-6)' : 'var(--gray-1)')};
  color: ${({ selected }) => (selected ? 'var(--gray-1)' : 'var(--gray-10)')};
  border-radius: 12px;
  border: 1px solid var(--gray-5);
  padding: var(--space-4) var(--space-8);
  min-width: 32px;
  text-align: center;
  cursor: pointer;
`;

const t = {
  selectDays: 'Select days from a month',
  lastDay: 'Last Day',
};

const monthDays = monthDaysGenerator(31);

interface MonthSelectorProps {
  value?: number[];
  onChange?: (monthNumbers: number[]) => void;
}

export function MonthSelector(props: MonthSelectorProps) {
  const { onChange, value = [] } = props;

  function handleDaySelect(selectedDay: number) {
    const valuePresent = value.includes(selectedDay);

    const newDaysArray = valuePresent
      ? value.filter((day) => day !== selectedDay)
      : [...value, selectedDay];

    onChange && onChange(newDaysArray.sort());
  }

  return (
    <Flex direction="column" gap="--space-8">
      <Texto>{t.selectDays}</Texto>
      <Flex gap="--space-8" wrap="wrap">
        {monthDays.map((day) => (
          <MonthDay
            selected={value.includes(Number(day))}
            key={day}
            onClick={() => handleDaySelect(Number(day))}
          >
            {day}
          </MonthDay>
        ))}
        <MonthDay selected={value.includes(32)} onClick={() => handleDaySelect(Number(32))}>
          {t.lastDay}
        </MonthDay>
      </Flex>
    </Flex>
  );
}
