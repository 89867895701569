import { RuleObject } from 'antd/lib/form';
import { FormRules } from 'components/BaseComponents/Form/Antd/type';
import { CalenderTriggerInput } from 'components/Common/CalenderEvent/CalenderTriggerInput';
import { generatePeriodicOptions } from 'components/Common/CalenderEvent/Hooks';
import { CalendarEventType } from 'components/Common/CalenderEvent/type';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import produce from 'immer';
import { CalendarDaysEventData } from 'types/entities/collection-strategy/rule-event';
import {
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import {
  defaultEqOperatorData,
  defaultInPeriodOperatorData,
} from '../../EventCondition/initial-state';
import { WorkFlowPeriodicOperatorProps } from '../type';

function singleValueValidator(_: RuleObject, value: CalendarDaysEventData) {
  if (value.type === 'DAILY') {
    return Promise.resolve();
  } else if (value.days.length) {
    return Promise.resolve();
  }
  return Promise.reject('Please select atleast one option');
}

const singleValueRules: FormRules = [
  {
    validator: singleValueValidator,
  },
];

export function TriggerPeriodicField(props: WorkFlowPeriodicOperatorProps) {
  const calenderEventOptions = generatePeriodicOptions();
  const operator = props.mandatory?.operator?.operands as DefaultAbsoluteOperand<unknown>;
  function handlePeriodChange(value: CalendarDaysEventData) {
    if (value?.type === CalendarEventType.DAILY) {
      return { type: value?.type, defaultEqOperatorData };
    } else if (value.type === CalendarEventType.DAY_OF_MONTH) {
      const updated = produce(defaultInPeriodOperatorData, (draft) => {
        const absOperand = draft.operands as InAbsoluteOperands<number>;
        const periodicValues = absOperand?.value_holder.map((manager) => {
          return { value: manager.value };
        });
        absOperand.value_holder = periodicValues;
      });
      return { type: value.type, updated };
    }
  }
  return (
    <StyledFormItem
      name={'periodic_conditions'}
      initialValue={
        props.mandatory
          ? { type: props.mandatory?.type, days: operator.value_holder.value }
          : { type: CalendarEventType.DAILY }
      }
      rules={singleValueRules}
    >
      <CalenderTriggerInput options={calenderEventOptions} onChange={handlePeriodChange} />
    </StyledFormItem>
  );
}
