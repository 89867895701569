import { SelectProps } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WorkFlowCreditContactOperationOptions } from 'components/Settings/WorkFlowAutomation/common/Predicate';
import produce from 'immer';
import { ValidateStatus } from 'types/entities/invoice-segments';
import {
  DefaultAbsoluteOperand,
  DefaultContactAbsoluteOperand,
  InAbsoluteOperands,
  UsersValueOperand,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import SearchableSelect from '../../../../../../../Common/SearchableSelect/SearchableSelect';
import { WorkFlowCreditContactOperatorTypes } from '../../../../type';
import { WorkFlowFilterOperator } from '../../../EventCondition/type';
import SelectOption from './SelectOption';
import useProcessDynamicFilterFunctions, {
  DestructureValueType,
  DynamicFilterGeneratorData,
} from './utils';

const t = {
  errorMsg: 'Please fill the field',
};
export type StringRangeItem = {
  value?: DestructureValueType;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
};
interface DynamicFilterGeneratorProps<T extends DynamicFilterGeneratorData> {
  value?: WorkFlowFilterOperator<DestructureValueType>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<DestructureValueType>) => void;
  options?: SelectProps['options'];
  data: T[];
  conditionType: WorkFlowEventTypes;
  initialValue?: string | number | UsersValueOperand;
}

export default function DynamicFilterGenerator<T extends DynamicFilterGeneratorData>(
  props: DynamicFilterGeneratorProps<T>
) {
  const { generateStructure, destructureValue, getMatchedValues } =
    useProcessDynamicFilterFunctions();
  const currentType = props?.value?.type;
  const matchedContact = getMatchedValues(props.conditionType, props.data, props.initialValue);
  const initialValue = generateStructure(props.conditionType, matchedContact);
  function handleOperatorChange(value: string) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        draftFilter.type = value as WorkFlowCreditContactOperatorTypes;
        const statusOperand = draftFilter.operands as
          | DefaultAbsoluteOperand<boolean>
          | InAbsoluteOperands<DestructureValueType>;
        const values = destructureValue(props.conditionType, matchedContact);
        statusOperand.value_holder =
          value === 'IN' && values
            ? [
                {
                  value: values,
                },
              ]
            : { value: true };
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <SearchableSelect
      options={WorkFlowCreditContactOperationOptions}
      onChange={handleOperatorChange}
      value={props?.value?.type ?? 'IN'}
      style={{ width: 'fit-content' }}
    />
  );
  function handleValueChange(value: string) {
    const matchedContact = getMatchedValues(props.conditionType, props.data, value);
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        if (!(draftFilter && matchedContact)) {
          return;
        }
        const ownerOperand = draftFilter.operands as
          | DefaultContactAbsoluteOperand
          | InAbsoluteOperands<DestructureValueType>;
        const values = destructureValue(props.conditionType, matchedContact);
        ownerOperand.value_holder = values
          ? [
              {
                value: values,
              },
            ]
          : [];
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }
  return (
    <Flex gap="--space-8" align="flex-start" style={{ alignSelf: 'center' }}>
      {Operators}
      <HideWrapper hide={currentType !== 'IN'}>
        <StyledFormItem
          shouldUpdate
          validateStatus={matchedContact ? 'success' : 'error'}
          help={matchedContact ? <></> : <>{t.errorMsg}</>}
        >
          <SelectOption
            value={initialValue}
            onChange={handleValueChange}
            contactUsersOption={props.options}
          />
        </StyledFormItem>
      </HideWrapper>
    </Flex>
  );
}
