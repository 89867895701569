import { Badge } from 'antd';
import styled from 'styled-components';
const CollectionStatusBadge = styled(Badge)`
  &.cell {
    .ant-badge-status-dot {
      width: 12px !important;
      height: 12px !important;
    }
  }
  .ant-badge-status-dot {
    width: 16px !important;
    height: 16px !important;
  }
  .ant-badge-status-text {
    display: none;
  }
`;

export { CollectionStatusBadge };
