import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StrategyErrorField } from 'store/collection-strategy/validations';
import { followupStrategyActions } from '..';
import {
  emailDeliveryDaysSelector,
  emailDeliveryTimeSelector,
  enableEmailDeliverySelector,
  followupEnableSelector,
  followupTitleSelector,
  strategyErrorSelector,
  strategyErrorsSelector,
  strategyErrorsVisibleSelector,
} from '../selectors';

export function useStrategyTitle() {
  const title = useSelector(followupTitleSelector);
  const dispatch = useDispatch();

  const setTitle = useCallback(
    (title: string) => {
      dispatch(followupStrategyActions.updateTitle(title));
    },
    [dispatch]
  );

  return { title, setTitle };
}
export function useStrategyEnable() {
  const enabled = useSelector(followupEnableSelector);
  const dispatch = useDispatch();

  const setEnable = useCallback(
    (enabled: boolean) => {
      dispatch(followupStrategyActions.enableFollowup(enabled));
    },
    [dispatch]
  );

  return { enabled, setEnable };
}

export function useToggleEmailDeliverySettings() {
  const enabled = useSelector(enableEmailDeliverySelector);
  const dispatch = useDispatch();

  const setEnabled = useCallback(
    (enabled: boolean) => {
      dispatch(followupStrategyActions.enableEmailDeliverySettigns(enabled));
    },
    [dispatch]
  );

  return { enabled, setEnabled };
}

export function useEmailDeliveryTime() {
  const deliveryTime = useSelector(emailDeliveryTimeSelector);
  const dispatch = useDispatch();

  const setDeliveryTime = useCallback(
    (newTime: string) => {
      dispatch(followupStrategyActions.updateEmailDeliveryTime(newTime));
    },
    [dispatch]
  );

  return { deliveryTime, setDeliveryTime };
}

export function useEmailDeliveryDays() {
  const deliveryDays = useSelector(emailDeliveryDaysSelector);
  const dispatch = useDispatch();

  const setDeliveryDays = useCallback(
    (newDays: number[]) => {
      dispatch(followupStrategyActions.updateEmailDeliveryDays(newDays));
    },
    [dispatch]
  );

  return { deliveryDays, setDeliveryDays };
}

export function useStrategyErrors() {
  const showError = useSelector(strategyErrorsVisibleSelector);
  const errors = useSelector(strategyErrorsSelector);
  const dispatch = useDispatch();

  const setShowErrors = useCallback(
    (showError: boolean) => {
      dispatch(followupStrategyActions.setShowErrors(showError));
    },
    [dispatch]
  );

  return { showError, errors, setShowErrors };
}

export function useStrategyErrorField(fieldKey: StrategyErrorField) {
  const error = useSelector(strategyErrorSelector(fieldKey));
  const showError = useSelector(strategyErrorsVisibleSelector);

  return { error, showError };
}
