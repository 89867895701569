import { MultiCheckBoxPagination } from 'components/Common/FilterComponents/Filters/MultiSelectCheckbox';
import { uniqBy } from 'lodash';
import {
  useGetCustomerDropdownList,
  useGetCustomerDropdownListSearch,
  useGetSelectedCustomerDropdownList,
} from 'queries/Customers/customers';
import { useMemo } from 'react';
import { CustomerDropDownDTO, CustomerUIDropDownDTO } from 'types/entities/customer';
import { useGetCustomerNameFormatter } from './useGetCustomerNameFormatter';

interface useCustomerListProps {
  selectedIds?: number[];
  unscoped?: boolean;
}

function useCustomerList(props: useCustomerListProps) {
  const { data: defaultList } = useGetCustomerDropdownList(props.unscoped);
  const { data: selectedValueList } = useGetSelectedCustomerDropdownList(props.selectedIds);
  const customerSearch = useGetCustomerDropdownListSearch(props.unscoped);
  const { getCustomerNameFormatter } = useGetCustomerNameFormatter();

  const defaultPagination: Pick<MultiCheckBoxPagination, 'currentPage' | 'totalPages'> = {
    currentPage: defaultList?.current_page,
    totalPages: defaultList?.total_pages,
  };
  async function handleCustomerSearch(
    text: string,
    page?: number
  ): Promise<CustomerUIDropDownDTO[]> {
    const data = await customerSearch.mutateAsync({ text, page });

    return data.list.map((customer) => {
      return {
        ...customer,
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_records: data.total_records,
      };
    });
  }
  async function handlePagintaion(page: number, text?: string): Promise<CustomerUIDropDownDTO[]> {
    const data = await customerSearch.mutateAsync({ text, page });

    return data.list.map((customer) => {
      return {
        ...customer,
        current_page: data.current_page,
        total_pages: data.total_pages,
        total_records: data.total_records,
      };
    });
  }

  function getCustomerList() {
    if (!selectedValueList?.list?.length) {
      return defaultList?.list?.length
        ? defaultList.list?.map((option) => {
            const customerName = getCustomerNameFormatter({
              customerName: option.name,
              customerNumber: option.customer_no,
            });
            return { label: customerName, value: option.id, customerObject: option };
          })
        : [];
    }
    const list = [...selectedValueList.list, ...(defaultList?.list ?? [])];
    const modifiedList = list?.map((option) => {
      const customerName = getCustomerNameFormatter({
        customerName: option.name,
        customerNumber: option.customer_no,
      });
      return { label: customerName, value: option.id, customerObject: option };
    });

    return uniqBy(modifiedList, 'value');
  }

  const customersList = useMemo(getCustomerList, [
    defaultList,
    selectedValueList,
    getCustomerNameFormatter,
  ]);
  function computedSearchedCustomerNameOptions(customerInfo?: CustomerDropDownDTO[]) {
    if (customerInfo && Boolean(customerInfo.length)) {
      return customerInfo?.map((option) => {
        const customerName = getCustomerNameFormatter({
          customerName: option.name,
          customerNumber: option.customer_no,
        });
        return { label: customerName, value: option.id, customerObject: option };
      });
    }
    return [];
  }

  return {
    customerSearch,
    customersList,
    computedSearchedCustomerNameOptions,
    handleCustomerSearch,
    handlePagintaion,
    defaultPagination,
  };
}

export default useCustomerList;
