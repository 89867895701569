import { Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { useGetInvoiceStatusOptions } from 'components/Common/hooks/useGetFilteredInvoiceStatus';
import { WorkFlowOperatorTypes } from 'components/Settings/WorkFlowAutomation/CustomAutomations/type';
import {
  InvoiceStatusChangedOptions,
  invoiceStatusList,
} from 'components/Settings/WorkFlowAutomation/common/Predicate';
import produce from 'immer';
import { head } from 'lodash';
import { InvoiceStatus } from 'types/entities/invoice';
import {
  InAbsoluteOperands,
  WorkFlowAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import {
  WorkFlowFilterOperator,
  WorkFlowInvoiceStatusFilterOperator,
} from '../../EventCondition/type';
import BetweenStatus from './BetweenStatus';

interface TotalDueAmountFilterProps {
  value: WorkFlowInvoiceStatusFilterOperator<InvoiceStatus>;
  form: FormInstance<any>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<InvoiceStatus>) => void;
}

export type InvoiceWorkFlowOperatorTypes = Extract<WorkFlowOperatorTypes, 'CHG' | 'IN'>;
export function InvoiceStatusFilter(props: TotalDueAmountFilterProps) {
  const currentType = props?.value?.type;
  const invoiceListOptions = useGetInvoiceStatusOptions(invoiceStatusList);
  const eqStatusValue = props.value?.operands as InAbsoluteOperands<InvoiceStatus>;

  function handleOperatorChange(value: InvoiceWorkFlowOperatorTypes) {
    props.form.setFieldValue('operands_value', InvoiceStatus.PENDING);
    const updatedFilter = produce(props.value, (draft) => {
      if (draft) {
        draft.type = value;
        const operandValue = draft.operands as WorkFlowAbsoluteOperands<InvoiceStatus>;
        operandValue.value_holder =
          value === 'CHG'
            ? {
                from: { value: InvoiceStatus.PENDING },
                to: { value: InvoiceStatus.VOID },
              }
            : value === 'IN'
            ? [{ value: InvoiceStatus.PENDING }]
            : { value: InvoiceStatus.PENDING };
      }
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  function handleValueChange(value: InvoiceStatus) {
    const updatedFilter = produce(props.value, (draftFilter) => {
      if (draftFilter) {
        const operandValue = draftFilter.operands as InAbsoluteOperands<InvoiceStatus>;
        operandValue.value_holder = [{ value }];
      }
    });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  const Operators = (
    <Select
      options={InvoiceStatusChangedOptions}
      style={{ width: 'fit-content' }}
      value={props?.value?.type ?? 'IN'}
      onChange={handleOperatorChange}
    />
  );
  const singleStatusValue = head(eqStatusValue?.value_holder)?.value;
  return (
    <Flex gap="--space-8" align="flex-start">
      {Operators}
      <HideWrapper hide={currentType !== 'CHG'}>
        <BetweenStatus
          value={props?.value}
          onChange={props.onChange}
          form={props.form}
          options={invoiceListOptions}
        />
      </HideWrapper>

      <HideWrapper hide={currentType !== 'IN'}>
        <StyledFormItem shouldUpdate required>
          <Select
            options={invoiceListOptions}
            onChange={handleValueChange}
            value={singleStatusValue}
            style={{ width: '120px' }}
            showSearch
          />
        </StyledFormItem>
      </HideWrapper>
    </Flex>
  );
}
