import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Tooltip, TooltipProps } from 'antd';

interface IconWithToolToolTipProps {
  toolTipProps: TooltipProps;
  fontAwesomeIconProps: FontAwesomeIconProps;
}

export default function IconWithToolToolTip(props: IconWithToolToolTipProps) {
  const { toolTipProps, fontAwesomeIconProps } = props;
  const Icon = <FontAwesomeIcon {...fontAwesomeIconProps} />;
  const InfoIconWithToolTip = <Tooltip {...toolTipProps}>{Icon}</Tooltip>;

  return <>{InfoIconWithToolTip}</>;
}
