import { Form, Input } from 'antd';
import { UploadFilesComponent } from 'components/BaseComponents/UploadFiles';
import { useFileUpload } from 'components/BaseComponents/UploadFiles/hooks';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
// import { DisputeTypeSelect } from 'components/HigherOrderComponent/KeyActivitesContainer/RaiseDispute/DisputeType';
import DescriptionTextArea from 'components/BaseComponents/DescriptionTextArea/TextArea';
import AssignOwners from 'components/Common/ActivitiesForm/FormElements/AssignOwners';
import { UploadFileTypes } from 'services/attachments';
import { UploadedFiles } from 'types/common/attachments';
import { FormItem } from '../../../../common/style';
import { t } from '../../text';
import { ActionActivityTypes, RaiseDisputeActionTypeProps } from '../../types';
import { WorkflowForms } from '../../../type';
import { ActionType } from '../ActionType';

export default function RaiseDisputeAction(props: RaiseDisputeActionTypeProps) {
  const { form, data } = props;

  const [fileList, setFileList] = useFileUpload({
    form: form,
    paramName: 'upload',
    uploadFileType: UploadFileTypes.DISPUTE,
  });

  const DisputeTitle = (
    <FormItem
      label={t.title}
      name="title"
      rules={[{ required: true, message: t.dispute_title_needed }]}
      initialValue={data?.title}
    >
      <Input placeholder={t.enter_dispute_title} />
    </FormItem>
  );

  // const DisputeType = (
  //   <FormItem
  //     label={t.dispute_type}
  //     name="dispute_type_ids"
  //     rules={[{ required: true, message: t.dispute_type_needed }]}
  //     initialValue={data?.dispute_type_ids}
  //   >
  //     <DisputeTypeSelect
  //       selectedDisputeType={data?.dispute_type_ids}
  //       onSelectCallback={(value) => {
  //         form.setFieldValue('dispute_type_ids', value);
  //       }}
  //     />
  //   </FormItem>
  // );

  const DisputeOwner = (
    <FormItem
      label={t.dispute_owner}
      name="dispute_owner"
      rules={[{ required: true, message: t.dispute_owner_needed }]}
      initialValue={data?.dispute_owner}
    >
      <AssignOwners
        placeholder={t.select_dispute_owner}
        context={'STRATERGY'}
        assigneeOptionType={ActionableEntity.INVOICE}
        maxTagCount={'responsive'}
      />
    </FormItem>
  );

  const Description = (
    <FormItem
      label={t.description}
      name="dispute_description"
      rules={[{ required: true, message: t.dispute_description_needed }]}
      initialValue={data?.description}
    >
      <DescriptionTextArea placeholder={t.enter_dispute_description} />
    </FormItem>
  );

  const UploadFiles = (
    <FormItem name="attachments" label={t.attachments}>
      <UploadFilesComponent
        fileList={fileList}
        onFileUpload={(file: File) => {
          const attachedFiles = [...fileList, file];
          setFileList(attachedFiles);
          form.setFieldValue('file_upload_ids', attachedFiles);
        }}
        onFileRemove={(deletedFile?: UploadedFiles) => {
          if (!deletedFile) return;
          const updatedFileList = fileList?.filter((file) => file.uid !== deletedFile.uid);
          setFileList(updatedFileList);
          form.setFieldValue('file_upload_ids', updatedFileList);
        }}
        showFileItemsPreview={true}
      />
    </FormItem>
  );

  return (
    <Form
      name={WorkflowForms.ACTION_FORM}
      style={{ height: '100%' }}
      requiredMark={false}
      form={form}
      layout="vertical"
      preserve={false}
    >
      {DisputeTitle}
      {/* {DisputeType} */}
      {DisputeOwner}
      {Description}
      {UploadFiles}
      <ActionType value={ActionActivityTypes.DISPUTE} />
    </Form>
  );
}
