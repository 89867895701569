import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'reducers/rootReducer';
import { RuleErrorField } from 'store/collection-strategy-rule/validations';

const dunningRuleSliceSelector = (state: RootState) => state.followupRule;

export const dunningRuleRuleSelector = createSelector(
  dunningRuleSliceSelector,
  (dunningRuleSlice) => dunningRuleSlice.rule
);

export const dunningRuleErrorsSelector = createSelector(
  dunningRuleSliceSelector,
  (dunningRuleSlice) => dunningRuleSlice.errors
);

export const dunningRuleErrorsVisibleSelector = createSelector(
  dunningRuleSliceSelector,
  (dunningRuleSlice) => dunningRuleSlice.showErrors
);

export const dunningRuleErrorSelector = (errorField: RuleErrorField) =>
  createSelector(dunningRuleErrorsSelector, (dunningRuleErrors) => dunningRuleErrors[errorField]);

export const dunningRuleFollowupTypeSelector = createSelector(
  dunningRuleSliceSelector,
  (dunningRuleSlice) => dunningRuleSlice.followupType
);

export const dunningRuleFormVisibleSelector = createSelector(
  dunningRuleSliceSelector,
  (dunningRuleSlice) => dunningRuleSlice.formVisible
);

export const dunningRuleTitleSelector = createSelector(
  dunningRuleRuleSelector,
  (dunningRule) => dunningRule.data.title
);

export const dunningRuleEventSelector = createSelector(
  dunningRuleRuleSelector,
  (dunningRule) => dunningRule.data.event
);

export const dunningRuleScopeSelector = createSelector(
  dunningRuleRuleSelector,
  (dunningRule) => dunningRule.data.scopes
);

export const dunningRuleActionSelector = createSelector(
  dunningRuleRuleSelector,
  (dunningRule) => dunningRule.data.action
);

export const dunningRuleActionTypeSelector = createSelector(
  dunningRuleActionSelector,
  (dunningRuleAction) => dunningRuleAction.type
);
