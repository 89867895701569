import { GrowFlex, GrowTypography } from '@sinecycle/growcomponents';
import { Switch, Tooltip } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { AccountConfigKey } from 'types/entities/account';
import IconWithToolToolTip from './IconWithToolToolTip';
interface ConsolidatedSOASwitchProps {
  value?: boolean;
  onChange?: SwitchChangeEventHandler;
  hide?: boolean;
}
const t = {
  consolidatedText: 'Consolidated Statement',
  infoTitle: 'Includes all child customers of the parent customer.',
  infoDesc:
    '(Note: If a customer doesn’t have child customers, they will receive an individual statement rather than a consolidated one)',
  disabledText: 'No child customers',
};
const ConsolidatedOutstandingText = <GrowTypography.Text>{t.consolidatedText}</GrowTypography.Text>;
const InfoToolTip = (
  <GrowFlex vertical gap={'4'}>
    <div>{t.infoTitle}</div>
    <div>{t.infoDesc}</div>
  </GrowFlex>
);
const InfoIconWithToolTip = (
  <IconWithToolToolTip
    toolTipProps={{ title: InfoToolTip, zIndex: 1000000 }}
    fontAwesomeIconProps={{ icon: ['far', 'info-circle'], color: 'var(--blue-6)' }}
  />
);

export default function ConsolidatedSOASwitch(props: ConsolidatedSOASwitchProps) {
  const isParentChildEnabled = useConfig(AccountConfigKey.PARENT_CHILD_ENABLED);

  const ConsolidatedSwitch = (
    <Tooltip title={props.hide ? t.disabledText : ''} zIndex={1200}>
      <Switch size="small" checked={props.value} onChange={props?.onChange} disabled={props.hide} />
    </Tooltip>
  );
  return (
    <>
      {isParentChildEnabled ? (
        <GrowFlex space="8" align="center">
          {ConsolidatedSwitch} {ConsolidatedOutstandingText}
          {InfoIconWithToolTip}
        </GrowFlex>
      ) : (
        <></>
      )}
    </>
  );
}
