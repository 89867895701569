import { GrowCheckBox, GrowCheckBoxGroup, GrowTypography } from '@sinecycle/growcomponents';
import { Spin } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import styled from 'styled-components';
import { OptionsProps } from '../../types';

const StyledCheckBox = styled(GrowCheckBox)`
  .ant-checkbox {
    align-self: flex-start;
  }
`;
export interface MultiCheckBoxPagination {
  hasPagination?: boolean;
  onPagination?: () => void;
  currentPage?: number;
  totalPages?: number;
  loading?: boolean;
  totalRecords?: number;
}
interface FilterInputMultiSelectProps<T> {
  label?: string;
  name: string;
  value?: T[];
  options: OptionsProps[];
  onChange?: (value: T[]) => void;
  allowSelectAndUnselectAll?: boolean;
  pagination?: MultiCheckBoxPagination;
}

const style: React.CSSProperties = {
  maxHeight: 220,
  overflow: 'auto',
};

type MultiSelectCheckboxValueType = string | number;

export function MultiSelectCheckboxFilter<T extends MultiSelectCheckboxValueType>(
  props: FilterInputMultiSelectProps<T>
) {
  const { onChange, name, value, options, pagination } = props;

  const optionsList = options.map((option) => ({
    label: option.label,
    value: option.value,
    isChecked: value ? Boolean(value.find((v) => v === option.value)) : false,
  }));

  const hasPagination =
    pagination?.currentPage && pagination.totalPages
      ? pagination.currentPage < pagination.totalPages
      : false;

  return (
    <GrowCheckBoxGroup
      value={value}
      onChange={(value) => onChange && onChange(value as T[])}
      name={name}
      style={style}
      design="border-design"
    >
      <Flex direction="column">
        {optionsList.map((option, index) => (
          <StyledCheckBox
            design="border-design"
            style={{
              marginLeft: 0,
              maxWidth: '250px',
              overflow: 'hidden',
              lineHeight: 'normal',
              padding: 'var(--space-8)',
            }}
            key={`${option.value}-${index}`}
            value={option.value}
            checked={option.isChecked}
          >
            <GrowTypography.Text>{option.label}</GrowTypography.Text>
          </StyledCheckBox>
        ))}
        <>
          {pagination?.loading ? (
            <Spin size="small" tip="Loading...." />
          ) : (
            <>
              {hasPagination && pagination ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <GrowTypography.Link strong onClick={pagination.onPagination}>
                    Load More
                  </GrowTypography.Link>
                </div>
              ) : null}
            </>
          )}
        </>
      </Flex>
    </GrowCheckBoxGroup>
  );
}
