import { SelectProps } from 'antd';
import SearchableSelect from '../../../../../../../Common/SearchableSelect/SearchableSelect';

const t = {
  placeHolder: 'Please select users',
};
export interface SingleStatusFilterProps {
  value?: string | number;
  onChange?: (updatedFilter: string) => void;
  contactUsersOption?: SelectProps['options'];
}
export default function SelectOption(props: SingleStatusFilterProps) {
  const Filter = (
    <>
      {props.contactUsersOption && (
        <SearchableSelect
          value={props.value}
          options={props.contactUsersOption}
          onChange={props.onChange}
          style={{ width: '240px' }}
          placeHolder={t.placeHolder}
        />
      )}
    </>
  );
  return <>{Filter}</>;
}
