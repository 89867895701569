import { FormInstance } from 'antd';
import { CalendarEventType } from 'components/Common/CalenderEvent/type';
import { WorkFlowInvoiceEventFilterType } from 'components/Settings/WorkFlowAutomation/utils';
import { Dayjs } from 'dayjs';
import { isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { baseCurrencySelector } from 'store/authentication/authentication';
import { CustomField } from 'types/entities/custom-field';
import { InvoiceStatus } from 'types/entities/invoice';
import { UsersValueOperand } from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { getCurrencySymbol } from 'util/number-formatter';
import CollectionOwnerField from '../Common/CollectionOwnerField';
import ContactsField from '../Common/ContactsField';
import CreditTermFilter from '../Common/CreditTermFilter';
import SubsidaryFilter from '../Common/SubsidiaryFilter';
import { getDefaultFilterDataFromType } from '../EventCondition/Helper';
import { WorkFlowInOperator } from '../EventCondition/operator';
import {
  DateWorkFlowFilterOperator,
  WorkFlowFilterOperator,
  WorkFlowInvoiceStatusFilterOperator,
} from '../EventCondition/type';
import BooleanFilter from './BooleanFilter/BooleanFilter';
import SingleStatusFilter from './CommonFilterHelper/CustomerSingleStatusFilter';
import { CustomFieldMapping } from './CustomFieldFilter/CustomFieldMappingFilter';
import { InvoiceStatusFilter } from './InvoiceStatusFilter/InvoiceStatusFilter';
import WorkFlowDefaultDateFilter from './WorkFlowDateFilters';
import { NumericFilter } from './WorkFlowNumericFilter';

interface RenderProps {
  value?: WorkFlowFilterOperator<unknown> | DateWorkFlowFilterOperator<unknown>;
  onChange?: (
    updatedFilter: WorkFlowFilterOperator<unknown> | DateWorkFlowFilterOperator<unknown>
  ) => void;
  form: FormInstance<any>;
  selectedEvent: WorkFlowEventTypes;
  type?: CalendarEventType | WorkFlowInvoiceEventFilterType;

  customFields?: CustomField[];
}
const t = {
  days: 'days',
};
export function WorkFlowFilterRenderer(props: RenderProps) {
  const { onChange: filterToRenderOnchange, value: filterToRenderValue } = props;
  const baseCurrency = useSelector(baseCurrencySelector) ?? 'USD';
  const currencySymbol = getCurrencySymbol(baseCurrency);
  const defaultFilterData =
    props.selectedEvent && getDefaultFilterDataFromType(props.selectedEvent, props.customFields);

  switch (props.selectedEvent) {
    case 'DUE_DAYS':
    case 'PTP_DATE':
    case 'OVERDUE_DAYS':
      return (
        <NumericFilter
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<number>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<number>)
          }
          onChange={filterToRenderOnchange}
          singleFieldMoreInfo={t.days}
          singleFieldInputWidth={'50px'}
        />
      );
    case 'OPEN_INVOICE_COUNT':
      return (
        <NumericFilter
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<number>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<number>)
          }
          onChange={filterToRenderOnchange}
          singleFieldInputWidth={'50px'}
        />
      );
    case 'INVOICE_BALANCE':
    case 'INVOICE_VALUE':
    case 'PAYMENT_BALANCE':
    case 'CREDIT_MEMO_BALANCE':
    case 'OUTSTANDING_VALUE':
    case 'PTP_AMOUNT':
    case 'INVOICE_BALANCE_DUE_TX':
    case 'INVOICE_DUE':
    case 'INVOICE_OVERDUE':
    case 'INVOICE_TAX':
      return (
        <NumericFilter
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<number>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<number>)
          }
          selectedFilter={props.selectedEvent}
          onChange={filterToRenderOnchange}
          prefix={currencySymbol}
          precision={2}
          singleFieldInputWidth={'114px'}
        />
      );
    case 'ISSUE_DATE':
    case 'INVOICE_DUE_DATE':
      return (
        <WorkFlowDefaultDateFilter
          value={filterToRenderValue as DateWorkFlowFilterOperator<Dayjs | number>}
          onChange={filterToRenderOnchange}
          singleFieldMoreInfo={t.days}
        />
      );
    case 'INVOICE_STATUS':
      return (
        <InvoiceStatusFilter
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowInvoiceStatusFilterOperator<InvoiceStatus>)
              : (defaultFilterData?.operator as WorkFlowInvoiceStatusFilterOperator<InvoiceStatus>)
          }
          onChange={filterToRenderOnchange}
          form={props.form}
        />
      );
    case 'DELIVERY_FLAG':
    case 'INVOICE_PDF':
    case 'INVOICE_IS_DUE':
    case 'INVOICE_IS_OVERDUE':
      return (
        <BooleanFilter
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowInOperator<boolean>)
              : (defaultFilterData?.operator as WorkFlowInOperator<boolean>)
          }
          onChange={filterToRenderOnchange}
        />
      );

    case 'PAYMENT_STATUS':
    case 'CREDIT_MEMO_STATUS':
    case 'DISPUTE_STATUS':
    case 'DISPUTE_ASSIGNEE':
    case 'TASK_ASSIGNEE':
    case 'TASK_STATUS':
    case 'PTP_STATUS':
    case 'ESCALATION_STATUS':
    case 'ESCALATION_ASSIGNEE':
    case 'DISPUTE_TYPE':
    case 'INVOICE_PAYMENT_STATUS':
    case 'INVOICE_FOLLOWUP_STATUS':
      return (
        <SingleStatusFilter
          selectedFilter={props.selectedEvent}
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<number | string>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<number | string>)
          }
          onChange={filterToRenderOnchange}
          prefix={currencySymbol}
          precision={2}
          singleFieldInputWidth="114px"
        />
      );

    case 'CREDIT_TERM':
      return (
        <CreditTermFilter
          type={'FILTER'}
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<string>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<string>)
          }
          onChange={filterToRenderOnchange}
        />
      );
    case 'INVOICE_SUBSIDIARY':
    case 'CUSTOMER_SUBSIDIARY':
      return (
        <SubsidaryFilter
          type={'FILTER'}
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<number>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<number>)
          }
          onChange={filterToRenderOnchange}
          conditionType={props.selectedEvent}
        />
      );
    case 'COLLECTION_OWNER':
      return (
        <CollectionOwnerField
          type={'FILTER'}
          value={filterToRenderValue as WorkFlowFilterOperator<number>}
          onChange={filterToRenderOnchange}
        />
      );
    case 'INVOICE_CREATE':
    case 'INVOICE_UPDATE':
    case 'PAYEMENT_CREATE':
    case 'CREDIT_MEMO_CREATED':
      return <></>;
    case 'PRIMARY_CONTACT':
    case 'SECONDARY_CONTACT':
      return (
        <ContactsField
          type={'FILTER'}
          value={
            filterToRenderValue
              ? (filterToRenderValue as WorkFlowFilterOperator<UsersValueOperand>)
              : (defaultFilterData?.operator as WorkFlowFilterOperator<UsersValueOperand>)
          }
          onChange={filterToRenderOnchange}
          selectedFilter={props.selectedEvent}
        />
      );
    default:
      return (
        <CustomFieldMapping
          value={filterToRenderValue as WorkFlowFilterOperator<number>}
          selectedFilter={
            isNumber(props.selectedEvent) ? (props.selectedEvent as number) : undefined
          }
          onChange={filterToRenderOnchange}
          customFields={props.customFields}
        />
      );
  }
}
