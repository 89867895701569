import { capitalize, trim } from 'lodash';

interface DetailsObject {
  first_name?: string;
  last_name?: string;
}

export default function formatFullName(detailsObject: DetailsObject) {
  const { first_name = '', last_name = '' } = detailsObject;
  return capitalize(`${trim(first_name)} ${trim(last_name)}`);
}
