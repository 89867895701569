import { Flex } from 'components/BaseComponents/Layout/Flex';
import HideWrapper from 'components/Common/Util/HideWrapper';
import { WorkFlowBetweenRelativeOperands } from '../../../EventCondition/operator';
import { TriggerDisplayTexts, displaySingleItem } from '../DisplayOperand';

interface RelativeBTWNDateProps {
  condition: WorkFlowBetweenRelativeOperands;
  color: string;
  strong?: boolean;
}

export default function RelativeBTWNDate(props: RelativeBTWNDateProps) {
  const fromValue = props?.condition?.value?.from?.offset;
  const toValue = props?.condition?.value?.to?.offset;
  const afterCurrentDate = displaySingleItem(
    TriggerDisplayTexts.currentDate,
    props.color,
    props.strong
  );
  const beforeCurrentDate = displaySingleItem(
    `${TriggerDisplayTexts.currentDate} ${TriggerDisplayTexts.to}`,
    props.color,
    props.strong
  );
  const fromTextRenderer = displaySingleItem(
    `${fromValue} ${TriggerDisplayTexts.daysBefore}`,
    props.color,
    props.strong
  );
  const toTextRenderer = displaySingleItem(
    `${toValue} ${TriggerDisplayTexts.daysAfter}`,
    props.color,
    props.strong
  );

  return (
    <Flex gap="var(--space-4)">
      <HideWrapper hide={!fromValue}>{fromTextRenderer}</HideWrapper>
      {beforeCurrentDate}
      <HideWrapper hide={!toValue}>{toTextRenderer}</HideWrapper>
      {afterCurrentDate}
    </Flex>
  );
}
