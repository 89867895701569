import dayjs, { Dayjs } from 'dayjs';
import { InvoiceStatus } from 'types/entities/invoice';
import { InvoiceSegmentOperandType } from 'types/entities/invoice-segments/operands';
import {
  CustomerPeriodContactOperatorprops,
  DateWorkFlowEQOperator,
  WorkFlowEqualToOperator,
  WorkFlowGreaterOperator,
  WorkFlowGreaterThanOperator,
  WorkFlowInOperator,
} from './operator';
import {
  WorkFlowCustomerContactAnyChgAmountFilters,
  WorkFlowCustomerOwnerFilters,
  WorkFlowCustomerSecondaryFilters,
  WorkFlowInvoiceAmountFilter,
  WorkFlowInvoiceDeliveryFilters,
  WorkFlowInvoiceFollowupStatusFilter,
  WorkFlowInvoiceIssueDateFilter,
  WorkFlowInvoicePaymentStatusFilter,
  WorkFlowInvoicePDFFilters,
  WorkFlowInvoiceStatusFilter,
  WorkFlowStatusesFilters,
} from './type';
import { InvoiceFollowupStatus } from 'store/invoice/type';
import { InvoicePaymentStatus } from 'types/activities/payment';

const defaultGreaterThanOperatorData: WorkFlowGreaterThanOperator<number> = {
  type: 'GOE',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: { value: 0 },
  },
};
const defaultPdfOperatorData: WorkFlowInOperator<boolean> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [{ value: true }],
  },
};
const defaultCustomerPeriodContactOperatorData: CustomerPeriodContactOperatorprops<boolean> = {
  type: 'ANY',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: { value: true },
  },
};

const defaultDeliveryOperatorData: WorkFlowInOperator<boolean> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [{ value: false }],
  },
};
const defaultGreaterThanDateOperatorData: WorkFlowGreaterOperator<Dayjs> = {
  type: 'GT',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: {
      value: dayjs(),
    },
  },
};
const defaultEQDateOperatorData: DateWorkFlowEQOperator<string> = {
  type: 'EQ',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: {
      value: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    },
  },
};

export const defaultInvoiceStatusInOperatorData: WorkFlowInOperator<unknown> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [{ value: 'PENDING' }],
  },
};

export const defaultInvoicePaymentStatusInOperatorData: WorkFlowInOperator<unknown> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [{ value: InvoicePaymentStatus.PENDING }],
  },
};
export const defaultInvoiceFollowupStatusInOperatorData: WorkFlowInOperator<unknown> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [{ value: InvoiceFollowupStatus.EMAIL_OPENED }],
  },
};
export const defaultStatusInOperatorData: WorkFlowInOperator<string> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [],
  },
};

export const defaultEqOperatorData: WorkFlowEqualToOperator<boolean> = {
  type: 'EQ',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: { value: true },
  },
};
export const defaultInPeriodOperatorData: WorkFlowInOperator<unknown> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [
      {
        value: null,
      },
    ],
  },
};
export const defaultInvoiceAmountData: WorkFlowInvoiceAmountFilter = {
  operator: defaultGreaterThanOperatorData,
  mandatory: false,
};

export const greaterThanDefaultData: WorkFlowInvoiceAmountFilter = {
  operator: defaultGreaterThanOperatorData,
  mandatory: false,
};

export const defaultInvoiceStatusData: WorkFlowInvoiceStatusFilter = {
  operator: defaultInvoiceStatusInOperatorData as WorkFlowInOperator<InvoiceStatus>,
  mandatory: false,
};
export const defaultInvoiceFollowupStatusData: WorkFlowInvoiceFollowupStatusFilter = {
  operator: defaultInvoiceFollowupStatusInOperatorData as WorkFlowInOperator<InvoiceFollowupStatus>,
  mandatory: false,
};

export const defaultInvoicePaymentStatusData: WorkFlowInvoicePaymentStatusFilter = {
  operator: defaultInvoicePaymentStatusInOperatorData as WorkFlowInOperator<InvoicePaymentStatus>,
  mandatory: false,
};

export const defaultDateData: WorkFlowInvoiceIssueDateFilter = {
  operator: defaultGreaterThanDateOperatorData,
  mandatory: false,
};
export const CustomFieldDefaultDateData: WorkFlowInvoiceIssueDateFilter = {
  operator: defaultEQDateOperatorData,
  mandatory: false,
};
export const defaultDeliveryFlagData: WorkFlowInvoiceDeliveryFilters = {
  operator: defaultDeliveryOperatorData,
  mandatory: false,
};
export const defaultInvoicePdfData: WorkFlowInvoicePDFFilters = {
  operator: defaultPdfOperatorData,
  mandatory: false,
};
export const defaultInvoiceCreatedData = {
  type: 'INVOICE_CREATE',
  mandatory: false,
};
export const defaultStatusData: WorkFlowStatusesFilters = {
  operator: defaultStatusInOperatorData,
  mandatory: false,
};
export const defaultCustomerAnyChgAmountData: WorkFlowCustomerContactAnyChgAmountFilters = {
  operator: defaultCustomerPeriodContactOperatorData,
  mandatory: false,
};
export const defaultCustomerSecondaryData: WorkFlowCustomerSecondaryFilters = {
  operator: defaultCustomerPeriodContactOperatorData,
  mandatory: false,
};
export const defaultCustomerOwnerData: WorkFlowCustomerOwnerFilters = {
  operator: defaultCustomerPeriodContactOperatorData,
  mandatory: false,
};
