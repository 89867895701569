import {
  DisputeFilters,
  DisputeStatus,
  DisputeStatusConfig,
} from 'components/CollectionActivites/Disputes/types';
import { IParamsForPostComments } from 'components/HigherOrderComponent/KeyActivitesContainer/Comment/comment';
import { Invoice, InvoiceLineItem } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { ActivityBaseWithAttachments } from './activity';

export const DisputeTypeMenu = [
  {
    label: 'Open',
    value: 'OPEN',
    color: 'red',
  },
  {
    label: 'Waiting on Customer',
    value: 'WAITING_ON_CUSTOMER',
    color: 'orange',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
    color: 'green',
  },
];

type DisputeType = { id: number; name: string };

export interface Dispute extends ActivityBaseWithAttachments {
  id: number;
  title: string;
  description: string;
  status: DisputeStatus;
  assigned_to_list: User[];
  invoices: Invoice[];
  invoice_line_items: InvoiceLineItem[];
  dispute_types: DisputeType[];
}

export type UpdateDisputeActivityParams = {
  ids: string[];
  participant_ids?: number[];
  status?: DisputeStatus;
};

export type UpdateDisputeActivityBulkActionParams = {
  filter: DisputeFilters;
} & UpdateDisputeActivityParams;

export type BulkActionDelete = {
  ids: string[];
  filter: DisputeFilters;
};

export type DisputeCommentsParams = Omit<IParamsForPostComments, 'comments' | 'reference_ids'> & {
  reference_ids?: string[];
  comments?: string;
  file_upload_ids?: string[];
};
export type DisputeMultiSelectComment = {
  ids: string[];
  comments: DisputeCommentsParams;
};

export type DisputeBulkSelectComment = {
  comments: DisputeCommentsParams;
  filter: DisputeFilters;
};

export const disputeStatusConfig: DisputeStatusConfig = {
  OPEN: {
    label: 'Open',
    style: {
      backgroundColor: 'var(--red-1)',
      textColor: 'var(--red-7)',
    },
  },
  WAITING_ON_CUSTOMER: {
    label: 'Waiting on Customer',
    style: {
      backgroundColor: 'var(--orange-1)',
      textColor: 'var(--orange-8)',
    },
  },
  CLOSED: {
    label: 'Closed',
    style: {
      backgroundColor: 'var(--green-1)',
      textColor: 'var(--green-8)',
    },
  },
};

export function getDisputeStatus(status: DisputeStatus) {
  return disputeStatusConfig[status];
}
