import { GrowFlex } from '@sinecycle/growcomponents';
import { Form } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { EditorCanvas } from 'components/BaseComponents/RTE/Editor';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { EmailForm } from 'components/Common/Email/Form/EmailForm/EmailForm';
import { UploadDrawerPosition } from 'components/Common/FileUploadDrawer';
import { getTransformedSelection } from 'components/Common/SelectComponents/RelativeContactSelect';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { useRichTextEditor } from 'lib/RichText/TipTapEditor/hooks/useRichTextEditor';
import { match } from 'ts-pattern';
import { FollowupType } from 'types/entities/collection-strategy';
import {
  BaseRecepientsType,
  RelativeContact,
} from 'types/entities/collection-strategy/contact-type';
import { cleanArrayPredicate } from 'util/predicates';
import { useEmailAttachments } from '../../../hooks/useEmailAttachments';
import { useEmailValues } from '../../../hooks/useEmailValues';
import { WorkflowForms } from '../../../type';
import AssigneeReadOnly from '../../ActionConfigurations/TitleComponents/AssigneeReadOnly';
import { ActionActivityTypes, WorkflowEmailFormProps } from '../../types';
import { ActionType } from '../ActionType';

export default function WorkflowEmailForm(props: WorkflowEmailFormProps) {
  const {
    data,
    onUploadFilesOpen,
    form,
    isInvoiceLevel,
    emailFormData = {},
    errors,
    handleEmailContentChange,
  } = props;
  const isNew = form.getFieldValue([])?.is_new_action;
  const Emaildata = isNew ? undefined : { ...emailFormData, ...data };
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <WorkflowEmailReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          <Flex gap={'var(--space-12)'} direction="column" className="tw-mb-[90px]">
            <EmailForm
              followupType={isInvoiceLevel ? FollowupType.INVOICE : FollowupType.STATEMENT}
              showErrors={errors?.showErrors}
              errors={errors}
              onChange={(value) => {
                handleEmailContentChange(value);
              }}
              onUploadFilesOpen={onUploadFilesOpen}
              value={isNew ? undefined : Emaildata}
              isInvoiceLevel={isInvoiceLevel}
              enableInvoiceDelivery
              uploadPositionSpacing={UploadDrawerPosition.WORKFLOW_UPLOAD_POSITION}
            />
            {/* <DeliverySettings delayedExecutionConfig={data?.delayed_execution_config} form={form} /> */}
          </Flex>
          <ActionType value={ActionActivityTypes.EMAIL} />
        </Form>
      )}
    </>
  );
}
export function WorkflowEmailReadOnly(props: WorkflowEmailFormProps) {
  const { data, emailFormData, isInvoiceLevel } = props;
  const emaildata = { ...emailFormData, ...data };
  const { editor: subjetEditor } = useRichTextEditor({
    editable: false,
    value: emaildata?.subject,
  });
  const { editor: BodyEditor } = useRichTextEditor({
    editable: false,
    value: emaildata?.body,
  });
  const { fromOptions } = useEmailValues();

  function getFromValue(value?: RelativeContact, isR?: boolean) {
    return match(value)
      .with({ type: BaseRecepientsType.POC }, (from) =>
        fromOptions.find((option) => option.value === from.value)
      )
      .with({ type: BaseRecepientsType.USER }, (from) =>
        fromOptions.find((option) => option.value === from.id)
      )
      .with({ type: BaseRecepientsType.AR_EMAIL }, (from) =>
        fromOptions.find((option) => option.value === from.id)
      )
      .with({ type: BaseRecepientsType.CONTACT_TYPE }, (from) =>
        fromOptions.find((option) => option.value === from.value)
      )
      .otherwise(() => undefined);
  }

  const from = getFromValue(emaildata?.from);

  const to = emaildata?.to
    ?.map(getTransformedSelection)
    ?.map((to) => {
      return getFromValue(to)?.label ?? '';
    })
    .filter(cleanArrayPredicate);
  const cc = emaildata?.cc
    ?.map(getTransformedSelection)
    ?.map((to) => {
      return getFromValue(to)?.label ?? '';
    })
    .filter(cleanArrayPredicate);
  const bcc = emaildata?.bcc
    ?.map(getTransformedSelection)
    ?.map((to) => {
      return getFromValue(to)?.label ?? '';
    })
    .filter(cleanArrayPredicate);
  const replyTo = getFromValue(emaildata?.reply_to)?.label;

  const { attachmentComponent } = useEmailAttachments({
    attachments: emaildata?.attachments,
    isInvoiceLevel,
  });

  return (
    <div className="tw-flex tw-flex-col tw-gap-24 tw-h-[100vh]">
      <InfoContent label="From" content={<AssigneeReadOnly assignees={[from?.label ?? '']} />} />
      <InfoContent label="To" content={<AssigneeReadOnly assignees={to ?? []} />} />
      {cc?.length ? (
        <InfoContent label="Cc" content={<AssigneeReadOnly assignees={cc ?? []} />} />
      ) : null}
      {bcc?.length ? (
        <InfoContent label="Bcc" content={<AssigneeReadOnly assignees={bcc ?? []} />} />
      ) : null}
      {replyTo ? (
        <InfoContent
          label="Reply To"
          content={<AssigneeReadOnly assignees={replyTo ? [replyTo] : []} />}
        />
      ) : null}
      <InfoContent label="Subject" content={<EditorCanvas editor={subjetEditor} />} />
      <InfoContent label="Body" content={<EditorCanvas editor={BodyEditor} />} />
      <InfoContent
        label="Attachments"
        content={
          <GrowFlex wrap space="8">
            {attachmentComponent}
          </GrowFlex>
        }
      />
    </div>
  );
}
