import { GenericRadioOptions } from 'components/BaseComponents/Radio/Antd/type';
import { DocumentConfigs, DocumentLevel, DocumentViews } from 'types/entities/documents';

export const documentViewOptions: GenericRadioOptions<DocumentViews> = [
  {
    label: 'All',
    value: 'ALL',
  },
  { label: 'Customer', value: 'CUSTOMER' },
  {
    label: 'Invoice',
    value: 'INVOICE',
  },
];

const documentConfig: DocumentConfigs = {
  INVOICE: {
    label: 'Invoice',
    style: {
      backgroundColor: 'var(--geekblue-1)',
      textColor: 'var(--geekblue-6)',
    },
  },
  CUSTOMER: {
    label: 'Customer',
    style: {
      backgroundColor: 'var(--cyan-1)',
      textColor: 'var(--cyan-6)',
    },
  },
};

export function getDocumentConfig(status: DocumentLevel) {
  return documentConfig[status];
}
