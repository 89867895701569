import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import CustomFieldTitle from 'components/Settings/WorkFlowAutomation/common/CustomFieldTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import useCustomerFieldData from '../../hooks/useCustomerFieldData';
import { ActionTitleProps, CustomerCustomFieldType } from '../../types';
import { actionIcons } from '../ActionIcons';

export default function CustomerCustomFieldTitle(props: ActionTitleProps<CustomerCustomFieldType>) {
  // const { fieldName, isLoading } = useCustomerFieldData({
  //   fieldId: item?.field_id,
  // });
  // const field = <CustomFieldIconWithText hasSymbol>{fieldName}</CustomFieldIconWithText>;
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.CUSTOMER_CUSTOM_FIELD.title}
      icon={actionIcons.CUSTOMER_CUSTOM_FIELD.icon}
      backgroundColor={actionIcons.CUSTOMER_CUSTOM_FIELD.style.backgroundColor}
      title={actionIcons.CUSTOMER_CUSTOM_FIELD.title}
      strong={props.strong}
    />
  );
}
