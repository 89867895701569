import { WorkFlowFilterOperator } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/EventCondition/type';
import { useSubsidiariesQuery } from 'queries/subsidaries';
import ConditionFilterFieldAndDisplay from './CommonConditionFilter/ConditionFilterFieldAndDisplay';
import { MultiSelectFilterField } from './CommonConditionFilter/MultiSelectFilterField';
interface SubsidaryFilterProps {
  value?: WorkFlowFilterOperator<number>;
  type: 'DISPLAY' | 'FILTER';
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
  selectedValue?: number[];
  conditionType: 'INVOICE_SUBSIDIARY' | 'CUSTOMER_SUBSIDIARY';
}
export default function SubsidaryFilter(props: SubsidaryFilterProps) {
  const { data: subsidiaryData, isLoading } = useSubsidiariesQuery();

  const matchedCustomValueOptions = subsidiaryData
    ?.filter((subsidiary) => (props.selectedValue as number[])?.includes(subsidiary.id))
    .map((subsidiary) => subsidiary.display_name);
  const FilterComponent = (
    <>
      {subsidiaryData && (
        <MultiSelectFilterField
          conditionType={props.conditionType}
          value={props.value}
          onChange={props.onChange}
          data={subsidiaryData}
        />
      )}
    </>
  );

  const DisplayComponent = (
    <>
      {matchedCustomValueOptions?.map((value, index) => (
        <>
          {value}
          {index === matchedCustomValueOptions?.length - 1 ? '' : ', '}
        </>
      ))}
    </>
  );
  return (
    <ConditionFilterFieldAndDisplay
      type={props.type}
      filterCompoenent={FilterComponent}
      displayComponent={DisplayComponent}
      loading={isLoading}
    />
  );
}
