import { CustomFieldIconWithText } from '@sinecycle/growcomponents';
import { GrowText } from 'components/BaseComponents/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'store/authentication/authentication';
import { CustomField, CustomFieldUIType } from 'types/entities/custom-field';
import {
  BetweenAbsoluteOperands,
  GreaterThanAbsoluteOperands,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { getCustomFormattedDate } from 'util/datetime-formatter';
import {
  MultiSelectFilterFieldsType,
  findCustomAndContactOptionMatches,
} from '../../../Common/CommonConditionFilter/MultiSelectFilterField';
import { BaseWorkFlowFilter } from '../../../EventCondition/type';
import { DisplayConditionListProps } from '../../TriggerTypeBasedDisplaySeparator';
import { DisplayOperatorValueProps, DisplayRangefield } from '../DisplayOperand';

export function DisplayCustomFieldFilterName(props: DisplayConditionListProps) {
  const customField = props.customFields?.find((field) => field.id === props.condition?.id);

  return (
    <CustomFieldIconWithText hasSymbol>
      <GrowText color={props.color} strong={props.strong}>
        {customField?.display_name}
      </GrowText>
    </CustomFieldIconWithText>
  );
}
export function DisplayMultipleValues(
  conditionType: MultiSelectFilterFieldsType,
  condition?: BaseWorkFlowFilter,
  customFields?: CustomField[],
  uiType?: CustomFieldUIType
) {
  if (condition?.operator.type !== 'IN') return;
  const multipleValues = condition?.operator?.operands as InAbsoluteOperands<string>;
  const values = multipleValues?.value_holder?.map((value) => value?.value);
  switch (uiType) {
    case CustomFieldUIType.TEXT_AREA:
    case CustomFieldUIType.TEXT_BOX:
    case CustomFieldUIType.NONE:
      return values;
    default:
      return findCustomAndContactOptionMatches(conditionType, customFields, values);
  }
}
export function DisplayCustomFieldFilter(props: DisplayOperatorValueProps) {
  const singleValue = props.condition?.operator?.operands as GreaterThanAbsoluteOperands<string>;
  const format = useSelector(dateFormatSelector);
  const customeFieldId = props.customFields?.find((filed) => filed.id === props.condition?.id);

  function displayRange() {
    const btwnOperandValues = props.condition?.operator?.operands as BetweenAbsoluteOperands<
      string | number
    >;
    const from = btwnOperandValues?.value_holder?.from?.value ?? 0;
    const to = btwnOperandValues?.value_holder?.to?.value ?? 0;
    return <DisplayRangefield from={from} to={to} color={props.color} strong={props.strong} />;
  }
  const matchedCustomValueOptions = DisplayMultipleValues(
    props.conditionType,
    props.condition,
    props.customFields,
    customeFieldId?.ui_type
  );
  const DisplayBtwnText = displayRange();
  const displayValue = getCustomFormattedDate(singleValue?.value_holder?.value, format);
  return (
    <>
      {customeFieldId?.ui_type === CustomFieldUIType.DROPDOWN &&
      matchedCustomValueOptions?.length ? (
        matchedCustomValueOptions.map((value, index) => (
          <React.Fragment key={index}>
            <GrowText color={props.color} strong={props.strong}>
              {value}
            </GrowText>
            {index < matchedCustomValueOptions.length - 1 && ', '}
            {index === matchedCustomValueOptions.length - 1 && ' '}
          </React.Fragment>
        ))
      ) : props.condition?.operator.type === 'BTWN' ? (
        <>{DisplayBtwnText} </>
      ) : props.condition?.operator.type === 'IN' ? (
        matchedCustomValueOptions?.map((value, index) => (
          <React.Fragment key={index}>
            {value}
            {index < matchedCustomValueOptions.length - 1 && ', '}
            {index === matchedCustomValueOptions.length - 1 && ' '}
          </React.Fragment>
        ))
      ) : (
        <>
          <GrowText color={props.color} strong={props.strong}>
            {customeFieldId?.ui_type === CustomFieldUIType.DATEPICKER
              ? displayValue
              : singleValue?.value_holder?.value}
          </GrowText>{' '}
        </>
      )}
    </>
  );
}
