import { Form } from 'antd';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import CollectionStatusSelect from 'components/Common/CollectionStatus/CollectionStatusSelect';
import { CollectionStatusBadge } from 'components/Common/CollectionStatus/style';
import { EditFieldsFormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import { collectionStatusSelector } from 'store/collection-status/collection-status';
import { WorkflowForms } from '../../../type';
import { t } from '../../text';
import { ActionActivityTypes, UpdateCollectionStatusProps } from '../../types';
import { ActionType } from '../ActionType';

export default function UpdateCollectionStatus(props: UpdateCollectionStatusProps) {
  const { data, form } = props;

  const collectionStatusesList = useSelector(collectionStatusSelector);
  const value = find(collectionStatusesList.collectionStatuses, {
    id: parseInt(data?.value || ''),
  });
  const { isWorkflowOwner } = useWorkflowOwner();
  return (
    <>
      {isWorkflowOwner ? (
        <UpdateCollectionStatusReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          <EditFieldsFormItem
            label={t.collection_status}
            name={'collection_status_value'}
            initialValue={value ? value.id : undefined}
            rules={[{ required: true, message: t.collection_status_required }]}
          >
            <CollectionStatusSelect placeholder={t.select_collection_status} />
          </EditFieldsFormItem>
          <ActionType value={ActionActivityTypes.EDIT_COLLECTION_STATUS} />
        </Form>
      )}
    </>
  );
}

export function UpdateCollectionStatusReadOnly(props: UpdateCollectionStatusProps) {
  const { data } = props;

  const collectionStatusesList = useSelector(collectionStatusSelector);
  const value = find(collectionStatusesList.collectionStatuses, {
    id: parseInt(data?.value || ''),
  });

  return (
    <div className="tw-flex tw-flex-col tw-gap-24">
      <InfoContent
        label={t.collection_status}
        content={
          <div className="tw-flex tw-items-center tw-gap-8">
            <CollectionStatusBadge color={value?.color_code} />
            {value?.label}
          </div>
        }
      />
    </div>
  );
}
