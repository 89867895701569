import { FormInstance } from 'antd';
import { FormRules } from 'components/BaseComponents/Form/Antd/type';
import { StyledFormItem } from 'components/Common/Styles/Styles';
import { CustomField } from 'types/entities/custom-field';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import { getDefaultFilterDataFromType } from '../../EventCondition/Helper';
import { BaseWorkFlowFilter } from '../../EventCondition/type';
import { WorkFlowFilterRenderer } from '../../Filters/WorkFlowFilterCallBack';
import { operatorValidator } from './util';
interface WorkflowFiltersComponentProps {
  name: number;
  selectedEvent: WorkFlowEventTypes | number;
  form: FormInstance<any>;
  customFields?: CustomField[];
}
export function WorkflowFiltersComponent(props: WorkflowFiltersComponentProps) {
  const operatorCustomRules: FormRules = [
    {
      validator: operatorValidator,
    },
  ];
  const value = getDefaultFilterDataFromType(
    props.selectedEvent as WorkFlowEventTypes,
    props.customFields
  ) as BaseWorkFlowFilter;
  return (
    <StyledFormItem
      name={[props.name, `operator`]}
      initialValue={value?.operator}
      rules={operatorCustomRules}
      validateStatus="success"
      help={<></>}
    >
      <WorkFlowFilterRenderer
        selectedEvent={props.selectedEvent as unknown as WorkFlowEventTypes}
        form={props.form}
        customFields={props.customFields}
      />
    </StyledFormItem>
  );
}
