import { CancelButton, GrowFlex, SaveButton } from '@sinecycle/growcomponents';
import { FormInstance, Popconfirm } from 'antd';
import { PopConfirmContent } from 'components/BaseComponents/ButtonWithConfirmation/PopconfirmContent';
import React from 'react';
import useOpen from 'util/hooks/useOpen';

interface WorkflowFooterProps {
  onClose: () => void;
  renderButton?: React.ReactElement | null;
  onSubmit: () => void;
  confirmationText: string;
  enablePopConfirm: boolean;
  form: FormInstance;
  extra?: React.ReactNode;
}

interface SubmitButtonProps {
  onSubmit: () => void;
}

const t = {
  confirmation_required: 'Confirmation Required',
  confirm: 'Confirm',
};

export default function WorkflowFooter(props: WorkflowFooterProps) {
  const { onClose, onSubmit, renderButton, confirmationText, enablePopConfirm, form, extra } =
    props;

  const {
    open: openPopup,
    toggleOpen: toggleOpenPopup,
    handleOpenChange: handleOpenPopup,
    onClose: close,
  } = useOpen({ open: false });

  const ConfirmationTitle = (
    <PopConfirmContent
      title={t.confirmation_required}
      desc={confirmationText}
      descStyles={{
        maxWidth: '200px',
      }}
    />
  );

  function validationFieldPopup() {
    form
      .validateFields()
      .then(() => {
        toggleOpenPopup();
      })
      .catch((err) => {
        handleOpenPopup(false);
      });
  }

  function SubmitButton(props: SubmitButtonProps) {
    const { onSubmit } = props;
    return <SaveButton onClick={onSubmit} type="primary" />;
  }

  const Cancel = <CancelButton onClick={onClose} style={{ padding: 0 }} />;

  const Save = (
    <>
      {enablePopConfirm ? (
        <Popconfirm
          placement="topRight"
          title={ConfirmationTitle}
          onConfirm={() => {
            onSubmit();
            close();
          }}
          okText={t.confirm}
          onCancel={close}
          open={openPopup}
          icon={false}
          onOpenChange={handleOpenPopup}
        >
          <SubmitButton onSubmit={validationFieldPopup} />
        </Popconfirm>
      ) : (
        <SubmitButton onSubmit={onSubmit} />
      )}
    </>
  );

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-pr-24 tw-pl-24">
      <GrowFlex align="center" gap={8}>
        {Save}
        {extra}
        {Cancel}
      </GrowFlex>
      <>{renderButton}</>
    </div>
  );
}
