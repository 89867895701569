export enum exchangeRateSource {
  GROWFIN = 'GROWFIN',
  ERP = 'INTEGRATION',
  STATIC = 'STATIC',
}

type sourceType =
  | 'ZOHO_BOOKS'
  | 'ZOHO_INVOICE'
  | 'STRIPE'
  | 'CHARGEBEE'
  | 'NETSUITE'
  | 'ZUORA'
  | 'INVOICED'
  | 'QUICKBOOKS'
  | 'SALESFORCE'
  | 'CSV_GOOGLE_SHEET'
  | 'CSV_S3'
  | 'PACIFIC_SAP_S4_HANA'
  | 'SAGE_INTACCT'
  | 'XERO';

export interface AllAccountSourceProps {
  id: number;
  enabled: boolean;
  client_id: string;
  client_secret: string;
  org_id: string;
  source_type: sourceType;
  realm_id: string | null;
  access_token: string | null;
  refresh_token: string | null;
  exchange_rate_config: ExchangeRate[];
  exchange_rate_source: exchangeRateSource;
}

export interface ExchangeRateData {
  source: string;
  destination: string;
  exchange_rate?: number;
  effectiveDate?: string | null;
  exchangeRateSource?: exchangeRateSource;
  accountSourceId?: number;
}

export interface ExchangeRate {
  exchange_rate_data: ExchangeRateData[];
  start_date: string;
  end_date: string;
  is_past_period?: boolean;
}
