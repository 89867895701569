import { Button } from 'antd';
import {
  EmailActionData,
  ValidEmailActionData,
} from 'types/entities/collection-strategy/rule-action';
import useOpen from 'util/hooks/useOpen';
import { EmailPreviewDrawer } from './EmailPreviewDrawer';

const t = {
  showPreview: 'Show Preview',
  hidePreview: 'Hide Preview',
};

interface ShowEmailPreviewProps {
  emailFormData: Partial<EmailActionData>;
}

function isValidEmail(
  emailFormData: ValidEmailActionData | Partial<EmailActionData>
): emailFormData is ValidEmailActionData {
  if (!emailFormData?.from) return false;
  if (!emailFormData.to?.length) return false;
  if (!emailFormData.subject?.length) return false;
  if (!emailFormData.body?.length) return false;

  return true;
}

export function ShowEmailPreview({ emailFormData }: ShowEmailPreviewProps) {
  const { open, onClose, handleOpenChange, toggleOpen } = useOpen({ open: false });

  return (
    <>
      <Button onClick={toggleOpen} disabled={!isValidEmail(emailFormData)}>
        {open ? t.hidePreview : t.showPreview}
      </Button>
      {isValidEmail(emailFormData) && (
        <EmailPreviewDrawer
          visible={open}
          onClose={onClose}
          emailData={emailFormData}
          handleOpenChange={handleOpenChange}
        />
      )}
    </>
  );
}
