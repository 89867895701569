import { CustomFieldIconWithText, GrowFlex } from '@sinecycle/growcomponents';
import { Form, Select, Skeleton, Spin } from 'antd';
import { useFormWatch } from 'components/BaseComponents/Form/Antd/Antd';
import { TextBlock } from 'components/CollectionActivities/Common/Details/BodyBlocks';
import { InfoContent } from 'components/CollectionActivities/Common/Details/Content';
import { CustomFieldSelect } from 'components/Settings/WorkFlowAutomation/common/ActionPredicate';
import { EditFieldsFormItem } from 'components/Settings/WorkFlowAutomation/common/style';
import { useWorkflowOwner } from 'components/Settings/WorkFlowAutomation/WorkflowCardList/Hooks';
import { WorkflowForms } from '../../../type';
import useCustomerFieldData from '../../hooks/useCustomerFieldData';
import { t } from '../../text';
import { ActionActivityTypes, CustomerCustomFieldProps } from '../../types';
import { customFieldSearch } from '../../utils';
import { ActionType } from '../ActionType';

export default function CustomerCustomField(props: CustomerCustomFieldProps) {
  const { form, data, baseForm } = props;

  const selectedFieldId = useFormWatch('field_id', form);
  const { customerOptions, selectedLabel, customFieldList, isLoading } = useCustomerFieldData({
    form: baseForm,
    fieldId: data?.field_id,
  });
  const { isWorkflowOwner } = useWorkflowOwner();

  const CustomerFieldSelect = (
    <EditFieldsFormItem
      label={t.customer_field_name}
      name={'field_id'}
      initialValue={customFieldList.get(data?.field_id ?? 0) ? selectedLabel : undefined}
      rules={[{ required: true, message: t.field_name_required }]}
    >
      <Select
        showSearch
        filterOption={(input, option) => customFieldSearch(input, option)}
        placeholder={t.select_customer_field}
        onChange={(val) => {
          const customFieldLabel = customFieldList.get(val);
          form.setFieldsValue({
            field_id: {
              label: customFieldLabel?.display_name,
              value: customFieldLabel?.id,
            },
            customer_custom_value: '',
          });
        }}
        options={customerOptions}
        loading={isLoading}
        disabled={isLoading}
        suffixIcon={isLoading ? <Spin size="small" /> : undefined}
        labelRender={(props) => {
          return <CustomFieldIconWithText hasSymbol>{props.label}</CustomFieldIconWithText>;
        }}
      />
    </EditFieldsFormItem>
  );

  const CustomerFieldValue = (
    <EditFieldsFormItem
      label={selectedFieldId?.label ? t.value : ''}
      name={'customer_custom_value'}
      initialValue={data?.value}
      rules={[{ required: true, message: t.value_required }]}
    >
      <CustomFieldSelect
        selectedOption={customFieldList.get(selectedFieldId?.value || data?.field_id)}
        form={form}
        formKey={'customer_custom_value'}
      />
    </EditFieldsFormItem>
  );

  return (
    <>
      {isWorkflowOwner ? (
        <CustomerCustomFieldReadOnly {...props} />
      ) : (
        <Form
          name={WorkflowForms.ACTION_FORM}
          style={{ height: '100%' }}
          requiredMark={false}
          form={form}
          layout="vertical"
          preserve={false}
        >
          {isLoading ? (
            <GrowFlex space="24" vertical>
              <Skeleton.Input />
              <Skeleton.Input />
            </GrowFlex>
          ) : (
            <>
              {CustomerFieldSelect}
              {CustomerFieldValue}
            </>
          )}

          <ActionType value={ActionActivityTypes.CUSTOMER_CUSTOM_FIELD} />
        </Form>
      )}
    </>
  );
}

export function CustomerCustomFieldReadOnly(props: CustomerCustomFieldProps) {
  const { data } = props;
  const { fieldName, isLoading } = useCustomerFieldData({
    fieldId: data?.field_id,
  });
  const field = <CustomFieldIconWithText hasSymbol>{fieldName}</CustomFieldIconWithText>;

  return (
    <Skeleton active loading={isLoading}>
      <InfoContent label={t.customer_field_name} content={field} />
      <InfoContent label={t.value} content={<TextBlock value={data?.value} />} />
    </Skeleton>
  );
}
