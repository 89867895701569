import { InvoiceListTabKeys } from 'components/InvoiceListV2';
import { CUSTOMER_DETAILS_EVENT } from 'events/customer-details';
import { INVOICE_LIST_EVENT } from 'events/invoice-List';
import { dispatchAppEvent } from 'lib/pub-sub';

export const handleDispatchEventViewPdfOpen = (fromInvoiceList?: boolean) => {
  const eventToDispatch = fromInvoiceList
    ? INVOICE_LIST_EVENT.INVOICE_LIST_VIEW_PDF
    : CUSTOMER_DETAILS_EVENT.VIEW_PDF;

  dispatchAppEvent({
    type: eventToDispatch,
    payload: {
      tab: InvoiceListTabKeys.OPEN_INVOICE,
    },
  });
};
