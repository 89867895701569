import { InfiniteData, MutationFunction } from '@tanstack/react-query';
import { DisputeStatus } from 'components/CollectionActivites/Disputes/types';
import {
  ActivityButtons,
  CustomActivityProps,
  FormItemNames,
  MutationFunctionProps,
} from 'components/Common/ActivitiesForm/types';
import { ActivityKeysPayload } from 'components/Common/ActivitiesModal/type';
import {
  CallLogResponseSchema,
  DisputeResponseSchema,
  DocumentResponseSchema,
  EscalationResponseSchema,
  NoteResponseSchema,
  TaskRemainderSchema,
  TaskResponseSchema,
} from 'components/Common/hooks/type';
import { ActivityKey } from 'queries/activites';
import { IInvoices } from 'store/customer/types';
import { ActivityType } from 'types/activities/activity-types';
import { EscalationType } from 'types/activities/escalation';
import { PromiseToPayStatus } from 'types/activities/promise-to-pay';
import { TaskStatus } from 'types/activities/task';
import { PerformedActionType, SubscribedEntityType } from 'types/api/inbox/activity';
import { Attachment } from 'types/common/attachments';
import { PageList } from 'types/common/page-list';
import { BaseCustomer } from 'types/entities/customer';
import { InvoiceBasicDetails } from 'types/entities/invoice';
import { User } from 'types/entities/user';
import { DeepExtractPrefixArray } from 'types/utils/utils';
import { ActivityFeedCollection, ELastContactActivity } from '../../../store/activity-feed/type';
import { InvoiceDropdownInterfaceProps } from '../../../store/invoice/type';
import { ActionableEntity } from './Email';

export interface IKeyActivitesContainer {
  invoiceId?: number;
  customerId?: number;
  setQuickAction: (key: ELastContactActivity | undefined) => void;
  selectAll?: boolean;
  invoiceDetail?: InvoiceBasicDetails;
  wrap?: boolean;
  extra?: React.ReactNode;
  viewChild?: boolean;
}

export interface IBaseExpandedActivity {
  setQuickAction?: (
    type: ELastContactActivity,
    invoices: { id: number; invoice_no: string; invoice_date?: string }[] | number[]
  ) => void;
}

export interface IActivity {
  customerId?: number;
  customerCurrency?: string;
  createMode?: boolean;
  invoiceList?: InvoiceDropdownInterfaceProps[];
  entity?: ActionableEntity;
  customers?: BaseCustomer[];
  selectAll?: boolean;
  totalRecords?: number;
  attachments?: Attachment[];
}

export enum EActivityQueryParams {
  ACTIVITY_TYPE = 'activity_type',
  ACTIVITY_ID = 'activity_id',
  CURRENT_ACTIVITY_TAB = 'current_activity_tab',
  CURRENT_SECTION = 'current_section',
  CURRENT_VIEW = 'current_view',
  THREAD_ID = 'thread_id',
  PAGE = 'page',
  CUSTOMER = 'customer',
  CUSTOMER_NO = 'customer_no',
  CUSTOMER_ID = 'customer_id',
  VIEW = 'view',
  OPEN = 'open',
  ASSOCIATED_ACTIVITY = 'associated_activity',
  EDIT = 'edit',
  QUERY_KEY = 'query_key',
  ACTIVITY_PAGE = 'activity_page',
  TOTAL_RECORDS = 'total_records',
  TOTAL_PAGES = 'total_pages',
  PAGE_SIZE = 'page_size',
  ASSOCIATED_ACTIVITY_ID = 'associated_activity_id',
  ASSOCIATED_ACTIVITY_TYPE = 'associated_activity_type',
  EMAIL_ID = 'email_id',
  COMMENT_ID = 'comment_id',
  FROM_TYPE = 'form_type',
  READ_ONLY = 'read_only',
}

type ActivitySchema = DeepExtractPrefixArray<ActivityFeedCollection>;

export type ActivitySchemaMapping =
  | Record<string, ActivitySchema | Record<string, ActivitySchema>>
  | undefined;

export interface ActivitiesListCardProps {
  activity?: SubscribedEntityType;
  activitiesMode?: boolean;
  onStatusUpdate?: (data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus) => void;
  mode: ActivitiesMode;
}

export type customerDetailsProps = {
  id: number;
  name: string;
  customerNumber?: string;
};

export interface ActivityGroupProps {
  title: string;
  list: (SubscribedEntityType | undefined)[];
}

export enum ActivitiesMode {
  DASHBOARD = 'dashboard',
  ACTIVITIES = 'activitiesMode',
}

export interface ActivityCardContainerProps {
  activity?: SubscribedEntityType;
  customerDetails?: customerDetailsProps;
  currentActivityView: string;
  activitiesGroup?: ActivityGroupProps[];
  currentFeed?: InfiniteData<PageList<ActivityFeedCollection, unknown>>;
  activitiesMode?: boolean;
  onStatusUpdate?: (data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus) => void;
  mode: ActivitiesMode;
  activityPage?: number;
  totalPages?: number;
  totalRecords?: number;
  queryKey?: string;
}

export interface CreateActivityCardProps {
  currentActivityView: string;
  customerDetails?: customerDetailsProps;
  currentFeed?: InfiniteData<PageList<ActivityFeedCollection, unknown>>;
  onStatusUpdate?: (
    data: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus,
    id?: string,
    type?: ActivityType
  ) => void;
  handleLoadMore: () => void;
  queryKey?: string;
}

export interface ActivitiesPayload {
  activityKeys: ActivityKeysPayload;
  activityData?: ActivityGroupProps[];
}

export interface LatestActivityData {
  type: PerformedActionType;
  actor: User;
  createdDate: string;
  showAvatar: boolean;
}

export interface StatusUpdateQueryProps {
  activity: ActivityType;
  id: number | string;
  value: {
    status: EscalationType | DisputeStatus | TaskStatus | PromiseToPayStatus;
  };
}

export type ActivityMutationProps =
  | DisputeResponseSchema
  | CallLogResponseSchema
  | NoteResponseSchema
  | EscalationResponseSchema
  | TaskResponseSchema
  | DocumentResponseSchema
  | TaskRemainderSchema;

export interface ActivityButtonsDisplayKeys {
  key: ActivityKey | 'WRITE_OFF';
  type: 'button' | 'list';
  hide?: boolean;
  buttonConfig?: ActivityButtons;
}
export interface ActivityConfig {
  icon: JSX.Element;
  label: string;
}

export interface ActivityButtonsProps {
  lineItems?: number[];
  invoice?: InvoiceBasicDetails[];
  customerId?: number | number[];
  customerCurrency?: string;
  entityLevelData?: ActionableEntity;
  setQuickAction?: (key: ELastContactActivity | undefined) => void;
  displayKeys: ActivityButtonsDisplayKeys[];
  extra?: React.ReactNode;
  customTransformer?: (
    data: Record<FormItemNames, any>,
    customProps?: CustomActivityProps
  ) => Record<string, any>;
  onMutateFn: MutationFunction<ActivityMutationProps, MutationFunctionProps<ActivityMutationProps>>;
  bulkAction?: boolean;
  disableAction?: boolean;
  bulkRecordsLength?: number;
  customerNames?: string[];
  viewChild?: boolean;
}

export interface useFormCustomTransformerProps {
  data: Record<FormItemNames, any>;
  customProps?: CustomActivityProps;
  noteRefid?: number | number[];
  customerId?: number | number[];
  invoices?: InvoiceBasicDetails[] | number[] | IInvoices[];
  selectAll?: boolean;
}

export type MutationFunctionResponseType<ResponseType> = ResponseType extends ActivityMutationProps
  ? MutationFunction<ResponseType, MutationFunctionProps<ResponseType>>
  : never;
