import { EVENT_TRIGGER_CONDITION_KEYS } from '../../WorkflowCardList/type';
import { ConditionListInvoiceStatusLabelsInvoice } from '../../common/Predicate';
import { CustomerConditionFilterLabels } from '../../common/types';

//need to change Record<EVENT_TRIGGER_CONDITION_KEYS, Record<any, string>>
export const WorkFlowEventLabelConfig: Record<
  EVENT_TRIGGER_CONDITION_KEYS,
  Record<string, string>
> = {
  INVOICE: ConditionListInvoiceStatusLabelsInvoice,
  // PAYMENT: PaymentConditionFilterLabels,
  // CREDIT_MEMO: CreditMemoConditionFilterLabels,
  // DISPUTE: DisputeConditionFilterLabels,
  // TASK: TaskConditionFilterLabels,
  // PTP: PTPConditionFilterLabels,
  // ESCALATION: EscalationConditionFilterLabels,
  CUSTOMER: CustomerConditionFilterLabels,
};
