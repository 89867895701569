import { ReactNode } from 'react';
import { CustomFiledRangeFilterAdditionalProps } from './Filters/CustomFiledRange';
import { DateRangeFilterAdditionalProps } from './Filters/DateRange';
import { DayRangeFilterAdditionalProps } from './Filters/DayRange';
import { MultiCheckBoxPagination } from './Filters/MultiSelectCheckbox';
import { RangeFilterAdditionalProps } from './Filters/Range';
import { SwitcherAdditionalProps } from './Filters/Switcher';
import { TreeMultiSelectAdditionalProps } from './Filters/TreeMultiSelect/type';

///////////////////////// TYPES /////////////////////////////
export enum FilterType {
  MULTI_SELECT_CHECKBOX = 'MULTI_SELECT_CHECKBOX',
  SINGLE_SELECT_CHECKBOX = 'SINGLE_SELECT_CHECKBOX',
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  RADIO = 'RADIO',
  DATE_RANGE = 'DATE_RANGE',
  DAY_RANGE = 'DAY_RANGE',
  DATE = 'DATE',
  RANGE = 'RANGE',
  TOGGLE = 'TOGGLE',
  MIN_MAX = 'MIN_MAX',
  SWITCHER = 'SWITCHER',
  CUSTOM_FIELD_RANGE = 'CUSTOM_FIELD_RANGE',
  TREE_MULTI_SELECT = 'TREE_MULTI_SELECT',
  TEXT = 'TEXT',
}

export interface SelectOption {
  key?: string;
  label: React.ReactNode;
  value: unknown;
  valueObj?: unknown;
  selected?: boolean;
}
export interface OptionsProps {
  label: React.ReactNode;
  value: any;
}

export type GenericSelectOption<T, R extends PropertyKey> = Omit<
  SelectOption,
  'value' | 'valueObj'
> & {
  value: T;
  valueObj?: {
    [key in R]: GenericSelectOption<any, R>[];
  };
  key?: string;
};
export type FilterConfig = {
  label?: string;
  name: string;
  options?: SelectOption[];
  emptyText?: string;
  customTitle?: React.ReactNode;
  hideFooterActions?: boolean;
  allowSelectAndUnselectAll?: boolean;
  contentOnly?: boolean;
  disableAutoAdjust?: boolean;
  valueToString?: (value: any, defaultValueToString: () => ReactNode) => ReactNode;
} & (
  | {
      type: FilterType.MULTI_SELECT_CHECKBOX;
      options: SelectOption[];
      additionalProps?: {};
      pagination?: MultiCheckBoxPagination;
    }
  | {
      type: FilterType.SINGLE_SELECT_CHECKBOX;
      options: SelectOption[];
      additionalProps?: {};
    }
  | {
      type: FilterType.MULTI_SELECT;
      options: SelectOption[];
      additionalProps?: {};
    }
  | {
      type: FilterType.SINGLE_SELECT;
      options: SelectOption[];
      additionalProps?: {};
    }
  | {
      type: FilterType.RADIO;
      options: SelectOption[];
      additionalProps?: {};
    }
  | {
      type: FilterType.DATE_RANGE;
      additionalProps?: DateRangeFilterAdditionalProps;
    }
  | {
      type: FilterType.DAY_RANGE;
      additionalProps?: DayRangeFilterAdditionalProps;
    }
  | {
      type: FilterType.DATE;
      additionalProps?: {};
    }
  | {
      type: FilterType.RANGE;
      additionalProps?: RangeFilterAdditionalProps;
    }
  | {
      type: FilterType.TOGGLE;
      additionalProps?: {};
    }
  | {
      type: FilterType.MIN_MAX;
      additionalProps?: {};
    }
  | {
      type: FilterType.SWITCHER;
      options: SelectOption[];
      additionalProps?: SwitcherAdditionalProps;
    }
  | {
      type: FilterType.CUSTOM_FIELD_RANGE;
      additionalProps?: CustomFiledRangeFilterAdditionalProps;
    }
  | {
      type: FilterType.TREE_MULTI_SELECT;
      options: GenericSelectOption<any, 'children'>[];
      additionalProps?: TreeMultiSelectAdditionalProps;
    }
  | {
      type: FilterType.TEXT;
      additionalProps?: {};
    }
);

///////////////////////// FILTER LIST CONFIG /////////////////////////////
export interface Filter<T = any> {
  id: string;
  label: string;
  active: boolean;
  removable: boolean;
  order?: number;
  filterConfig?: FilterConfig;
  filterObject?: T;
  filterValueKeys?: string[];
}
export interface FilterGroup<T = any> {
  id: string;
  label?: string;
  filterList: Filter<T>[];
}

export enum StandardFilterGroup {
  CUSTOMER = 'CUSTOMER',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  DISPUTE = 'DISPUTE',
}

export enum CustomFitlerGroup {
  REPORTING_TAGS = 'reporting_tags',
  CUSTOMER_CUSTOM_FIELDS = 'customer_custom_fields',
  INVOICE_CUSTOM_FIELDS = 'invoice_custom_fields',
}

export const ValidFilterTypes = [
  FilterType.MULTI_SELECT_CHECKBOX,
  FilterType.SINGLE_SELECT,
  FilterType.SINGLE_SELECT_CHECKBOX,
  FilterType.MULTI_SELECT,
];
