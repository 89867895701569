import {
  BasicWorkFlowOperatorTypes,
  WorkFlowCreditContactOperatorTypes,
  WorkFlowOperator,
} from './CustomAutomations/type';

export const WorkFlowInvoiceEventFilter = [
  'INVOICE_CREATE',
  'INVOICE_VALUE',
  'DUE_DAYS',
  'INVOICE_STATUS',
  'ISSUE_DATE',
  'INVOICE_DUE_DATE',
  'INVOICE_BALANCE',
  'INVOICE_PDF',
  'DELIVERY_FLAG',
  'INVOICE_SUBSIDIARY',
  'INVOICE_UPDATE',
  'INVOICE_FOLLOWUP_STATUS',
  'OVERDUE_DAYS',
  'INVOICE_PAYMENT_STATUS',
  'INVOICE_BALANCE_DUE_TX',
  'INVOICE_DUE',
  'INVOICE_OVERDUE',
  'INVOICE_TAX',
  'INVOICE_IS_DUE',
  'INVOICE_IS_OVERDUE',
] as const;

export const WorkflowInvoiceEvents = ['INVOICE_CREATED', 'INVOICE_UPDATE'] as const;
export type WorkFlowInvoiceEventFilterType = (typeof WorkFlowInvoiceEventFilter)[number];
export type WorkFlowInvoiceEventTypes = (typeof WorkflowInvoiceEvents)[number];

export const InvoiceStatusLabels: Record<WorkFlowInvoiceEventFilterType, string> = {
  INVOICE_STATUS: 'Invoice Status Changed',
  ISSUE_DATE: 'Invoice Issue Date Changed',
  INVOICE_DUE_DATE: 'Invoice Due Date Changed',
  DUE_DAYS: 'Invoice Age Changed',
  INVOICE_VALUE: 'Invoice Amount Changed',
  INVOICE_BALANCE: 'Invoice Balance Changed',
  INVOICE_CREATE: 'Invoice Created',
  INVOICE_PDF: 'Invoice PDF available',
  DELIVERY_FLAG: 'Invoice Email Delivered',
  INVOICE_SUBSIDIARY: 'Subsidiary',
  INVOICE_UPDATE: 'Invoice Updated',
  INVOICE_FOLLOWUP_STATUS: 'Invoice Follow-up Status Changed',
  OVERDUE_DAYS: 'Overdue Days Changed',
  INVOICE_PAYMENT_STATUS: 'Invoice Payment Status Changed',
  INVOICE_BALANCE_DUE_TX: 'Invoice Balance Due Transaction',
  INVOICE_DUE: 'Invoice Due',
  INVOICE_OVERDUE: 'Invoice Overdue',
  INVOICE_TAX: 'Invoice Tax',
  INVOICE_IS_DUE: 'Invoice Is Due',
  INVOICE_IS_OVERDUE: 'Invoice Is Overdue',
} as const;

export const WorkFlowOperatorLabel: Record<BasicWorkFlowOperatorTypes, string> = {
  BTWN: 'Between',
  GOE: 'Greater than or equals',
  LOE: 'Less than or equals',
  IN: 'Equals',
  EQ: 'Equal To',
  ANY: 'Any Change',
  CHG: 'From',
};
export const WorkFlowCreditContactOperatorLabel: Record<
  WorkFlowCreditContactOperatorTypes,
  string
> = {
  IN: 'Equals',
  ANY: 'Any Change',
};

export const WorkFlowFolloupOperatorLabel: Record<WorkFlowOperator, string> = {
  AND: 'ALL',
  OR: 'ANY',
};

export const WorkFlowStatusOperatorLabel: Record<BasicWorkFlowOperatorTypes, string> = {
  BTWN: 'From',
  IN: 'Equals',
  GOE: 'Greater than or equals',
  LOE: 'Lesser than or equals',
  EQ: 'Equal to',
  ANY: 'Any change',
  CHG: 'From',
} as const;
