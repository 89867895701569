import { amountFormats } from '@sinecycle/growcomponents';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { GrowText } from 'components/BaseComponents/Typography';
import { isNumber } from 'lodash';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'store/authentication/authentication';

interface DisplayCurrentDateProps {
  value: ReactNode;
  color: string;
  strong?: boolean;
  text?: string;
}
export default function DisplayGeneralData(props: DisplayCurrentDateProps) {
  const locale = useSelector(localeSelector);

  return (
    <Flex gap="var(--space-4)">
      <GrowText
        color={props.color}
        strong={props.strong}
        ellipsis={{ tooltip: props.value }}
        style={{ maxWidth: '300px' }}
      >
        {isNumber(props.value)
          ? amountFormats.number(Number(props.value), { locale })
          : props.value}
      </GrowText>
      <GrowText color={props.color} strong={props.strong}>
        {props.text}
      </GrowText>
    </Flex>
  );
}
