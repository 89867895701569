import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notify } from 'components/BaseComponents/Notifications';
import { getCustomerInternalContacts } from 'services/customers';
import {
  createCustomerInternalContacts,
  createInvoiceInternalContacts,
  deleteCustomerUserInternalContact,
  deleteInvoiceUserInternalContact,
  getCustomerUserCategory,
  getInvoiceInternalContacts,
  getInvoicelUserCategory,
  updateCustomerInternalContact,
  updateInvoiceInternalContact,
} from 'services/internal-contacts';
import { getCollectionOwner } from 'services/users';
import {
  UpdateInternalCustomerContact,
  UpdateInternalInvoiceContact,
} from 'types/api/internal-contact';

const t = {
  createFailure: 'Internal Contact Failed To Add',
  deleteFailure: 'Internal Contact Failed To Delete',
};

const HALF_DAY = 12 * 60 * 60 * 1000;

export function useGetAllUserCategory(id: number) {
  return useQuery({
    queryKey: ['user-category', id],
    retry: false,
    queryFn: () => getCustomerUserCategory(id),
    enabled: id >= 0,
  });
}

export function useGetCustomerInternalContactItems(id: number) {
  return useQuery({
    queryKey: ['internal-customer-contact'],
    retry: false,
    queryFn: () => getCustomerInternalContacts(id),
  });
}

export function useCreateCustomerInternalContact(id: number) {
  return useMutation({
    mutationFn: (data: UpdateInternalCustomerContact) =>
      createCustomerInternalContacts({ id, data }),
    retry: false,

    onSuccess(data) {},
    onError(data) {
      notify.error(t.createFailure);
    },
  });
}

export function useUpdateCustomerContact(id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateInternalCustomerContact) => updateCustomerInternalContact(id, data),
    retry: false,

    onSuccess(data) {
      queryClient.invalidateQueries(['internal-customer-contact']);
    },
    onError(data) {},
  });
}

export function useDeleteCustomerContact(customerId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: number) => deleteCustomerUserInternalContact(customerId, userId),
    retry: false,

    onSuccess(data) {
      queryClient.invalidateQueries(['internal-customer-contact']);
    },
    onError(data) {},
  });
}

export function useGetInvoiceLevelUserCategory(customerId: number, selectedUserId: number) {
  return useQuery({
    queryKey: ['internal-user-category', selectedUserId],
    retry: false,
    enabled: selectedUserId >= 0,
    queryFn: () => getInvoicelUserCategory(customerId, selectedUserId),
    cacheTime: 0,
    staleTime: 0,
  });
}

export function useGetInvoiceInternalContactItems(id: number) {
  return useQuery({
    queryKey: ['internal-invoice-contact'],
    retry: false,
    queryFn: () => getInvoiceInternalContacts(id),
  });
}

export function useCreateInvoiceInternalContact(id: number) {
  return useMutation({
    mutationFn: (data: UpdateInternalInvoiceContact) => createInvoiceInternalContacts({ id, data }),
    retry: false,

    onSuccess() {},
    onError() {},
  });
}

export function useUpdateInvoiceContact(id: number) {
  return useMutation({
    mutationFn: (data: UpdateInternalInvoiceContact) => updateInvoiceInternalContact(id, data),
    retry: false,

    onSuccess() {},
    onError() {},
  });
}

export function useDeleteInvoiceUserCategory(entityId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteInvoiceUserInternalContact(entityId, id),
    retry: false,

    onSuccess(data) {
      queryClient.invalidateQueries(['internal-invoice-contact']);
    },
    onError(data) {
      notify.error(t.deleteFailure);
    },
  });
}

export function useGetCollectionOwner() {
  return useQuery({
    queryKey: ['collection-owner-users'],
    retry: false,
    queryFn: () => getCollectionOwner(),
    cacheTime: HALF_DAY,
    staleTime: HALF_DAY,
  });
}
