import { CashAppProvider } from '@growfin/asgard';
import { ThemeProvider } from '@sinecycle/growcomponents';
import useTheme from 'hooks/use-theme';

interface GrowThemeProviderProps {
  children: React.ReactNode;
}

function GrowThemeProvider(props: GrowThemeProviderProps) {
  const { config } = useTheme();
  return <ThemeProvider themeConfig={config}>{props.children}</ThemeProvider>;
}

function CashAppsPropviderWarpper(props: GrowThemeProviderProps) {
  const { config } = useTheme();
  /* @ts-ignore */
  return <CashAppProvider themeConfig={config}>{props.children}</CashAppProvider>;
}

export { CashAppsPropviderWarpper, GrowThemeProvider };
