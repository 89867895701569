import {
  useInternalContactForEntity,
  useRelativeContactTypes,
} from 'components/Common/Contact/ContactType';
import { useAREmailData } from 'components/Settings/Company/AREmail/useAREmailData';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { usersSelector } from 'store/user/user';

function useEmailValues() {
  const users = useSelector(usersSelector) ?? [];
  const contactType = useRelativeContactTypes();
  const customerContactType = contactType.customer ?? [];
  const invoiceContactType = contactType.invoice ?? [];
  const { internalContact: internalContactsCustomer } = useInternalContactForEntity('CUSTOMER');
  const { filteredList } = useAREmailData();
  const userOptions = users?.map((user) => ({
    label: `${user.first_name} ${user.last_name} (${user.email})`,
    value: user.id,
  }));

  const arEmailList = map(filteredList, (list) => {
    return {
      label: list.email,
      value: list.id,
    };
  });
  const internal = map(internalContactsCustomer, (list) => {
    return {
      label: list.label,
      value: list.user_type,
    };
  });
  const invoiceContactTypelist = map(invoiceContactType, (list) => {
    return {
      label: list.email,
      value: list.email,
    };
  });

  const customerContactTypelist = map(customerContactType, (list) => {
    return {
      label: list.email,
      value: list.email,
    };
  });

  function getFromOptions() {
    return [
      ...userOptions,
      ...arEmailList,
      ...internal,
      ...invoiceContactTypelist,
      ...customerContactTypelist,
    ];
  }

  const fromOptions = getFromOptions();
  return { fromOptions };
}

export { useEmailValues };
