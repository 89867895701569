import { Radio, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export interface SwitcherItem {
  id: string;
  label: React.ReactNode;
  disabled?: boolean;
  visible?: boolean;
  tooltip?: React.ReactNode;
}
interface SwitcherProps {
  items: SwitcherItem[];
  defaultValue: string;
  onChange: (x: SwitcherItem) => void;
  hideIfSingleItem?: boolean;
  value?: string;
}
export function Switcher(props: SwitcherProps) {
  const { items, hideIfSingleItem, onChange } = props;
  const [selectedValue, setSelectedValue] = useState(props.value ?? props.defaultValue);

  useEffect(() => {
    props.value && setSelectedValue(props.value);
  }, [props.value]);

  function handleCurrentSelected(value: string) {
    const item = items.find((item) => item.id === value);
    item && onChange(item);
  }

  // if only one item, nothing to switch, hence should not show the switcher at all if required so.
  if (hideIfSingleItem && props.items.length === 1) return null;

  return (
    <StyledRadioGroup
      defaultValue={props.defaultValue}
      onChange={(e) => {
        setSelectedValue(e.target.value);
        handleCurrentSelected(e.target.value);
      }}
      value={selectedValue}
    >
      {props.items
        .filter(({ visible = true }) => visible) // if value not provided, considered as true
        .map((item) => (
          <Radio.Button key={item.id} value={item.id} disabled={item.disabled}>
            <Tooltip title={item.tooltip}>{item.label}</Tooltip>
          </Radio.Button>
        ))}
    </StyledRadioGroup>
  );
}
