import { useComputeInternalContactRecepient } from 'components/Common/ActivitiesForm/Utils';
import { ActionableEntity } from 'components/HigherOrderComponent/KeyActivitesContainer/Email';
import ActionTileTitle from 'components/Settings/WorkFlowAutomation/common/ActionTileTitle';
import { EventActionReadOnlyMap } from 'components/Settings/WorkFlowAutomation/Executions/type';
import { join } from 'lodash';
import { ActionActivityTypes, ActionTitleProps, EscalationActionType } from '../../types';
import { computeActionableEntity, extractAssignToLabel } from '../../utils';
import { actionIcons } from '../ActionIcons';

export default function EscalationTitle(props: ActionTitleProps<EscalationActionType>) {
  return (
    <ActionTileTitle
      tooltipTitle={actionIcons.ESCALATION.title}
      icon={actionIcons.ESCALATION.icon}
      backgroundColor={actionIcons.ESCALATION.style.backgroundColor}
      title={actionIcons.ESCALATION.title}
      strong={props.strong}
    />
  );
}
