import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectProps } from 'antd';
import { Flex } from 'components/BaseComponents/Layout/Flex';
import { UserSelect } from 'components/Common/SelectComponents/UsersSelect';
import produce from 'immer';
import { head } from 'lodash';
import {
  DefaultAbsoluteOperand,
  InAbsoluteOperands,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowEventTypes } from 'types/entities/workflow';
import SearchableSelect from '../../../../../../Common/SearchableSelect/SearchableSelect';
import { WorkFlowFilterOperator } from '../../EventCondition/type';
import { WorkFlowSingleStatusConfig } from '../predicate';
import DisputeTypesFilter from './DisputeTypesFilter';
interface SingleStatusFilterProps {
  type?: string;
  selectedFilter?: WorkFlowEventTypes;
  value?: WorkFlowFilterOperator<unknown>;
  onChange?: (updatedFilter: WorkFlowFilterOperator<unknown>) => void;
  options?: SelectProps['options'];
  singleFieldMoreInfo?: string;
  prefix?: string;
  precision?: number;
  singleFieldInputWidth?: string;
  showRightArrow?: boolean;
}
const singleStatusFilter = [
  'PTP_STATUS',
  'CREDIT_MEMO_STATUS',
  'PAYMENT_STATUS',
  'DISPUTE_STATUS',
  'TASK_STATUS',
  'ESCALATION_STATUS',
  'INVOICE_FOLLOWUP_STATUS',
  'INVOICE_PAYMENT_STATUS',
] as const;
export type singleStatusFilterType = (typeof singleStatusFilter)[number];

const rightArrow = (
  <FontAwesomeIcon
    icon={['fal', 'arrow-right']}
    style={{ color: 'var(--gray-9)', fontSize: 'var(--fs-14)' }}
  />
);

export default function SingleStatusFilter(props: SingleStatusFilterProps) {
  const creditPeriodValue = props.value?.operands as InAbsoluteOperands<number>;
  const singleValue = head(creditPeriodValue?.value_holder)?.value;

  const statusFilterValue = props.value?.operands as InAbsoluteOperands<number | string>;
  const usersoptions = (
    <UserSelect
      size="middle"
      value={head(statusFilterValue?.value_holder)?.value ?? undefined}
      onChange={handleFieldOnchange}
      optionType="WITHOUT_AVATAR"
    />
  );

  function handleFieldOnchange(value: string | number) {
    const updatedFilter =
      props.value &&
      produce(props.value, (draftFilter) => {
        if (draftFilter) {
          const statusOperand = draftFilter.operands as
            | InAbsoluteOperands<string | number>
            | DefaultAbsoluteOperand<string | number>;
          statusOperand.value_holder = draftFilter.type === 'IN' ? [{ value }] : { value };
        }
      });

    updatedFilter && props?.onChange?.(updatedFilter);
  }

  function filterFieldComponentGenerator() {
    if (!props.selectedFilter) return;
    switch (props.selectedFilter) {
      case 'DISPUTE_ASSIGNEE':
      case 'TASK_ASSIGNEE':
      case 'ESCALATION_ASSIGNEE':
        return <>{usersoptions}</>;
      case 'DISPUTE_TYPE':
        return <DisputeTypesFilter value={props.value} onChange={props.onChange} />;
      default:
        const filters = props.selectedFilter as singleStatusFilterType;
        const isSingleStatusFilter = singleStatusFilter.includes(filters);
        if (isSingleStatusFilter) {
          const { type, options } = WorkFlowSingleStatusConfig[filters];
          return (
            <>
              {type && options && (
                <SearchableSelect
                  value={singleValue ?? type}
                  onChange={handleFieldOnchange}
                  options={options}
                  style={{ width: '200px' }}
                />
              )}
            </>
          );
        }
    }
  }
  const FilterToRender = filterFieldComponentGenerator();

  return (
    <Flex gap="var(--space-8)" align="center">
      {props.showRightArrow && rightArrow}
      {FilterToRender}
    </Flex>
  );
}
