import { AddApproval } from '../ActionRenderComponents/createActivity/AddApproval';
import AddCallLog from '../ActionRenderComponents/createActivity/AddCallLog';
import AddNote from '../ActionRenderComponents/createActivity/AddNote';
import AddPtpAction from '../ActionRenderComponents/createActivity/AddPtpAction';
import AddTask from '../ActionRenderComponents/createActivity/AddTask';
import RaiseDisputeAction from '../ActionRenderComponents/createActivity/RaiseDisputeAction';
import RaiseEscalation from '../ActionRenderComponents/createActivity/RaiseEscalation';
import WorkflowEmailForm from '../ActionRenderComponents/createActivity/WorkflowEmail';
import InvoiceCustomField from '../ActionRenderComponents/others/InvoiceCustomField';
import CustomerCustomField from '../ActionRenderComponents/others/customerCustomField';
import FlagInvoices from '../ActionRenderComponents/others/flagInvoices';
import UpdateCollectionOwner from '../ActionRenderComponents/others/updateCollectionOwner';
import UpdateCollectionStatus from '../ActionRenderComponents/others/updateCollectionStatus';
import UpdateCollectionStrategy from '../ActionRenderComponents/others/updateCollectionStrategy';
import {
  ActionActivityTypes,
  ActionComponentMapInterface,
  ActionComponentMapTypeProps,
} from '../types';

export default function actionComponentMapType(
  props: ActionComponentMapTypeProps
): ActionComponentMapInterface {
  const {
    commonProps,
    onUploadFilesOpen,
    emailFormData,
    errors,
    handleEmailContentChange,
    options,
    id,
  } = props;

  return {
    [ActionActivityTypes.EMAIL]: {
      component: WorkflowEmailForm,
      props: {
        onUploadFilesOpen,
        errors,
        emailFormData,
        handleEmailContentChange,
        ...commonProps,
        id,
      },
    },
    [ActionActivityTypes.DISPUTE]: {
      component: RaiseDisputeAction,
      props: commonProps,
    },
    [ActionActivityTypes.ESCALATION]: {
      component: RaiseEscalation,
      props: commonProps,
    },
    [ActionActivityTypes.TASK]: {
      component: AddTask,
      props: commonProps,
    },
    [ActionActivityTypes.PROMISE_TO_PAY]: {
      component: AddPtpAction,
      props: commonProps,
    },
    [ActionActivityTypes.NOTE]: {
      component: AddNote,
      props: commonProps,
    },
    [ActionActivityTypes.CUSTOMER_CUSTOM_FIELD]: {
      component: CustomerCustomField,
      props: commonProps,
    },
    [ActionActivityTypes.INVOICE_CUSTOM_FIELD]: {
      component: InvoiceCustomField,
      props: commonProps,
    },
    [ActionActivityTypes.EDIT_STRATEGY]: {
      component: UpdateCollectionStrategy,
      props: commonProps,
    },
    [ActionActivityTypes.USER_CATEGORY]: {
      component: UpdateCollectionOwner,
      props: commonProps,
    },
    [ActionActivityTypes.EDIT_COLLECTION_STATUS]: {
      component: UpdateCollectionStatus,
      props: commonProps,
    },
    [ActionActivityTypes.FLAG_INVOICE]: {
      component: FlagInvoices,
      props: commonProps,
    },
    [ActionActivityTypes.CALL_LOG]: {
      component: AddCallLog,
      props: commonProps,
    },
    [ActionActivityTypes.APPROVAL_REQUEST]: {
      component: AddApproval,
      props: { ...commonProps, options },
    },
  };
}
