import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '../text';
import { ActionActivityTypes } from '../types';

export const actionIcons = {
  [ActionActivityTypes.EMAIL]: {
    title: t.email,
    icon: <FontAwesomeIcon color="var(--geekblue-7)" icon={['far', 'envelope']} />,
    style: {
      backgroundColor: 'var(--geekblue-1)',
    },
  },
  [ActionActivityTypes.DISPUTE]: {
    title: t.dispute,
    icon: <FontAwesomeIcon color="var(--volcano-6)" icon={['far', 'comment-times']} />,
    style: {
      backgroundColor: 'var(--volcano-1)',
    },
  },
  [ActionActivityTypes.PROMISE_TO_PAY]: {
    title: t.promise_to_pay,
    icon: <FontAwesomeIcon color="var(--lime-8)" icon={['far', 'handshake-simple']} />,
    style: {
      backgroundColor: 'var(--lime-1)',
    },
  },
  [ActionActivityTypes.TASK]: {
    title: t.task,
    icon: <FontAwesomeIcon color="var(--magenta-5)" icon={['far', 'check']} />,
    style: {
      backgroundColor: 'var(--magenta-1)',
    },
  },
  [ActionActivityTypes.ESCALATION]: {
    title: t.escalation,
    icon: <FontAwesomeIcon color="var(--volcano-8)" icon={['far', 'exclamation-circle']} />,
    style: {
      backgroundColor: 'var(--volcano-1)',
    },
  },
  [ActionActivityTypes.NOTE]: {
    title: t.note,
    icon: <FontAwesomeIcon color="var(--blue-7)" icon={['far', 'clipboard']} />,
    style: {
      backgroundColor: 'var(--blue-1)',
    },
  },
  [ActionActivityTypes.CUSTOMER_CUSTOM_FIELD]: {
    title: t.customer_custom_field,
    icon: <FontAwesomeIcon color="var(--gold-7)" icon={['far', 'pen']} />,
    style: {
      backgroundColor: 'var(--gold-1)',
    },
  },
  [ActionActivityTypes.INVOICE_CUSTOM_FIELD]: {
    title: t.invoice_custom_field,
    icon: <FontAwesomeIcon color="var(--gold-7)" icon={['far', 'pen']} />,
    style: {
      backgroundColor: 'var(--gold-1)',
    },
  },
  [ActionActivityTypes.EDIT_STRATEGY]: {
    title: t.collection_strategy,
    icon: <FontAwesomeIcon color="var(--gold-7)" icon={['far', 'pen']} />,
    style: {
      backgroundColor: 'var(--gold-1)',
    },
  },
  [ActionActivityTypes.USER_CATEGORY]: {
    title: t.collection_owner,
    icon: <FontAwesomeIcon color="var(--gold-7)" icon={['far', 'pen']} />,
    style: {
      backgroundColor: 'var(--gold-1)',
    },
  },
  [ActionActivityTypes.EDIT_COLLECTION_STATUS]: {
    title: t.collection_status,
    icon: <FontAwesomeIcon color="var(--gold-7)" icon={['far', 'pen']} />,
    style: {
      backgroundColor: 'var(--gold-1)',
    },
  },
  [ActionActivityTypes.FLAG_INVOICE]: {
    title: t.flag_invoices,
    icon: <FontAwesomeIcon color="var(--red-7)" icon={['far', 'flag']} />,
    style: {
      backgroundColor: 'var(--red-1)',
    },
  },
  [ActionActivityTypes.CALL_LOG]: {
    title: t.flag_invoices,
    icon: <FontAwesomeIcon color="var(--blue-7)" icon={['far', 'phone']} />,
    style: {
      backgroundColor: 'var(--blue-1)',
    },
  },
  [ActionActivityTypes.APPROVAL_REQUEST]: {
    title: t.send_for_approval,
    icon: <FontAwesomeIcon color="var(--gray-7)" icon={['far', 'thumbs-up']} />,
    style: {
      backgroundColor: 'var(--gray-1)',
    },
  },
};
