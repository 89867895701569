import { DetailsLayout } from 'components/CollectionActivities/Common/Details/DetailsLayout';
import { ActivityDetailsLayoutProps } from 'types/activities/activity-types';
import { Note } from 'types/activities/note';
import { NoteDetailsBody } from './NoteDetailsBody';
import { NoteDetailsInfo } from './NoteDetailsInfo';

function NotesDetailsLayout(props: ActivityDetailsLayoutProps<Note>) {
  const {
    customer,
    hideActions,
    mode,
    subscriptionType,
    onDelete,
    editLoading,
    deleteLoading,
    activitiesMode,
    isLoading,
    data,
    isUnauthorized,
    isReadOnly,
    hideCopyLink,
  } = props;
  return (
    <DetailsLayout
      mode={mode}
      isUnauthorized={isUnauthorized}
      content={
        <NoteDetailsBody
          data={data}
          isLoading={isLoading}
          hideActions={hideActions}
          customer={customer}
          subscriptionType={subscriptionType}
          onDelete={onDelete}
          editLoading={editLoading}
          deleteLoading={deleteLoading}
          activitiesMode={activitiesMode}
          isReadOnly={isReadOnly}
          hideCopyLink={hideCopyLink}
        />
      }
      info={
        <NoteDetailsInfo
          loading={isLoading}
          data={data}
          customer={customer}
          isReadOnly={isReadOnly}
        />
      }
    />
  );
}

export { NotesDetailsLayout };
