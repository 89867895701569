import { ErrorMessage } from 'components/Common/Util/ErrorMessage';
import { useRuleErrorField } from 'store/collection-strategy-rule/hooks';
import { RuleErrors } from 'store/collection-strategy-rule/initial-state';
import { RuleErrorField } from 'store/collection-strategy-rule/validations';
import { useStrategyErrorField } from 'store/collection-strategy/hooks/general';
import { StrategyErrorField } from 'store/collection-strategy/validations';

interface BaseError<T> {
  errorField: T;
  showImplicitGapForError?: boolean;
}

interface ErrorBaseProps<T> extends BaseError<T> {
  useErrorField: (fieldKey: T) => { error?: string; showError: boolean };
}

function ErrorBase<T>(props: ErrorBaseProps<T>) {
  const { errorField, showImplicitGapForError, useErrorField } = props;

  const { error, showError } = useErrorField(errorField);
  const errorVisible = Boolean(showError && error);

  return (
    <ErrorMessage
      errorVisible={errorVisible}
      showImplicitGapForError={showImplicitGapForError}
      error={error}
    />
  );
}

interface RuleErrorFieldProps extends BaseError<RuleErrorField> {}

export function RuleError(props: RuleErrorFieldProps) {
  const { errorField, showImplicitGapForError } = props;

  return (
    <ErrorBase
      errorField={errorField}
      showImplicitGapForError={showImplicitGapForError}
      useErrorField={useRuleErrorField}
    />
  );
}

interface RuleActionErrorFieldProps extends RuleErrorFieldProps {
  errors?: RuleErrors;
  showError?: boolean;
}

export function RuleActionError(props: RuleActionErrorFieldProps) {
  const { errors, showError, errorField, showImplicitGapForError } = props;

  if (!errors) return null;

  const error = errors[errorField];

  const errorVisible = Boolean(showError && error);

  return (
    <ErrorMessage
      errorVisible={errorVisible}
      showImplicitGapForError={showImplicitGapForError}
      error={error}
    />
  );
}

interface StrategyErrorFieldProps extends BaseError<StrategyErrorField> {}

export function StrategyError(props: StrategyErrorFieldProps) {
  const { errorField, showImplicitGapForError } = props;

  return (
    <ErrorBase
      errorField={errorField}
      showImplicitGapForError={showImplicitGapForError}
      useErrorField={useStrategyErrorField}
    />
  );
}
