import { StatusTag } from '@sinecycle/growcomponents';

type AssigneesListProps = {
  assignees: string[];
};

export default function AssigneeReadOnly({ assignees }: AssigneesListProps) {
  if (!assignees || assignees.length === 0) {
    return <div>-</div>;
  }
  return (
    <div className="tw-flex tw-items-center tw-gap-8 tw-flex-wrap">
      {assignees?.map((assignee, index) => (
        <StatusTag
          key={index}
          text={assignee}
          style={{
            borderColor: '',
            backgroundColor: 'var(--geekblue-1)',
            textColor: 'var(--gray-9)',
          }}
        />
      ))}
    </div>
  );
}
