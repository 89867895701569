import { useSelector } from 'react-redux';
import { collectionStatusSelector } from 'store/collection-status/collection-status';
import OptionRender from '../optionRender';

export default function useCollectionStatusSelectOptions() {
  const collectionStatusesList = useSelector(collectionStatusSelector);
  const optionsWithRenderer = collectionStatusesList.collectionStatuses?.map((item) => {
    return {
      ...item,
      value: item.id,
      label: <OptionRender option={item} />,
    };
  });

  const options = collectionStatusesList.collectionStatuses?.map((item) => {
    return {
      ...item,
      value: item.id,
      label: item.label,
      title: '',
    };
  });
  return { options, optionsWithRenderer };
}
