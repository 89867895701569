import { MultiSelectFilterField } from 'components/Settings/WorkFlowAutomation/CustomAutomations/Trigger/Common/CommonConditionFilter/MultiSelectFilterField';
import dayjs, { Dayjs } from 'dayjs';
import produce from 'immer';
import {
  CustomField,
  CustomFieldUIType,
  NumberBasedField,
  validNumberBasedFields,
} from 'types/entities/custom-field';
import {
  DefaultAbsoluteOperand,
  InvoiceSegmentOperandType,
} from 'types/entities/invoice-segments/operands';
import { WorkFlowInOperator } from '../../EventCondition/operator';
import { DateWorkFlowFilterOperator, WorkFlowFilterOperator } from '../../EventCondition/type';
import SingleDateFieldFilter from '../CommonFilterHelper/SingleDateFieldFilter';
import SingleTextBoxFilter from '../CommonFilterHelper/SingleTextBoxFilter';
import { NumericFilter } from '../WorkFlowNumericFilter';

interface CustomFieldMappingProps {
  selectedFilter?: number;
  value?: WorkFlowFilterOperator<unknown>;
  onChange?: (
    updatedFilter: WorkFlowFilterOperator<unknown> | DateWorkFlowFilterOperator<unknown>
  ) => void;
  customFields?: CustomField[];
}
export const defaultCustomFieldNumericValue = {
  type: 'GOE',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: { value: undefined },
  },
};
const defaultCustomFielddropdownTypeValue: WorkFlowInOperator<unknown> = {
  type: 'IN',
  operands: {
    type: InvoiceSegmentOperandType.ABSOLUTE,
    value_holder: [],
  },
};
export function CustomFieldMapping(props: CustomFieldMappingProps) {
  const customFieldId = props.customFields?.find((filed) => filed.id === props.selectedFilter);
  const field = props.customFields?.find((field) => field.id === props.selectedFilter)?.id;
  const matchedCustomField = props.customFields?.find((field) => field?.id === customFieldId?.id);
  function destructureDateValue(value: DateWorkFlowFilterOperator<unknown>) {
    const updatedFilter = produce(value, (draftFilter) => {
      if (draftFilter) {
        const currentdDateValue = draftFilter.operands as DefaultAbsoluteOperand<unknown>;
        currentdDateValue.value_holder.value = dayjs(currentdDateValue.value_holder.value as Dayjs)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
      }
    });
    updatedFilter && props?.onChange?.(updatedFilter);
  }
  const DecimalNumericFilter = (
    <NumericFilter
      value={
        props?.value
          ? (props.value as WorkFlowFilterOperator<number>)
          : (defaultCustomFieldNumericValue as unknown as WorkFlowFilterOperator<number>)
      }
      onChange={props.onChange}
      singleFieldInputWidth={'114px'}
      betweenText={'-'}
      precision={2}
    />
  );
  const NumericFilterComponent = (
    <NumericFilter
      value={
        props?.value
          ? (props.value as WorkFlowFilterOperator<number>)
          : (defaultCustomFieldNumericValue as unknown as WorkFlowFilterOperator<number>)
      }
      onChange={props.onChange}
      singleFieldInputWidth={'50px'}
      betweenText={'-'}
    />
  );
  const DropDownFilter = (
    <MultiSelectFilterField
      conditionType="CUSTOMER_CUSTOM_FIELD"
      selectedField={field}
      value={props.value ? props.value : defaultCustomFielddropdownTypeValue}
      onChange={props.onChange}
      data={props.customFields}
    />
  );
  const DatePickerFilter = (
    <SingleDateFieldFilter
      value={props.value as DateWorkFlowFilterOperator<Dayjs>}
      onChange={destructureDateValue}
    />
  );
  const OtherCustomFieldFilters = (
    <>
      {validNumberBasedFields.includes(
        matchedCustomField?.data_type as NumberBasedField[number]
      ) ? (
        matchedCustomField?.data_type === 'NUMBER' ? (
          NumericFilterComponent
        ) : (
          DecimalNumericFilter
        )
      ) : (
        <SingleTextBoxFilter
          value={props.value ? props.value : defaultCustomFielddropdownTypeValue}
          onChange={props.onChange}
        />
      )}
    </>
  );

  function filterRenderer() {
    switch (matchedCustomField?.ui_type) {
      case CustomFieldUIType.DROPDOWN:
        return <>{DropDownFilter}</>;

      case CustomFieldUIType.DATEPICKER:
        return <>{DatePickerFilter}</>;

      default:
        return <>{OtherCustomFieldFilters}</>;
    }
  }
  const Filters = filterRenderer();
  return <>{props.selectedFilter !== undefined && Filters}</>;
}
