import { CellRenderProps } from '@sinecycle/growcomponents';
import { AmountWithCurrencySplit } from 'components/Common/MultiCurrency';
import { useConfig } from 'components/HigherOrderComponent/Config/config';
import { CreditMemo } from 'types/activities/credit-memo';
import { AccountConfigKey } from 'types/entities/account';
interface CreditRendererProps<T> extends CellRenderProps<T> {
  data: T;
}
export function AmountCell(props: CreditRendererProps<CreditMemo>) {
  const isMulticurrencyEnabled = useConfig(AccountConfigKey.MULTI_CURRENCY_ENABLED);
  return (
    <AmountWithCurrencySplit
      type="multi"
      amountWithCurrencySplit={props.data.amount_data}
      context="Credit Memos Table"
      hideSplitTotal
      showTooltip={false}
      hideMCSplitComp={isMulticurrencyEnabled}
    />
  );
}

export function BaseCurrencyCell(props: CreditRendererProps<CreditMemo>) {
  return (
    <AmountWithCurrencySplit
      type="multi"
      context={'payment_amount_transaction_currency'}
      amountWithCurrencySplit={props.data.amount_data}
      hideSplitTotal
      showTooltip={false}
    />
  );
}

export function TransActionCurrencyCell(props: CreditRendererProps<CreditMemo>) {
  return (
    <AmountWithCurrencySplit
      type="multi"
      context={'payment_amount_transaction_currency'}
      amountWithCurrencySplit={props.data.amount_customer_currency_data}
      hideSplitTotal
      showTooltip={false}
    />
  );
}
