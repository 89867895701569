export const t = {
  action_type_error: 'Please choose action type',
  add_action: 'Add Action',
  actions: 'Action(s)',
  action: 'Add Action',
  type: 'Type',
  select_type: 'Select type',
  invoice_level: 'Invoice level',
  invoice_level_info:
    'By default, actions are performed at a customer level. Enable this checkbox to perform actions for each individual invoice.',
  select_dispute_owner: 'Select Dispute Owner',
  title: 'Title',
  dispute_type: 'Dispute Type',
  dispute_owner: 'Dispute Owner',
  description: 'Description',
  dispute_type_needed: 'Choose Dispute Type',
  dispute_title_needed: 'Choose Dispute Title',
  dispute_owner_needed: 'Choose Dispute Owner',
  ptp_owner: 'PTP Owner',
  promissory_note: 'Enter promissory note',
  task_note: 'Enter task note',
  dispute_description_needed: 'Enter Dispute Description',
  attachments: 'Attachments',
  enter_dispute_description: 'Enter the description of the dispute...',
  ptp_owner_needed: 'Choose PTP Owner',
  ptp_creation_date: 'PTP creation date',
  ptp_date: 'PTP Date',
  select_ptp_date: 'Select PTP Date',
  task_title_needed: 'Enter task title',
  task_title: 'Title of the Task',
  add_members: 'Add members',
  enter_task_description: 'Enter description of the task',
  due_date: 'Due Date',
  task_creation_date: 'Days from the task creation date',
  select_task_creation_date: 'Select task creation date',
  enter_task_title: 'Enter task title',
  enter_dispute_title: 'Enter Dispute Title...',
  email: 'Email',
  assign_task_to: 'Task Owner',
  assign_to: 'Escalation Owner',
  assigned_to_required: 'Assigned to required',
  description_of_escalation: 'Enter the description of the escalation... ',
  enter_escalation_description: 'Enter description',
  enter_notes: 'Enter notes...',
  enter_notes_required: 'Notes required',
  notes: 'Notes',
  customer_field_name: 'Custom Field Name',
  value: 'Value',
  select_customer_field: 'Custom Field Name',
  select_invoice_customer_field: 'Invoice Field Name',
  enter_value: 'Enter value',
  select_collection_strategy: 'Select collection strategy',
  collection_strategy: 'Collection Strategy',
  collection_owner: 'Collection Owner',
  select_collection_owner: 'Select collection Owner',
  collection_status: 'Collection Status',
  select_collection_status: 'Select collection Status',
  comments: 'Comments',
  enter_comments: 'Enter comments',
  set_trigger: 'Before adding an action, please set a trigger.',
  dispute: 'Dispute',
  promise_to_pay: 'Promise to Pay',
  flag_invoices: 'Flag Invoices',
  task: 'Task',
  escalation: 'Escalation',
  note: 'Note',
  customer_custom_field: 'Customer Custom Field',
  invoice_custom_field: 'Invoice Custom Field',
  remove: 'Remove',
  call_details: 'Call Details',
  call_details_required: 'Call Log Details Required',
  enter_call_details: 'Enter Call Details',
  override_actions: `You're about to apply changes to this action in the workflow. this will currently disable the workflow Proceed with these changes?`,
  field_name_required: 'Customer field name required',
  value_required: 'Value required',
  collection_owner_required: 'Collection owner required',
  collection_status_required: 'Collection status required',
  collection_strategy_required: 'Collection strategy required',
  comments_required: 'Comments required',
  task_creation_date_on: 'task creation date',
  send_for_approval: 'Send for Approval',
  approval_edit: 'If the event is accepted, this action will be performed.',
  rejection_edit: 'If the event is rejected, this action will be performed.',
  approval: 'Approval',
  rejection: 'Rejection',
  add_approval: 'If the event is accepted, add an action.',
  add_rejection: 'If the event is rejected, add an action.',
  action_for: 'Action for',
  of_the_event: 'of the Event',
  edit_action: 'Edit an Action for',
  add_action_for: 'Add an Action for',
};
